import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { Group } from "model/datatypes";

const EditGroup: React.FC<{ projectID: string; onFinish: () => void; group: Group }> = ({
  onFinish,
  group,
  projectID,
}) => {
  const [editedGroup, setEditedGroup] = useState<Group>(group);
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const updateGroup = () => {
    if (editedGroup) {
      setLoading(true);
      const { created, id, scenarios, ...updateableFields } = editedGroup;
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .update(updateableFields)
        .then(() => {
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal onClose={() => onFinish()}>
      <div
        className={`border border-gray-300 rounded p-4 bg-white shadow flex flex-col z-30 w-2/3`}
      >
        <label className="font-bold text-xs">Group name</label>
        <input
          data-test="editGroupName"
          type="text"
          className={`input-box mb-2`}
          value={editedGroup.groupName}
          onChange={(e) => setEditedGroup({ ...editedGroup, groupName: e.target.value })}
        />
        <label className="font-bold text-xs">Group description</label>
        <textarea
          data-test="editGroupDescription"
          className={`input-box h-20 mb-2`}
          value={editedGroup.description}
          onChange={(e) => setEditedGroup({ ...editedGroup, description: e.target.value })}
        />
        {/* Group parameters */}
        <div className="flex mt-4">
          <button
            data-test="editGroupSave"
            className={`flex-1 button-small mr-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              !loading && updateGroup();
            }}
          >
            Edit Group
          </button>
          <button
            className={`flex-1 button-small ml-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => !loading && onFinish()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditGroup;
