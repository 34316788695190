import { useDatasetScenarios } from "api/useFirebase";
import AddIcon from "components/basic/icons/AddIcon";
import InputDataIcon from "components/basic/icons/InputDataIcon";
import NewInput from "components/input/newInput/NewInput";
import React, { useMemo, useState } from "react";

import ProjectDatasets from "./ProjectDatasets";

interface Props {}

const ProjectInputSection = (props: Props) => {
  const [addingInput, setAddingInput] = useState(false);
  const datasets = useDatasetScenarios();
  const amountOfInputs = useMemo(() => {
    return datasets.length;
  }, [datasets]);

  //hack to save dataset ids:
  // const { projectID } = useGlobalState();
  // const fs = useFirestore();
  // useEffect(() => {
  //   if (projectID) {
  //     const projectDoc = fs.collection("Projects").doc(projectID);
  //     const datasetsIds = datasets.map((ds) => ds.id);
  //     projectDoc.update({ scenarios: fsFieldvalue.arrayUnion(...datasetsIds) }).then(() => {
  //       console.log("hotfix dataset");
  //     });
  //   }
  // }, [projectID, datasets, fs]);

  return (
    <>
      <div className=" px-4 py-3 border-2 border-gray-200 rounded relative">
        <div className="flex items-center">
          <InputDataIcon />
          <div className="font-medium text-lg flex-grow ml-2">Datasets</div>
        </div>
        {amountOfInputs === 0 && (
          <div className="italic text-sm mb-2">{amountOfInputs} input sets</div>
        )}

        <div className="pt-4">
          <ProjectDatasets datasets={datasets} />
        </div>

        <button
          onClick={() => setAddingInput(true)}
          className="w-48 button-small flex items-center py-2"
        >
          <AddIcon className="h-6 w-6 mr-3" />
          <span className="text-xs font-bold">New input</span>
        </button>
      </div>
      {addingInput && (
        <>
          <NewInput
            onFinish={() => setAddingInput(false)}
            onCSVAdded={() => {
              setAddingInput(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default ProjectInputSection;
