import React, { useState, useMemo, useCallback } from "react";
import EMLogo from "resources/EMlogo.svg";
import { useFirebase } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import { useGlobalDispatch, useGlobalState } from "store";
import Toast from "components/basic/Toast";
import { PopupOnClick } from "components/basic/PopupMenu";
import NumerousLogo from "resources/NumerousLogo";
import { useCloudProjects, CloudProject } from "./useCloudProjects";
import LoadingOverlay from "components/basic/LoadingOverlay";

const Login: React.FC<{}> = () => {
  const fb = useFirebase();
  const dispatch = useGlobalDispatch();
  const { gproject } = useGlobalState();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState<string | null>(null);
  const { cloudProjects, loading: organisationsLoading } = useCloudProjects();

  const cloudProjectsOptions = useMemo(
    () =>
      cloudProjects.map((org) => ({
        display: org.name,
        id: org.id,
        val: org,
        title: org.name,
        description: "",
      })),
    [cloudProjects]
  );

  const selectOrganisation = useCallback(
    (org: CloudProject) => {
      dispatch({
        type: "SET_CLOUD_PROJECT",
        payload: org,
      });
      localStorage.setItem("cloudProjectID", org.id);
    },
    [dispatch]
  );

  const authenticate = () => {
    if (!fb) {
      return;
    }

    setLoading(true);
    console.log({ auth: fb.auth, email, password });
    fb.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log("loggedin");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        seterror(error.message);
      });
  };

  const renderOrganistaion = () => {
    if (gproject === "EM")
      return (
        <div className="w-full flex justify-center">
          <PopupOnClick
            options={cloudProjectsOptions}
            onSelect={(option) => {
              selectOrganisation(option.val);
            }}
          >
            <div className="p-2">
              <img className="mx-auto h-14 w-auto" src={EMLogo} alt="Logo" />
            </div>
          </PopupOnClick>
        </div>
      );
    else
      return (
        <div className="text-sm">
          <label className="block text-sm font-medium text-gray-700">Cloud</label>
          <Dropdown
            className="w-full bg-white"
            selectedID={gproject || undefined}
            options={cloudProjectsOptions}
            placeholder="Select organisation"
            onSelect={(option) => {
              selectOrganisation(option.val);
            }}
            loading={organisationsLoading}
          ></Dropdown>
        </div>
      );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="mb-4">
          <NumerousLogo className="mx-auto h-8 mb-4 w-auto" />
          <h2 className="mt-2 text-center text-3xl leading-9 font-medium text-gray-700">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            Or{" "}
            <button
              className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              onClick={() => {
                Toast("Please contact your administrater to set up an account");
              }}
            >
              create an account
            </button>
          </p>
        </div>
        <div className="bg-white rounded-md border border-gray-200 shadow-md px-8 py-6">
          {renderOrganistaion()}
          <label htmlFor="email" className="block mt-4 text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            id="email"
            name="email"
            data-test="email"
            type="email"
            autoComplete="email"
            required
            value={email}
            onChange={(e) => setemail(e.target.value)}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />

          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-4">
            Password
          </label>
          <input
            id="password"
            data-test="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && authenticate()}
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />

          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                className="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              />
              <label className="ml-2 block text-sm leading-5 text-gray-900">Remember me</label>
            </div>

            {/* <div className="text-sm leading-5">
              <button className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                Forgot your password?
              </button>
            </div> */}
          </div>

          <div className="mt-6" data-test="loginBtn">
            <button
              onClick={() => authenticate()}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y pl-3">
                <svg
                  className="h-5 w-5 text-blue-500 group-hover:text-blue-400 transition ease-in-out duration-150"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
          {error && (
            <div data-test="errorLoggingIn" className="text-xs italic">
              {error}
            </div>
          )}
          {loading && <LoadingOverlay />}
        </div>
      </div>
    </div>
  );
};

export default Login;
