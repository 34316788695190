import React, { useMemo, useState } from "react";
import MultipleTagSelecter from "components/basic/TagSelecter";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Plot from "react-plotly.js";

import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { PlotData } from "plotly.js";
import { Dataframe } from "grpc/grpcReact";
import dayjs from "dayjs";
import Papa from "papaparse";
import { TimeInfo } from "model/datatypes";

interface Props {
  lineGraph: { id: string; fields: string[] };
  allTags: string[];
  onUpdate: (updatedFields: { id: string; fields: string[] }) => void;
  onRemove: () => Promise<void>;
  data: Dataframe | null;
  t: number[] | null;
  dataInfo: TimeInfo | null;
  scenarioName: string;
}

const RawLineGraph: React.FC<Props> = ({
  lineGraph,
  allTags,
  onUpdate,
  onRemove,
  data,
  t,
  dataInfo,
  scenarioName,
}) => {
  const [loading, setLoading] = useState(false);

  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];
    lineGraph.fields.forEach((field) => {
      const theData = data ? data[field] : null;
      if (theData && t)
        plotData.push({
          x: t.map((epoc) => {
            const offset = dataInfo?.offset || 0;
            return dayjs.unix(epoc + offset).format("YYYY-MM-DD HH:mm:ss");
          }),
          y: theData,
          type: "scatter",
          mode: "lines",
          name: field,
        });
    });
    return plotData;
  }, [data, t, lineGraph.fields, dataInfo?.offset]);

  const tagOptions = useMemo(() => allTags.map((t) => ({ displayName: t, id: t })), [allTags]);

  const saveAsCSV = () => {
    if (!data) {
      return;
    }

    let tags: string[] = [];
    const dataRows: number[][] = [[]];

    //add time vector as first data:
    if (t) {
      tags.push("t");
      t.forEach((val, j) => {
        if (dataRows[j]) dataRows[j].push(val);
        else dataRows[j] = [val];
      });
    }

    //add remaining tags and their values:
    const graphTags = lineGraph.fields.filter((tag) => !!data[tag] && data[tag].length > 0); // make sure data exists for tags
    graphTags.forEach((tag) => {
      tags.push(tag);
      data[tag].forEach((p, j) => {
        if (dataRows[j]) dataRows[j].push(p);
        else dataRows[j] = [p];
      });
    });

    const csv = Papa.unparse([tags, ...dataRows], { delimiter: ";" });
    const blob = new Blob([csv]);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${scenarioName}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={() => {
                setLoading(true);
                onRemove()
                  .then(() => setLoading(false))
                  .catch(() => setLoading(false));
                closeMe();
              }}
            >
              Remove
            </button>
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  return (
    <div
      className="my-2 p-4 shadow border border-gray-200 relative"
      style={{ backgroundColor: "#F7FAFC" }}
    >
      <div className="absolute top-0 right-0 mr-2 mt-2">{renderOptions()}</div>
      <div className="text-sm font-medium mb-2">Line graph</div>
      <div className="text-xs font-medium">Data tags</div>
      <div className="w-full">
        <MultipleTagSelecter
          tags={tagOptions}
          selectedTagIDs={lineGraph.fields}
          selectTag={(tagID) => {
            onUpdate({
              ...lineGraph,
              fields: [...lineGraph.fields, tagID],
            });
          }}
          removeTag={(tagID) =>
            onUpdate({
              ...lineGraph,
              fields: lineGraph.fields.filter((field) => field !== tagID),
            })
          }
        />
      </div>

      <div className="w-full pt-2">
        {loading && <LoadingOverlay />}
        {mappedData.length > 0 && (
          <Plot
            className={``}
            data={mappedData}
            useResizeHandler
            config={{ displayModeBar: false }}
            style={{ width: "100%" }}
            layout={{
              autosize: true,
              paper_bgcolor: "#F7FAFC",
              plot_bgcolor: "#F7FAFC",
              margin: {
                t: 40,
                b: 40,
                l: 40,
                r: 40,
                pad: 0,
              },
            }}
          />
        )}

        {mappedData.length > 0 && (
          <button
            className="button-small absolute right-0 bottom-0 mr-2 mb-2"
            onClick={() => saveAsCSV()}
          >
            Save CSV
          </button>
        )}
      </div>
    </div>
  );
};

export default RawLineGraph;
