import React, { createContext, useState, useEffect, useRef } from "react";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import { useGlobalState } from "store";
import fbBetaConfig from "./config/FBBetaConfig";
import EMFBConfig from "./config/EMFBConfig";
import DevConfig from "./config/DevConfig";

const missionControlFBConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

type FBConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
};

export const FirebaseContext = createContext<{
  fbMain?: app.app.App;
  fbManager?: app.app.App;
  managerAnalytics?: app.analytics.Analytics;
  orgAnalytics?: app.analytics.Analytics;
}>({});

const FirebaseProvider: React.FC = ({ children }) => {
  //set up manager and manager analytics:
  const [fbManager, setfbManager] = useState<app.app.App>();

  const [managerAnalytics, setManagerAnalytics] = useState<app.analytics.Analytics>();
  useEffect(() => {
    try {
      const existing = app.app("manager");
      setfbManager(existing);
    } catch (error) {
      const appManager = app.initializeApp(missionControlFBConfig, "manager");
      setfbManager(appManager);
    }
  }, []);
  useEffect(() => {
    if (fbManager) {
      const analytics = app.analytics(fbManager);
      setManagerAnalytics(analytics);
    }
  }, [fbManager]);

  const { gproject, inLocalDevMode } = useGlobalState();

  //register the main firebase:
  const [fbMain, setFbMain] = useState<app.app.App>();
  const [orgAnalytics, setOrgAnalytics] = useState<app.analytics.Analytics>();
  const curGoogleProjectRef = useRef<string | null>(null);

  //call to register app when new gproject is selected:
  useEffect(() => {
    const registerAPP = async () => {
      const conf: FBConfig = getAppConfig(gproject);
      const prevGproject = curGoogleProjectRef.current;

      if (prevGproject) {
        console.log("remove fb for: " + prevGproject);
        await app.app(prevGproject).delete();
      }

      // console.log("set up new main app");
      const mainApp = app.initializeApp(conf, gproject);
      if (gproject === "local") {
        //@ts-ignore
        if (window.Cypress) {
          mainApp.firestore().settings({ experimentalForceLongPolling: true });
          // console.log("Enbable fs longpolling for cypress to work...");
        }
        // mainApp.functions().useEmulator("localhost", 5001);
        mainApp.auth().useEmulator("http://localhost:9099");
        mainApp.firestore().useEmulator("localhost", 8080);
      }

      curGoogleProjectRef.current = gproject;
      setFbMain(mainApp);

      if (conf.measurementId) {
        const orgAnalytics = app.analytics(mainApp);
        console.log("Started organisation analytics");
        setOrgAnalytics(orgAnalytics);
      }
    };

    if (gproject && curGoogleProjectRef.current !== gproject) {
      registerAPP();
    }
  }, [gproject]);

  //Disableanalytics for local
  useEffect(() => {
    if (inLocalDevMode) {
      const analyticsOrg = fbMain?.analytics();
      if (analyticsOrg) {
        analyticsOrg.setAnalyticsCollectionEnabled(false);
        // console.log("disable analytics for ORG..");
      }

      const analyticsMan = fbManager?.analytics();
      if (analyticsMan) {
        analyticsMan.setAnalyticsCollectionEnabled(false);
        // console.log("disable analytics for manager..");
      }
    }
  }, [inLocalDevMode, fbManager, fbMain]);

  return (
    <FirebaseContext.Provider value={{ fbMain, fbManager, managerAnalytics, orgAnalytics }}>
      {children}
    </FirebaseContext.Provider>
  );
};
export default FirebaseProvider;

const getAppConfig = (gproject: string) => {
  // use beta project for local emulators
  if (gproject === "local") return DevConfig;
  else if (gproject === "numerous-development") return DevConfig;
  else if (gproject === "EM_Beta") return fbBetaConfig;
  else if (gproject === "EM") return EMFBConfig;
  else return DevConfig;
};

export const getFirebase = () => {};
