import React from "react";
import {
  ComponentParameter,
  ComponentParamType,
  FileQuery,
  ComponentTypeReference,
} from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import { ParamEditValue } from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import {
  ReferenceSelecter,
  ParamEditMode,
  FileQuerySelecter,
  SelecterParamSetup,
} from "./ParamSystemInputs";
import { InputText } from "components/basic/Input";
import ParamTableColumnEdit from "./ParamTableColumnEdit";

const parameterTypes: ComponentParameter["type"][] = [
  "number",
  "string",
  "boolean",
  "selecter",
  "month",
  "config",
  "file",
  "table",
  "time_value",
  "json",
];

export const EditableParameterRow: React.FC<{
  systemRef: FileQuery["modelRef"];
  param: ComponentParamType;
  onUpdateParam: (newParam: ComponentParamType) => void;
  onDeleteParam: () => void;
  allowedComponentRefs?: ComponentTypeReference[];
}> = ({ param, onUpdateParam, onDeleteParam, allowedComponentRefs, systemRef }) => {
  const types = [...parameterTypes];
  if (!!allowedComponentRefs) types.push("reference");

  return (
    <div className="w-full flex items-center py-2 px-2 border-t border-gray-200 hover:bg-gray-200 text-xs">
      <div data-test="paramID" className={`w-1/8 pr-4`}>
        <InputText
          value={param.id}
          readOnly={param.fixedID}
          className={`input-box focus:outline-none px-2 py-1 ${
            param.fixedID ? "bg-gray-100" : ""
          }`}
          onChange={(val) => {
            if (!param.fixedID) onUpdateParam({ ...param, id: val });
          }}
        />
      </div>
      <div data-test="paramName" className="w-1/8 pr-4">
        <InputText
          value={param.displayName}
          className={`input-box w-full`}
          onChange={(val) => {
            onUpdateParam({ ...param, displayName: val });
          }}
        />
      </div>
      <div data-test="paramTooltip" className="w-1/8 pr-4">
        <InputText
          value={param.tooltip || ""}
          className={`input-box w-full`}
          onChange={(val) => {
            onUpdateParam({ ...param, tooltip: val });
          }}
        />
      </div>
      <div data-test="paramType" className="w-1/8 pr-4">
        <DropdownAtRoot
          className={"text-xs bg-white"}
          options={types.map((type) => ({
            id: type,
            display: type,
            val: type,
          }))}
          selectedID={param.type}
          onSelect={(option) => {
            let updatedParam: ComponentParameter = {
              ...param,
              type: option.val,
            };
            //clean up when parameter type changes:
            if (param.type === "file" && option.val !== "file") {
              updatedParam.value = null;
            }
            if (param.type === "table" && option.val !== "table") {
              updatedParam.value = null;
            }
            if (option.val === "table") {
              if (param.tableColumns) {
                const entries = param.tableColumns.map((col) => [col.colKey, [0]]); //create empty default values if table already exists
                updatedParam.value = Object.fromEntries(entries);
              } else {
                updatedParam.value = null;
              }
            }
            if (option.val === "reference")
              updatedParam = { ...updatedParam, displayMode: "hidden" };
            if (param.type === "reference" && option.val !== "reference")
              updatedParam = { ...updatedParam, displayMode: "normal" };
            if (updatedParam.type === "time_value" && param.type !== "time_value")
              updatedParam = { ...updatedParam, value: { value: 3600, unit: "hours" } };
            if (updatedParam.type === "json" && param.type !== "json")
              updatedParam = { ...updatedParam, value: {} };
            onUpdateParam(updatedParam);
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        {param.type === "config" && (
          <InputText
            value={param.tag || ""}
            className={`input-box w-full`}
            onChange={(val) => {
              onUpdateParam({ ...param, tag: val });
            }}
          />
        )}
        {param.type === "file" && (
          <FileQuerySelecter
            modelRef={systemRef}
            parameter={param}
            onUpdate={(newParam) => {
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type === "table" && (
          <ParamTableColumnEdit
            parameter={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type === "selecter" && (
          <SelecterParamSetup
            parameter={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
      </div>
      <div className="w-1/8 pr-4">
        {param.type === "reference" && allowedComponentRefs && (
          <ReferenceSelecter
            allowedParamRefs={allowedComponentRefs}
            parameter={param}
            onUpdate={(newParam) => {
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type !== "reference" && (
          <ParamEditValue
            parameter={param}
            paramType={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
      </div>
      <div className="w-1/12 pr-4">
        <ParamEditMode
          parameter={param}
          onUpdate={(newParam) => {
            onUpdateParam(newParam);
          }}
        />
      </div>
      <div className="w-1/12 pr-4">
        <input
          data-test="paramOptional"
          type="checkbox"
          checked={!!param.optional}
          onChange={(e) => {
            onUpdateParam({ ...param, optional: e.target.checked ? true : undefined });
          }}
        />
      </div>
      <div className="w-1/12 flex justify-end">
        {!param.fixedID && (
          <button
            onClick={() => {
              onDeleteParam();
            }}
            className={"button-small"}
          >
            Remove
          </button>
        )}
      </div>
    </div>
  );
};
