import React from "react";

const StudentHat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19"
      fill="none"
      viewBox="0 0 23 18"
    >
      <path
        fill="#fff"
        d="M3.81 10.098v2.609c0 .678.425 1.31 1.104 1.634l5.303 2.535a2.37 2.37 0 002.036 0l5.303-2.535c.679-.325 1.103-.956 1.103-1.634v-2.61l-6.406 3.065a2.37 2.37 0 01-2.036 0L3.81 10.098zm6.407-8.97L1.276 5.399c-.732.353-.732 1.282 0 1.634l8.94 4.272a2.37 2.37 0 002.037 0l8.527-4.077v5.488c0 .511.478.929 1.061.929s1.06-.418 1.06-.929V6.764c0-.343-.211-.65-.55-.817l-10.098-4.82a2.413 2.413 0 00-1.018-.222c-.355 0-.705.076-1.018.223z"
      ></path>
    </svg>
  );
};

export default StudentHat;
