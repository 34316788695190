import React from "react";
import SystemsOverview from "./systems/SystemsOverview";
import { Switch, Route } from "react-router-dom";
import EditSystemPage from "./systems/editSystem/EditSystemPage";
import FileOverview from "./files/FileOverview";

interface Props {}

const AdminPages: React.FC<Props> = () => {
  return (
    <Switch>
      <Route exact path="/systems" component={SystemsOverview} />
      <Route exact path="/files" component={FileOverview} />
      <Route path="/systems/:simModelID" component={EditSystemPage} />
    </Switch>
  );
};
export default AdminPages;
