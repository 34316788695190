import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useScenarios, useFirestore } from "api/useFirebase";
import { useGlobalDispatch, useGlobalState } from "store";
import {
  ComponentParameter,
  Group,
  ProjectType,
  SimulationModel,
  SimulationScenario,
} from "model/datatypes";
import { Popup } from "components/basic/Popup";
import ScenarioCard from "./ScenarioCard";
import * as Sentry from "@sentry/browser";
import EditGroup from "./group/EditGroup";
import NewScenario from "./newSimulation/NewScenario";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import { useHasEditAccess } from "api/useAuth";
import CommentableComponent from "components/comments/CommentableComponent";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { copyToClipboard } from "utils/jsUtils/CopyToClipBoard";
import RepositionList, { RepositionListItem } from "components/basic/RepositionList";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";
import AddIcon from "components/basic/icons/AddIcon";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";
import { ProjectTypeParameters } from "./ParameterSetup";

const GroupCard: React.FC<{
  group: Group;
  onStartReposition: () => void;
  projectType?: ProjectType;
}> = ({ group, onStartReposition, projectType }) => {
  const scenariosAmount = group.scenarios?.length;
  const { projectID, projectName, selectedGroups } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const hasGroupEditAccess = useHasEditAccess(group.ownerId);

  const copyGroupLinkToClipboard = () => {
    const link = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${projectName}/${projectID}/${group.id}`
    );
    copyToClipboard(link);
    Toast("Direct link to group copied to clipboard", { icon: "success" });
  };

  const fs = useFirestore();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedGroups.some((selected: string) => selected === group.id)) setOpen(true);
    else setOpen(false);
  }, [selectedGroups, group, setOpen]);

  const [loading, setLoading] = useState(false);
  const [editingGroup, setEditingGroup] = useState(false);

  const fixedGroup = useMemo(() => {
    if (projectType?.constrains === "groups")
      return projectType.fixedGroups?.find((g) => g.id === group.id);
    else return undefined;
  }, [projectType, group.id]);

  const updateGroupParameters = useCallback(
    async (parameters: ComponentParameter[]) => {
      if (!projectID) return;
      await fs
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .update({ parameters });
    },
    [fs, projectID, group.id]
  );

  //simulation models availale to use in this group
  const [systemsAvailable, setSystemsAvailable] = useState<SimulationModel[]>([]);
  useEffect(() => {
    const loadSystems = async (systemIDs: string[]) => {
      const systems: SimulationModel[] = [];
      for (let i = 0; i < systemIDs.length; i++) {
        const systemID = systemIDs[i];
        const sysDoc = await fs.collection("SimulationModels").doc(systemID).get();
        if (sysDoc.exists) {
          systems.push(
            convertFromFirestoreFormatNew({
              ...sysDoc.data(),
              id: sysDoc.id,
            }) as SimulationModel
          );
        }
      }
      return systems;
    };

    let systemIDs: string[] = [];
    if (fixedGroup) {
      systemIDs = fixedGroup.systems;
    }
    if (projectType && projectType.constrains === "system" && projectType.systems) {
      systemIDs = projectType.systems;
    }
    loadSystems(systemIDs)
      .then((systems) => {
        setSystemsAvailable(systems);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [projectType, fixedGroup, fs, group.id]);

  const groupScenarioBtnText = useMemo(() => {
    if (fixedGroup) return fixedGroup.simulationName || "scenario";
    else if (projectType?.constrains === "system")
      return projectType.simulationName || "scenario";
    return "scenario";
  }, [projectType, fixedGroup]);

  const [addingScenario, setAddingScenario] = useState<boolean | SimulationScenario>(false);

  const deleteGroup = () => {
    //only allow deleting empty groups..
    if (scenariosAmount === 0 && projectID) {
      setLoading(true);
      fs.collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .delete()
        .then(() => {
          //nothing to do here, listerners should remove group...
        })
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);
          setLoading(false);
        });
    }
  };

  const updateGroup = async (updatedFields: Partial<Group>) => {
    if (projectID && !loading) {
      setLoading(true);
      await fs
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .update(updatedFields);
      setLoading(false);
    }
  };

  const renderOpenGroup = () => {
    if (projectID)
      return (
        <div data-test="group" className="px-4 py-2">
          <div className="text-lg font-medium">{group.groupName}</div>
          <div className="text-xs">{group.description}</div>
          {fixedGroup?.parameters && (
            <ProjectTypeParameters
              paramHeadline={"Group parameters"}
              parameters={group.parameters}
              paramTypes={fixedGroup.parameters}
              updateParameters={updateGroupParameters}
            />
          )}
          <OpenGroupCardContent
            totalAmountOfScenarios={scenariosAmount}
            projectID={projectID}
            group={group}
            scenarioOrder={group.scenarios}
            updateScenarioOrder={(updatedScenaris) => {
              updateGroup({ scenarios: updatedScenaris });
            }}
            onDuplicateScenario={setAddingScenario}
          />
        </div>
      );
  };

  const renderClosedGroup = () => {
    return (
      <CommentableComponent
        commentTaget={{
          projectID: projectID!,
          projectName: projectName!,
          groupName: group.groupName,
          groupID: group.id,
        }}
        className={``}
      >
        <div className="px-4 py-2">
          <div className="text-lg font-medium">{group.groupName}</div>
          <div className="text-xs">{group.description}</div>
          {fixedGroup?.parameters && scenariosAmount === 0 && (
            <ProjectTypeParameters
              paramHeadline={"Group parameters"}
              parameters={group.parameters}
              paramTypes={fixedGroup.parameters}
              updateParameters={updateGroupParameters}
            />
          )}
          <div className="text-sm italic">
            {scenariosAmount === 0
              ? "No scenarios yet."
              : `${scenariosAmount} scenario${scenariosAmount > 1 ? "s" : ""}`}
          </div>
        </div>
      </CommentableComponent>
    );
  };

  const renderGroupOptions = () => {
    let errorMessage: string;
    if (scenariosAmount > 0) {
      errorMessage = "Only empty groups can be deleted";
    } else if (!hasGroupEditAccess) {
      errorMessage = "You do not have permission to delete this group";
    }

    return (
      <Popup
        useHover
        mt={13}
        align="right"
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={() => {
                setAddingScenario(true);
                closeMe();
              }}
            >
              New scenario
            </button>
            <button className={`button-popup border-t border-gray-400  opacity-50`}>
              Duplicate group
            </button>
            <button
              className={`button-popup border-t border-gray-400`}
              onClick={() => {
                onStartReposition();
                closeMe();
              }}
            >
              Reposition group
            </button>
            <button
              data-test="editGroup"
              className={`button-popup border-t border-gray-400`}
              onClick={() => setEditingGroup(true)}
            >
              Edit group
            </button>

            <button
              onClick={() => {
                copyGroupLinkToClipboard();
                closeMe();
              }}
              className={`button-popup border-t border-gray-400`}
            >
              Copy link to group
            </button>
            {scenariosAmount > 0 || !hasGroupEditAccess ? (
              <button
                className={`button-popup opacity-50 text-red-700 border-t border-gray-400`}
                onClick={() => {
                  Toast(`${errorMessage}`, { icon: "warning" });
                }}
              >
                Delete group
              </button>
            ) : (
              DeleteButtonPopup()
            )}
          </div>
        )}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const DeleteButtonPopup = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete group?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        deleteGroup();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`button-popup text-red-500 border-t border-gray-400`}>
          Delete group
        </button>
      </Popup>
    );
  };

  return (
    <>
      <div className="relative w-full border-2 border-gray-500 border-dashed rounded flex flex-col mb-6">
        {!open && renderClosedGroup()}
        {open && renderOpenGroup()}

        <div className="absolute right-0 top-0 mt-4 mr-4 flex items-center">
          <div data-test="options" className="relative mr-2">
            {renderGroupOptions()}
          </div>
          {scenariosAmount > 0 && (
            <OpenCloseButton
              open={open}
              onClick={() => {
                if (open) dispatch({ type: "DESELECT_GROUP", payload: group.id });
                else dispatch({ type: "SELECT_GROUP", payload: group.id });
              }}
            />
          )}
        </div>
        <button
          data-test="addScenarioButton"
          onClick={() => {
            setAddingScenario(true);
          }}
          className="ml-4 mb-4 w-48 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
        >
          <AddIcon className="h-6 w-6 mr-3" />
          <span className="text-xs font-bold">{`New ${groupScenarioBtnText}`}</span>
        </button>
      </div>

      {editingGroup && projectID && (
        <EditGroup
          group={group}
          onFinish={() => setEditingGroup(false)}
          projectID={projectID}
        />
      )}
      {addingScenario && projectID && (
        <NewScenario
          projectID={projectID}
          onFinish={() => setAddingScenario(false)}
          fromScenario={typeof addingScenario === "object" ? addingScenario : undefined}
          group={group}
          availableModels={systemsAvailable}
        />
      )}
    </>
  );
};

export default GroupCard;

const OpenGroupCardContent: React.FC<{
  projectID: string;
  group: Group;
  onDuplicateScenario: (prevScenario: SimulationScenario) => void;
  scenarioOrder: string[];
  updateScenarioOrder: (updatedOrder: string[]) => void;
  totalAmountOfScenarios: number;
}> = ({
  projectID,
  group,
  onDuplicateScenario,
  totalAmountOfScenarios,
  scenarioOrder,
  updateScenarioOrder,
}) => {
  const [limit, setLimit] = useState(20);
  const scenarios = useScenarios(projectID, group.id, limit);
  const [repositioningID, setRepositioningID] = useState<string | null>(null);

  const scenarioList = useMemo(() => {
    const scnearioList: RepositionListItem[] = [];
    scenarioOrder.forEach((scenarioID) => {
      const scenario = scenarios?.find((s) => s.id === scenarioID);
      if (!scenario) return;

      scnearioList.push({
        id: scenario.id,
        element: (
          <ScenarioCard
            key={scenario.id}
            scenario={scenario}
            groupName={group.groupName}
            onDuplicate={() => {
              onDuplicateScenario(scenario);
            }}
            onStartReposition={() => {
              setRepositioningID(scenario.id);
            }}
          />
        ),
        val: scenario,
      });
    });
    return scnearioList;
  }, [scenarios, scenarioOrder, group.groupName, onDuplicateScenario]);

  return (
    <>
      {scenarios && (
        <RepositionList
          list={scenarioList}
          onReposition={(updatedList) => {
            updateScenarioOrder(updatedList.map((li) => li.id));
          }}
          onDragEnd={() => {
            setRepositioningID(null);
          }}
          dragableID={repositioningID}
          enableDragOverlay
        />
      )}
      {totalAmountOfScenarios > limit && (
        <div>
          <div className="italic text-sm mb-2">{`Showing ${limit} of ${totalAmountOfScenarios} simulations`}</div>
          <button className={"button-small"} onClick={() => setLimit((p) => p + 20)}>
            Load more
          </button>
        </div>
      )}

      {!scenarios && (
        <div className="h-32 relative">
          <LoadingOverlay />
        </div>
      )}
    </>
  );
};
