import { BooleanDropdownSelecter } from "components/basic/Dropdown";
import HoverTooltip from "components/basic/HoverTooltip";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import InfoIcon from "components/basic/icons/InfoIcon";
import { Popup } from "components/basic/Popup";
import ToggleButton from "components/basic/ToggleButton";
import {
  ExtensionCompProps,
  ExtensionCompTypeProps,
} from "components/extensions/ExtensionComponents";
import { InputVariable } from "components/simulations/newSimulation/simSetup/simComponent/InputVariable";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { useEditSystemDispatch } from "components/systems/editSystem/EditSystemStore";
import { Component, ComponentType, InputVar } from "model/datatypes";
import { useCallback, useMemo } from "react";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import BHSLibrarySelecter from "./BHSLibrarySelecter";

//expose BHS component as "extension component":

export const BHSExtensionComponent: React.FC<ExtensionCompProps> = ({
  component,
  updateComponent,
  scenarioState,
}) => {
  const editBHS = useCallback(
    (updates: Partial<Component>) => {
      updateComponent({
        ...component,
        ...updates,
      });
    },
    [component, updateComponent]
  );

  const compType = useMemo(() => {
    return scenarioState.system?.componentTypes.find((ct) => ct.id === component.id);
  }, [scenarioState.system, component.id]);

  const renderInputVariables = () => {
    return (
      <>
        <div className="text-sm font-medium mb-4">Input Data</div>
        <div className="flex border-b border-gray-200">
          <div className="w-1/6 px-4 font-bold">Variable Name</div>
          <div className="w-1/6 px-4 font-bold">Source</div>
          <div className="w-1/12 px-2 font-bold">Scaling</div>
          <div className="w-1/12 px-2 font-bold">Offset</div>
          <div className="w-1/2 px-4 font-bold">Value</div>
        </div>
        {component.inputVariables.map((value) => {
          return (
            <InputVariable
              key={value.uuid}
              inputVariable={value}
              onUpdate={(updatedFields) => {
                const updatedVar: InputVar = {
                  ...value,
                  ...updatedFields,
                };
                editBHS({
                  inputVariables: updateArrayVal(component.inputVariables, updatedVar),
                });
              }}
              scenarioState={scenarioState}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className={`card mb-4 relative ${component.disabled ? "opacity-50" : ""}`}>
      <div className="flex items-center">
        <div className="font-bold text-lg flex-grow">{component.displayName}</div>
        {compType?.instantiationRules.allowDisabling && (
          <>
            <div>
              <ToggleButton
                active={!component.disabled}
                onChange={() => {
                  editBHS({ ...component, disabled: !component.disabled });
                }}
              />
              <div className="text-xs italic">
                {component.disabled ? "disabled" : "enabled"}
              </div>
            </div>
          </>
        )}
      </div>
      {!component.disabled && (
        <>
          <BHSLibrarySelecter BHSComponent={component} editBHS={editBHS} />
          <ComponentParameters component={component} onUpdate={updateComponent} />
          <div className="py-4">{renderInputVariables()}</div>
        </>
      )}
    </div>
  );
};

export const BHSExtensionCompTypeEditor: React.FC<ExtensionCompTypeProps> = ({
  componentType,
  removeComponent,
  maxOrder,
  onMove,
}) => {
  const editSystemDispatch = useEditSystemDispatch();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...componentType, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const renderOptions = () => {
    return (
      <Popup
        className="text-xs"
        align={"right"}
        useHover
        content={(closeMe) => {
          return (
            <>
              {onMove && typeof componentType.order === "number" && componentType.order > 0 && (
                <button
                  className={`button-popup border-b border-gray-400`}
                  onClick={() => {
                    onMove("up");
                    closeMe();
                  }}
                >
                  Move up
                </button>
              )}
              {onMove &&
                typeof componentType.order === "number" &&
                typeof maxOrder === "number" &&
                componentType.order < maxOrder && (
                  <button
                    className={`button-popup border-b border-gray-400`}
                    onClick={() => {
                      onMove("down");
                      closeMe();
                    }}
                  >
                    Move down
                  </button>
                )}
              <button
                className="button-popup"
                onClick={() => {
                  removeComponent(componentType.id);
                  closeMe();
                }}
              >
                Remove
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon className=" cursor-pointer" />
      </Popup>
    );
  };

  const renderComponentSettings = () => {
    return (
      <>
        <div className="flex pt-2">
          <div className="mr-4 w-40 flex flex-col">
            <label className={`text-xs font-medium`}>Allow disabling</label>
            <BooleanDropdownSelecter
              className="w-40 text-xs mb-2"
              value={componentType.instantiationRules.allowDisabling}
              onChange={(newVal) => {
                updateComponent({
                  instantiationRules: {
                    ...componentType.instantiationRules,
                    allowDisabling: newVal,
                  },
                });
              }}
            />
            {componentType.instantiationRules.allowDisabling && (
              <>
                <label className={`text-xs font-medium`}>Enabled by default</label>
                <BooleanDropdownSelecter
                  className="w-40 text-xs mb-2"
                  value={!!componentType.instantiationRules.defaultEnabled}
                  onChange={(newVal) => {
                    updateComponent({
                      instantiationRules: {
                        ...componentType.instantiationRules,
                        defaultEnabled: newVal,
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
          <div className=" flex flex-col">
            <div className={`text-xs font-medium flex items-center`}>
              <span className="mr-1">Instantiate on start</span>
              <HoverTooltip
                mt={-35}
                text="If enabled component will be added to top level of new scenarios."
              >
                <InfoIcon className="w-4 h-4" />
              </HoverTooltip>
            </div>
            <BooleanDropdownSelecter
              className="w-40 text-xs mb-2"
              value={componentType.instantiationRules.isMain}
              onChange={(newVal) => {
                updateComponent({
                  instantiationRules: {
                    ...componentType.instantiationRules,
                    isMain: newVal,
                  },
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card my-4">
      <div className="flex">
        <div className="font-bold flex-grow">{componentType.displayName} </div>
        <div className="text-xs font-medium mr-1">{componentType.order}</div>
        {renderOptions()}
      </div>
      {renderComponentSettings()}

      <div className="italic text-xs">
        This component will render a custom Borehole storage selecter component
      </div>
    </div>
  );
};

export const DefaultBHSType: ComponentType = {
  displayName: "BHS",
  type: "BHS",
  item_class: "gshp.BoreholeStorage_reference",
  subCompRules: [],
  instantiationRules: {
    isMain: true,
    allowDisabling: true,
    defaultEnabled: true,
  },
  name: "BHS",
  id: "BHS_EXTENSION_COMPONENT",
  inputVariables: [
    {
      scaling: 1,
      offset: 0,
      display: "Direct Load to BHS (advanced, normally 0!)",
      uuid: "62bee9db_1d9f_48f4_a444_72c2983dacaf",
      unit: "W",
      dataSourceType: "static",
      id: "BHS.P_direct",
      value: 0,
    },
  ],
  parameters: [
    {
      displayName: "Search Configuration",
      type: "json",
      displayMode: "hidden",

      uuid: "fd23e923_b1ef_46fa_a292_f47be9b736a4",
      id: "search_configuration",
      value: {
        H_max: 1000,
        Short_side: 1000,
        Long_side: 1000,

        k: 2.25,
        V: 2.856,
        T_g: 12.41,
        Geo_grad: 3,
        Geo_cover: 1,
        Cp: 4000,
        mfl: 29.0265,
        max_bt: 30,
        min_bt: 0,
        r_bore: 57,
        l_conf: 1,

        h_tot: 517,
        q_h_h: 126,
        q_m_h: 70,
        c_tot: 0,
        q_h_c: 0,
        q_m_c: 0,
        COP_h: 3.5,
        COP_c: 12,
      },
    },
    {
      displayName: "Selected Configuration",
      type: "json",
      displayMode: "hidden",

      uuid: "fd23e923_b1ef_46fa_a292_erhiojehers",
      id: "selected_configuration",
      value: {},
    },
    {
      displayName: "Configuration ID",
      tooltip: "Unique id for the boreholefield",
      displayMode: "hidden",

      type: "string",
      uuid: "f832009b_70fb_4f1d_8ec3_19a86966b3e3",
      id: "configuration_id",
      value: "",
    },
    {
      displayName: "Depth of borefield (m)",
      type: "number",
      displayMode: "fixed",
      uuid: "375850f7_96ad_4077_b8f0_0d41906facd5",
      id: "H",
      value: 0,
    },
    {
      displayName: "Resistance of borehole wall (K/W/m)",
      tooltip: "Typically 0.1",
      type: "number",
      uuid: "c8d6c15e_9385_4d1a_acc8_b99fb762fdf4",
      id: "R",
      value: 0.1,
    },
    {
      displayName: "Scaling of power extracted from boreholes",
      tooltip: "Typically 1",
      optional: true,
      type: "number",
      uuid: "faf332c2_cab8_4ef9_98a5_a86cc6346c64",
      id: "scale",
      value: 1,
    },
    {
      displayName: "Thermal conductivity of ground (k_s) (W/m)",
      type: "number",
      uuid: "d6ae0f1b_9c56_47cb_8f47_ce693c9d82e9",
      id: "k_s",
      value: 3,
    },
    {
      displayName: "Thermal capacity of ground (cV) (J/K/m3)",
      type: "number",
      uuid: "814375d5_8b19_4479_83b0_586557c5849c",
      id: "cV",
      value: 2300000,
    },
    {
      displayName: "Mean yearly near surface temperature (C)",
      type: "number",
      uuid: "68a0f206_84be_4cae_a2dd_6b6e7ff76145",
      id: "T_surf",
      value: 10,
    },
    {
      displayName: "Temperature increase pr. 100 m in depth",
      type: "number",
      uuid: "75625f27_22da_4de2_8dd6_86d1401fdab1",
      id: "dT_depth_100",
      value: 3,
    },
  ],
  order: 0,
};
