import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FirebaseContext } from "./FirebaseProvider";
import app from "firebase/app";
import {
  Project,
  SimulationModel,
  Group,
  ComponentType,
  LocalSimulationModel,
  SimFile,
  FileQuery,
  Container,
  Team,
  User,
  Invitation,
  RawDataReport,
  Comment,
  Notification,
  FullNotification,
  ProjectLog,
  LogEventTimed,
  LogEvent,
  SimulationScenario,
  ProjectType,
  DatasetScenario,
  BaseScenario,
  Organisation,
} from "model/datatypes";
import { store, useGlobalState } from "store";
import {
  cleanFSDataForUpdate,
  convertFromFirestoreFormat,
  convertFromFirestoreFormatNew,
  convertToFirestoreFormat,
} from "utils/firebase/firestoreFormatter";
import { mergeArrays, updateArrayVal } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useDataInfo } from "grpc/grpcReact";
import { fsFieldvalue } from "utils/firebase/helpers";
import { useUserRole } from "./useAuth";

//returns firebase state from context when callen from a component.
export const useFirebase = () => {
  return useContext(FirebaseContext).fbMain as app.app.App;
};
export const useFBManager = () => {
  return useContext(FirebaseContext).fbManager as app.app.App;
};

export const useFirestore = () => {
  const fb = useFirebase();
  return fb.firestore();
};

export const useAnalyticsLogger = () => {
  const { state } = useContext(store);
  const { inLocalDevMode } = state;

  const fbManager = useFBManager();
  const logFunction = useMemo(() => fbManager?.analytics().logEvent, [fbManager]);

  const orgFB = useFirebase();
  const orgLogFunction = useMemo(() => orgFB?.analytics().logEvent, [orgFB]);

  const theLogger = useCallback(
    (eventName: string, ...params) => {
      if (inLocalDevMode) {
        console.log("In local dev mode - did not send event: " + eventName);
      } else {
        if (logFunction) logFunction(eventName, ...params);
        if (orgLogFunction) orgLogFunction(eventName, ...params);
      }
    },
    [orgLogFunction, logFunction, inLocalDevMode]
  );

  return theLogger as typeof logFunction;
};

export const useComponentTypes = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [componentTypes, setComponentTypes] = useState<ComponentType[]>([]);
  const fb = useFirebase();
  const { user } = useGlobalState();

  const getUpdatedVersion = () => {
    const isLocalModel = !!projectID && !!scenarioID;
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };
    const nextVersion = app.firestore.FieldValue.increment(1);
    return {
      latestEdited: convertToFirestoreFormat(latestEdited),
      ...(isLocalModel && { localVersion: nextVersion }),
      ...(!isLocalModel && { version: nextVersion }),
    };
  };

  useEffect(() => {
    if (!simModelID) {
      setComponentTypes([]);
    } else {
      const modelDoc =
        projectID && scenarioID
          ? fb
              .firestore()
              .collection("Projects")
              .doc(projectID)
              .collection("Scenarios")
              .doc(scenarioID)
              .collection("SimulationModel")
              .doc(simModelID)
          : fb.firestore().collection("SimulationModels").doc(simModelID);

      const unsub = modelDoc.collection("Components").onSnapshot((snapshot) => {
        const compTypes: ComponentType[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as ComponentType;
          compTypes.push(c);
        });
        setComponentTypes(compTypes);
      });
      return () => {
        unsub();
      };
    }
  }, [simModelID, projectID, scenarioID, fb]);

  const removeComponent = (componentID: string) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const componentDoc = modelDoc.collection("Components").doc(componentID);
    batch.delete(componentDoc);
    batch.update(modelDoc, getUpdatedVersion());
    return batch.commit();
  };

  const addComponentType = (newCompType: ComponentType) => {
    return new Promise<string>((res, rej) => {
      const batch = fb.firestore().batch();
      const isLocalModel = !!projectID && !!scenarioID;
      const modelDoc = isLocalModel
        ? fb
            .firestore()
            .collection("Projects")
            .doc(projectID)
            .collection("Scenarios")
            .doc(scenarioID)
            .collection("SimulationModel")
            .doc(simModelID)
        : fb.firestore().collection("SimulationModels").doc(simModelID);
      console.log("ADDING new component");
      const componentDoc = modelDoc.collection("Components").doc();
      newCompType.id = componentDoc.id;
      batch.set(componentDoc, newCompType);

      batch.update(modelDoc, getUpdatedVersion());
      batch
        .commit()
        .then(() => {
          res(componentDoc.id);
        })
        .catch(rej);
    });
  };

  const updateComponent = (componentID: string, newComponentProps: Partial<ComponentType>) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const componentDoc = modelDoc.collection("Components").doc(componentID);

    delete newComponentProps.id;
    batch.update(componentDoc, convertToFirestoreFormat(newComponentProps, true));

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };
  return { componentTypes, updateComponent, removeComponent, addComponentType };
};

export const useContainers = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [containers, setContainers] = useState<Container[]>([]);
  const fb = useFirebase();
  const { user } = useGlobalState();

  const getUpdatedVersion = () => {
    const isLocalModel = !!projectID && !!scenarioID;
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };
    const nextVersion = app.firestore.FieldValue.increment(1);
    return {
      latestEdited: convertToFirestoreFormat(latestEdited),
      ...(isLocalModel && { localVersion: nextVersion }),
      ...(!isLocalModel && { version: nextVersion }),
    };
  };

  useEffect(() => {
    if (!simModelID) {
      setContainers([]);
    } else {
      const modelDoc =
        projectID && scenarioID
          ? fb
              .firestore()
              .collection("Projects")
              .doc(projectID)
              .collection("Scenarios")
              .doc(scenarioID)
              .collection("SimulationModel")
              .doc(simModelID)
          : fb.firestore().collection("SimulationModels").doc(simModelID);

      const unsub = modelDoc.collection("Containers").onSnapshot((snapshot) => {
        const cts: Container[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as Container;
          cts.push(c);
        });
        setContainers(cts);
      });
      return () => {
        unsub();
      };
    }
  }, [simModelID, projectID, scenarioID, fb]);

  const removeContainer = (containerID: string) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    console.log("REMOVING CONTAINER: " + containerID);
    const batch = fb.firestore().batch();
    const containerDoc = modelDoc.collection("Containers").doc(containerID);
    batch.delete(containerDoc);

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };

  const updateContainer = (containerID: string, newContainerProps: Partial<Container>) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const containerDoc = modelDoc.collection("Containers").doc(containerID);
    batch.update(containerDoc, cleanFSDataForUpdate(newContainerProps));

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };
  return { containers, updateContainer, removeContainer };
};

export const useModelComponent = (
  simModelID: string,
  compoentID: string,
  projectID?: string | null,
  scenarioID?: string
) => {
  const [component, setComponent] = useState<ComponentType | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (!simModelID || !compoentID) setComponent(null);
    else {
      const isLocalModel = !!projectID && !!scenarioID;
      const modelDoc = isLocalModel
        ? fb
            .firestore()
            .collection("Projects")
            .doc(projectID as string)
            .collection("Scenarios")
            .doc(scenarioID)
            .collection("SimulationModel")
            .doc(simModelID)
        : fb.firestore().collection("SimulationModels").doc(simModelID);
      const unsub = modelDoc
        .collection("Components")
        .doc(compoentID)
        .onSnapshot((docSnap) => {
          if (docSnap.exists) {
            const comp = { id: docSnap.id, ...docSnap.data() } as ComponentType;
            setComponent(comp);
          } else {
            setComponent(null);
          }
        });
      return () => {
        unsub();
      };
    }
  }, [simModelID, compoentID, fb, projectID, scenarioID]);

  return component;
};

export const useComponentLibrary = () => {
  const [componentLib, setcomponentLib] = useState<ComponentType[]>([]);
  const fb = useFirebase();

  const { user } = useGlobalState();

  useEffect(() => {
    if (!user) return;

    const unsub = fb
      .firestore()
      .collection("ComponentLibrary")
      .doc(user.organisation)
      .collection("SavedComponents")
      .onSnapshot((snapshot) => {
        const comp: ComponentType[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as ComponentType;
          comp.push(c);
        });
        setcomponentLib(comp);
      });
    return () => {
      unsub();
    };
  }, [fb, user]);

  const removeComponentFromLib = (componentID: string) => {
    return new Promise<void>(async (resolve, reject) => {
      if (!user?.organisation) {
        reject("Error, no user organisation found");
        return;
      }
      console.log("REMOVING" + componentID);
      // console.log({ componentID, newComponent, simModelID });
      await fb
        .firestore()
        .collection("ComponentLibrary")
        .doc(user.organisation)
        .collection("SavedComponents")
        .doc(componentID)
        .delete();

      resolve();
    });
  };
  const updateLibraryComponent = (componentID: string, newComponent: ComponentType) => {
    return new Promise<void>(async (resolve, reject) => {
      if (!user?.organisation) {
        reject("Error, no user organisation found");
        return;
      }
      await fb
        .firestore()
        .collection("ComponentLibrary")
        .doc(user.organisation)
        .collection("SavedComponents")
        .doc(componentID)
        .set({ ...newComponent }, { merge: true });

      resolve();
    });
  };

  return { componentLib, updateLibraryComponent, removeComponentFromLib };
};

export const useProjectTypes = () => {
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const fb = useFirebase();

  const { user } = useGlobalState();

  useEffect(() => {
    if (!user) return;
    const unsub = fb
      .firestore()
      .collection("ProjectTypes")
      .where("organisation", "==", user.organisation)
      .where("deleted", "==", null)
      .onSnapshot((snap) => {
        const pt: ProjectType[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          const type = convertFromFirestoreFormat({ ...data, id: doc.id }) as ProjectType;
          pt.push(type);
        });
        setProjectTypes(pt);
      });
    return () => {
      unsub();
    };
  }, [fb, user]);

  return projectTypes;
};

export const useProjectType = (projectTypeID?: string) => {
  const [projectType, setProjectType] = useState<ProjectType>();
  const fb = useFirebase();

  useEffect(() => {
    if (!projectTypeID) {
      setProjectType(undefined);
      return;
    }
    const unsub = fb
      .firestore()
      .collection("ProjectTypes")
      .doc(projectTypeID)
      .onSnapshot((doc) => {
        const data = doc.data();
        const type = convertFromFirestoreFormat({ ...data, id: doc.id }) as ProjectType;
        setProjectType(type);
      });
    return () => {
      unsub();
    };
  }, [fb, projectTypeID]);

  return projectType;
};

export const useProjects = () => {
  const { user, teamIds } = useGlobalState();
  const { hasDeveloperAccess } = useUserRole();
  const userID = useMemo(() => user?.id, [user]);
  const organisationID = useMemo(() => user?.organisation, [user]);

  const [collaboratorProjects, setCollaboratorProjects] = useState<Project[]>([]);
  const [teamProjects, setTeamProjects] = useState<Project[]>([]);
  const [ownedProjects, setOwnedProjects] = useState<Project[]>([]);
  const [otherProjects, setOtherProjects] = useState<Project[]>([]);

  const myProjects = useMemo(() => {
    //take all owned projects and add accesible projects.
    const allCollaboratorProjects = mergeArrays(
      teamProjects,
      collaboratorProjects
    ) as Project[];
    return mergeArrays(ownedProjects, allCollaboratorProjects) as Project[];
  }, [collaboratorProjects, ownedProjects, teamProjects]);

  const allProjects = useMemo(() => {
    let projects = mergeArrays(teamProjects, collaboratorProjects) as Project[];
    projects = mergeArrays(projects, ownedProjects) as Project[];
    return mergeArrays(projects, otherProjects) as Project[];
  }, [collaboratorProjects, teamProjects, ownedProjects, otherProjects]);

  const fb = useFirebase();

  //Get all projects if have developer access:
  useEffect(() => {
    if (userID && hasDeveloperAccess && organisationID) {
      const onReadAllProjects = (projects: Project[]) => {
        const ownedP: Project[] = [];
        const colabProjects: Project[] = [];
        const teamP: Project[] = [];
        const otherP: Project[] = [];
        projects.forEach((project) => {
          if (project.ownerId === userID) ownedP.push(project);
          else if (project.collaborators.some((colaboratorID) => colaboratorID === userID))
            colabProjects.push(project);
          else if (project.teams.some((team) => teamIds.some((tID) => tID === team)))
            teamP.push(project);
          else otherP.push(project);
        });
        setCollaboratorProjects(colabProjects);
        setTeamProjects(teamP);
        setOwnedProjects(ownedP);
        setOtherProjects(otherP);
      };

      const unsub = setProjectListener(fb.firestore(), onReadAllProjects, {
        organisationID,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIds, userID, organisationID]);

  //Get all colaborator projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess && organisationID) {
      const unsub = setProjectListener(fb.firestore(), setCollaboratorProjects, {
        organisationID,
        collaboratorID: userID,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIds, userID, organisationID]);

  //Get all owned projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess && organisationID) {
      const unsub = setProjectListener(fb.firestore(), setOwnedProjects, {
        organisationID,
        ownerID: userID,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, userID, organisationID]);

  //get team projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess && teamIds.length > 0 && organisationID) {
      const unsub = setProjectListener(fb.firestore(), setTeamProjects, {
        organisationID,
        teamIDs: teamIds,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIds, userID, organisationID]);

  return { myProjects, allProjects };
};

const setProjectListener = (
  fs: app.firestore.Firestore,
  setProjects: (projects: Project[]) => void,
  queryParams: {
    organisationID: string;
    teamIDs?: string[];
    ownerID?: string;
    collaboratorID?: string;
  }
) => {
  const projectCollection = fs.collection("Projects");
  let query = projectCollection
    .where("organisation", "==", queryParams.organisationID)
    .where("deleted", "==", null);

  if (queryParams.teamIDs)
    query = query.where("teams", "array-contains-any", queryParams.teamIDs);

  if (queryParams.ownerID) query = query.where("ownerId", "==", queryParams.ownerID);

  if (queryParams.collaboratorID)
    query = query.where("collaborators", "array-contains", queryParams.collaboratorID);

  const unsub = query.onSnapshot((snap) => {
    const projects: Project[] = [];
    snap.docs.forEach((doc) => {
      const data = doc.data();
      if (data.created !== null) {
        const project = convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project;
        projects.push(project);
      }
    });
    setProjects(projects);
  });
  return unsub;
};

export const useTeams = () => {
  const { user } = useGlobalState();

  const [teams, setTeams] = useState<Team[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    if (!user) return;
    const unsub = fb
      .firestore()
      .collection("teams")
      .where("organisation", "==", user.organisation)
      .onSnapshot((snap) => {
        const t: Team[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          const team = convertFromFirestoreFormat({ ...data, id: doc.id }) as Team;
          t.push(team);
        });
        setTeams(t);
      });
    return () => {
      unsub();
    };
  }, [fb, user]);

  return teams;
};

export const useProject = (projectID: string | null | undefined) => {
  const [project, setproject] = useState<Project | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (data) {
            setproject(convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project);
          }
        });
      return () => {
        usub();
      };
    } else setproject(null);
  }, [fb, projectID]);
  return project;
};

const getAvgRunTime = (logs?: LogEventTimed[]) => {
  if (!logs) return null;
  let totalRuntime = logs.reduce((prev, cur) => prev + cur.runTime, 0);
  return totalRuntime / logs.length;
};
const getLogAmount = (logs?: LogEvent[]) => {
  if (!logs) return 0;
  return logs.length;
};
const findMostRecentAction = (simStarted?: LogEvent[], processorStarted?: LogEvent[]) => {
  const recentSim = simStarted?.reduce((prev, cur) =>
    cur.timeStamp.isAfter(prev.timeStamp) ? cur : prev
  );
  if (!processorStarted) return recentSim;
  const recentProcessor = processorStarted.reduce((prev, cur) =>
    cur.timeStamp.isAfter(prev.timeStamp) ? cur : prev
  );
  if (!recentSim) return recentProcessor;
  return recentProcessor.timeStamp.isAfter(recentSim.timeStamp) ? recentProcessor : recentSim;
};
export const useProjectStatistics = (projectID: string) => {
  const [projectLog, setProjectLog] = useState<ProjectLog>();
  const statistics = useMemo(
    () => ({
      avgSimTimeSuccess: getAvgRunTime(projectLog?.log_simulation_finished),
      avgSimTimeFailed: getAvgRunTime(projectLog?.log_simulation_error),
      avgProcessorTimeSuccess: getAvgRunTime(projectLog?.log_processor_finished),
      avgProcessorTimeFailed: getAvgRunTime(projectLog?.log_processor_error),

      totalSimStarted: getLogAmount(projectLog?.log_simulation_started),
      totalSimSuccess: getLogAmount(projectLog?.log_simulation_finished),
      totalSimFailed: getLogAmount(projectLog?.log_simulation_error),

      totalProcessorStarted: getLogAmount(projectLog?.log_processor_started),
      totalProcessorSuccess: getLogAmount(projectLog?.log_processor_finished),
      totalProcessorFailed: getLogAmount(projectLog?.log_processor_error),

      totalStarted:
        getLogAmount(projectLog?.log_simulation_started) +
        getLogAmount(projectLog?.log_processor_started),
      totalSuccess:
        getLogAmount(projectLog?.log_simulation_finished) +
        getLogAmount(projectLog?.log_processor_finished),
      totalFailed:
        getLogAmount(projectLog?.log_simulation_error) +
        getLogAmount(projectLog?.log_processor_error),

      mostRecent: findMostRecentAction(
        projectLog?.log_simulation_started,
        projectLog?.log_processor_started
      ),
    }),
    [projectLog]
  );
  // if (projectLog) console.log({ projectLog, statistics });

  const fs = useFirestore();
  useEffect(() => {
    const unsub = fs
      .collection("Logs")
      .doc(projectID)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setProjectLog(
            convertFromFirestoreFormatNew({ ...data, id: projectID }) as ProjectLog
          );
        }
      });
    return unsub;
  }, [projectID, fs]);
  return { projectLog, statistics };
};

export const useGroups = (projectID?: string) => {
  const [groups, setgroups] = useState<Group[] | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .onSnapshot((snap) => {
          const groups: Group[] = [];
          snap.docs.forEach((doc) => {
            groups.push(convertFromFirestoreFormatNew({ ...doc.data(), id: doc.id }) as Group);
          });
          setgroups(groups);
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID]);
  return groups;
};

export const useTeamCollaborators = (team: Team) => {
  const [collaborators, setCollaborators] = useState<User[]>([]);
  const fs = useFirestore();

  useEffect(() => {
    setCollaborators([]);
    team.users.forEach((userID) => {
      fs.collection("users")
        .doc(userID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const user = { ...doc.data(), id: doc.id } as User;
            setCollaborators((prev) => updateArrayVal(prev, user));
          }
        });
    });
  }, [team, fs]);

  return collaborators;
};

export const useScenarios = (projectID: string | null, groupID: string, limit?: number) => {
  const [scenarios, setscenarios] = useState<SimulationScenario[] | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("groupID", "==", groupID)
        .limit(limit || 30)
        .onSnapshot((snap) => {
          const scenarios: SimulationScenario[] = [];
          snap.docs.forEach((doc) => {
            scenarios.push(
              convertFromFirestoreFormatNew({
                ...doc.data(),
                id: doc.id,
              }) as SimulationScenario
            );
          });
          setscenarios(scenarios);
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID, groupID, limit]);
  return scenarios;
};

export const useScenario = (projectID?: string, scenarioID?: string) => {
  const [scenario, setscenario] = useState<SimulationScenario>();
  const fb = useFirebase();
  useEffect(() => {
    if (projectID && scenarioID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .onSnapshot((doc) => {
          if (doc.data) {
            const scenarioData = { ...doc.data(), id: doc.id, type: "scenario" };
            setscenario(convertFromFirestoreFormat(scenarioData) as SimulationScenario);
          }
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID, scenarioID]);
  return scenario;
};

export const useSimulationModels = () => {
  const { user, teamIds } = useGlobalState();
  const { hasDeveloperAccess } = useUserRole();

  const [allPublicModels, setAllPublicModels] = useState<SimulationModel[]>([]);
  const [draftModels, setDraftModels] = useState<SimulationModel[]>([]);

  const [collaboratorModels, setCollaboratorModels] = useState<SimulationModel[]>([]);
  const [teamCollaboratorModels, setTeamCollaboratorModels] = useState<SimulationModel[]>([]);

  const [allModels, setAllModels] = useState<SimulationModel[]>([]);

  //set all models for developter:
  useEffect(() => {
    if (hasDeveloperAccess) {
      setAllModels([...allPublicModels, ...draftModels]);
    }
  }, [hasDeveloperAccess, allPublicModels, draftModels]);

  //setAllmodels for non-developers
  useEffect(() => {
    if (!hasDeveloperAccess) {
      setAllModels(
        mergeArrays(collaboratorModels, teamCollaboratorModels) as SimulationModel[]
      );
    }
  }, [hasDeveloperAccess, collaboratorModels, teamCollaboratorModels]);

  const fb = useFirebase();

  //get all public models for developers
  useEffect(() => {
    if (hasDeveloperAccess && user?.organisation) {
      const unsub = setSystemListener(fb.firestore(), setAllPublicModels, {
        organisationID: user.organisation,
        status: "published",
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, user?.organisation]);

  //get all drafs for developers:
  useEffect(() => {
    if (hasDeveloperAccess && user) {
      const unsub = setSystemListener(fb.firestore(), setDraftModels, {
        organisationID: user.organisation,
        status: "draft",
        ownerId: user.id,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, user]);

  //get collaboartor models for non-dev users:
  useEffect(() => {
    if (!hasDeveloperAccess && user) {
      const unsub = setSystemListener(fb.firestore(), setCollaboratorModels, {
        organisationID: user.organisation,
        status: "published",
        collaboratorId: user.id,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, user]);

  //get team collaborator models for non-developers
  useEffect(() => {
    if (!hasDeveloperAccess && teamIds.length > 0 && user) {
      const unsub = setSystemListener(fb.firestore(), setTeamCollaboratorModels, {
        organisationID: user.organisation,
        status: "published",
        teamIds,
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIds, user]);

  return { draftModels, allModels };
};

const setSystemListener = (
  fs: app.firestore.Firestore,
  setSystems: (systems: SimulationModel[]) => void,
  queryParams: {
    organisationID: string;
    status: "published" | "draft";
    ownerId?: string;
    teamIds?: string[];
    collaboratorId?: string;
  }
) => {
  const systemCollection = fs.collection("SimulationModels");
  let query = systemCollection
    .where("organisation", "==", queryParams.organisationID)
    .where("status", "==", queryParams.status);

  if (queryParams.teamIds)
    query = query.where("teams", "array-contains-any", queryParams.teamIds);

  if (queryParams.collaboratorId)
    query = query.where("collaborators", "array-contains", queryParams.collaboratorId);

  if (queryParams.ownerId) query = query.where("ownerId", "==", queryParams.ownerId);

  const unsub = query.onSnapshot((snap) => {
    const systems: SimulationModel[] = [];
    snap.docs.forEach((doc) => {
      const data = doc.data();
      if (data.created !== null) {
        const system = convertFromFirestoreFormatNew({
          ...data,
          id: doc.id,
          version: data.version || 0,
        }) as SimulationModel;
        systems.push(system);
      }
    });
    setSystems(systems);
  });
  return unsub;
};

export const useSimulationModel = (id?: string) => {
  const [simulationModel, setSimulationModel] = useState<SimulationModel | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (id) {
      const usub = fb
        .firestore()
        .collection("SimulationModels")
        .doc(id)
        .onSnapshot((doc) => {
          let data = doc.data();
          if (doc.exists && data) {
            const model: SimulationModel = {
              ...convertFromFirestoreFormatNew(data),
              id: doc.id,
              version: data.version || 0,
            } as SimulationModel;
            setSimulationModel(model);
          } else {
            setSimulationModel(null);
          }
        });
      return () => {
        usub();
      };
    } else setSimulationModel(null);
  }, [fb, id]);
  return simulationModel;
};

export const useLocalSimulationModel = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [simulationModel, setSimulationModel] = useState<LocalSimulationModel | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (simModelID && projectID && scenarioID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("SimulationModel")
        .doc(simModelID)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (doc.exists && data) {
            const model: LocalSimulationModel = {
              ...data,
              id: doc.id,
              version: data.version || 0,
              localVersion: data.localVersion || 0,
              componentTypes: data.componentTypes || [],
              containers: data.containers || [],
            } as LocalSimulationModel;
            setSimulationModel(model);
          } else {
            setSimulationModel(null);
          }
        });
      return () => {
        usub();
      };
    } else setSimulationModel(null);
  }, [fb, projectID, scenarioID, simModelID]);
  return simulationModel;
};

export const useSimFiles = ({
  tags,
  type,
  projectRef: project,
  modelRef: model,
}: FileQuery) => {
  const { user } = useGlobalState();

  const [simFiles, setSimFiles] = useState<SimFile[]>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const fs = useFirestore();
  useEffect(() => {
    if (!user) return;
    const projectID = project?.id;
    const modelID = model?.id;
    if (tags.length > 0 || type || modelID || projectID) {
      let fileCol:
        | app.firestore.Query<app.firestore.DocumentData>
        | app.firestore.CollectionReference<app.firestore.DocumentData> = fs
        .collection("FileLibrary")
        .doc(user.organisation)
        .collection("files");
      if (tags.length > 0) fileCol = fileCol.where("tags", "array-contains-any", tags);
      if (projectID) fileCol = fileCol.where("projectID", "==", projectID);
      if (modelID) fileCol = fileCol.where("modelID", "==", modelID);
      if (type) fileCol = fileCol.where("type", "==", type);

      setLoadingFiles(true);
      const usub = fileCol.onSnapshot((snap) => {
        const files: SimFile[] = [];
        snap.docs.forEach((doc) => {
          files.push({ ...doc.data(), id: doc.id } as SimFile);
        });
        setLoadingFiles(false);
        setSimFiles(files);
      });
      return () => {
        setLoadingFiles(false);
        usub();
      };
    } else {
      setSimFiles([]);
      setLoadingFiles(false);
    }
  }, [fs, tags, project, model, type, user]);

  // useEffect(() => {
  //   const fixDeprecatedFiles = async () => {
  //     if (!user) return;
  //     const batch = fs.batch();
  //     const filesSnap = await fs
  //       .collection("FileLibrary")
  //       .where("organisation", "==", user.organisation)
  //       .get();

  //     filesSnap.forEach((doc) => {
  //       const file = { ...doc.data(), id: doc.id } as SimFile;
  //       batch.set(
  //         fs.collection("FileLibrary").doc(user.organisation).collection("files").doc(file.id),
  //         file
  //       );
  //       console.log("move file: " + file.name);
  //     });

  //     batch.commit();
  //   };
  //   fixDeprecatedFiles();
  // }, [user, fs]);

  return { simFiles, loadingFiles };
};

//tags for finding files:
export const useFileTags = () => {
  const { user } = useGlobalState();

  const [tags, setTags] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    if (!user) return;
    const usub = fb
      .firestore()
      .collection("FileLibrary")
      .doc(user.organisation)
      .onSnapshot((doc) => {
        const tags: string[] = doc.data()?.tags;
        const types: string[] = doc.data()?.types;
        if (tags) setTags(tags);
        if (types) setTypes(types);
      });
    return () => {
      usub();
    };
  }, [fb, user]);

  // useEffect(() => {
  //   const fixDeprecatedFileTags = async () => {
  //     if (!user) return;
  //     const tagsDoc = await fb.firestore().collection("FileTags").doc("tags").get();
  //     const tags: string[] = tagsDoc.data()?.tags;

  //     const typeTagsDoc = await fb.firestore().collection("FileTags").doc("typeTags").get();
  //     const types: string[] = typeTagsDoc.data()?.types;

  //     fb.firestore().collection("FileLibrary").doc(user.organisation).set({ tags, types });
  //   };
  //   fixDeprecatedFileTags();
  // }, [user, fb]);

  return { tags, types };
};

type MongoDBConfig = {
  id: string;
  Name: string;
  Tags: string[];
  [key: string]: any;
};

export const useConfigs = (tag?: string) => {
  const [configs, setConfigs] = useState<MongoDBConfig[]>([]);
  const [loadingConfigs, setLoadingConfigs] = useState(false);
  const fb = useFirebase();

  useEffect(() => {
    if (tag) {
      setLoadingConfigs(true);
      const usub = fb
        .firestore()
        .collection("Configs")
        .where("Tags", "array-contains", tag)
        .onSnapshot((snap) => {
          const confs: any[] = [];
          snap.docs.forEach((doc) => {
            confs.push({ ...doc.data(), id: doc.id });
          });
          setLoadingConfigs(false);
          setConfigs(confs);
        });
      return () => {
        usub();
      };
    } else setConfigs([]);
  }, [fb, tag]);
  return { configs, loadingConfigs };
};

export const useDatasetScenarios = () => {
  const fs = useFirestore();
  const { projectID } = useGlobalState();
  const [datasets, setDatasets] = useState<DatasetScenario[]>([]);

  useEffect(() => {
    if (!projectID) return;
    const fixDeprecation = async () => {
      const batch = fs.batch();
      const projectDoc = fs.collection("Projects").doc(projectID);
      const snap = await projectDoc.collection("Datasets").get();
      if (snap.empty) {
        return;
      }
      console.log("deprecated found: " + snap.docs.length);
      const updatedIds: string[] = [];

      snap.docs.forEach((doc) => {
        if (!doc.exists) return;
        let dataset = convertFromFirestoreFormatNew({
          ...doc.data(),
          id: doc.id,
        }) as DatasetScenario;

        //some magic to fix compatability after changed format:
        //@ts-ignore
        if (dataset.datasetName) dataset.scenarioName = dataset.datasetName;
        //@ts-ignore
        if (dataset.tags) dataset.dataTags = dataset.tags;
        if (!dataset.created) dataset.created = dayjs();
        dataset.type = "dataset";
        batch.set(
          projectDoc.collection("Scenarios").doc(dataset.id),
          convertToFirestoreFormat(dataset)
        );
        batch.delete(doc.ref);
        updatedIds.push(doc.id);
      });
      batch.update(projectDoc, { scenarios: fsFieldvalue.arrayUnion(...updatedIds) });
      await batch.commit();
      console.log("moved datasets!");
    };
    fixDeprecation();
  }, [projectID, fs]);

  useEffect(() => {
    if (projectID) {
      return fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("type", "==", "dataset")
        .onSnapshot((snap) => {
          const ds: DatasetScenario[] = [];
          snap.docs.forEach((doc) => {
            if (!doc.exists) return;
            let dataset = convertFromFirestoreFormatNew({
              ...doc.data(),
              id: doc.id,
            }) as DatasetScenario;

            //some magic to fix compatability after changed format:
            //@ts-ignore
            if (dataset.datasetName) dataset.scenarioName = dataset.datasetName;
            //@ts-ignore
            if (dataset.tags) dataset.dataTags = dataset.tags;
            if (!dataset.created) dataset.created = dayjs();
            dataset.type = "dataset";

            ds.push(dataset);
          });
          setDatasets(ds);
        });
    }
  }, [projectID, fs]);

  return datasets;
};

export const useDataParamValues = (
  inputDataID: string,
  variableID?: string,
  projectID?: string | null
) => {
  const fs = useFirestore();
  const [parameterData, setparameterData] = useState<number[]>([]);
  const [dataLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!variableID || !projectID) setparameterData([]);
    else {
      setLoading(true);
      return fs
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID)
        .collection("Data")
        .doc(variableID)
        .onSnapshot(
          (doc) => {
            const docData = doc.data();
            if (docData) {
              const vals = docData.values as (string | number)[];
              const parsed = vals.map((v) => (typeof v !== "number" ? parseFloat(v) : v));
              setparameterData(parsed);
            }
            setLoading(false);
          },
          (e) => {
            Sentry.captureException(e);
            console.log(e);
            setLoading(false);
          }
        );
    }
  }, [variableID, fs, projectID, inputDataID]);

  return { parameterData, dataLoading };
};

export const useAllUserInvitations = () => {
  const fs = useFirestore();

  const { user } = useGlobalState();
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    if (!user) return;
    return fs
      .collection("invitations")
      .where("organisation", "==", user.organisation)
      .onSnapshot((snap) => {
        const invitations: Invitation[] = [];
        snap.docs.forEach((doc) => {
          const invitation = convertFromFirestoreFormat({
            id: doc.id,
            ...doc.data(),
          }) as Invitation;
          invitations.push(invitation);
        });
        setInvitations(invitations);
      });
  }, [fs, user]);
  return invitations;
};

export const useAllUsers = () => {
  const fs = useFirestore();

  const { user } = useGlobalState();

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (user?.organisation)
      return fs
        .collection("users")
        .where("organisation", "==", user.organisation)
        .onSnapshot((snap) => {
          const u: User[] = [];
          snap.docs.forEach((doc) => {
            const user = { id: doc.id, ...doc.data() } as User;
            u.push(user);
          });
          setUsers(u);
        });
  }, [fs, user]);

  return users;
};

export const useUserNotifications = () => {
  const fs = useFirestore();
  const [userNotifications, setUserNotifications] = useState<Notification[]>([]);
  const [fullUserNotifications, setFullUserNotifications] = useState<FullNotification[]>();
  const { user } = useGlobalState();

  // Get all notifications tied to current UID
  useEffect(() => {
    return fs
      .collection("users")
      .doc(user?.fbUser.uid)
      .collection("notifications")
      .onSnapshot((snap) => {
        const n: Notification[] = [];
        snap.docs.forEach((doc) => {
          const notification = convertFromFirestoreFormat({
            id: doc.id,
            ...doc.data(),
          }) as Notification;
          n.push(notification);
        });
        setUserNotifications(n);
      });
  }, [user, fs]);

  // Get full notifications with comments tied to them
  useEffect(() => {
    const getNotificationData = async () => {
      const fn: FullNotification[] = [];

      for (let i = 0; i < userNotifications.length; i++) {
        const notification = userNotifications[i];
        if (notification.CommentID) {
          const doc = await fs.collection("Comments").doc(notification.CommentID).get();

          const fullNotification = convertFromFirestoreFormat({
            ...doc.data(),
            ...notification,
          }) as FullNotification;

          fn.push(fullNotification);
        }
      }

      const sortedFn = fn.sort((a, b) => {
        if (a.read === b.read) return b.timestamp.valueOf() - a.timestamp.valueOf();
        if (a.read) return 1;
        return -1;
      });

      setFullUserNotifications(sortedFn);
    };

    getNotificationData();
  }, [userNotifications, fs]);

  return { fullUserNotifications };
};

//gets all comments in a group and below....:
export const useGroupComments = (groupIDs: string[], projectID?: string) => {
  const fs = useFirestore();
  const [rawComments, setRawComments] = useState<Comment[]>([]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const groupCommentsActive = useMemo(() => {
    return currentPage === "simulations";
  }, [currentPage]);

  //Load comments:
  useEffect(() => {
    if (groupCommentsActive && projectID && groupIDs.length > 0) {
      let query = fs
        .collection("Comments")
        .where("projectID", "==", projectID)
        .where("groupID", "in", groupIDs);

      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setRawComments(c);
      });
    } else setRawComments([]);
  }, [groupIDs, groupCommentsActive, projectID, fs]);

  return rawComments;
};

export const useProjectComments = () => {
  const fs = useFirestore();
  const [comments, setComments] = useState<Comment[]>([]);

  const { projectID } = useGlobalState();

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const projectCommentsActive = useMemo(() => {
    return currentPage === "simulations" || currentPage === "" || currentPage === "overview";
  }, [currentPage]);

  //Load comments:
  useEffect(() => {
    if (projectCommentsActive) {
      let query = fs.collection("Comments").where("commentScope", "==", "project");
      if (projectID) query = query.where("projectID", "==", projectID);
      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setComments(c);
      });
    } else {
      setComments([]);
    }
  }, [projectCommentsActive, projectID, fs, currentPage]);

  return comments;
};

export const useSystemComments = () => {
  const fs = useFirestore();
  const [comments, setComments] = useState<Comment[]>([]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const simModelID = useMemo(
    () => location.pathname.split("/")[2] as string | undefined,
    [location]
  );

  //Load comments:
  useEffect(() => {
    if (currentPage === "systems") {
      let query: app.firestore.Query<app.firestore.DocumentData> = fs.collection("Comments");
      if (simModelID) query = query.where("systemID", "==", simModelID);
      else query = query.where("commentScope", "==", "system");
      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setComments(c);
      });
    } else {
      setComments([]);
    }
  }, [simModelID, fs, currentPage]);

  return comments;
};

export const useCheckForComments = (
  commentIDKey: "scenarioID" | "projectID" | "groupID" | "componentID",
  id: string
) => {
  const [commentExists, setCommentExists] = useState(false);
  const { comments } = useGlobalState();
  useEffect(() => {
    const exists = comments.some((c: Comment) => c[commentIDKey] === id);
    setCommentExists(exists);
  }, [comments, commentIDKey, id]);

  return commentExists;
};

export const useUserInfo = (userID?: string) => {
  const [userInfo, setuserInfo] = useState<User>();
  const fs = useFirestore();

  useEffect(() => {
    if (userID)
      return fs
        .collection("users")
        .doc(userID)
        .onSnapshot((doc) => {
          const data = { id: doc.id, ...doc.data() } as User;
          if (data) setuserInfo(data);
        });
    else setuserInfo(undefined);
  }, [userID, fs]);
  return userInfo;
};

// export const usePIDiagrams = (modelID: string, projectID?: string, scenarioID?: string) => {
//   const [piDiagrams, setPIDiagrams] = useState<PIDiagram[]>([]);
//   const fs = useFirestore();
//   useEffect(() => {
//     const docSource =
//       projectID && scenarioID
//         ? fs.collection("Projects").doc(projectID).collection("Scenarios").doc(scenarioID)
//         : fs.collection("SimulationModels").doc(modelID);
//     if (projectID && scenarioID) {
//       const usub = docSource.collection("PIDiagrams").onSnapshot((snap) => {
//         const diagrams: PIDiagram[] = [];
//         snap.docs.forEach((doc) => {
//           diagrams.push({ ...doc.data(), id: doc.id } as PIDiagram);
//         });
//         setPIDiagrams(diagrams);
//       });
//       return () => {
//         usub();
//         setPIDiagrams([]);
//       };
//     }
//   }, [modelID, projectID, scenarioID, fs]);

//   return piDiagrams;
// };

export const useRawdataReports = (
  modelID: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [rawDataReports, setRawDataReports] = useState<RawDataReport[]>([]);
  const fs = useFirestore();
  useEffect(() => {
    const docSource =
      projectID && scenarioID
        ? fs.collection("Projects").doc(projectID).collection("Scenarios").doc(scenarioID)
        : fs.collection("SimulationModels").doc(modelID);
    if (projectID && scenarioID) {
      const usub = docSource.collection("RawDataReports").onSnapshot((snap) => {
        const reports: RawDataReport[] = [];
        snap.docs.forEach((doc) => {
          reports.push({ ...doc.data(), id: doc.id } as RawDataReport);
        });
        setRawDataReports(reports);
      });
      return () => {
        usub();
        setRawDataReports([]);
      };
    }
  }, [modelID, projectID, scenarioID, fs]);

  return rawDataReports;
};

export const useIdToken = () => {
  const { user } = useGlobalState();
  const [idToken, setIdToken] = useState<string | null>(null);

  useEffect(() => {
    user?.fbUser
      .getIdToken()
      .then((token) => {
        setIdToken(token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);
  return idToken;
};

export const useInputFullInputSource = (
  scenarioID: string,
  projectID: string,
  type: "scenario" | "dataset",
  offset?: number
) => {
  const [fullSource, setFullSource] = useState<null | BaseScenario>(null);
  const fs = useFirestore();

  useEffect(() => {
    fs.collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenarioID)
      .get()
      .then((doc) => {
        const scenario = { ...doc.data(), type } as BaseScenario;

        //@ts-ignore
        if (scenario.datasetName) scenario.scenarioName = scenario.datasetName;

        setFullSource(scenario);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [scenarioID, projectID, type, fs]);

  const { timeInfo, producingData } = useDataInfo(
    projectID,
    scenarioID,
    fullSource?.lastest_main_execution,
    offset
  );

  return { fullSource, timeInfo, producingData };
};

export const useOrganisation = () => {
  const { user } = useGlobalState();
  const [organisation, setOrganisation] = useState<Organisation>();
  const fs = useFirestore();
  useEffect(() => {
    if (user?.organisation) {
      return fs
        .collection("organisations")
        .doc(user.organisation)
        .onSnapshot((doc) => {
          if (!doc.exists) {
            setOrganisation(undefined);
            return;
          }
          const org = convertFromFirestoreFormatNew({
            id: doc.id,
            ...doc.data(),
          }) as Organisation;
          setOrganisation(org);
        });
    }
  }, [user, fs]);

  return organisation;
};
