import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  useProjects,
  useFirestore,
  useProjectStatistics,
  useProjectType,
} from "api/useFirebase";
import { store } from "store";
import { Popup } from "./basic/Popup";
import { Project } from "model/datatypes";
import EditProject from "./projects/EditProject";
import { useHistory } from "react-router-dom";
import gtw from "gtw";
import Toast from "./basic/Toast";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import LoadingOverlay from "./basic/LoadingOverlay";
import { useUserRole } from "api/useAuth";
import AddIcon from "./basic/icons/AddIcon";
import DotDotDotIcon from "./basic/icons/DotDotDotIcon";
import SearchBar from "./basic/SearchBar";
import dayjs from "dayjs";
import CommentableComponent from "components/comments/CommentableComponent";
import NewProject from "./projects/NewProject";

interface Props {}

const HomePage: React.FC<Props> = () => {
  const { dispatch } = useContext(store);
  const history = useHistory();

  const { hasDeveloperAccess } = useUserRole();

  const { myProjects, allProjects } = useProjects();

  const setProject = (projectID: string, projectName: string) => {
    //save selected project locally...
    localStorage.setItem("selectedProjectID", projectID);
    localStorage.setItem("selectedProjectName", projectName);
    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: { projectID, projectName },
    });
    history.push("/simulations");
  };

  const [addingProject, setAddingProject] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [shownProjects, setshownProjects] = useState<"my_projects" | "all">("all");

  //Set the project shown based on whether my_projects first time laoading is done:
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (allProjects.length > 0 && !firstLoad) {
      setFirstLoad(true);
      if (myProjects.length > 0 && hasDeveloperAccess) setshownProjects("my_projects");
      else setshownProjects("all");
    }
  }, [allProjects, myProjects, hasDeveloperAccess, firstLoad]);

  const currentProjects = useMemo(() => {
    let projects = shownProjects === "my_projects" ? myProjects : allProjects;
    if (searchWord.length > 0)
      projects = projects.filter((p) => p.projectName.match(new RegExp(searchWord, "i")));
    return projects.sort((a, b) => a.created.diff(b.created));
  }, [myProjects, allProjects, shownProjects, searchWord]);

  const showProjectTypeSelecter =
    hasDeveloperAccess && myProjects.length > 0 && myProjects.length < allProjects.length;

  const renderTypeOfProjectSelecter = () => {
    return (
      <>
        <button
          onClick={() => setshownProjects("my_projects")}
          className={`button-small mr-4
            ${shownProjects === "my_projects" ? gtw.activeBtn : ""}
          `}
        >
          My Projects
        </button>
        <button
          onClick={() => setshownProjects("all")}
          className={`button-small mr-4
            ${shownProjects === "all" ? gtw.activeBtn : ""}
          `}
        >
          All Projects
        </button>
      </>
    );
  };

  return (
    <div className={"py-8 px-8"}>
      <div className={`font-bold text-gray-700 text-xl mb-4 pl-4`}>Projects</div>

      <div className="flex items-center w-full px-4 mb-6">
        {showProjectTypeSelecter && renderTypeOfProjectSelecter()}
        <div className="flex-grow"></div>
        <SearchBar value={searchWord} onUpdate={(updated) => setSearchWord(updated)} />
        <button
          onClick={() => setAddingProject(true)}
          className={`flex items-center justify-center px-4 py-1 border-2 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
        >
          <AddIcon className="w-4 h-4 mr-4" />
          <span className="font-medium text-xs">Add new project</span>
        </button>
      </div>

      <div className="flex items-center w-full px-4">
        <div className="w-1/4 font-medium text-sm px-2">Project name</div>
        <div className="w-1/4 font-medium text-sm px-2">Most recent</div>
        <div className="w-1/4 font-medium text-sm px-2">Created</div>
        <div className="w-1/6 font-medium text-sm px-2">Scenarios</div>
      </div>

      <div style={{ minHeight: "24rem" }} className="mb-10 relative">
        {currentProjects.map((project, i) => {
          return (
            <CommentableComponent
              key={project.id}
              commentTaget={{
                projectID: project.id,
                projectName: project.projectName,
              }}
              className={`relative my-1`}
            >
              <ProjectRow
                project={project}
                onSelect={() => setProject(project.id, project.projectName)}
                i={i}
                key={project.id}
              />
            </CommentableComponent>
          );
        })}
        {currentProjects.length === 0 && (
          <div className="italic text-sm px-6 mt-4">
            No projects yet - create your first project for it to appear here
          </div>
        )}
      </div>
      {addingProject && (
        <NewProject
          onFinish={(newProject) => {
            setAddingProject(false);
            if (newProject) setProject(newProject.id, newProject.projectName);
          }}
        />
      )}
    </div>
  );
};

const ProjectRow: React.FC<{
  project: Project;
  onSelect: () => void;
  i: number;
}> = ({ project, onSelect, i }) => {
  const description =
    project.description && project.description.length > 0
      ? project.description
      : "No project description..";
  const [editingProject, setEditingProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const { state } = useContext(store);
  const { user } = state;

  const projectType = useProjectType(project.projectType);

  const { statistics } = useProjectStatistics(project.id);

  const copyProjectLinkToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${project.projectName}/${project.id}`
    );
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Direct link to project copied to clipboard", { icon: "success" });
  };

  const deleteProject = async () => {
    if (user?.fbUser.uid === project.ownerId)
      try {
        setLoading(true);
        const updateFields = { deleted: moment() };
        await fs
          .collection("Projects")
          .doc(project.id)
          .update(convertToFirestoreFormat(updateFields));
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        Toast("Error deleting project", { icon: "error" });
        setLoading(false);
      }
  };

  const renderProjectOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={(e) => {
                e.stopPropagation();
                setEditingProject(true);
                closeMe();
              }}
            >
              Edit project
            </button>
            <button
              className={`button-popup border-t`}
              onClick={(e) => {
                e.stopPropagation();
                copyProjectLinkToClipboard();
                closeMe();
              }}
            >
              Copy project link
            </button>
            {user?.fbUser.uid === project.ownerId ? renderDeleteOption(closeMe) : null}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const renderDeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete project?</div>
              <div className="italic mb-2">
                Are you sure you would like to delete this project?
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (!loading) {
                        deleteProject();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`button-popup text-red-500 border-t border-gray-400`}>
          Delete Project
        </button>
      </Popup>
    );
  };

  const mostRecent = statistics.mostRecent?.timeStamp;
  const recentSimDate = mostRecent?.isSame(dayjs(), "year")
    ? mostRecent.format("DD/MM HH:mm")
    : mostRecent?.format("DD/MM YY HH:mm");

  return (
    <>
      <div
        data-test="projectCard"
        onClick={() => onSelect()}
        className={`flex items-center z-10 w-full px-6 py-4 shadow-sm bg-white cursor-pointer relative rounded border border-gray-200 hover:border-gray-300 `}
      >
        <div className="w-1/4 px-2">
          <div className="font-medium">{project.projectName}</div>{" "}
          <div className="text-xs my-1">{description}</div>
          {projectType && <div className="text-xs italic">{projectType.type}</div>}
        </div>
        <div className="w-1/4 px-2 text-xs">{recentSimDate || "No simulations logged"}</div>
        <div className="w-1/4 px-2 text-xs">{project.created.format("DD/MM YYYY")}</div>
        <div className="w-1/6 px-2 text-xs">{project.amountOfScenarios} Scenarios</div>
        <div className="w-1/12 px-2 flex justify-end">{renderProjectOptions()}</div>
        {loading && <LoadingOverlay />}
      </div>
      {editingProject && (
        <EditProject project={project} onFinish={() => setEditingProject(false)} />
      )}
    </>
  );
};

export default HomePage;
