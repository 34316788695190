import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { Popup } from "components/basic/Popup";
import React from "react";

const SmallComponentCard: React.FC<{ onRemove?: () => void }> = ({ children, onRemove }) => {
  const renderOptions = () => {
    return (
      <div className="absolute top-0 right-0 mt-2 mr-4 ">
        <Popup
          align="right"
          content={() => {
            return (
              <>
                {onRemove && (
                  <button className="button-popup text-xs" onClick={() => onRemove()}>
                    Remove
                  </button>
                )}
              </>
            );
          }}
        >
          <DotDotDotIcon />
        </Popup>
      </div>
    );
  };
  return (
    <div className="bg-white px-4 py-4 rounded border border-gray-200 my-4 relative">
      {children}
      {renderOptions()}
    </div>
  );
};

export default SmallComponentCard;
