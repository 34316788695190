import { useAllUserInvitations, useAllUsers } from "api/useFirebase";
import React, { useMemo, useState } from "react";
import SearchBar from "components/basic/SearchBar";
import InvitationModal from "./InvitationModal";
import InvitationRow from "./InvitationRow";
import UserRow from "./UserRow";

const UserAdministration: React.FC<{}> = () => {
  const [searchValue, setSearchValue] = useState("");
  const users = useAllUsers();
  const invitations = useAllUserInvitations();
  const [isOpen, setIsOpen] = useState(false);

  const filteredUsers = useMemo(
    () =>
      users
        .filter((user) => {
          return user.fullName.toLowerCase().includes(searchValue.toLowerCase());
        })
        .sort((a, b) => {
          let a_value = a.deleted
            ? 0
            : a.userRole === "owner"
            ? 4
            : a.userRole === "developer"
            ? 3
            : a.userRole === "simulator"
            ? 2
            : 1;
          let b_value = b.deleted
            ? 0
            : b.userRole === "owner"
            ? 4
            : b.userRole === "developer"
            ? 3
            : b.userRole === "simulator"
            ? 2
            : 1;

          return b_value - a_value;
        }),
    [users, searchValue]
  );

  return (
    <div className={`py-8 px-8 `}>
      <div className="flex mb-3 items-center">
        <div className={`font-bold text-gray-700 text-xl pl-4 flex-grow`}>
          User administration
        </div>

        <div className="mr-4">
          <SearchBar value={searchValue} onUpdate={(updated) => setSearchValue(updated)} />
        </div>

        <div>
          <button
            onClick={() => setIsOpen(true)}
            className="bg-white px-2 py-1 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium"
          >
            Invite user
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md border border-gray-300 rounded-lg flex-none px-4 h-128 overflow-auto pb-2">
        <div className="flex text-xs text-gray-600 flex-1 px-2 py-3 sticky top-0 bg-white font-bold z-20">
          <div className="w-1/5">Name</div>
          <div className="w-2/5">Mail</div>
          <div className="w-1/5 place-self-center">Change role</div>
          <div className="w-1/5"></div>
        </div>
        {users ? (
          filteredUsers.map((user) => {
            return <UserRow key={user.id} user={user} />;
          })
        ) : (
          <h1>No users found!</h1>
        )}
      </div>
      {invitations.length >= 1 && (
        <>
          <div className={`font-bold text-gray-700 text-xl pl-4 flex-grow mt-6 mb-4`}>
            Pending invitations
          </div>
          <div className="bg-white shadow-md border border-gray-300 rounded-lg flex-none px-4 max-h-50vh overflow-auto pb-2">
            <div className="flex text-xs text-gray-600 flex-1 px-2 py-3 sticky top-0 bg-white font-bold z-20">
              <div className="w-1/5">Invitation date</div>
              <div className="w-2/5">Mail</div>
              <div className="w-1/5">User role</div>
              <div className="w-1/5"></div>
            </div>
            {invitations.map((invitation) => {
              return <InvitationRow key={invitation.id} invitation={invitation} />;
            })}
          </div>
        </>
      )}
      {isOpen && (
        <InvitationModal
          users={users}
          invitations={invitations}
          onFinish={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default UserAdministration;
