import React, { useState } from "react";
import { useComponentLibrary } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { ComponentType, Container } from "model/datatypes";
import getUUID from "utils/jsUtils/getUUID";
import useExtensionComponents from "components/extensions/ExtensionComponents";
import { Input } from "components/basic/Input";
import { Button } from "components/basic/Buttons";
import { FMUComponentType } from "components/extensions/FMU/FMU";

interface Props {
  order: number;
  onFinish: () => void;
  onAddComponent: (newComponent: ComponentType) => any;
  onAddContainer?: (newContainer: Container) => void;
  startComp?: ComponentType;
}

const AddComponentType: React.FC<Props> = ({
  onFinish,
  onAddComponent,
  onAddContainer,
  startComp,
  order,
}) => {
  const [newComponent, setNewComponent] = useState<ComponentType | undefined>(
    startComp && {
      ...startComp,
      displayName: `${startComp.displayName} duplicate`,
      name: "",
      type: "",
      id: getUUID(),
    }
  );
  const [compSource, setCompSource] = useState<null | string>(
    startComp ? "From duplicate" : null
  );

  return (
    <Modal onClose={onFinish}>
      <div className="modal-content z-30 ">
        <div className="font-bold mb-4 text-center">Add new component</div>
        <div className="">
          <button
            className="w-full border border-gray-200 rounded h-16 shadow-md"
            onClick={() => {
              setNewComponent(getBlankComponentType());
              setCompSource("From blank");
            }}
          >
            Blank component
          </button>
          <button
            className="mt-2 w-full border border-gray-200 rounded h-16 shadow-md"
            onClick={() => {
              setNewComponent(FMUComponentType);
              setCompSource("FMU");
            }}
          >
            FMU component
          </button>

          <LibraryComponentSelecter
            onSelect={(comp) => {
              setNewComponent(comp);
              setCompSource("From library");
            }}
          />
          {onAddContainer && (
            <AddNewContainer
              onFinish={onFinish}
              onAddContainer={onAddContainer}
              order={order}
            />
          )}
        </div>
        {newComponent && (
          <Modal onClose={() => setNewComponent(undefined)}>
            <AddNewComponent
              startComp={newComponent}
              onFinish={(newComponent) => {
                if (newComponent) {
                  let compTypeToSave: ComponentType = {
                    ...newComponent,
                    order,
                  };
                  onAddComponent(compTypeToSave);
                  onFinish();
                }
              }}
              source={compSource || undefined}
            />
          </Modal>
        )}
      </div>
    </Modal>
  );
};

const getBlankComponentType = () => {
  return {
    id: getUUID(),
    displayName: "",
    name: "",
    type: "",
    parameters: [],
    inputVariables: [],
    item_class: "",
    order: 0,
    instantiationRules: { allowDisabling: false, isMain: true },
  } as ComponentType;
};

export default AddComponentType;

type NewComponentProps = {
  startComp: ComponentType;
  onFinish: (newComponent?: ComponentType) => void;
  source?: string;
};

export const AddNewComponent: React.FC<NewComponentProps> = ({
  startComp,
  onFinish,
  source,
}) => {
  const [newComponent, setNewComponent] = useState<ComponentType>(startComp);

  return (
    <div className="w-64 md:w-128 modal-content z-30">
      <div className="font-bold text-center">Add new component</div>
      {source && <div className="italic text-xs">{`${source}`}</div>}
      <div className="text-xs mt-4">Display Name</div>
      <Input
        autoFocus
        type="text"
        className={`w-full`}
        onChange={(e) => {
          setNewComponent({ ...newComponent, displayName: e.target.value });
        }}
        value={newComponent.displayName}
      />

      <div className="text-xs mt-4">Name/ID</div>

      <Input
        type="text"
        readOnly={startComp.isFixed}
        className={`w-full ${startComp.isFixed ? "opacity-75" : ""}`}
        onChange={(e) => {
          setNewComponent({ ...newComponent, name: e.target.value, type: e.target.value });
        }}
        value={newComponent.name}
      />

      <Button
        className={`mt-4 w-full`}
        onClick={() => {
          onFinish(newComponent);
        }}
      >
        Add to system
      </Button>
    </div>
  );
};

interface LibraryComponentSelecterProps {
  onSelect: (libComp?: ComponentType) => void;
}
export const LibraryComponentSelecter = ({ onSelect }: LibraryComponentSelecterProps) => {
  const { componentLib } = useComponentLibrary();
  const extensionComponents = useExtensionComponents();
  const [selectedComp, setSelectedComp] = useState<ComponentType | null>(null);
  const [libraryOpen, setLibraryOpen] = useState(false);

  const renderLibrayComp = (libComp: ComponentType) => {
    const isSelected = libComp.id === selectedComp?.id;
    return (
      <div
        className={`mr-4 mb-4 shadow-md rounded cursor-pointer border  hover:opacity-100
              ${isSelected ? "ring-2 ring-blue-400 border-blue-400" : "border-gray-300"}
              ${!isSelected && !!selectedComp ? "opacity-75" : ""}
            `}
        onClick={() => setSelectedComp(isSelected ? null : libComp)}
      >
        <div className={`px-2 py-2`}>
          <div className="font-medium">{libComp.displayName}</div>
          <div className="text-xs mt-3">
            {libComp.parameters ? libComp.parameters.length : 0} parameters
          </div>
          <div className="text-xs">{libComp.inputVariables.length} input variables</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <button
        className="w-full border border-gray-200 rounded h-16 mt-2 shadow-md"
        onClick={() => {
          setLibraryOpen(true);
        }}
      >
        Library component
      </button>
      {libraryOpen && (
        <Modal
          onClose={() => {
            setLibraryOpen(false);
          }}
        >
          <div className="modal-content z-50 w-1/2">
            <div className="mb-2">Component library</div>
            <div className="flex flex-wrap mb-2">
              {extensionComponents.length > 0 &&
                extensionComponents.map(({ type }) => renderLibrayComp(type))}
              {componentLib?.length > 0 && componentLib.map(renderLibrayComp)}
            </div>

            <div>
              <button
                className={`button-small ${selectedComp ? "" : "opacity-50"} mr-2`}
                onClick={() => {
                  if (selectedComp) {
                    onSelect(selectedComp);
                    setLibraryOpen(false);
                  }
                }}
              >
                Ok
              </button>
              <button className={"button-small"} onClick={() => setLibraryOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const AddNewContainer: React.FC<{
  onFinish: () => void;
  onAddContainer: (newContainer: Container) => void;
  order: number;
}> = ({ order, onAddContainer, onFinish }) => {
  const [newContainer, setNewContainer] = useState<Container>(getBlankContainer());
  const [addingContainer, setAddingContainer] = useState(false);
  const renderAddingContainer = () => {
    return (
      <Modal onClose={() => setAddingContainer(false)}>
        <div className="modal-content z-30 w-1/2">
          <div className="font-bold text-center">Add new container</div>
          <div className="text-xs mt-4">Display Name</div>
          <Input
            type="text"
            className={"w-full"}
            onChange={(e) => {
              setNewContainer({ ...newContainer, displayName: e.target.value });
            }}
            value={newContainer.displayName}
          />
          <div className="text-xs mt-4">Name/ID</div>
          <Input
            type="text"
            className={"w-full"}
            onChange={(e) => {
              setNewContainer({ ...newContainer, name: e.target.value });
            }}
            value={newContainer.name}
          />
          <Button
            className={`mt-4 w-full`}
            onClick={() => {
              let containerForSaving: Container = {
                ...newContainer,
                order,
              };
              onAddContainer(containerForSaving);
              onFinish();
            }}
          >
            Add to system
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <button
        className="w-full border border-gray-200 rounded h-16 mt-2 shadow-md"
        onClick={() => {
          setAddingContainer(true);
        }}
      >
        Container
      </button>
      {addingContainer && renderAddingContainer()}
    </>
  );
};

const getBlankContainer = () => {
  return {
    displayName: "",
    id: getUUID(),
    name: "",
    order: 0,
    componentTypeIDs: [],
    instantiationRules: { allowDisabling: false, isMain: true },
  } as Container;
};
