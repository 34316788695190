import { useFirebase } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import LinkIcon from "components/basic/icons/LinkIcon";
import MailIcon from "components/basic/icons/MailIcon";
import UserRoleIcon from "components/basic/icons/UserRoleIcon";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import dayjs from "dayjs";
import { Invitation, User } from "model/datatypes";
import React, { useState } from "react";
import { useGlobalState } from "store";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { copyToClipboard } from "utils/jsUtils/CopyToClipBoard";

export interface InvitationModalProps {
  onFinish: () => void;
  users: User[];
  invitations: Invitation[];
}

const InvitationModal: React.FC<InvitationModalProps> = ({ onFinish, users, invitations }) => {
  const { gproject, user } = useGlobalState();
  const [inputMail, setInputMail] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState<string>();

  const fb = useFirebase();
  const [invitationLink, setInvitationLink] = useState<null | string>(null);
  const [inputError, setInputError] = useState(false);

  const roleOptions = [
    { id: "developer", display: "Developer" },
    { id: "simulator", display: "Simulator" },
    { id: "guest", display: "Guest" },
  ];

  const generateInvitationLink = (invitationMail: string) => {
    if (
      users.some((user) => user.mail === invitationMail) ||
      invitations.some((invitation) => invitation.mail === invitationMail)
    ) {
      Toast("This mail is already in use", { icon: "error" });
      return;
    }
    if (!gproject) {
      Toast("There was an issue with the organisation ID", { icon: "error" });
      return;
    }
    if (!selectedUserRole || !user) return;

    const invitationDoc = fb.firestore().collection("invitations").doc();

    const invitation: Invitation = {
      id: invitationDoc.id,
      mail: inputMail,
      userRole: selectedUserRole,
      created: dayjs(),
      invitationLink: `signup/${gproject}/${invitationDoc.id}`,
      organisation: user.organisation,
    };

    invitationDoc
      .set(convertToFirestoreFormat(invitation))
      .then(() => {
        const link = `${window.location.protocol}//${window.location.host}/${invitation.invitationLink}`;
        copyToClipboard(link);
        setInvitationLink(link);
        // Finishing
        Toast("Invitation link copied to clipboard", { icon: "success" });
        setInputError(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className={`modal-content z-50 p-6`}>
        {!invitationLink && (
          <div>
            <div className="text-xl text-center mb-2">Add collaborator to organisation</div>
            <div className="text-center text-xs">
              This will generate an invitation link you can send to the new collaborator
            </div>
            <div className="px-4 mt-4 flex relative items-center bg-gray-100 rounded">
              <span className="mr-2">
                <MailIcon />
              </span>
              <input
                aria-label="Mail input"
                value={inputMail}
                onChange={(e) => setInputMail(e.target.value)}
                type="email"
                className="py-1 mr-2 h-8 text-sm outline-none w-full bg-gray-100"
              ></input>
            </div>
            <div className="pl-4 mt-4 flex relative items-center bg-gray-100 rounded">
              <span>
                <UserRoleIcon />
              </span>
              <Dropdown
                headlessStyle
                className="py-1 h-8 text-sm outline-none w-full bg-gray-100 pl-3 pr-2"
                selectedID={selectedUserRole}
                placeholder={"Select user role"}
                options={roleOptions}
                onSelect={(option) => setSelectedUserRole(option.id)}
              />
            </div>

            <button
              onClick={() => {
                if (inputMail) generateInvitationLink(inputMail);
              }}
              className={`${
                selectedUserRole && inputMail ? "bg-blue-400" : "bg-blue-200"
              } mt-4 w-full p-1 shadow rounded border text-white border-blue-200 focus:outline-none text-sm font-bold hover:font-medium`}
            >
              Generate invitation link
            </button>
          </div>
        )}

        {inputError && (
          <div className="text-red-600 text-sm mt-2">Mail and user role are required.</div>
        )}
        {invitationLink && (
          <>
            <div className="flex items-center w-64">
              <LinkIcon></LinkIcon>
              <div className="ml-2 text-sm">Invitation link</div>
            </div>
            <textarea
              className="resize-none overflow-auto w-full mt-4"
              readOnly
              id="link"
              value={invitationLink}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default InvitationModal;
