//@ts-nocheck Reenamble once new report file structure done!

import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useScenario, useProject } from "api/useFirebase";
import LoadingOverlay from "./basic/LoadingOverlay";
import ReportEditor from "./simulations/reportEditor/ReportEditor";
import { store } from "store";
import { ScenarioOutputFile } from "model/datatypes";

interface Props {}

export const ReportViewPage: React.FC<Props> = () => {
  const { projectID, scenarioID, fileName } =
    useParams<{
      projectID: string | undefined;
      scenarioID: string | undefined;
      fileName: string | undefined;
    }>();
  const scenario = useScenario(projectID, scenarioID);
  const project = useProject(projectID);
  const { dispatch } = useContext(store);

  const viewedFile: ScenarioOutputFile | undefined = useMemo(() => {
    const file = scenario?.output_files[fileName];
    return file;
  }, [scenario, fileName]);

  useEffect(() => {
    if (scenario) document.title = scenario.scenarioName;
  }, [scenario]);

  useEffect(() => {
    if (project)
      dispatch({
        type: "SET_CURRENT_PROJECT",
        payload: { projectID: project.id, projectName: project.projectName },
      });
  }, [project, dispatch]);

  if (!projectID || !scenarioID || (scenario && !viewedFile))
    return <div className="px-4 py-4 italic text-red-600">Error loading reports</div>;
  else
    return (
      <div className="fixed top-0 left-0 h-screen w-screen bg-white z-50">
        {!scenario && <LoadingOverlay />}
        {viewedFile && (
          <ReportEditor
            report_download={viewedFile}
            sourcetype="scenario"
            savedChanges={
              undefined
              // scenario.reportChanges
            }
            sourceID={scenario.id}
            useModal={false}
            reportName={`${project?.projectName}_${scenario.scenarioName}`}
          />
        )}
      </div>
    );
};
