import { DropdownAtRoot } from "components/basic/Dropdown";
import InputNumber from "components/basic/headless/InputNumber";
import ScheduleDayGraph from "components/input/newSchedule/components/ScheduleDayGraph";
import ScheduleInput from "components/input/newSchedule/ScheduleInput";
import { DatasetScenario, InputVar, Schedule, PeriodSetting } from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalState } from "store";
import { useAllSchedules, useDefaultScheduleDataset } from "./useSchedule";

const ScheduleVariableBuilder: React.FC<{
  scheduleInputVar: InputVar;
  selectSchedule: (selected: { dataset: DatasetScenario; schedule: Schedule }) => void;
  updateScheduleVariable: (updated: Partial<InputVar>) => void;
}> = ({ scheduleInputVar, selectSchedule, updateScheduleVariable }) => {
  const { projectID } = useGlobalState();

  const allSchedules = useAllSchedules();
  const defaultScheduleDataset = useDefaultScheduleDataset();

  const [editingSchedule, setEditingSchedule] = useState<{
    dataset: DatasetScenario;
    schedule: Schedule;
  } | null>(null);
  const [addingNewSchedule, setAddingNewSchedule] = useState(false);

  const selectedSchedule = useMemo(() => {
    return allSchedules.find(
      (ds) => ds.dataset.id === scheduleInputVar?.tagSource?.scenarioID
    );
  }, [allSchedules, scheduleInputVar]);

  if (!projectID) return null;

  return (
    <>
      <div className="w-full">
        <ScheduleSelecter
          dhwScheduleDatasets={allSchedules}
          selectedSchedule={
            scheduleInputVar?.tagSource
              ? `${scheduleInputVar.tagSource.scenarioID}${scheduleInputVar.tagSource.tag}`
              : undefined
          }
          setSchedule={selectSchedule}
          addNew={() => {
            setAddingNewSchedule(true);
          }}
        />
      </div>
      {selectedSchedule && (
        <ScheduleInstance
          schedule={selectedSchedule.schedule}
          scaling={scheduleInputVar?.scaling || 0}
          setScaling={(updatedScaling) => {
            updateScheduleVariable({ scaling: updatedScaling });
          }}
          offset={scheduleInputVar?.offset || 0}
          setOffset={(updatedOffset) => {
            updateScheduleVariable({ offset: updatedOffset });
          }}
          onEdit={() => setEditingSchedule(selectedSchedule)}
        />
      )}
      {addingNewSchedule && (
        <ScheduleInput
          dataset={defaultScheduleDataset}
          onFinished={(scheduleDataset) => {
            console.log("on finish schedule");
            console.log({ scheduleDataset });
            if (scheduleDataset)
              selectSchedule({
                dataset: scheduleDataset.dataset,
                schedule: scheduleDataset.schedule,
              });
            setAddingNewSchedule(false);
          }}
        />
      )}
      {editingSchedule && (
        <ScheduleInput
          dataset={editingSchedule.dataset}
          startSchedule={editingSchedule.schedule}
          onFinished={(scheduleDataset) => {
            if (scheduleDataset)
              selectSchedule({
                dataset: scheduleDataset.dataset,
                schedule: scheduleDataset.schedule,
              });
            setEditingSchedule(null);
          }}
        />
      )}
    </>
  );
};

export default ScheduleVariableBuilder;

export const ScheduleInstance: React.FC<{
  scaling: number;
  offset: number;
  setScaling: (updated: number) => void;
  setOffset: (updated: number) => void;
  schedule: Schedule;
  onEdit: () => void;
}> = ({ offset, scaling, setOffset, setScaling, schedule, onEdit }) => {
  const [viewingPeriod, setViewingPeriod] = useState<PeriodSetting | null>(null);
  useEffect(() => {
    setViewingPeriod(schedule.periodSettings[0] || null);
  }, [schedule]);

  return (
    <div className="border border-gray-200 shadow-md">
      <div className="flex items-center text-xs bg-gray-100 px-1 py-1">
        <div className="flex-grow">
          {schedule.periodSettings.map((period) => {
            const active = viewingPeriod?.id === period.id;

            return (
              <button
                onClick={() => {
                  setViewingPeriod(active ? null : period);
                }}
                className={`${
                  active ? "bg-green-numerous text-white" : "hover:bg-green-100"
                } px-3 py-1 mr-1 text-sm focus:outline-none rounded`}
              >
                {period.type}
              </button>
            );
          })}
        </div>
        <div className="flex items-center">
          <div className="mr-1">Scaling </div>
          <InputNumber
            className="input-box w-24 mr-6"
            value={scaling}
            onChange={(updated) => {
              setScaling(updated);
            }}
          />

          <div className="mr-1">Offset </div>
          <InputNumber
            className="input-box w-24 mr-6"
            value={offset}
            onChange={(updated) => {
              setOffset(updated);
            }}
          />
          <button
            className="focus:outline-none mr-4 hover:underline text-blue-600"
            onClick={() => onEdit()}
          >
            Edit schdule
          </button>
        </div>
      </div>
      <div className="w-full">
        {viewingPeriod && <ScheduleDayGraph period={viewingPeriod} />}
      </div>
    </div>
  );
};

export const ScheduleSelecter: React.FC<{
  selectedSchedule?: string;
  setSchedule: (newSchedule: { dataset: DatasetScenario; schedule: Schedule }) => void;
  addNew: () => void;
  dhwScheduleDatasets: {
    dataset: DatasetScenario;
    schedule: Schedule;
  }[];
}> = ({ setSchedule, selectedSchedule, addNew, dhwScheduleDatasets }) => {
  const scheduleOptions = useMemo(() => {
    return dhwScheduleDatasets.map((schedule) => ({
      display: `${schedule.schedule.tag} (${schedule.dataset.scenarioName})`,
      id: `${schedule.dataset.id}${schedule.schedule.tag}`,
      value: schedule,
    }));
  }, [dhwScheduleDatasets]);

  return (
    <div className="my-4 w-full">
      <div className="text-xs font-bold mb-1">Selected schedule</div>
      <DropdownAtRoot
        placeholder="Select a schedule"
        selectedID={selectedSchedule}
        className="bg-white text-xs w-full"
        options={scheduleOptions}
        onSelect={(option) => {
          setSchedule(option.value);
        }}
        onAddNew={addNew}
      />
    </div>
  );
};
