import { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import HoverTooltip from "./HoverTooltip";
import { Popup } from "./Popup";

interface Props {
  startDate: Dayjs;
  endDate: Dayjs;
  startRange?: Dayjs;
  endRange?: Dayjs | "continuous";
  displayDates?: true;
  endStrategy: "repeat" | "wait" | "end";
  color?: "blue";
}

const TimeRangeDisplayLine: React.FC<Props> = ({
  startDate,
  endDate,
  endRange,
  startRange,
  displayDates,
  color,
  endStrategy,
}) => {
  const { after, before, during } = useMemo(() => {
    let before = 0;
    let after = 0;

    let rangeStart = startRange?.valueOf() || startDate.valueOf();
    let rangeEnd =
      typeof endRange === "string"
        ? endDate.valueOf() + (endDate.valueOf() - rangeStart) / 4
        : endRange?.valueOf() || endDate.valueOf();
    const totalTR = rangeEnd - rangeStart;

    if (startRange) {
      before = ((startDate.valueOf() - startRange.valueOf()) / totalTR) * 100;
    }
    if (endRange) {
      after = ((rangeEnd - endDate.valueOf()) / totalTR) * 100;
    }
    let during = 100 - before - after;

    return { before, during, after };
  }, [startDate, endDate, endRange, startRange]);

  const renderFullDateTime = (date: Dayjs, position: "center" | "right" | "left") => {
    return (
      <div
        className={`flex flex-col text-xxs leading-tight w-16 py-1 ${
          position === "center" ? " items-center" : position === "right" ? "items-end" : ""
        }`}
      >
        <div>{date.format("HH:mm")}</div>
        <div>{date.format("DD/MM YYYY")}</div>
      </div>
    );
  };
  const renderDateIcon = (date: Dayjs) => {
    return (
      <div className="text-xxs">
        <Popup
          useHover
          width={"5rem"}
          mt={0}
          align="left"
          content={() => {
            return renderFullDateTime(date, "center");
          }}
        >
          <div>{date.format("DD/MM YYYY")}</div>
        </Popup>
      </div>
    );
  };

  const endStrategyDecription = useMemo(() => {
    if (endStrategy === "repeat") return "Will repeat.";
    if (endStrategy === "wait") return "Will wait for further data";
    else return "End of simulation";
  }, [endStrategy]);

  return (
    <div className="flex items-center pt-2 pb-6">
      <div style={{ width: `${before}%` }} className="border-b-4 border-gray-200"></div>
      <div
        style={{ width: `${during}%` }}
        className={`border-b-4  relative ${color ? "border-blue-400" : "border-green-400"} `}
      >
        <div
          className={`absolute top-0 mt-2 left-0 ${
            before > 0 ? (displayDates ? "-ml-8" : "-ml-3") : ""
          } `}
        >
          {displayDates
            ? renderFullDateTime(startDate, before > 0 ? "center" : "left")
            : renderDateIcon(startDate)}
        </div>
        <div
          className={`absolute top-0 mt-2 right-0 ${
            after > 0 ? (displayDates ? "-mr-8" : "-mr-3") : ""
          }`}
        >
          {displayDates
            ? renderFullDateTime(endDate, after > 0 ? "center" : "right")
            : renderDateIcon(endDate)}
        </div>
      </div>
      <HoverTooltip
        mt={-30}
        text={endStrategyDecription}
        style={{ width: `${after}%` }}
        className={`border-b-4   
        ${endStrategy === "wait" ? "border-dashed" : ""}
        ${
          endStrategy === "end"
            ? "border-gray-300"
            : color
            ? "border-blue-300"
            : "border-green-300"
        }`}
      />
    </div>
  );
};

export default TimeRangeDisplayLine;
