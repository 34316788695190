import { Invitation, SimFile, SimulationScenario } from "model/datatypes";
import firebase from "firebase/app";
import {
  convertFromFirestoreFormatNew,
  convertToFirestoreFormat,
} from "../../utils/firebase/firestoreFormatter";
import dayjs from "dayjs";

export const newProject = (
  fs: firebase.firestore.Firestore,
  projectName: string,
  description: string,
  ownerId: string
) => {
  const batch = fs.batch();

  //create new project
  const projectDoc = fs.collection("Projects").doc();
  batch.set(projectDoc, {
    projectName,
    description,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    deleted: null,
    ownerId,
    teams: [],
    collaborators: [],
  });

  return new Promise((res, rej) => {
    batch
      .commit()
      .then(() => res(projectDoc.id))
      .catch((error) => rej(error));
  });
};

export const newTeam = (fs: firebase.firestore.Firestore, name: string, ownerId: string) => {
  const batch = fs.batch();

  const teamDoc = fs.collection("teams").doc();
  batch.set(teamDoc, {
    name,
    deleted: null,
    ownerId,
    teams: [],
    collaborators: [],
  });
  return batch.commit();
};

export const saveFileInFs = (
  fs: firebase.firestore.Firestore,
  uploadedFile: SimFile,
  tags: string[]
) => {
  const batch = fs.batch();
  const organisationFileLib = fs.collection("FileLibrary").doc(uploadedFile.organisation);

  const fileLibDoc = organisationFileLib.collection("files").doc(uploadedFile.id);
  batch.set(fileLibDoc, uploadedFile); //store the file reference

  batch.update(organisationFileLib, {
    tags: firebase.firestore.FieldValue.arrayUnion(...tags),
    types: firebase.firestore.FieldValue.arrayUnion(uploadedFile.type),
  });

  return batch.commit();
};

export const getScenario = async (
  projectID: string,
  scenarioID: string,
  fs: firebase.firestore.Firestore
) => {
  const scenarioDoc = await fs
    .collection("Projects")
    .doc(projectID)
    .collection("Scenarios")
    .doc(scenarioID)
    .get();

  if (scenarioDoc.exists) {
    return convertFromFirestoreFormatNew({
      ...scenarioDoc.data(),
      id: scenarioDoc.id,
    }) as SimulationScenario;
  }
};

export const getInvitation = async (inviteID: string, fs: firebase.firestore.Firestore) => {
  const doc = await fs.collection("invitations").doc(inviteID).get();

  if (!doc.exists) throw new Error("Invitaion does not exist");
  const invitation = convertFromFirestoreFormatNew({
    id: doc.id,
    ...doc.data(),
  }) as Invitation;
  return invitation;
};

export const removeProjectType = (fs: firebase.firestore.Firestore, projectTypeID: string) => {
  return fs
    .collection("ProjectTypes")
    .doc(projectTypeID)
    .update(convertToFirestoreFormat({ deleted: dayjs() }));
};
