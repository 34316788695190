const firebaseConfig = {
  apiKey: "AIzaSyDVDsZ6VsLKmSlDYU8ay8GzPJLkD9ObcCo",
  authDomain: "simulationwidgets.firebaseapp.com",
  databaseURL: "https://simulationwidgets.firebaseio.com",
  projectId: "simulationwidgets",
  storageBucket: "simulationwidgets.appspot.com",
  messagingSenderId: "220155658705",
  appId: "1:220155658705:web:4505913e62834d434720f0",
  measurementId: "G-ME02E4BB1M",
};

export default firebaseConfig;
