import Modal from "components/basic/Modal";
import { coy as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import React from "react";

interface Props {
  onClose: () => void;
}

const Datatypes = (props: Props) => {
  return (
    <Modal onClose={props.onClose}>
      <div
        className="z-30 w-3/4 bg-white rounded border border-gray-200 shadow-lg px-4 py-4 flex flex-col"
        style={{ maxHeight: "90vh" }}
      >
        <div className="text-lg pb-2 border-b border-gray-200">All datatypes</div>
        <div className="px-4 py-2 text-xs w-full flex-grow overflow-auto">
          <SyntaxHighlighter
            showLineNumbers
            className={`w-full`}
            language={"typescript"}
            style={prismStyle}
            // customStyle={{ backgroundColor: "#5A5A5A", color: "#fff" }}
          >
            {tsTypes}
          </SyntaxHighlighter>
        </div>
      </div>
    </Modal>
  );
};

export default Datatypes;

const tsTypes = `

//////////////////////////////////////////////
//////////  SIMULATION management  ///////////
//////////////////////////////////////////////

//#region SIMULATION management

export type Project = {
  projectName: string;
  id: string;
  created: Dayjs;
  deleted: Dayjs | null;
  ownerId: string;
  description?: string;
  scenarios: string[];
  collaborators: string[];
  teams: string[];
  amountOfScenarios: number;
  projectType?: string;
  parameters?: ComponentParameter[]; //will contain project level settings etc.
};

export type ProjectType = {
  id: string;
  type: string;
  description?: string;
  collaborators: string[];
  teams: string[];
  constrains: "system" | "groups";
  fixedGroups?: FixedGroup[];
  systems?: string[];
  parameters?: ComponentParamType[];
};

export type FixedGroup = {
  id: string;
  groupName: string;
  systems: string[];
  parameters?: ComponentParamType[];
};

export type Group = {
  id: string;
  ownerId: string;
  created: Dayjs;
  groupName: string;
  scenarios: string[];
  order?: number;
  description?: string;
  parameters?: ComponentParameter[]; //will contain group level settings etc.
};

export type Dataset = {
  id: string;
  projectID: string;
  datasetName: string;
  description?: string;
  tags: string[];
};

export type SimulationScenario = {
  id: string;
  groupID: string;
  projectID: string;
  scenarioName: string;
  ownerId: string;
  created: Dayjs;
  description?: string;
  systemID: string; //what system does the scenario created from.

  //inputs:
  datasets: { projectID: string; datasetID: string }[];
  inputScenarios: { projectID: string; scenarioID: string }[];
  CMConnections?: CMConnection[]; // change to string ref!

  //scenario state setup:
  parameters: ComponentParameter[]; //will contain scenario level settings etc.
  simComponents: Component[];

  //simulation state:
  status:
    | "incomplete"
    | "Components Incomplete"
    | "ready"
    | "requested"
    | "running"
    | "finished";
  jobs: SimulationJob[];

  //outputs
  output_files?: ScenarioOutputFile[];
  filePublished?: ScenarioOutputFile;
  dataTags: string[]; //All data for this scenario
  results?: { colName: string; value: number; unit: string }[];
  logs: { message: string; imageID: string; timestamp: Dayjs }[];

  //internal SPM:
  log_metadata?: {
    //For scheduler
    simulationStartTime?: Dayjs;
    simStartUser?: string;
    processorStartTime?: Dayjs;
    processorStartUser?: string;
  };
  scheduled?: string[];
  extensionData?: any;
  SPM_version: 2; //to verify scenario is new format
};

export type ScenarioStateEdited = SimulationScenario & {
  changed?: boolean;
  pendingSync?: boolean;
  componentTypes?: ComponentType[];
  fullInputScenarios?: SimulationScenario[];
  fullCMHarvesters?: FullCMConnnection[];
  fullDatasets?: Dataset[];
};

export type SimulationJob = {
  image: SimulationImage;
  status: JobStatus;
};

export type JobStatus = { status: JobStatusStates; message?: string; progress?: number };

export type ScenarioOutputFile = {
  fileType: string;
  fileName: string;
  bucket_id: string;
  report_path: string;
  timestamp: Dayjs;
};

export type JobStatusStates =
  | "ready"
  | "running"
  | "finished"
  | "request_termination"
  | "terminated"
  | "failed"
  | "requested"
  | "initializing"
  | "Environment Initializing"
  | "Model Initializing"
  | "Equations Assembly";

export type ScheduledSimulationTask = {
  id: string;
  launchTime: Dayjs;
  projectID: string;
  scenarioID: string;
  userID?: string;
  image: SimulationImage;
  runInterval?: { secconds: number; displayUnit: SimTimeUnit; displayValue: string };
};

//#endregion

//////////////////////////////////////////////
//////////  SIMULATION Components  ///////////
//////////////////////////////////////////////

//#region Simulation Components

export type Component = {
  uuid: string;
  displayName: string;
  id: string;
  name: string;
  type: string;
  item_class: string;
  disabled: boolean;
  parameters: ComponentParameter[];
  inputVariables: InputVar[];
  subcomponents?: Subcomponent[];
  fixed?: boolean;
  order: number;
  isMainComponent: boolean;
  containerID?: string;
};

export type ComponentType = {
  displayName: string;
  id: string;
  name: string;
  type: string;
  item_class: string;
  order: number;
  parameters: ComponentParamType[];
  inputVariables: InputVar[];
  instantiationRules: {
    isMain: boolean;
    allowDisabling: boolean;
    defaultEnabled?: boolean;
    min?: number;
    max?: number;
  };
  subCompRules?: SubCompRules[];
  modelID?: string;
  fixed?: boolean;
  version?: number;
};

export type Container = {
  displayName: string;
  id: string;
  name: string;
  order: number;
  instantiationRules: {
    isMain: boolean;
    allowDisabling: boolean;
    defaultEnabled?: boolean;
    min?: number;
    max?: number;
  };
  componentTypeIDs: string[];
  defaultComponentType?: string;
};

export type SubCompRules = {
  id: string;
  fixed?: boolean;
  default: number;
  min: number;
  max: number | null;
};

export type ComponentTypeReference = {
  id: string;
  displayName: string;
};

export type Subcomponent = {
  id: string;
  uuid: string;
};

export type ComponentParameter = {
  uuid: string;
  value: number | string | boolean | null | FileRef | ParameterTable | TimeValue | JSONValue;
  type:
    | "number"
    | "string"
    | "boolean"
    | "selecter"
    | "month"
    | "config"
    | "reference"
    | "file"
    | "table"
    | "time_value"
    | "json";
  id: string;
  displayName: string;
  tooltip?: string;
  optional?: boolean;
  displayMode?: "normal" | "fixed" | "advanced" | "hidden";
  tag?: string;
  displayValue?: string;
};

export type JSONValue = {
  [key: string]: any;
};

export type ComponentParamType = ComponentParameter & {
  fileQuery?: FileQuery;
  tableColumns?: { uuid: string; colKey: string; colDisplay: string }[];
  selecterOptions?: { id: string; display: string; value: string | number }[];
};

export type ParameterTable = { [key: string]: number[] };

export type TimeValue = { value: number; unit: SimTimeUnit };

export type SimTimeUnit = "months" | "days" | "hours" | "minutes" | "seconds";

export type InputVar = {
  uuid: string;
  value: number;
  display: string;
  tooltip?: string;
  id: string;
  dataSourceType: "static" | "csv" | "scenario" | "control_machines";
  dataSourceID?: string; //id of csv, scenario or harvester.
  tagSource?: ScenarioDataTag; //<- use to get data from BIGTABLE
  // source?: InputVariableReference; //Remove
  CMTag?: CMTag; //Reference in controlmachines
  unit?: string;
  scaling: number;
  offset: number;
  mode?: "fixed" | "hidden";
};

export type ScenarioDataTag = {
  tag: string;
  projectID: string;
  scenarioID: string;
};

//#endregion

//////////////////////////////////
//////////  SIM model  ///////////
//////////////////////////////////

//#region Sim model

export type SimulationModel = {
  id: string;
  displayName: string;
  ownerId: string;
  images: SimulationImage[]; //<-Images that can run!
  version: number;
  status: "published" | "draft";
  latestEdited?: { userId: string; time: Dayjs };
  collaborators: string[];
  teams: string[];
  parameters: ComponentParamType[];
  // inputs?: InputVar;
  componentTypes: ComponentType[];
  containers: Container[];
  SPM_version: 2;
};

export type SimulationImage = { id: string; name: string; path: string; isMain: boolean };

export type LocalSimulationModel = {
  localVersion: number;
} & SimulationModel;

export type PostProcessor = {
  id: string;
  name: string;
  path: string;
  reference: string | "master";
};

//#endregion

//////////////////////////////////
//////////   LOGGING   ///////////
//////////////////////////////////

//#region logging

export type ProjectLog = {
  id: string;
  users: string[];
  log_simulation_started?: LogEvent[];
  log_simulation_finished?: LogEventTimed[];
  log_simulation_error?: LogEventTimed[];
  log_processor_started?: LogEvent[];
  log_processor_finished?: LogEventTimed[];
  log_processor_error?: LogEventTimed[];
};
export type LogEvent = {
  scenarioID: string;
  timeStamp: Dayjs;
  userID: string;
};
export type LogEventTimed = LogEvent & { runTime: number };

//#endregion

///////////////////////////////
//////////  INPUTS  ///////////
///////////////////////////////

//#region Inputs

export type InputData = {
  variableName: string;
  variableType: "string" | "number";
  values: (string | number)[];
};

export type VariableReference = { id: string; variableName: string; origin?: string }; // local reference used in source

//full reference to source and var:
export type InputVariableReference = {
  id: string;
  variableName: string;
  sourceID: string;
  parameterID: string;
};

//reference to the harvesterTag:
export type HarvesterTagReference = {
  tagID: string;
  harvesterID: string;
  displayName: string;
};

//Generic harvester (To be later implemented)
export type Harvester = {
  id: string;
  url: string;
  harvesterType: "API";
  requestType: "POST" | "GET";
  params: { [key: string]: any };
};

//Controll machines harvester
export type CMConnection = {
  id: string;
  projectID: string;
  url: "https://controlmachines.cloud/api/v1/";
  harvesterType: "control_machines";
  requestType: "POST";
  name: string;
  com_projectID: string;
  tagDocs: string[];
  tokenID: string; //link to FS-doc with the actual token
  params?: {
    groupingsType: "MINUTES" | "HOURS";
    tagList: string[];
    timeFrom: number;
    timeTo: number;
  };
};

export type FullCMConnnection = CMConnection & {
  tags: CMTag[];
};

export type CMTag = { id: string; path: string; isWritable: boolean; unit: string };

////////////////////////////////
//////////  RESULTS  ///////////
////////////////////////////////

export type ResultDataset = {
  id: string;
  scenario: string;
  model_id: string;
  resultSetName: string;
  resultsMeta: { key: string; id: string; data_type: "string" | "number" }[];
};

// export type Result = StringResult;

// export type StringResult = {
//   data: string;
//   data_type: "string";
//   included: boolean;
//   resultName: string;
// };

export type RawDataReport = {
  id: string;
  name: string;
  lineGraphs: { id: string; fields: string[] }[];
  piDiagrams: PIDiagram[];
  localVersion: number;
  globalVersion: number;
};

export type ReportChange = {
  id: string;
  innerHTML: string;
  original: string;
  pos: { top: number; left: number };
};

//#endregion

//////////////////////////////
//////////  FILES  ///////////
//////////////////////////////

//#region Files

export type FileQuery = {
  tags: string[];
  type?: string;
  projectRef?: { id: string; displayName: string };
  modelRef?: { id: string; displayName: string };
};

export type SimFile = {
  id: string;
  path: string;
  name: string;
  tags: string[];
  projectID?: string;
  modelID?: string;
  type: string;
};

export type FileRef = {
  id: string;
  path: string;
  name: string;
};

//#endregion

`;
