import { useFirestore } from "api/useFirebase";
import { InputScenarioRef, ScenarioStateEdited, SimulationScenario } from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import RadioButton from "components/basic/RadioButton";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { useDataInfo, useGRPCData } from "grpc/grpcReact";
import Plot from "react-plotly.js";
import { PlotData } from "plotly.js";
import dayjs from "dayjs";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const EnergyDemandSelecter: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const selectedEnergyDemand = useMemo(() => {
    if (scenarioState.inputScenarios.length > 0) return scenarioState.inputScenarios[0];
    else return undefined;
  }, [scenarioState.inputScenarios]);
  const energyDemandScenarios = useEnergyDemandsScenarios(scenarioState.projectID);

  const renderEnergyDemandSelectTable = () => {
    return (
      <div className="border-gray-200 text-xs border-b mb-4">
        <div className="flex w-full font-medium border">
          <Col>Select Energy Demand</Col>
          <Col>Scenario Name</Col>
          <Col>Heating</Col>
          <Col>Cooling</Col>
          <Col>Hot water</Col>
        </div>
        {energyDemandScenarios.map((scenario) => {
          const isSelected = selectedEnergyDemand?.scenarioID === scenario.id;
          return (
            <div
              key={scenario.id}
              className={`flex w-full border-t border-r border-l cursor-pointer ${
                isSelected ? "border border-green-numerous" : ""
              }`}
              onClick={() => {
                const scenarioRef: InputScenarioRef = {
                  projectID: scenario.projectID,
                  scenarioID: scenario.id,
                  type: "scenario",
                };
                scenarioDispatch({
                  type: "UPDATE_SCENARIO_INPUTS",
                  payload: isSelected ? [] : [scenarioRef],
                });
              }}
            >
              <Col className={`flex justify-center ${isSelected ? "border-white" : ""}`}>
                <RadioButton active={isSelected} />
              </Col>
              <Col className={isSelected ? "border-white" : ""}>{scenario.scenarioName}</Col>
              <Col className={isSelected ? "border-white" : ""}>No data available</Col>
              <Col className={isSelected ? "border-white" : ""}>No data available</Col>
              <Col className={isSelected ? "border-white" : ""}>No data available</Col>
            </div>
          );
        })}
        {energyDemandScenarios.length === 0 && (
          <div className={`flex w-full border-r border-l px-2 py-1`}>
            No energy demands available. Energy demands in this project will appear here once
            available.
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="px-4 ">
      {renderEnergyDemandSelectTable()}
      {selectedEnergyDemand && <EnergyGraph selectedEnergyDemand={selectedEnergyDemand} />}
    </div>
  );
};

const energyTags = ["Stub.DHW.dhw"];

const EnergyGraph: React.FC<{ selectedEnergyDemand: InputScenarioRef }> = ({
  selectedEnergyDemand,
}) => {
  const data = useGRPCData(
    energyTags,
    selectedEnergyDemand.scenarioID,
    selectedEnergyDemand.projectID
  );

  const { timeInfo } = useDataInfo(
    selectedEnergyDemand.projectID,
    selectedEnergyDemand.scenarioID
  );

  const plotData = useMemo(() => {
    if (data.data && data.time) {
      //transform the data to aggregated for duration diagram

      const plotData: Partial<PlotData>[] = [];
      const t = data.time.map((epoc) => {
        const offset = timeInfo?.offset || 0;
        return dayjs.unix(epoc + offset).format("YYYY-MM-DD HH:mm:ss");
      });
      plotData.push({
        x: t,
        y: data.data["Stub.DHW.dhw"],
        type: "scatter",
        mode: "lines",
        "line.color": "#FF0000",
        name: "Domestic hot water",
      });

      console.log({ plotData });

      return plotData;
    } else return null;
  }, [data, timeInfo?.offset]);

  return (
    <div className="py-4 flex items-center justify-center italic h-64">
      {plotData && (
        <Plot
          className={``}
          data={plotData}
          useResizeHandler
          config={{ displayModeBar: false }}
          style={{ width: "100%", height: "100%" }}
          layout={{
            autosize: true,
            paper_bgcolor: "#FFF",
            plot_bgcolor: "#FFF",
            margin: {
              t: 20,
              b: 20,
              l: 20,
              r: 20,
              pad: 0,
            },
            showlegend: true,
          }}
        />
      )}
    </div>
  );
};

const useEnergyDemandsScenarios = (projectID: string) => {
  const [energyDemandsScenarios, setEnergyDemandsScenarios] = useState<SimulationScenario[]>(
    []
  );
  const fs = useFirestore();

  useEffect(() => {
    const energyModelSystemIDs = [
      "LcJEVhJd3FOd2dB0Bope",
      "1spr3rXK0TduU1YBpn9R",
      "0rdLtZT6xzSoZmWkwkUa",
    ];
    fs.collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .where("systemID", "in", energyModelSystemIDs)
      .get()
      .then((snap) => {
        const scenarios: SimulationScenario[] = [];
        snap.docs.forEach((doc) => {
          scenarios.push({ ...doc.data(), id: doc.id } as SimulationScenario);
        });
        setEnergyDemandsScenarios(scenarios);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [projectID, fs]);
  return energyDemandsScenarios;
};

export default EnergyDemandSelecter;

const Col: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={`w-1/5 px-2 py-1 border-r ${className || ""}`}>{children}</div>
);
