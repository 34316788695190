import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { Project } from "model/datatypes";

const EditProject: React.FC<{ onFinish: () => void; project: Project }> = ({
  onFinish,
  project,
}) => {
  const [editedProject, setEditedProject] = useState<Project>(project);
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const updateProject = () => {
    if (editedProject) {
      setLoading(true);
      const { created, id, ...updateableFields } = editedProject;
      fb.firestore()
        .collection("Projects")
        .doc(project.id)
        .update(updateableFields)
        .then(() => {
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal onClose={() => onFinish()}>
      <div className={`card z-30 flex flex-col w-1/2`}>
        <div className="font-medium">Edit project</div>
        <label className="font-bold text-xs">Project name</label>
        <input
          type="text"
          className={`input-box mb-2`}
          value={editedProject.projectName}
          onChange={(e) => setEditedProject({ ...editedProject, projectName: e.target.value })}
        />
        <label className="font-bold text-xs">Project description</label>
        <textarea
          className={`input-box h-20 mb-2`}
          value={editedProject.description}
          onChange={(e) => setEditedProject({ ...editedProject, description: e.target.value })}
        />
        {/* Project parameters */}
        <div className="flex mt-4">
          <button
            className={`flex-1 button-small mr-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              !loading && updateProject();
            }}
          >
            Save
          </button>
          <button
            className={`flex-1 button-small ml-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => !loading && onFinish()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProject;
