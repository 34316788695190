import React from "react";

interface Props {}

const LockIcon = (props: Props) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M12.65 10a5.998 5.998 0 00-6.88-3.88c-2.29.46-4.15 2.29-4.63 4.58A6.006 6.006 0 007 18a5.99 5.99 0 005.65-4H17v2c0 1.1.9 2 2 2s2-.9 2-2v-2c1.1 0 2-.9 2-2s-.9-2-2-2h-8.35zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
    </svg>
  );
};

export default LockIcon;
