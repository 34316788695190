import React, { useState, useEffect, useMemo } from "react";
import {
  ComponentParameter,
  ComponentParamType,
  FileQuery,
  FileRef,
  JSONValue,
  ParameterTable,
  TimeValue,
} from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import { useConfigs, useSimFiles } from "api/useFirebase";
import HoverTooltip from "components/basic/HoverTooltip";
import InfoIcon from "components/basic/icons/InfoIcon";
import BooleanTick from "components/basic/BooleanTick";
import FileParameterUploader from "components/files/FileParameterUploader";
import Modal from "components/basic/Modal";
import { objectMap } from "utils/jsUtils/objectMap";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import InputNumber from "components/basic/headless/InputNumber";
import TimeValueInput from "components/systems/editSystem/componentEditor/parameters/TimeValueInput";
import JSONParameterValue from "components/systems/editSystem/componentEditor/parameters/JSONParameterValue";

const Parameter: React.FC<{
  parameter: ComponentParameter;
  paramType?: ComponentParamType;
  hideBorder?: boolean;
  onUpdate: (updatedParam: ComponentParameter) => void;
  fullWidth?: true;
}> = ({ parameter, onUpdate, paramType, hideBorder, fullWidth }) => {
  const inactive = parameter.value === null && parameter.optional;
  const missing = parameter.value === null && !parameter.optional;
  const fixed = parameter.displayMode === "fixed";
  return (
    <div
      className={`${hideBorder ? "" : "border-b"} border-gray-200 flex items-center px-4 py-3
        ${missing ? "bg-red-100" : ""}
        ${fullWidth ? " w-full" : " w-1/2"}
      `}
      key={parameter.id}
    >
      <div className={`w-1/3 pr-2 ${inactive || fixed ? "opacity-50" : ""}`}>
        <ParameterName tooltip={parameter.tooltip} displayName={parameter.displayName} />
      </div>
      <div className={`w-2/3 pl-2`}>
        {!fixed ? (
          <ParamEditValue parameter={parameter} onUpdate={onUpdate} paramType={paramType} />
        ) : (
          <span>{parameter.value?.toString()}</span>
        )}
      </div>
    </div>
  );
};

export default Parameter;

export const ParamEditValue: React.FC<{
  parameter: ComponentParameter;
  paramType?: ComponentParamType;
  onUpdate: (updatedParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate, paramType }) => {
  return (
    <>
      {parameter.type === "string" && (
        <input
          type="text"
          className={`input-box w-full`}
          value={
            typeof parameter.value === "string" ? parameter.value : parameter.value?.toString()
          }
          onChange={(e) => onUpdate({ ...parameter, value: e.target.value })}
        />
      )}
      {parameter.type === "number" && (
        <NumberInput
          className={`input-box w-full`}
          value={typeof parameter.value === "number" ? parameter.value : undefined}
          onChange={(newValue) => {
            newValue !== parameter.value && onUpdate({ ...parameter, value: newValue });
          }}
          optional={!!parameter.optional}
        />
      )}
      {parameter.type === "boolean" && (
        <BooleanTick
          selected={!!parameter.value}
          onCheck={(newVal) => {
            onUpdate({ ...parameter, value: newVal });
          }}
        />
      )}
      {parameter.type === "selecter" && (
        <SelecterParameter
          parameter={parameter}
          onUpdate={(newParam) => {
            onUpdate(newParam);
          }}
        />
      )}
      {parameter.type === "month" && (
        <MonthSelecter
          parameter={parameter}
          onUpdate={(newParam) => {
            onUpdate(newParam);
          }}
        />
      )}
      {parameter.type === "config" && (
        <ConfigSelecter
          parameter={parameter}
          onUpdate={(newParam) => {
            onUpdate(newParam);
          }}
        />
      )}
      {parameter.type === "reference" && <div>{parameter.value}</div>}
      {parameter.type === "file" && paramType?.fileQuery && (
        <FileSelecter
          fileQuery={paramType.fileQuery}
          parameter={parameter}
          onUpdate={(newParam) => {
            onUpdate(newParam);
          }}
        />
      )}
      {parameter.type === "table" && paramType?.tableColumns && (
        <TableRowInputs
          tableColoumns={paramType.tableColumns}
          value={parameter.value as null | ParameterTable}
          onUpdate={(newVal) => {
            onUpdate({ ...parameter, value: newVal });
          }}
        />
      )}
      {parameter.type === "time_value" && (
        <TimeValueInput
          timeValue={parameter.value as TimeValue}
          updateValue={(updatedVal) => {
            onUpdate({
              ...parameter,
              value: { ...(parameter.value as TimeValue), value: updatedVal },
            });
          }}
          update={(updated) => {
            onUpdate({
              ...parameter,
              value: { ...updated },
            });
          }}
        />
      )}
      {parameter.type === "json" && (
        <JSONParameterValue
          json={parameter.value as JSONValue}
          updateMetadata={(updated) => {
            onUpdate({
              ...parameter,
              value: updated,
            });
          }}
        />
      )}
    </>
  );
};

const TableRowInputs: React.FC<{
  value: null | ParameterTable;
  tableColoumns: ComponentParamType["tableColumns"];
  onUpdate: (newVal: ParameterTable) => void;
}> = ({ value, tableColoumns, onUpdate }) => {
  const [tableInputOpen, setTableInputOpen] = useState(false);

  const amountOfRows = useMemo(() => {
    const rowOne = value && Object.entries(value)[0][1];
    const length = rowOne && rowOne.length;
    return length || 0;
  }, [value]);

  const renderRowNumbers = () => {
    const rowNumbers = new Array(amountOfRows).fill(0).map((v, i) => i);
    return (
      <div className="italic w-8">
        {rowNumbers.map((nr) => (
          <div
            key={nr}
            className={`py-2 border flex justify-center ${
              nr % 2 === 0 ? "bg-gray-100 border-gray-100" : "bg-white border-white"
            }`}
          >
            {nr}
          </div>
        ))}
      </div>
    );
  };

  const renderRowDeleteButtons = () => {
    const rowNumbers = new Array(amountOfRows).fill(0).map((v, i) => i);
    return (
      <div className="italic w-20">
        {rowNumbers.map((nr) => (
          <div className={`py-1 px-2 ${nr % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
            <button
              className={`button-small w-full`}
              onClick={() => {
                if (value) {
                  const updatedValues = objectMap(value, (v) => immutableSplice(v, nr, 1)); // delete number at "nr" location
                  onUpdate(updatedValues);
                }
              }}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderTableCol = (tc: { uuid: string; colKey: string; colDisplay: string }) => {
    const rowData = value && value[tc.colKey];
    return (
      <div key={tc.uuid} className="w-32 mx-px">
        <div className="w-full px-2 py-1 bg-gray-200 h-12">
          <div className="font-medium">{tc.colDisplay}</div>
          <div className="italic">{tc.colKey}</div>
        </div>
        {rowData &&
          rowData.map((val, i) => {
            return (
              <div key={i} className={`px-2 py-1 ${i % 2 === 0 ? "bg-gray-100" : ""}`}>
                <InputNumber
                  className="w-full border border-gray-200 rounded focus:outline-none px-2 py-1"
                  value={val}
                  onChange={(val) => {
                    const updatedArray = immutableSplice(rowData, i, 1, val);
                    onUpdate({ ...value, [tc.colKey]: updatedArray });
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderEditTableInputs = () => {
    return (
      <Modal onClose={() => setTableInputOpen(false)}>
        <div className="z-30 bg-white py-6 px-8 shadow-lg rounded max-w-full max-h-full overflow-auto">
          <div className="flex text-xs font-medium">
            <div className="w-8 ">
              <div className="bg-gray-200 h-12"></div>
              {renderRowNumbers()}
            </div>
            {tableColoumns?.map(renderTableCol)}
            <div className="w-20">
              <div className="bg-gray-200 h-12"></div>
              {renderRowDeleteButtons()}
            </div>
          </div>
          <button
            className={`button-small mt-4`}
            onClick={() => {
              if (value) {
                const updated = objectMap(value, (v) => [...v, 0]) as ParameterTable;
                onUpdate(updated);
              } else if (tableColoumns) {
                const colEntries = tableColoumns.map((col) => [col.colKey, [0]]);
                const newParameterTable = Object.fromEntries(colEntries);
                onUpdate(newParameterTable);
              }
            }}
          >
            Add row
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <button onClick={() => setTableInputOpen(true)} className={`button-small w-full`}>
        {`${amountOfRows} row${amountOfRows === 1 ? "" : "s"}`}
      </button>
      {tableInputOpen && renderEditTableInputs()}
    </>
  );
};

const FileSelecter: React.FC<{
  fileQuery: FileQuery;
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate, fileQuery }) => {
  const { simFiles: files } = useSimFiles(fileQuery);

  const [addNewOpen, setAddNewOpen] = useState(false);

  const options = files.map((file) => ({
    id: file.id,
    display: file.name,
    val: { id: file.id, path: file.path, name: file.name } as FileRef,
  }));

  const selectedID =
    typeof parameter.value === "object" && parameter.value && "id" in parameter.value
      ? (parameter.value.id as string)
      : undefined;
  return (
    <>
      <DropdownAtRoot
        className={`bg-white`}
        options={options}
        onSelect={(dropdownOption) => {
          onUpdate({ ...parameter, value: dropdownOption.val });
        }}
        selectedID={selectedID}
        placeholder={options.length > 0 ? "Select file" : "No files found"}
        emptyMsg="No files found"
        onAddNew={() => {
          setAddNewOpen(true);
        }}
      />
      {addNewOpen && (
        <FileParameterUploader
          fileQuery={fileQuery}
          onFinish={(fileRef) => {
            setAddNewOpen(false);
            if (fileRef) onUpdate({ ...parameter, value: fileRef });
          }}
        />
      )}
    </>
  );
};

const ConfigSelecter: React.FC<{
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate }) => {
  const { configs, loadingConfigs } = useConfigs(parameter.tag);
  const options = configs.map((config) => ({
    id: config.Name,
    display: config.Name,
    val: config.Name,
  }));
  return (
    <DropdownAtRoot
      className={`${loadingConfigs ? "opacity-50" : ""} bg-white`}
      options={options}
      onSelect={(dropdownOption) => {
        onUpdate({ ...parameter, value: dropdownOption.val });
      }}
      selectedID={parameter.value === null ? "default" : (parameter.value as string)}
      placeholder={options.length > 0 ? "Select value" : "No configs found"}
      emptyMsg="No cofigs found for tag"
    />
  );
};

const NumberInput: React.FC<{
  value?: number;
  onChange: (newValue: number | null) => void;
  optional: boolean;
  className?: string;
}> = ({ value, onChange, optional, className }) => {
  const [displayVal, setdisplayVal] = useState(value?.toString() || "");

  const [error, seterror] = useState(false);

  useEffect(() => {
    //external value changes:
    if (value) {
      if (value.toString() !== displayVal) {
        setdisplayVal(value.toString());
        seterror(false);
      }
    }
  }, [value, displayVal]);

  const setVal = (newVal: string) => {
    const parsedVal = parseFloat(newVal);
    if (isNaN(parsedVal)) {
      onChange(null);
      if (!optional) seterror(true);
    } else {
      onChange(parsedVal);
      seterror(false);
    }
    setdisplayVal(newVal);
  };

  return (
    <input
      type="number"
      className={`${error ? "border-red-400" : ""} ${className || ""}`}
      value={displayVal}
      onChange={(e) => setVal(e.target.value)}
    />
  );
};

const MonthSelecter: React.FC<{
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate }) => {
  return (
    <DropdownAtRoot
      className={`bg-white`}
      options={monthOptions}
      onSelect={(dropdownOption) => {
        onUpdate({ ...parameter, value: dropdownOption.val });
      }}
      selectedID={parameter.value === null ? "default" : parameter.value.toString()}
      placeholder="Select month"
    />
  );
};

const monthOptions = [
  { id: "off", display: "None", val: null },
  { id: "1", display: "January", val: 1 },
  { id: "2", display: "February", val: 2 },
  { id: "3", display: "March", val: 3 },
  { id: "4", display: "April", val: 4 },
  { id: "5", display: "May", val: 5 },
  { id: "6", display: "June", val: 6 },
  { id: "7", display: "July", val: 7 },
  { id: "8", display: "August", val: 8 },
  { id: "9", display: "September", val: 9 },
  { id: "10", display: "October", val: 10 },
  { id: "11", display: "November", val: 11 },
  { id: "12", display: "December", val: 12 },
];

const SelecterParameter: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParameter) => void;
  className?: string;
}> = ({ parameter, onUpdate, className }) => {
  return (
    <DropdownAtRoot
      className={`${className} bg-white`}
      options={parameter.selecterOptions || []}
      onSelect={(dropdownOption) => {
        onUpdate({
          ...parameter,
          value: dropdownOption.value,
          tag: dropdownOption.id,
          displayValue: dropdownOption.display,
        });
      }}
      selectedID={parameter.tag}
      placeholder="Select option"
    />
  );
};

const ParameterName: React.FC<{ displayName: string; tooltip?: string }> = ({
  displayName,
  tooltip,
}) => {
  return (
    <div className="w-full flex items-center">
      <div className={`text-xs font-bold flex-grow`}>
        <span>{displayName}</span>
      </div>
      {tooltip && (
        <HoverTooltip className="ml-2" mt={-25} text={tooltip}>
          <InfoIcon />
        </HoverTooltip>
      )}
    </div>
  );
};
