import dayjs from "dayjs";
import { SPMClient, JobManagerClient } from "grpc/client/SpmServiceClientPb";
import {
  DataList,
  DataBlock,
  ReadScenario,
  Job,
  ExecutionReadLogs,
  ExeLogEntry,
  Scenario,
  ScenarioMetaData,
} from "grpc/client/spm_pb";
import { TimeInfo } from "model/datatypes";

let spmClient = new SPMClient("", {});
let jobManager = new JobManagerClient("", {});

export const getGRPCClient = (defaultAPI: string, idToken: string) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  spmClient.hostname_ = serverAPI;
  spmClient.credentials_!["idToken"] = idToken;
  return spmClient;
};

export const getGRPCClientUnauthorized = (defaultAPI: string) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  spmClient.hostname_ = serverAPI;
  delete spmClient.credentials_!["idToken"];
  return spmClient;
};

export const getJobManagerClient = (defaultAPI: string, idToken: string) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  jobManager.hostname_ = serverAPI;
  jobManager.credentials_!["idToken"] = idToken;
  return jobManager;
};

//@ts-ignore
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
enableDevTools([spmClient]);

export const startJob = (
  grpcAPI: string,
  idToken: string,
  configuration: {
    projectId: string;
    scenarioId: string;
    jobId: string;
    organisationId: string;
    userId: string;
  }
) => {
  return new Promise<Job>((resolve, reject) => {
    const { jobId, organisationId, projectId, scenarioId, userId } = configuration;

    let job = new Job();
    job.setScenarioId(scenarioId);
    job.setProjectId(projectId);
    job.setJobId(jobId);
    job.setUserId(userId);
    job.setOrganizationId(organisationId);

    console.log(job.toObject());

    let completed = false;

    const stream = getJobManagerClient(grpcAPI, idToken).startJob(
      job,
      { authorization: idToken },
      (err, response) => {
        completed = true;
        if (err) {
          reject(err);
        } else {
          resolve(response);
          console.log("Response, start", response?.toObject());
        }
      }
    );

    stream.on("error", (error) => {
      console.log("Stream Error");
      console.log(error);
      reject(error);
    });
    stream.on("status", (status) => {
      console.log({ status });
    });
    stream.on("end", () => {
      console.log("Stream End");
      if (!completed) {
        reject(new Error("stream end without res"));
      }
    });
    setTimeout(() => {
      if (!completed) {
        stream.cancel();
        console.log("Manual stream timeout");
        reject(new Error("Manual timeout reached"));
      }
    }, 10000);
  });
};

export const stopJob = (
  grpcURL: string,
  idToken: string,
  configuration: {
    projectId: string;
    scenarioId: string;
    jobId: string;
    organisationId: string;
    userId: string;
  }
) => {
  return new Promise<Job>((resolve, reject) => {
    const { projectId, scenarioId, jobId, organisationId, userId } = configuration;

    let job = new Job();
    job.setScenarioId(scenarioId);
    job.setProjectId(projectId);
    job.setJobId(jobId);
    job.setUserId(userId);
    job.setOrganizationId(organisationId);

    let completed = false;

    const stream = getJobManagerClient(grpcURL, idToken).terminateJob(
      job,
      { authorization: idToken },
      (err, response) => {
        completed = true;
        if (err) {
          reject(err);
        } else resolve(response);
      }
    );

    setTimeout(() => {
      if (!completed) {
        stream.cancel();
        console.log("Manual stream timeout");
        reject(new Error("Manual timeout reached"));
      }
    }, 10000);
  });
};

export const hardResetJob = (
  grpcURL: string,
  idToken: string,
  configuration: {
    projectId: string;
    scenarioId: string;
    jobId: string;
    organisationId: string;
    userId: string;
  }
) => {
  const { projectId, scenarioId, jobId, organisationId, userId } = configuration;

  const jobClient = getJobManagerClient(grpcURL, idToken);
  let job = new Job();
  job.setScenarioId(scenarioId);
  job.setProjectId(projectId);
  job.setJobId(jobId);
  job.setUserId(userId);
  job.setOrganizationId(organisationId);

  return jobClient.resetJob(job, { authorization: idToken });
};

export const uploadTagData = async (
  grpcURL: string,
  idToken: string,
  configuration: {
    scenarioID: string;
    projectID: string;
    data: { tag: string; values: number[] }[];
    execution: string;
    alreadyExists?: boolean;
    metadata?: ScenarioMetaData;
  }
) => {
  const { scenarioID, data, metadata, alreadyExists, execution } = configuration;

  const client = getGRPCClient(grpcURL, idToken);

  if (metadata) {
    await client.setScenarioMetaData(metadata, { authorization: idToken });
  }

  const datalist = new DataList();
  datalist.setScenario(scenarioID);

  data.forEach((col) => {
    const newBlock = new DataBlock(); //et tag pr. block
    newBlock.setTag(col.tag);
    newBlock.setValuesList(col.values);
    datalist.addData(newBlock);
  });
  datalist.setRowComplete(true);
  datalist.setBlockComplete(true);
  datalist.setExecution(execution);
  if (alreadyExists) datalist.setResetBlockCounter(true);

  const response = await client.pushDataList(datalist, { authorization: idToken });
  console.log(response);
  return response;
};

type Dataframe = {
  [key: string]: number[];
};

export const readDataExample = (conf: {
  grpcAPI: string;
  idToken: string;
  scenarioID: string;
  projectID: string;
  executionID?: string;
  tags: string[];
}) => {
  return new Promise<Dataframe>((resolve, reject) => {
    const { grpcAPI, tags, scenarioID, projectID, idToken, executionID } = conf;
    // console.log("read data for: ");
    // console.log({ scenarioID, tags });
    const readScenario = new ReadScenario();
    readScenario.setListen(false);
    readScenario.setProject(projectID);
    readScenario.setScenario(scenarioID);
    if (executionID) readScenario.setExecution(executionID);

    readScenario.setTagsList(tags);

    const stream = getGRPCClient(grpcAPI, idToken).readData(readScenario, {
      authorization: idToken,
    });

    const data: Dataframe = {};
    let firstContact = false;

    stream.on("data", (res: any) => {
      firstContact = true;
      const list: DataList = res;
      const dataListObj = list.toObject();

      dataListObj.dataList.forEach((block, i) => {
        const prev = data[block.tag];
        if (Array.isArray(prev)) data[block.tag] = [...prev, ...block.valuesList];
        else data[block.tag] = block.valuesList;
      });
    });

    stream.on("error", (err) => {
      firstContact = true;
      console.log(err);

      reject(err);
      stream.cancel();
    });

    stream.on("status", (status) => {
      firstContact = true;
      console.log({ status });
      if (status.code === 0) {
        console.log("DataStream ended");
        //end of stream reached!
        resolve(data);
      }
    });
    stream.on("end", () => {
      console.log("the end..");
      resolve(data);
    });
    stream.on("metadata", (metadata) => {
      console.log(metadata);
    });

    setTimeout(() => {
      if (!firstContact) {
        stream.cancel();
        console.log("Manual stream timeout");
        reject(new Error("Manual timeout reached"));
      }
    }, 50000);
  });
};

export const readLogs = (
  grpcAPI: string,
  idToken: string,
  executionId: string,
  start: number,
  end: number
) => {
  let finished = false;
  let cancel = () => {
    finished = true;
  };

  const promise = new Promise<ExeLogEntry.AsObject[]>((resolve, reject) => {
    const request = new ExecutionReadLogs()
      .setExecutionId(executionId)
      .setStart(start)
      .setEnd(end);

    console.log(request.toObject());

    const stream = getGRPCClient(grpcAPI, idToken).readExecutionLogEntries(request, {
      authorization: idToken,
    });

    cancel = () => {
      if (!finished) stream.cancel();
    };

    const logs: ExeLogEntry.AsObject[] = [];
    let firstContact = false;

    stream.on("data", (res: any) => {
      firstContact = true;
      const entry = res.toObject() as ExeLogEntry.AsObject;
      logs.push(entry);
    });

    stream.on("error", (err) => {
      firstContact = true;
      finished = true;
      console.log(err);
      reject(err);
    });
    stream.on("status", (status) => {
      firstContact = true;
      console.log({ status });

      if (status.code === 0) {
        console.log("DataStream ended");
        //end of stream reached!
        finished = true;
        resolve(logs);
      }
    });

    stream.on("end", () => {
      console.log("the end..");
      finished = true;
      resolve(logs);
    });

    stream.on("metadata", (metadata) => {
      console.log(metadata);
    });

    setTimeout(() => {
      if (!firstContact) {
        stream.cancel();
        finished = true;
        console.log("Manual stream timeout");
        reject(new Error("Manual timeout reached"));
      }
    }, 10000);
  });

  return { promise, cancel };
};

export const readScenarioMetadata = (
  grpcAPI: string,
  idToken: string,
  projectId: string,
  scenarioId: string
) => {
  const scenario = new Scenario().setProject(projectId).setScenario(scenarioId);
  return getGRPCClient(grpcAPI, idToken).getScenarioMetaData(scenario, {
    authorization: idToken,
  });
};

export const readTimeInfo = async (
  grpcAPI: string,
  idToken: string,
  projectId: string,
  scenarioId: string,
  executionId?: string
) => {
  const scenario = new Scenario().setProject(projectId).setScenario(scenarioId);
  if (executionId) scenario.setExecution(executionId);

  const rawStats = await getGRPCClient(grpcAPI, idToken).getScenarioDataStats(scenario, {
    authorization: idToken,
  });

  const rawMetadata = await getGRPCClient(grpcAPI, idToken).getScenarioMetaData(scenario, {
    authorization: idToken,
  });

  const scenarioStats = rawStats.toObject();
  const scenarioMetadata = rawMetadata.toObject();
  const startTimeVal = scenarioStats.min + scenarioMetadata.offset;
  const endTimeVal = scenarioStats.max + scenarioMetadata.offset;
  // //TODO: multiply by epocType?

  const timeInfo: TimeInfo = {
    scenarioID: scenarioId,
    scenarioStats,
    scenarioMetadata,
    offset: scenarioMetadata.offset,
    startTime: dayjs.unix(startTimeVal),
    endTime: dayjs.unix(endTimeVal),
    originalStart: dayjs.unix(scenarioStats.min),
    originalEnd: dayjs.unix(scenarioStats.max),
  };

  return { timeInfo, rawMetadata };
};

export const checkDatasetClosed = async (
  grpcAPI: string,
  idToken: string,
  projectId: string,
  scenarioId: string,
  executionId?: string
) => {
  const scenario = new Scenario().setProject(projectId).setScenario(scenarioId);
  if (executionId) scenario.setExecution(executionId);

  const closed = await getGRPCClient(grpcAPI, idToken).getDataSetClosed(scenario, {
    authorization: idToken,
  });
  return closed.getIsClosed();
};
