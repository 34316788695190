import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Component,
  InputVar,
  SimulationModel,
  Subcomponent,
  ScenarioStateEdited,
  ComponentType,
} from "model/datatypes";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import { InputVariable } from "./InputVariable";
import Parameter from "./Parameter";
import ToggleButton from "components/basic/ToggleButton";
import { NewScenarioAction } from "../../NewScenarioReducer";
import { Popup } from "components/basic/Popup";
import { store } from "store";
import {
  compToSubComp,
  InstantiateAllSubComponents,
  InstantiateComponent,
  updateComponentsNames,
} from "utils/ComponentTypeHelpers";
import CommentableComponent from "components/comments/CommentableComponent";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { SimComponentRenderer } from "components/simulations/newSimulation/simSetup/ComponentSetup";

const SimulationComponent: React.FC<{
  model: SimulationModel;
  component: Component;
  scenarioState: ScenarioStateEdited;
  scenarioDispatch: (value: NewScenarioAction) => void;
}> = ({ component, model, scenarioDispatch, scenarioState }) => {
  const { state } = useContext(store);
  const { projectID, projectName } = state;

  const isInContainer = useMemo(() => !!component.containerID, [component]);

  const componentType = useMemo(
    () => scenarioState.system?.componentTypes?.find((type) => type.id === component.id),
    [scenarioState.system, component.id]
  );
  const allComponents = useMemo(() => scenarioState.simComponents, [scenarioState]);

  const onUpdate = useCallback(
    (updatedComponent: Component) => {
      scenarioDispatch({
        type: "UPDATE_COMPONENT",
        payload: updatedComponent,
      });
    },
    [scenarioDispatch]
  );

  const addSubComponent = useCallback(
    (newComponentID: string) => {
      const compType = scenarioState.system?.componentTypes?.find(
        (ct) => ct.id === newComponentID
      );
      if (projectID && compType && scenarioState.system?.componentTypes) {
        const newSubComponent = InstantiateComponent(compType, false);
        const comps = InstantiateAllSubComponents(
          [newSubComponent],
          scenarioState.system.componentTypes
        );
        const updatedAllComponents = updateComponentsNames([...allComponents, ...comps]);
        scenarioDispatch({
          type: "UPDATE_COMPONENTS",
          payload: updatedAllComponents,
        });

        //update the parent to reference the new subComponent:
        const newSubC = compToSubComp(newSubComponent);
        const updatedSubComps = component.subcomponents
          ? [...component.subcomponents, newSubC]
          : [newSubC];
        scenarioDispatch({
          type: "UPDATE_COMPONENT",
          payload: { ...component, subcomponents: updatedSubComps },
        });
      }
    },
    [scenarioDispatch, allComponents, component, projectID, scenarioState.system]
  );

  const getNestedSubcomponents: (subComps: Subcomponent[]) => Component[] = useCallback(
    (subComps) => {
      const comps = allComponents.filter((c) => subComps.some((subC) => subC.uuid === c.uuid));
      const startArray: Subcomponent[] = [];
      const nestedSubComps = comps.reduce((prev, cur) => {
        if (cur.subcomponents && cur.subcomponents.length > 0)
          return [...prev, ...cur.subcomponents];
        else return prev;
      }, startArray);
      if (nestedSubComps.length > 0) {
        return [...comps, ...getNestedSubcomponents(nestedSubComps)];
      } else return [...comps];
    },
    [allComponents]
  );

  const removeSubComponent = useCallback(
    (compToRemove: Component) => {
      const compsToRemove = [compToRemove];

      //find all nested comps so they're alse removed:
      if (compToRemove.subcomponents) {
        const nestedComps = getNestedSubcomponents(compToRemove.subcomponents);
        console.log(nestedComps);
        nestedComps.length > 0 && compsToRemove.push(...nestedComps);
      }
      //remove the sub-components instances:
      scenarioDispatch({
        type: "UPDATE_COMPONENTS",
        payload: allComponents.filter(
          (c) => !compsToRemove.some((comptr) => c.uuid === comptr.uuid)
        ),
      });
      //update the component reference to exclude the one removed:
      const updatedSubComps = component.subcomponents?.filter(
        (sc) => sc.uuid !== compToRemove.uuid
      );
      scenarioDispatch({
        type: "UPDATE_COMPONENT",
        payload: { ...component, subcomponents: updatedSubComps },
      });
    },
    [scenarioDispatch, allComponents, component, getNestedSubcomponents]
  );

  const renderSubComponent = (subComponent: Subcomponent) => {
    const subC = allComponents.find((c) => c.uuid === subComponent.uuid);
    //can be removed?
    const rules = componentType?.subCompRules?.find(
      (subCompRules) => subCompRules.id === subC?.id
    );
    const amountOfType = allComponents.filter((c) => c.id === subComponent.id).length;
    const removeable = !!rules && rules.min < amountOfType;

    if (subC)
      return (
        <div key={subC.uuid} className="relative my-3">
          <SimComponentRenderer
            model={model}
            component={subC}
            scenarioState={scenarioState}
            scenarioDispatch={scenarioDispatch}
          />
          {removeable && (
            <button
              className="absolute top-0 right-0 mt-1 mr-2 focus:outline-none"
              onClick={() => removeSubComponent(subC)}
            >
              x
            </button>
          )}
        </div>
      );
    else return <div>Error finding sub-component...</div>;
  };

  const renderAddSubcomponents = () => {
    const addableSubComponents = componentType?.subCompRules?.filter((scr) => {
      if (!scr.max) return true;
      const alreadyAddedAmount = component.subcomponents?.filter(
        (sc) => sc.id === scr.id
      ).length;
      return !alreadyAddedAmount || alreadyAddedAmount < scr.max;
    });

    if (addableSubComponents && addableSubComponents.length > 0)
      return (
        <Popup
          mt={26}
          content={(closeMe) => (
            <div className="cursor-pointer text-xs">
              {addableSubComponents.map((scr) => {
                const compType = scenarioState.system?.componentTypes?.find(
                  (type) => type.id === scr.id
                );
                if (compType)
                  return (
                    <div
                      key={scr.id}
                      className="cursor-pointer px-2 py-1 border-b hover:bg-gray-100"
                      onClick={() => {
                        addSubComponent(scr.id);
                        closeMe();
                      }}
                    >
                      {compType.displayName}
                    </div>
                  );
                else return null;
              })}
            </div>
          )}
        >
          <button className={`button-small`}>+</button>
        </Popup>
      );
    else return null;
  };

  const renderInputVariables = () => {
    return (
      <>
        <div className="text-sm font-medium mb-4">Input Data</div>
        <div className="flex border-b border-gray-200">
          <div className="w-1/6 px-4 font-bold">Variable Name</div>
          <div className="w-1/6 px-4 font-bold">Source</div>
          <div className="w-1/12 px-2 font-bold">Scaling</div>
          <div className="w-1/12 px-2 font-bold">Offset</div>
          <div className="w-1/2 px-4 font-bold">Value</div>
        </div>
        {component.inputVariables.map((value) => {
          return (
            <InputVariable
              key={value.uuid}
              inputVariable={value}
              onUpdate={(updatedFields) => {
                const updatedVar: InputVar = {
                  ...value,
                  ...updatedFields,
                };
                onUpdate({
                  ...component,
                  inputVariables: updateArrayVal(component.inputVariables, updatedVar),
                });
              }}
              scenarioState={scenarioState}
            />
          );
        })}
      </>
    );
  };

  return (
    <CommentableComponent
      commentTaget={{
        groupID: scenarioState.groupID,
        projectID: projectID || undefined,
        projectName: projectName || undefined,
        scenarioID: scenarioState.id,
        scenarioName: scenarioState.scenarioName,
        componentID: component.id,
      }}
      className={`border border-gray-300 rounded py-4 px-4 bg-white w-full mb-4 ${
        component.disabled ? "opacity-50" : ""
      }`}
    >
      {!isInContainer && (
        <div className="flex items-center justify-between">
          <div className={`font-bold text-lg mb-2`}>{component.displayName}</div>
          {componentType?.instantiationRules?.allowDisabling && (
            <div>
              <ToggleButton
                active={!component.disabled}
                onChange={() => {
                  onUpdate({ ...component, disabled: !component.disabled });
                }}
              />
              <div className="text-xs italic">
                {component.disabled ? "disabled" : "enabled"}
              </div>
            </div>
          )}
        </div>
      )}

      {!component.disabled && (
        <>
          <div className="text-sm font-medium mb-2">Parameters</div>
          <ComponentParameters
            component={component}
            componentType={componentType}
            onUpdate={onUpdate}
          />
        </>
      )}
      {!component.disabled && component.inputVariables.length > 0 && renderInputVariables()}
      {component.subcomponents && component.subcomponents.length > 0 && (
        <div className="mb-2">
          {component.subcomponents && component.subcomponents.map(renderSubComponent)}
          {renderAddSubcomponents()}
        </div>
      )}
    </CommentableComponent>
  );
};
export default SimulationComponent;

const isLastRow = (totalItems: number, itemsPerRow: number, i: number) => {
  let lastRowStart = totalItems - itemsPerRow + (totalItems % itemsPerRow);
  return i >= lastRowStart;
};

export const ComponentParameters: React.FC<{
  component: Component;
  componentType?: ComponentType;
  onUpdate: (updatedComponent: Component) => void;
}> = ({ component, componentType, onUpdate }) => {
  //hide reference and hidden comp from scenario
  const shownParameters = useMemo(
    () =>
      component.parameters.filter(
        (param) =>
          param.type !== "reference" &&
          param.displayMode !== "hidden" &&
          param.displayMode !== "advanced"
      ),
    [component]
  );

  const advancedParameters = useMemo(
    () => component.parameters.filter((param) => param.displayMode === "advanced"),
    [component]
  );

  const [showAdvanced, setShowAdvanced] = useState(false);

  if (shownParameters.length === 0 && advancedParameters.length === 0) return null;
  return (
    <>
      {shownParameters.length > 0 && (
        <div className="flex flex-wrap">
          {shownParameters.map((param, i) => {
            const protoParam = componentType?.parameters.find((p) => param.id === p.id);
            const hideBorder = isLastRow(shownParameters.length, 2, i);
            return (
              <Parameter
                key={param.uuid}
                parameter={param}
                paramType={protoParam}
                hideBorder={hideBorder}
                onUpdate={(updatedParam) => {
                  onUpdate({
                    ...component,
                    parameters: updateArrayVal(component.parameters, updatedParam),
                  });
                }}
              />
            );
          })}
        </div>
      )}
      {advancedParameters.length > 0 && (
        <div className="bg-gray-100 rounded px-2 mb-2">
          <div
            className="text-s font-medium flex items-center cursor-pointer px-2 py-2"
            onClick={() => setShowAdvanced((prev) => !prev)}
          >
            <div className="mr-2">{showAdvanced ? "Hide" : "Show"} advanced</div>
            <OpenCloseArrow isOpen={showAdvanced} />
          </div>
          {showAdvanced && (
            <div className="flex flex-wrap">
              {advancedParameters.map((param, i) => {
                const hideBorder = isLastRow(advancedParameters.length, 2, i);
                const protoParam = componentType?.parameters.find((p) => param.id === p.id);
                return (
                  <Parameter
                    key={param.uuid}
                    parameter={param}
                    paramType={protoParam}
                    hideBorder={hideBorder}
                    onUpdate={(updatedParam) => {
                      onUpdate({
                        ...component,
                        parameters: updateArrayVal(component.parameters, updatedParam),
                      });
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      <hr className="mb-4 border-gray-200" />
    </>
  );
};
