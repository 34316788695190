import { useFirestore } from "api/useFirebase";
import dayjs from "dayjs";
import { DatasetScenario, Schedule } from "model/datatypes";
import { useEffect, useState } from "react";
import { useGlobalState } from "store";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";

export const useAllSchedules = () => {
  const { projectID } = useGlobalState();
  const [savedSchedules, setSavedSchedules] = useState<
    { dataset: DatasetScenario; schedule: Schedule }[]
  >([]);

  const fs = useFirestore();
  useEffect(() => {
    if (projectID) {
      return fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("type", "==", "dataset")
        .onSnapshot((snap) => {
          const schedules: { dataset: DatasetScenario; schedule: Schedule }[] = [];
          snap.docs.forEach((doc) => {
            let dataset = convertFromFirestoreFormatNew({
              id: doc.id,
              ...doc.data(),
            }) as DatasetScenario;

            //some magic to fix compatability after changed format:
            //@ts-ignore
            if (dataset.datasetName) dataset.scenarioName = dataset.datasetName;
            //@ts-ignore
            if (dataset.tags) dataset.dataTags = dataset.tags;
            if (!dataset.created) dataset.created = dayjs();
            dataset.type = "dataset";

            if (dataset.schedules) {
              Object.entries(dataset.schedules).forEach(([key, schedule]) => {
                schedules.push({ dataset, schedule });
              });
            }
          });
          setSavedSchedules(schedules);
        });
    }
  }, [fs, projectID]);

  return savedSchedules;
};

export const useDefaultScheduleDataset = () => {
  const { projectID } = useGlobalState();
  const fs = useFirestore();
  const [defaultScheduleDataset, setDefaultScheduleDataset] = useState<DatasetScenario>();
  useEffect(() => {
    if (projectID) {
      return fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("scenarioName", "==", "Schedules")
        .onSnapshot((snap) => {
          const doc = snap.docs.pop();
          if (snap.empty || !doc) return;
          let dataset = convertFromFirestoreFormatNew({
            id: doc.id,
            ...doc.data(),
          }) as DatasetScenario;
          setDefaultScheduleDataset(dataset);
        });
    }
  }, [fs, projectID]);
  return defaultScheduleDataset;
};
