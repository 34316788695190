import React, { useMemo, useState } from "react";
import { Group, SimulationModel, SimulationScenario } from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import { useFirebase, useGroups, useSimulationModels } from "api/useFirebase";

import { NewScenarioAction } from "../NewScenarioReducer";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import { mergeScenarioSystem } from "utils/simulations/mergeScenarioSystem";
import { useGlobalState } from "store";
import LoadingOverlay from "components/basic/LoadingOverlay";

const EngineSetup: React.FC<{
  scenarioState: SimulationScenario;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  systemName: string;
}> = ({ scenarioState, scenarioDispatch, systemName }) => {
  const { groupID, scenarioName } = scenarioState;

  const [changingModel, setChangingModel] = useState(false);
  const renderSimulationModel = () => {
    return (
      <>
        <label className={`text-xs font-bold`}>System</label>
        <div className="mt-2 mb-4">
          {systemName}{" "}
          <button
            onClick={() => {
              setChangingModel(true);
            }}
            className="focus:outline-none italic underline text-blue-600"
          >
            change
          </button>
        </div>
        {changingModel && (
          <ModelChangeSelecter
            scenarioDispatch={scenarioDispatch}
            scenario={scenarioState}
            onFinish={() => setChangingModel(false)}
          />
        )}
      </>
    );
  };

  return (
    <div className={`flex mb-4`}>
      <div className="w-1/2 flex flex-col mr-6">
        <label className={`text-xs font-bold`}>Scenario Name</label>
        <input
          type="text"
          className={`input-box text-xs mb-4`}
          value={scenarioName}
          onChange={(e) =>
            scenarioDispatch({ type: "SCENARIO_NAME_UPADTE", payload: e.target.value })
          }
        />
        <label className={`text-xs font-bold`}>Group</label>
        <GroupSelecter
          selectedGroupID={groupID || undefined}
          onSelect={(group) => {
            scenarioDispatch({ type: "SET_GROUP", payload: group.id });
          }}
        />
      </div>
      <div className="w-1/2 flex flex-col">
        {renderSimulationModel()}
        <label className={`text-xs font-bold`}>Description</label>
        <textarea
          className={`input-box text-xs font-bold h-24`}
          value={scenarioState.description}
          onChange={(e) =>
            scenarioDispatch({ type: "UPDATE_DESCRIPTION", payload: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export const GroupSelecter: React.FC<{
  selectedGroupID?: string;
  onSelect: (group: Group) => void;
}> = ({ selectedGroupID, onSelect }) => {
  const { projectID } = useGlobalState();
  const groups = useGroups(projectID || undefined);

  return (
    <>
      <Dropdown
        className="bg-white"
        onSelect={(option) => {
          onSelect(option.val);
        }}
        placeholder="Select group"
        selectedID={selectedGroupID}
        options={groups ? groups.map((g) => ({ id: g.id, display: g.groupName, val: g })) : []}
      />
    </>
  );
};

export default EngineSetup;

const ModelChangeSelecter: React.FC<{
  scenario: SimulationScenario;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  onFinish: () => void;
}> = ({ scenario, scenarioDispatch, onFinish }) => {
  const fb = useFirebase();

  const { allModels } = useSimulationModels();

  const { projectID } = useGlobalState();
  const modelOptions = useMemo(
    () => allModels.map((model) => ({ id: model.id, display: model.displayName, val: model })),
    [allModels]
  );

  const [newModel, setNewModel] = useState<null | SimulationModel>(null);

  const selectedID = useMemo(
    () => (newModel ? newModel.id : scenario.systemID),
    [newModel, scenario]
  );

  console.log({ selectedID });

  const newModelSelected = useMemo(
    () => selectedID !== scenario.systemID,
    [selectedID, scenario]
  );

  const [loading, setLoading] = useState(false);

  const changeAndMergeModels = async () => {
    if (newModelSelected && newModel && !loading && projectID) {
      setLoading(true);
      try {
        const updates = await mergeScenarioSystem(fb, projectID, scenario, newModel);
        console.log({ updates });

        scenarioDispatch({
          type: "HARD_UPDATE_SCENARIO",
          payload: { ...updates, systemID: newModel.id, changed: false },
        });

        setLoading(false);
        onFinish();
      } catch (error) {
        setLoading(false);
        console.log(error);
        Toast("Error changing to new model");
      }
    }
  };

  return (
    <Modal onClose={onFinish}>
      <div className="z-30 bg-white p-4 rounded shadow-lg relative">
        <div className="text-lg font-medium">Change system</div>
        <div className="italic text-xs mb-4">
          This will overwrite with parameters from the new model while matching parameters and
          inputs.
        </div>
        <Dropdown
          className="text-xs"
          options={modelOptions}
          selectedID={selectedID}
          onSelect={(option) => {
            setNewModel(option.val);
          }}
        />
        <div className="flex mt-4">
          <button
            onClick={() => {
              if (newModelSelected) changeAndMergeModels();
            }}
            className={`button-small ${newModelSelected ? "" : "opacity-50"} flex-1 mr-2`}
          >
            Change and save
          </button>
          <button onClick={() => onFinish()} className={`button-small flex-1 ml-2`}>
            Cancel
          </button>
        </div>
        {loading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};
