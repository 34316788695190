import { useFirebase } from "api/useFirebase";
import { PlotData } from "plotly.js";
import { useEffect, useState } from "react";

export type FullBHSConfig = {
  borefield: {
    Bx_H: number;
    By_H: number;
    D: number;
    D_H: number;
    H: number;
    orientation: number;
    r_b: number;
    r_b_H: number;
    scalable: boolean;
    tilt: number;
    x: number[];
    y: number[];
  };
};

const useBHSPlotData = (configID?: string) => {
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const [bhsPlotData, setBHSPlotData] = useState<null | Partial<PlotData>[]>(null);

  useEffect(() => {
    if (!configID) {
      setLoading(false);
      setBHSPlotData(null);
      return;
    }

    const getConfig = async () => {
      try {
        setLoading(true);
        //SEARCH BHS HERE...
        const getBHSConfig = fb.functions().httpsCallable("getBHSConfig");
        const result = await getBHSConfig({ configID });
        const resultsBHSConfig = result.data as FullBHSConfig;
        console.log({ resultsBHSConfig });
        const plotData = parseBHSPlotToLayout(resultsBHSConfig);
        setBHSPlotData(plotData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getConfig();
  }, [configID, fb]);

  return { bhsPlotData, loading };
};

export default useBHSPlotData;

const polar2cart = (r: number, theta: number, phi: number) => {
  return [
    r * Math.sin(theta) * Math.cos(phi),
    r * Math.sin(theta) * Math.sin(phi),
    r * Math.cos(theta),
  ] as [number, number, number];
};

const degToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const parseBHSPlotToLayout = (resultsBHSConfig: FullBHSConfig) => {
  const b = resultsBHSConfig.borefield;

  const bhsCoords = {
    x: [] as (number | null)[],
    y: [] as (number | null)[],
    z: [] as (number | null)[],
  };
  const topPos = {
    x: [] as number[],
    y: [] as number[],
    z: [] as number[],
  };
  resultsBHSConfig.borefield.x.forEach((x, i) => {
    const y = resultsBHSConfig.borefield.y[i];
    topPos.x.push(x);
    topPos.y.push(y);
    topPos.z.push(0);

    const cart = polar2cart(
      resultsBHSConfig.borefield.H,
      degToRadians(b.tilt),
      degToRadians(b.orientation)
    );
    bhsCoords.x.push(x, cart[0] + x, null);
    bhsCoords.y.push(y, cart[1] + y, null);
    bhsCoords.z.push(0, -cart[2] - b.D, null);
  });
  console.log(bhsCoords);
  return [
    {
      ...bhsCoords,
      type: "scatter3d",
      mode: "lines",
      name: "Boreholes",
      line: { width: 4, color: "darkblue" },
      opacity: 0.25,
      hoverinfo: "skip",
    },
    {
      ...topPos,
      type: "scatter3d",
      mode: "markers",
      name: "Origin",
      marker: { size: 5, color: "darkblue" },
      hoverinfo: "skip",
    },
  ] as Partial<PlotData>[];
};
