import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import LoadingOverlay from "components/basic/LoadingOverlay";

interface Props {
  defaultCode: string;
  onUpdate: (updated: string) => void;
}

const CodeEditor: React.FC<Props> = ({ defaultCode, onUpdate }) => {
  const [editorReady, setEditorReady] = useState(false);
  return (
    <div className="h-80 rounded border border-gray-200 shadow-md relative overflow-hidden py-1">
      <Editor
        height="100%"
        language="python"
        defaultValue={defaultCode}
        options={{
          scrollBeyondLastLine: false,
          minimap: { enabled: false },
        }}
        onMount={(editor, monaco) => {
          setEditorReady(true);
          console.log(editor);
        }}
        onChange={(val) => {
          val && onUpdate(val);
        }}
      />
      {!editorReady && <LoadingOverlay />}
    </div>
  );
};

export default CodeEditor;
