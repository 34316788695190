import { EMHeatPumpDesignerClient } from "./client/NodeServiceClientPb";
import {
  Compressor,
  Condition,
  ConfigurationCondition,
  ConfigurationConditionList,
  HeatExchangerConfiguration,
  HeatpumpConfiguration,
} from "./client/node_pb";

const stdServer = "https://bitzer-api.numerously.com:8090";

let GRPCServer = stdServer;

let hpClient = new EMHeatPumpDesignerClient(GRPCServer);

//@ts-ignore
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
enableDevTools([hpClient]);

export const runConditions = (
  heatpumps: HeatpumpConfiguration.AsObject[],
  conditions: Condition.AsObject[]
) => {
  const configList = new ConfigurationConditionList();

  heatpumps.forEach((heatpump) => {
    const {
      circuitType,
      refrigerant,
      compressor: compressorObject,
      condenser,
      evaporator,
      subcooler,
      numberOfCompressors,
      id,
      name,
    } = heatpump;

    if (!compressorObject || !evaporator || !subcooler || !condenser) {
      console.log("Error, incomplete heatpump config");
      return;
    }

    const { compressor, cr, ds, model, net, ov, serie, usefulSuperheat } = compressorObject;
    const compressorClass = new Compressor()
      .setCompressor(compressor)
      .setCr(cr)
      .setDs(ds)
      .setModel(model)
      .setNet(net)
      .setOv(ov)
      .setSerie(serie)
      .setUsefulSuperheat(usefulSuperheat);

    const condenserClass = new HeatExchangerConfiguration()
      .setConcentration(condenser.concentration)
      .setFluid(condenser.fluid)
      .setPlates(condenser.plates)
      .setType(condenser.type);

    const evaporatorClass = new HeatExchangerConfiguration()
      .setConcentration(evaporator.concentration)
      .setFluid(evaporator.fluid)
      .setPlates(evaporator.plates)
      .setType(evaporator.type);

    const subcoolerClass = new HeatExchangerConfiguration()
      .setConcentration(subcooler.concentration)
      .setFluid(subcooler.fluid)
      .setPlates(subcooler.plates)
      .setType(subcooler.type);

    //put it all together:
    const heatpumpConf = new HeatpumpConfiguration()
      .setId(id)
      .setName(name)
      .setCircuitType(circuitType)
      .setRefrigerant(refrigerant)
      .setCompressor(compressorClass)
      .setCondenser(condenserClass)
      .setEvaporator(evaporatorClass)
      .setSubcooler(subcoolerClass)
      .setNumberOfCompressors(numberOfCompressors);

    const configCondition = new ConfigurationCondition();
    configCondition.setConfiguration(heatpumpConf);

    conditions.forEach((conditonObject) => {
      const {
        name,
        subcoolingAbsoluteTemp,
        tCondGuess,
        tEvapGuess,
        tFluid2CondIn,
        tFluid2CondOut,
        tFluid2EvapIn,
        tFluid2EvapOut,
        tFluid2SubIn,
        tFluid2SubOut,
        tLiquidSubcooling,
        tSubcoolCondenser,
        tSuperheat,
        id,
      } = conditonObject;

      const condition = new Condition();
      condition
        .setName(name)
        .setSubcoolingAbsoluteTemp(subcoolingAbsoluteTemp)
        .setTCondGuess(tCondGuess)
        .setTEvapGuess(tEvapGuess)
        .setTFluid2CondIn(tFluid2CondIn)
        .setTFluid2CondOut(tFluid2CondOut)
        .setTFluid2EvapIn(tFluid2EvapIn)
        .setTFluid2EvapOut(tFluid2EvapOut)
        .setTFluid2SubIn(tFluid2SubIn)
        .setTFluid2SubOut(tFluid2SubOut)
        .setTLiquidSubcooling(tLiquidSubcooling)
        .setTSubcoolCondenser(tSubcoolCondenser)
        .setTSuperheat(tSuperheat)
        .setId(id);

      configCondition.addCondition(condition);
    });
    configList.addConfigurationConditionList(configCondition);
  });

  console.log(configList);

  const stream = hpClient.iterateConfigurationCondition(configList);
  return stream;
};
