import { useTeams } from "api/useFirebase";
import React, { useState } from "react";
import TeamCard from "./TeamCard";
import NewTeam from "./NewTeam";
import { useUserRole } from "api/useAuth";
import AddIcon from "../basic/icons/AddIcon";

interface Props {}

const TeamsSection = (props: Props) => {
  const teams = useTeams();
  const { hasProjectEditAccess, hasSimulatorAccess } = useUserRole();
  const [addingTeam, setAddingTeam] = useState(false);

  return (
    <div>
      {hasSimulatorAccess && (
        <>
          <div className={`font-bold text-gray-700 text-xl mb-4 pl-4`}>Teams</div>
          {teams.length > 0 && (
            <div className="flex flex-wrap mb-4">
              {teams.map((team, i) => {
                return <TeamCard team={team} key={team.id} i={i}></TeamCard>;
              })}
            </div>
          )}
          {teams.length === 0 && (
            <div className="italic text-sm px-4 mb-4">
              When teams are created they will appear here.
            </div>
          )}
          {hasProjectEditAccess && (
            <div className="px-4 pb-8 w-1/3">
              <button
                onClick={() => setAddingTeam(true)}
                className={`flex items-center justify-center border-2 px-4 py-1 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
              >
                <AddIcon className="w-4 h-4" />
                <span data-test="addNewTeam" className="pl-4 font-medium text-xs">
                  Add new team
                </span>
              </button>
            </div>
          )}
        </>
      )}
      {addingTeam && <NewTeam onFinish={() => setAddingTeam(false)} />}
    </div>
  );
};

export default TeamsSection;
