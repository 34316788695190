import { useState, useEffect, useContext, useCallback } from "react";
import { ScenarioStateEdited, SimulationScenario } from "model/datatypes";
import { store } from "store";
import { useFirebase } from "api/useFirebase";
import { checkScenarioStatus } from "utils/checkScenarioStatus";
import { NewScenarioAction } from "../NewScenarioReducer";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

export const useSimulationSaver = (
  scenarioState: ScenarioStateEdited,
  scenarioDispatch: React.Dispatch<NewScenarioAction>
) => {
  const [scenarioStatus, setscenarioStatus] =
    useState<SimulationScenario["status"]>("incomplete");
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const { state } = useContext(store);
  const { projectID } = state;

  const saveScenario = useCallback(
    (scenario: SimulationScenario) => {
      return new Promise<void>((resolve, reject) => {
        if (projectID && !loading) {
          setLoading(true);
          const fs = fb.firestore();
          const batch = fs.batch();

          const { id, created, ...updateableScenarioFields } = scenario;

          console.log({ updateableScenarioFields });

          const scenarioDoc = fs
            .collection("Projects")
            .doc(projectID)
            .collection("Scenarios")
            .doc(id);
          batch.update(
            scenarioDoc,
            convertToFirestoreFormat(
              {
                ...updateableScenarioFields,
                // harvester,
                sim_api_id: undefined,
              },
              true
            )
          );

          batch
            .commit()
            .then(() => {
              setLoading(false);
              resolve();
            })
            .catch((error) => {
              setLoading(false);
              reject(error);
            });
        } else {
          console.log({ projectID, loading });
          reject("Error trying to save...");
        }
      });
    },
    [projectID, loading, fb]
  );

  const saveSimulation = useCallback(() => {
    const { changed, pendingSync, inputDataInfo, system, ...scenarioParameters } =
      scenarioState;
    const scenario: SimulationScenario = {
      ...scenarioParameters,
      status: scenarioStatus,
    };
    return saveScenario(scenario);
  }, [scenarioStatus, scenarioState, saveScenario]);

  useEffect(() => {
    //effect to update status of newest scenario state.
    const updatedStatus = checkScenarioStatus(scenarioState);
    setscenarioStatus(updatedStatus);
  }, [scenarioState]);

  const [autoSavingInProgress, setAutoSavingInProgress] = useState(false);
  useEffect(() => {
    //effect to save scenario when sync is pending
    if (scenarioState.pendingSync && scenarioStatus && !autoSavingInProgress) {
      setAutoSavingInProgress(true);
      const { changed, pendingSync, inputDataInfo, system, ...scenarioParameters } =
        scenarioState;

      const scenario: SimulationScenario = {
        ...scenarioParameters,
        status: scenarioStatus,
      };
      saveScenario(scenario)
        .then(() => {
          scenarioDispatch({ type: "SCENARIO_SAVED" });
          setAutoSavingInProgress(false);
        })
        .catch((error) => {
          console.log(error);
          setAutoSavingInProgress(false);
        });
    }
  }, [scenarioState, scenarioStatus, scenarioDispatch, saveScenario, autoSavingInProgress]);

  return { saveSimulation, loading };
};
