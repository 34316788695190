import {
  FilePaths,
  Scenario,
  SignupData,
  SystemRequest,
  UserRequest,
} from "grpc/client/spm_pb";
import { getGRPCClient, getGRPCClientUnauthorized } from "grpc/grpcClient";

export const deleteScenario = (
  grpcAPI: string,
  idToken: string,
  projectId: string,
  scenarioId: string
) => {
  const scenario = new Scenario().setProject(projectId).setScenario(scenarioId);
  return getGRPCClient(grpcAPI, idToken).deleteScenario(scenario, {
    authorization: idToken,
  });
};

export const deleteSystemModel = (grpcAPI: string, idToken: string, systemId: string) => {
  // console.log({ systemId, idToken, grpcAPI });
  const systemRequest = new SystemRequest().setSystem(systemId);
  return getGRPCClient(grpcAPI, idToken).deleteSystem(systemRequest, {
    authorization: idToken,
  });
};

export const getFileURL = async (grpcAPI: string, idToken: string, filePath: string) => {
  const systemRequest = new FilePaths().addPaths(filePath);
  console.log(systemRequest.toObject());
  const fileSignedUrls = await getGRPCClient(grpcAPI, idToken).getSignedURLs(systemRequest, {
    authorization: idToken,
  });
  console.log(fileSignedUrls.toObject());
  const url = fileSignedUrls.getFilesList()[0].getUrl();
  if (!url) throw new Error("No url returned by API");
  return url;
};

export const completeUserSignup = (
  grpcAPI: string,
  signUpData: {
    invitationID: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    organisation: string;
  }
) => {
  const signupData = new SignupData()
    .setEmail(signUpData.email)
    .setPassword(signUpData.password)
    .setInvitationid(signUpData.invitationID)
    .setFirstname(signUpData.firstName)
    .setLastname(signUpData.lastName)
    .setOrganisation(signUpData.organisation);
  return getGRPCClientUnauthorized(grpcAPI).completeUserSignup(signupData, null);
};

export const deleteUser = (grpcAPI: string, idToken: string, userId: string) => {
  const userRequest = new UserRequest().setUser(userId);
  return getGRPCClient(grpcAPI, idToken).deleteUser(userRequest, {
    authorization: idToken,
  });
};
