import EditableName from "components/basic/EditableName";
import SimulationComponent from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { ComponentTypeOptions } from "components/systems/editSystem/componentEditor/ComponentEditor";
import ParametersEditTable from "components/systems/editSystem/componentEditor/parameters/ParametersEditTable";
import InputEditor from "components/systems/editSystem/componentEditor/variable/InputEditor";
import {
  useEditSystemDispatch,
  useEditSystemState,
} from "components/systems/editSystem/EditSystemStore";
import dayjs from "dayjs";
import { ComponentParamType, ComponentType, SimulationJobType } from "model/datatypes";
import { useMemo } from "react";
import { useGlobalState } from "store";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { ExtensionCompProps, ExtensionCompTypeProps } from "../ExtensionComponents";
import FMUSelecter from "./FMULoader";

export const FMUCompTypeEditor: React.FC<ExtensionCompTypeProps> = ({
  componentType,
  removeComponent,
  maxOrder,
  onMove,
}) => {
  const { user } = useGlobalState();

  const editSystemDispatch = useEditSystemDispatch();
  const { editedSystem } = useEditSystemState();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...componentType, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const editableParameters = useMemo(() => {
    return componentType.parameters.filter((param) => param.id !== "fmu_file");
  }, [componentType.parameters]);

  return (
    <div className="card my-4 relative">
      <div className="flex justify-between">
        <EditableName
          className="w-26"
          loading={false}
          name={componentType.displayName}
          onChange={(newName) => {
            updateComponent({
              displayName: newName,
            });
          }}
        />
        <ComponentTypeOptions
          comp={componentType}
          maxOrder={maxOrder}
          onMove={onMove}
          removeComponent={removeComponent}
        />
      </div>

      {editedSystem && user && (
        <FMUSelecter
          componentType={componentType}
          fmiJob={editedSystem?.jobs["FMI_STD_JOB"]}
          systemID={editedSystem.id}
          user={user}
          updateComponent={(updatedComp) => {
            editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: updatedComp });
          }}
          updateSystemJob={(updatedJob) => {
            editSystemDispatch({
              type: "UPDATE_SYSTEM",
              payload: { jobs: { ...editedSystem.jobs, FMI_STD_JOB: updatedJob } },
            });
          }}
        />
      )}

      {editableParameters.length > 0 && (
        <ParametersEditTable
          componentParameters={editableParameters}
          onUpdateParam={(updated) => {
            const updatedParameters = updateArrayValUUID(
              componentType.parameters,
              updated
            ) as ComponentParamType[];
            updateComponent({
              parameters: updatedParameters,
            });
          }}
          onUpdateAllParms={(updated) => {
            updateComponent({
              parameters: updated,
            });
          }}
          onDelete={(param) => {
            updateComponent({
              parameters: componentType.parameters.filter((cp) => cp.uuid !== param.uuid),
            });
          }}
        />
      )}
      {editableParameters.length > 0 && componentType.inputVariables.length > 0 && (
        <div className="my-4" />
      )}
      {componentType.inputVariables.length > 0 && (
        <InputEditor
          componentVariables={componentType.inputVariables}
          onDelete={(inputVar) => {
            updateComponent({
              inputVariables: componentType.inputVariables.filter(
                (cv) => cv.uuid !== inputVar.uuid
              ),
            });
          }}
          onUpdate={(updatedInputVar) => {
            const updatedVariables = updateArrayValUUID(
              componentType.inputVariables,
              updatedInputVar
            );
            updateComponent({
              inputVariables: updatedVariables,
            });
          }}
          onUpdateAll={(updatedVars) => {
            updateComponent({
              inputVariables: updatedVars,
            });
          }}
        />
      )}
    </div>
  );
};

export const FMUInstanceEditor: React.FC<ExtensionCompProps> = ({
  component,
  updateComponent,
  scenarioState,
  scenarioDispatch,
  model,
}) => {
  return (
    <SimulationComponent
      model={model}
      component={component}
      scenarioDispatch={scenarioDispatch}
      scenarioState={scenarioState}
    />
  );
};

const defaultStartTime = dayjs().startOf("year");
const defaultEndTime = defaultStartTime.add(1, "day");

export const stdFMIJob: SimulationJobType = {
  isMain: true,
  runSettings: {
    startDate: defaultStartTime,
    endDate: defaultEndTime,
    endTimeVal: {
      value: defaultEndTime.valueOf() - defaultStartTime.valueOf(),
      unit: "hours",
    },
    runMode: "duration",
  },
  name: "Simulation",
  id: "FMI_STD_JOB",
  image: {
    name: "FMI simulation image",
    path: "gcr.io/numerous-development/numerous-fmi:v0.1.16",
    parameters: [],
    id: "FMI_STD_IMG",
  },
};

export const StdFMUFileParam: ComponentParamType = {
  displayMode: "hidden",
  fileQuery: { tags: ["fmu"] },
  type: "file",
  displayName: "FMU",
  id: "fmu_file",
  uuid: "7cbcc0a4_2b0f_418a_8b81_fb4f7537e6f9",
  value: null,
};

export const FMUComponentType: ComponentType = {
  id: "fmu",
  item_class: "fmu",
  type: "fmu",
  name: "fmu",
  displayName: "FMU",
  parameters: [],
  order: 0,
  inputVariables: [],
  instantiationRules: { allowDisabling: false, isMain: true },
};

export type FMUConfiguration = {
  modelName: string;
  continuous_states: number;
  defaultExperiment: {
    startTime?: number;
    stopTime?: number;
    tolerance?: number;
    stepSize?: number;
  };
  description: string;
  fmiTypes: string[];
  generationTool: string;
  generationDateAndTime: string;
  parameters: FMUParameter[];
  inputs: FMUParameter[];
  numberOfContinuousStates: number;
  numberOfEventIndicators: number;
  no_variables: number;
  outputs: FMUParameter[];
  platforms: string[];
  fmiVersion: string;
};

type FMUParameter = {
  name: string;
  description: string;
  unit: string | null;
  displayUnit: string | null;
  start: string;
  causality: "input" | "parameter" | "output";
  initial: number | null;
  max: number | null;
  min: number | null;
  nominal: number | null;
  quantity: number | null;
  relativeQuantity: boolean;
  type: "Real" | "Integer" | "Boolean" | "String" | "Enumeration";
  declaredType: boolean;
};
