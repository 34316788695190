import React, { useState, useEffect, useMemo } from "react";
import { useGlobalState } from "store";
import {
  ScenarioOutputFile,
  ScheduledSimulationTask,
  SimulationJob,
  SimulationScenario,
} from "model/datatypes";
import { useFirebase, useFirestore } from "api/useFirebase";
import { OutputFile, SmulationPipelineIcon } from "./PipelineControls";
import DataOutput from "./DataOutput";
import RunJob from "../newSimulation/simSetup/runJob/RunJob";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import {
  convertFromFirestoreFormatNew,
  convertToFirestoreFormat,
} from "utils/firebase/firestoreFormatter";
import ScheduleIcon from "components/basic/icons/ScheduleIcon";
import { Popup } from "components/basic/Popup";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import { fsFieldvalue } from "utils/firebase/helpers";
import dayjs from "dayjs";
import { hardResetJob, stopJob } from "grpc/grpcClient";
import Modal from "components/basic/Modal";
import ConsoleLogger from "../ConsoleLogger";

interface Props {
  scenario: SimulationScenario;
}

type DeprecatedReport = {
  report_path: string;
  bucket_id: string;
  timestamp: string;
};

const ScenarioOverview: React.FC<Props> = ({ scenario }) => {
  const fb = useFirebase();
  const { projectID } = useGlobalState();

  const oldFile = useMemo(() => {
    //@ts-ignore
    if (!scenario.report_download) return null;
    //@ts-ignore
    const reportDownload = scenario.report_download as DeprecatedReport;
    const file: ScenarioOutputFile = {
      bucket_id: reportDownload.bucket_id,
      fileName: "old_report",
      fileType: "html",
      report_path: reportDownload.report_path,
      timestamp: dayjs(reportDownload.timestamp, "YYYY-MM-DD HH:mm:ss"), //2020-07-05 13:57:29
    };
    return file;
  }, [scenario]);
  const scheduledTasks = useSchedules(scenario.scheduled);

  const [loading, setLoading] = useState(false);

  const removeSchedule = async (scheduleTask: ScheduledSimulationTask) => {
    if (!loading && projectID) {
      setLoading(true);
      const fs = fb.firestore();
      const batch = fs.batch();
      const scheduleDoc = fs.collection("ScheduledSimulationTasks").doc(scheduleTask.id);
      batch.delete(scheduleDoc);

      const scenarioDoc = fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenario.id);
      batch.update(scenarioDoc, { scheduled: fsFieldvalue.arrayRemove(scheduleTask.id) });

      batch
        .commit()
        .then(() => {
          setLoading(false);
          Toast("Schedule canceled", { icon: "success" });
        })
        .catch((error) => {
          setLoading(false);
          Toast("Error removing schedule..", { icon: "error" });
        });
    }
  };

  const renderScheduleInfo = (schedule: ScheduledSimulationTask) => {
    return (
      <div className=" mr-4 cursor-pointer">
        <Popup
          mt={20}
          align={"left"}
          content={(closeMe) => (
            <div className="text-xs">
              <button
                className={`button-popup`}
                onClick={() => {
                  removeSchedule(schedule);
                  closeMe();
                }}
              >
                Remove schedule
              </button>
            </div>
          )}
        >
          <div className="text-xs z-10 flex items-center px-4 py-1 rounded-full border border-gray-200 cursor-pointer">
            <ScheduleIcon />
            <div className="ml-2">
              <div className="font-medium leading-tight">{schedule.job.name}</div>
              <div className="italic leading-tight">
                {schedule.launchTime.format("HH:mm DD/MM")}
              </div>
              {schedule.runInterval && (
                <div className="italic leading-tight">
                  Repeating every {schedule.runInterval.displayValue}
                  {schedule.runInterval.displayUnit}s
                </div>
              )}
            </div>
          </div>
        </Popup>
      </div>
    );
  };

  const togglePublishFile = async (file?: ScenarioOutputFile) => {
    if (scenario.id && projectID && !loading) {
      setLoading(true);
      try {
        const scenarioDoc = fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenario.id);
        await scenarioDoc.update(
          convertToFirestoreFormat({ publishedFile: file, hasFilePublished: !!file }, true)
        );
        setLoading(false);
      } catch (error) {
        Toast("Error updating scenario", { icon: "warning" });
        console.log(error);
        setLoading(false);
      }
    }
  };

  const outputFiles = useMemo(() => {
    if (typeof scenario.output_files === "object") {
      return Object.entries(scenario.output_files);
    }
  }, [scenario.output_files]);

  return (
    <>
      <div className=" flex z-10 bg-white">
        <div className="w-1/3 px-8 py-2 border-r border-gray-200">
          <SimulationJobsOverview scenario={scenario} scheduledTasks={scheduledTasks} />
        </div>
        <div className="w-1/3 px-8 py-2 border-r border-gray-200">
          <DataOutput scenario={scenario} />
        </div>
        <div className="w-1/3 px-8  py-2">
          {outputFiles &&
            outputFiles.map(([fileName, file]) => {
              const isPublished = scenario.publishedFile?.fileName === file.fileName;
              // const reportNr = parseInt(fileName);

              // // const displayName = isNaN(reportNr)
              // //   ? fileName
              // //   : `Report ${reportNr > 1 ? reportNr : ""}`;

              return (
                <OutputFile
                  scenario={scenario}
                  file={file}
                  fileName={file.fileName || "Report"}
                  key={fileName}
                  reportLink={`/reports/${projectID}/${scenario.id}/${fileName}`}
                  isDeprecated={false}
                  isPublished={isPublished}
                  onPublish={() => {
                    if (isPublished) togglePublishFile(undefined);
                    else togglePublishFile(file);
                  }}
                />
              );
            })}
          {oldFile && (
            <OutputFile
              scenario={scenario}
              file={oldFile}
              fileName={"Report"}
              isDeprecated={true}
              isPublished={false}
            />
          )}
          {(!outputFiles || outputFiles.length === 0) && (
            <div className="text-xs italic">Output files will appear here</div>
          )}
        </div>
      </div>
      {scheduledTasks && (
        <div className="flex px-6 pb-4 z-10">
          {scheduledTasks.map((scheduledTask) => renderScheduleInfo(scheduledTask))}
        </div>
      )}

      {loading && <LoadingOverlay />}
    </>
  );
};

export default ScenarioOverview;

const useSchedules = (scheduleIDs?: string[]) => {
  const fs = useFirestore();
  const [schedule, setSchedule] = useState<null | ScheduledSimulationTask[]>(null);

  useEffect(() => {
    if (scheduleIDs) {
      const unsubs = scheduleIDs.map((scheduleID) => {
        return fs
          .collection("ScheduledSimulationTasks")
          .doc(scheduleID)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const task = convertFromFirestoreFormatNew({
                ...doc.data(),
                id: doc.id,
              }) as ScheduledSimulationTask;
              setSchedule((p) => (p ? updateArrayVal(p, task) : [task]));
            } else setSchedule(null);
          });
      });
      return () => {
        unsubs.forEach((unsub) => unsub());
      };
    } else setSchedule(null);
  }, [scheduleIDs, fs]);
  return schedule;
};

const SimulationJobsOverview: React.FC<{
  scenario: SimulationScenario;
  scheduledTasks: ScheduledSimulationTask[] | null;
}> = ({ scenario, scheduledTasks }) => {
  const { projectID, user, gproject, grpcURL } = useGlobalState();

  const [startingJob, setStartingJob] = useState<null | SimulationJob>(null);
  const [showJobLog, setShowJobLog] = useState<SimulationJob | null>(null);

  const [loading, setLoading] = useState(false);

  const mainJob = useMemo(
    () => Object.entries(scenario.jobs).find(([id, job]) => job.isMain),
    [scenario.jobs]
  );

  const otherJobs = useMemo(
    () => Object.entries(scenario.jobs).filter(([id, job]) => !job.isMain),
    [scenario.jobs]
  );

  const renderJob = ([id, job]: [string, SimulationJob]) => {
    const scheduledTask = scheduledTasks?.find((task) => task.job.id === job.id);

    return (
      <SmulationPipelineIcon
        key={id}
        pipelineReady={true}
        job={job}
        onStart={() => {
          if (scheduledTask)
            Toast("Remove scheduled task before running", { icon: "warning" });
          else setStartingJob(job);
        }}
        onStop={() => {
          !loading && onStop(job);
        }}
        onHardReset={() => {
          onHardReset(job);
        }}
        onShowLog={() => {
          setShowJobLog(job);
        }}
      />
    );
  };

  const onHardReset = async (job: SimulationJob) => {
    if (job.status.status !== "finished" && projectID && user && gproject && !loading) {
      try {
        setLoading(true);

        const idToken = await user.fbUser.getIdToken();

        await hardResetJob(grpcURL, idToken, {
          projectId: projectID,
          scenarioId: scenario.id,
          jobId: job.id,
          organisationId: gproject,
          userId: user.id,
        }); //Sucess?

        Toast("Requested a hard reset for job.", { icon: "warning" });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        Toast("Error resetting job");
      }
    } else {
      Toast("Unable to request reset job");
    }
  };

  const onStop = async (job: SimulationJob) => {
    if (job.status.status !== "finished" && projectID && user && gproject && !loading) {
      try {
        setLoading(true);
        const idToken = await user.fbUser.getIdToken();

        await stopJob(grpcURL, idToken, {
          projectId: projectID,
          scenarioId: scenario.id,
          jobId: job.id,
          organisationId: gproject,
          userId: user.id,
        }); //Sucess?
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        Toast("Error stopping job");
      }
    } else {
      Toast("Unable to request stop job");
    }
  };

  return (
    <>
      <div className="flex">
        {mainJob && renderJob(mainJob)}
        {otherJobs?.map(renderJob)}
      </div>

      {startingJob && projectID && (
        <RunJob
          scenario={scenario}
          onFinish={() => setStartingJob(null)}
          projectID={projectID}
          job={startingJob}
        />
      )}
      {showJobLog && (
        <Modal zIndex={30} onClose={() => setShowJobLog(null)}>
          <ConsoleLogger scenarioName={scenario.scenarioName} job={showJobLog} />
        </Modal>
      )}
    </>
  );
};
