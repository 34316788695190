import { DualButton } from "components/basic/ToggleButtonGroup";

import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { Component, ScenarioStateEdited } from "model/datatypes";
import React, { useCallback, useMemo } from "react";
import BHSLibrarySelecter from "./bhs/BHSLibrarySelecter";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}
const PrimarySource: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const FC = useMemo(
    () => scenarioState.simComponents.find((comp) => comp.name === "FC"),
    [scenarioState.simComponents]
  );
  const BHS = useMemo(
    () => scenarioState.simComponents.find((comp) => comp.name === "BHS"),
    [scenarioState.simComponents]
  );

  const systemType = useMemo(() => {
    if (FC!.disabled === false) return "FC";
    else if (BHS!.disabled === false) return "BHS";
    else return undefined;
  }, [FC, BHS]);

  const editBHS = useCallback(
    (updates: Partial<Component>) => {
      scenarioDispatch({
        type: "UPDATE_COMPONENT",
        payload: {
          ...BHS!,
          ...updates,
        },
      });
    },
    [BHS, scenarioDispatch]
  );

  // const demand = useMemo(() => {
  //   //get the input demand to pass to BHS:

  // }, []);

  return (
    <div className="bg-white">
      <DualButton
        optionOne="Borehole storage"
        optionTwo="Air source"
        active={systemType && (systemType === "BHS" ? "one" : "two")}
        onClickOne={() => {
          let updatedFC = { ...FC!, disabled: true };
          scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updatedFC });

          let updatedBHS = { ...BHS!, disabled: false };
          scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updatedBHS });
        }}
        onClickTwo={() => {
          let updatedFC = { ...FC!, disabled: false };
          scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updatedFC });

          let updatedBHS = { ...BHS!, disabled: true };
          scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updatedBHS });
        }}
      />
      {systemType === "BHS" && <BHSLibrarySelecter BHSComponent={BHS!} editBHS={editBHS} />}
      {systemType === "FC" && (
        <FCConfigurator
          component={FC!}
          onUpdate={(updated) => {
            scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updated });
          }}
        />
      )}
    </div>
  );
};
export default PrimarySource;

const FCConfigurator: React.FC<{
  component: Component;
  onUpdate: (updated: Component) => void;
}> = ({ component, onUpdate }) => {
  return (
    <div className="text-xs my-4">
      <ComponentParameters component={component} onUpdate={onUpdate} />
    </div>
  );
};
