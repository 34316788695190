import React, { useMemo, useState } from "react";
import {
  ComponentType,
  ComponentParamType,
  FileQuery,
  ComponentTypeReference,
  SubCompRules,
} from "model/datatypes";
import { Popup } from "components/basic/Popup";
import SubComponentsRules from "./subcomponents/SubComponentsRules";
import { BooleanDropdownSelecter } from "components/basic/Dropdown";
import EditableName from "components/basic/EditableName";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import ParametersEditTable from "./parameters/ParametersEditTable";
import InputEditor from "./variable/InputEditor";
import InfoIcon from "components/basic/icons/InfoIcon";
import HoverTooltip from "components/basic/HoverTooltip";
import ConfirmDeleteButton from "components/basic/ConfirmDeleteButton";
import { deepEqual } from "utils/jsUtils/equality";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";
import { useEditSystemDispatch } from "../EditSystemStore";

type Props = {
  comp: ComponentType;
  updateComponent: (componentID: string, updates: Partial<ComponentType>) => void;
  removeComponent?: (componentID: string) => void;
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  allowedParamRefs: ComponentTypeReference[];
  onMove?: (dir: "up" | "down") => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  systemRef: FileQuery["modelRef"];
  maxOrder?: number;
};

const ComponentEditer: React.FC<Props> = ({
  comp,
  subCompOptions,
  removeComponent,
  onMove,
  maxOrder,
  allowedParamRefs,
  onDuplicate,
  onSaveToLib,
  systemRef,
}) => {
  const editSystemDispatch = useEditSystemDispatch();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...comp, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const [open, setOpen] = useState(false);
  // console.log("render componenteditor");

  const attachedSubCompRules = useMemo(() => {
    return (
      comp.subCompRules?.map((rule) => {
        const displayName =
          subCompOptions.find((option) => option.subCompRules.id === rule.id)?.displayName ||
          "Error..";
        return {
          subCompRules: rule,
          displayName,
        };
      }) || []
    );
  }, [comp.subCompRules, subCompOptions]);

  return (
    <div
      className={`border border-gray-300 rounded bg-white shadow w-full flex flex-col my-4`}
    >
      <CompTypeHeadEditor
        comp={comp}
        updateComponent={updateComponent}
        maxOrder={maxOrder}
        onDuplicate={onDuplicate}
        onSaveToLib={onSaveToLib}
        removeComponent={removeComponent}
        onMove={onMove}
        open={open}
        setOpen={setOpen}
      />
      {open && (
        <div className="flex-grow flex flex-col px-4 pb-4">
          <ParametersEditTable
            componentParameters={comp.parameters}
            systemRef={systemRef}
            onUpdateParam={(updated) => {
              const updatedParameters = updateArrayValUUID(
                comp.parameters,
                updated
              ) as ComponentParamType[];
              updateComponent({
                parameters: updatedParameters,
              });
            }}
            onUpdateAllParms={(updated) => {
              updateComponent({
                parameters: updated,
              });
            }}
            onDelete={(param) => {
              updateComponent({
                parameters: comp.parameters.filter((cp) => cp.uuid !== param.uuid),
              });
            }}
            allowedComponentRefs={allowedParamRefs}
          />
          <div className="my-4" />
          <InputEditor
            componentVariables={comp.inputVariables}
            onDelete={(inputVar) => {
              updateComponent({
                inputVariables: comp.inputVariables.filter((cv) => cv.uuid !== inputVar.uuid),
              });
            }}
            onUpdate={(updatedInputVar) => {
              const updatedVariables = updateArrayValUUID(
                comp.inputVariables,
                updatedInputVar
              );
              updateComponent({
                inputVariables: updatedVariables,
              });
            }}
            onUpdateAll={(updatedVars) => {
              updateComponent({
                inputVariables: updatedVars,
              });
            }}
          />
          <div className="my-4" />

          <SubComponentsRules
            attachedSubCompRules={attachedSubCompRules}
            subCompOptions={subCompOptions?.filter(
              (option) => option.subCompRules.id !== comp.id
            )}
            onAddSubComponent={(rule) => {
              const prevRules = comp.subCompRules || [];
              updateComponent({
                subCompRules: [...prevRules, rule],
              });
            }}
            onRemoveSubComponent={(rule) => {
              updateComponent({
                subCompRules: comp.subCompRules!.filter((c) => c.id !== rule.id),
              });
            }}
            onEditRules={(editedRule) => {
              updateComponent({
                subCompRules: updateArrayVal(comp.subCompRules!, editedRule),
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

const compareProps = (prev: Props, next: Props) => {
  let isEqual = true;
  // if (!shallowEqual(prev.allowedParamRefs, next.allowedParamRefs)) return false;
  if (!deepEqual(prev.comp, next.comp)) isEqual = false;

  return isEqual;
};

export default React.memo(ComponentEditer, compareProps);

export const CompTypeHeadEditor: React.FC<{
  comp: ComponentType;
  updateComponent: (updates: Partial<ComponentType>) => void;
  removeComponent?: (componentID: string) => void;
  onMove?: (dir: "up" | "down") => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  maxOrder?: number;
  open: boolean;
  setOpen: (updated: boolean) => void;
}> = ({
  comp,
  updateComponent,
  maxOrder,
  onDuplicate,
  onMove,
  onSaveToLib,
  removeComponent,
  open,
  setOpen,
}) => {
  const instantiationRules = useMemo(
    () => comp.instantiationRules || { isMain: true, allowDisabling: false },
    [comp.instantiationRules]
  );

  const renderComponentSettings = () => {
    return (
      <>
        <div className="flex pt-2">
          <div className="mr-4 flex flex-col">
            <div className="text-xs font-medium">Name</div>
            <input
              className={`input-box text-xs`}
              type="string"
              value={comp.name}
              onChange={(e) => {
                updateComponent({
                  name: e.target.value,
                  type: e.target.value,
                });
              }}
            />

            <div className={`text-xs font-medium mt-2`}>Item class</div>

            <input
              type="text"
              className={`input-box text-xs`}
              value={comp.item_class}
              onChange={(e) => {
                updateComponent({ item_class: e.target.value });
              }}
            />
          </div>
          <div className="mr-4 w-40 flex flex-col">
            <label className={`text-xs font-medium`}>Allow disabling</label>
            <BooleanDropdownSelecter
              className="w-40 text-xs mb-2"
              value={instantiationRules.allowDisabling}
              onChange={(newVal) => {
                updateComponent({
                  instantiationRules: { ...instantiationRules, allowDisabling: newVal },
                });
              }}
            />
            {instantiationRules.allowDisabling && (
              <>
                <label className={`text-xs font-medium`}>Enabled by default</label>
                <BooleanDropdownSelecter
                  className="w-40 text-xs mb-2"
                  value={!!instantiationRules.defaultEnabled}
                  onChange={(newVal) => {
                    updateComponent({
                      instantiationRules: {
                        ...instantiationRules,
                        defaultEnabled: newVal,
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
          <div className=" flex flex-col">
            <div className={`text-xs font-medium flex items-center`}>
              <span className="mr-1">Instantiate on start</span>
              <HoverTooltip
                mt={-35}
                text="If enabled component will be added to top level of new scenarios."
              >
                <InfoIcon className="w-4 h-4" />
              </HoverTooltip>
            </div>
            <BooleanDropdownSelecter
              className="w-40 text-xs mb-2"
              value={instantiationRules.isMain}
              onChange={(newVal) => {
                updateComponent({
                  instantiationRules: {
                    ...instantiationRules,
                    isMain: newVal,
                  },
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="py-4 px-4">
      <div className="flex justify-between items-center">
        <EditableName
          className="w-26"
          loading={false}
          name={comp.displayName}
          onChange={(newName) => {
            updateComponent({
              displayName: newName,
            });
          }}
        />
        <div className="inline-flex">
          <ComponentTypeOptions
            comp={comp}
            onDuplicate={onDuplicate}
            maxOrder={maxOrder}
            onMove={onMove}
            onSaveToLib={onSaveToLib}
            removeComponent={removeComponent}
          />
          <OpenCloseButton open={open} onClick={() => setOpen(!open)} />
        </div>
      </div>
      {open && renderComponentSettings()}
    </div>
  );
};

export const ComponentTypeOptions: React.FC<{
  comp: ComponentType;
  onMove?: (dir: "up" | "down") => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  maxOrder?: number;
  removeComponent?: (componentID: string) => void;
}> = ({ comp, onDuplicate, onMove, onSaveToLib, maxOrder, removeComponent }) => {
  return (
    <div className="flex items-center">
      <div className="mr-1 text-xs font-medium">{comp.order}</div>
      <Popup
        align={"right"}
        useHover
        className="text-xs"
        content={(closeMe) => {
          return (
            <>
              {onMove && typeof comp.order === "number" && comp.order > 0 && (
                <button
                  className={`button-popup border-b border-gray-400`}
                  onClick={() => {
                    onMove("up");
                    closeMe();
                  }}
                >
                  Move up
                </button>
              )}
              {onMove &&
                typeof comp.order === "number" &&
                typeof maxOrder === "number" &&
                comp.order < maxOrder && (
                  <button
                    className={`button-popup border-b border-gray-400`}
                    onClick={() => {
                      onMove("down");
                      closeMe();
                    }}
                  >
                    Move down
                  </button>
                )}
              {onDuplicate && (
                <button
                  className={`button-popup border-b border-gray-400`}
                  onClick={() => {
                    onDuplicate();
                  }}
                >
                  Duplicate component
                </button>
              )}
              {onSaveToLib && (
                <button
                  className={`button-popup border-b border-gray-400`}
                  onClick={() => {
                    onSaveToLib();
                  }}
                >
                  Save to library
                </button>
              )}
              {removeComponent && (
                <ConfirmDeleteButton
                  buttonText={"Delete component"}
                  warningHeadline={"Delete component?"}
                  onDelete={() => {
                    removeComponent(comp.id);
                  }}
                />
              )}
            </>
          );
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </Popup>
    </div>
  );
};
