const firebaseConfig = {
  apiKey: "AIzaSyAKFyHXXOtZopKzFb4jQPzuElri0I9wL0U",
  authDomain: "numerous-development.firebaseapp.com",
  projectId: "numerous-development",
  storageBucket: "numerous-development.appspot.com",
  messagingSenderId: "275421940058",
  appId: "1:275421940058:web:9466a8b8cb19f34e5bcb63",
  measurementId: "G-M4VZYBFZRQ",
  databaseURL: "https://numerous-development.firebaseio.com",
};

export default firebaseConfig;
