import { useEffect, useState, useCallback } from "react";

//returns an absolute position of a ref element on page

type PagePosition = {
  distanceLeft: number;
  distanceTop: number; //of scrollHeight
  distanceBottom: number; //of scrollHeight
  distanceRight: number;
  boundingRect?: DOMRect;
};

export const usePagePosition = (ref: React.RefObject<HTMLDivElement>) => {
  const [pagePosition, setPagePosition] = useState<PagePosition>({
    distanceLeft: 0,
    distanceTop: 0,
    distanceBottom: 0,
    distanceRight: 0,
  });

  const [width, setWidth] = useState(0);
  useEffect(() => {
    const w = ref.current?.offsetWidth;
    setWidth(w || 0);
  }, [ref]);

  useEffect(() => {
    //get the absolute page position for the floating ref:
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      const screenScrollPosX = window.pageXOffset || document.documentElement.scrollLeft;
      const screenScrollPosY = window.pageYOffset || document.documentElement.scrollTop;
      const screenMarginRight = window.innerWidth - screenScrollPosX - boundingRect.right;

      const contentBelowScreen =
        document.documentElement.scrollHeight - (window.innerHeight + screenScrollPosY); //pixels of content below the cuttent screen

      setPagePosition({
        distanceLeft: boundingRect.left + screenScrollPosX,
        distanceTop: boundingRect.top + screenScrollPosY,
        distanceBottom: window.innerHeight - boundingRect.bottom + contentBelowScreen,

        distanceRight: screenMarginRight,
        boundingRect,
      });
    }
  }, [ref]);

  const updatePosition = useCallback(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      const screenScrollPosX = window.pageXOffset || document.documentElement.scrollLeft;
      const screenScrollPosY = window.pageYOffset || document.documentElement.scrollTop;
      const screenMarginRight = window.innerWidth - screenScrollPosX - boundingRect.right;

      const contentBelowScreen =
        document.documentElement.scrollHeight - (window.innerHeight + screenScrollPosY); //pixels of content below the cuttent screen

      setPagePosition({
        distanceLeft: boundingRect.left + screenScrollPosX,
        distanceTop: boundingRect.top + screenScrollPosY,
        distanceBottom: window.innerHeight - boundingRect.bottom + contentBelowScreen,
        distanceRight: screenMarginRight,
        boundingRect,
      });
    }
  }, [ref]);

  return { pagePosition, updatePosition, width };
};
