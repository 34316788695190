import { useOrganisation } from "api/useFirebase";
import React from "react";
import UserAdministration from "./organisation/userAdministration/UserAdministration";
import TeamsSection from "./teams/TeamsSection";

const OrganisationPage: React.FC<{}> = () => {
  const organisation = useOrganisation();

  return (
    <div className={``}>
      <div className="px-8 py-8">
        <div className="text-xl font-extrabold" data-test={"organisationName"}>
          {organisation?.name}
        </div>
      </div>
      <UserAdministration />
      <div className="px-4 py-8">
        <TeamsSection />
      </div>
    </div>
  );
};

export default OrganisationPage;
