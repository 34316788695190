import PVPanelsIcon from "components/basic/icons/PVPanelsIcon";
import PopupMenu, { PopupMenuOption } from "components/basic/PopupMenu";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { Component, ScenarioStateEdited } from "model/datatypes";
import React, { useMemo } from "react";
import SmallComponentCard from "../basic/SmallComponentCard";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}
const ElectricitySources: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const BatteryComp = useMemo(() => {
    return scenarioState.simComponents.find((comp) => comp.name === "Battery");
  }, [scenarioState.simComponents]);

  const WindTurbineComp = useMemo(() => {
    return scenarioState.simComponents.find((comp) => comp.name === "WindTurbine");
  }, [scenarioState.simComponents]);

  const PV = useMemo(() => {
    const PVSystemComp = scenarioState.simComponents.find((comp) => comp.name === "PVT");
    const PVControlerComp = scenarioState.simComponents.find(
      (comp) => comp.name === "PVT_Controller"
    );

    const PVTEnabled =
      !!PVSystemComp && !PVSystemComp.disabled && PVControlerComp && !PVControlerComp.disabled;

    //If PV Panels are enabled without the controler:
    const PVEnabled = !!PVSystemComp && !PVSystemComp.disabled && PVControlerComp?.disabled;

    return { PVSystemComp, PVTEnabled, PVEnabled };
  }, [scenarioState.simComponents]);
  const sourceOptions = useMemo(() => {
    const options: PopupMenuOption[] = [];
    if (BatteryComp)
      options.push({
        id: BatteryComp.id,
        title: BatteryComp.displayName,
        description: "Add a battery to store electricity when producing excess",
        value: BatteryComp,
      });
    if (WindTurbineComp)
      options.push({
        id: WindTurbineComp.id,
        title: WindTurbineComp.displayName,
        description: "Add small wind turbine from windmachines.",
        value: WindTurbineComp,
      });
    if (!PV.PVTEnabled && PV.PVSystemComp)
      options.push({
        id: PV.PVSystemComp.id,
        title: "Solar panels",
        description: "Add PV panels to generate solar power.",
        value: PV.PVSystemComp,
      });
    return options;
  }, [BatteryComp, WindTurbineComp, PV]);

  const renderBatteryComponent = (BatteryComp: Component) => {
    return (
      <SmallComponentCard
        onRemove={() => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...BatteryComp, disabled: true },
          });
        }}
      >
        <div className="text-sm font-bold">{BatteryComp.displayName}</div>
        <div className="pt-4 text-xs">
          <ComponentParameters
            component={BatteryComp}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        </div>
      </SmallComponentCard>
    );
  };
  const renderWindTurbineComp = (WindTurbineComp: Component) => {
    return (
      <SmallComponentCard
        onRemove={() => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...WindTurbineComp, disabled: true },
          });
        }}
      >
        <div className="text-sm font-bold">{WindTurbineComp.displayName}</div>
        <div className="pt-4 text-xs">
          <ComponentParameters
            component={WindTurbineComp}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        </div>
      </SmallComponentCard>
    );
  };

  const renderPVComponent = (PVSystemComponent: Component) => {
    return (
      <SmallComponentCard
        onRemove={() => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...PVSystemComponent, disabled: true },
          });
        }}
      >
        <div className="flex items-center">
          <PVPanelsIcon className="h-8 w-8 mr-1" />
          <div className="text-sm font-bold">{PVSystemComponent.displayName}</div>
        </div>
        <div className="pt-4 text-xs">
          <ComponentParameters
            component={PVSystemComponent}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        </div>
      </SmallComponentCard>
    );
  };

  const renderAddSourceOptions = () => {
    return (
      <PopupMenu
        buttonText="Add seccondary source"
        options={sourceOptions}
        selectedOptions={sourceOptions.filter((so) => !so.value.disabled).map((so) => so.id)}
        onSelect={(option) => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...option.value, disabled: false },
          });
        }}
        closeOnClick
      />
    );
  };

  return (
    <>
      {renderAddSourceOptions()}
      {BatteryComp && !BatteryComp.disabled && renderBatteryComponent(BatteryComp)}
      {WindTurbineComp && !WindTurbineComp.disabled && renderWindTurbineComp(WindTurbineComp)}
      {PV.PVEnabled && PV.PVSystemComp && renderPVComponent(PV.PVSystemComp)}
    </>
  );
};

export default ElectricitySources;
