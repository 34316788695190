import React, { useEffect, useState } from "react";
import { SimulationScenario } from "model/datatypes";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";
import ReportIcon from "../basic/icons/ReportIcon";
import { useFirestore } from "api/useFirebase";

const PublishedReports: React.FC<{ projectID: string }> = ({ projectID }) => {
  const published = usePublishedReports(projectID);
  const baseURL = window.location.protocol + "//" + window.location.host;

  if (published.length > 0)
    return (
      <div className="py-8">
        <div className={`font-medium text-gray-700 text-xl mb-2`}>Published reports</div>
        <div className="flex flex-wrap">
          {published.map((scenario) => {
            const date = scenario.publishedFile?.timestamp.format("DD/MM YY");
            let description = scenario.description;
            if (description && description.length > 90)
              description = description.slice(0, 90) + "...";
            return (
              <a
                href={`${baseURL}/reports/${projectID}/${scenario.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className=" "
              >
                <div
                  key={scenario.id}
                  style={{ height: "12rem" }}
                  className={`w-56 border border-gray-200 shadow-lg rounded bg-white py-4 px-4 mx-4 mb-8 relative flex flex-col items-center cursor-pointer`}
                >
                  <ReportIcon className="w-16 h-16 text-green-numerous mb-2 " />
                  <div className="font-medium text-sm text-center">
                    {scenario.scenarioName}
                  </div>
                  {description && <div className="text-xs text-center">{description}</div>}
                  <div
                    className={`text-xs text-center ${
                      description ? "absolute bottom-0 right-0 mb-2 mr-4" : "mt-2"
                    }`}
                  >
                    {date}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  else return null;
};

const usePublishedReports = (projectID: string) => {
  const [scenarios, setscenarios] = useState<SimulationScenario[]>([]);
  const fs = useFirestore();
  useEffect(() => {
    if (projectID) {
      const usub = fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("hasFilePublished", "==", true)
        .orderBy("created", "desc")
        .onSnapshot((snap) => {
          const scenarios: SimulationScenario[] = [];
          snap.docs.forEach((doc) => {
            scenarios.push(
              convertFromFirestoreFormatNew({
                ...doc.data(),
                id: doc.id,
              }) as SimulationScenario
            );
          });
          console.log(scenarios);

          setscenarios(scenarios);
        });
      return () => {
        usub();
      };
    } else setscenarios([]);
  }, [fs, projectID]);
  return scenarios;
};

export default PublishedReports;
