/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as node_pb from './node_pb';


export class EMHeatPumpDesignerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoTestEndpoint = new grpcWeb.AbstractClientBase.MethodInfo(
    node_pb.TestResponse,
    (request: node_pb.TestInfo) => {
      return request.serializeBinary();
    },
    node_pb.TestResponse.deserializeBinary
  );

  testEndpoint(
    request: node_pb.TestInfo,
    metadata: grpcWeb.Metadata | null): Promise<node_pb.TestResponse>;

  testEndpoint(
    request: node_pb.TestInfo,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: node_pb.TestResponse) => void): grpcWeb.ClientReadableStream<node_pb.TestResponse>;

  testEndpoint(
    request: node_pb.TestInfo,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: node_pb.TestResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EMHeatPumpDesigner/TestEndpoint',
        request,
        metadata || {},
        this.methodInfoTestEndpoint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EMHeatPumpDesigner/TestEndpoint',
    request,
    metadata || {},
    this.methodInfoTestEndpoint);
  }

  methodInfoiterateConfigurationCondition = new grpcWeb.AbstractClientBase.MethodInfo(
    node_pb.ConfigurationConditionIterationResult,
    (request: node_pb.ConfigurationConditionList) => {
      return request.serializeBinary();
    },
    node_pb.ConfigurationConditionIterationResult.deserializeBinary
  );

  iterateConfigurationCondition(
    request: node_pb.ConfigurationConditionList,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/EMHeatPumpDesigner/iterateConfigurationCondition',
      request,
      metadata || {},
      this.methodInfoiterateConfigurationCondition);
  }

}

