export const getHeadersExampleCode = (
  language: "node" | "python",
  userIDToken: string,
  url: string
) => {
  if (language === "node") return headersExampleCodeNode(userIDToken, url);
  else return headersExampleCodePython(userIDToken, url);
};

const headersExampleCodeNode = (
  userIDToken: string,
  url: string
) => `const token = "${userIDToken}"
const baseURL = "${url}"
const headers = {
  "Content-type": "application/json",
  "Authorization": "Bearer " + token
}`;

const headersExampleCodePython = (
  userIDToken: string,
  url: string
) => `token = "${userIDToken}"
base_url = "${url}"
headers = {
  "Content-type": "application/json",
  "Authorization": "Bearer " + token
}`;
