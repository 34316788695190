import React, { useEffect, useRef, useState } from "react";
import { Canvas } from "react-three-fiber";
import { MathUtils, Mesh, Vector3 } from "three";
import InputNumber from "components/basic/headless/InputNumber";

import { useThree } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { ScenarioStateEdited } from "model/datatypes";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ZoneGeometry } from "./Zone";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
  geometry: ZoneGeometry;
  setGeometry: (updated: ZoneGeometry) => void;
}

export const GeometryInputs: React.FC<Props> = ({ geometry, setGeometry }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderInput = (name: string, value: number, key: string) => {
    return (
      <div className="flex items-center py-1">
        <div className={`w-1/2 `}>{name}</div>
        <InputNumber
          className={`input-box text-xs w-1/2 pl-2`}
          value={value}
          onChange={(val) => {
            setGeometry({ ...geometry, [key]: val });
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className="w-full cursor-pointer text-xs flex items-center"
        onClick={() => {
          setIsOpen((p) => !p);
        }}
      >
        <div className="font-bold w-1/3 flex-none">Building geometry</div>
        {isOpen ? (
          <div className="flex-grow" />
        ) : (
          <div className="flex-grow flex flex-wrap text-xxs">
            <div className="mr-2">{`Height: ${geometry.height}m`}</div>
            <div className="mr-2">{`Width: ${geometry.width}m`}</div>
            <div className="mr-2">{`Length: ${geometry.length}m`}</div>
            <div className="mr-2">{`Angle: ${geometry.azimuth}deg`}</div>
          </div>
        )}
        <OpenCloseArrow className="flex-none" isOpen={isOpen} />
      </div>
      {isOpen && (
        <div className="text-xs mt-2">
          {renderInput("Azimuth angle (deg)", geometry.azimuth, "azimuth")}
          {renderInput("Heigh (m)", geometry.height, "height")}
          {renderInput("Length (m)", geometry.length, "length")}
          {renderInput("Width (m)", geometry.width, "width")}
        </div>
      )}
    </>
  );
};

export const Geometry3dVisualise: React.FC<{
  geometry: ZoneGeometry;
  selected:
    | null
    | "walls"
    | "north"
    | "south"
    | "west"
    | "east"
    | "roof"
    | "height"
    | "length"
    | "width";
}> = ({ geometry, selected }) => {
  return (
    <Canvas
      orthographic
      camera={{
        position: [50, 50, 50],
        zoom: 4,
      }}
    >
      <ambientLight />
      <pointLight intensity={1} position={[80, 80, 140]} />

      <ThreeBox
        position={[0, 0, 0]}
        onSelect={() => {}}
        size={[geometry.width, geometry.height, geometry.length]}
        color={"#a3a3a3"}
        transparent={true}
        opacity={1}
      />

      <CameraController geometry={geometry} lookAtTarget={new Vector3(0, 0, 0)} />
    </Canvas>
  );
};

//controller for rotating view:
const CameraController = (props: { lookAtTarget: Vector3; geometry: ZoneGeometry }) => {
  const { lookAtTarget, geometry } = props;
  const { camera, gl, scene } = useThree();
  const [controller, setcontroller] = useState<OrbitControls | null>(null);

  useEffect(() => {
    if (controller) {
      controller.target = lookAtTarget;
      controller.update();
    }
  }, [lookAtTarget, controller]);

  useEffect(() => {
    const layoutSum = geometry.height + geometry.width + geometry.length;
    console.log({ layoutSum });
    if (layoutSum < 20) {
      camera.zoom = 10;
    } else if (layoutSum < 100) {
      camera.zoom = 7;
    } else if (layoutSum < 150) {
      camera.zoom = 5;
    } else if (layoutSum < 250) {
      camera.zoom = 3;
    } else if (layoutSum < 400) {
      camera.zoom = 2;
    } else {
      camera.zoom = 1;
    }
    camera.updateProjectionMatrix();
  }, [geometry, camera]);

  useEffect(() => {
    let controls = new OrbitControls(camera, gl.domElement);
    controls.maxPolarAngle = MathUtils.degToRad(80);
    controls.enableZoom = false;
    // scene.add(new AxesHelper(20));
    setcontroller(controls);
    return () => {
      controls.dispose();
    };
  }, [camera, gl, scene]);

  return null;
};

const ThreeBox: React.FC<{
  position: [number, number, number];
  size: [number, number, number];
  onSelect?: (point: Vector3) => void;
  color: string;
  opacity?: number;
  transparent?: true;
}> = ({ onSelect, position, size, color, opacity, transparent }) => {
  // This reference will give us direct access to the mesh
  const meshRef = useRef<Mesh>();
  // Set up state for the hovered and active state

  return (
    <mesh
      position={position}
      ref={meshRef}
      scale={[1, 1, 1]}
      onClick={(e) => {
        onSelect && onSelect(e.point);
      }}
    >
      {/* <boxBufferGeometry attach="geometry" args={size} /> */}
      <boxGeometry attach="geometry" args={size} />
      <meshStandardMaterial
        attach="material"
        color={color}
        transparent={transparent}
        opacity={opacity ? opacity : 1}
      />
    </mesh>
  );
};
