import { useIdToken } from "api/useFirebase";
import Toast from "components/basic/Toast";
import dayjs from "dayjs";
import { TimeInfo } from "model/datatypes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGlobalState } from "store";
import getUUID from "utils/jsUtils/getUUID";
import {
  DataList,
  ExeLogEntry,
  ReadScenario,
  Scenario,
  ScenarioMetaData,
} from "./client/spm_pb";
import {
  checkDatasetClosed,
  getGRPCClient,
  readDataExample,
  readLogs,
  readTimeInfo,
} from "./grpcClient";

export type Dataframe = {
  [key: string]: number[];
};

export const useGRPCData = (
  tags: string[],
  scenarioID: string,
  projectID: string,
  executionID?: string
) => {
  const cachedData = useRef<Dataframe>({});

  const { grpcURL, user } = useGlobalState();

  const [loadingData, setloadingData] = useState(false);
  const [data, setData] = useState<Dataframe | null>(null);
  const [time, setTime] = useState<number[] | null>(null);

  const latestExecutionID = useLatestExecutionID(scenarioID, projectID);

  useEffect(() => {
    const loadData = async () => {
      try {
        //Load through GRPC!:
        if (tags.length === 0 || !user || (!executionID && !latestExecutionID)) {
          setloadingData(false);
          setData(null);
          setTime(null);
          return;
        }

        const cached: Dataframe = {};
        const missing: string[] = [];
        tags.forEach((tag) => {
          const data = cachedData.current[tag];
          if (data) {
            cached[tag] = data;
          } else missing.push(tag);
        });
        if (missing.length === 0) {
          setData(cached);
          return;
        }

        //Load the data missing:
        setloadingData(true);
        console.log("read data;");
        const idToken = await user.fbUser.getIdToken();

        const loadedData = await readDataExample({
          grpcAPI: grpcURL,
          idToken,
          scenarioID,
          projectID,
          executionID: executionID || latestExecutionID!,
          tags,
        });

        console.log({ loadedData });
        setloadingData(false);
        setData({ ...loadedData, ...cached });
        const t = loadedData["_index"];
        if (t) setTime(t);
        cachedData.current = { ...cachedData.current, ...loadedData };
      } catch (error) {
        setloadingData(false);
        setData(null);
        setTime(null);
        Toast("Eror loading data", { icon: "error" });
      }
    };
    loadData();
  }, [tags, scenarioID, projectID, latestExecutionID, executionID, grpcURL, user]);
  return { loadingData, data, time };
};

export const useGRPCDataStream = (
  configuration: {
    listen: boolean;
    scenarioID: string;
    projectID: string;
    executionID?: string;
  },
  tags: string[]
) => {
  const { grpcURL } = useGlobalState();

  const latestExecutionID = useLatestExecutionID(
    configuration.scenarioID,
    configuration.projectID
  );

  const executionID = useMemo(() => {
    return configuration.executionID || latestExecutionID || undefined;
  }, [latestExecutionID, configuration.executionID]);

  const [data, setData] = useState<Dataframe | null>(null);
  const [time, setTime] = useState<number[] | null>(null);

  const [activeStreams, setActiveStreams] = useState<string[]>([]);

  const tagsRef = useRef<string[]>([]); //tags in open connection
  const connectionRef = useRef<{ id: string; close: () => void }[]>([]); //functions for closing connections

  const streamActive = useMemo(() => {
    return activeStreams.length > 0;
  }, [activeStreams]);

  const idToken = useIdToken();

  const [conf, setConf] = useState<{
    listen: boolean;
    scenarioID: string;
    projectID: string;
    executionID: string;
    grpcURL: string;
    idToken: string;
  } | null>(null);

  useEffect(() => {
    if (executionID && grpcURL && idToken) {
      setConf({
        listen: configuration.listen,
        scenarioID: configuration.scenarioID,
        projectID: configuration.projectID,
        executionID: executionID,
        grpcURL: grpcURL,
        idToken,
      });
    }

    return () => {
      setData({});
      setTime([]);
      //close connections on change
      connectionRef.current.forEach((connection) => {
        connection.close();
      });
      connectionRef.current = [];
    };
  }, [
    configuration.listen,
    configuration.scenarioID,
    configuration.projectID,
    executionID,
    grpcURL,
    idToken,
  ]);

  useEffect(() => {
    if (tags.length === 0 || !conf) {
      console.log("missing info to read data..");
      setData(null);
      setTime(null);
      return;
    }

    //get all tags to open a new connection for:
    const newTags = tags.filter((tag) => !tagsRef.current.some((t) => t === tag));
    tagsRef.current = [...tagsRef.current, ...newTags];

    //create a connection with supplied tags and set as active connection until finished or closed
    const createConnection = (tags: string[]) => {
      const connectionID = getUUID();
      setActiveStreams((prev) => [...prev, connectionID]);

      const onReadData = (updated: Dataframe) => {
        console.log({ updated });
        const t = updated["_index"];
        if (t)
          setTime((prev) => {
            if (prev && prev.length > t.length) return prev;
            else return t;
          });
        setData((prev) => {
          if (!prev) return updated;
          return { ...prev, ...updated };
        });
      };

      const onEnd = () => {
        console.log("stream ended");
        setActiveStreams((prev) => prev.filter((id) => id !== connectionID));
        connectionRef.current = connectionRef.current.filter((con) => con.id !== connectionID);
      };

      const onError = (error: any) => {
        if (error === "timeout") {
          Toast("Stream timed out before recieving initial response", { icon: "error" });
        } else {
          Toast("Error loading data", { icon: "error" });
        }
        console.log(error);
        setActiveStreams((prev) => prev.filter((id) => id !== connectionID));
        connectionRef.current = connectionRef.current.filter((con) => con.id !== connectionID);
      };

      const cancelStream = streamDataForScenario(
        {
          executionID: conf.executionID,
          scenarioID: conf.scenarioID,
          projectID: conf.projectID,
          listen: conf.listen,
          tags: tags,
          grpcURL: conf.grpcURL,
          idToken: conf.idToken,
        },
        onReadData,
        onEnd,
        onError
      );

      connectionRef.current = [
        ...connectionRef.current,
        {
          id: connectionID,
          close: cancelStream,
        },
      ];
      console.log({ allConnections: connectionRef.current, allTags: tagsRef.current });
    };
    if (newTags.length > 0) createConnection(newTags);
  }, [conf, tags]);

  return { data, time, streamActive };
};

const streamDataForScenario = (
  configuration: {
    grpcURL: string;
    idToken: string;
    scenarioID: string;
    projectID: string;
    executionID?: string;
    tags: string[];
    listen: boolean;
  },
  onReadData: (updated: Dataframe) => void,
  onEnd: () => void,
  onError: (error: any) => void
) => {
  const { executionID, grpcURL, idToken, listen, projectID, scenarioID, tags } = configuration;

  const readScenario = new ReadScenario();
  readScenario.setListen(listen);
  readScenario.setScenario(scenarioID);
  readScenario.setTagsList(tags);
  readScenario.setProject(projectID);
  if (executionID) readScenario.setExecution(executionID);

  const stream = getGRPCClient(grpcURL, idToken).readData(readScenario, {
    authorization: idToken,
  });

  const data: Dataframe = {};
  let dataUpdateTime = 0;

  let firstContact = false;

  stream.on("data", (res: any) => {
    firstContact = true;
    const list: DataList = res;
    const dataListObj = list.toObject();

    dataListObj.dataList.forEach((block, i) => {
      const prev = data[block.tag];
      if (Array.isArray(prev)) data[block.tag].push(...block.valuesList);
      else data[block.tag] = block.valuesList;
    });

    if (dataListObj.blockComplete && dataListObj.rowComplete) {
      const now = Date.now();
      if (now > dataUpdateTime + 2000) {
        onReadData({ ...data });
        dataUpdateTime = now;
      }
      console.log("complete block&row");
    }
  });

  stream.on("error", (err) => {
    firstContact = true;
    stream.cancel();
    onError(err);
  });

  stream.on("status", (status) => {
    firstContact = true;
    console.log({ status });
    if (status.code === 0) {
      onReadData({ ...data });
      onEnd();
    }
  });

  stream.on("end", () => {
    firstContact = true;
    onReadData({ ...data });
    onEnd();
  });

  stream.on("metadata", (metadata) => {
    firstContact = true;
    console.log({ metadata });
  });

  let ended = false;

  setTimeout(() => {
    if (!firstContact && !ended) {
      stream.cancel();
      console.log("Manual stream timeout");
      onError("timeout");
    }
  }, 2000);

  return () => {
    console.log("cancel stream");
    ended = true;
    stream.cancel();
  };
};

export const useExcecutionLog = (executionId?: string) => {
  const { grpcURL } = useGlobalState();

  const [loadingLogs, setloadingLogs] = useState(false);
  const [logs, setLogs] = useState<ExeLogEntry.AsObject[]>([]);

  const idToken = useIdToken();

  useEffect(() => {
    if (!executionId || !idToken) {
      setLogs([]);
      setloadingLogs(false);
      return;
    }

    //Load the logs
    setloadingLogs(true);

    const { cancel, promise } = readLogs(grpcURL, idToken, executionId, 0, 0);
    promise
      .then((loadedData) => {
        console.log({ loadedData });
        setloadingLogs(false);
        setLogs(loadedData);
      })
      .catch((error) => {
        console.log(error);
        setLogs([]);
        setloadingLogs(false);
        Toast("Eror loading logs", { icon: "error" });
      });

    return cancel;
  }, [grpcURL, idToken, executionId]);

  return { loadingLogs, logs };
};

export const useDataInfo = (
  projectId: string,
  scenarioId: string,
  executionId?: string,
  customOffset?: number
) => {
  const { grpcURL } = useGlobalState();

  const idToken = useIdToken();

  const [timeInfo, setTimeInfo] = useState<TimeInfo | null>(null);
  const [scenarioMetadata, setScenarioMetadata] = useState<ScenarioMetaData | null>(null);
  const [producingData, setProducingData] = useState<null | boolean>(null);

  const latestExecutionID = useLatestExecutionID(scenarioId, projectId);

  const activeExecution = useMemo(() => {
    return executionId || latestExecutionID;
  }, [executionId, latestExecutionID]);

  useEffect(() => {
    if (!idToken || !activeExecution) return;
    readTimeInfo(grpcURL, idToken, projectId, scenarioId, activeExecution)
      .then((res) => {
        setTimeInfo(res.timeInfo);
        setScenarioMetadata(res.rawMetadata);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [grpcURL, idToken, projectId, scenarioId, activeExecution]);

  useEffect(() => {
    if (customOffset && timeInfo && timeInfo.offset !== customOffset) {
      const startTimeVal = timeInfo.scenarioStats.min + customOffset;
      const endTimeVal = timeInfo.scenarioStats.max + customOffset;
      setTimeInfo({
        ...timeInfo,
        offset: customOffset,
        startTime: dayjs.unix(startTimeVal),
        endTime: dayjs.unix(endTimeVal),
      });
    }
  }, [customOffset, timeInfo]);

  useEffect(() => {
    if (!idToken) return;
    checkDatasetClosed(grpcURL, idToken, projectId, scenarioId, executionId)
      .then((closed) => {
        console.log({ closed });
        setProducingData(!closed);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [grpcURL, idToken, projectId, scenarioId, executionId]);

  return { timeInfo, scenarioMetadata, producingData };
};

export const useLatestExecutionID = (scenarioID: string, projectID: string) => {
  const { grpcURL } = useGlobalState();

  const idToken = useIdToken();
  const [executionID, setExecutionID] = useState<string | null>(null);
  useEffect(() => {
    if (!idToken) return;
    const scenario = new Scenario().setProject(projectID).setScenario(scenarioID);
    getGRPCClient(grpcURL, idToken)
      .getLatestMainExecution(scenario, {
        authorization: idToken,
      })
      .then((res) => {
        console.log(res.getExecutionId());
        setExecutionID(res.getExecutionId());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [scenarioID, projectID, grpcURL, idToken]);
  return executionID;
};
