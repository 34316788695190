import { Comment } from "model/datatypes";
import React, { useContext, useState } from "react";
import { store } from "store";
import AddComment from "./AddComment";

interface Props {
  commentTaget: Partial<Comment>;
}

const CommentableComponent: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  onClick,
  commentTaget,
  className,
  children,
  ...props
}) => {
  const { state, dispatch } = useContext(store);
  const { isAddingComment } = state;
  const [target, setTarget] = useState<null | Partial<Comment>>(null);
  // console.log(commentTaget);

  return (
    <>
      <div
        id="commentable"
        {...props}
        className={` 
        relative 
        ${className || ""}
      `}
      >
        {isAddingComment && (
          <div
            id="commentable"
            onClick={(e) => {
              e.stopPropagation();
              if (isAddingComment) {
                setTarget(commentTaget);
              }
              if (onClick) onClick(e);
            }}
            className="absolute z-20 w-64 font-medium top-0 py-1 left-1/2 -ml-32 text-white text-center text-sm px-4 flex items-center justify-center cursor-pointer bg-blue-400 rounded-b animate-pulse"
          >
            <span data-test="clickToAddComment"> Click to add comment</span>
          </div>
        )}
        {children}
      </div>
      {target && (
        <AddComment
          commentTarget={target}
          onFinish={() => {
            dispatch({ type: "FINISHED_ADDING_COMMENT" });
            setTarget(null);
            console.log("On finish");
          }}
          onClose={() => {
            setTarget(null);
            console.log("On close");
          }}
        />
      )}
    </>
  );
};

export default CommentableComponent;
