import React, { useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import OutputDataIcon from "components/basic/icons/OutputDataIcon";
import { SimulationScenario } from "model/datatypes";
import { useGlobalState } from "store";
import RawDataViewer from "../dataOutput/RawDataViewer";
import Modal from "components/basic/Modal";

interface Props {
  scenario: SimulationScenario;
}

const DataOutput = ({ scenario }: Props) => {
  const { projectID } = useGlobalState();
  const [dataViewerOpen, setDataViewerOpen] = useState(false);

  const hasOutput =
    !!scenario.timeseriesDataAvailable ||
    !!scenario.lastest_main_execution ||
    scenario.dataTags.length > 0;
  let statusColor = !hasOutput ? "#aaa" : "#48BB78";
  return (
    <>
      <div className="relative">
        <div className="flex items-center relative">
          <div
            data-test="openDataViewer"
            className="h-8 w-8 relative cursor-pointer"
            style={{ color: statusColor }}
            onClick={() => {
              setDataViewerOpen(true);
            }}
          >
            <CircularProgressbarWithChildren
              value={100}
              strokeWidth={8}
              styles={{ path: { stroke: statusColor } }}
            >
              <OutputDataIcon />
            </CircularProgressbarWithChildren>
          </div>
        </div>
        <div className="text-xs font-bold">Data output</div>
        <div className="text-xs italic">{!hasOutput ? "No data yet" : "Output ready"}</div>
      </div>
      {dataViewerOpen && scenario.dataTags && projectID && (
        <Modal
          onClose={() => {
            setDataViewerOpen(false);
          }}
          className="p-8"
          canOverflow
        >
          <div className="z-40 bg-white border border-gray-200 shadow-xl rounded w-full xl:w-11/12">
            <RawDataViewer scenario={scenario} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DataOutput;
