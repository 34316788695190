import React, { useState, useMemo } from "react";

import ComponentEditer from "./componentEditor/ComponentEditor";
import { ComponentType, SubCompRules, Container, SimulationModel } from "model/datatypes";
import AddComponentType from "./AddComponentType";
import SaveLibraryComponent from "./componentEditor/SaveLibraryComponent";
import ContainerEditor from "./componentEditor/ContainerEditor";

import AddIcon from "components/basic/icons/AddIcon";
import useExtensionComponents from "components/extensions/ExtensionComponents";

interface Props {
  editedContainers: Container[] | undefined;
  editedComponentTypes: ComponentType[] | undefined;
  system: SimulationModel;
  onMoveComponentType: (comp: ComponentType, i: number, dir: "up" | "down") => void;
  updateContainer: (containerID: string, newContainerProps: Partial<Container>) => void;
  updateComponent: (componentID: string, newComponentProps: Partial<ComponentType>) => void;
  onRemoveContainer: (container: Container) => void;
  onRemoveComponent: (componentID: string, i: number) => void;
  onMoveContainer: (cont: Container, i: number, dir: "up" | "down") => void;
  addComponentType: (newCompType: ComponentType) => void;
  addContainer: (newContainer: Container) => void;
}

type EntityListItem =
  | { type: "container"; entity: Container }
  | { type: "componentType"; entity: ComponentType };

const SystemComponents: React.FC<Props> = ({
  editedComponentTypes,
  editedContainers,
  system,
  onMoveComponentType,
  updateComponent,
  updateContainer,
  onRemoveContainer,
  onRemoveComponent,
  onMoveContainer,
  addComponentType,
  addContainer,
}) => {
  const [newLibraryComponent, setNewLibraryComponent] = useState<null | ComponentType>(null);
  const [addComponent, setAddComponent] = useState<boolean | ComponentType>(false);

  const extensionComps = useExtensionComponents();

  //reference to the system
  const systemRef = useMemo(
    () => (system ? { id: system.id, displayName: system.displayName } : undefined),
    [system]
  );

  //Create list of all containers and components:
  const entities = useMemo(() => {
    if (!editedContainers || !editedComponentTypes) return [];
    const entitityHolder: EntityListItem[] = editedContainers.map((container) => ({
      type: "container",
      entity: container,
    }));
    editedComponentTypes.forEach((c) => {
      entitityHolder.push({ type: "componentType", entity: c });
    });
    return entitityHolder.sort((a, b) => {
      if (!a.entity.order) return -1;
      else if (!b.entity.order) return 1;
      else return a.entity.order - b.entity.order;
    });
  }, [editedComponentTypes, editedContainers]);

  const paramRefs = useMemo(
    () =>
      entities.map((item) => ({
        id: item.entity.id,
        displayName: item.entity.displayName,
      })),
    [entities]
  );

  const subComponentRulesOptions = useMemo(
    () =>
      editedComponentTypes?.map((c) => {
        const subCompRules: SubCompRules = {
          id: c.id,
          min: 1,
          max: 1,
          default: 1,
        };
        return { displayName: c.displayName, subCompRules };
      }),
    [editedComponentTypes]
  );

  const renderComponentType = (comp: ComponentType, i: number, maxOrder: number) => {
    const extensionComp = extensionComps.find((extension) => extension.type.id === comp.id);
    if (extensionComp)
      return (
        <extensionComp.typeEditor
          key={comp.id}
          componentType={comp}
          removeComponent={(componentID) => onRemoveComponent(componentID, i)}
          onMove={(dir) => onMoveComponentType(comp, i, dir)}
          maxOrder={maxOrder}
        />
      );
    else
      return (
        <ComponentEditer
          key={comp.id}
          systemRef={systemRef}
          comp={comp}
          onMove={(dir) => onMoveComponentType(comp, i, dir)}
          maxOrder={maxOrder}
          updateComponent={updateComponent}
          removeComponent={(componentID) => onRemoveComponent(componentID, i)}
          onDuplicate={() => {
            setAddComponent(comp);
          }}
          onSaveToLib={() => {
            setNewLibraryComponent(comp);
          }}
          subCompOptions={subComponentRulesOptions || []}
          allowedParamRefs={paramRefs.slice(0, i)}
        />
      );
  };

  return (
    <>
      <div className={`font-medium text-lg mb-2`}>System components</div>
      <div>
        {entities.map((item, i) => {
          if (item.type === "componentType") {
            return renderComponentType(item.entity, i, entities.length - 1);
          } else {
            return (
              <ContainerEditor
                key={item.entity.id}
                container={item.entity}
                allComponentTypes={editedComponentTypes || []}
                updateContainer={updateContainer}
                removeContainer={() => onRemoveContainer(item.entity)}
                allowedParamRefs={paramRefs.slice(0, i)}
                subCompOptions={subComponentRulesOptions || []}
                onMove={(dir) => onMoveContainer(item.entity, i, dir)}
                maxOrder={entities.length - 1}
              />
            );
          }
        })}
      </div>
      <button
        onClick={() => setAddComponent(true)}
        className="mt-1 mb-6 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
      >
        <AddIcon className="h-6 w-6 mr-3" />
        <span className="text-xs font-bold">Add component type</span>
      </button>

      {newLibraryComponent && (
        <SaveLibraryComponent
          onFinish={() => setNewLibraryComponent(null)}
          compType={newLibraryComponent}
        />
      )}
      {addComponent && (
        <AddComponentType
          startComp={typeof addComponent === "object" ? addComponent : undefined}
          order={entities.length}
          onFinish={() => setAddComponent(false)}
          onAddComponent={addComponentType}
          onAddContainer={addContainer}
        />
      )}
    </>
  );
};

export default SystemComponents;
