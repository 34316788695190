import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
// import { LocationSearchResult } from "./LocationInput";

// const pKey =
//   "pk.eyJ1Ijoic2ltb25icGVtIiwiYSI6ImNrNHk1aHhidjA2ejUzcGw1ajdpbHVxOHQifQ.-5qTLSYe9v-lFRYX7QqfNw";

const pKey =
  "pk.eyJ1Ijoic2ltb25icGVtIiwiYSI6ImNrcWo3MjIyeDBhMjUyb3BzbGwxZGE4dzgifQ.SqGyEqlKuScP2nnOAxhxdQ";

interface Props {}

const MapboxView: React.FC<Props> = () => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mapboxgl.accessToken = pKey;
    if (!map && mapContainer.current) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/simonbpem/ck4ybw4nc1niw1cp46yh3f348", // stylesheet location
        center: { lng: 12, lat: 55 },
        zoom: 6.5,
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
      });
    }
  }, [map]);

  // useEffect(() => {
  //   if (map) {
  //     const wEl = document.createElement("div");
  //     wEl.className = "black-dot-marker";
  //     const marker = new mapboxgl.Marker(wEl).setLngLat(weatherStationLocation).addTo(map);

  //     const popup = new mapboxgl.Popup({
  //       offset: 10,
  //       maxWidth: "720px",
  //       closeOnClick: false,
  //       closeButton: false,
  //     })
  //       .setHTML(getWeatherPopupHTML(weatherStation, avgWeather))
  //       .setLngLat(weatherStationLocation)
  //       .addTo(map);
  //     return () => {
  //       marker.remove();
  //       popup.remove();
  //     };
  //   }
  // }, [map, weatherStationLocation, weatherStation, avgWeather]);

  // useEffect(() => {
  //   if (map && weatherStationLocation) {
  //     map.flyTo({ center: weatherStationLocation });
  //   }
  // }, [map, weatherStationLocation]);

  return <div ref={mapContainer} style={{ height: "100%", width: "100%" }} />;
};

export default MapboxView;

// const getWeatherPopupHTML = (avgWeather: any, weatherStation: any) => {
//   return `<div class="rounded flex items-center px-2">
//   <div class="text-xs">
//     <div class="font-medium">${weatherStation.name}</div>
//       ${avgWeather && AvgWeatherTableHTML(avgWeather)}
//   </div>
//   </div>
// `;
// };

// const AvgWeatherTableHTML = (avgWeather: any) => `
//     <table class="text-xxs">
//       <thead>
//         <tr class=" font-medium">
//           <td class="px-2  pl-0">Sesason</td>
//           <td class="px-2">Day average</td>
//           <td class="px-2">Night average</td>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td class="px-2 pl-0">Winter</td>
//           <td class="px-2">${avgWeather["winter"].day_average.toFixed(1)}°C</td>
//           <td class="px-2">${avgWeather["winter"].night_average.toFixed(1)}°C</td>
//         </tr>

//         <tr>
//           <td class="px-2 pl-0">Summer</td>
//           <td class="px-2">${avgWeather["summer"].day_average.toFixed(1)}°C</td>
//           <td class="px-2">${avgWeather["summer"].night_average.toFixed(1)}°C</td>
//         </tr>
//       </tbody>
//     </table>
// `;
