import React, { useMemo } from "react";
import MapboxView from "./MapboxView";
import { ScenarioStateEdited } from "model/datatypes";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import Parameter from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

const WeatherInput: React.FC<{
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}> = ({ scenarioState, scenarioDispatch }) => {
  const ambientComponent = useMemo(() => {
    return scenarioState.simComponents.find((comp) => comp.name === "Ambient");
  }, [scenarioState.simComponents]);

  const climateFileParam = useMemo(() => {
    return ambientComponent?.parameters.find((param) => param.id === "climate_file");
  }, [ambientComponent]);

  const climateFileParamType = useMemo(() => {
    return scenarioState.system?.componentTypes
      .find((comp) => comp.name === "Ambient")
      ?.parameters.find((param) => param.id === "climate_file");
  }, [scenarioState.system]);

  return (
    <>
      <div
        className={`w-full relative h-64 rounded overflow-hidden py-6 pl-8 border border-gray-300`}
      >
        <div className="z-30 relative border border-gray-300 rounded shadow w-1/2">
          <div className="z-10 relative">
            <div className="w-full text-xs">
              {climateFileParam && ambientComponent && (
                <Parameter
                  parameter={climateFileParam}
                  paramType={climateFileParamType}
                  fullWidth
                  hideBorder={true}
                  onUpdate={(updatedParam) => {
                    scenarioDispatch({
                      type: "UPDATE_COMPONENT",
                      payload: {
                        ...ambientComponent,
                        parameters: updateArrayVal(ambientComponent.parameters, updatedParam),
                      },
                    });
                  }}
                />
              )}
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-gray-200 bg-opacity-75"></div>
        </div>

        <div className="absolute w-full h-full top-0 left-0 ">
          <MapboxView />
        </div>
      </div>
      {/* {weatherStation && (
        <div className="my-1 text-xs flex">
          <div className="font-medium mr-1">Selected weather file:</div>
          <div>
            {weatherStation.name}, {weatherStation.distance.toFixed(1)}km from search location.
          </div>
        </div>
      )} */}
    </>
  );
};

export default WeatherInput;
