import React from "react";

const SetupStep: React.FC<{
  stepNr: number;
  stepName: string;
  ready: boolean;
  isActive: boolean;
  setCurrentStep: (step: number) => void;
}> = ({ setCurrentStep, ready, isActive, children, stepNr, stepName }) => {
  return (
    <div className=" rounded-lg border border-gray-300 bg-white my-4">
      <div
        className={`flex items-center font-medium px-4 py-3
        ${isActive ? "" : "opacity-75"}
      ${ready && !isActive ? "cursor-pointer" : ""}`}
        onClick={() => {
          if (ready && !isActive) setCurrentStep(stepNr);
        }}
      >
        <div
          className={`flex items-center justify-center rounded-full text-white h-8 w-8 ${
            ready ? "bg-green-numerous" : "bg-gray-300"
          }`}
        >
          {stepNr}
        </div>
        <div className={`text-lg ml-3 ${ready ? "" : "text-gray-400"}`}>{stepName}</div>
      </div>
      {isActive && <div className="px-4 pb-3">{children}</div>}
    </div>
  );
};

export default SetupStep;
