import React, { useState, useEffect } from "react";
import { InputVar } from "model/datatypes";
import { InputText } from "components/basic/Input";
import { DropdownAtRoot } from "components/basic/Dropdown";

interface Props {
  value: InputVar;
  onUpdate: (newVal: InputVar) => void;
  onDeleteInput: () => void;
  useExperimental?: boolean;
}

const InputVariableEditable: React.FC<Props> = ({
  value,
  onUpdate,
  onDeleteInput,
  useExperimental,
}) => {
  const [displayDefaultVal, setdisplayDefaultVal] = useState<string>(value.value.toString());
  const [error, seterror] = useState<boolean>(false);

  useEffect(() => {
    setdisplayDefaultVal(value.value.toString());
  }, [value]);

  const handleChangeDefaultVal = (newVal: string) => {
    setdisplayDefaultVal(newVal);
    const num = parseFloat(newVal);
    seterror(isNaN(num));
    if (!isNaN(num)) onUpdate({ ...value, value: num });
  };

  return (
    <div className="py-2 px-2 border-t  border-gray-200 hover:bg-gray-200">
      <div className="flex w-full items-center text-xs">
        <div className="pr-4 w-1/8">
          <InputText
            value={value.id}
            readOnly={value.fixedID}
            className={`input-box w-full ${value.fixedID ? "bg-gray-100" : ""}`}
            onChange={(val) => {
              onUpdate({ ...value, id: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/8">
          <InputText
            value={value.display}
            className={`input-box w-full `}
            onChange={(val) => {
              onUpdate({ ...value, display: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/8">
          <InputText
            value={value.tooltip || ""}
            className={`input-box w-full`}
            onChange={(val) => {
              onUpdate({ ...value, tooltip: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/8">
          <InputText
            value={value.unit || ""}
            className={`input-box w-full`}
            onChange={(val) => {
              onUpdate({ ...value, unit: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/8"></div>
        <div className="pr-4 w-1/8">
          <input
            type="number"
            className={`input-box w-full ${error ? "border-red-300" : "border-gray-300"}`}
            value={displayDefaultVal}
            onChange={(e) => handleChangeDefaultVal(e.target.value)}
          />
        </div>

        <div className="pr-4 w-1/8">
          {useExperimental && (
            <DropdownAtRoot
              className="bg-white text-xs"
              selectedID={value.type || "input"}
              options={[
                { id: "input", display: "Input" },
                { id: "internal", display: "Internal state" },
                { id: "constant", display: "Constant" },
              ]}
              onSelect={(option) => {
                onUpdate({ ...value, type: option.id as InputVar["type"] });
              }}
            />
          )}
        </div>

        <div className="w-1/12 flex justify-end">
          {!value.fixedID && (
            <button
              onClick={() => {
                onDeleteInput();
              }}
              className={"button-small"}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputVariableEditable;
