import React, { useState } from "react";
import {
  RunSettings,
  SimulationImageType,
  SimulationJobType,
  SimulationModel,
} from "model/datatypes";
import Modal from "components/basic/Modal";
import getUUID from "utils/jsUtils/getUUID";
import ToggleButton from "components/basic/ToggleButton";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import ParametersEditTable from "./componentEditor/parameters/ParametersEditTable";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { TimeSelecterCurrentJob } from "components/simulations/newSimulation/simSetup/runJob/time/TimeSelecter";
import dayjs from "dayjs";

interface Props {
  jobs: SimulationModel["jobs"];
  updateJobs: (updated: SimulationModel["jobs"]) => void;
  systemID: string;
}

const SimulationJobsEditor: React.FC<Props> = ({ jobs, updateJobs, systemID }) => {
  const [addingJob, setAddingJob] = useState(false);
  const [editingJob, setEditingJob] = useState<SimulationJobType | null>(null);

  const renderJobOptions = (job: SimulationJobType) => {
    return (
      <Popup
        useHover
        mt={13}
        align="left"
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={(e) => {
                e.stopPropagation();
                setEditingJob(job);
                closeMe();
              }}
            >
              Edit
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                const updatedJobs = jobs;
                delete updatedJobs[job.id];
                updateJobs(updatedJobs);
                closeMe();
              }}
              className={`button-popup border-t border-gray-200 text-red-600`}
            >
              Delete
            </button>
          </div>
        )}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  return (
    <div className="my-2">
      {Object.entries(jobs || {}).length === 0 && (
        <div className="text-xs italic">No jobs configured</div>
      )}
      <div className="flex flex-wrap">
        {Object.entries(jobs || {}).map(([key, job], i) => {
          return (
            <div key={job.id} className={`w-1/2 ${i % 2 === 0 ? "pr-1" : "pl-1"}`}>
              <div
                className={`px-4 py-2 bg-white border border-gray-200 rounded overflow-hidden relative w-full cursor-pointer`}
                onClick={() => {
                  setEditingJob(job);
                }}
              >
                <div className=" text-sm font-medium truncate">{job.name}</div>
                <div className="text-xs truncate">Image: {job.image.name}</div>
                <div className="text-xs truncate">Path: {job.image.path}</div>
                <div className="text-xs">{job.isMain ? "Main image" : "Processor"}</div>
                <div className="text-xs font-medium mt-2">Image parameters</div>
                <div className="text-xs flex items-center flex-wrap">
                  {job.image.parameters.map((param) => {
                    return (
                      <div
                        key={param.uuid}
                        className="px-2 border border-gray-200 rounded mr-2"
                      >
                        {param.displayName}
                      </div>
                    );
                  })}
                </div>
                <div className="absolute top-0 right-0 mt-2 mr-2">{renderJobOptions(job)}</div>
              </div>
            </div>
          );
        })}
      </div>
      {addingJob && (
        <EditJob
          isFirst={Object.entries(jobs || {}).length === 0}
          onFinish={(newJob) => {
            setAddingJob(false);
            if (newJob) {
              let updatedJobs = { ...jobs };
              if (newJob.isMain) {
                Object.entries(jobs).forEach(([key, job]) => {
                  if (job.isMain) updatedJobs[key].isMain = false;
                });
              }
              updateJobs({ ...updatedJobs, [newJob.id]: newJob });
            }
          }}
        />
      )}
      {editingJob && (
        <EditJob
          prev={editingJob}
          onFinish={(updated) => {
            setEditingJob(null);
            if (updated) {
              let updatedJobs = { ...jobs };
              if (updated.isMain) {
                Object.entries(jobs).forEach(([key, job]) => {
                  if (job.isMain) updatedJobs[key].isMain = false;
                });
              }
              updatedJobs[updated.id] = updated;
              updateJobs(updatedJobs);
            }
          }}
        />
      )}
      <button
        className="button-small mt-2"
        onClick={() => {
          setAddingJob(true);
        }}
      >
        Add job
      </button>
    </div>
  );
};

export default SimulationJobsEditor;

const EditJob: React.FC<{
  isFirst?: boolean;
  prev?: SimulationJobType;
  onFinish: (newJob?: SimulationJobType) => void;
}> = ({ prev, onFinish, isFirst }) => {
  const [editedJob, setEditedJob] = useState<SimulationJobType>(prev || getNewJob(!!isFirst));
  const [editedRunSettings, setEditedRunSettings] = useState(
    prev?.runSettings || stdRunSettings
  );

  return (
    <Modal onClose={() => onFinish()}>
      <div className="modal-content z-30 w-full xxl:w-3/4">
        <div className="font-medium mb-2">{prev ? "Edit" : "Add"} simulation Job</div>
        <div className="text-xs font-medium">Job Name</div>
        <input
          className="input-box text-xs w-full"
          value={editedJob.name}
          onChange={(e) => setEditedJob({ ...editedJob, name: e.target.value })}
        />
        <div className="text-xs font-medium mt-2">Main simulation job</div>
        <ToggleButton
          active={editedJob.isMain}
          onChange={() => {
            setEditedJob({ ...editedJob, isMain: !editedJob.isMain });
          }}
        />
        {editedJob.isMain && (
          <div className="py-6">
            <div className="text-xs font-medium">Standard run settings</div>
            <div className="px-1">
              <TimeSelecterCurrentJob
                startDate={editedRunSettings.startDate}
                endTimeVal={editedRunSettings.endTimeVal}
                updateEnd={(updated) => {
                  setEditedRunSettings({ ...editedRunSettings, endTimeVal: updated });
                }}
                updateStart={(updated) => {
                  setEditedRunSettings({ ...editedRunSettings, startDate: updated });
                }}
                timeMode={editedRunSettings.runMode}
                setTimeMode={(updated) => {
                  setEditedRunSettings({ ...editedRunSettings, runMode: updated });
                }}
              />
            </div>
          </div>
        )}

        <div className="mb-2 mt-6 border border-gray-200 rounded px-4 py-2">
          <div className="font-bold text-xs">Docker image</div>
          <div className="text-xs font-medium mt-2">Deployment Name</div>
          <input
            className="input-box text-xs w-full"
            value={editedJob.image.name}
            onChange={(e) =>
              setEditedJob({
                ...editedJob,
                image: { ...editedJob.image, name: e.target.value },
              })
            }
          />
          <div className="text-xs font-medium mt-2">Path</div>
          <input
            className="input-box text-xs w-full mb-2"
            value={editedJob.image.path}
            onChange={(e) =>
              setEditedJob({
                ...editedJob,
                image: { ...editedJob.image, path: e.target.value },
              })
            }
          />

          <ParametersEditTable
            componentParameters={editedJob.image.parameters}
            onUpdateParam={(updated) => {
              setEditedJob({
                ...editedJob,
                image: {
                  ...editedJob.image,
                  parameters: updateArrayValUUID(editedJob.image.parameters, updated),
                },
              });
            }}
            onUpdateAllParms={(updated) => {
              setEditedJob({
                ...editedJob,
                image: {
                  ...editedJob.image,
                  parameters: updated,
                },
              });
            }}
            onDelete={(deletedParam) => {
              setEditedJob({
                ...editedJob,
                image: {
                  ...editedJob.image,
                  parameters: editedJob.image.parameters.filter(
                    (p) => p.uuid !== deletedParam.uuid
                  ),
                },
              });
            }}
          />
        </div>
        <div className="flex mt-4">
          <button
            className="button-small mr-1 flex-1"
            onClick={() => {
              onFinish({ ...editedJob, runSettings: editedRunSettings });
            }}
          >
            {prev ? "Confirm edits" : "Add job"}
          </button>
          <button
            className="button-small ml-1 flex-1"
            onClick={() => {
              onFinish();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const stdRunSettings: RunSettings = {
  startDate: dayjs("00:00 01/01/21", "HH:mm DD/MM/YY"),
  runMode: "duration",
  endTimeVal: {
    unit: "months",
    value:
      (dayjs("01/01/2022 00:00", "DD/MM/YYYY HH:mm").valueOf() -
        dayjs("00:00 01/01/21", "HH:mm DD/MM/YY").valueOf()) /
      1000,
  },
  endDate: dayjs("01/01/2022 00:00", "DD/MM/YYYY HH:mm"),
};

const getNewJob = (isMain: boolean) => {
  const newImage: SimulationImageType = {
    id: getUUID(),
    name: "",
    path: "",
    parameters: [],
  };
  const newJob: SimulationJobType = {
    id: getUUID(),
    name: "",
    image: newImage,
    isMain,
  };
  if (isMain) newJob.runSettings = stdRunSettings;
  return newJob;
};
