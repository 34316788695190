import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import ScheduleVariableBuilder from "components/simulations/newSimulation/simSetup/inputs/ScheduleVariableBuilder";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { DatasetScenario, InputVar, ScenarioStateEdited, Schedule } from "model/datatypes";
import React, { useMemo } from "react";
import { useGlobalState } from "store";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const AHUComponent = ({ scenarioDispatch, scenarioState }: Props) => {
  const AHUComponent = useMemo(() => {
    return scenarioState.simComponents.find((c) => c.name === "AHU");
  }, [scenarioState.simComponents]);
  if (!AHUComponent) return null;
  return (
    <div className="w-full px-4 py-4 bg-white mt-4 mb-8 text-xs">
      <ComponentParameters
        component={AHUComponent}
        onUpdate={(updated) => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: updated,
          });
        }}
      />
      <div>Schedule</div>
      <AHUSchedule scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
    </div>
  );
};

export default AHUComponent;

const AHUSchedule: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const { projectID } = useGlobalState();

  const AHUComponent = useMemo(() => {
    return scenarioState.simComponents.find((c) => c.name === "AHU");
  }, [scenarioState.simComponents]);

  const supplyInputVar = useMemo(() => {
    return AHUComponent?.inputVariables.find((inputVar) => inputVar.id === "F_supply");
  }, [AHUComponent]);

  const extractInputVar = useMemo(() => {
    return AHUComponent?.inputVariables.find((inputVar) => inputVar.id === "F_extract");
  }, [AHUComponent]);

  const selectSchedule = (selected: { dataset: DatasetScenario; schedule: Schedule }) => {
    const { dataset, schedule } = selected;
    //add dataset to sceneario inputs:
    let prevInputs = scenarioState.inputScenarios;
    if (!prevInputs.some((pi) => pi.scenarioID === selected.dataset.id)) {
      if (supplyInputVar?.tagSource) {
        prevInputs = prevInputs.filter(
          (inputRef) => inputRef.scenarioID !== supplyInputVar.tagSource!.scenarioID
        );
      }
      scenarioDispatch({
        type: "UPDATE_SCENARIO_INPUTS",
        payload: [
          ...prevInputs,
          { scenarioID: dataset.id, projectID: projectID!, type: "dataset" },
        ],
      });
    }

    //update tag source:
    updateScheduleVariable({
      dataSourceType: "scenario",
      tagSource: { scenarioID: dataset.id, projectID: projectID!, tag: schedule.tag },
      dataSourceID: dataset.id,
    });
  };

  const updateScheduleVariable = (updated: Partial<InputVar>) => {
    if (!AHUComponent) return;
    let updatedVariables = AHUComponent.inputVariables;

    if (supplyInputVar) {
      const updatedSupplyVar = {
        ...supplyInputVar,
        ...updated,
      };
      updatedVariables = updateArrayValUUID(updatedVariables, updatedSupplyVar);
    }
    if (extractInputVar) {
      const updatedExtractVar = {
        ...extractInputVar,
        ...updated,
      };
      updatedVariables = updateArrayValUUID(updatedVariables, updatedExtractVar);
    }
    scenarioDispatch({
      type: "UPDATE_COMPONENT",
      payload: {
        ...AHUComponent,
        inputVariables: updatedVariables,
      },
    });
  };

  return (
    <div>
      {supplyInputVar && (
        <ScheduleVariableBuilder
          scheduleInputVar={supplyInputVar}
          selectSchedule={selectSchedule}
          updateScheduleVariable={updateScheduleVariable}
        />
      )}
    </div>
  );
};
