import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  useCheckForComments,
  useFirebase,
  useFirestore,
  useIdToken,
  useLocalSimulationModel,
  useSimulationModel,
} from "api/useFirebase";
import { useGlobalDispatch, useGlobalState } from "store";
import { SimulationModel, SimulationScenario } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import Modal from "components/basic/Modal";
import { downloadJsonObject } from "utils/jsUtils/downloadJsonObject";
import HoverTooltip from "components/basic/HoverTooltip";
import * as Sentry from "@sentry/browser";
import Toast from "components/basic/Toast";
import ConsoleLogger from "./ConsoleLogger";
import ScenarioBuilder from "./newSimulation/ScenarioBuilder";
import GlobalUpdatedIcon from "components/basic/icons/GlobalUpdatedIcon";
import LocalUpdatedIcon from "components/basic/icons/LocalUpdatedIcon";
import PullIcon from "components/basic/icons/PullIcon";
import ScenarioOverview from "./overview/ScenarioOverview";
import { useHasEditAccess, useUserRole } from "api/useAuth";
import { useHistory } from "react-router-dom";
import CommentableComponent from "components/comments/CommentableComponent";
import { mergeScenarioSystem } from "utils/simulations/mergeScenarioSystem";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Extension from "components/extensions/iFrameExpansion/Extension";
import CommentIcon from "components/basic/icons/CommentIcon";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";
import SimIcon from "components/basic/icons/SimIcon";
import { EyeIcon } from "@heroicons/react/solid";
import { deleteScenario } from "grpc/api/grpcUtilAPI";

interface ScenarioCardProps {
  scenario: SimulationScenario;
  groupName: string;
  onDuplicate: (scenario: SimulationScenario) => void;
  onStartReposition: () => void;
}

const ScenarioCard: React.FC<ScenarioCardProps> = ({
  scenario,
  groupName,
  onDuplicate,
  onStartReposition,
}) => {
  const [consoleLoggerOpen, setConsoleLoggerOpen] = useState(false);
  const [playgroundOpen, setPlaygroundOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const { projectID, projectName, linkedScenario, openScenarios, activeScenario, grpcURL } =
    useGlobalState();
  const idToken = useIdToken();

  const dispatch = useGlobalDispatch();

  const scenarioOpenState = useMemo(
    () => openScenarios.find((openScenario) => openScenario.scenarioID === scenario.id),
    [scenario, openScenarios]
  );

  const commentExists = useCheckForComments("scenarioID", scenario.id);

  const { hasDeveloperAccess } = useUserRole();

  const isActiveRef = useRef(false);
  //scroll to scenario if gets set to active
  useEffect(() => {
    if (activeScenario === scenario.id) {
      scrollToScenarioCard();
      isActiveRef.current = true;
    } else {
      isActiveRef.current = false;
    }
  }, [activeScenario, scenario.id]);

  const scenarioCardRef = useRef<HTMLDivElement>(null);
  const hasScenarioEditAccess = useHasEditAccess(scenario.ownerId);

  const highlighted = useHighlightEffect(linkedScenario, scenario.id, scenarioCardRef);

  const localModel = useLocalSimulationModel(
    scenario.systemID,
    projectID || undefined,
    scenario.id
  );

  const globalModel = useSimulationModel(scenario.systemID);
  const [localUpdated, setLocalUpdated] = useState(false);
  const [globalUpdated, setGlobalUpdated] = useState(false);

  useEffect(() => {
    // console.log({ localModel, globalModel });
    setLocalUpdated(!!localModel && localModel.localVersion !== localModel.version);
    setGlobalUpdated(
      !!globalModel && !!localModel && globalModel.version !== localModel.version
    );
  }, [localModel, globalModel]);

  const pullSimulationModel = async () => {
    if (globalUpdated && projectID && globalModel) {
      try {
        setLoading(true);
        await mergeScenarioSystem(fb, projectID, scenario, globalModel);
        setLoading(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        Toast("Error pulling system changes", { icon: "error" });
        setLoading(false);
      }
    }
  };

  const copyRefToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = JSON.stringify({
      projectID,
      scenarioID: scenario.id || null,
      groupID: scenario.groupID,
      scenarioName: scenario.scenarioName,
      projectName,
      jobs: scenario.jobs,
      fs_path: `/Projects/${projectID}/Scenarios/${scenario.id}`,
    });
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Reference copied to clipboard", { icon: "success" });
  };

  const copyScenarioLinkToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${projectName}/${projectID}/${scenario.groupID}/${scenario.id}`
    );
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Direct link to scenario copied to clipboard", { icon: "success" });
  };

  const onDeleteScenario = async () => {
    //TODO add checks if deling should be allowed
    if (scenario.id && projectID && idToken) {
      setLoading(true);
      try {
        deleteScenario(grpcURL, idToken, projectID, scenario.id);

        //nothing to do here, listeners should remove scenario...
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const fs = useFirestore();

  const upgradeScenario = async () => {
    if (loading || scenario.SPM_version === 2 || !projectID) return;
    try {
      setLoading(true);
      //fix changes to scenario:
      //@ts-ignore
      const oldSystem = scenario.model as SimulationModel;
      if (oldSystem) {
        const newScenarioFields: Partial<SimulationScenario> = {
          systemID: oldSystem.id,
          parameters: [],
          projectID,
          inputScenarios: [],
          jobs: {},
          dataTags: [],
          logs: [],
          SPM_version: 2,
        };
        await fs
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenario.id)
          .update(newScenarioFields);
        setLoading(false);

        //TODO: save the scenariomodel with parameters, componentType and containers.....

        Toast("Updated scenario format", { icon: "success" });
      }
    } catch (error) {
      setLoading(false);
      Toast("Error updating scenario");
    }
  };

  const getJson = () => {
    downloadJsonObject({ ...scenario, projectID }, scenario.scenarioName);
  };

  const renderMoreOptionsBtn = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            {globalUpdated && PullOption()}
            <button
              onClick={() => {
                onDuplicate(scenario);
                closeMe();
              }}
              className={"button-popup"}
            >
              Duplicate scenario
            </button>
            {!scenarioOpenState && (
              <button
                onClick={() => {
                  onStartReposition();
                }}
                className={`button-popup border-t border-gray-400`}
              >
                Reposition scenario
              </button>
            )}

            <button
              onClick={() => {
                setConsoleLoggerOpen(true);
                closeMe();
              }}
              className={`button-popup border-t border-gray-400`}
            >
              Show log
            </button>
            <button
              onClick={() => {
                copyScenarioLinkToClipboard();
                closeMe();
              }}
              className={`button-popup border-t border-gray-400`}
            >
              Copy scenario link
            </button>

            {hasDeveloperAccess && (
              <button
                onClick={() => {
                  getJson();
                  closeMe();
                }}
                className={`button-popup border-t border-gray-400`}
              >
                Get json
              </button>
            )}
            <button
              onClick={() => {
                copyRefToClipboard();
                closeMe();
              }}
              className={`button-popup border-t border-gray-400`}
            >
              Copy reference
            </button>

            <button
              onClick={() => {
                setPlaygroundOpen(true);
              }}
              className={`button-popup border-t border-gray-400`}
            >
              Open expansion
            </button>

            {!hasScenarioEditAccess ? (
              <button
                className={`button-popup opacity-50 text-red-700 border-t border-gray-400`}
                onClick={() => {
                  Toast("You do not have permission to delete this scenario", {
                    icon: "warning",
                  });
                }}
              >
                Delete scenario
              </button>
            ) : (
              DeleteOption()
            )}
          </div>
        )}
      >
        <button
          data-test="scenarioCardOptionsButton"
          className="relative focus:outline-none flex justify-center items-center"
        >
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete scenario?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    data-test="deleteScenarioConfirmationButton"
                    onClick={() => {
                      if (!loading) {
                        onDeleteScenario();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button
          data-test="deleteScenarioButton"
          className={`button-popup text-red-500 border-t border-gray-400`}
        >
          Delete scenario
        </button>
      </Popup>
    );
  };

  const PullOption = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Pull new System Model?</div>
              <div className="italic mb-2">
                This will merge and overwrite local version of the system model.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-green-400 bg-green-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        pullSimulationModel();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Pull
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button
          className={`button-popup border-b border-gray-400 flex items-center justify-center`}
        >
          <PullIcon className="h-4 w-4 mr-2" /> <span>Pull system model</span>
        </button>
      </Popup>
    );
  };

  const scrollToScenarioCard = () => {
    if (scenarioCardRef.current) {
      console.log("Scroll to scenario...");
      const boudingRect = scenarioCardRef.current.getBoundingClientRect();
      const scrollPos = window.scrollY;
      setTimeout(() => {
        window.scrollTo({
          top: boudingRect.top + scrollPos,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
      setTimeout(() => {
        //extra check for always making sure it gets right position:
        const boudingRect = scenarioCardRef.current!.getBoundingClientRect();
        console.log({ newTop: boudingRect.top });
        if (boudingRect.top !== 0 && isActiveRef.current === true)
          window.scrollTo({
            top: window.scrollY + boudingRect.top,
            left: 0,
            behavior: "smooth",
          });
      }, 2000);
    }
  };

  const renderSimulationOptions = () => {
    return (
      <>
        {!scenarioOpenState && (
          <>
            {commentExists && (
              <div className="mr-2">
                <CommentIcon />
              </div>
            )}
            {globalUpdated && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Global system updated since scenario created"
              >
                <GlobalUpdatedIcon />
              </HoverTooltip>
            )}
            {localUpdated && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Local system changed"
              >
                <LocalUpdatedIcon />
              </HoverTooltip>
            )}
            {scenario.hasFilePublished && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Report is published and can be viewed by guests."
              >
                <EyeIcon className="w-4 h-4" />
              </HoverTooltip>
            )}
          </>
        )}

        {renderMoreOptionsBtn()}

        <OpenCloseButton
          open={!!scenarioOpenState}
          onClick={(e) => {
            if (!scenarioOpenState) {
              dispatch({
                type: "OPEN_SCENARIO",
                payload: {
                  id: scenario.id,
                  scenarioID: scenario.id,
                  scenarioName: scenario.scenarioName,
                  groupID: scenario.groupID,
                  edited: false,
                  saving: false,
                },
              });
              // scrollToScenarioCard();
            } else if (scenarioOpenState) {
              e.stopPropagation();
              if (!scenarioOpenState.edited)
                dispatch({ type: "CLOSE_SCENARIO", payload: scenario.id });
              else
                dispatch({
                  type: "UPDATE_OPEN_SCENARIO_STATE",
                  payload: { scenarioID: scenario.id, saving: true },
                });
            }
          }}
        />
      </>
    );
  };
  const hasDescription = !!scenario.description && scenario.description.length > 0;

  const isActive = activeScenario === scenario.id;

  return (
    <>
      <CommentableComponent
        commentTaget={{
          scenarioID: scenario.id,
          scenarioName: scenario.scenarioName,
          groupID: scenario.groupID,
          groupName: groupName,
          projectID: projectID || undefined,
          projectName: projectName || undefined,
        }}
        className={`relative my-6 shadow-md
        ${highlighted && !scenarioOpenState ? "pr-6 ring-4 ring-offset-2 rounded" : ""}
        ${activeScenario === scenario.id ? "shadow-xl" : ""}
      `}
      >
        <div
          data-test="scenarioCard"
          ref={scenarioCardRef}
          style={scenarioOpenState ? { height: "100vh" } : {}}
          onClick={(e) => {
            if (activeScenario !== scenario.id && scenarioOpenState) {
              // scrollToScenarioCard();
              dispatch({ type: "SET_ACTIVE_SCENARIO", payload: scenario.id });
            }
            e.stopPropagation();
          }}
          className={`bg-white flex flex-col border text-gray-700 border-gray-300 z-10 relative overflow-hidden
          ${isActive ? "" : "rounded"}`}
        >
          <div className={`w-full px-3 py-2 border-b border-gray-200`}>
            <div className="flex items-center">
              <SimIcon className="mr-3 w-5" />
              <div data-test="scenarioName" className="font-medium flex-grow">
                {scenario.scenarioName}
              </div>
              {renderSimulationOptions()}
            </div>
            {hasDescription && !scenarioOpenState && (
              <div className="italic text-xs leading-tight">{scenario.description}</div>
            )}
          </div>
          {loading && <LoadingOverlay className="z-20" />}

          {!scenarioOpenState && scenario.SPM_version === 2 && (
            <ScenarioOverview scenario={scenario} />
          )}
          {scenarioOpenState && scenario.SPM_version === 2 && (
            <ScenarioBuilder scenario={scenario} projectID={projectID || undefined} />
          )}
          {scenario.SPM_version !== 2 && (
            <div className="w-full h-16 bg-gray-200 flex flex-col items-center justify-center text-sm italic">
              <div>
                This scenario was created with a previous version of SPM - it is no longer
                supported. Upgrade scenario format to use again.
              </div>
              <button className="button-small" onClick={() => upgradeScenario()}>
                Upgrade
              </button>
            </div>
          )}
        </div>
        {highlighted && !scenarioOpenState && (
          <div className="absolute top-0 right-0 bg-white text-xs h-full w-6 flex items-center justify-center border border-gray-100">
            <div className=" transform rotate-90 whitespace-nowrap">Linked scenario</div>
          </div>
        )}
      </CommentableComponent>
      {consoleLoggerOpen && (
        <Modal onClose={() => setConsoleLoggerOpen(false)}>
          <ConsoleLogger
            scenarioName={scenario.scenarioName}
            jobs={scenario.jobs}
            job={Object.values(scenario.jobs).find((job) => job.isMain)}
          />
        </Modal>
      )}
      {playgroundOpen && projectID && (
        <Modal className="px-16" onClose={() => setPlaygroundOpen(false)}>
          <div className="bg-white z-50 shadow-lg rounded w-full h-screen">
            <Extension scenario={scenario} projectID={projectID} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ScenarioCard;

const useHighlightEffect = (
  linkedScenario: string | null,
  scenarioId: string,
  scenarioCardRef: React.RefObject<HTMLDivElement>
) => {
  const history = useHistory();
  const dispatch = useGlobalDispatch();

  const [highlighted, setHighlighted] = useState(false);
  //Highlight the scenario if linked to:

  useEffect(() => {
    if (linkedScenario === scenarioId) {
      setTimeout(() => {
        if (scenarioCardRef.current) {
          const boudingRect = scenarioCardRef.current.getBoundingClientRect();
          const scrollPos = window.scrollY;
          const windowHeight = window.innerHeight;
          const halfElementHeight = (boudingRect.bottom - boudingRect.top) / 2;
          window.scrollTo({
            top: boudingRect.top + scrollPos - windowHeight / 2 + halfElementHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 1000);
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  }, [linkedScenario, scenarioId, dispatch, history, scenarioCardRef]);

  return highlighted;
};
