import { SimulationImage, SimulationModel, SimulationScenario } from "model/datatypes";
import { mergeParameters, ParamTypeToInstance } from "utils/ComponentTypeHelpers";

export const instantiateSimJob = (
  jobTypes: SimulationModel["jobs"],
  previousJobs?: SimulationScenario["jobs"]
) => {
  const jobs: SimulationScenario["jobs"] = {};
  Object.entries(jobTypes).forEach(([id, jobType]) => {
    const prevJob = previousJobs && previousJobs[id];

    const image: SimulationImage = {
      ...jobType.image,
      parameters: jobType.image.parameters.map((p) => ParamTypeToInstance(p)),
    };

    if (prevJob)
      image.parameters = mergeParameters(image.parameters, prevJob.image.parameters);

    jobs[id] = { ...jobType, image, status: { status: "ready" } };
  });
  return jobs;
};
