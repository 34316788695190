import { PencilAltIcon } from "@heroicons/react/solid";
import DateTimeInput from "components/basic/DateTimeInput";
import Dropdown from "components/basic/Dropdown";
import TimeValueInput from "components/systems/editSystem/componentEditor/parameters/TimeValueInput";
import dayjs, { Dayjs } from "dayjs";
import { TimeValue } from "model/datatypes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";

interface Props {
  startDate: Dayjs;
  updateStart: (updated: Dayjs) => void;
  endTimeVal: TimeValue;
  updateEnd: (updated: TimeValue) => void;
  timeMode: "duration" | "continuous";
  setTimeMode: (update: "duration" | "continuous") => void;
}
export const TimeSelecterCurrentJob: React.FC<Props> = ({
  startDate,
  endTimeVal,
  updateEnd,
  updateStart,
  setTimeMode,
  timeMode,
}) => {
  return (
    <div className="flex items-center pt-2 pb-6">
      <div style={{ width: `100%` }} className={`border-b-4  relative border-blue-400`}>
        <div
          className={`absolute top-0 left-0 h-3 w-3 border-2 border-blue-400 -mt-1 -ml-1 bg-white rounded-full`}
        ></div>
        <div
          className={`absolute top-0 right-0 h-3 w-3 border-2 border-blue-400 -mt-1 -mr-1 bg-white rounded-full`}
        ></div>
        <div className={`absolute top-0 mt-2 left-0 -ml-1`}>
          <TimeTagEditable
            headline="Edit simulation start"
            startDate={startDate}
            onUpdate={updateStart}
          />
        </div>
        <div className={`absolute top-0 mt-2 right-0 -mr-1`}>
          <EndTimeEditable
            startDate={startDate}
            endTimeVal={endTimeVal}
            onUpdate={updateEnd}
            timeMode={timeMode}
            setTimeMode={setTimeMode}
          />
        </div>
      </div>
    </div>
  );
};

export const TimeTagEditable: React.FC<{
  startDate: Dayjs;
  originalStart?: Dayjs;
  simulationStart?: Dayjs;
  headline: string;
  onUpdate: (updated: Dayjs) => void;
}> = ({ startDate, onUpdate, originalStart, headline, simulationStart }) => {
  const [editing, setEditing] = useState(false);
  const [editedStart, setEditedStart] = useState(startDate.toDate());

  useEffect(() => {
    setEditedStart(startDate.toDate());
  }, [startDate]);

  const editorRef = useRef<HTMLDivElement>(null);
  const closeEditor = useCallback(() => {
    const editedDay = dayjs(editedStart);
    if (!editedDay.isSame(startDate)) {
      onUpdate(editedDay);
    }
    setEditing(false);
  }, [editedStart, startDate, onUpdate]);

  useClickOutsideEffect(editorRef, closeEditor);

  const showMatchButton = useMemo(() => {
    return simulationStart && !simulationStart.isSame(dayjs(editedStart));
  }, [simulationStart, editedStart]);

  const renderEditor = () => {
    return (
      <div
        ref={editorRef}
        className="bg-white border border-gray-200 absolute top-0 left-0 z-20 px-4 pt-2 pb-4 shadow-md rounded-lg cursor-default"
      >
        <div className="text-xs text-center mb-4 font-medium">{headline}</div>
        {originalStart && (
          <div className="flex items-center justify-between text-gray-600 mb-4">
            <div className="mr-2">Base start time</div>
            <div className="font-medium">{originalStart.format("DD/MM/YYYY HH:mm")}</div>
          </div>
        )}
        <div className="flex items-center justify-between text-xxs">
          <div>Start time</div>
          {showMatchButton && simulationStart && (
            <button
              className="focus:outline-none underline text-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                if (!startDate.isSame(simulationStart)) {
                  onUpdate(simulationStart);
                }
                setEditedStart(startDate.toDate());
                setEditing(false);
                console.log({ startDate, simulationStart });
              }}
            >
              Match simulation
            </button>
          )}
        </div>
        <DateTimeInput
          className="w-48"
          date={editedStart}
          onUpdate={(updated) => {
            setEditedStart(updated);
          }}
        />
      </div>
    );
  };

  return (
    <div
      onClick={() => setEditing(true)}
      style={{ width: "6.5rem" }}
      className={`flex items-center text-xxs leading-tight bg-white border border-gray-200 rounded-lg shadow py-1 cursor-pointer relative`}
    >
      <div className="pl-2 flex-grow">
        <div>{simulationStart ? "Start time" : "Simulation start"}</div>
        <div>{startDate.format("HH:mm DD/MM YY")}</div>
      </div>
      <PencilAltIcon className="w-3 h-3 mx-1" />
      {editing && renderEditor()}
    </div>
  );
};

const EndTimeEditable: React.FC<{
  startDate: Dayjs;
  endTimeVal: TimeValue;
  onUpdate: (updated: TimeValue) => void;
  timeMode: "duration" | "continuous";
  setTimeMode: (update: "duration" | "continuous") => void;
}> = ({ endTimeVal, onUpdate, startDate, timeMode, setTimeMode }) => {
  const [editing, setEditing] = useState(false);

  const timeModOptions = useMemo(() => {
    return [
      { id: "duration", display: "Duration" },
      { id: "continuous", display: "Run continuously" },
    ];
  }, []);

  const editorRef = useRef<HTMLDivElement>(null);
  const closeEditor = useCallback(() => {
    setEditing(false);
  }, []);
  useClickOutsideEffect(editorRef, closeEditor, "time_val_selecter");

  const endDate = useMemo(() => {
    return startDate.add(endTimeVal.value, "seconds");
  }, [endTimeVal, startDate]);

  const renderEditor = () => {
    return (
      <div
        ref={editorRef}
        className="bg-white border border-gray-200 absolute top-0 right-0 z-20 p-4 shadow-md rounded-lg w-64"
      >
        <Dropdown
          headlessStyle
          className="text-xs pr-2 mb-2"
          options={timeModOptions}
          onSelect={(option) => {
            setTimeMode(option.id as "duration" | "continuous");
          }}
          selectedID={timeMode}
        />

        {timeMode === "duration" && (
          <TimeValueInput
            timeValue={endTimeVal}
            updateValue={(updatedVal) => {
              onUpdate({ ...endTimeVal, value: updatedVal });
            }}
            update={(updated) => {
              onUpdate(updated);
            }}
          />
        )}
        {timeMode === "continuous" && (
          <div className="">Will run as digital twin continuously until stopped.</div>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() => setEditing(true)}
      className={`flex items-center text-xxs leading-tight bg-white border border-gray-200 rounded-lg shadow py-1 cursor-pointer relative`}
    >
      <div className="pl-2">
        {timeMode === "duration" && (
          <>
            <div>Simulation end</div>
            <div>{endDate.format("HH:mm DD/MM YY")}</div>
          </>
        )}
        {timeMode === "continuous" && <div>Running continuously</div>}
      </div>
      <PencilAltIcon className="h-3 w-3 mx-1" />
      {editing && renderEditor()}
    </div>
  );
};
