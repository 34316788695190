import React, { useState, useRef, useCallback, useEffect } from "react";
import { TimePicker } from "./Timepicker";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import InputNumber from "components/basic/headless/InputNumber";
import { TimeSlot } from "model/datatypes";

interface Props {
  slot: TimeSlot;
  onUpdate: (slot: TimeSlot) => void;
  unit?: string;
  isNew?: boolean;
}

export const TimeSlotEditable: React.FC<Props> = ({ slot, onUpdate, unit, isNew }) => {
  const [isFocused, setIsFocused] = useState(isNew || false);

  const [editedSlot, setEditedSlot] = useState(slot);
  const [edited, setEdited] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickoutside = useCallback(() => {
    if (edited) onUpdate(editedSlot);
    setEdited(false);
    setIsFocused(false);
  }, [edited, editedSlot, onUpdate, setIsFocused]);

  useClickOutsideEffect(ref, handleClickoutside);

  useEffect(() => {
    if (isFocused) inputRef.current?.focus();
  }, [isFocused]);

  const updateEdited = useCallback((slot: TimeSlot) => {
    setEditedSlot((p) => ({ ...p, ...slot }));
    setEdited(true);
    console.log("update edited");
  }, []);

  return (
    <div
      ref={ref}
      className={`flex cursor-pointer items-center relative py-1 my-2 border rounded ${
        isFocused ? " border-green-numerous " : "border-gray-200"
      }`}
      onClick={() => {
        // setIsFocused(slot.id);
        setIsFocused(true);
      }}
    >
      <div className={`w-1/2 text-xs focus:outline-none cursor-pointer px-2`}>
        {`${editedSlot.startTime.format("HH:mm")} - ${editedSlot.endTime.format("HH:mm")}`}
      </div>
      <InputNumber
        ref={inputRef}
        value={editedSlot.setting}
        onChange={(val) => {
          console.log({ val });
          setEditedSlot({ ...editedSlot, setting: val });
          setEdited(true);
        }}
        className={`w-1/2 text-xs focus:outline-none cursor-pointer text-right px-2`}
      />
      {unit && <div className="text-xs px-2">{unit}</div>}

      {isFocused && (
        <div className="absolute top-0 left-0 z-10 w-full" style={{ marginTop: "1.75rem" }}>
          <TimePicker onUpdate={updateEdited} slot={editedSlot} />
        </div>
      )}
    </div>
  );
};

interface AddTimeslotProps {
  onAdd: (slot: TimeSlot) => void;
}

export const AddTimeslot: React.FC<AddTimeslotProps> = ({ onAdd }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => {
    setisOpen(false);
  }, []);

  useClickOutsideEffect(ref, closeDropdown);

  const onAddedTime = useCallback(
    (newTimeslot: TimeSlot) => {
      onAdd(newTimeslot);
      setisOpen(false);
    },
    [onAdd]
  );

  return (
    <div className="relative">
      <button onClick={() => setisOpen(true)} className={`button-small`}>
        Add new setting
      </button>
      {isOpen && (
        <div ref={ref} className="absolute right-0 z-10 mt-2 w-88">
          <TimePicker onUpdate={onAddedTime} />
        </div>
      )}
    </div>
  );
};
