import { useIdToken } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import ArrowIcon from "components/basic/icons/ArrowIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import dayjs from "dayjs";
import { motion, useAnimation } from "framer-motion";
import { PIDiagram, PIDTag } from "model/datatypes";
import { PlotData } from "plotly.js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Plot from "react-plotly.js";
import clamp from "utils/clamp";
import { IconsOptions } from "./icons/PIDIcons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PropsList } from "react-zoom-pan-pinch/dist/store/interfaces/propsInterface";
import { Dataframe } from "grpc/grpcReact";
import { useGlobalState } from "store";
import { getFileURL } from "grpc/api/grpcUtilAPI";

interface Props {
  onFinish: () => void;
  diagram: PIDiagram;
  // simulation_run_time: number; //seconds simulation has run for....
  scenarioID: string;
  projectID: string;
  data: Dataframe | null;
  t: number[] | null;
}

const PIDViewer = ({ onFinish, diagram, data, t }: Props) => {
  const startTime = useMemo(() => dayjs().startOf("year"), []);
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const [loadingIMG, setLoadingIMG] = useState(false);
  const [imgURL, setImgURL] = useState("");
  useEffect(() => {
    if (!idToken) return;
    setLoadingIMG(true);
    getFileURL(grpcURL, idToken, diagram.bg.path)
      .then((url) => {
        setImgURL(url);
        setLoadingIMG(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingIMG(false);
      });
  }, [diagram, idToken, grpcURL]);

  const dataLength = useMemo(() => (t ? t.length : 100), [t]);

  useEffect(() => {
    if (!data) return;
    const firstKey = Object.keys(data)[0];
    if (firstKey) setSelecedField({ field: firstKey, fieldI: 0 });
  }, [data]);

  const [selecedField, setSelecedField] = useState<{ field: string; fieldI: number } | null>(
    null
  );

  const [pannedDataI, setPannedDataI] = useState(0);
  const [selectedDataI, setSelectedDataI] = useState(0);
  const timelinePosRef = useRef(0); //used for calculation of position

  const currentDataRow = useMemo(() => {
    if (data && t && selectedDataI > -1 && selectedDataI < t.length) {
      const dataframeRow: { [key: string]: number } = {};
      Object.entries(data).forEach((entry) => {
        const key = entry[0];
        const rowVal = entry[1][selectedDataI];
        dataframeRow[key] = rowVal;
      });
      return dataframeRow;
    } else return undefined;
  }, [data, selectedDataI, t]);

  const loading = useMemo(() => loadingIMG, [loadingIMG]);

  const timelineControls = useAnimation();

  const updateTimelinePosition = useCallback(
    (newPos: number) => {
      setSelectedDataI(newPos);
      setPannedDataI(newPos);
      timelinePosRef.current = newPos;
      if (timelineRef.current) {
        const cusPosFraction = newPos / dataLength;
        const cursorTargetPos = cusPosFraction * timelineRef.current.offsetWidth;
        timelineControls.start({ x: cursorTargetPos });
      }
    },
    [timelineControls, dataLength]
  );

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      console.log(e.key);
      if (e.key === "ArrowRight") {
        if (timelinePosRef.current < dataLength)
          updateTimelinePosition(timelinePosRef.current + 1);
      }
      if (e.key === "ArrowLeft") {
        if (timelinePosRef.current > 0) updateTimelinePosition(timelinePosRef.current - 1);
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [updateTimelinePosition, dataLength]);

  const bgRef = useRef<HTMLImageElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);

  const renderTag = (tag: PIDTag) => {
    const extraTagWidth = tag.unit.length * 4;
    const icon = IconsOptions.find((icon) => icon.id === tag.icon)?.icon;

    const value = currentDataRow && tag.sourceID ? currentDataRow[tag.sourceID] : undefined;
    const displayValue =
      typeof value === "string"
        ? value
        : typeof value === "number"
        ? value.toFixed(2)
        : `(${tag.name.slice(0, 9)}${tag.name.length > 9 ? ".." : ""})`;

    return (
      <g
        key={tag.id}
        id={tag.id}
        transform={`translate(${tag.position[0]}, ${tag.position[1]})`}
      >
        <g>
          <g>
            <rect
              x={0}
              y={0}
              rx={10}
              width={100 + extraTagWidth}
              height={20}
              fill={"#fff"}
              stroke={"#a3a3a3"}
              strokeWidth={1}
            />
            {icon}

            <text x={26} y={13.5} fontSize={10}>
              {displayValue}
            </text>
            <text x={87} y={13.5} fontSize={10}>
              {`${tag.unit}`}
            </text>
          </g>
        </g>
      </g>
    );
  };

  const renderGraphic = () => {
    return (
      <>
        <TransformWrapper
          options={{ minScale: 0.5, limitToBounds: false }}
          wheel={{ step: 50 }}
          defaultScale={1}
        >
          {({ zoomIn, zoomOut }: PropsList) => (
            <React.Fragment>
              <div></div>
              <TransformComponent>
                <div
                  className="relative"
                  style={{ width: diagram.canvasSize[0], height: diagram.canvasSize[1] }}
                >
                  <img ref={bgRef} src={imgURL} alt="" className="max-w-none w-full h-full" />
                  <svg
                    width={diagram.canvasSize[0]}
                    height={diagram.canvasSize[1]}
                    className="absolute top-0 left-0"
                  >
                    {diagram.tags.map((tag) => {
                      return renderTag(tag);
                    })}
                    <g></g>
                  </svg>
                </div>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </>
    );
  };

  const renderControls = () => {
    return (
      <div className="relative w-full">
        <div className="pl-8 py-4 w-40 absolute top-0 left-0">
          <div className="flex items-center text-xs mb-2">
            <span className="mr-2 font-medium">TIMESTEP</span>
            <Dropdown
              headlessStyle
              flipped
              className="border-b border-gray-600 w-20 pl-3 pr-2 py-1"
              options={[{ id: "hour", display: "1 Hour" }]}
              onSelect={(option) => {}}
              selectedID={"hour"}
            />
          </div>
          <div className="flex">
            <button
              onClick={() => updateTimelinePosition(timelinePosRef.current - 1)}
              className="w-6 h-6 rounded-full flex items-center justify-center bg-white border border-gray-200 focus:outline-none"
            >
              <ArrowIcon direction="left" />
            </button>
            <button
              onClick={() => updateTimelinePosition(timelinePosRef.current + 1)}
              className="w-6 h-6 ml-4 rounded-full flex items-center justify-center bg-white border border-gray-200 focus:outline-none"
            >
              <ArrowIcon direction="right" />
            </button>
          </div>
          <div className={`text-xs font-medium mt-2`}>Timeline background</div>
          <Dropdown
            flipped
            className="w-32 text-xs bg-white"
            options={
              data
                ? Object.keys(data).map((field, i) => ({
                    id: field,
                    display: field,
                    val: { field, fieldI: i },
                  }))
                : []
            }
            onSelect={(option) => {
              setSelecedField(option.val);
            }}
            selectedID={selecedField?.field}
          />
        </div>
        <div className="w-full pl-48 pr-8 py-4">
          <div
            ref={timelineRef}
            className="w-full h-20 bg-white rounded relative"
            onDoubleClick={(e) => {
              console.log(e);
            }}
          >
            <motion.div
              drag={"x"}
              dragConstraints={timelineRef}
              dragMomentum={false}
              className="absolute z-10 bottom-0 left-0 border-l w-px border-gray-700 border-dashed"
              style={{ height: "130%", cursor: "col-resize" }}
              animate={timelineControls}
              onDrag={(e, pan) => {
                if (timelineRef.current) {
                  let deltaI =
                    (Math.abs(pan.offset.x) / timelineRef.current.offsetWidth) * dataLength;
                  if (pan.offset.x < 0) deltaI = deltaI * -1;
                  const newI = selectedDataI + Math.round(deltaI);
                  setPannedDataI(clamp(newI, 0, dataLength));
                }
              }}
              onDragEnd={() => {
                setSelectedDataI(pannedDataI);
                timelinePosRef.current = pannedDataI;
              }}
            >
              <div className="absolute top-0 left-0 bg-gray-700 rounded-t rounded-br px-2 text-white text-xs -mt-2 -ml-px">
                {startTime.add(pannedDataI, "hour").format("DD/MM HH:ss")}
              </div>
            </motion.div>
            <div className="w-full overflow-hidden">
              {selecedField && <TimelinePlot data={data} t={t} selecedField={selecedField} />}
            </div>
          </div>
          <div className="w-full flex justify-between mt-2 text-xs">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((nr) => {
              return <div key={nr}>{dayjs(`${nr}`, "MM").format("DD MMM")}</div>;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div
        className="flex flex-col relative bg-white shadow z-30 w-full rounded border border-gray-300"
        style={{ height: "95vh" }}
      >
        <div className={`text-xs font-medium pt-4 px-8 absolute top-0 left-0 z-20`}>
          {diagram.name}
        </div>
        <div className={`h-full pb-40 w-full overflow-hidden relative rounded`}>
          {renderGraphic()}
        </div>
        <div className="w-full absolute bottom-0 left-0 bg-gray-300 flex">
          {renderControls()}
        </div>
        {loading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default PIDViewer;

const TimelinePlot: React.FC<{
  data: Dataframe | null;
  t: number[] | null;
  selecedField: { field: string; fieldI: number };
}> = ({ data, selecedField, t }) => {
  const plotData = useMemo(() => {
    if (data && t) {
      const fieldData = data[selecedField.field];

      return (
        fieldData &&
        ([
          {
            x: t,
            y: fieldData,
            type: "scatter",
            mode: "lines",
            // name: fieldName,
            line: { width: 0.3 },
          },
        ] as Partial<PlotData>[] | undefined)
      );
    }
  }, [data, selecedField, t]);

  return (
    <div className="w-full h-20 rounded">
      {plotData && (
        <Plot
          className={``}
          data={plotData}
          useResizeHandler
          config={{ displayModeBar: false, staticPlot: true, frameMargins: 0 }}
          style={{ width: "100%", height: "100%" }}
          layout={{
            autosize: true,
            paper_bgcolor: "#fff",
            plot_bgcolor: "#fff",
            showlegend: false,
            margin: {
              t: 0,
              b: 0,
              l: 0,
              r: 0,
              pad: 0,
            },
            xaxis: {
              showgrid: false,
              zeroline: false,
              visible: false,
            },
            yaxis: {
              showgrid: false,
              zeroline: false,
              visible: false,
            },
          }}
        />
      )}
    </div>
  );
};
