import { Component, ComponentType, Container } from "model/datatypes";
import {
  mergeComponenets,
  updateComponentsNames,
  compToSubComp,
  ParamTypeToInstance,
} from "./ComponentTypeHelpers";
import getUUID from "./jsUtils/getUUID";

export const experimentalInstantiateAllComponents = (
  componentTypes: ComponentType[],
  containers: Container[]
) => {
  let mainComponents = componentTypes
    .filter((ct) => ct.instantiationRules === undefined || ct.instantiationRules.isMain)
    .map((ct) => {
      return InstantiateComponentExperimental(ct, true);
    });

  //add containers
  containers
    .filter((ct) => ct.instantiationRules.isMain)
    .forEach((container) => {
      let compType = componentTypes.find((ct) => ct.id === container.defaultComponentType);

      //if no default try to select the first one:
      if (!compType)
        compType = componentTypes.find((ct) => ct.id === container.componentTypeIDs[0]);

      if (compType) {
        mainComponents.push(InstantiateComponentExperimental(compType, true, container.id));
      }
    });

  const allComponents = ExperimentalInstantiateSubComps(mainComponents, componentTypes);
  const componentsWithSiblingsNames = updateComponentsNames(allComponents);

  return componentsWithSiblingsNames;
};

//used when going over components to instantiate..
//flat list of all components and their types:
type CompListItem = {
  comp: Component;
  prevComp?: Component;
  compType: ComponentType;
  subsInstantiated: boolean;
};

export const ExperimentalInstantiateSubComps = (
  mainInstances: Component[],
  componentTypes: ComponentType[],
  prevComps?: Component[]
) => {
  //Start intantiation for subcomponents
  let compList: CompListItem[] = mainInstances.map((comp) => {
    const prevComp = prevComps?.find(
      (pc) => pc.isMainComponent && pc.id === comp.id && pc.containerID === comp.containerID
    );
    const compType = componentTypes.find((ct) => ct.id === comp.id) as ComponentType;
    return {
      comp,
      compType,
      prevComp,
      subsInstantiated: false,
    };
  });

  //instantiate comps and subcomps:
  let level = 0; //max 5 levels of subComponents to avoid infinite loops..
  //while some component remains to have checked to subCs:
  while (compList.some((cli) => !cli.subsInstantiated) && level < 5) {
    const newItems: CompListItem[] = [];
    const instantiatedList: CompListItem[] = [];

    compList.forEach((cli) => {
      if (
        cli.subsInstantiated ||
        !cli.compType.subComponents ||
        cli.compType.subComponents.length === 0
      ) {
        instantiatedList.push({ ...cli, subsInstantiated: true }); //no instantiation required
        return;
      }

      const newSubComps: CompListItem[] = [];

      cli.compType.subComponents.forEach((sub) => {
        //find sub component type and instantiate component:
        const compType = componentTypes.find((ct) => ct.id === sub.componentTypeId);

        //if previous component had subs components, instantiate from these:
        const prevSub = cli.prevComp?.subcomponents?.find((sc) => sc.uuid === sub.uuid);

        if (prevSub && compType) {
          const prevSubComp = prevComps?.find((pc) => pc.uuid === prevSub?.uuid);

          let newSubCompInstance = InstantiateComponentExperimental(compType, false);
          newSubCompInstance = { ...newSubCompInstance, uuid: sub.uuid };

          if (prevSubComp)
            newSubCompInstance = mergeComponenets(newSubCompInstance, prevSubComp);

          newSubComps.push({
            compType: compType,
            comp: newSubCompInstance,
            subsInstantiated: false,
            prevComp: prevSubComp,
          });
        } else if (compType) {
          //else default rules:
          let newSubCompInstance = InstantiateComponentExperimental(compType, false);
          newSubCompInstance = { ...newSubCompInstance, uuid: sub.uuid };

          //overwrite default parameters:

          newSubCompInstance.parameters = newSubCompInstance.parameters.map((param) => {
            const defaultVal = sub.stdParameters.find(
              (stdParam) => stdParam.uuid === param.uuid
            )?.value;
            if (defaultVal) return { ...param, value: defaultVal };
            else return param;
          });

          newSubCompInstance.inputVariables = newSubCompInstance.inputVariables.map(
            (inputVar) => {
              const defaultVal = sub.stdVariables.find(
                (stdVar) => stdVar.uuid === inputVar.uuid
              )?.value;
              if (defaultVal) return { ...inputVar, value: defaultVal };
              else return inputVar;
            }
          );

          newSubComps.push({
            compType: compType,
            comp: newSubCompInstance,
            subsInstantiated: false,
          });
        }
      });

      //add sub compomponents to list of all components:
      newItems.push(...newSubComps);

      //add subcomponents reference to parent components.....
      cli.comp.subcomponents = newSubComps.map((sc) => compToSubComp(sc.comp));
      return instantiatedList.push({ ...cli, subsInstantiated: true });
    });

    compList = [...instantiatedList, ...newItems];
    level++;
  }

  return compList.map((listItem) => listItem.comp);
};

//////////////////////////////////////////////
////functions for instantiation with merge////
//////////////////////////////////////////////
export const experimentalInstantiateAllComponentsFromPrev = (
  componentTypes: ComponentType[],
  containers: Container[],
  prevComps: Component[]
) => {
  //Create main instances for comptypes
  const mainInstances = componentTypes
    .filter((ct) => ct.instantiationRules === undefined || ct.instantiationRules.isMain)
    .map((ct) => InstantiateComponentExperimental(ct, true))
    .map((newComp) => {
      //merge components with prev:
      const existingComp = prevComps.find((c) => c.name === newComp.name);
      if (!existingComp) return newComp;
      return mergeComponenets(newComp, existingComp);
    });

  //Add main instances for containers
  containers
    .filter((ct) => ct.instantiationRules.isMain)
    .forEach((container) => {
      //check if there is a previous component for the container:
      const prevCompInstance = prevComps.find(
        (comp) => comp.isMainComponent && comp.containerID === container.id
      );

      let newCompType = componentTypes.find(
        (ct) =>
          (prevCompInstance && ct.id === prevCompInstance?.id) ||
          (!prevCompInstance && container.defaultComponentType === ct.id)
      );

      //if no component found just take the first one:
      if (!newCompType)
        newCompType = componentTypes.find((ct) => ct.id === container.componentTypeIDs[0]);

      if (newCompType) {
        let newComInstance = InstantiateComponentExperimental(newCompType, true, container.id);
        if (prevCompInstance)
          newComInstance = mergeComponenets(newComInstance, prevCompInstance);
        mainInstances.push(newComInstance);
      }
    });

  const allComponents = ExperimentalInstantiateSubComps(
    mainInstances,
    componentTypes,
    prevComps
  );

  const componentsWithSiblingsNames: Component[] = updateComponentsNames(allComponents);
  console.log({ componentsWithSiblingsNames });
  return componentsWithSiblingsNames;
};

const InstantiateComponentExperimental = (
  compType: ComponentType,
  isMainComp: boolean,
  containerID?: string,
  defaultEnabled?: boolean
) => {
  const { parameters, subCompRules, instantiationRules, ...c } = compType;

  const disabled =
    instantiationRules?.allowDisabling &&
    !instantiationRules?.defaultEnabled &&
    !defaultEnabled;

  const component: Component = {
    ...c,
    disabled,
    parameters: parameters.map((p) => ParamTypeToInstance(p)),
    inputVariables: c.inputVariables
      .filter((variable) => !variable.type || variable.type === "input")
      .map((variable) => {
        //@ts-ignore
        delete variable.sourceType;
        if (!variable.dataSourceType) variable.dataSourceType = "static";
        return variable;
      }),
    uuid: containerID ? `comp_${containerID}` : getUUID(),
    isMainComponent: isMainComp,
    ...(containerID && { containerID }),
  };
  return component;
};
