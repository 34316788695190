import { Component, ComponentParameter, InputVar, SimulationScenario } from "model/datatypes";

export const checkScenarioStatus = (scenario: SimulationScenario) => {
  //check sim is valid here
  let status: SimulationScenario["status"] = "ready";
  if (!scenario.simComponents || !componentsvalid(scenario.simComponents)) {
    status = "Components Incomplete";
  }
  return status;
};

const componentsvalid = (components: Component[]) => {
  const componentsValid = components.reduce((prev, c) => {
    const allParamValid = !c.parameters
      ? true
      : c.parameters.reduce((prev, p) => prev && parameterIsValid(p), true);

    const allValuesValid = c.inputVariables.reduce(
      (prev, val) => prev && simulationVariableIsValid(val),
      true
    );
    const valid = c.disabled || (allParamValid && allValuesValid);

    return prev && valid;
  }, true);
  return componentsValid;
};

const parameterIsValid = (param: ComponentParameter) => {
  let valid = true;
  //If required should not be null
  if (param.value === null && param.optional) return true;
  if (param.value === null) valid = false;
  else if (param.type === "month") {
    if (param.value < 1 || param.value > 12) valid = false;
  }
  return valid;
};

const simulationVariableIsValid = (simVar: InputVar) => {
  let valid = true;
  //different checks goes here .....
  if (simVar.dataSourceType === "static") {
    if (isNaN(simVar.value)) valid = false;
  } else if (!simVar.tagSource && !simVar.CMTag) {
    valid = false;
  }
  return valid;
};
