import React from "react";
import SearchIcon from "./icons/SearchIcon";

interface Props {
  value: string;
  onUpdate: (updated: string) => void;
}

const SearchBar = ({ value, onUpdate }: Props) => {
  return (
    <div className="flex items-center border bg-white mx-4 border-gray-200 rounded-full px-2 py-px">
      <div className="py-1 flex items-center">
        <SearchIcon />
      </div>
      <input
        className={`focus:outline-none px-2 py-1 text-xs`}
        aria-label="Search field"
        type="text"
        value={value}
        onChange={(e) => onUpdate(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
