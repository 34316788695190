/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as spm_pb from './spm_pb';


export class SPMClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDataSetClosed = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Closed,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Closed.deserializeBinary
  );

  getDataSetClosed(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Closed>;

  getDataSetClosed(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Closed) => void): grpcWeb.ClientReadableStream<spm_pb.Closed>;

  getDataSetClosed(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Closed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetDataSetClosed',
        request,
        metadata || {},
        this.methodInfoGetDataSetClosed,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetDataSetClosed',
    request,
    metadata || {},
    this.methodInfoGetDataSetClosed);
  }

  methodInfoClearData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  clearData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  clearData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  clearData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/ClearData',
        request,
        metadata || {},
        this.methodInfoClearData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/ClearData',
    request,
    metadata || {},
    this.methodInfoClearData);
  }

  methodInfoReadData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.DataList,
    (request: spm_pb.ReadScenario) => {
      return request.serializeBinary();
    },
    spm_pb.DataList.deserializeBinary
  );

  readData(
    request: spm_pb.ReadScenario,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/SPM/ReadData',
      request,
      metadata || {},
      this.methodInfoReadData);
  }

  methodInfoCloseData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.DataCompleted) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  closeData(
    request: spm_pb.DataCompleted,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  closeData(
    request: spm_pb.DataCompleted,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  closeData(
    request: spm_pb.DataCompleted,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/CloseData',
        request,
        metadata || {},
        this.methodInfoCloseData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/CloseData',
    request,
    metadata || {},
    this.methodInfoCloseData);
  }

  methodInfoClearDataTags = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.ScenarioDataTags) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  clearDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  clearDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  clearDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/ClearDataTags',
        request,
        metadata || {},
        this.methodInfoClearDataTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/ClearDataTags',
    request,
    metadata || {},
    this.methodInfoClearDataTags);
  }

  methodInfoPushDataList = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.DataList) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  pushDataList(
    request: spm_pb.DataList,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  pushDataList(
    request: spm_pb.DataList,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  pushDataList(
    request: spm_pb.DataList,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/PushDataList',
        request,
        metadata || {},
        this.methodInfoPushDataList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/PushDataList',
    request,
    metadata || {},
    this.methodInfoPushDataList);
  }

  methodInfoGetScenarioMetaData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioMetaData,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioMetaData.deserializeBinary
  );

  getScenarioMetaData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioMetaData>;

  getScenarioMetaData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioMetaData) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioMetaData>;

  getScenarioMetaData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioMetaData) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetScenarioMetaData',
        request,
        metadata || {},
        this.methodInfoGetScenarioMetaData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetScenarioMetaData',
    request,
    metadata || {},
    this.methodInfoGetScenarioMetaData);
  }

  methodInfoSetScenarioMetaData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioMetaData) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  setScenarioMetaData(
    request: spm_pb.ScenarioMetaData,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  setScenarioMetaData(
    request: spm_pb.ScenarioMetaData,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  setScenarioMetaData(
    request: spm_pb.ScenarioMetaData,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioMetaData',
        request,
        metadata || {},
        this.methodInfoSetScenarioMetaData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioMetaData',
    request,
    metadata || {},
    this.methodInfoSetScenarioMetaData);
  }

  methodInfoGetScenarioCustomMetaData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioCustomMetaData,
    (request: spm_pb.ScenarioCustomMetaData) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioCustomMetaData.deserializeBinary
  );

  getScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioCustomMetaData>;

  getScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioCustomMetaData) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioCustomMetaData>;

  getScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioCustomMetaData) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetScenarioCustomMetaData',
        request,
        metadata || {},
        this.methodInfoGetScenarioCustomMetaData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetScenarioCustomMetaData',
    request,
    metadata || {},
    this.methodInfoGetScenarioCustomMetaData);
  }

  methodInfoSetScenarioCustomMetaData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioCustomMetaData,
    (request: spm_pb.ScenarioCustomMetaData) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioCustomMetaData.deserializeBinary
  );

  setScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioCustomMetaData>;

  setScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioCustomMetaData) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioCustomMetaData>;

  setScenarioCustomMetaData(
    request: spm_pb.ScenarioCustomMetaData,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioCustomMetaData) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioCustomMetaData',
        request,
        metadata || {},
        this.methodInfoSetScenarioCustomMetaData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioCustomMetaData',
    request,
    metadata || {},
    this.methodInfoSetScenarioCustomMetaData);
  }

  methodInfoGetScenarioDataStats = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioStats,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioStats.deserializeBinary
  );

  getScenarioDataStats(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioStats>;

  getScenarioDataStats(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioStats) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioStats>;

  getScenarioDataStats(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioStats) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetScenarioDataStats',
        request,
        metadata || {},
        this.methodInfoGetScenarioDataStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetScenarioDataStats',
    request,
    metadata || {},
    this.methodInfoGetScenarioDataStats);
  }

  methodInfoGetModel = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Model,
    (request: spm_pb.Model) => {
      return request.serializeBinary();
    },
    spm_pb.Model.deserializeBinary
  );

  getModel(
    request: spm_pb.Model,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Model>;

  getModel(
    request: spm_pb.Model,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Model) => void): grpcWeb.ClientReadableStream<spm_pb.Model>;

  getModel(
    request: spm_pb.Model,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Model) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetModel',
        request,
        metadata || {},
        this.methodInfoGetModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetModel',
    request,
    metadata || {},
    this.methodInfoGetModel);
  }

  methodInfoPushExecutionLogEntries = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.LogEntries) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  pushExecutionLogEntries(
    request: spm_pb.LogEntries,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  pushExecutionLogEntries(
    request: spm_pb.LogEntries,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  pushExecutionLogEntries(
    request: spm_pb.LogEntries,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/PushExecutionLogEntries',
        request,
        metadata || {},
        this.methodInfoPushExecutionLogEntries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/PushExecutionLogEntries',
    request,
    metadata || {},
    this.methodInfoPushExecutionLogEntries);
  }

  methodInfoReadExecutionLogEntries = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ExeLogEntry,
    (request: spm_pb.ExecutionReadLogs) => {
      return request.serializeBinary();
    },
    spm_pb.ExeLogEntry.deserializeBinary
  );

  readExecutionLogEntries(
    request: spm_pb.ExecutionReadLogs,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/SPM/ReadExecutionLogEntries',
      request,
      metadata || {},
      this.methodInfoReadExecutionLogEntries);
  }

  methodInfoStoreConfiguration = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Configuration) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  storeConfiguration(
    request: spm_pb.Configuration,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  storeConfiguration(
    request: spm_pb.Configuration,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  storeConfiguration(
    request: spm_pb.Configuration,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/StoreConfiguration',
        request,
        metadata || {},
        this.methodInfoStoreConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/StoreConfiguration',
    request,
    metadata || {},
    this.methodInfoStoreConfiguration);
  }

  methodInfoReadConfiguration = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Configuration,
    (request: spm_pb.ConfigurationRequest) => {
      return request.serializeBinary();
    },
    spm_pb.Configuration.deserializeBinary
  );

  readConfiguration(
    request: spm_pb.ConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Configuration>;

  readConfiguration(
    request: spm_pb.ConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Configuration) => void): grpcWeb.ClientReadableStream<spm_pb.Configuration>;

  readConfiguration(
    request: spm_pb.ConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Configuration) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/ReadConfiguration',
        request,
        metadata || {},
        this.methodInfoReadConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/ReadConfiguration',
    request,
    metadata || {},
    this.methodInfoReadConfiguration);
  }

  methodInfoGetScenario = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioDocument,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioDocument.deserializeBinary
  );

  getScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioDocument>;

  getScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioDocument) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioDocument>;

  getScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioDocument) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetScenario',
        request,
        metadata || {},
        this.methodInfoGetScenario,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetScenario',
    request,
    metadata || {},
    this.methodInfoGetScenario);
  }

  methodInfoGetGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.GroupDocument,
    (request: spm_pb.Group) => {
      return request.serializeBinary();
    },
    spm_pb.GroupDocument.deserializeBinary
  );

  getGroup(
    request: spm_pb.Group,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.GroupDocument>;

  getGroup(
    request: spm_pb.Group,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.GroupDocument) => void): grpcWeb.ClientReadableStream<spm_pb.GroupDocument>;

  getGroup(
    request: spm_pb.Group,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.GroupDocument) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetGroup',
        request,
        metadata || {},
        this.methodInfoGetGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetGroup',
    request,
    metadata || {},
    this.methodInfoGetGroup);
  }

  methodInfoGetProject = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ProjectDocument,
    (request: spm_pb.Project) => {
      return request.serializeBinary();
    },
    spm_pb.ProjectDocument.deserializeBinary
  );

  getProject(
    request: spm_pb.Project,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ProjectDocument>;

  getProject(
    request: spm_pb.Project,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ProjectDocument) => void): grpcWeb.ClientReadableStream<spm_pb.ProjectDocument>;

  getProject(
    request: spm_pb.Project,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ProjectDocument) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetProject',
        request,
        metadata || {},
        this.methodInfoGetProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetProject',
    request,
    metadata || {},
    this.methodInfoGetProject);
  }

  methodInfoDeleteScenario = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  deleteScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  deleteScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  deleteScenario(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/DeleteScenario',
        request,
        metadata || {},
        this.methodInfoDeleteScenario,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/DeleteScenario',
    request,
    metadata || {},
    this.methodInfoDeleteScenario);
  }

  methodInfoDeleteSystem = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.SystemRequest) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  deleteSystem(
    request: spm_pb.SystemRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  deleteSystem(
    request: spm_pb.SystemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  deleteSystem(
    request: spm_pb.SystemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/DeleteSystem',
        request,
        metadata || {},
        this.methodInfoDeleteSystem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/DeleteSystem',
    request,
    metadata || {},
    this.methodInfoDeleteSystem);
  }

  methodInfoDeleteUser = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.UserRequest) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  deleteUser(
    request: spm_pb.UserRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  deleteUser(
    request: spm_pb.UserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  deleteUser(
    request: spm_pb.UserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/DeleteUser',
        request,
        metadata || {},
        this.methodInfoDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/DeleteUser',
    request,
    metadata || {},
    this.methodInfoDeleteUser);
  }

  methodInfoCompleteUserSignup = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.SignupData) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  completeUserSignup(
    request: spm_pb.SignupData,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  completeUserSignup(
    request: spm_pb.SignupData,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  completeUserSignup(
    request: spm_pb.SignupData,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/CompleteUserSignup',
        request,
        metadata || {},
        this.methodInfoCompleteUserSignup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/CompleteUserSignup',
    request,
    metadata || {},
    this.methodInfoCompleteUserSignup);
  }

  methodInfoListenScenario = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioDocument,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioDocument.deserializeBinary
  );

  listenScenario(
    request: spm_pb.Scenario,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/SPM/ListenScenario',
      request,
      metadata || {},
      this.methodInfoListenScenario);
  }

  methodInfoSetScenarioProgress = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioProgress) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  setScenarioProgress(
    request: spm_pb.ScenarioProgress,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  setScenarioProgress(
    request: spm_pb.ScenarioProgress,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  setScenarioProgress(
    request: spm_pb.ScenarioProgress,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioProgress',
        request,
        metadata || {},
        this.methodInfoSetScenarioProgress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioProgress',
    request,
    metadata || {},
    this.methodInfoSetScenarioProgress);
  }

  methodInfoSetScenarioDataTags = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioDataTags) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  setScenarioDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  setScenarioDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  setScenarioDataTags(
    request: spm_pb.ScenarioDataTags,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioDataTags',
        request,
        metadata || {},
        this.methodInfoSetScenarioDataTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioDataTags',
    request,
    metadata || {},
    this.methodInfoSetScenarioDataTags);
  }

  methodInfoSetScenarioJobImage = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ExecutionId,
    (request: spm_pb.ScenarioJobImage) => {
      return request.serializeBinary();
    },
    spm_pb.ExecutionId.deserializeBinary
  );

  setScenarioJobImage(
    request: spm_pb.ScenarioJobImage,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ExecutionId>;

  setScenarioJobImage(
    request: spm_pb.ScenarioJobImage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ExecutionId) => void): grpcWeb.ClientReadableStream<spm_pb.ExecutionId>;

  setScenarioJobImage(
    request: spm_pb.ScenarioJobImage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ExecutionId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioJobImage',
        request,
        metadata || {},
        this.methodInfoSetScenarioJobImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioJobImage',
    request,
    metadata || {},
    this.methodInfoSetScenarioJobImage);
  }

  methodInfoPushScenarioError = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioError) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  pushScenarioError(
    request: spm_pb.ScenarioError,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  pushScenarioError(
    request: spm_pb.ScenarioError,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  pushScenarioError(
    request: spm_pb.ScenarioError,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/PushScenarioError',
        request,
        metadata || {},
        this.methodInfoPushScenarioError,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/PushScenarioError',
    request,
    metadata || {},
    this.methodInfoPushScenarioError);
  }

  methodInfoPushScenarioFormattedError = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioFormattedError) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  pushScenarioFormattedError(
    request: spm_pb.ScenarioFormattedError,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  pushScenarioFormattedError(
    request: spm_pb.ScenarioFormattedError,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  pushScenarioFormattedError(
    request: spm_pb.ScenarioFormattedError,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/PushScenarioFormattedError',
        request,
        metadata || {},
        this.methodInfoPushScenarioFormattedError,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/PushScenarioFormattedError',
    request,
    metadata || {},
    this.methodInfoPushScenarioFormattedError);
  }

  methodInfoGetSignedURLs = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.FileSignedUrls,
    (request: spm_pb.FilePaths) => {
      return request.serializeBinary();
    },
    spm_pb.FileSignedUrls.deserializeBinary
  );

  getSignedURLs(
    request: spm_pb.FilePaths,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.FileSignedUrls>;

  getSignedURLs(
    request: spm_pb.FilePaths,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.FileSignedUrls) => void): grpcWeb.ClientReadableStream<spm_pb.FileSignedUrls>;

  getSignedURLs(
    request: spm_pb.FilePaths,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.FileSignedUrls) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetSignedURLs',
        request,
        metadata || {},
        this.methodInfoGetSignedURLs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetSignedURLs',
    request,
    metadata || {},
    this.methodInfoGetSignedURLs);
  }

  methodInfoGenerateScenarioUploadSignedURL = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioUploadURL,
    (request: spm_pb.ScenarioFilePath) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioUploadURL.deserializeBinary
  );

  generateScenarioUploadSignedURL(
    request: spm_pb.ScenarioFilePath,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioUploadURL>;

  generateScenarioUploadSignedURL(
    request: spm_pb.ScenarioFilePath,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioUploadURL) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioUploadURL>;

  generateScenarioUploadSignedURL(
    request: spm_pb.ScenarioFilePath,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioUploadURL) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GenerateScenarioUploadSignedURL',
        request,
        metadata || {},
        this.methodInfoGenerateScenarioUploadSignedURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GenerateScenarioUploadSignedURL',
    request,
    metadata || {},
    this.methodInfoGenerateScenarioUploadSignedURL);
  }

  methodInfoClearScenarioResults = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  clearScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  clearScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  clearScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/ClearScenarioResults',
        request,
        metadata || {},
        this.methodInfoClearScenarioResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/ClearScenarioResults',
    request,
    metadata || {},
    this.methodInfoClearScenarioResults);
  }

  methodInfoSetScenarioResults = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Scenario,
    (request: spm_pb.ScenarioResults) => {
      return request.serializeBinary();
    },
    spm_pb.Scenario.deserializeBinary
  );

  setScenarioResults(
    request: spm_pb.ScenarioResults,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Scenario>;

  setScenarioResults(
    request: spm_pb.ScenarioResults,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void): grpcWeb.ClientReadableStream<spm_pb.Scenario>;

  setScenarioResults(
    request: spm_pb.ScenarioResults,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Scenario) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/SetScenarioResults',
        request,
        metadata || {},
        this.methodInfoSetScenarioResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/SetScenarioResults',
    request,
    metadata || {},
    this.methodInfoSetScenarioResults);
  }

  methodInfoGetScenarioResults = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ScenarioResults,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ScenarioResults.deserializeBinary
  );

  getScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ScenarioResults>;

  getScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ScenarioResults) => void): grpcWeb.ClientReadableStream<spm_pb.ScenarioResults>;

  getScenarioResults(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ScenarioResults) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetScenarioResults',
        request,
        metadata || {},
        this.methodInfoGetScenarioResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetScenarioResults',
    request,
    metadata || {},
    this.methodInfoGetScenarioResults);
  }

  methodInfoSubscribeForUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.SubscriptionMessage,
    (request: spm_pb.Subscription) => {
      return request.serializeBinary();
    },
    spm_pb.SubscriptionMessage.deserializeBinary
  );

  subscribeForUpdates(
    request: spm_pb.Subscription,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/SPM/SubscribeForUpdates',
      request,
      metadata || {},
      this.methodInfoSubscribeForUpdates);
  }

  methodInfoPublishSubcsriptionMessage = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.SubscriptionMessage) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  publishSubcsriptionMessage(
    request: spm_pb.SubscriptionMessage,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  publishSubcsriptionMessage(
    request: spm_pb.SubscriptionMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  publishSubcsriptionMessage(
    request: spm_pb.SubscriptionMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/PublishSubcsriptionMessage',
        request,
        metadata || {},
        this.methodInfoPublishSubcsriptionMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/PublishSubcsriptionMessage',
    request,
    metadata || {},
    this.methodInfoPublishSubcsriptionMessage);
  }

  methodInfoCompleteExecution = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.CompleteExecutionMessage) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  completeExecution(
    request: spm_pb.CompleteExecutionMessage,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  completeExecution(
    request: spm_pb.CompleteExecutionMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  completeExecution(
    request: spm_pb.CompleteExecutionMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/CompleteExecution',
        request,
        metadata || {},
        this.methodInfoCompleteExecution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/CompleteExecution',
    request,
    metadata || {},
    this.methodInfoCompleteExecution);
  }

  methodInfoCompleteExecutionIgnoreInstance = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.ExecutionMessage) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  completeExecutionIgnoreInstance(
    request: spm_pb.ExecutionMessage,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  completeExecutionIgnoreInstance(
    request: spm_pb.ExecutionMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  completeExecutionIgnoreInstance(
    request: spm_pb.ExecutionMessage,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/CompleteExecutionIgnoreInstance',
        request,
        metadata || {},
        this.methodInfoCompleteExecutionIgnoreInstance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/CompleteExecutionIgnoreInstance',
    request,
    metadata || {},
    this.methodInfoCompleteExecutionIgnoreInstance);
  }

  methodInfoListenExecutions = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Json,
    (request: spm_pb.Empty) => {
      return request.serializeBinary();
    },
    spm_pb.Json.deserializeBinary
  );

  listenExecutions(
    request: spm_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/SPM/ListenExecutions',
      request,
      metadata || {},
      this.methodInfoListenExecutions);
  }

  methodInfoUpdateExecution = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Json) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  updateExecution(
    request: spm_pb.Json,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  updateExecution(
    request: spm_pb.Json,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  updateExecution(
    request: spm_pb.Json,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/UpdateExecution',
        request,
        metadata || {},
        this.methodInfoUpdateExecution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/UpdateExecution',
    request,
    metadata || {},
    this.methodInfoUpdateExecution);
  }

  methodInfoClearExecutionMemory = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  clearExecutionMemory(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  clearExecutionMemory(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  clearExecutionMemory(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/ClearExecutionMemory',
        request,
        metadata || {},
        this.methodInfoClearExecutionMemory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/ClearExecutionMemory',
    request,
    metadata || {},
    this.methodInfoClearExecutionMemory);
  }

  methodInfoRemoveExecutionData = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  removeExecutionData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  removeExecutionData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  removeExecutionData(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/RemoveExecutionData',
        request,
        metadata || {},
        this.methodInfoRemoveExecutionData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/RemoveExecutionData',
    request,
    metadata || {},
    this.methodInfoRemoveExecutionData);
  }

  methodInfoGetLatestMainExecution = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ExecutionId,
    (request: spm_pb.Scenario) => {
      return request.serializeBinary();
    },
    spm_pb.ExecutionId.deserializeBinary
  );

  getLatestMainExecution(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ExecutionId>;

  getLatestMainExecution(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ExecutionId) => void): grpcWeb.ClientReadableStream<spm_pb.ExecutionId>;

  getLatestMainExecution(
    request: spm_pb.Scenario,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ExecutionId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/GetLatestMainExecution',
        request,
        metadata || {},
        this.methodInfoGetLatestMainExecution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/GetLatestMainExecution',
    request,
    metadata || {},
    this.methodInfoGetLatestMainExecution);
  }

  methodInfoUpdateProbe = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.ProbeResponse,
    (request: spm_pb.ProbeInformation) => {
      return request.serializeBinary();
    },
    spm_pb.ProbeResponse.deserializeBinary
  );

  updateProbe(
    request: spm_pb.ProbeInformation,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.ProbeResponse>;

  updateProbe(
    request: spm_pb.ProbeInformation,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.ProbeResponse) => void): grpcWeb.ClientReadableStream<spm_pb.ProbeResponse>;

  updateProbe(
    request: spm_pb.ProbeInformation,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.ProbeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/SPM/UpdateProbe',
        request,
        metadata || {},
        this.methodInfoUpdateProbe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/SPM/UpdateProbe',
    request,
    metadata || {},
    this.methodInfoUpdateProbe);
  }

}

export class JobManagerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoStartJob = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  startJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  startJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  startJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/StartJob',
        request,
        metadata || {},
        this.methodInfoStartJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/StartJob',
    request,
    metadata || {},
    this.methodInfoStartJob);
  }

  methodInfoStartJobWithTime = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.JobTime) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  startJobWithTime(
    request: spm_pb.JobTime,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  startJobWithTime(
    request: spm_pb.JobTime,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  startJobWithTime(
    request: spm_pb.JobTime,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/StartJobWithTime',
        request,
        metadata || {},
        this.methodInfoStartJobWithTime,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/StartJobWithTime',
    request,
    metadata || {},
    this.methodInfoStartJobWithTime);
  }

  methodInfoStartJobWithSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.JobSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  startJobWithSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  startJobWithSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  startJobWithSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/StartJobWithSchedule',
        request,
        metadata || {},
        this.methodInfoStartJobWithSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/StartJobWithSchedule',
    request,
    metadata || {},
    this.methodInfoStartJobWithSchedule);
  }

  methodInfoStartJobWithTimeAndSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.JobTimeSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  startJobWithTimeAndSchedule(
    request: spm_pb.JobTimeSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  startJobWithTimeAndSchedule(
    request: spm_pb.JobTimeSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  startJobWithTimeAndSchedule(
    request: spm_pb.JobTimeSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/StartJobWithTimeAndSchedule',
        request,
        metadata || {},
        this.methodInfoStartJobWithTimeAndSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/StartJobWithTimeAndSchedule',
    request,
    metadata || {},
    this.methodInfoStartJobWithTimeAndSchedule);
  }

  methodInfoTerminateJob = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  terminateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  terminateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  terminateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/TerminateJob',
        request,
        metadata || {},
        this.methodInfoTerminateJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/TerminateJob',
    request,
    metadata || {},
    this.methodInfoTerminateJob);
  }

  methodInfoResetJob = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  resetJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  resetJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  resetJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/ResetJob',
        request,
        metadata || {},
        this.methodInfoResetJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/ResetJob',
    request,
    metadata || {},
    this.methodInfoResetJob);
  }

  methodInfoGetExecutionStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Json,
    (request: spm_pb.ExecutionId) => {
      return request.serializeBinary();
    },
    spm_pb.Json.deserializeBinary
  );

  getExecutionStatus(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Json>;

  getExecutionStatus(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Json) => void): grpcWeb.ClientReadableStream<spm_pb.Json>;

  getExecutionStatus(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Json) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/GetExecutionStatus',
        request,
        metadata || {},
        this.methodInfoGetExecutionStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/GetExecutionStatus',
    request,
    metadata || {},
    this.methodInfoGetExecutionStatus);
  }

  methodInfoDeleteExecution = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  deleteExecution(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  deleteExecution(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  deleteExecution(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/DeleteExecution',
        request,
        metadata || {},
        this.methodInfoDeleteExecution,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/DeleteExecution',
    request,
    metadata || {},
    this.methodInfoDeleteExecution);
  }

  methodInfoAddJobSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.JobSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  addJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  addJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  addJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/AddJobSchedule',
        request,
        metadata || {},
        this.methodInfoAddJobSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/AddJobSchedule',
    request,
    metadata || {},
    this.methodInfoAddJobSchedule);
  }

  methodInfoUpdateJobSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.JobSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  updateJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  updateJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  updateJobSchedule(
    request: spm_pb.JobSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/UpdateJobSchedule',
        request,
        metadata || {},
        this.methodInfoUpdateJobSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/UpdateJobSchedule',
    request,
    metadata || {},
    this.methodInfoUpdateJobSchedule);
  }

  methodInfoRemoveJobSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  removeJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  removeJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  removeJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/RemoveJobSchedule',
        request,
        metadata || {},
        this.methodInfoRemoveJobSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/RemoveJobSchedule',
    request,
    metadata || {},
    this.methodInfoRemoveJobSchedule);
  }

  methodInfoGetJobSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Schedule,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Schedule.deserializeBinary
  );

  getJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Schedule>;

  getJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Schedule) => void): grpcWeb.ClientReadableStream<spm_pb.Schedule>;

  getJobSchedule(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Schedule) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/GetJobSchedule',
        request,
        metadata || {},
        this.methodInfoGetJobSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/GetJobSchedule',
    request,
    metadata || {},
    this.methodInfoGetJobSchedule);
  }

  methodInfoAddExecutionSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.ExecutionAndSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  addExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  addExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  addExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/AddExecutionSchedule',
        request,
        metadata || {},
        this.methodInfoAddExecutionSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/AddExecutionSchedule',
    request,
    metadata || {},
    this.methodInfoAddExecutionSchedule);
  }

  methodInfoUpdateExecutionSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.ExecutionAndSchedule) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  updateExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  updateExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  updateExecutionSchedule(
    request: spm_pb.ExecutionAndSchedule,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/UpdateExecutionSchedule',
        request,
        metadata || {},
        this.methodInfoUpdateExecutionSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/UpdateExecutionSchedule',
    request,
    metadata || {},
    this.methodInfoUpdateExecutionSchedule);
  }

  methodInfoRemoveExecutionSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.ExecutionId) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  removeExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  removeExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  removeExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/RemoveExecutionSchedule',
        request,
        metadata || {},
        this.methodInfoRemoveExecutionSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/RemoveExecutionSchedule',
    request,
    metadata || {},
    this.methodInfoRemoveExecutionSchedule);
  }

  methodInfoGetExecutionSchedule = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Schedule,
    (request: spm_pb.ExecutionId) => {
      return request.serializeBinary();
    },
    spm_pb.Schedule.deserializeBinary
  );

  getExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Schedule>;

  getExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Schedule) => void): grpcWeb.ClientReadableStream<spm_pb.Schedule>;

  getExecutionSchedule(
    request: spm_pb.ExecutionId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Schedule) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/GetExecutionSchedule',
        request,
        metadata || {},
        this.methodInfoGetExecutionSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/GetExecutionSchedule',
    request,
    metadata || {},
    this.methodInfoGetExecutionSchedule);
  }

  methodInfoResumeJob = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  resumeJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  resumeJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  resumeJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/ResumeJob',
        request,
        metadata || {},
        this.methodInfoResumeJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/ResumeJob',
    request,
    metadata || {},
    this.methodInfoResumeJob);
  }

  methodInfoHibernateJob = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Job,
    (request: spm_pb.Job) => {
      return request.serializeBinary();
    },
    spm_pb.Job.deserializeBinary
  );

  hibernateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Job>;

  hibernateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Job) => void): grpcWeb.ClientReadableStream<spm_pb.Job>;

  hibernateJob(
    request: spm_pb.Job,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/JobManager/HibernateJob',
        request,
        metadata || {},
        this.methodInfoHibernateJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/JobManager/HibernateJob',
    request,
    metadata || {},
    this.methodInfoHibernateJob);
  }

}

export class TokenManagerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoLoginGetRefreshToken = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Token,
    (request: spm_pb.LoginCredentials) => {
      return request.serializeBinary();
    },
    spm_pb.Token.deserializeBinary
  );

  loginGetRefreshToken(
    request: spm_pb.LoginCredentials,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Token>;

  loginGetRefreshToken(
    request: spm_pb.LoginCredentials,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Token) => void): grpcWeb.ClientReadableStream<spm_pb.Token>;

  loginGetRefreshToken(
    request: spm_pb.LoginCredentials,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Token) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TokenManager/LoginGetRefreshToken',
        request,
        metadata || {},
        this.methodInfoLoginGetRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TokenManager/LoginGetRefreshToken',
    request,
    metadata || {},
    this.methodInfoLoginGetRefreshToken);
  }

  methodInfoCreateRefreshToken = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Token,
    (request: spm_pb.TokenRequest) => {
      return request.serializeBinary();
    },
    spm_pb.Token.deserializeBinary
  );

  createRefreshToken(
    request: spm_pb.TokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Token>;

  createRefreshToken(
    request: spm_pb.TokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Token) => void): grpcWeb.ClientReadableStream<spm_pb.Token>;

  createRefreshToken(
    request: spm_pb.TokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Token) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TokenManager/CreateRefreshToken',
        request,
        metadata || {},
        this.methodInfoCreateRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TokenManager/CreateRefreshToken',
    request,
    metadata || {},
    this.methodInfoCreateRefreshToken);
  }

  methodInfoGetAccessToken = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Token,
    (request: spm_pb.RefreshRequest) => {
      return request.serializeBinary();
    },
    spm_pb.Token.deserializeBinary
  );

  getAccessToken(
    request: spm_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Token>;

  getAccessToken(
    request: spm_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Token) => void): grpcWeb.ClientReadableStream<spm_pb.Token>;

  getAccessToken(
    request: spm_pb.RefreshRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Token) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/TokenManager/GetAccessToken',
        request,
        metadata || {},
        this.methodInfoGetAccessToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/TokenManager/GetAccessToken',
    request,
    metadata || {},
    this.methodInfoGetAccessToken);
  }

}

export class BuildManagerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateSnapshot = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.SnapshotReply,
    (request: spm_pb.Snapshot) => {
      return request.serializeBinary();
    },
    spm_pb.SnapshotReply.deserializeBinary
  );

  createSnapshot(
    request: spm_pb.Snapshot,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.SnapshotReply>;

  createSnapshot(
    request: spm_pb.Snapshot,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.SnapshotReply) => void): grpcWeb.ClientReadableStream<spm_pb.SnapshotReply>;

  createSnapshot(
    request: spm_pb.Snapshot,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.SnapshotReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/CreateSnapshot',
        request,
        metadata || {},
        this.methodInfoCreateSnapshot,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/CreateSnapshot',
    request,
    metadata || {},
    this.methodInfoCreateSnapshot);
  }

  methodInfoCreateHistoryUpdate = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.HistoryReply,
    (request: spm_pb.HistoryUpdate) => {
      return request.serializeBinary();
    },
    spm_pb.HistoryReply.deserializeBinary
  );

  createHistoryUpdate(
    request: spm_pb.HistoryUpdate,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.HistoryReply>;

  createHistoryUpdate(
    request: spm_pb.HistoryUpdate,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.HistoryReply) => void): grpcWeb.ClientReadableStream<spm_pb.HistoryReply>;

  createHistoryUpdate(
    request: spm_pb.HistoryUpdate,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.HistoryReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/CreateHistoryUpdate',
        request,
        metadata || {},
        this.methodInfoCreateHistoryUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/CreateHistoryUpdate',
    request,
    metadata || {},
    this.methodInfoCreateHistoryUpdate);
  }

  methodInfoGetHistory = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.History,
    (request: spm_pb.HistoryRequest) => {
      return request.serializeBinary();
    },
    spm_pb.History.deserializeBinary
  );

  getHistory(
    request: spm_pb.HistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.History>;

  getHistory(
    request: spm_pb.HistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.History) => void): grpcWeb.ClientReadableStream<spm_pb.History>;

  getHistory(
    request: spm_pb.HistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.History) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/GetHistory',
        request,
        metadata || {},
        this.methodInfoGetHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/GetHistory',
    request,
    metadata || {},
    this.methodInfoGetHistory);
  }

  methodInfoPullSnapshot = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.PullResponse,
    (request: spm_pb.PullRequest) => {
      return request.serializeBinary();
    },
    spm_pb.PullResponse.deserializeBinary
  );

  pullSnapshot(
    request: spm_pb.PullRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.PullResponse>;

  pullSnapshot(
    request: spm_pb.PullRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.PullResponse) => void): grpcWeb.ClientReadableStream<spm_pb.PullResponse>;

  pullSnapshot(
    request: spm_pb.PullRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.PullResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/PullSnapshot',
        request,
        metadata || {},
        this.methodInfoPullSnapshot,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/PullSnapshot',
    request,
    metadata || {},
    this.methodInfoPullSnapshot);
  }

  methodInfoLaunchBuild = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.BuildReply,
    (request: spm_pb.BuildRequest) => {
      return request.serializeBinary();
    },
    spm_pb.BuildReply.deserializeBinary
  );

  launchBuild(
    request: spm_pb.BuildRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.BuildReply>;

  launchBuild(
    request: spm_pb.BuildRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.BuildReply) => void): grpcWeb.ClientReadableStream<spm_pb.BuildReply>;

  launchBuild(
    request: spm_pb.BuildRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.BuildReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/LaunchBuild',
        request,
        metadata || {},
        this.methodInfoLaunchBuild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/LaunchBuild',
    request,
    metadata || {},
    this.methodInfoLaunchBuild);
  }

  methodInfoGetBuildInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.BuildInfo,
    (request: spm_pb.BuildInfoRequest) => {
      return request.serializeBinary();
    },
    spm_pb.BuildInfo.deserializeBinary
  );

  getBuildInfo(
    request: spm_pb.BuildInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.BuildInfo>;

  getBuildInfo(
    request: spm_pb.BuildInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.BuildInfo) => void): grpcWeb.ClientReadableStream<spm_pb.BuildInfo>;

  getBuildInfo(
    request: spm_pb.BuildInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.BuildInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/GetBuildInfo',
        request,
        metadata || {},
        this.methodInfoGetBuildInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/GetBuildInfo',
    request,
    metadata || {},
    this.methodInfoGetBuildInfo);
  }

  methodInfoUpdateBuild = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.BuildUpdate) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  updateBuild(
    request: spm_pb.BuildUpdate,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  updateBuild(
    request: spm_pb.BuildUpdate,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  updateBuild(
    request: spm_pb.BuildUpdate,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/UpdateBuild',
        request,
        metadata || {},
        this.methodInfoUpdateBuild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/UpdateBuild',
    request,
    metadata || {},
    this.methodInfoUpdateBuild);
  }

  methodInfoUpdateJobImageReference = new grpcWeb.AbstractClientBase.MethodInfo(
    spm_pb.Empty,
    (request: spm_pb.JobImageReferenceUpdate) => {
      return request.serializeBinary();
    },
    spm_pb.Empty.deserializeBinary
  );

  updateJobImageReference(
    request: spm_pb.JobImageReferenceUpdate,
    metadata: grpcWeb.Metadata | null): Promise<spm_pb.Empty>;

  updateJobImageReference(
    request: spm_pb.JobImageReferenceUpdate,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void): grpcWeb.ClientReadableStream<spm_pb.Empty>;

  updateJobImageReference(
    request: spm_pb.JobImageReferenceUpdate,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: spm_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BuildManager/UpdateJobImageReference',
        request,
        metadata || {},
        this.methodInfoUpdateJobImageReference,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BuildManager/UpdateJobImageReference',
    request,
    metadata || {},
    this.methodInfoUpdateJobImageReference);
  }

}

