import { useFirebase, useIdToken } from "api/useFirebase";
import { DropdownAtRoot } from "components/basic/Dropdown";
import HoverTooltip from "components/basic/HoverTooltip";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import { Popup } from "components/basic/Popup";
import { deleteUser } from "grpc/api/grpcUtilAPI";
import { User } from "model/datatypes";
import React, { useState } from "react";
import { useGlobalState } from "store";

const UserRow: React.FC<{ user: User }> = ({ user }) => {
  const fb = useFirebase();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const roleOptions = [
    { id: "developer", display: "Developer" },
    { id: "simulator", display: "Simulator" },
    { id: "guest", display: "Guest" },
    // vil vi tilbyde at man kan ændre nogle til owner? De kan ikke ændres tilbage til sim/dev efter den ændring
  ];

  // Skal der lige en setLoading(true) på her? Eller er det ligegyldigt.
  const updateUserRole = (updatedRole: string) => {
    if (user && updatedRole)
      fb.firestore().collection("users").doc(user.id).update({ userRole: updatedRole });
  };

  const removeUser = () => {
    if (user.id && idToken && !loading) {
      setLoading(true);
      deleteUser(grpcURL, idToken, user.id)
        .then(() => {
          setIsOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Issue deleting user", err);
          setLoading(false);
        });
    }
  };

  const renderUserOptions = () => {
    return (
      <>
        <div className="w-1/5 pr-3">
          {user.userRole !== "owner" ? (
            <DropdownAtRoot
              selectedID={user.userRole || undefined}
              options={roleOptions}
              onSelect={(option) => updateUserRole(option.id)}
            />
          ) : (
            <p>Owner</p>
          )}
        </div>
        <div className="w-1/5 flex justify-end">
          {user.userRole !== "owner" ? (
            <Popup
              useHover
              mt={15}
              align={"right"}
              content={(closeMe) => (
                <div className="text-xs">
                  <button
                    className={`button-popup text-red-400`}
                    onClick={() => {
                      setIsOpen(true);
                      closeMe();
                    }}
                  >
                    Remove user
                  </button>
                </div>
              )}
            >
              <button className="relative focus:outline-none flex justify-center items-center">
                <DotDotDotIcon />
              </button>
            </Popup>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div
      className={`flex items-center p-2 text-xs text-gray-700 ${
        user.deleted ? "opacity-50" : ""
      }`}
    >
      <div className="w-1/5 flex items-center">
        <span>{user.fullName}</span>
        {user.appDeveloper && <DeveloperIcon />}
      </div>
      <div className="w-2/5">{user.mail}</div>
      {!user.deleted && renderUserOptions()}
      {user.deleted && <div className="w-2/5">Deleted</div>}
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <div className={`border border-gray-300 rounded bg-white shadow z-50 p-6 relative`}>
            {loading && <LoadingOverlay />}
            <div className="text-xl text-center mb-2">
              Remove {user.fullName} from the organisation?
            </div>
            <div className="text-center">This action is permanent and cannot be undone</div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={removeUser}
                className="bg-red-400 p-2 border border-red-400 text-white font-bold rounded px-20 mx-1"
              >
                Delete
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="bg-transparent border border-gray-300 p-2 rounded px-20 mx-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserRow;

const DeveloperIcon = () => {
  return (
    <HoverTooltip className="ml-2" text="Developer user" mt={-30}>
      <span className="text-xs rounded-full bg-green-numerous h-4 w-4 flex items-center justify-center font-bold text-white text-center pl-px">
        {"D"}
      </span>
    </HoverTooltip>
  );
};
