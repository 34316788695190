import { Component, ScenarioStateEdited, SimulationScenario } from "model/datatypes";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { updateComponentsNames } from "utils/ComponentTypeHelpers";

export type NewScenarioAction =
  | { type: "SET_GROUP"; payload: string }
  | { type: "SCENARIO_NAME_UPADTE"; payload: string }
  | { type: "UPDATE_SCENARIO_INPUTS"; payload: SimulationScenario["inputScenarios"] }
  | { type: "UPDATE_PARAMETERS"; payload: SimulationScenario["parameters"] }
  | { type: "UPDATE_COMPONENTS"; payload: Component[] }
  | { type: "UPDATE_COMPONENT"; payload: Component }
  | { type: "UPDATE_DESCRIPTION"; payload: string }
  | { type: "RESET_SCENARIO"; payload: ScenarioStateEdited }
  | { type: "SCENARIO_SAVED" }
  | { type: "REQUEST_SAVE_SIMULATION" }
  | {
      type: "SWAP_COMPONENTS";
      payload: { oldComponent: Component; newComponents: Component[] };
    }
  | { type: "HARD_UPDATE_SCENARIO"; payload: Partial<ScenarioStateEdited> }
  | { type: "SET_SYSTEM"; payload: ScenarioStateEdited["system"] }
  | { type: "UPDATE_DATA_TAGS"; payload: SimulationScenario["dataTags"] }
  | { type: "SET_EXTENSION_DATA"; payload: SimulationScenario["extensionData"] }
  | { type: "LOAD_DATA_INFO"; payload: ScenarioStateEdited["inputDataInfo"] };

type ScenarioReducerFunction = (
  state: ScenarioStateEdited,
  action: NewScenarioAction
) => ScenarioStateEdited;

export const newScenarioReducer: ScenarioReducerFunction = (
  state: ScenarioStateEdited,
  action: NewScenarioAction
) => {
  console.log(action);
  switch (action.type) {
    case "UPDATE_PARAMETERS":
      return { ...state, parameters: action.payload, changed: true };
    case "SET_GROUP":
      return { ...state, groupID: action.payload, changed: true };
    case "SCENARIO_NAME_UPADTE":
      return { ...state, scenarioName: action.payload, changed: true };
    case "UPDATE_COMPONENTS":
      return { ...state, simComponents: action.payload, changed: true };
    case "UPDATE_COMPONENT":
      return {
        ...state,
        simComponents: updateArrayValUUID(state.simComponents, action.payload),
        changed: true,
      };
    case "SWAP_COMPONENTS":
      const swappedComponents = removeCompAndSubcomps(
        action.payload.oldComponent,
        state.simComponents,
        action.payload.newComponents
      );
      return {
        ...state,
        simComponents: swappedComponents,
      };
    case "UPDATE_SCENARIO_INPUTS":
      return { ...state, inputScenarios: action.payload, changed: true };

    case "UPDATE_DESCRIPTION":
      return { ...state, description: action.payload, changed: true };
    case "SCENARIO_SAVED":
      return { ...state, changed: false, pendingSync: false };
    case "REQUEST_SAVE_SIMULATION":
      return { ...state, pendingSync: true };
    case "RESET_SCENARIO":
      return {
        ...action.payload,
        system: state.system,
        inputDataInfo: state.inputDataInfo,
      };
    case "SET_SYSTEM":
      return { ...state, system: action.payload };
    case "HARD_UPDATE_SCENARIO":
      return { ...state, ...action.payload };
    case "UPDATE_DATA_TAGS":
      return { ...state, dataTags: action.payload };
    case "SET_EXTENSION_DATA":
      return { ...state, extensionData: action.payload, changed: true };
    case "LOAD_DATA_INFO":
      return { ...state, inputDataInfo: action.payload };

    default:
      return state;
  }
};

const removeCompAndSubcomps = (
  componentToRemove: Component,
  allComponents: Component[],
  newComponents?: Component[]
) => {
  let compForRemove = [componentToRemove];
  let updatedSimComps = [...allComponents];

  //remove the component and its subcomponents:
  while (compForRemove.length > 0) {
    const nextLevelSubs: Component[] = [];
    for (let i = 0; i < compForRemove.length; i++) {
      const comp = compForRemove[i];
      if (comp.subcomponents) {
        //find all subcomponents
        for (let i = 0; i < comp.subcomponents.length; i++) {
          const subC = comp.subcomponents[i];
          const c = updatedSimComps.find((c) => c.uuid === subC.uuid);
          c && nextLevelSubs.push(c);
        }
      }
      //remove the comp:
      updatedSimComps = updatedSimComps.filter((sc) => sc.uuid !== comp.uuid);
    }
    compForRemove = nextLevelSubs;
  }

  if (newComponents) {
    //add the new.
    updatedSimComps = [...updatedSimComps, ...newComponents];
    //update names:
    updatedSimComps = updateComponentsNames(updatedSimComps);
  }

  return updatedSimComps;
};
