import moment from "moment";
import firebase from "firebase";
import { objectMap } from "../jsUtils/objectMap";
import dayjs from "dayjs";

// type FirestoreValue = string | number | boolean | firebase.firestore.Timestamp;

export const convertToFirestoreFormat = (
  data: { [key: string]: any },
  deleteUndefined?: boolean
) => {
  const cleaner: (val: any) => any = (val: any) => {
    if (
      typeof val === "string" ||
      typeof val === "number" ||
      typeof val === "boolean" ||
      val === null
    )
      return val;
    else if (moment.isMoment(val)) return firebase.firestore.Timestamp.fromDate(val.toDate());
    else if (dayjs.isDayjs(val)) return firebase.firestore.Timestamp.fromDate(val.toDate());
    else if (Array.isArray(val))
      return val.map((aV) => {
        return cleaner(aV); //recursive on array elements
      });
    else if (typeof val === "object") return convertToFirestoreFormat(val);
    else if (typeof val === "undefined" && deleteUndefined)
      return firebase.firestore.FieldValue.delete();
    else if (typeof val === "undefined") return val;
    else return "unknown val";
  };

  let cleaned = objectMap(data, cleaner);

  //filter out undefined:
  cleaned = Object.fromEntries(
    Object.entries(cleaned).filter((entry) => typeof entry[1] !== "undefined")
  );
  return cleaned;
};

export const convertFromFirestoreFormat = (data: { [key: string]: any }) => {
  const cleaner = (val: any) => {
    if (typeof val?.toDate === "function") return moment(val.toDate());
    else if (Array.isArray(val)) return val.map((aV) => convertFromFirestoreFormat(aV));
    else if (typeof val === "object") return convertFromFirestoreFormat(val);
    else return val;
  };
  if (data === null) return data;
  else if (typeof data === "object") {
    const cleaned = objectMap(data, cleaner);
    return cleaned;
  } else return data;
};

export const convertFromFirestoreFormatNew = (data: { [key: string]: any }) => {
  const cleaner = (val: any) => {
    if (typeof val?.toDate === "function") return dayjs(val.toDate());
    else if (Array.isArray(val)) return val.map((aV) => convertFromFirestoreFormatNew(aV));
    else if (typeof val === "object") return convertFromFirestoreFormatNew(val);
    else return val;
  };
  if (data === null) return data;
  else if (typeof data === "object") {
    const cleaned = objectMap(data, cleaner);
    return cleaned;
  } else return data;
};

export const cleanFSDataForUpdate = (data: { [key: string]: any }) => {
  const cleaner = (val: any) => {
    if (typeof val === "undefined") return firebase.firestore.FieldValue.delete();
    return val;
  };
  return objectMap(data, cleaner);
};
