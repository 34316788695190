import { TrashIcon } from "@heroicons/react/solid";
import EditableDisplayText from "components/basic/EditableDisplayText";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import Parameter from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import { Component, ScenarioStateEdited, SimulationModel } from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import { InstantiateAllSubComponents, InstantiateComponent } from "utils/ComponentTypeHelpers";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import {
  Compressor,
  Condition,
  ConfigurationConditionIterationResult,
  HeatExchangerConfiguration,
  HeatpumpConfiguration,
  IterationStatus,
} from "./grpc/client/node_pb";
import { runConditions } from "./grpc/heatDesignGrpcClient";
import ReactJson from "react-json-view";
import Dropdown from "components/basic/Dropdown";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const HeatpumpdesignScenario: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  return (
    <div className="flex-grow overflow-y-scroll scrollbar-light py-4">
      <div className="font-bold text-xl px-8 mb-8">Energy machines HP designer</div>

      <HeatpumpsSetup scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
      <CasesSetup scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
      <Simulation scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
    </div>
  );
};

export default HeatpumpdesignScenario;

//heres an example of a component setup for the compressor component:

const HeatpumpsSetup: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const heatpumpComponents = useMemo(() => {
    return scenarioState.simComponents.filter((comp) => comp.name === "heatpump");
  }, [scenarioState.simComponents]);

  const [addingHeatpump, setAddingHeatpump] = useState<null | string>(null);

  const renderAddingHP = () => {
    return (
      <Modal onClose={() => setAddingHeatpump(null)}>
        <div className="modal-content z-30 w-3/4 lg:w-1/2">
          <div className="mb-2 font-medium">Add new Heatpump</div>
          <div className="font-medium text-xs">Name</div>
          <input
            className="input-box w-full"
            value={addingHeatpump!}
            onChange={(e) => {
              setAddingHeatpump(e.target.value);
            }}
          />
          <div className="flex mt-4">
            <button
              className="flex-1 button-small mr-1"
              onClick={() => {
                const system = scenarioState.system;
                const newComponents = getNewHPComponent(addingHeatpump!, system);
                if (!newComponents) return;
                scenarioDispatch({
                  type: "UPDATE_COMPONENTS",
                  payload: [...scenarioState.simComponents, ...newComponents],
                });
                setAddingHeatpump(null);
              }}
            >
              Add
            </button>
            <button
              className="flex-1 button-small ml-1"
              onClick={() => {
                setAddingHeatpump(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="mb-8 px-8">
      <div className="flex items-center mb-4">
        <div className="rounded-full w-7 h-7 bg-green-numerous text-white flex items-center justify-center text-sm font-bold mr-2">
          1
        </div>
        <div className="font-medium">Configure product</div>
      </div>
      {heatpumpComponents.map((heatpump) => {
        return (
          <Heatpump
            key={heatpump.uuid}
            heatpump={heatpump}
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        );
      })}
      <button
        className="button-small"
        onClick={() => {
          setAddingHeatpump("");
        }}
      >
        + Heatpump
      </button>
      {addingHeatpump !== null && renderAddingHP()}
    </div>
  );
};

const Heatpump: React.FC<Props & { heatpump: Component }> = ({
  heatpump,
  scenarioDispatch,
  scenarioState,
}) => {
  const subComponents = useMemo(() => {
    const subs: Component[] = [];
    heatpump.subcomponents?.forEach((s) => {
      const subC = scenarioState.simComponents.find((c) => c.uuid === s.uuid);
      if (subC) subs.push(subC);
    });
    return subs;
  }, [heatpump, scenarioState.simComponents]);

  return (
    <div className="border rounded border-gray-200 bg-gray-100 shadow-md p-4 mb-4">
      <div className="flex items-center justify-between">
        <div className="relative" style={{ minWidth: "50%" }}>
          <EditableDisplayText
            className="font-medium"
            text={heatpump.displayName}
            onChange={(updated) => {
              const updatedHP = {
                ...heatpump,
                displayName: updated,
              };
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updatedHP,
              });
            }}
          />
        </div>
        <button
          className="focus:outline-none"
          onClick={() => {
            scenarioDispatch({
              type: "UPDATE_COMPONENTS",
              payload: scenarioState.simComponents.filter(
                (comp) => comp.uuid !== heatpump.uuid
              ),
            });
          }}
        >
          <TrashIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="flex flex-wrap text-xs">
        {heatpump.parameters.map((param, i) => {
          return (
            <Parameter
              key={param.uuid}
              parameter={param}
              onUpdate={(updatedParam) => {
                const updatedHP = {
                  ...heatpump,
                  parameters: updateArrayValUUID(heatpump.parameters, updatedParam),
                };
                scenarioDispatch({
                  type: "UPDATE_COMPONENT",
                  payload: updatedHP,
                });
              }}
            />
          );
        })}
      </div>
      <div className="flex flex-wrap">
        {subComponents.map((comp) => {
          return (
            <div className={`w-full py-2`}>
              <div className="p-4 bg-white rounded">
                <div className="font-medium text-sm">{comp.displayName}</div>
                <div className="flex flex-wrap text-xs">
                  {comp.parameters.map((param) => (
                    <Parameter
                      key={param.uuid}
                      parameter={param}
                      onUpdate={(updatedParam) => {
                        const updatedComp = {
                          ...comp,
                          parameters: updateArrayValUUID(comp.parameters, updatedParam),
                        };
                        scenarioDispatch({
                          type: "UPDATE_COMPONENT",
                          payload: updatedComp,
                        });
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CasesSetup: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const casesComponents = useMemo(() => {
    return scenarioState.simComponents.filter((comp) => comp.name === "case");
  }, [scenarioState.simComponents]);

  const [addingCase, setAddingCase] = useState<null | string>(null);

  const renderAddingCase = () => {
    return (
      <Modal onClose={() => setAddingCase(null)}>
        <div className="modal-content z-30 w-3/4 lg:w-1/2">
          <div className="mb-2 font-medium">Add new case</div>
          <div className="font-medium text-xs">Name</div>
          <input
            className="input-box w-full"
            value={addingCase!}
            onChange={(e) => {
              setAddingCase(e.target.value);
            }}
          />
          <div className="flex mt-4">
            <button
              className="flex-1 button-small mr-1"
              onClick={() => {
                const caseType = scenarioState.system?.componentTypes.find(
                  (ct) => ct.name === "case"
                );
                if (!caseType) return;
                const newCaseComponent = InstantiateComponent(caseType, true);
                newCaseComponent.displayName = addingCase!;
                scenarioDispatch({
                  type: "UPDATE_COMPONENTS",
                  payload: [...scenarioState.simComponents, newCaseComponent],
                });
                setAddingCase(null);
              }}
            >
              Add
            </button>
            <button
              className="flex-1 button-small ml-1"
              onClick={() => {
                setAddingCase(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderCase = (comp: Component) => {
    return (
      <div
        key={comp.uuid}
        className="border rounded border-gray-200 bg-gray-100 shadow-md p-4 mb-4"
      >
        <div className="flex items-center justify-between">
          <div className="relative" style={{ minWidth: "50%" }}>
            <EditableDisplayText
              className="font-medium"
              text={comp.displayName}
              onChange={(updated) => {
                const updatedComp = {
                  ...comp,
                  displayName: updated,
                };
                scenarioDispatch({
                  type: "UPDATE_COMPONENT",
                  payload: updatedComp,
                });
              }}
            />
          </div>
          <button
            className="focus:outline-none"
            onClick={() => {
              scenarioDispatch({
                type: "UPDATE_COMPONENTS",
                payload: scenarioState.simComponents.filter((c) => c.uuid !== comp.uuid),
              });
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="flex flex-wrap text-xs">
          {comp.parameters.map((param, i) => {
            return (
              <Parameter
                key={param.uuid}
                parameter={param}
                onUpdate={(updatedParam) => {
                  const updatedComp = {
                    ...comp,
                    parameters: updateArrayValUUID(comp.parameters, updatedParam),
                  };
                  scenarioDispatch({
                    type: "UPDATE_COMPONENT",
                    payload: updatedComp,
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-8 px-8">
      <div className="flex items-center mb-4">
        <div className="rounded-full w-7 h-7 bg-green-numerous text-white flex items-center justify-center text-sm font-bold mr-2">
          2
        </div>
        <div className="font-medium">Configure cases</div>
      </div>
      {casesComponents.map(renderCase)}
      <button
        className="button-small"
        onClick={() => {
          setAddingCase("");
        }}
      >
        + case
      </button>
      {addingCase !== null && renderAddingCase()}
    </div>
  );
};

type IterationResult = {
  status: IterationStatus.AsObject;
  result: string;
};

type ProductConditionResult = {
  condition: Condition.AsObject;
  heatpump: HeatpumpConfiguration.AsObject;
  results: IterationResult[];
};

const Simulation: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const [simulationRunning, setSimulationRunning] = useState(false);
  const [allResults, setAllResults] = useState<{ [id: string]: ProductConditionResult }>(
    scenarioState.extensionData?.results || []
  );

  const heatpumps = useMemo(() => {
    //sort results as a object with of heatpumps:
    let heatpumps: {
      id: string;
      name: string;
      results: { [id: string]: ProductConditionResult };
    }[] = [];
    Object.entries(allResults).forEach(([id, res]) => {
      const prevRes = heatpumps.find((hp) => hp.id === res.heatpump.id)?.results || {};
      heatpumps = updateArrayVal(heatpumps, {
        id: res.heatpump.id,
        name: res.heatpump.name,
        results: { ...prevRes, [id]: res },
      });
    });
    return heatpumps;
  }, [allResults]);

  const saveResults = (res: { [id: string]: ProductConditionResult }) => {
    scenarioDispatch({ type: "SET_EXTENSION_DATA", payload: { results: res } });
  };

  const startSimulation = () => {
    if (!simulationRunning) {
      setSimulationRunning(true);

      setAllResults({});
      let _results: { [id: string]: ProductConditionResult } = {};

      const state = getBitzerState(scenarioState);

      console.log({ state });
      const stream = runConditions(state.heatpumps, state.conditions);

      stream.on("data", (res: any) => {
        console.log("stream on data");
        const responseRaw: ConfigurationConditionIterationResult = res;
        const response = responseRaw.toObject();
        const condition = response.condition;
        const heatpump = response.heatpump;
        console.log({ response });

        if (!response.status || !condition || !heatpump) {
          console.log("Missing state in res");
          return;
        }

        const resultStr = response.result.replace("Infinity", '"Infinity"');
        const iterationResult = { status: response.status!, result: resultStr };

        const id = heatpump.id + condition.id;
        const prevRes = _results[id];

        const updated = {
          heatpump,
          condition,
          results: prevRes ? [...prevRes.results, iterationResult] : [iterationResult],
        };
        _results = { ..._results, [id]: updated };

        setAllResults(_results);
      });

      stream.on("error", (error) => {
        console.log("Stream Error");
        console.log(error);
        Toast(error.message, { icon: "error" });
        setSimulationRunning(false);
      });

      stream.on("status", (status) => {
        console.log("stream status:");
        console.log(status);
        if (status.code === 0) {
          console.log("stream end with status 0");
          saveResults(_results);
          setSimulationRunning(false);
        }
      });

      stream.on("metadata", (metadata) => {
        console.log("stream metadata:");
        console.log(metadata);
      });

      stream.on("end", () => {
        console.log("Stream end");
        saveResults(_results);
        setSimulationRunning(false);
      });
    }
  };

  return (
    <div className="mt-4">
      <div className="border-t border-b border-gray-200 px-4 py-4">
        <button
          onClick={() => {
            startSimulation();
          }}
          className={`bg-green-numerous text-white h-12 w-full rounded relative flex items-center justify-center`}
        >
          Simulate {simulationRunning && <LoadingIcon className="ml-2" />}
        </button>
      </div>
      <div className="px-8 min-h-64">
        <div className="flex items-center my-4">
          <div className="rounded-full w-7 h-7 bg-green-numerous text-white flex items-center justify-center text-sm font-bold mr-2">
            3
          </div>
          <div className="font-medium">Results</div>
        </div>
        {heatpumps.map((hp) => {
          return (
            <div key={hp.id}>
              <div className="font-bold">{hp.name}</div>
              {Object.entries(hp.results).map(([id, res]) => {
                return <ConditionResult key={id} {...res} />;
              })}
            </div>
          );
        })}
        {Object.entries(heatpumps).length === 0 && (
          <div className="text-xs italic">Results will appear here once available</div>
        )}
      </div>
    </div>
  );
};

const ConditionResult: React.FC<ProductConditionResult> = ({
  condition,
  heatpump,
  results,
}) => {
  const [viewingResult, setViewingResult] = useState<IterationResult | null>(null);

  useEffect(() => {
    if (results.length > 0) setViewingResult(results[results.length - 1]);
    else setViewingResult(null);
  }, [results]);

  const displayResult = useMemo(() => {
    if (!viewingResult) return "Select iteration to view raw results";
    const resStr = viewingResult.result;
    const result = resStr.length > 0 ? JSON.parse(resStr) : {};

    return { result, status: viewingResult?.status };
    // return JSON.stringify({ result, status: viewingResult?.status }, undefined, 2);
  }, [viewingResult]);

  const renderResults = () => {
    return (
      <div className="flex">
        <div
          className="text-xs bg-gray-200 rounded px-2 py-2 w-2/3 overflow-auto scrollbar-light min-h-64"
          style={{ maxHeight: "80vh" }}
        >
          {typeof displayResult === "string" ? (
            displayResult
          ) : (
            <ReactJson
              src={displayResult}
              name={false}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          )}
        </div>
        <div className="w-1/4 px-4">
          <div className="flex text-xs font-medium pb-2 border-b border-gray-200">
            <div className="w-1/2 px-2">Iteration</div>
          </div>

          <div
            className={`flex text-xs border-b border-gray-200 py-2 cursor-pointer px-2
                  `}
          >
            <Dropdown
              className="w-full"
              options={results.map((res) => ({
                id: `${res.status.iteration}`,
                display: `${res.status.iteration} ${
                  res.status.success ? "Success" : "Failed"
                }`,
                val: res,
              }))}
              selectedID={viewingResult ? `${viewingResult.status.iteration}` : undefined}
              onSelect={(option) => {
                setViewingResult(option.val);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-4">
      <div>{condition.name}</div>
      {renderResults()}
    </div>
  );
};

type BitzerState = {
  heatpumps: HeatpumpConfiguration.AsObject[];
  conditions: Condition.AsObject[];
};

type HPCompParams = {
  refrigerant: string;
  circuitType: string;
  numberOfCompressors: number;
};

const getBitzerState = (scenarioState: ScenarioStateEdited) => {
  //take the numerous state and return a gRPC ready state

  const bitzerState: BitzerState = {
    heatpumps: [],
    conditions: [],
  };

  const heatpumps = scenarioState.simComponents.filter((comp) => comp.name === "heatpump");
  heatpumps.forEach((hpComp) => {
    const hpObj = Object.fromEntries(
      hpComp.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as HPCompParams;

    const subcoolerUUID = hpComp.subcomponents?.find((sub) => sub.name === "subcooler")?.uuid!;
    const subcooler = scenarioState.simComponents.find((comp) => comp.uuid === subcoolerUUID)!;
    const subcoolerObj = Object.fromEntries(
      subcooler.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as HeatExchangerConfiguration.AsObject;

    const condenserUUID = hpComp.subcomponents?.find((sub) => sub.name === "condenser")?.uuid!;
    const condenser = scenarioState.simComponents.find((comp) => comp.uuid === condenserUUID)!;
    const condenserObj = Object.fromEntries(
      condenser.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as HeatExchangerConfiguration.AsObject;

    const evaporatorUUID = hpComp.subcomponents?.find((sub) => sub.name === "evaporator")
      ?.uuid!;
    const evaporator = scenarioState.simComponents.find(
      (comp) => comp.uuid === evaporatorUUID
    )!;
    const evaporatorObj = Object.fromEntries(
      evaporator.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as HeatExchangerConfiguration.AsObject;

    const compressorUUID = hpComp.subcomponents?.find((sub) => sub.name === "compressor")
      ?.uuid!;
    const compressor = scenarioState.simComponents.find(
      (comp) => comp.uuid === compressorUUID
    )!;
    const compressorObj = Object.fromEntries(
      compressor.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as Compressor.AsObject;

    const hpConfig: HeatpumpConfiguration.AsObject = {
      id: hpComp.uuid,
      name: hpComp.displayName,
      ...hpObj,
      compressor: compressorObj,
      condenser: condenserObj,
      evaporator: evaporatorObj,
      subcooler: subcoolerObj,
    };
    bitzerState.heatpumps.push(hpConfig);
  });

  const cases = scenarioState.simComponents.filter((comp) => comp.name === "case");
  cases.forEach((caseComp) => {
    const params = Object.fromEntries(
      caseComp.parameters.map((p) => {
        return [p.id, p.value];
      })
    ) as Condition.AsObject;
    const condition: Condition.AsObject = {
      ...params,
      name: caseComp.displayName,
      id: caseComp.uuid,
    };
    bitzerState.conditions.push(condition);
  });
  return bitzerState;
};

const getNewHPComponent = (name: string, system?: SimulationModel) => {
  //add get a new heatpump and its subcomponents:

  const hpType = system?.componentTypes.find((ct) => ct.name === "heatpump");
  if (!system || !hpType) return null;

  const hpInstance = InstantiateComponent(hpType, true);
  hpInstance.displayName = name;
  const newComponents = InstantiateAllSubComponents([hpInstance], system.componentTypes);
  console.log({ newComponents });
  return newComponents;
};
