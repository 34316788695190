import React, { useEffect, useMemo, useState } from "react";
import { ComponentParameter, ComponentType, InputVar } from "model/datatypes";
import { CompTypeHeadEditor } from "components/systems/editSystem/componentEditor/ComponentEditor";
import { useEditSystemDispatch } from "components/systems/editSystem/EditSystemStore";
import { ExtensionCompProps, ExtensionCompTypeProps } from "../ExtensionComponents";
import Modal from "components/basic/Modal";
import LoadingOverlay from "components/basic/LoadingOverlay";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import axios from "axios";
import Toast from "components/basic/Toast";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import SearchSelecter from "components/basic/SearchSelecter";
import { InputText } from "components/basic/Input";
import InputNumber from "components/basic/headless/InputNumber";
import EditableDisplayText from "components/basic/EditableDisplayText";

export const CMConnectionTypeEditor: React.FC<ExtensionCompTypeProps> = ({
  componentType,
  removeComponent,
}) => {
  const editSystemDispatch = useEditSystemDispatch();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...componentType, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const [open, setOpen] = useState(false);

  const connection = useMemo(() => {
    const connection =
      (componentType.parameters.find((p) => p.id === "CM_Connection")
        ?.value as CMConnection) || null;
    console.log(connection);
    return connection;
  }, [componentType]);

  const updateConnection = (updated: CMConnection) => {
    let param =
      componentType.parameters.find((p) => p.id === "CM_Connection") || getDefaultCMParam();
    param = { ...param, value: updated };
    console.log(param);
    updateComponent({
      parameters: updateArrayVal(componentType.parameters, param),
    });
  };

  const { tags, loadingTags } = useCMTags(connection);

  const options = useMemo(
    () => tags.map((tag) => ({ id: tag.id, display: tag.path, val: tag })),
    [tags]
  );

  const updateVariable = (updated: InputVar) => {
    updateComponent({
      inputVariables: updateArrayValUUID(componentType.inputVariables, updated),
    });
  };

  const renderEditableTags = () => {
    return (
      <div>
        <div className="font-medium text-xs mb-2">Inputs</div>
        <div className="flex border-b border-gray-200">
          <div className="pr-4 w-2/6 text-xs font-medium">Variable Id</div>
          <div className="pr-4 w-3/6 text-xs font-medium">Control Machines Tag</div>
        </div>
        {componentType.inputVariables.map((inputVar) => {
          return (
            <div key={inputVar.uuid} className="flex text-xs my-2">
              <div className="pr-4 w-2/6">
                <InputText
                  value={inputVar.id || ""}
                  className={`input-box w-full`}
                  onChange={(val) => {
                    updateVariable({ ...inputVar, id: val, display: val });
                  }}
                />
              </div>

              <div className="w-3/6">
                <SearchSelecter
                  className="w-full input-box"
                  headlessStyle
                  placeholder="Search tags"
                  options={options}
                  selectedID={inputVar.CMTag?.id}
                  onSelect={(option) => {
                    updateVariable({ ...inputVar, CMTag: option.val });
                  }}
                />
              </div>
              <div className="1/6 flex justify-end">
                <button
                  className="button-small"
                  onClick={() => {
                    updateComponent({
                      inputVariables: componentType.inputVariables.filter(
                        (variable) => variable.uuid !== inputVar.uuid
                      ),
                    });
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        })}

        <button
          className="button-small mt-2"
          onClick={() => {
            const newCompVariable: InputVar = {
              uuid: getUUID(),
              id: "",
              display: "",
              dataSourceType: "control_machines",
              scaling: 1,
              offset: 0,
              unit: "",
              value: 0,
            };
            updateComponent({
              inputVariables: [...componentType.inputVariables, newCompVariable],
            });
          }}
        >
          + Add tag
        </button>
      </div>
    );
  };

  return (
    <div className="border broder-gray-200 bg-white shadow-md mb-4">
      <CompTypeHeadEditor
        comp={componentType}
        updateComponent={updateComponent}
        removeComponent={removeComponent}
        open={open}
        setOpen={setOpen}
      />

      {open && (
        <div className="px-4 pb-4">
          <CMConnectionInfo
            connection={connection}
            updateConnection={updateConnection}
            tags={tags}
          />
          {renderEditableTags()}
          {loadingTags && <LoadingOverlay />}
        </div>
      )}
    </div>
  );
};

export const CMConnectionInstanceEditor: React.FC<ExtensionCompProps> = ({
  component,
  updateComponent,
  scenarioDispatch,
  scenarioState,
}) => {
  const [addingTag, setAddingTag] = useState<null | string>(null);
  const updateConnection = (updated: CMConnection) => {
    let param =
      component.parameters.find((p) => p.id === "CM_Connection") || getDefaultCMParam();
    param = { ...param, value: updated };
    console.log(param);
    updateComponent({
      ...component,
      parameters: updateArrayVal(component.parameters, param),
    });
  };

  const connection = useMemo(() => {
    const connection =
      (component.parameters.find((p) => p.id === "CM_Connection")?.value as CMConnection) ||
      null;
    console.log(connection);
    return connection;
  }, [component]);
  const { tags, loadingTags } = useCMTags(connection);
  const options = useMemo(
    () => tags.map((tag) => ({ id: tag.id, display: tag.path, val: tag })),
    [tags]
  );

  const updateVariable = (updated: InputVar) => {
    updateComponent({
      ...component,
      inputVariables: updateArrayValUUID(component.inputVariables, updated),
    });
  };

  useEffect(() => {
    if (!scenarioState?.dataTags || !scenarioDispatch) return;
    //make sure all ids of variables are always saved to the scneario datatags
    let inSync = true;
    const dataTags = scenarioState.dataTags;
    const currentTags = component.inputVariables.map((inputVar) => inputVar.id);
    currentTags.forEach((tag) => {
      if (!dataTags.some((t) => t === tag)) {
        inSync = false;
        dataTags.push(tag);
      }
    });

    if (!inSync) {
      scenarioDispatch({ type: "UPDATE_DATA_TAGS", payload: dataTags });
    }
  }, [component.inputVariables, scenarioState?.dataTags, scenarioDispatch]);

  const renderAddNewTag = () => {
    if (addingTag === null) return null;
    return (
      <Modal
        onClose={() => {
          setAddingTag(null);
        }}
      >
        <div className="modal-content z-30 w-1/2">
          <div className="text-lg text-center mb-4">Add new Tag</div>
          <div className="text-xs font-medium">Variable Id</div>
          <input
            className="input-box w-full"
            type="text"
            value={addingTag}
            onChange={(e) => setAddingTag(e.target.value)}
          />
          <div className="flex mt-4">
            <button
              className="button-small flex-1 mr-1"
              onClick={() => {
                if (component.inputVariables.some((v) => v.id === addingTag)) {
                  Toast("Variable name already exists", { icon: "error" });
                  return;
                }
                const newCompVariable: InputVar = {
                  uuid: getUUID(),
                  id: addingTag,
                  display: addingTag,
                  dataSourceType: "control_machines",
                  scaling: 1,
                  offset: 0,
                  unit: "",
                  value: 0,
                };
                updateComponent({
                  ...component,
                  inputVariables: [...component.inputVariables, newCompVariable],
                });
                setAddingTag(null);
              }}
            >
              add
            </button>
            <button
              className="button-small flex-1 ml-1"
              onClick={() => {
                setAddingTag(null);
              }}
            >
              cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const removeTagFromScenario = (tag: string) => {
    if (!scenarioDispatch || !scenarioState) return;

    scenarioDispatch({
      type: "UPDATE_DATA_TAGS",
      payload: scenarioState.dataTags?.filter((t) => t !== tag),
    });
  };

  const renderTags = () => {
    return (
      <div>
        <div className="font-medium text-xs mb-2">Inputs</div>
        <div className="flex border-b border-gray-200">
          <div className="pr-4 w-3/12 text-xs font-medium">Variable Id</div>
          <div className="pr-4 w-2/12 text-xs font-medium">Scaling</div>
          <div className="pr-4 w-2/12 text-xs font-medium">Offset</div>
          <div className="pr-4 w-4/12 text-xs font-medium">CM tag</div>
        </div>
        {component.inputVariables.map((inputVar) => {
          return (
            <div className="flex text-xs my-2">
              <EditableDisplayText
                className="pr-4 w-3/12"
                text={inputVar.id}
                onChange={(updated) => {
                  //check value not taken
                  if (component.inputVariables.some((v) => v.id === updated)) {
                    Toast("Variable name already exists", { icon: "error" });
                    return;
                  }
                  //remove prev id as value
                  removeTagFromScenario(inputVar.id);
                  updateVariable({ ...inputVar, id: updated });
                }}
              />

              <div className="pr-4 w-2/12">
                <InputNumber
                  value={inputVar.scaling}
                  className={`input-box w-full`}
                  onChange={(val) => {
                    updateVariable({ ...inputVar, scaling: val });
                  }}
                />
              </div>
              <div className="pr-4 w-2/12">
                <InputNumber
                  value={inputVar.offset}
                  className={`input-box w-full`}
                  onChange={(val) => {
                    updateVariable({ ...inputVar, offset: val });
                  }}
                />
              </div>
              <div className="w-4/12 relative pr-4">
                <SearchSelecter
                  domTarget="absolute"
                  flipped
                  className="w-full input-box"
                  headlessStyle
                  placeholder="Search tags"
                  options={options}
                  selectedID={inputVar.CMTag?.id}
                  onSelect={(option) => {
                    updateVariable({ ...inputVar, CMTag: option.val });
                  }}
                />
              </div>
              <div className="w-1/12">
                <button
                  className="button-small"
                  onClick={() => {
                    removeTagFromScenario(inputVar.id);
                    updateComponent({
                      ...component,
                      inputVariables: component.inputVariables.filter(
                        (iv) => iv.uuid !== inputVar.uuid
                      ),
                    });
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        })}

        <button
          className="button-small mt-2"
          onClick={() => {
            setAddingTag("");
          }}
        >
          + Add tag
        </button>
        {addingTag !== null && renderAddNewTag()}
      </div>
    );
  };

  return (
    <div className="card relative mb-4">
      <div className="font-medium text-lg mb-2">{component.displayName}</div>
      <CMConnectionInfo
        connection={connection}
        updateConnection={updateConnection}
        tags={tags}
      />
      {renderTags()}
      {loadingTags && <LoadingOverlay />}
    </div>
  );
};

const CMConnectionInfo: React.FC<{
  connection?: CMConnection;
  updateConnection: (updated: CMConnection) => void;
  tags: CMTag[];
}> = ({ connection, updateConnection, tags }) => {
  const [editingConnection, setEditingConnection] = useState(false);
  const [viewingTags, setViewingTags] = useState(false);

  const renderConnectionInfo = () => {
    if (!connection) return null;
    return (
      <>
        <div className={`text-xs font-medium`}>Name</div>
        <div className="mb-2">{connection.name}</div>
        <div className={`text-xs font-medium`}>Control machines Project Id</div>
        <input
          type="text"
          className={`input-box w-full mb-4 text-gray-500`}
          value={connection.com_projectID}
          readOnly
        />
        <div className={`text-xs font-medium`}>Token (secret)</div>
        <input
          className={`input-box w-full mb-4 text-gray-500`}
          type={"password"}
          value={connection.token}
          readOnly
        />
      </>
    );
  };

  const renderTagModal = () => {
    return (
      <Modal onClose={() => setViewingTags(false)}>
        <div className="modal-content z-30 w-full max-w-4xl">
          <div className="font-medium">All tags</div>
          <TagViewer tags={tags} />
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <button className="button-small mb-2" onClick={() => setEditingConnection(true)}>
        {connection ? "Change" : "Add"} connection
      </button>
      {editingConnection && (
        <NewCOMConnection
          onFinish={(connection) => {
            setEditingConnection(false);
            if (connection) updateConnection(connection);
          }}
        />
      )}
      {renderConnectionInfo()}
      {tags.length > 0 && (
        <div
          className={`text-xs font-medium cursor-pointer text-blue-500 hover:underline mb-4`}
          onClick={() => {
            setViewingTags(true);
          }}
        >
          View tags ({tags.length})
        </div>
      )}
      {viewingTags && renderTagModal()}
    </div>
  );
};

const getDefaultCMParam = () => {
  const defaultCMParam: ComponentParameter = {
    id: "CM_Connection",
    displayName: "Control machines connection",
    type: "json",
    uuid: getUUID(),
    value: null,
  };
  return defaultCMParam;
};

export const DefaultCMConnectionType: ComponentType = {
  id: "CM_COMP",
  displayName: "Control machines",
  name: "CM_COMP",
  type: "CM_COMP",
  item_class: "CM_COMP",
  order: 0,
  parameters: [getDefaultCMParam()],
  inputVariables: [],
  variableInputVarAmount: true,
  instantiationRules: {
    isMain: true,
    allowDisabling: false,
    defaultEnabled: true,
  },
  isFixed: true,
};

//Controll machines harvester
export type CMConnection = {
  id: string;
  projectID: string;
  url: "https://controlmachines.cloud/api/v1/";
  harvesterType: "control_machines";
  requestType: "POST";
  name: string;
  com_projectID: string;
  token: string;
  params?: {
    groupingsType: "MINUTES" | "HOURS";
    tagList: string[];
    timeFrom: number;
    timeTo: number;
  };
};

export type CMTag = { id: string; path: string; isWritable: boolean; unit: string };

const useCMTags = (connection: CMConnection | null) => {
  const [tags, setTags] = useState<CMTag[]>([]);
  const [loadingTags, setLoadingTags] = useState(false);

  useEffect(() => {
    if (!connection) return;
    const getTags = async () => {
      const resTags = await axios.get<CMTag[]>(
        `${connection.url}${connection.com_projectID}/tag/list`,
        {
          headers: { Authorization: `Bearer ${connection.token}` },
        }
      );
      return resTags.data;
    };
    setLoadingTags(true);
    getTags()
      .then((tags) => {
        setTags(tags);
        setLoadingTags(false);
      })
      .catch((error) => {
        Toast("Error loading tags from controlmachines", { icon: "error" });
        setLoadingTags(false);
      });
  }, [connection]);
  return { tags, loadingTags };
};

export const TagViewer: React.FC<{ tags: CMTag[] }> = ({ tags }) => {
  const mappedTags = useMemo(() => {
    const mapped = new Map<string, CMTag[]>();
    tags.forEach((tag) => {
      const splitTag = tag.path.split("/");
      const tagRoute = splitTag.length > 1 ? splitTag[0] : "Global";
      const prev = mapped.get(tagRoute);
      if (prev) mapped.set(tagRoute, [...prev, tag]);
      else mapped.set(tagRoute, [tag]);
    });

    return mapped;
  }, [tags]);
  const routes = useMemo(() => [...mappedTags.keys()], [mappedTags]);

  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);

  const renderTag = (tag: CMTag) => {
    return (
      <div
        key={tag.id}
        className="bg-gray-200 mr-2 mb-2 px-2 rounded-full border border-gray-200 shadow"
      >
        {tag.path}
      </div>
    );
  };

  const renderRoute = (route: string) => {
    const isSelected = selectedRoutes.some((sr) => sr === route);
    const tags = isSelected && mappedTags.get(route);
    return (
      <div key={route}>
        <div className="mr-2 mb-2 px-2 py-1  shadow">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              if (isSelected) setSelectedRoutes(selectedRoutes.filter((r) => r !== route));
              else setSelectedRoutes([...selectedRoutes, route]);
            }}
          >
            <OpenCloseArrow isOpen={isSelected} />
            <span className="ml-2">{route}</span>
          </div>
          {tags && <div className="flex flex-wrap px-2 py-1">{tags.map(renderTag)}</div>}
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-y-auto px-2 py-2 bg-gray-100" style={{ maxHeight: "16rem" }}>
      {routes.map(renderRoute)}
    </div>
  );
};

export const NewCOMConnection: React.FC<{
  onFinish: (newConnection?: CMConnection, tagList?: CMTag[]) => void;
}> = ({ onFinish }) => {
  const [newHarvester, setNewHarvester] = useState<CMConnection>(getInitialConnection());
  const [tags, setTags] = useState<CMTag[]>();
  const [cmAPILoaded, setCmAPILoaded] = useState(false);
  const [loadingCMApi, setLoadingCMApi] = useState(false);

  const LoadProject = async () => {
    if (!loadingCMApi) {
      try {
        setLoadingCMApi(true);
        const res = await axios.get<{ id: string; name: string }>(
          `${newHarvester.url}${newHarvester.com_projectID}/project`,
          {
            headers: { Authorization: `Bearer ${newHarvester.token}` },
          }
        );
        const resTags = await axios.get<CMTag[]>(
          `${newHarvester.url}${newHarvester.com_projectID}/tag/list`,
          {
            headers: { Authorization: `Bearer ${newHarvester.token}` },
          }
        );
        const projectInfo = res.data;
        const tags = resTags.data;
        setNewHarvester({ ...newHarvester, name: projectInfo.name });
        setTags(tags);

        setCmAPILoaded(true);
        setLoadingCMApi(false);
      } catch (error) {
        Toast("Error connecting to controlmachines", { icon: "warning" });
        console.log(error);
        setLoadingCMApi(false);
      }
    }
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className="w-2/3 bg-white z-30 rounded shadow-xl py-4 px-8 flex flex-col relative">
        <div className="mb-2 font-medium text-lg">Add controlmachines connection</div>
        <div className={`font-medium text-xs`}>Control machines Project Id</div>
        <input
          type="text"
          className={`input-box w-full mb-4`}
          value={newHarvester.com_projectID}
          onChange={(e) => setNewHarvester({ ...newHarvester, com_projectID: e.target.value })}
        />
        <div className={`font-medium text-xs`}>Token (secret)</div>
        <input
          className={`input-box w-full mb-4`}
          type={"password"}
          value={newHarvester.token}
          onChange={(e) => setNewHarvester({ ...newHarvester, token: e.target.value })}
        />
        {!cmAPILoaded && (
          <button className={`button-small mb-4`} onClick={() => LoadProject()}>
            <span>Load control machines harvester</span>
          </button>
        )}
        {cmAPILoaded && (
          <>
            <div className={`font-medium text-xs`}>Name</div>
            <div className="mb-4">{newHarvester.name}</div>
            <div className={`font-medium text-xs`}>Tags ({tags?.length || 0})</div>
            {tags && <TagViewer tags={tags} />}
          </>
        )}
        {cmAPILoaded && (
          <div className="flex mt-2">
            <button
              className={`button-small relative flex-1 mr-2`}
              onClick={() => {
                onFinish(newHarvester, tags);
              }}
            >
              <span>Save</span>
            </button>
            <button className={`button-small flex-1 ml-2`}>Cancel</button>
          </div>
        )}
        {loadingCMApi && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default NewCOMConnection;

const getInitialConnection = () => {
  const initialCM: CMConnection = {
    id: getUUID(),
    projectID: "",
    token: "",
    com_projectID: "",
    name: "",
    harvesterType: "control_machines",
    requestType: "POST",
    url: "https://controlmachines.cloud/api/v1/",
  };

  return initialCM;
};
