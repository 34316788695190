import React, { useCallback, useMemo } from "react";
import {
  SimulationModel,
  Component,
  ComponentType,
  ScenarioStateEdited,
  Container,
} from "model/datatypes";
import * as Sentry from "@sentry/browser";
import { NewScenarioAction } from "../NewScenarioReducer";
import SimulationComponent from "./simComponent/SimulationComponent";
import gtw from "gtw";
import { InstantiateComponent, InstantiateAllSubComponents } from "utils/ComponentTypeHelpers";
import ToggleButton from "components/basic/ToggleButton";
import useExtensionComponents from "components/extensions/ExtensionComponents";

const ComponentSetup: React.FC<{
  scenarioState: ScenarioStateEdited;
  system: SimulationModel;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}> = ({ scenarioState, scenarioDispatch, system }) => {
  const { simComponents } = scenarioState;

  const sortedSimComponents = useMemo(
    () =>
      simComponents
        .filter((comp) => comp.isMainComponent)
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          else if (!a.order) return -1;
          else if (!b.order) return 1;
          else return a.order - b.order;
        }),
    [simComponents]
  );

  return (
    <>
      {sortedSimComponents.map((c) => {
        const container = scenarioState.system?.containers.find(
          (ct) => ct.id === c.containerID
        );
        if (container)
          return (
            <CompWithContainer
              key={c.uuid}
              model={system}
              container={container}
              component={c}
              scenarioDispatch={scenarioDispatch}
              scenarioState={scenarioState}
            />
          );
        else
          return (
            <SimComponentRenderer
              key={c.uuid}
              model={system}
              component={c}
              scenarioDispatch={scenarioDispatch}
              scenarioState={scenarioState}
            />
          );
      })}
    </>
  );
};

export default ComponentSetup;

const CompWithContainer: React.FC<{
  model: SimulationModel;
  container: Container;
  component: Component;
  scenarioDispatch: (value: NewScenarioAction) => void;
  scenarioState: ScenarioStateEdited;
}> = ({ model, component, scenarioDispatch, scenarioState, container }) => {
  const selectableComponentTypes = useMemo(() => {
    if (!scenarioState.system?.componentTypes) return [];
    return scenarioState.system.componentTypes.filter((ct) =>
      container.componentTypeIDs.some((ctid) => ct.id === ctid)
    );
  }, [container, scenarioState.system]);

  const onSelectNewComp = async (compType: ComponentType) => {
    try {
      if (scenarioState.system?.componentTypes) {
        //instantiate comp
        let newComponent = InstantiateComponent(compType, true, container.id, true);

        //components and subcomponents:
        let comps = InstantiateAllSubComponents(
          [newComponent],
          scenarioState.system.componentTypes
        );

        //delete previous and swap for new:
        scenarioDispatch({
          type: "SWAP_COMPONENTS",
          payload: { oldComponent: component, newComponents: comps },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div
      className={`${
        component.disabled ? "opacity-50" : "shadow-md"
      } bg-white border rounded-lg border-gray-300 mb-4`}
    >
      <div className="border-b border-gray-300 px-4 py-2 flex items-center justify-between">
        <span className="text-xl font-medium">{container.displayName}</span>
        {container?.instantiationRules?.allowDisabling && (
          <div>
            <ToggleButton
              active={!component.disabled}
              onChange={() => {
                scenarioDispatch({
                  type: "UPDATE_COMPONENT",
                  payload: { ...component, disabled: !component.disabled },
                });
              }}
            />
            <div className="text-xs italic">{component.disabled ? "disabled" : "enabled"}</div>
          </div>
        )}
      </div>
      {!component.disabled && (
        <div className="px-4 pt-4">
          <div className="flex flex-wrap mb-4">
            {selectableComponentTypes.map((ctype) => {
              const isActive = ctype.id === component.id;
              return (
                <button
                  key={ctype.id}
                  className={`button-small mr-2 ${isActive ? gtw.activeBtn : ""}`}
                  onClick={() => onSelectNewComp(ctype)}
                >
                  {ctype.displayName}
                </button>
              );
            })}
          </div>
          <SimComponentRenderer
            model={model}
            component={component}
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        </div>
      )}
    </div>
  );
};

export const SimComponentRenderer: React.FC<{
  model: SimulationModel;
  component: Component;
  scenarioState: ScenarioStateEdited;
  scenarioDispatch: (value: NewScenarioAction) => void;
}> = ({ component, model, scenarioDispatch, scenarioState }) => {
  const extensionComps = useExtensionComponents();
  const extensionComp = extensionComps.find((extension) => extension.type.id === component.id);
  const updateComponent = useCallback(
    (updated: Component) => {
      scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updated });
    },
    [scenarioDispatch]
  );

  if (extensionComp)
    return (
      <extensionComp.instanceEditor
        component={component}
        updateComponent={updateComponent}
        scenarioDispatch={scenarioDispatch}
        scenarioState={scenarioState}
        model={model}
      />
    );
  else
    return (
      <SimulationComponent
        model={model}
        component={component}
        scenarioDispatch={scenarioDispatch}
        scenarioState={scenarioState}
      />
    );
};
