import HeatRecoveryIcon from "components/basic/icons/HeatRecoveryIcon";
import PVPanelsIcon from "components/basic/icons/PVPanelsIcon";
import PopupMenu, { PopupMenuOption } from "components/basic/PopupMenu";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { Component, ScenarioStateEdited } from "model/datatypes";
import React, { useMemo } from "react";
import SmallComponentCard from "../basic/SmallComponentCard";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}
const SecondarySources: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const PVT = useMemo(() => {
    const PVTSystemComp = scenarioState.simComponents.find((comp) => comp.name === "PVT");
    const PVControlerComp = scenarioState.simComponents.find(
      (comp) => comp.name === "PVT_Controller"
    );

    const PVTEnabled =
      !!PVTSystemComp &&
      !PVTSystemComp.disabled &&
      PVControlerComp &&
      !PVControlerComp.disabled;

    //If PV Panels are enabled without the controler:
    const PVEnabled = !!PVTSystemComp && !PVTSystemComp.disabled && PVControlerComp?.disabled;

    return { PVTSystemComp, PVControlerComp, PVTEnabled, PVEnabled };
  }, [scenarioState.simComponents]);

  const AHURecoveryComp = useMemo(() => {
    return scenarioState.simComponents.find((comp) => comp.name === "AHU_recovery");
  }, [scenarioState.simComponents]);

  const sourceOptions = useMemo(() => {
    const options: PopupMenuOption[] = [];
    if (PVT.PVTSystemComp && !PVT.PVEnabled)
      options.push({
        id: PVT.PVTSystemComp.id,
        title: PVT.PVTSystemComp.displayName,
        description: "Add a PVT panel that produces electricity and heat.",
        value: PVT.PVTSystemComp,
      });
    if (AHURecoveryComp)
      options.push({
        id: AHURecoveryComp.id,
        title: AHURecoveryComp.displayName,
        description: "Add heat recovery from air handling unit.",
        value: AHURecoveryComp,
      });
    return options;
  }, [PVT, AHURecoveryComp]);

  const renderPVTComponent = () => {
    const PVTSystemComp = PVT.PVTSystemComp;
    if (!PVTSystemComp) return null;

    return (
      <SmallComponentCard
        onRemove={() => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...PVTSystemComp, disabled: true },
          });

          if (PVT.PVControlerComp) {
            scenarioDispatch({
              type: "UPDATE_COMPONENT",
              payload: { ...PVT.PVControlerComp, disabled: true },
            });
          }
        }}
      >
        <div className="flex items-center">
          <PVPanelsIcon className="h-8 w-8 mr-1" />
          <div className="text-sm font-bold">{PVTSystemComp.displayName}</div>
        </div>
        <div className="pt-4 text-xs">
          <ComponentParameters
            component={PVTSystemComp}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        </div>
      </SmallComponentCard>
    );
  };
  //..
  const renderHeatRecoveryComponent = (HeatComp: Component) => {
    return (
      <SmallComponentCard
        onRemove={() => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...HeatComp, disabled: true },
          });
        }}
      >
        <div className="flex items-center">
          <HeatRecoveryIcon className="h-6 w-6 mr-1" />
          <div className="text-sm font-bold">{HeatComp.displayName}</div>
        </div>
        <div className="pt-4 text-xs">
          <ComponentParameters
            component={HeatComp}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        </div>
      </SmallComponentCard>
    );
  };

  const renderAddSourceOptions = () => {
    return (
      <PopupMenu
        buttonText="Add seccondary source"
        options={sourceOptions}
        selectedOptions={sourceOptions.filter((so) => !so.value.disabled).map((so) => so.id)}
        onSelect={(option) => {
          scenarioDispatch({
            type: "UPDATE_COMPONENT",
            payload: { ...option.value, disabled: false },
          });
          if (option.value.name === "PVT" && PVT.PVControlerComp) {
            scenarioDispatch({
              type: "UPDATE_COMPONENT",
              payload: { ...PVT.PVControlerComp, disabled: false },
            });
          }
        }}
        closeOnClick
      />
    );
  };

  return (
    <>
      {renderAddSourceOptions()}

      {PVT.PVTEnabled && renderPVTComponent()}
      {AHURecoveryComp &&
        !AHURecoveryComp.disabled &&
        renderHeatRecoveryComponent(AHURecoveryComp)}
    </>
  );
};

export default SecondarySources;
