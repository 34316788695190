import HoverTooltip from "components/basic/HoverTooltip";
import InfoIcon from "components/basic/icons/InfoIcon";

import React, { useMemo, useState } from "react";
import { invlerp, lerp } from "utils/jsUtils/lerp";
import BHSPlot from "./BHSPlot";
import { BHSConfig } from "./BHSLibrarySelecter";
import RadioButton from "components/basic/RadioButton";
import { PlotData } from "plotly.js";
import LoadingOverlay from "components/basic/LoadingOverlay";

interface Props {
  searchResults: BHSConfig[] | null;
  lengthResult: null | number;
  onSelect: (selected: null | BHSConfig) => void;
  selected: null | BHSConfig;
  layout: Partial<PlotData>[] | null;
  loadingLayout: boolean;
}

const BHSResults: React.FC<Props> = ({
  searchResults,
  lengthResult,
  onSelect,
  selected,
  layout,
  loadingLayout,
}) => {
  const [sortBy, setSortBy] =
    useState<"temp" | "temp-inverted" | "cost" | "cost-inverted">("temp");

  const shownResults = useMemo(
    () =>
      searchResults?.sort((a, b) => {
        if (sortBy === "cost") return a.Cost - b.Cost;
        else if (sortBy === "cost-inverted") return b.Cost - a.Cost;
        else if (sortBy === "temp-inverted") return a.T_design - b.T_design;
        else return b.T_design - a.T_design;
      }),
    [searchResults, sortBy]
  );

  const costBasis = useMemo(() => {
    if (!searchResults || searchResults.length === 0) return undefined;
    const minCost = searchResults.reduce(
      (prev, cur) => (prev > cur.Cost ? cur.Cost : prev),
      searchResults[0].Cost
    );
    const maxCost = searchResults.reduce(
      (prev, cur) => (prev < cur.Cost ? cur.Cost : prev),
      0
    );
    return { minCost, maxCost };
  }, [searchResults]);

  const renderResultTable = () => {
    if (shownResults)
      return (
        <div className="text-xs table-auto">
          <div
            className={`w-full flex border-b items-stretch ${
              shownResults.length > 10 ? "pr-2" : ""
            }`}
          >
            <Col className="w-2/12">Select</Col>
            <Col
              onClick={() => {
                setSortBy(sortBy === "temp" ? "temp-inverted" : "temp");
              }}
              className={`w-3/12 cursor-pointer ${
                ["temp", "temp-inverted"].includes(sortBy) ? "font-medium" : ""
              }`}
            >
              Design Temperature
            </Col>
            <Col
              onClick={() => {
                setSortBy(sortBy === "cost" ? "cost-inverted" : "cost");
              }}
              className={`w-1/12 cursor-pointer ${
                ["cost", "cost-inverted"].includes(sortBy) ? "font-bold" : ""
              }`}
            >
              Cost
            </Col>
            <Col className="w-2/12">Total boreholes</Col>
            <Col className="w-2/12">Depth</Col>
            <Col hideBorder className="w-2/12">
              Total length
            </Col>
          </div>
          <div className="h-64 overflow-auto scrollbar-light">
            {shownResults.map((BHSconfig) => {
              const isSelected = BHSconfig.Configuration_ID === selected?.Configuration_ID;

              const costColor = getColorGradient(
                BHSconfig.Cost,
                costBasis!.minCost,
                costBasis!.maxCost
              );
              return (
                <div
                  key={BHSconfig.Configuration_ID}
                  className={`flex w-full border-b ${
                    isSelected ? "border border-green-numerous shadow-md" : "hover:bg-gray-100"
                  } cursor-pointer`}
                  onClick={() => {
                    onSelect(isSelected ? null : BHSconfig);
                  }}
                >
                  <Col hideBorder={isSelected} className="w-2/12">
                    <RadioButton active={isSelected} />
                  </Col>
                  <Col hideBorder={isSelected} className="w-3/12">
                    {BHSconfig.T_design}
                  </Col>
                  <Col hideBorder={isSelected} className="w-1/12">
                    <div className="h-4 w-full rounded" style={{ background: costColor }} />
                  </Col>
                  <Col hideBorder={isSelected} className="w-2/12">
                    {BHSconfig.N_tot}
                  </Col>
                  <Col hideBorder={isSelected} className="w-2/12">
                    {BHSconfig.Depth}
                  </Col>
                  <Col hideBorder={true} className="w-2/12">
                    {BHSconfig.Total_length}
                  </Col>
                </div>
              );
            })}
            {shownResults.length === 0 && (
              <div className="py-1 px-2 italic">No results found</div>
            )}
          </div>
        </div>
      );
  };

  return (
    <>
      {lengthResult && (
        <div className="mt-4 ">
          <div className="flex items-center">
            <div>Estimated total required borehole length</div>
            <HoverTooltip
              className="ml-1"
              mt={25}
              mx={10}
              text=' The total length of a borefield is calculated using the
            "Vertical Geothermal Borefield, Sizing Calculation Spreadsheet"
            by Mikael Philippe, Michel Bernier, Dominique Marchio, published
            in ASHRAE journal in July 2010.'
            >
              <InfoIcon className="h-4 w-4" />
            </HoverTooltip>
          </div>
          <div className="font-medium text-xl">{Math.round(lengthResult)}m</div>
        </div>
      )}

      {shownResults && (
        <div className="mt-4">
          <div className="text-xs font-medium mb-2">Optimal borehole configurations</div>
          <div className="border border-gray-200 rounded-sm shadow-lg ">
            <div className="w-full max-h-72 border-b border-gray-200">
              {renderResultTable()}
            </div>
            <div className="w-full flex justify-center">
              {selected && (
                <div
                  className="w-2/3 lg:w-1/2 bg-gray-100 relative"
                  style={{ minHeight: "8rem" }}
                >
                  <BHSPlot layout={layout} />
                  {loadingLayout && <LoadingOverlay />}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BHSResults;

const getColorGradient = (val: number, min: number, max: number) => {
  //const good = [78, 225, 66];
  const good = [47, 210, 166];
  const bad = [255, 95, 69];
  const procentageGood = invlerp(min, max, val);
  const color = [
    lerp(good[0], bad[0], procentageGood),
    lerp(good[1], bad[1], procentageGood),
    lerp(good[2], bad[2], procentageGood),
  ];
  return `rgb(${color[0]},${color[1]},${color[2]})`;
};

export const SingleResultTable: React.FC<{ res: BHSConfig }> = ({ res }) => {
  return (
    <div className="text-xs table-auto">
      <div className={`w-full flex border-b items-stretch`}>
        <Col className={`w-3/12 cursor-pointer`}>Design Temperature</Col>

        <Col className="w-2/12">Total boreholes</Col>
        <Col className="w-2/12">Depth</Col>
        <Col hideBorder className="w-2/12">
          Total length
        </Col>
      </div>
      <div className="">
        <div
          className={`flex w-full border-b border border-green-numerous shadow-md cursor-pointer`}
        >
          <Col hideBorder={true} className="w-3/12">
            {res.T_design}
          </Col>

          <Col hideBorder={true} className="w-2/12">
            {res.N_tot}
          </Col>
          <Col hideBorder={true} className="w-2/12">
            {res.Depth}
          </Col>
          <Col hideBorder={true} className="w-2/12">
            {res.Total_length}
          </Col>
        </div>
      </div>
    </div>
  );
};

const Col: React.FC<
  { className?: string; hideBorder?: boolean } & React.HTMLProps<HTMLDivElement>
> = ({ className, children, hideBorder, ...props }) => (
  <div
    {...props}
    className={`flex-none px-2 py-1 flex items-center ${hideBorder ? "" : "border-r"} ${
      className || ""
    }`}
  >
    {children}
  </div>
);
