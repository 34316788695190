import React, { useState, useMemo } from "react";
import { InputVar, ScenarioDataTag, ScenarioStateEdited } from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import HoverTooltip from "components/basic/HoverTooltip";
import InfoIcon from "components/basic/icons/InfoIcon";
import InputNumber from "components/basic/headless/InputNumber";
import { DynamicGraphIcon, FlatGraphIcon } from "components/basic/icons/GraphIcon";
import Modal from "components/basic/Modal";

export const InputVariable: React.FC<{
  inputVariable: InputVar;
  onUpdate: (updatedFields: Partial<InputVar>) => void;
  scenarioState: ScenarioStateEdited;
}> = ({ inputVariable, onUpdate, scenarioState }) => {
  const selectableTags: ScenarioDataTag[] = useMemo(() => {
    if (inputVariable.dataSourceType === "static") return [];
    else {
      const sourceRef = scenarioState.inputScenarios.find(
        (is) => is.scenarioID === inputVariable.dataSourceID
      );
      const source = scenarioState.inputDataInfo?.find(
        (dataInfo) => dataInfo.scenario.id === inputVariable.dataSourceID
      );
      if (!source || !sourceRef) return [];
      if (source.timeInfo?.scenarioMetadata.tagsList) {
        return source.timeInfo?.scenarioMetadata.tagsList.map((dataTag) => ({
          ...sourceRef,
          tag: dataTag.name,
          // type: "scenario_output",
        }));
      } else {
        return source.scenario.dataTags.map((tag) => ({
          ...sourceRef,
          tag,
          // type: "scenario_output",
        }));
      }
    }
  }, [
    inputVariable.dataSourceType,
    inputVariable.dataSourceID,
    scenarioState.inputScenarios,
    scenarioState.inputDataInfo,
  ]);

  const isValid = useMemo(() => {
    return (
      inputVariable.dataSourceType === "static" ||
      (inputVariable.dataSourceType === "control_machines" && inputVariable.CMTag) ||
      !!inputVariable.tagSource
    );
  }, [inputVariable]);
  const [viewGraph, setViewGraph] = useState(false);

  const dataSourceOptions = useMemo(() => {
    type Option = { id: string; display: string; val?: InputVar["dataSourceType"] };
    const options: Option[] = [{ id: "static", display: "static", val: "static" }];

    scenarioState.inputDataInfo?.forEach((dataInfo) => {
      options.push({
        id: dataInfo.scenario.id,
        display: dataInfo.scenario.scenarioName,
        val: "scenario",
      });
    });

    return options;
  }, [scenarioState.inputDataInfo]);

  const renderStatic = () => {
    return (
      <>
        <div className="w-1/6"></div>
        <div className="w-1/2 px-4">
          <div
            className="flex  items-center border border-gray-300 rounded w-full"
            style={{ height: "1.75rem" }}
          >
            <div className="border-r border-gray-300 px-2 flex-none h-full flex items-center">
              <FlatGraphIcon className="h-6" />
            </div>
            <div className="flex-grow">
              <InputNumber
                className="px-2 py-1 focus:outline-none w-full"
                value={inputVariable.value}
                onChange={(newVal) => onUpdate({ value: newVal })}
              />
            </div>
            <span className="border-l flex-none w-12 border-gray-300 px-2 h-full flex items-center">
              {inputVariable.unit}
            </span>
          </div>
        </div>
      </>
    );
  };
  const renderDynamic = () => {
    return (
      <>
        <div className="w-1/12 px-2">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.scaling}
            onChange={(newVal) => onUpdate({ scaling: newVal })}
          />
        </div>
        <div className="w-1/12 px-2">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.offset}
            onChange={(newVal) => onUpdate({ offset: newVal })}
          />
        </div>
        <div className="w-1/2 px-4">
          <div
            className={`flex items-center border border-gray-300 rounded w-full bg-white  ${
              isValid ? "" : "text-red-400"
            }`}
            style={{ height: "1.75rem" }}
          >
            {renderScenarioDataSelecter()}
            <span className="w-12 flex-none border-l border-gray-300 px-2 h-full flex items-center">
              <span className="max-w-full truncate">{inputVariable.unit}</span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderScenarioDataSelecter = () => {
    return (
      <>
        <div
          className={`border-r border-gray-300 px-2 h-full flex items-center flex-none ${
            isValid ? "hover:text-gray-800 cursor-pointer hover:bg-gray-100" : ""
          }`}
          onClick={() => {
            if (isValid) setViewGraph(true);
          }}
        >
          <DynamicGraphIcon className={`h-6`} />
        </div>
        <DropdownAtRoot
          className="flex-grow"
          drawerClasses="text-gray-700 pl-3 pr-2 py-1"
          headlessStyle
          onSelect={(selectedOption) => {
            onUpdate({
              tagSource: selectedOption.val as ScenarioDataTag,
            });
          }}
          placeholder={selectableTags.length > 0 ? "select tag" : "missing source"}
          options={selectableTags.map((dataTag) => {
            const id = dataTag.tag;
            const display = dataTag.tag;
            return {
              id,
              display,
              val: dataTag,
            };
          })}
          selectedID={inputVariable.tagSource?.tag}
        />
      </>
    );
  };

  return (
    <>
      <div className={`flex items-center w-full py-2 border-b border-gray-200`}>
        <div className={`text-xs font-medium flex items-center w-1/6 px-4`}>
          <span className="">{inputVariable.display}</span>
          {inputVariable.tooltip && (
            <HoverTooltip className="ml-2" mx={24} text={inputVariable.tooltip}>
              <InfoIcon className="w-4 h-4" />
            </HoverTooltip>
          )}
        </div>
        <div className="w-1/6 px-4">
          <DropdownAtRoot
            selectedID={
              inputVariable.dataSourceType === "static" ? "static" : inputVariable.dataSourceID
            }
            className="bg-white "
            options={dataSourceOptions}
            onSelect={(option) => {
              const prevID =
                inputVariable.dataSourceType === "static"
                  ? "static"
                  : inputVariable.dataSourceID;
              if (option.id !== prevID) {
                //@ts-ignore
                onUpdate({
                  dataSourceType: option.val,
                  dataSourceID: option.id !== "static" ? option.id : undefined,
                  tagSource: undefined,
                  CMTag: undefined,
                });
              }
            }}
          />
        </div>
        {inputVariable.dataSourceType === "static" && renderStatic()}
        {inputVariable.dataSourceType !== "static" && renderDynamic()}
      </div>
      {viewGraph && (
        <ViewVariableGraph inputVariable={inputVariable} onClose={() => setViewGraph(false)} />
      )}
    </>
  );
};

export const ViewVariableGraph: React.FC<{
  inputVariable: InputVar;
  onClose: () => void;
}> = ({ inputVariable, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className="z-50 w-2/3 rounded shadow-xl">
        {inputVariable.dataSourceID && (
          <>
            {/* <VariableDetails
              inputDataID={inputVariable.dataSourceID}
              variable={getSimpleVariableRef(inputVariable.source)}
              scaling={inputVariable.scaling}
              offset={inputVariable.offset}
            /> */}
          </>
        )}
      </div>
    </Modal>
  );
};
