import React, { useState, useEffect, useCallback } from "react";

interface Props {
  value: number | null;
  onChange: (v: number | null) => void;
  className?: string;
  onEnter?: () => void;
  type: "default" | "min" | "max";
}

const InputInstance: React.FC<Props> = ({ value, onChange, className, onEnter, type }) => {
  const [displayVal, setdisplayVal] = useState<string>(value?.toString() || "");
  const onChangeDisplayVal = useCallback(
    (val: number | null) => {
      if (!val && type === "max") {
        setdisplayVal("∞");
      } else if (!val) {
        setdisplayVal("0");
      } else {
        setdisplayVal(val.toString());
      }
    },
    [type]
  );

  useEffect(() => {
    onChangeDisplayVal(value);
  }, [onChangeDisplayVal, value]);

  return (
    <div className={`flex items-center ${className || ""}`}>
      <input
        className="w-8 bg-transparent text-right focus:outline-none px-1 border-b"
        type="text"
        value={displayVal}
        onChange={(e) => {
          const string = e.target.value.replace(/[^\d|.|-]/, "");
          let val: number | null = parseFloat(string);
          val = parseFloat(val.toFixed(0));
          if (type === "max" && (val === 0 || isNaN(val))) val = null;
          else if (isNaN(val)) val = 0;
          onChange(val);
        }}
        onKeyDown={(e) => e.key === "Enter" && onEnter && onEnter()}
      />
    </div>
  );
};
export default InputInstance;
