import React, { useEffect, useMemo, useState } from "react";
import UploadIcon from "components/basic/icons/UploadIcon";
import Modal from "components/basic/Modal";
import Dropzone from "components/basic/upload/Dropzone";
import Papa from "papaparse";
import { parseInput } from "utils/parseInputFormater";
// import FileIcon from "components/basic/icons/FileIcon";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import Toast from "components/basic/Toast";
import { useFirestore } from "api/useFirebase";
import { useGlobalState } from "store";
import { DatasetScenario, SimTimeUnit } from "model/datatypes";
import { uploadTagData } from "grpc/grpcClient";
import { ScenarioMetaData, Tag } from "grpc/client/spm_pb";
import { fsFieldvalue } from "utils/firebase/helpers";
import Dropdown from "components/basic/Dropdown";
import { SimTimeUnitSelecter } from "components/systems/editSystem/componentEditor/parameters/TimeValueInput";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import { DatabaseIcon } from "@heroicons/react/solid";
import HoverTooltip from "components/basic/HoverTooltip";
import { convertRawTimeFromUnit } from "utils/dataTransform/timeConvert";
import TimeRangeDisplayLine from "components/basic/TimeRangeDisplayLine";
import dayjs from "dayjs";
import Datetime from "react-datetime";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import getUUID from "utils/jsUtils/getUUID";

export type CSVData = {
  tag: string;
  variableType: "string" | "number";
  values: (string | number)[];
  included: boolean;
};

const NewCSV: React.FC<{
  onFinish: () => void;
  onAddCSV: (newCSV: DatasetScenario) => void;
}> = ({ onFinish, onAddCSV }) => {
  const { projectID, grpcURL } = useGlobalState();
  const [file, setfile] = useState<File | null>(null);
  const [dataName, setdataName] = useState("");
  const [csvData, setcsvData] = useState<CSVData[] | null>(null);

  const onCsvParsed = (res: Papa.ParseResult<any>) => {
    try {
      //create variable to put data in:
      const headlineRow = res.data[0];
      let data: CSVData[] = headlineRow.map((headline: string) => {
        return { tag: headline, values: [], included: true };
      });
      console.log({ res });
      //populate with row values:
      res.data.forEach((row: string[], rowI) => {
        //skip headline
        if (rowI === 0) {
          return;
        }

        row.forEach((colVal, i) => {
          const parsedVal = parseInput(colVal);
          data[i].values.push(parsedVal);
        });
      });

      let shortest = data[0].values.length;
      let longest = data[0].values.length;
      data.forEach((col) => {
        const length = col.values.length;
        if (length < shortest) shortest = length;
        if (length > longest) longest = length;
      });
      if (shortest < longest) {
        data = data.map((col) => ({ ...col, values: col.values.slice(0, shortest) }));
        Toast(`Different row lengths. Truncated data to ${shortest} rows`);
      }

      data = data.map((d) => ({
        ...d,
        variableType: typeof d.values[0] === "number" ? "number" : "string",
      }));
      setcsvData(data);
    } catch (error) {
      console.log(error);
      setcsvData(null);
      setdataName("");
      Toast("Error parsing data", { icon: "error" });
    }
  };

  //parse the CSV data to JS object
  useEffect(() => {
    if (file) {
      console.log(file);
      Papa.parse(file, { complete: onCsvParsed });
      setdataName(file.name);
    }
  }, [file]);

  const [uploadingData, setUpladingData] =
    useState<{
      progress: number;
      finished: boolean;
    } | null>(null);

  const fs = useFirestore();

  const { user } = useGlobalState();

  const uploadCSVData = async () => {
    if (!csvData || uploadingData || !projectID || !user || !indexValues) return;
    try {
      const projectDoc = fs.collection("Projects").doc(projectID);
      const datasetDoc = projectDoc.collection("Scenarios").doc();

      setUpladingData({ progress: 1, finished: false });
      const dataForUpload = csvData.filter((d) => d.included);

      //stream data upload into Backend
      const data: {
        tag: string;
        values: number[];
      }[] = [];

      csvData.forEach((d) => {
        if (d.variableType === "number" && d.included)
          data.push({ tag: d.tag, values: d.values as number[] });
      });

      data.push({ tag: "_index", values: indexValues });
      console.log(data);

      const executionID = getUUID();
      const idToken = await user.fbUser.getIdToken();
      const tagsList = data.map((d) => {
        const tag = new Tag().setName(d.tag).setType("double");
        return tag;
      });

      const metadata = new ScenarioMetaData()
        .setScenario(datasetDoc.id)
        .setTagsList(tagsList)
        .setEpochType("s")
        .setTimezone("utc")
        .setOffset(offset)
        .setExecution(executionID);

      const scenario = await uploadTagData(grpcURL, idToken, {
        scenarioID: datasetDoc.id,
        projectID,
        data,
        metadata,
        execution: executionID,
      });

      console.log(scenario);
      setUpladingData({ progress: 90, finished: false });

      //then save the reference....:
      const tags = dataForUpload.map((d) => d.tag);

      const dataset: DatasetScenario = {
        id: datasetDoc.id,
        created: dayjs(),
        ownerId: user.id,
        type: "dataset",
        projectID,
        scenarioName: dataName,
        dataTags: tags,
        lastest_main_execution: executionID,
      };
      await datasetDoc.set(convertToFirestoreFormat(dataset));
      await projectDoc.update({ scenarios: fsFieldvalue.arrayUnion(dataset.id) });
      onAddCSV(dataset);
      setUpladingData(null);
    } catch (error) {
      console.log(error);
      setUpladingData(null);
      Toast("Error uploading data", { icon: "error" });
    }
  };

  const [indexCol, setIndexCol] = useState<{ tag: string; timeUnit: SimTimeUnit }>({
    tag: "_index",
    timeUnit: "hours",
  });

  const indexValues = useMemo(() => {
    if (!csvData) return null;
    if (indexCol.tag === "_index") {
      const length = csvData[0].values.length;
      return new Array(length).fill(0).map((v, i) => {
        return convertRawTimeFromUnit(i, indexCol.timeUnit);
      });
    } else {
      return (csvData.find((col) => col.tag === indexCol.tag)?.values as number[]) || null;
    }
  }, [indexCol, csvData]);

  const [offset, setOffset] = useState(0);
  const [offsetDate, setOffsetDate] = useState<Date | string>(
    dayjs("00:00 01/01/21", "HH:mm DD/MM/YY").toDate()
  );
  useEffect(() => {
    if (typeof offsetDate !== "string") setOffset(offsetDate.valueOf() / 1000);
  }, [offsetDate]);

  const absoluteDates = useMemo(() => {
    if (!indexValues) return null;
    const startDate = dayjs.unix(indexValues[0] + offset);
    const endDate = dayjs.unix(indexValues[indexValues.length - 1] + offset);
    return { startDate, endDate };
  }, [indexValues, offset]);

  const indexOptions = useMemo(() => {
    let options = [{ id: "_index", display: "Standard index" }];
    const validIndexCols = csvData?.filter((col) => {
      if (col.variableType === "string" || !col.included) return false;

      let valid = true;
      let latest = 0;
      let i = 0;

      do {
        const v = col.values[i] as number;
        if (i === 0) {
          if (v < 0) valid = false;
        } else if (v <= latest) {
          // console.log({ v, latest, col, name: col.tag, i });
          valid = false;
        }

        latest = v;
        i++;
      } while (valid === true && i < col.values.length);
      return valid;
    });
    if (validIndexCols) {
      options = [
        ...options,
        ...validIndexCols.map((data) => ({ id: data.tag, display: data.tag })),
      ];
    }
    return options;
  }, [csvData]);

  const renderTimeSelecter = () => {
    return (
      <div className="text-xs mt-4 py-4 px-4 bg-gray-100 rounded-lg">
        <div className="text-base font-medium mb-4">Time configuration</div>
        <div className="flex">
          <div className="w-1/2 pr-2">
            <div className="font-medium">Index coloumn</div>
            <Dropdown
              className="text-xs bg-white"
              selectedID={indexCol.tag}
              options={indexOptions}
              onSelect={(option) => {
                setIndexCol({ ...indexCol, tag: option.id });
              }}
            />
          </div>
          {indexCol.tag === "_index" && (
            <div className="w-1/2 pl-2">
              <div className="font-medium">Time interval between rows</div>
              <SimTimeUnitSelecter
                unit={indexCol.timeUnit}
                onUpdate={(updated) => {
                  setIndexCol({ ...indexCol, timeUnit: updated });
                }}
              />
            </div>
          )}
        </div>
        <div className="mt-4">
          <div className="font-medium">Offset date</div>
          <Datetime
            dateFormat={"DD/MM/YYYY"}
            timeFormat={"HH:mm"}
            value={offsetDate}
            onChange={(updated) => {
              if (typeof updated === "object") {
                setOffsetDate(updated.toDate());
              } else {
                setOffsetDate(updated);
              }
            }}
            className={`input-box text-xs w-40 bg-white ${
              typeof offsetDate === "string" ? "border-red-400" : ""
            }`}
          />
        </div>
        <div className="mt-4">
          <div className="font-medium">Absolute time range</div>
          {absoluteDates && (
            <TimeRangeDisplayLine
              startDate={absoluteDates.startDate}
              endDate={absoluteDates.endDate}
              displayDates
              endStrategy="repeat"
            />
          )}
        </div>
      </div>
    );
  };

  const renderFileUploader = () => {
    return (
      <>
        <div className="text-xs">Upload a time series .csv data file</div>

        <Dropzone
          allowedTypes="csv"
          className=""
          onFilesAdded={(files) => {
            setfile(files[0]);
          }}
        >
          {(dropHovered, manualOpen) => {
            return (
              <div
                className={`cursor-pointer flex flex-col items-center justify-center border-4 border-gray-400 border-dashed rounded-lg h-32 ${
                  dropHovered ? "bg-green-200 shadow-lg" : ""
                }`}
                onClick={() => manualOpen()}
              >
                <div className="w-12 h-12 text-gray-700">
                  <UploadIcon />
                </div>
                <div className="font-medium text-sm text-gray-700">
                  Drop files or click here to upload
                </div>
              </div>
            );
          }}
        </Dropzone>
      </>
    );
  };

  return (
    <Modal
      onClose={() => {
        if (!uploadingData || uploadingData.finished) onFinish();
      }}
    >
      <div className="modal-content z-30  w-full lg:w-3/4 relative">
        <div className="font-medium mb-2">Add CSV dataset</div>
        {!csvData && renderFileUploader()}
        {csvData && (
          <>
            <div className="font-medium text-xs">Data name</div>
            <input
              type="text"
              value={dataName}
              onChange={(e) => setdataName(e.target.value)}
              className="input-box text-xs mb-4 w-1/2"
            />
            <CSVPreviewer
              csvData={csvData}
              onUpdate={(updated) => setcsvData(updated)}
              indexCol={indexCol}
            />
            {renderTimeSelecter()}
          </>
        )}
        <div className="flex mt-4">
          <button
            className={`button-small mr-2 flex-1 relative ${
              csvData ? "" : "opacity-50 cursor-default"
            }`}
            onClick={() => {
              if (csvData && !uploadingData) {
                uploadCSVData();
              }
            }}
          >
            Upload
          </button>
          <button className="button-small ml-2 flex-1" onClick={onFinish}>
            Cancel
          </button>
        </div>
        {uploadingData?.finished && (
          <div className="w-full z-10 absolute bottom-0 left-0 px-6 pb-4">
            <button
              className="button-small w-full"
              onClick={() => {
                onFinish();
              }}
            >
              OK
            </button>
          </div>
        )}
        {uploadingData && (
          <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center py-4 px-8">
            <div className="px-4 w-64 lg:w-128 py-2 border border-gray-200 flex items-center justify-center z-20 rounded-full shadow-lg bg-white relative overflow-hidden">
              {uploadingData.finished ? (
                <CheckmarkIcon className="w-5 z-10" />
              ) : (
                <LoadingIcon className="z-10" />
              )}
              <span className="ml-3 font-medium z-10">Uploading</span>
              {
                <div
                  style={{ width: `${uploadingData?.progress}%` }}
                  className="h-full absolute top-0 left-0 bg-green-300 "
                ></div>
              }
            </div>

            <div className="w-full h-full absolute top-0 left-0 bg-white opacity-75"></div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NewCSV;

const CSVPreviewer: React.FC<{
  csvData: CSVData[];
  onUpdate: (newData: CSVData[]) => void;
  indexCol: {
    tag: string;
    timeUnit: SimTimeUnit;
  };
}> = ({ csvData, onUpdate, indexCol }) => {
  const onToggleParam = (param: CSVData, index: number) => {
    const updatedParam = { ...param, included: !param.included };
    onUpdate(immutableSplice(csvData, index, 1, updatedParam));
  };

  const stdIndexValues = useMemo(() => {
    return new Array(10).fill(0).map((v, i) => {
      return convertRawTimeFromUnit(i, indexCol.timeUnit);
    });
  }, [indexCol]);

  const renderIndexCol = () => {
    return (
      <div className={`flex-none border-r border-gray-200 overflow-hidden`}>
        <div className={`border-b py-2 px-2 flex items-center bg-gray-500 text-white`}>
          <HoverTooltip text="Index coloumn" mt={-25}>
            <DatabaseIcon className="w-3 h-3 mr-1" />
          </HoverTooltip>
          <div className="text-xs font-medium ">_index</div>
        </div>
        <div className="text-xs italic px-2  bg-gray-500 text-white">
          {stdIndexValues.map((val) => {
            return <div key={val}>{val}</div>;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="relative max-w-full overflow-hidden">
      <div className="flex overflow-x-auto border border-gray-200 rounded scrollbar-vertical">
        {indexCol.tag === "_index" && renderIndexCol()}
        {csvData.map((param, i) => {
          const active = param.included && param.variableType === "number";
          const isIndex = indexCol.tag === param.tag;
          return (
            <div
              key={param.tag}
              className={`flex-none border-r border-gray-200 overflow-hidden
              ${active ? "" : "opacity-50"}
            `}
            >
              <div
                className={`border-b py-2 px-2 flex items-center ${
                  isIndex ? "bg-gray-500 text-white" : ""
                }`}
              >
                {isIndex && (
                  <HoverTooltip text="Index coloumn" mt={-25}>
                    <DatabaseIcon className="w-3 h-3 mr-1" />
                  </HoverTooltip>
                )}
                <div className="text-xs font-medium ">{param.tag}</div>
              </div>
              <div className="text-xs italic px-2">
                {param.values.slice(0, 10).map((val, i) => (
                  <div key={i}>{val}</div>
                ))}
                <div>...{param.values.length - 10} more rows</div>
              </div>

              <div
                className="flex bg-gray-100 items-center px-2 py-1"
                onClick={() => param.variableType === "number" && onToggleParam(param, i)}
              >
                <input className="cursor-pointer" type="checkbox" checked={active} readOnly />
                <label className="text-xs ml-2 cursor-pointer font-medium">include</label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
