import { useUserRole } from "api/useAuth";
import { useAllUsers, useFirestore } from "api/useFirebase";
import CrossIcon from "components/basic/icons/CrossIcon";
import Modal from "components/basic/Modal";
import SearchDropdownMultiple from "components/basic/SearchDropdownMultiple";
import { Team } from "model/datatypes";
import React, { useState, useContext, useMemo } from "react";
import { store } from "store";

const NewTeam: React.FC<{ onFinish: () => void }> = ({ onFinish }) => {
  const [loading, setloading] = useState(false);
  const [teamName, setTeamName] = useState("Example team name");
  const { hasDeveloperAccess } = useUserRole();
  const { state } = useContext(store);
  const { user } = state;

  const allUsers = useAllUsers();
  const allTags = useMemo(
    () => allUsers.map((rawUser) => ({ id: rawUser.id, displayName: rawUser.fullName })),
    [allUsers]
  );

  const [selectedUserIDs, setSelectedUserIDs] = useState<string[]>([]);

  const fs = useFirestore();
  const addTeam = async () => {
    if (hasDeveloperAccess && user?.fbUser.uid) {
      try {
        setloading(true);
        const newTeamDoc = fs.collection("teams").doc();
        const newTeam: Team = {
          id: newTeamDoc.id,
          name: teamName,
          ownerId: user.fbUser.uid,
          users: selectedUserIDs,
          organisation: user.organisation,
        };
        await newTeamDoc.set(newTeam);

        setloading(false);
        onFinish();
      } catch (error) {
        console.log(error);
        setloading(false);
      }
    }
  };

  return (
    <Modal onClose={onFinish}>
      <div
        data-test="newTeamModal"
        className={`card flex flex-col w-1/2 xl:w-1/3 p-4 z-50 bg-white`}
      >
        <div className="font-medium">New Team</div>
        <label className="font-bold text-xs">Team name</label>
        <input
          data-test="teamName"
          type="text"
          className={`input-box mb-2`}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
        <label className="font-bold text-xs">Members</label>
        {selectedUserIDs.map((userID) => {
          const fullUserInfo = allUsers.find((u) => u.id === userID);
          if (fullUserInfo)
            return (
              <div
                key={fullUserInfo.id}
                className="flex justify-between border-t border-gray-200 p-2 align-middle"
              >
                <span>{fullUserInfo.fullName}</span>
                <button
                  onClick={() => {
                    setSelectedUserIDs(selectedUserIDs.filter((uid) => uid !== userID));
                  }}
                >
                  <CrossIcon />
                </button>
              </div>
            );
          return null;
        })}
        <SearchDropdownMultiple
          tags={allTags}
          selectedTagIDs={selectedUserIDs}
          selectTag={(tagID) => {
            setSelectedUserIDs([...selectedUserIDs, tagID]);
          }}
        />
        <button
          data-test="addTeamButton"
          className={`button-small mb-2 mt-2 ${loading ? "opacity-50" : ""}`}
          onClick={() => {
            !loading && addTeam();
          }}
        >
          Add team
        </button>
      </div>
    </Modal>
  );
};

export default NewTeam;
