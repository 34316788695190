import { DropdownAtRoot } from "components/basic/Dropdown";
import Toast from "components/basic/Toast";
import { MultiToggleButton } from "components/basic/ToggleButtonGroup";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import {
  Component,
  ComponentParameter,
  ComponentType,
  ScenarioStateEdited,
} from "model/datatypes";
import React, { useMemo, useState } from "react";
import { InstantiateAllSubComponents, InstantiateComponent } from "utils/ComponentTypeHelpers";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import HeatpumpIcon from "../icons/HeatpumpIcon";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const EnergySystemSelecter: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const energySystemContainer = useMemo(() => {
    return scenarioState.system?.containers.find((container) => container.name === "EP");
  }, [scenarioState.system?.containers]);

  const currentComp = useMemo(() => {
    return scenarioState.simComponents.find(
      (comp) => comp.containerID === energySystemContainer?.id
    );
  }, [scenarioState.simComponents, energySystemContainer?.id]);

  const type = useMemo(() => {
    if (currentComp?.displayName === "Simple Heat Pump") return "single";
    if (currentComp?.displayName === "EnergyMachines") return "system";
    if (currentComp?.displayName === "Advanced") return "advanced";
  }, [currentComp]);

  const renderCompParameters = () => {
    return (
      <>
        <div className=" text-xs my-4">
          {currentComp && (
            <ComponentParameters
              component={currentComp}
              onUpdate={(updated) => {
                scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updated });
              }}
            />
          )}
        </div>
      </>
    );
  };

  const systemTypeOptions = useMemo(
    () => [
      {
        id: "single",
        display: "Single heat pump",
      },
      {
        id: "system",
        display: "Energy machines system",
      },
      {
        id: "advanced",
        display: "Advanced",
      },
    ],
    []
  );

  const SwapToNewComponent = (newCompDisplayName: string) => {
    const newCompType = scenarioState.system?.componentTypes.find(
      (ct) => ct.displayName === newCompDisplayName
    );
    if (newCompType && currentComp) {
      onSelectNewComp(newCompType, currentComp);
    } else {
      Toast("Error swapping components");
    }
  };

  const onSelectNewComp = async (compType: ComponentType, previous: Component) => {
    try {
      if (scenarioState.system?.componentTypes && energySystemContainer) {
        //instantiate comp
        let newComponent = InstantiateComponent(
          compType,
          true,
          energySystemContainer.id,
          true
        );

        //components and subcomponents:
        let comps = InstantiateAllSubComponents(
          [newComponent],
          scenarioState.system.componentTypes
        );

        //delete previous and swap for new:
        scenarioDispatch({
          type: "SWAP_COMPONENTS",
          payload: { oldComponent: previous, newComponents: comps },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-white ">
      <div className="pb-6">
        <MultiToggleButton
          active={type}
          options={systemTypeOptions}
          onClick={(option) => {
            if (option.id !== type) {
              option.id === "single" && SwapToNewComponent("Simple Heat Pump");
              option.id === "system" && SwapToNewComponent("EnergyMachines");
              option.id === "advanced" && SwapToNewComponent("Advanced");
            }
          }}
        />
        {type === "single" && renderCompParameters()}
        {type === "advanced" && renderCompParameters()}
        {type === "system" && currentComp && (
          <EnergyMachinesSelecter
            component={currentComp}
            scenarioDispatch={scenarioDispatch}
          />
        )}
      </div>
      <div className="pt-6 border-t border-gray-200">
        <PeakloadSelecter scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
      </div>
    </div>
  );
};

export default EnergySystemSelecter;

const PeakloadSelecter: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const [selectedPeak, setSelectedPeak] = useState<string>();
  const peakloadOptions = useMemo(() => {
    return [
      { id: "electric_boiler", display: "Electric boiler" },
      { id: "gas", display: "Gas burner" },
      { id: "district_heating", display: "District Heating" },
    ];
  }, []);

  return (
    <>
      <div className="font-medium mb-2">Peak load</div>
      <DropdownAtRoot
        className="text-xs"
        placeholder="Select peak load device"
        options={peakloadOptions}
        selectedID={selectedPeak}
        onSelect={(option) => {
          setSelectedPeak(option.id);
        }}
      />
    </>
  );
};

type EMSystem = {
  id: string;
  displayName: string;
  display: {
    capacityHeating: string;
    capacityCooling: string;
    efficiencyHeating: string;
    efficiencyCooling: string;
  };
  parameterConfiguration: {
    "EMA-type": string;
    "EMB-type": string;
    "EMHW-type": string;
    "N EMA": number;
    N_EMB: number;
    T_min_brine: number;
  };
  icon: JSX.Element;
};

const energyMachines: EMSystem[] = [
  {
    id: "EM1",
    displayName: "EM1",
    display: {
      capacityHeating: "100kW",
      capacityCooling: "100kW",
      efficiencyHeating: "5",
      efficiencyCooling: "12",
    },
    icon: <HeatpumpIcon className="h-12 w-12" />,
    parameterConfiguration: {
      "EMA-type": "EMA-1",
      "N EMA": 1,
      "EMB-type": "EMB-1",
      N_EMB: 1,
      "EMHW-type": "EMHW-1",
      T_min_brine: -15,
    },
  },
  {
    id: "EM2",
    displayName: "EM2",
    display: {
      capacityHeating: "100kW",
      capacityCooling: "100kW",
      efficiencyHeating: "5",
      efficiencyCooling: "12",
    },
    parameterConfiguration: {
      "EMA-type": "EMA-2",
      "N EMA": 1,
      "EMB-type": "EMB-2",
      N_EMB: 1,
      "EMHW-type": "EMHW-2",
      T_min_brine: -15,
    },
    icon: <HeatpumpIcon className="h-12 w-12" />,
  },
  {
    id: "EM3",
    displayName: "EM3",
    display: {
      capacityHeating: "100kW",
      capacityCooling: "100kW",
      efficiencyHeating: "5",
      efficiencyCooling: "12",
    },
    parameterConfiguration: {
      "EMA-type": "EMA-3",
      "N EMA": 1,
      "EMB-type": "EMB-3",
      N_EMB: 1,
      "EMHW-type": "EMHW-3",
      T_min_brine: -15,
    },
    icon: <HeatpumpIcon className="h-12 w-12" />,
  },
];

const defaultEMTypeParam: ComponentParameter = {
  id: "selected_em_type",
  uuid: getUUID(),
  value: "",
  type: "string",
  displayName: "Selected EM type (generated by webconfigurator)",
};

const EnergyMachinesSelecter: React.FC<{
  component: Component;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}> = ({ scenarioDispatch, component }) => {
  const selectedEMID = useMemo(() => {
    return (
      (component.parameters.find((p) => p.id === "selected_em_type")?.value as string) || null
    );
  }, [component]);

  const selectEMSystem = (EMSystem: EMSystem) => {
    //Todo: dispatch EM selected
    let updatedParameters = [...component.parameters];
    Object.entries(EMSystem.parameterConfiguration).forEach(([id, value]) => {
      let updated = updatedParameters.find((p) => p.id === id);
      if (updated) {
        updated = { ...updated, value: value };
        updatedParameters = updateArrayValUUID(updatedParameters, updated);
      } else {
        //error value not fount....
      }
    });
    let selectedEMIDParam =
      updatedParameters.find((p) => p.id === "selected_em_type") || defaultEMTypeParam;
    updatedParameters = updateArrayValUUID(updatedParameters, {
      ...selectedEMIDParam,
      value: EMSystem.id,
    });

    scenarioDispatch({
      type: "UPDATE_COMPONENT",
      payload: { ...component, parameters: updatedParameters },
    });
  };

  return (
    <div className="flex flex-wrap my-3">
      {energyMachines.map((em) => {
        const isSelected = selectedEMID === em.id;
        return (
          <div key={em.id} className="lg:w-1/2 w-full px-3 py-2">
            <div
              onClick={() => {
                if (!isSelected) selectEMSystem(em);
              }}
              className={`cursor-pointer py-2 border-2 rounded  flex ${
                isSelected ? "border-green-numerous shadow-lg" : "border-gray-200 "
              }`}
            >
              <div className="px-4  border-r-2 border-gray-200 flex items-center">
                {em.icon}
              </div>
              <div className="flex-grow px-3">
                <div className="text-lg">{em.displayName}</div>
                <div className="flex text-xs w-full$">
                  <div className="w-1/2">
                    <div className="font-medium">Max capacity</div>
                    <div className="flex">
                      <div className="w-1/2 pr-1">Heating</div>
                      <div className="w-1/2 pl-1">{em.display.capacityHeating}</div>
                    </div>
                    <div className="flex">
                      <div className="w-1/2 pr-1">Cooling</div>
                      <div className="w-1/2 pl-1">{em.display.capacityCooling}</div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="font-medium">Efficiency</div>
                    <div className="flex">
                      <div className="w-1/2 pr-1">COP heating</div>
                      <div className="w-1/2 pl-1">{em.display.efficiencyHeating}</div>
                    </div>
                    <div className="flex">
                      <div className="w-1/2 pr-1">COP cooling</div>
                      <div className="w-1/2 pl-1">{em.display.efficiencyCooling}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
