import { DropdownAtRoot } from "components/basic/Dropdown";
import { SimTimeUnit, TimeValue } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { convertRawTimeFromUnit, convertRawTimeToUnit } from "utils/dataTransform/timeConvert";

interface Props {
  timeValue: TimeValue;
  updateValue: (updated: number) => void;
  update: (updated: TimeValue) => void;
}

const TimeValueInput: React.FC<Props> = ({ timeValue, updateValue, update }) => {
  const [displayDuration, setDisplayDuration] = useState(
    convertRawTimeToUnit(timeValue.value, timeValue.unit).toString()
  );

  return (
    <div className={`w-full text-xs flex border rounded overflow-hidden`}>
      <input
        className={`text-xs w-1/2 px-2 py-1 focus:outline-none `}
        type="number"
        value={displayDuration}
        min="0"
        pattern="^-?[0-9]\d*\.?\d*$"
        onChange={(e) => {
          setDisplayDuration(e.target.value);
          const newVal = parseFloat(e.target.value);
          if (!isNaN(newVal)) {
            updateValue(convertRawTimeFromUnit(newVal, timeValue.unit));
          } else updateValue(0);
        }}
      />
      <SimTimeUnitSelecter
        className="bg-white text-xs w-1/2 border-l pl-3 pr-2 py-1"
        unit={timeValue.unit}
        onUpdate={(updated) => {
          const newDurationVal = convertRawTimeFromUnit(parseInt(displayDuration), updated);
          update({ value: newDurationVal, unit: updated });
        }}
        headlessStyle
      />
    </div>
  );
};

export default TimeValueInput;

export const SimTimeUnitSelecter: React.FC<{
  unit: SimTimeUnit;
  onUpdate: (updated: SimTimeUnit) => void;
  className?: string;
  headlessStyle?: true;
}> = ({ unit, onUpdate, className, headlessStyle }) => {
  const timeOptions = useMemo(() => {
    return [
      { id: "years", display: "Years" },
      { id: "months", display: "Months" },
      { id: "days", display: "Days" },
      { id: "hours", display: "Hours" },
      { id: "minutes", display: "Minutes" },
      { id: "seconds", display: "Seconds" },
    ] as { id: SimTimeUnit; display: string }[];
  }, []);

  return (
    <DropdownAtRoot
      drawerID="time_val_selecter"
      headlessStyle={headlessStyle}
      className={className || "bg-white text-xs"}
      selectedID={unit}
      options={timeOptions}
      onSelect={(option) => {
        const newUnit = option.id as SimTimeUnit;
        onUpdate(newUnit);
      }}
    />
  );
};
