export const apiReadProjectExample = (language: "node" | "python") => {
  if (language === "node") return readProjectNode;
  else return readProjectPython;
};

const readProjectNode = `import axios from "axios";

const url = baseURL + "/project/:projectID"

axios.get(url, {headers})
.then(response=>{
  //project is returned as response.
})
.catch(error=>{
  //Error occured reading the scenario
})`;

const readProjectPython = `import requests

url = base_url + "/project/:projectID"

response = requests.get(url, headers=headers)

# Raise exception if error occurred during request
response.raise_for_status() 

print(response.text)`;

//
export const apiReadScenarioExample = (language: "node" | "python") => {
  if (language === "node") return readScenarioNode;
  else return readScenarioPython;
};

const readScenarioNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID"

axios.get(url, {headers})
.then(response=>{
  //scenario is returned as response.
})
.catch(error=>{
  //Error occured reading the scenario
})`;

const readScenarioPython = `import requests

url = base_url + "/project/:projectID/scenario/:scenarioID"

response = requests.get(url, headers=headers)

# Raise exception if error occurred during request
response.raise_for_status() 

print(response.text)`;

//////////
export const apiDuplicateScenarioExample = (language: "node" | "python") => {
  if (language === "node") return duplicateScenarioNode;
  else return duplicateScenarioPyhton;
};

const duplicateScenarioNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/duplicate"

const parameters = {
   newScenarioName: "<Name>", //Paste the name of the new scneario
  newScenarioGroupID: "<GroupID>" //paste ID of the group to copy the scenario into
}

axios.post(url, parameters, {headers})
.then(response=>{
  //Duplicated succesfully
})
.catch(error=>{
  //Error occured duplicating the scenario
})`;

const duplicateScenarioPyhton = `import requests, json

url = base_url + "/project/:projectID/scenario/:scenarioID/duplicate"

parameters = {
    "newScenarioName": "<Name>",  # Paste the name of the new scenario
    "newScenarioGroupID": "<GroupID>"  # paste ID of the group to copy the scenario into
}

response = requests.post(url, json.dumps(parameters), headers=headers)

# Raises exception if error occurred during request
response.raise_for_status()

print(response.text)`;

//////////
export const apiUpdateScenarioExample = (language: "node" | "python") => {
  if (language === "node") return updateScenarioNode;
  else return updateScenarioPython;
};

const updateScenarioNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/update"

const scenarioParameters = {
  //Scenario parameters to update
}

axios.post(url, scenarioParameters, {headers})
.then(response=>{
  //Updated succesfully
})
.catch(error=>{
  //Error occured updating the scenario
})`;

const updateScenarioPython = `import requests, json

url = base_url + "/project/:projectID/scenario/:scenarioID/update"

scenario_parameters = {
  # Scenario parameters to update
}

response = requests.post(url, json.dumps(scenario_parameters), headers=headers)

# Raise exception if error occurred during request
response.raise_for_status()

print(response.text)
`;

//////////
export const apiDeleteScenarioExample = (language: "node" | "python") => {
  if (language === "node") return deleteScenarioNode;
  else return deleteScenarioPython;
};

const deleteScenarioNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/delete"


axios.post(url, {}, {headers})
.then(response=>{
  //Updated succesfully
})
.catch(error=>{
  //Error occured updating the scenario
})`;

const deleteScenarioPython = `import requests

url = base_url + "/project/:projectID/scenario/:scenarioID/delete"

response = requests.post(url, {}, headers=headers)

# Raises exception if error occurred during request
response.raise_for_status()

print(response.text)`;

//////////
export const apiLaunchSimulationExample = (language: "node" | "python") => {
  if (language === "node") return launchSimulationNode;
  else return launchSimulationPython;
};

const launchSimulationNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/launch_simulation"

const parameters = {
  beta: false,
  usePipelines: false
}

axios.post(url, parameters, {headers})
.then(response=>{
  //Launched succesfully
})
.catch(error=>{
  //Error occured launching the scenario simulation
})`;

const launchSimulationPython = `import requests, json

url = base_url + "/project/:projectID/scenario/:scenarioID/launch_simulation"

parameters = {
  "beta": False,
  "usePipelines": False
}

response = requests.post(url, json.dumps(parameters), headers=headers)

# Raises exception if error occurred during request
response.raise_for_status()

print(response.text)`;

//////////
export const apiStopSimulationExample = (language: "node" | "python") => {
  if (language === "node") return stopSimulationNode;
  else return stopSimulationPython;
};

const stopSimulationNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/stop_simulation"


axios.post(url, {}, {headers})
.then(response=>{
  //Stopped succesfully
})
.catch(error=>{
  //Error occured stopped the simulation
})`;

const stopSimulationPython = `import requests, json

url = base_url + "/project/:projectID/scenario/:scenarioID/delete"

parameters = {
  "beta": False,
  "usePipelines": False
}

response = requests.post(url, json.dumps(parameters), headers=headers)

# Raises exception if error occurred during request
response.raise_for_status()

print(response.text)`;

//////////
export const apiLaunchProcessorExample = (language: "node" | "python") => {
  if (language === "node") return launchProcessorNode;
  else return launchProcessorPython;
};
const launchProcessorNode = `import axios from "axios";

const url = baseURL + "/project/:projectID/scenario/:scenarioID/launch_processor"

axios.post(url, {}, {headers})
.then(response=>{
  //Launched succesfully
})
.catch(error=>{
  //Error occured launching the scenario processor
})`;

const launchProcessorPython = `import requests, json

url = base_url + "/project/:projectID/scenario/:scenarioID/launch_processor"

response = requests.post(url, json.dumps({}), headers=headers)

# Raises exception if error occurred during request
response.raise_for_status()

print(response.text)`;

//////////
export const apiGetFirestoreExample = (
  language: "node" | "python",
  google_project?: string
) => {
  if (language === "node") return getFirestoreRestNode(google_project);
  else return getFirestoreRestPython(google_project);
};
const getFirestoreRestNode = (google_project?: string) => `import axios from "axios";

const baseURL = "https://firestore.googleapis.com/v1/projects/${
  google_project || "GPROJECT_ID"
}/databases/";
`;

const getFirestoreRestPython = (google_project?: string) => `import requests, json

baseURL = "https://firestore.googleapis.com/v1/projects/${
  google_project || "GPROJECT_ID"
}/databases/";
`;
