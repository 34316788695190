import Modal from "components/basic/Modal";
import { DatasetScenario } from "model/datatypes";
import { useState } from "react";
import { useGlobalState } from "store";
import ScheduleInput from "../newSchedule/ScheduleInput";
import NewCSV from "./NewCSV";

interface Props {
  onFinish: () => void;
  onCSVAdded: (newCSV: DatasetScenario) => void;
}

const NewInput = ({ onFinish, onCSVAdded }: Props) => {
  const { projectID } = useGlobalState();

  const [inputType, setInputType] = useState<null | "schedule" | "newCSV">(null);

  // const [newSchedule, setNewSchedule] = useState<Schedule | null>(null);
  const renderInputTypeSelecter = () => {
    return (
      <>
        <div className="font-medium">Select input type</div>
        <div className="flex flex-col">
          <button
            className="button-small h-10 text-sm mt-4"
            onClick={() => {
              setInputType("newCSV");
            }}
          >
            Upload CSV
          </button>
          <button
            className="button-small h-10 text-sm mt-4"
            onClick={() => {
              setInputType("schedule");
            }}
          >
            Create schedule
          </button>
        </div>
      </>
    );
  };

  const renderInputSelecter = () => {
    return (
      <>
        {inputType === "newCSV" && projectID && (
          <NewCSV
            onAddCSV={(newCSV) => {
              onCSVAdded(newCSV);
            }}
            onFinish={() => {
              setInputType(null);
            }}
          />
        )}
        {inputType === "schedule" && (
          <ScheduleInput
            onFinished={(schedule) => {
              console.log(schedule);
              onFinish();
            }}
          />
        )}
      </>
    );
  };
  return (
    <Modal onClose={onFinish}>
      <div className="modal-content z-30 w-1/2 relative">
        {inputType === null && renderInputTypeSelecter()}
        {inputType !== null && renderInputSelecter()}
      </div>
    </Modal>
  );
};

export default NewInput;
