import React from "react";

const CheckmarkIcon = ({ className }: { className?: string }) => (
  <svg
    className={className ? className : ""}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path
      fill="currentColor"
      d="M9 16.2l-3.5-3.5a.984.984 0 00-1.4 0 .984.984 0 000 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7a.984.984 0 000-1.4.984.984 0 00-1.4 0L9 16.2z"
    ></path>
  </svg>
);

export default CheckmarkIcon;
