import React, { useState, useRef } from "react";
import { useFirebase, useSimulationModels, useProjects, useFileTags } from "api/useFirebase";
import Modal from "components/basic/Modal";
import Dropzone from "components/basic/upload/Dropzone";
import gtw from "gtw";
import { SimFile } from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import { createPortal } from "react-dom";
import { usePagePosition } from "utils/hooks/usePagePosition";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";

import { saveFileInFs } from "api/firestore/firestoreAPI";
import UploadIcon from "components/basic/icons/UploadIcon";
import getUUID from "utils/jsUtils/getUUID";
import { useGlobalState } from "store";

interface Props {
  projectID?: string;
  modelID?: string;
}

type UploadFile = {
  id: string;
  file: File;
  name: string;
  progress: number | null;
  type: string;
};

const FileUploader: React.FC<Props> = ({ projectID, modelID }) => {
  const [newFiles, setNewFiles] = useState<Map<string, UploadFile>>(new Map());
  const [tags, setTags] = useState<string[]>([]);
  const fb = useFirebase();

  const [selectedProjectID, setSelectedProjectID] = useState(projectID);
  const [selectedModelID, setSelectedModelID] = useState(modelID);

  const { user } = useGlobalState();

  const { allModels } = useSimulationModels();

  const { myProjects } = useProjects();

  const saveNewFile = async (nf: UploadFile) => {
    if (!user) return;

    setNewFiles((prev) => {
      const map = new Map(prev);
      map.set(nf.id, { ...nf, progress: 0 });
      return map;
    });

    const allTags = [...tags];
    const type = nf.type.toLowerCase();
    if (selectedProjectID) allTags.push(`project_${selectedProjectID}`);
    if (selectedModelID) allTags.push(`model_${selectedModelID}`);

    //UPLOAD To buckett with metadata:
    let path = `${user.organisation}/simulation_files/${
      selectedProjectID ? `project/${selectedProjectID}` : "global"
    }`;
    if (selectedModelID) path = `${path}/system/${selectedModelID}`;
    path = `${path}/${nf.id}`;
    const uploadRef = fb.storage().ref(path);
    const customMetadata: { [key: string]: any } = { name: nf.name, tags: allTags.toString() };
    if (selectedProjectID) customMetadata.projectID = selectedProjectID;
    if (selectedModelID) customMetadata.modelID = selectedModelID;
    const uploadTask = uploadRef.put(nf.file, { customMetadata });

    uploadTask.on(
      "state_changed",
      (snap) => {
        let prog = (snap.bytesTransferred / snap.totalBytes) * 100;
        setNewFiles((prev) => {
          const map = new Map(prev);
          map.set(nf.id, { ...nf, progress: prog });
          return map;
        });
      },
      (error) => {
        console.log(error);
        setNewFiles((prev) => {
          const map = new Map(prev);
          map.set(nf.id, { ...nf, progress: null });
          return map;
        });
      },

      async () => {
        const fs = fb.firestore();
        const uploadedFile: SimFile = {
          id: nf.id,
          path,
          name: nf.name,
          tags: allTags,
          type,
          organisation: user.organisation,
        };
        if (selectedProjectID) uploadedFile.projectID = selectedProjectID;
        if (selectedModelID) uploadedFile.modelID = selectedModelID;
        await saveFileInFs(fs, uploadedFile, tags);

        setNewFiles((prev) => {
          const map = new Map(prev);
          map.delete(nf.id);
          return map;
        });
      }
    );
  };

  const renderNewFileModal = () => {
    const fileList: UploadFile[] = [];
    newFiles.forEach((nf) => fileList.push(nf));
    const uploading = fileList.some((uf) => uf.progress !== null);
    return (
      <Modal
        onClose={() => {
          if (!uploading) {
            setNewFiles(new Map());
            setTags([]);
          }
        }}
      >
        <div className="flex-none w-1/2 px-4 py-4 bg-white rounded border border-gray-100 shadow-lg z-50">
          <div className="font-bold">Add new file</div>
          <div className="flex flex-col">
            <div className="flex">
              <div className={`text-xs font-medium flex-1 mr-2`}>Name</div>
              <div className={`text-xs font-medium flex-1 ml-2`}>Status</div>
            </div>
          </div>
          <div className="flex flex-col max-h-50vh overflow-auto">
            {fileList.map((file) => {
              const progress = file.progress;
              return (
                <div key={file.id} className="flex items-end my-1">
                  <div className="flex-1 mr-2">
                    <input
                      type="text"
                      className={`input-box text-xs w-full`}
                      value={file.name}
                      onChange={(e) => {
                        const newVal = e.target.value;
                        setNewFiles((prev) => {
                          const map = new Map(prev);
                          map.set(file.id, { ...file, name: newVal });
                          return map;
                        });
                      }}
                    />
                  </div>
                  <div className="flex-1 ml-2">
                    {progress !== null && (
                      <div className="w-full border border-gray-400 rounded h-6 my-1 bg-white relative overflow-hidden">
                        <div
                          className="h-full bg-green-400"
                          style={{ width: `${progress}%` }}
                        ></div>
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center font-medium text-xs italic">
                          Uploading
                        </div>
                      </div>
                    )}
                    {progress === null && fileList.length > 1 && (
                      <div className="text-xs italic">Ready</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="w-full mt-2">
            <div className="font-medium text-xs">Tags</div>
            <TagInput tags={tags} setTags={(newTags) => setTags(newTags)} />
          </div>

          <div className="flex my-2 text-xs">
            <div className="flex-1 mr-2">
              <div className={gtw.label}>Attached project</div>
              <Dropdown
                placeholder="Select project"
                selectedID={selectedProjectID}
                onSelect={(option) => {
                  setSelectedProjectID(option.val);
                }}
                options={[
                  ...myProjects.map((p) => ({
                    id: p.id,
                    display: p.projectName,
                    val: p.id,
                  })),
                  { id: "none", display: "No Project", val: undefined },
                ]}
              />
            </div>
            <div className="flex-1 ml-2">
              <div className={gtw.label}>Attached model</div>
              <Dropdown
                placeholder="Select model"
                selectedID={selectedModelID}
                onSelect={(option) => {
                  setSelectedModelID(option.val);
                }}
                options={[
                  ...allModels.map((simModel) => ({
                    id: simModel.id,
                    display: simModel.displayName,
                    val: simModel.id,
                  })),
                  { id: "none", display: "No model", val: undefined },
                ]}
              />
            </div>
          </div>

          <div className="flex">
            <button
              className={`button-small mr-2 ${uploading ? "opacity-50" : ""} flex-1 mr-2`}
              onClick={() => {
                if (!uploading) fileList.forEach((f) => saveNewFile(f));
              }}
            >
              {fileList.length > 1 ? `Upload all` : `Upload`}
            </button>
            <button
              className={`button-small ${uploading ? "opacity-50" : ""} flex-1 ml-2`}
              onClick={() => {
                if (!uploading) setNewFiles(new Map());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {newFiles.size > 0 && renderNewFileModal()}
      <Dropzone
        allowedTypes="all"
        className=""
        onFilesAdded={(files) => {
          const fileMap = new Map<string, UploadFile>();
          files.forEach((file) => {
            const fileID = getUUID();
            const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
            fileMap.set(fileID, {
              id: fileID,
              file,
              progress: null,
              name: file.name,
              type: extension,
            });
          });
          setNewFiles(fileMap);
        }}
        multiFile
      >
        {(dropHovered, manualOpen) => {
          return (
            <div
              className={`cursor-pointer flex flex-col items-center justify-center border-4 border-gray-400 border-dashed rounded-lg h-32 ${
                dropHovered ? "bg-green-200 shadow-lg" : ""
              }`}
              onClick={() => manualOpen()}
            >
              <div className="w-12 h-12 text-gray-700">
                <UploadIcon />
              </div>
              <div className="font-medium text-gray-700">
                Drop files or click here to upload
              </div>
            </div>
          );
        }}
      </Dropzone>
    </>
  );
};

export default FileUploader;

const TagInput: React.FC<{ tags: string[]; setTags: (newTags: string[]) => void }> = ({
  tags,
  setTags,
}) => {
  const allTags = useFileTags();
  const [nextTag, setNextTag] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const { pagePosition, width } = usePagePosition(ref);
  const addNewTag = (newTag: string) => {
    if (!tags.some((t) => t === newTag)) {
      const nextTags = [...tags, newTag];
      setTags(nextTags);
      setNextTag("");
      setOpen(false);
    }
  };
  const { open, setOpen, style } = useMaxHeightTransition("0", "240px");

  const renderSuggestions = () => {
    const matches = allTags.tags.filter(
      (tag) => tag.includes(nextTag) && !tags.some((t) => t === tag)
    );
    return (
      <RootPortal>
        <div
          className={
            "absolute top-0 left-0 w-full bg-white shadow-xl py-2 px-4 border border-gray-300 rounded text-xs overflow-auto"
          }
          style={{
            ...style,
            marginTop: pagePosition.distanceTop + 30,
            marginLeft: pagePosition.distanceLeft,
            zIndex: 1000,
            width,
          }}
        >
          {matches.length === 0 && (
            <div className="italic">No existing tags match (Press enter to add new)</div>
          )}
          {matches.length > 0 &&
            matches.map((matchTag) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  addNewTag(matchTag);
                }}
              >
                {matchTag} +
              </div>
            ))}
        </div>
      </RootPortal>
    );
  };

  return (
    <div
      className={`px-2 pt-1 border rounded flex items-center flex-wrap relative text-xs ${
        tags.length === 0 ? "pb-1" : ""
      }`}
      ref={ref}
    >
      {tags.map((tag) => {
        return (
          <div
            onClick={() => setTags(tags.filter((t) => t !== tag))}
            className="px-2 rounded-full bg-gray-700 text-white mr-1 mb-1 cursor-pointer"
          >
            {tag} -
          </div>
        );
      })}
      <input
        type="text"
        className={`focus:outline-none text-xs flex-grow`}
        value={nextTag}
        onChange={(e) => {
          const newVal = e.target.value;
          setNextTag(newVal);
          setOpen(newVal.length > 0);
        }}
        onKeyDown={(e) => {
          console.log(e.key);
          if (e.key === "Enter" && nextTag.length > 0) addNewTag(nextTag);
          if (e.key === "Backspace" && nextTag.length === 0)
            setTags(tags.slice(0, tags.length - 1));
        }}
      />
      {open && renderSuggestions()}
    </div>
  );
};

const RootPortal: React.FC = ({ children }) => {
  const appRoot = document.getElementById("root");
  return appRoot && createPortal(children, appRoot);
};
