import React from "react";

const PullIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className ? className : ""}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#B1B1B1"
        d="M5.94 8.097l-.711.595a.648.648 0 00-.17.209.55.55 0 000 .494c.04.079.097.15.17.21l6.225 5.204c.071.06.156.109.25.141a.899.899 0 00.592 0 .789.789 0 00.25-.14l6.226-5.21a.65.65 0 00.169-.208.551.551 0 000-.495.65.65 0 00-.17-.209l-.712-.595a.797.797 0 00-.252-.138.904.904 0 00-.592.006.788.788 0 00-.248.143l-3.685 3.23V3.642a.55.55 0 00-.058-.246.648.648 0 00-.166-.208.792.792 0 00-.25-.14.9.9 0 00-.295-.048h-1.026a.9.9 0 00-.295.048.79.79 0 00-.25.14.647.647 0 00-.166.208.55.55 0 00-.058.246v7.692L7.039 8.108a.782.782 0 00-.248-.144.895.895 0 00-.592-.006.79.79 0 00-.252.139h-.006z"
      ></path>
      <path stroke="#B1B1B1" strokeLinecap="round" strokeWidth="1.5" d="M4 20h16"></path>
    </svg>
  );
};

export default PullIcon;
