import React from "react";
import { SubCompRules } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import InputInstance from "./InputInstance";

interface Props {
  attachedSubCompRules: { displayName: string; subCompRules: SubCompRules }[];
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  onAddSubComponent: (option: SubCompRules) => void;
  onRemoveSubComponent: (option: SubCompRules) => void;
  onEditRules: (newRules: SubCompRules) => void;
}

const SubComponentsRules: React.FC<Props> = ({
  attachedSubCompRules,
  subCompOptions,
  onAddSubComponent,
  onRemoveSubComponent,
  onEditRules,
}) => {
  return (
    <>
      <div className="font-bold text-sm mb-2">Sub Components</div>

      {attachedSubCompRules && (
        <div className="flex flex-col">
          {attachedSubCompRules.map((subcomp) => {
            return (
              <EditableSubCompRules
                key={subcomp.subCompRules.id}
                displayName={subcomp.displayName}
                subcompRules={subcomp.subCompRules}
                onRemove={() => {
                  onRemoveSubComponent(subcomp.subCompRules);
                }}
                onEdit={(newRules) => {
                  onEditRules(newRules);
                }}
              />
            );
          })}
        </div>
      )}
      <Popup
        mt={25}
        content={(closeMe) => {
          const options = subCompOptions?.filter(
            (option) =>
              !attachedSubCompRules?.some(
                (attachedRule) => attachedRule.subCompRules.id === option.subCompRules.id
              )
          );
          return (
            <div className="text-xs">
              {!options || options.length === 0 ? (
                <div className="px-4 py-1 ">no components available</div>
              ) : (
                options.map((option, i) => {
                  const rounding =
                    i === 0 ? "rounded-t" : i === options.length - 1 ? "rounded-b" : "";
                  return (
                    <button
                      key={option.subCompRules.id}
                      className={`px-4 py-1 focus:outline-none w-full hover:bg-blue-400 ${rounding}`}
                      onClick={() => {
                        onAddSubComponent(option.subCompRules);
                        closeMe();
                      }}
                    >
                      {option.displayName}
                    </button>
                  );
                })
              )}
            </div>
          );
        }}
      >
        <button className="w-16 bg-white shadow-md rounded border border-gray-200 focus:outline-none">
          +
        </button>
      </Popup>
    </>
  );
};

export default SubComponentsRules;

type SubCompProps = {
  subcompRules: SubCompRules;
  displayName: string;
  onEdit: (newRules: SubCompRules) => void;
  onRemove: () => void;
};

const EditableSubCompRules = ({
  subcompRules,
  onRemove,
  onEdit,
  displayName,
}: SubCompProps) => {
  return (
    <div className="border border-gray-300 rounded w-full px-2 py-2 text-xs mb-2 flex bg-white shadow-md">
      <div className="flex-grow ">
        <div className="text-sm font-medium truncate">{displayName}</div>
      </div>
      <div className="flex flex-col pr-2">
        <div className="flex items-center justify-end">
          <div className="pr-1">Mininum</div>
          <InputInstance
            type="min"
            value={subcompRules.min}
            onChange={(newVal) => onEdit({ ...subcompRules, min: newVal || 0 })}
          />
          <div className="pl-1">Instance</div>
        </div>
        <div className="flex items-center justify-end">
          <div className="pr-1">Default</div>
          <InputInstance
            type="default"
            value={subcompRules.default}
            onChange={(newVal) => onEdit({ ...subcompRules, default: newVal || 0 })}
          />
          <div className="pl-1">Instance</div>
        </div>
        <div className="flex items-center justify-end">
          <div className="pr-1">Maximum</div>
          <InputInstance
            type="max"
            value={subcompRules.max}
            onChange={(newVal) => onEdit({ ...subcompRules, max: newVal })}
          />
          <div className="pl-1">Instance</div>
        </div>
      </div>
      {!subcompRules.fixed && <RemoveIcon onClick={() => onRemove()} />}
    </div>
  );
};

const RemoveIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      className="flex-none cursor-pointer"
      onClick={() => onClick()}
      xmlns="http://www.w3.org/2000/svg"
      height="14"
      viewBox="0 0 24 24"
      width="14"
      fill={"currentColor"}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
    </svg>
  );
};
