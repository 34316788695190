import React, { useEffect, useMemo, useState } from "react";
import {
  ComponentType,
  ComponentParamType,
  FileQuery,
  ConnectorPin,
  Connector,
} from "model/datatypes";
import {
  immutableSplice,
  updateArrayVal,
  updateArrayValUUID,
} from "utils/jsUtils/imutableArray";
import ParametersEditTable from "./parameters/ParametersEditTable";
import InputEditor from "./variable/InputEditor";

import { deepEqual } from "utils/jsUtils/equality";
import CodeEditor from "../pythonCode/CodeEditor";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import getUUID from "utils/jsUtils/getUUID";
import TrashIcon from "components/basic/icons/TrashIcon";
import NewModal from "components/basic/NewModal";
import { Transition } from "@headlessui/react";
import { DualButton } from "components/basic/ToggleButtonGroup";
import { useGlobalState } from "store";
import SelectMenu from "components/basic/SelectMenu";
import { useEditSystemDispatch, useEditSystemState } from "../EditSystemStore";

import SubComponents from "./advanced/SubComponents";
import { CompTypeHeadEditor } from "components/systems/editSystem/componentEditor/ComponentEditor";
import Dropdown from "components/basic/Dropdown";

type Props = {
  comp: ComponentType;
  removeComponent?: (componentID: string) => void;
  onMove?: (dir: "up" | "down") => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  systemRef: FileQuery["modelRef"];
  maxOrder?: number;
};

const AdvancedComponentEditer: React.FC<Props> = ({
  comp,
  removeComponent,
  onMove,
  maxOrder,
  onDuplicate,
  onSaveToLib,
  systemRef,
}) => {
  const editSystemDispatch = useEditSystemDispatch();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...comp, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const [open, setOpen] = useState(false);

  return (
    <div
      className={`border border-gray-300 rounded bg-white shadow w-full flex flex-col my-4`}
    >
      <CompTypeHeadEditor
        comp={comp}
        updateComponent={updateComponent}
        maxOrder={maxOrder}
        onDuplicate={onDuplicate}
        onSaveToLib={onSaveToLib}
        removeComponent={removeComponent}
        onMove={onMove}
        open={open}
        setOpen={setOpen}
      />
      {open && (
        <>
          <div className="px-4 py-4">
            <ParametersEditTable
              componentParameters={comp.parameters}
              systemRef={systemRef}
              onUpdateParam={(updated) => {
                const updatedParameters = updateArrayValUUID(
                  comp.parameters,
                  updated
                ) as ComponentParamType[];
                updateComponent({
                  parameters: updatedParameters,
                });
              }}
              onUpdateAllParms={(updated) => {
                updateComponent({
                  parameters: updated,
                });
              }}
              onDelete={(param) => {
                updateComponent({
                  parameters: comp.parameters.filter((cp) => cp.uuid !== param.uuid),
                });
              }}
            />
            <InputEditor
              useExperimental
              componentVariables={comp.inputVariables}
              onDelete={(inputVar) => {
                updateComponent({
                  inputVariables: comp.inputVariables.filter(
                    (cv) => cv.uuid !== inputVar.uuid
                  ),
                });
              }}
              onUpdate={(updatedInputVar) => {
                const updatedVariables = updateArrayValUUID(
                  comp.inputVariables,
                  updatedInputVar
                );
                updateComponent({
                  inputVariables: updatedVariables,
                });
              }}
              onUpdateAll={(updatedVars) => {
                updateComponent({
                  inputVariables: updatedVars,
                });
              }}
            />
          </div>
          <ComponentCodeEditor comp={comp} updateComponent={updateComponent} />

          <SubComponents comp={comp} systemRef={systemRef} />

          <Connectors comp={comp} updateComponent={updateComponent} />
        </>
      )}
    </div>
  );
};

const ComponentCodeEditor: React.FC<{
  comp: ComponentType;
  updateComponent: (updates: Partial<ComponentType>) => void;
}> = ({ comp, updateComponent }) => {
  const [showcode, setShowcode] = useState(false);

  return (
    <div className="border-t border-b border-gray-300 px-4 py-4">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          setShowcode(!showcode);
        }}
      >
        <div className="font-medium flex-grow">Simulation code (Python)</div>
        <OpenCloseArrow isOpen={showcode} />
      </div>
      {showcode && (
        <>
          <div className="font-bold text-sm mt-4">Initialisation</div>
          <CodeEditor
            defaultCode={comp.code?.initialisation || ""}
            onUpdate={(updated) => {
              const code = comp.code || { equations: "", initialisation: "" };
              updateComponent({ code: { ...code, initialisation: updated } });
            }}
          />
          <div className="mt-4 font-bold text-sm">Equations</div>
          <CodeEditor
            defaultCode={comp.code?.equations || ""}
            onUpdate={(updated) => {
              const code = comp.code || { equations: "", initialisation: "" };
              updateComponent({ code: { ...code, equations: updated } });
            }}
          />
        </>
      )}
    </div>
  );
};

const Connectors: React.FC<{
  comp: ComponentType;
  updateComponent: (updates: Partial<ComponentType>) => void;
}> = ({ comp, updateComponent }) => {
  const [newConnector, setNewConnector] = useState<string | null>(null);
  // console.log({ newConnector, open: typeof newConnector === "string" });
  return (
    <div className="px-4 py-4">
      <div className="font-bold text-xs mb-2">Connectors</div>
      {comp.connectors?.map((connector) => {
        return (
          <EditableConnector
            comp={comp}
            connector={connector}
            onUpdate={(updated) => {
              updateComponent({
                connectors: updateArrayVal(comp.connectors!, updated),
              });
            }}
            onRemove={() => {
              updateComponent({
                connectors: comp.connectors!.filter((con) => con.id !== connector.id),
              });
            }}
          />
        );
      })}
      <button
        className="button-small"
        onClick={() => {
          const connectors = comp.connectors || [];
          setNewConnector(`Connector${connectors.length + 1}`);
        }}
      >
        + Add connector
      </button>
      <NewModal
        onClose={() => {
          setNewConnector(null);
        }}
        open={typeof newConnector === "string"}
      >
        <div className="mb-2 text-center font-medium">New connection</div>
        <div className="text-xs font-medium">Name</div>

        <input
          type="text"
          value={newConnector || ""}
          onChange={(e) => {
            setNewConnector(e.target.value);
          }}
          className="input-box w-full"
        />

        <div className="flex mt-4">
          <button
            className="button-small flex-1 mr-2"
            onClick={() => {
              if (!newConnector) {
                return;
              }
              const newCon: Connector = { id: getUUID(), name: newConnector, pins: [] };
              const prev = comp.connectors || [];
              updateComponent({
                connectors: [...prev, newCon],
              });
              setNewConnector(null);
            }}
          >
            Add connection
          </button>
          <button
            className="button-small flex-1 ml-2"
            onClick={() => {
              setNewConnector(null);
            }}
          >
            Cancel
          </button>
        </div>
      </NewModal>
    </div>
  );
};

const EditableConnector: React.FC<{
  comp: ComponentType;
  connector: Connector;
  onUpdate: (updated: Connector) => void;
  onRemove: () => void;
}> = ({ connector, onUpdate, onRemove, comp }) => {
  const [showPinEditor, setshowPinEditor] = useState(false);
  const [editingPin, setEditingPin] = useState<ConnectorPin>({
    name: "",
    access: "get",
    internal: "",
  });
  const [editingPinPos, setEditingPinPos] = useState<null | number>(null);

  return (
    <div
      key={connector.name}
      className="rounded border border-dashed border-gray-300 px-3 py-2 mb-4"
    >
      <div className="flex items-center justify-between mb-2">
        <div>{connector.name}</div>
        <button
          className="focus:outline-none"
          onClick={() => {
            onRemove();
          }}
        >
          <TrashIcon className="h-4 w-4" />
        </button>
      </div>
      <div className="flex items-center my-2">
        {connector.pins.map((pin, i) => {
          let internalMapping = "";
          const internal = pin.internal;
          if (typeof internal === "object") {
            internalMapping = `${internal.sub_component || "unknown"}.${internal.pin.name}`;
          } else internalMapping = internal;

          return (
            <div
              className=" border border-gray-200 rounded text-xs mr-2 cursor-pointer"
              onClick={() => {
                setEditingPinPos(i);
                setEditingPin(pin);
                setshowPinEditor(true);
              }}
            >
              <div className="font-medium px-2 border-b py-1 text-center">{pin.name}</div>
              <div className="flex items-center">
                <div className="px-2 border-r">{pin.access}</div>
                <div className="px-2">{internalMapping}</div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="button-small"
        onClick={() => {
          setEditingPin({ name: "", access: "get", internal: "" });
          setEditingPinPos(null);
          setshowPinEditor(true);
        }}
      >
        + pin
      </button>
      <Transition.Root
        show={showPinEditor}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {
          <EditPin
            comp={comp}
            pin={editingPin}
            onFinish={(updated) => {
              if (updated) {
                let updatedPins = connector.pins;
                if (typeof editingPinPos === "number") {
                  updatedPins = immutableSplice(updatedPins, editingPinPos, 1, updated);

                  //avoid duplicate names:
                  updatedPins = updatedPins.filter(
                    (p, i) => i === editingPinPos || p.name !== updated.name
                  );
                } else {
                  updatedPins = updatedPins.filter((p, i) => p.name !== updated.name);
                  updatedPins = [...updatedPins, updated];
                }

                const updatedConnector: Connector = {
                  ...connector,
                  pins: updatedPins,
                };
                onUpdate(updatedConnector);
              }
              setEditingPinPos(null);
              setshowPinEditor(false);
            }}
          />
        }
      </Transition.Root>
    </div>
  );
};

const EditPin: React.FC<{
  comp: ComponentType;
  pin: ConnectorPin;
  onFinish: (updatedPin?: ConnectorPin) => void;
}> = ({ pin, onFinish, comp }) => {
  const { sidebarState } = useGlobalState();
  const { editedComponentTypes } = useEditSystemState();

  const [editedPin, setEditedPin] = useState<{
    name: string;
    access: "set" | "get";
    internal:
      | string
      | {
          sub_component: string;
          connector_id?: string;
          pin?: ConnectorPin;
        };
  }>(pin);

  useEffect(() => {
    pin && setEditedPin(pin);
  }, [pin]);

  const orininOptions = useMemo(() => {
    const subcomps = comp.subComponents || [];
    return [
      { id: "internal", display: `${comp.displayName}`, value: "internal" },
      ...subcomps.map((sub) => {
        return { id: sub.name, display: `${sub.name}`, value: sub.name };
      }),
    ];
  }, [comp.subComponents, comp.displayName]);

  const selectedSource = useMemo(
    () =>
      typeof editedPin.internal === "object" ? editedPin.internal.sub_component : "internal",
    [editedPin.internal]
  );

  const internalOptions = useMemo(() => {
    const options: {
      id: string;
      display: string;
      value: {
        sub_component: string;
        connector_id: string;
        pin: ConnectorPin;
      };
    }[] = [];
    //if internal selected, no pins are available:
    if (selectedSource === "internal") return options;

    //else get the connectors for the selected subcomponent:
    const selectedSubCompType = comp.subComponents?.find((sub) => sub.name === selectedSource);
    const selectedConnectors = editedComponentTypes.find(
      (ct) => ct.id === selectedSubCompType?.componentTypeId
    )?.connectors;
    if (!selectedConnectors) return options;
    selectedConnectors.forEach((connector) => {
      connector.pins.forEach((pin) => {
        options.push({
          id: `${selectedSource}.${connector.id}.${pin.name}`,
          display: `${connector.name}.${pin.name}`,
          value: { sub_component: selectedSource, connector_id: connector.id, pin: pin },
        });
      });
    });
    return options;
  }, [editedComponentTypes, selectedSource, comp.subComponents]);
  const selectedInternal = useMemo(
    () =>
      typeof editedPin.internal === "object"
        ? `${editedPin.internal.sub_component}.${editedPin.internal.connector_id}.${editedPin.internal.pin?.name}`
        : undefined,
    [editedPin.internal]
  );

  const renderConnectorOptions = () => {
    return (
      <div className="text-xs">
        <SelectMenu
          options={internalOptions}
          selectedId={selectedInternal}
          onSelect={(option) => {
            setEditedPin({ ...editedPin, internal: option.value });
          }}
        />
      </div>
    );
  };

  const interalVariableOptions = useMemo(() => {
    const vars: { display: string; id: string }[] = [];
    comp.inputVariables.forEach((inputVar) => {
      vars.push({ id: inputVar.id, display: inputVar.id });
      if (inputVar.type === "internal")
        vars.push({ id: `${inputVar.id}_dot`, display: `${inputVar.id}_dot` });
    });
    return vars;
  }, [comp]);

  const renderInternalVariable = () => {
    if (typeof editedPin.internal === "string")
      return (
        <Dropdown
          className="text-xs"
          options={interalVariableOptions}
          selectedID={editedPin.internal}
          onSelect={(option) => {
            setEditedPin({ ...editedPin, internal: option.id });
          }}
          placeholder="Select variable"
          flipped
        />
      );
  };

  return (
    <div
      className="fixed bottom-0 left-0 mb-2 z-20 w-full pr-4"
      style={{ paddingLeft: sidebarState === "wide" ? "14rem" : "6rem" }}
    >
      <div className="w-full px-4 py-3 z-50 bg-white shadow-2xl border border-gray-200 rounded">
        <div className="font-medium mb-3">Edit pin</div>
        <div className="text-xs font-medium">name</div>
        <input
          type="text"
          className="input-box text-xs w-full"
          value={editedPin.name}
          onChange={(e) => setEditedPin({ ...editedPin, name: e.target.value })}
        />
        <div className="text-xs font-medium mt-3">Access</div>
        <DualButton
          active={editedPin.access === "get" ? "one" : "two"}
          onClickOne={() => {
            setEditedPin({ ...editedPin, access: "get" });
          }}
          onClickTwo={() => {
            setEditedPin({ ...editedPin, access: "set" });
          }}
          optionOne="Get"
          optionTwo="Set"
        />
        <div className="text-xs mt-2">
          <SelectMenu
            labelText="From component"
            options={orininOptions}
            selectedId={selectedSource}
            onSelect={(option) => {
              if (option.id !== selectedSource) {
                if (option.id === "internal") setEditedPin({ ...editedPin, internal: "" });
                else
                  setEditedPin({
                    ...editedPin,
                    internal: { sub_component: option.id },
                  });
              }
            }}
          />
        </div>
        <div className="text-xs font-medium mt-3">
          {selectedSource === "internal" ? "Mapped Variable" : "Mapped Pin"}
        </div>
        {selectedSource === "internal" ? renderInternalVariable() : renderConnectorOptions()}
        <div className="flex mt-4">
          <button
            className="button-small mr-1 flex-1"
            onClick={() => {
              if (
                typeof editedPin.internal === "string" ||
                (editedPin.internal.connector_id !== undefined &&
                  editedPin.internal.pin !== undefined)
              ) {
                onFinish(editedPin as ConnectorPin);
              }
            }}
          >
            OK
          </button>
          <button
            className="button-small ml-1 flex-1"
            onClick={() => {
              onFinish();
            }}
          >
            cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const compareProps = (prev: Props, next: Props) => {
  let isEqual = true;
  // if (!shallowEqual(prev.allowedParamRefs, next.allowedParamRefs)) return false;
  if (!deepEqual(prev.comp, next.comp)) isEqual = false;
  return isEqual;
};

export default React.memo(AdvancedComponentEditer, compareProps);
