import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import { PlotData } from "plotly.js";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getDayStart } from "../scheduleUtils";
import { PeriodSetting, TimeSlot } from "model/datatypes";
dayjs.extend(isBetween);

interface Props {
  period: PeriodSetting;
  unit?: string;
}

const ScheduleDayGraph: React.FC<Props> = ({ period, unit }) => {
  const dayData = useMemo(() => getDayData(period.timeslots, period.defaultValue), [period]);

  const range = useMemo(() => {
    let min = period.timeslots.reduce((prev, cur) => {
      return prev < cur.setting ? prev : cur.setting;
    }, 0);
    let max = period.timeslots.reduce((prev, cur) => {
      return prev > cur.setting ? prev : cur.setting;
    }, 0);
    if (period.defaultValue > max) max = period.defaultValue;
    if (period.defaultValue < min) min = period.defaultValue;

    return {
      min,
      max: Math.floor(max + 1 + max * 0.1),
    };
  }, [period.timeslots, period.defaultValue]);

  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];
    const startTime = getDayStart();

    const xAxis = dayData.map((v, i) => v.time.diff(startTime));

    if (dayData && xAxis)
      plotData.push({
        x: xAxis,
        y: dayData.map((d) => d.setting),
        type: "scatter",
        mode: "lines",
        hoverinfo: "skip",
        line: {
          color: "#2FD2A5",
          width: 4,
        },
      });
    return plotData;
  }, [dayData]);

  let startOfDay = getDayStart();

  return (
    <div className="w-full relative flex items-center overflow-hidden">
      <Plot
        className={`cursor-default`}
        data={mappedData}
        useResizeHandler
        config={{ displayModeBar: false }}
        style={{ width: "100%" }}
        layout={{
          autosize: true,
          paper_bgcolor: "#FFF",
          plot_bgcolor: "#FFF",

          margin: {
            t: 30,
            b: 70,
            l: 60,
            r: 40,
            pad: 10,
          },
          yaxis: {
            fixedrange: true,
            ticksuffix: ` ${unit || ""}`,
            range: [range.min, range.max],
          },
          xaxis: {
            fixedrange: true,
            tickvals: [
              ...Array(25)
                .fill(0)
                .map((v, i) => startOfDay.add(i, "hour").diff(startOfDay)),
            ],
            ticktext: [
              ...Array(24)
                .fill(0)
                .map((v, i) => startOfDay.add(i, "hour").format("HH:mm")),
              "24:00",
            ],
          },
        }}
      />
    </div>
  );
};

export default ScheduleDayGraph;

const getDayData = (timeslots: TimeSlot[], defaultSetting: number) => {
  //Get data for all hours during a single day
  const data: { setting: number; time: Dayjs }[] = [];
  let time = getDayStart();
  const endTime = time.clone().add(1, "day").add(1, "second");

  const findTimeslot = (t: Dayjs) =>
    timeslots.find((ts) => t.isBetween(ts.startTime, ts.endTime, undefined, "[)"));

  while (time.isBefore(endTime)) {
    const slot = findTimeslot(time);
    const value = slot ? slot.setting : defaultSetting;
    data.push({ setting: value, time: time });
    data.push({ setting: value, time: time.add(59, "minutes") });

    time = time.add(1, "hour");
  }
  console.log({ dayData: data, timeslots, time });
  return data;
};
