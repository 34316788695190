import React, { useEffect, useMemo } from "react";
import { InputScenarioRef } from "model/datatypes";

import dayjs, { Dayjs } from "dayjs";
import { useInputFullInputSource } from "api/useFirebase";

import { RefreshIcon } from "@heroicons/react/solid";
import { TimeTagEditable } from "./TimeSelecter";
import HoverTooltip from "components/basic/HoverTooltip";
import InfoIcon from "components/basic/icons/InfoIcon";
import { TimeInfoItem } from "./TimeSetup";

interface Props {
  inputScenario: InputScenarioRef;
  onUpdateOffset: (updated: number) => void;
  onLoadedTime: (info: TimeInfoItem) => void;
  simulationStart: Dayjs;
  simulationEnd: dayjs.Dayjs;
  timeDomain: TimeInfoItem[];
}

const InputTimeInfo: React.FC<Props> = ({
  inputScenario,
  onLoadedTime,
  simulationStart,
  simulationEnd,
  onUpdateOffset,
}) => {
  const { fullSource, timeInfo, producingData } = useInputFullInputSource(
    inputScenario.scenarioID,
    inputScenario.projectID,
    inputScenario.type,
    inputScenario.offset
  );

  useEffect(() => {
    if (timeInfo) {
      onLoadedTime({
        startTime: timeInfo.startTime,
        endTime: timeInfo.endTime,
        id: inputScenario.scenarioID,
      });
    }
  }, [timeInfo, onLoadedTime, inputScenario.scenarioID]);

  const inputError = useMemo(() => {
    if (!timeInfo) return "Time info not loaded";
    if (simulationStart.isBefore(timeInfo?.startTime)) {
      return "Input start time should be before, or same as simulation.";
    }
    return false;
  }, [simulationStart, timeInfo]);

  const procentBefore = useMemo(() => {
    if (!timeInfo) return 0;

    const simRuntime = simulationEnd.diff(simulationStart);
    //input start compared to simRuntime
    const startTimeFraction = timeInfo.startTime.diff(simulationStart) / simRuntime;
    if (startTimeFraction < 0) return 2; // -> add fraction compared to domain?
    if (startTimeFraction > 1) return 93; // 3% margin;
    return 10 + startTimeFraction * 80; //10% start and % of full 80%..
  }, [timeInfo, simulationStart, simulationEnd]);

  const procentDuring = useMemo(() => {
    if (!timeInfo) return 0;

    const simRuntime = simulationEnd.diff(simulationStart);

    //input start compared to simRuntime
    const endTimeFraction = timeInfo.endTime.diff(simulationStart) / simRuntime;

    if (endTimeFraction < 0) return 6; //-> add fraction compared to timedomain?;
    if (endTimeFraction > 1) return 80 + 10 + 6 - procentBefore; //end at 98%
    return endTimeFraction * 80 + (10 - procentBefore);
  }, [timeInfo, simulationStart, simulationEnd, procentBefore]);

  const inputMissingProcent = useMemo(() => {
    if (!timeInfo) return 0;
    if (timeInfo.endTime.isAfter(simulationEnd)) {
      return 0;
    }
    return 90 - procentBefore - procentDuring;
  }, [timeInfo, simulationEnd, procentBefore, procentDuring]);

  const renderMissingInputAfter = () => {
    /* Missing input after input has run out */
    return (
      <div
        className="border-b-4 relative border-green-200"
        style={{ width: `${inputMissingProcent}%` }}
      >
        <div
          className="absolute top-0 left-1/2 -mt-3 text-xxs border border-green-200 rounded-xl bg-white px-1 py-1 flex items-center z-10"
          style={{
            marginLeft: inputMissingProcent > 15 ? `-3rem` : "-1rem",
            marginTop: "",
          }}
        >
          <HoverTooltip
            mt={-10}
            rightAlign={true}
            text="Input will repeat after data is no longer available."
          >
            <RefreshIcon className="w-4 h-4" />
          </HoverTooltip>

          {inputMissingProcent > 15 && (
            <span className="mx-1">{producingData ? "Future reading" : "Repeat input"}</span>
          )}
        </div>
        {/* marker */}
        <div
          className={`absolute top-0 right-0 h-3 w-3 border-2 border-green-200 -mt-1 -mr-1 bg-white rounded-full`}
        ></div>
      </div>
    );
  };

  const renderTimeDisplay = () => {
    if (!timeInfo) return null;

    return (
      <div className="flex items-center pt-12 pb-6">
        <div
          // className="border-b-4  relative border-gray-200"
          style={{ width: `${procentBefore}%` }}
        ></div>

        <div
          style={{ width: `${procentDuring}%` }}
          className={`border-b-4  relative border-green-400`}
        >
          {/* markers */}
          <div
            className={`absolute top-0 left-0 h-3 w-3 border-2 border-green-400 -mt-1 -ml-1 bg-white rounded-full z-10`}
          ></div>
          <div
            className={`absolute top-0 right-0 h-3 w-3 border-2 border-green-400 -mt-1 -mr-1 bg-white rounded-full z-10`}
          ></div>

          {/*StartTime box*/}
          <div className={`absolute top-0 left-0 -ml-1 -mt-10`}>
            <TimeTagEditable
              headline="Offset input start"
              startDate={timeInfo.startTime}
              simulationStart={simulationStart}
              originalStart={timeInfo.originalStart}
              onUpdate={(updated) => {
                onUpdateOffset(updated.diff(timeInfo.originalStart, "seconds"));
              }}
            />
          </div>

          {/*EndTime box*/}
          <div className={`absolute top-0 mt-2 right-0 -mr-1`}>
            <div
              className={`whitespace-nowrap text-xxs leading-tight bg-white border border-gray-200 px-2 rounded-lg shadow py-1 cursor-pointer relative`}
            >
              <div>End time</div>
              <div>{timeInfo.endTime.format("HH:mm DD/MM YY")}</div>
            </div>
          </div>
        </div>

        {inputMissingProcent > 0 && renderMissingInputAfter()}
      </div>
    );
  };

  return (
    <div className="pb-10">
      <div className=" text-sm px-1/10 mx-2 flex items-center">
        <span>{fullSource?.scenarioName || "Input"}</span>
        {inputError && (
          <HoverTooltip className="ml-1" mt={-25} text={inputError}>
            <InfoIcon className="text-red-500 h-5 w-5" />
          </HoverTooltip>
        )}
      </div>
      {renderTimeDisplay()}
    </div>
  );
};

export default InputTimeInfo;
