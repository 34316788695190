import React, { useEffect, useMemo, useState } from "react";
import { PIDiagram, RawDataReport, SimulationScenario, TimeInfo } from "model/datatypes";
import { useFirestore, useRawdataReports } from "api/useFirebase";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import getUUID from "utils/jsUtils/getUUID";
import PIDSetup from "components/PID/PIDEditor";
import PIDViewer from "components/PID/PIDViewer";
import RawLineGraph from "./RawLineGraph";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import Modal from "components/basic/Modal";
import { fsFieldvalue } from "utils/firebase/helpers";
import { useDataInfo, useGRPCDataStream } from "grpc/grpcReact";
import PopupMenu from "components/basic/PopupMenu";
import { ChevronRightIcon, PencilAltIcon } from "@heroicons/react/solid";

interface Props {
  scenario: SimulationScenario;
}

const RawDataViewer: React.FC<Props> = ({ scenario }) => {
  const fs = useFirestore();

  const reports = useRawdataReports(scenario.systemID, scenario.projectID, scenario.id);
  const [selectedReportID, setSelectedReportID] = useState<string | null>(null);

  // useEffect(() => {
  //   if (reports.length === 1 && selectedReportID === null) setSelectedReportID(reports[0].id);
  // }, [reports, selectedReportID]);

  const { timeInfo } = useDataInfo(
    scenario.projectID,
    scenario.id,
    scenario.lastest_main_execution
  );

  const tags = useMemo(() => {
    if (timeInfo) {
      return timeInfo.scenarioMetadata.tagsList.map((tli) => tli.name);
    }
    if (scenario.dataTags) return scenario.dataTags;
    return [];
  }, [timeInfo, scenario.dataTags]);

  const selectedReport = useMemo(
    () => reports.find((report) => report.id === selectedReportID),
    [selectedReportID, reports]
  );

  const [editingReport, setEditingReport] = useState<Partial<RawDataReport> | null>(null);
  const [addReportLoading, setAddReportLoading] = useState(false);
  const saveReport = () => {
    if (!addReportLoading && editingReport) {
      const newReportDoc = fs
        .collection("Projects")
        .doc(scenario.projectID)
        .collection("Scenarios")
        .doc(scenario.id)
        .collection("RawDataReports")
        .doc(editingReport.id);

      const report: RawDataReport = {
        id: newReportDoc.id,
        globalVersion: 0,
        localVersion: 1,
        lineGraphs: [],
        piDiagrams: [],
        name: editingReport.name!,
        ...editingReport,
      };
      setAddReportLoading(true);
      newReportDoc
        .set(report)
        .then(() => {
          setAddReportLoading(false);
          setEditingReport(null);
          setSelectedReportID(report.id);
        })
        .catch((error) => {
          setAddReportLoading(false);
          console.log(error);
        });
    }
  };

  const renderEditNewReport = () => {
    if (!editingReport) return;
    return (
      <Modal
        onClose={() => {
          setEditingReport(null);
        }}
      >
        <div
          className={`z-40 bg-white border border-gray-200 rounded shadow-xl w-1/2 px-8 py-4`}
        >
          <div className={`font-medium mb-4 text-center`}>
            {editingReport.id ? "Edit" : "Add new"} report
          </div>
          <div className="text-xs font-medium">Report name</div>
          <input
            data-test="reportName"
            className={`input-box text-xs w-full mb-2`}
            value={editingReport.name}
            onChange={(e) => {
              setEditingReport({ ...editingReport, name: e.target.value });
            }}
          />

          <div className="text-xs font-medium">Description</div>
          <textarea
            className={`input-box text-xs w-full mb-4`}
            value={editingReport.description}
            onChange={(e) => {
              setEditingReport({ ...editingReport, description: e.target.value });
            }}
          />
          <div className="flex">
            <button
              data-test="saveReport"
              className={`button-small mr-2 flex-1`}
              onClick={() => {
                saveReport();
              }}
            >
              Save
            </button>
            <button
              className={`button-small ml-2 flex-1`}
              onClick={() => setEditingReport(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderReportSelecter = () => {
    return (
      <>
        <div className="flex flex-wrap">
          {reports.map((report) => {
            return (
              <div
                data-test="reportViewButton"
                key={report.id}
                className={`px-4 py-2 w-56 h-28 shadow-md mr-4 mb-4 border rounded border-gray-200 cursor-pointer`}
                onClick={() => {
                  setSelectedReportID(report.id);
                }}
              >
                <div className="text-sm font-bold">{report.name}</div>
                <div className="text-xs">{report.description || "No description"}</div>
                <div className="text-xs">
                  {report.lineGraphs.length}{" "}
                  {report.lineGraphs.length !== 1 ? "graphs" : "graph"}
                </div>
              </div>
            );
          })}
        </div>
        {reports.length === 0 && <div className="mt-4 italic">No reports yet</div>}
        <div>
          <button
            data-test="addReportButton"
            className="button-small"
            onClick={() => setEditingReport({ name: "My report" })}
          >
            + add new report
          </button>
        </div>
      </>
    );
  };
  const renderBreadCrumbs = () => {
    return (
      <div className="flex items-center text-sm py-2 px-4 border-b border-gray-200">
        <button
          className="focus:outline-none"
          onClick={() => {
            if (selectedReportID) setSelectedReportID(null);
          }}
        >
          Reports
        </button>
        {selectedReport && (
          <>
            <ChevronRightIcon className="w-4 h-4 mx-1" />
            <div className="">{selectedReport.name}</div>
            <PencilAltIcon
              className="w-3 h-3 ml-1 cursor-pointer"
              onClick={() => {
                setEditingReport(selectedReport);
              }}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {renderBreadCrumbs()}
      <div className="px-4 py-4 flex flex-col relative">
        {!selectedReportID && renderReportSelecter()}
        {selectedReportID && (
          <div className="flex-grow overflow-auto">
            {selectedReport && (
              <RawDataReportView
                selectedReport={selectedReport}
                tags={tags}
                scenario={scenario}
                dataInfo={timeInfo}
              />
            )}
          </div>
        )}
      </div>
      {editingReport && renderEditNewReport()}
    </div>
  );
};

export default RawDataViewer;

const getNewSelectedOutputField = () => {
  return { id: getUUID(), fields: [] } as { id: string; fields: string[] };
};

const RawDataReportView: React.FC<
  Props & { selectedReport: RawDataReport; tags: string[]; dataInfo: TimeInfo | null }
> = ({ selectedReport, tags, scenario, dataInfo }) => {
  const fs = useFirestore();
  const [viewingPID, setViewingPID] = useState<null | PIDiagram>(null);
  const [pidEditiorOpen, setPIDEditorOpen] = useState(false);
  const [editingDiagram, setEditingDiagram] = useState<PIDiagram>();

  const [dataFields, setDataFields] = useState<string[]>([]);

  useEffect(() => {
    let updatedFields: string[] = [];
    selectedReport.piDiagrams.forEach((diagram) => {
      diagram.tags.forEach((tag) => {
        if (tag.sourceID) updatedFields.push(tag.sourceID);
      });
    });
    selectedReport.lineGraphs.forEach((lineGraph) => {
      updatedFields = [...updatedFields, ...lineGraph.fields];
    });

    updatedFields = [...new Set(updatedFields)];
    setDataFields(updatedFields);
  }, [selectedReport]);

  const reportElements = useMemo(() => {
    return selectedReport.lineGraphs.length + selectedReport.piDiagrams.length;
  }, [selectedReport]);

  const { data, streamActive, time } = useGRPCDataStream(
    {
      listen: true,
      scenarioID: scenario.id,
      projectID: scenario.projectID,
      executionID: scenario.lastest_main_execution,
    },
    dataFields
  );

  const updateGraph = (updatedGraph: { id: string; fields: string[] }) => {
    if (selectedReport)
      fs.collection("Projects")
        .doc(scenario.projectID)
        .collection("Scenarios")
        .doc(scenario.id)
        .collection("RawDataReports")
        .doc(selectedReport.id)
        .update({
          lineGraphs: updateArrayVal(selectedReport.lineGraphs, updatedGraph),
          localVersion: fsFieldvalue.increment(1),
        })
        .then(() => {
          // setDataUpToData(false);
        })
        .catch((e) => {
          console.log(e);
          Toast("Error updaing graph...");
        });
  };

  const removeOutputFieldGraph = async (fieldsID: string) => {
    if (selectedReport)
      await fs
        .collection("Projects")
        .doc(scenario.projectID)
        .collection("Scenarios")
        .doc(scenario.id)
        .collection("RawDataReports")
        .doc(selectedReport.id)
        .update({
          lineGraphs: selectedReport.lineGraphs.filter((fields) => fields.id !== fieldsID),
          localVersion: fsFieldvalue.increment(1),
        })
        .then(() => {})
        .catch((e) => {
          console.log(e);
          Toast("Error removing graph...");
        });
  };

  const savePID = (pid: PIDiagram) => {
    return fs
      .collection("Projects")
      .doc(scenario.projectID)
      .collection("Scenarios")
      .doc(scenario.id)
      .collection("RawDataReports")
      .doc(selectedReport!.id)
      .update({
        piDiagrams: updateArrayVal(selectedReport!.piDiagrams, pid),
        localVersion: fsFieldvalue.increment(1),
      })
      .then(() => {
        // setDataUpToData(false);
      })
      .catch((e) => {
        console.log(e);
        Toast("Error saving PID...");
      });
  };

  const [savingToModel, setSavingReport] = useState(false);
  const saveReportToSystem = (report: RawDataReport) => {
    if (!savingToModel) {
      setSavingReport(true);
      const batch = fs.batch();
      const modelDoc = fs.collection("SimulationModels").doc(scenario.systemID);
      const modelReportDoc = modelDoc.collection("RawDataReports").doc(report.id);
      const localReportDoc = fs
        .collection("Projects")
        .doc(scenario.projectID)
        .collection("Scenarios")
        .doc(scenario.id)
        .collection("RawDataReports")
        .doc(report.id);
      batch.set(modelReportDoc, { ...report, globalVersion: report.localVersion }); //save to model...
      batch.update(modelDoc, { version: fsFieldvalue.increment(1) }); // save that theres a new model version...
      batch.update(localReportDoc, { globalVersion: report.localVersion }); //update local version
      batch
        .commit()
        .then(() => {
          setSavingReport(false);
          Toast("Successfully saved to system", { icon: "success" });
        })
        .catch((e) => {
          console.log(e);
          setSavingReport(false);
          Toast("Error saving to system", { icon: "error" });
        });
    }
  };

  const renderDiagramSaveOptions = () => {
    if (selectedReport) {
      if (!selectedReport.globalVersion) {
        return (
          <button
            className={`button-small mx-2`}
            onClick={() => {
              saveReportToSystem(selectedReport);
            }}
          >
            Save report to system
          </button>
        );
      } else if (selectedReport.localVersion > selectedReport.globalVersion) {
        return (
          <button
            className={`button-small mx-2`}
            onClick={() => {
              saveReportToSystem(selectedReport);
            }}
          >
            Save updates to system
          </button>
        );
      }
    }
    return null;
  };

  const renderPIDDiagrams = (PIDDiagrams: PIDiagram[]) => {
    return (
      <>
        {PIDDiagrams.map((diagram) => {
          return (
            <div
              key={diagram.id}
              style={{ backgroundColor: "#F7FAFC" }}
              className={`w-full shadow flex items-center px-4 text-xs py-2 rounded border border-gray-200 my-2`}
            >
              <div className="font-medium flex-grow">{diagram.name}</div>
              <button
                className={`button-small mr-2`}
                onClick={() => {
                  setViewingPID(diagram);
                }}
              >
                View
              </button>
              <button
                className={"button-small"}
                onClick={() => {
                  setEditingDiagram(diagram);
                  setPIDEditorOpen(true);
                }}
              >
                Edit
              </button>
            </div>
          );
        })}
        {viewingPID && (
          <PIDViewer
            projectID={scenario.projectID}
            scenarioID={scenario.id}
            onFinish={() => setViewingPID(null)}
            diagram={viewingPID}
            data={data}
            t={time}
          />
        )}
        {pidEditiorOpen && (
          <PIDSetup
            savePID={savePID}
            onFinish={() => {
              setEditingDiagram(undefined);
              setPIDEditorOpen(false);
            }}
            availableFields={tags}
            modelID={scenario.systemID}
            scenarioID={scenario.id}
            projectID={scenario.projectID}
            startDiagram={editingDiagram}
          />
        )}
      </>
    );
  };

  const renderLinegraphs = (lineGraphs: RawDataReport["lineGraphs"]) => {
    return (
      <>
        {lineGraphs?.map((graph) => {
          return (
            <RawLineGraph
              dataInfo={dataInfo}
              data={data}
              t={time}
              key={graph.id}
              lineGraph={graph}
              allTags={tags}
              onUpdate={updateGraph}
              onRemove={() => {
                return removeOutputFieldGraph(graph.id);
              }}
              scenarioName={scenario.scenarioName}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="flex-grow overflow-auto relative min-h-64">
        {selectedReport && renderLinegraphs(selectedReport.lineGraphs)}
        {selectedReport && renderPIDDiagrams(selectedReport.piDiagrams)}
        {selectedReport && (
          <div className="flex mt-2 ">
            <PopupMenu
              flipped={reportElements > 1 || undefined}
              closeOnClick
              buttonText="New element"
              options={[
                { id: "graph", title: "Line graph", description: "Add a linegraph" },
                {
                  id: "pid",
                  title: "PI Diagram",
                  description:
                    "Add an SVG based Piping and infrastructure diagram with embedded data tags. (experimental)",
                },
              ]}
              onSelect={(option) => {
                if (option.id === "graph") updateGraph(getNewSelectedOutputField());
                else if (option.id === "pid") setPIDEditorOpen(true);
              }}
            />
            {renderDiagramSaveOptions()}
          </div>
        )}

        {savingToModel && <LoadingOverlay />}
      </div>
      {streamActive && (
        <div className="absolute top-0 right-0 mt-2 mr-2 text-green-600 text-xs italic">
          Connection open
        </div>
      )}
    </>
  );
};
