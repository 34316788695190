import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import { ComponentParamType, ParameterTable } from "model/datatypes";
import React, { useState } from "react";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";

const ParamTableColumnEdit: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ onUpdate, parameter }) => {
  const tableColumns = parameter.tableColumns;

  const updateCol = (newCol: ParamTableColumn) => {
    const prevCol = tableColumns?.find((col) => col.uuid === newCol.uuid);
    const keyAlreadyTaken = tableColumns?.some(
      (col) => col.colKey === newCol.colKey && col.uuid !== newCol.uuid
    );
    if (keyAlreadyTaken) return false; //

    if (tableColumns && prevCol) {
      const updatedCols = updateArrayValUUID(tableColumns, newCol);
      //update the key in the actual data default if exists:
      const val = parameter.value as null | ParameterTable;
      if (val && newCol.colKey !== prevCol.colKey) {
        const values = val[prevCol.colKey];
        delete val[prevCol.colKey];
        val[newCol.colKey] = values;
        console.log({ val });
      }
      onUpdate({ ...parameter, tableColumns: updatedCols, value: val });
      return true;
    }
    return false;
  };

  const addNewCol = (newCol: ParamTableColumn) => {
    if (!tableColumns) {
      onUpdate({ ...parameter, tableColumns: [newCol], value: { [newCol.colKey]: [] } });
    } else {
      const updatedEntries = Object.entries(parameter.value as ParameterTable);
      updatedEntries.push([newCol.colKey, new Array(updatedEntries[0][1].length).fill(0)]);
      const updatedCols = [...tableColumns, newCol];
      onUpdate({
        ...parameter,
        tableColumns: updatedCols,
        value: Object.fromEntries(updatedEntries),
      });
    }
  };

  const deleteCol = (deletedCol: ParamTableColumn) => {
    if (tableColumns) {
      const updatedValTable = parameter.value as ParameterTable;
      delete updatedValTable[deletedCol.colKey];
      onUpdate({
        ...parameter,
        tableColumns: tableColumns.filter((c) => c.uuid !== deletedCol.uuid),
        value: updatedValTable,
      });
    }
  };

  const [columnEditorOpen, setColumnEditorOpen] = useState(false);

  return (
    <>
      <button onClick={() => setColumnEditorOpen(true)} className={`button-small w-full`}>
        Edit columns
      </button>
      {columnEditorOpen && (
        <Modal onClose={() => setColumnEditorOpen(false)}>
          <div className="bg-white rounded w-5/6 shadow-lg p-4 z-30">
            <div className="font-medium">Edit columns</div>
            <div className="flex w-full my-2 h-32">
              <div className="text-xs">
                <div className="px-4 h-8 flex items-center">Display Name</div>
                <div className="px-4 py-2 h-8 flex items-center">Key</div>
              </div>
              {tableColumns?.map((col) => (
                <TableColumn
                  key={col.uuid}
                  column={col}
                  updateCol={updateCol}
                  onDelete={() => {
                    deleteCol(col);
                  }}
                />
              ))}
            </div>
            <button
              onClick={() => {
                let colNr = 1;
                if (tableColumns) colNr += tableColumns.length;
                const newCol = {
                  uuid: getUUID(),
                  colKey: `new_key_${colNr}`,
                  colDisplay: "New col",
                };
                addNewCol(newCol);
              }}
              className={`button-small mt-4`}
            >
              Add column
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

type ParamTableColumn = { uuid: string; colKey: string; colDisplay: string };

const TableColumn: React.FC<{
  column: ParamTableColumn;
  updateCol: (newCol: ParamTableColumn) => boolean;
  onDelete: () => void;
}> = ({ column, updateCol, onDelete }) => {
  const [editedCol, setEditedCol] = useState<ParamTableColumn | null>(null);
  const renderDisplayCol = () => {
    return (
      <>
        <div className="bg-gray-100 px-2 border border-gray-100 h-8 flex items-center">
          {column.colDisplay}
        </div>
        <div className="px-2 border border-white bg-white h-8 flex items-center">
          {column.colKey}
        </div>
        <div className="bg-gray-100 px-2 py-2">
          <button
            onClick={() => {
              setEditedCol(column);
            }}
            className={`border border-gray-200 w-full focus:outline-none rounded  mb-2`}
          >
            Edit
          </button>
          <button
            onClick={() => {
              onDelete();
            }}
            className={`border border-gray-200 w-full focus:outline-none rounded`}
          >
            Delete
          </button>
        </div>
      </>
    );
  };

  const renderEditCol = () => {
    return (
      <>
        <div className="bg-gray-100 px-1 h-8 flex items-center">
          <input
            type="text"
            className={`px-1 focus:outline-none border rounded w-full`}
            value={editedCol?.colDisplay}
            onChange={(e) => {
              editedCol && setEditedCol({ ...editedCol, colDisplay: e.target.value });
            }}
          />
        </div>
        <div className="px-1 flex items-center h-8">
          <input
            type="text"
            className={`px-1 focus:outline-none border rounded w-full`}
            value={editedCol?.colKey}
            onChange={(e) => {
              editedCol && setEditedCol({ ...editedCol, colKey: e.target.value });
            }}
          />
        </div>
        <div className="bg-gray-100 px-2 py-2 relative">
          <button
            onClick={() => {
              if (editedCol) {
                const success = updateCol(editedCol);
                if (success) setEditedCol(null);
                else Toast("Error updating column");
              }
            }}
            className={`border border-gray-200 w-full focus:outline-none rounded mb-2`}
          >
            Save
          </button>
          <button
            onClick={() => {
              setEditedCol(null);
            }}
            className={`border border-gray-200 w-full focus:outline-none rounded`}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="w-24 mx-px text-xs">
      {editedCol ? renderEditCol() : renderDisplayCol()}
    </div>
  );
};

export default ParamTableColumnEdit;
