import { useFirestore } from "api/useFirebase";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import InputDataIcon from "components/basic/icons/InputDataIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import { Popup } from "components/basic/Popup";
import { DatasetScenario, Schedule } from "model/datatypes";
import React, { useMemo, useState } from "react";
import Plot from "react-plotly.js";
import { PlotData } from "plotly.js";
import { useGRPCData, Dataframe, useDataInfo } from "grpc/grpcReact";
import ScheduleInput from "./newSchedule/ScheduleInput";
import { fsFieldvalue } from "utils/firebase/helpers";

interface Props {
  datasets: DatasetScenario[];
}

const ProjectDatasets = ({ datasets }: Props) => {
  return (
    <div className="">
      {datasets.map((ds) => {
        return <DatasetCard key={ds.id} dataset={ds} />;
      })}
    </div>
  );
};

export default ProjectDatasets;

const DatasetCard: React.FC<{ dataset: DatasetScenario }> = ({ dataset }) => {
  // const [displayHarvesterInfo, setDisplayHarvesterInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { projectID } = useGlobalState();
  const fs = useFirestore();
  const deleteDataset = async () => {
    try {
      setLoading(true);
      //TODO remove data from bigtable

      const batch = fs.batch();
      const projectDoc = fs.collection("Projects").doc(dataset.projectID);
      batch.delete(projectDoc.collection("Scenarios").doc(dataset.id));
      batch.update(projectDoc, { scenarios: fsFieldvalue.arrayRemove(dataset.id) });
      await batch.commit();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { scenarioMetadata } = useDataInfo(
    dataset.projectID,
    dataset.id,
    dataset.lastest_main_execution
  );

  const [editingSchedule, setEditingSchedule] = useState<Schedule | null>(null);
  const [addingSchedule, setAddingSchedule] = useState(false);

  const renderDeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete dataset?</div>
              <div className="italic mb-2">
                Scenarios using this dataset will stop working.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteDataset();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`button-popup text-red-500`}>Delete dataset</button>
      </Popup>
    );
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={"right"}
        content={(closeMe) => {
          return (
            <div className="text-xs">
              <button
                className={`button-popup border-b`}
                onClick={() => {
                  setAddingSchedule(true);
                }}
              >
                Add schedule
              </button>

              {renderDeleteOption(closeMe)}
            </div>
          );
        }}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const [displayData, setDisplayData] = useState(false);

  return (
    <div
      className={`w-full font-medium relative bg-white rounded border border-gray-300 mb-6`}
    >
      <div className="cursor-pointer px-4 py-2 w-full border-b border-gray-200 flex items-center">
        <InputDataIcon className="mr-3 h-5 w-5" />
        <div className="flex-grow">{dataset.scenarioName}</div>
        {renderOptions()}
      </div>
      <div className="px-4 py-2 flex">
        <div>
          <div
            className="rounded-full border-2 border-green-500 flex items-center justify-center w-10 h-10 cursor-pointer "
            onClick={() => {
              setDisplayData(true);
            }}
          >
            <InputDataIcon className="text-green-500" />
          </div>
          <div className="font-medium text-xs cursor-pointer">View tags</div>
        </div>
        {dataset.schedules && (
          <div className="text-xs rounded border border-gray-400 py-1 px-3 mx-4">
            <div className="font-medium mb-1">Schedules</div>
            <div className="flex items-center">
              {Object.entries(dataset.schedules).map(([tag, schedule]) => {
                return (
                  <div
                    key={tag}
                    onClick={() => {
                      setEditingSchedule(schedule);
                    }}
                    className="cursor-pointer underline text-blue-600 mr-3"
                  >
                    {tag}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {loading && <LoadingOverlay />}
      {displayData && (
        <DatasetViewer dataset={dataset} onFinish={() => setDisplayData(false)} />
      )}
      {(editingSchedule || addingSchedule) && (
        <ScheduleInput
          dataset={dataset}
          scenarioMetadata={scenarioMetadata || undefined}
          startSchedule={editingSchedule || undefined}
          onFinished={(updated) => {
            setEditingSchedule(null);
            setAddingSchedule(false);
          }}
        />
      )}
    </div>
  );
};

const DatasetViewer: React.FC<{ dataset: DatasetScenario; onFinish: () => void }> = ({
  dataset,
  onFinish,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data, time, loadingData } = useGRPCData(
    selectedTags,
    dataset.id,
    dataset.projectID,
    dataset.lastest_main_execution
  );

  return (
    <Modal onClose={onFinish}>
      <div className="modal-content z-30 w-full relative h-full flex flex-col">
        <div className="font-bold">View data</div>
        <div className="flex-grow overflow-hidden w-full flex">
          <div className="w-1/3 text-xs h-full overflow-auto pr-4 scrollbar-light">
            {dataset.dataTags.map((tag) => {
              const isSelected = selectedTags.some((t) => t === tag);
              return (
                <div
                  key={tag}
                  onClick={() => {
                    if (isSelected) setSelectedTags(selectedTags.filter((t) => t !== tag));
                    else setSelectedTags([...selectedTags, tag]);
                  }}
                  className={`my-1 cursor-pointer ${isSelected ? "bg-green-numerous" : ""}`}
                >
                  {tag}
                </div>
              );
            })}
          </div>
          <div className="w-2/3 h-full flex items-center">
            {data && <Graph fields={selectedTags} data={data} t={time} />}
          </div>
        </div>
        {loadingData && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

const Graph: React.FC<{ fields: string[]; data: Dataframe; t: number[] | null }> = ({
  fields,
  data,
  t,
}) => {
  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];
    fields.forEach((field) => {
      const theData = data ? data[field] : null;
      const xAxis = t || theData?.map((v, i) => i);
      if (theData && xAxis)
        plotData.push({
          x: xAxis,
          y: theData,
          type: "scatter",
          mode: "lines",
          name: field,
        });
    });
    return plotData;
  }, [data, t, fields]);

  return (
    <Plot
      className={``}
      data={mappedData}
      useResizeHandler
      config={{ displayModeBar: false }}
      style={{ width: "100%" }}
      layout={{
        autosize: true,
        paper_bgcolor: "#F7FAFC",
        plot_bgcolor: "#F7FAFC",
        margin: {
          t: 40,
          b: 40,
          l: 40,
          r: 40,
          pad: 0,
        },
      }}
    />
  );
};
