import React, { useState, useMemo, useEffect } from "react";
import {
  useComponentTypes,
  useContainers,
  useFirestore,
  useSimulationModel,
} from "api/useFirebase";
import { useParams } from "react-router-dom";
import { ComponentType, Container, SimulationModel } from "model/datatypes";

import EditableName from "components/basic/EditableName";
import LocalFiles from "./files/LocalFiles";
import { useGlobalState } from "store";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Dropdown, { DropdownAtRoot } from "components/basic/Dropdown";
import EditCollaborators from "components/collaboratos/EditCollaborators";
import dayjs from "dayjs";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { fsFieldvalue } from "utils/firebase/helpers";
import SimulationJobsEditor from "./SimulationJobsEditor";
import Toast from "components/basic/Toast";
import CodeEditor from "./pythonCode/CodeEditor";
import SystemComponents from "./SystemComponents";
import AdvancedSystemComponents from "./AdvancedSystemComponents";
import {
  EditSystemStateProvider,
  useEditSystemDispatch,
  useEditSystemState,
} from "./EditSystemStore";
import ToggleButton from "components/basic/ToggleButton";
import SystemParameters from "./SystemParameters";

interface Props {}

type EntityListItem =
  | { type: "container"; entity: Container }
  | { type: "componentType"; entity: ComponentType };

const EditSystemPage: React.FC<Props> = () => {
  const { user, sidebarState, experimentalFeaturesEnabled } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const { simModelID } = useParams<{ simModelID: string | undefined }>();
  const system = useSimulationModel(simModelID);

  const editSystemDispatch = useEditSystemDispatch();
  const { editedSystem, editedComponentTypes, editedContainers, systemLoaded, isChanged } =
    useEditSystemState();

  //Ordered list of all containers and components:
  const simulationEntities = useMemo(() => {
    if (!editedContainers || !editedComponentTypes) return [];
    const entitityHolder: EntityListItem[] = editedContainers.map((container) => ({
      type: "container",
      entity: container,
    }));
    editedComponentTypes.forEach((c) => {
      entitityHolder.push({ type: "componentType", entity: c });
    });
    return entitityHolder.sort((a, b) => {
      if (!a.entity.order) return -1;
      else if (!b.entity.order) return 1;
      else return a.entity.order - b.entity.order;
    });
  }, [editedComponentTypes, editedContainers]);

  useEffect(() => {
    if (system && !systemLoaded) {
      editSystemDispatch({ type: "SET_INITIAL_SYSTEM", payload: system });
    }
  }, [system, systemLoaded, editSystemDispatch]);

  //deprecated:
  const { componentTypes } = useComponentTypes(simModelID);
  const { containers } = useContainers(simModelID);
  const isDeprecated = useMemo(
    () => editedSystem && (!editedSystem.componentTypes || !editedSystem.containers),
    [editedSystem]
  );

  const updateComponent = (componentID: string, newComponentProps: Partial<ComponentType>) => {
    let compToUpdate = editedComponentTypes?.find((ct) => ct.id === componentID);
    if (!compToUpdate) return;
    compToUpdate = { ...compToUpdate, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate! });
  };

  const addComponentType = (newCompType: ComponentType) => {
    if (!editedComponentTypes) return;
    // setEditedComponentTypes((p) => [...p!, newCompType]);
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: newCompType });
  };

  const updateContainer = (containerID: string, newContainerProps: Partial<Container>) => {
    let cont = editedContainers?.find((ct) => ct.id === containerID);
    if (!cont) return;
    cont = { ...cont, ...newContainerProps };
    editSystemDispatch({ type: "UPDATE_CONTAINER", payload: cont! });
  };
  const addContainer = (newContainer: Container) => {
    if (!editedContainers) return;
    editSystemDispatch({ type: "UPDATE_CONTAINER", payload: newContainer });
  };

  const isSystemOwner = useMemo(() => {
    return user && user.fbUser.uid === system?.ownerId;
  }, [user, system?.ownerId]);

  const fs = useFirestore();

  //
  //modify system functions:
  //

  const onRemoveContainer = (container: Container) => {
    //delete the container
    editSystemDispatch({
      type: "SET_CONTAINERS",
      payload: editedContainers.filter((c) => c.id !== container.id),
    });
    //Move entities below up:
    moveEntitiesBelowUp(container.order);
  };

  const updateSystem = (changed: Partial<SimulationModel>, updateVersion?: boolean) => {
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };
    return fs
      .collection("SimulationModels")
      .doc(simModelID)
      .update({
        ...convertToFirestoreFormat(
          {
            ...changed,
            latestEdited,
          },
          true
        ),
        ...(updateVersion && { version: fsFieldvalue.increment(1) }),
      });
  };

  const onMoveComponentType = (comp: ComponentType, i: number, dir: "up" | "down") => {
    if (dir === "up") {
      const itemAbove = simulationEntities[i - 1];
      if (!itemAbove || itemAbove.entity.order !== comp.order - 1) {
        resetSimEntitiesOrder();
        return;
      }

      updateComponent(comp.id, { order: comp.order - 1 });
      if (itemAbove.type === "componentType")
        updateComponent(itemAbove.entity.id, {
          order: itemAbove.entity.order + 1,
        });
      else updateContainer(itemAbove.entity.id, { order: itemAbove.entity.order + 1 });
    } else {
      const itemBelow = simulationEntities[i + 1];
      if (!itemBelow || itemBelow.entity.order !== comp.order + 1) {
        resetSimEntitiesOrder();
        return;
      }

      updateComponent(comp.id, { ...comp, order: comp.order + 1 });
      if (itemBelow.type === "componentType")
        updateComponent(itemBelow.entity.id, {
          order: itemBelow.entity.order - 1,
        });
      else {
        updateContainer(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
      }
    }
  };

  const onMoveContainer = (cont: Container, i: number, dir: "up" | "down") => {
    if (dir === "up") {
      updateContainer(cont.id, { order: cont.order - 1 });
      const itemAbove = simulationEntities[i - 1];
      if (itemAbove) {
        if (itemAbove.type === "componentType")
          updateComponent(itemAbove.entity.id, {
            order: itemAbove.entity.order + 1,
          });
        else updateContainer(itemAbove.entity.id, { order: itemAbove.entity.order + 1 });
      }
    } else {
      const itemBelow = simulationEntities[i + 1];
      if (itemBelow) {
        updateContainer(cont.id, { ...cont, order: cont.order + 1 });
        if (itemBelow.type === "componentType")
          updateComponent(itemBelow.entity.id, {
            order: itemBelow.entity.order - 1,
          });
        else {
          updateContainer(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
        }
      }
    }
  };

  const onRemoveComponent = (componentID: string, i: number) => {
    //delete the component
    editSystemDispatch({
      type: "SET_COMPONENT_TYPES",
      payload: editedComponentTypes.filter((ct) => ct.id !== componentID),
    });

    //move all entities below up:
    moveEntitiesBelowUp(i);

    //remove from all conatiners where it is referenced:
    editedContainers
      ?.filter((container) => container.componentTypeIDs.some((id) => id === componentID))
      .forEach((container) => {
        const isDefaultComponent = container.defaultComponentType === componentID;
        updateContainer(container.id, {
          componentTypeIDs: container.componentTypeIDs?.filter((cid) => cid !== componentID),
          ...(isDefaultComponent && { defaultComponentType: undefined }),
          ...(container.order > i && { order: container.order - 1 }),
        });
      });
  };

  const moveEntitiesBelowUp = (i: number) => {
    //move all comp below one order up...
    let iterator = i + 1;
    while (iterator < simulationEntities.length) {
      const itemBelow = simulationEntities[iterator];
      if (itemBelow.type === "componentType") {
        updateComponent(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
      } else {
        updateContainer(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
      }
      iterator = iterator + 1;
    }
  };

  const resetSimEntitiesOrder = () => {
    console.log("Error in order, will reset.");
    simulationEntities.forEach((entity, i) => {
      if (i !== entity.entity.order) {
        if (entity.type === "componentType") updateComponent(entity.entity.id, { order: i });
        else updateContainer(entity.entity.id, { order: i });
      }
    });
  };

  //
  //helper render functions:
  //
  const renderSystemJobs = () => {
    if (editedSystem)
      return (
        <>
          <div className="text-lg font-medium mt-4">System jobs</div>
          <SimulationJobsEditor
            systemID={editedSystem.id}
            jobs={editedSystem.jobs}
            updateJobs={(updated) => {
              editSystemDispatch({
                type: "UPDATE_SYSTEM",
                payload: {
                  jobs: updated,
                },
              });
            }}
          />
        </>
      );
  };

  const renderSystemSaver = () => {
    return (
      <div
        className={`fixed bottom-0 right-0 pr-4 w-full z-10  ${
          sidebarState === "wide" ? "pl-56" : "pl-24"
        } `}
      >
        <div className="w-full py-2 px-2 bg-gray-100 border border-gray-200 rounded-t shadow-lg">
          <div className="bg-white border border-gray-200 shadow-md rounded text-xs flex w-full overflow-hidden">
            <button
              className="flex-1 focus:outline-none py-1 border-r border-gray-300 hover:bg-gray-50"
              onClick={() => {
                if (loading) return;
                setLoading(true);
                const updatedSystem: SimulationModel = {
                  ...editedSystem!,
                  containers: editedContainers!,
                  componentTypes: editedComponentTypes!,
                };
                updateSystem(updatedSystem, true)
                  .then(() => {
                    setLoading(false);
                    editSystemDispatch({ type: "SAVED_UPDATES" });
                  })
                  .catch((e) => {
                    Toast("Error saving system");
                    setLoading(false);
                  });
              }}
            >
              Save system changes
            </button>
            <button
              className="flex-1  py-1 focus:outline-none hover:bg-gray-50"
              onClick={() => {
                if (system) {
                  editSystemDispatch({ type: "SET_INITIAL_SYSTEM", payload: system });
                }
              }}
            >
              Cancel system changes
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderExperimentalFeatures = () => {
    if (!editedSystem) return null;
    return (
      <>
        <div className="text-xs mt-2">System Type</div>
        <Dropdown
          className="bg-white w-48 text-xs"
          placeholder="Default"
          options={systemTypes}
          onSelect={(option) => {
            if (option.id === "FMU") {
              editSystemDispatch({
                type: "SET_FMU_MODE",
              });
            } else {
              editSystemDispatch({
                type: "UPDATE_SYSTEM",
                payload: { modelType: option.id as SystemTypesAvailable },
              });
            }
          }}
          selectedID={editedSystem.modelType}
        />
        <div className="text-xs mt-2">Custom Scenario UI</div>
        <div className="flex items-center">
          <ToggleButton
            active={typeof editedSystem.customUI === "string"}
            onChange={() => {
              editSystemDispatch({
                type: "UPDATE_SYSTEM",
                payload: {
                  customUI: typeof editedSystem.customUI === "string" ? undefined : "",
                },
              });
            }}
          />
          {typeof editedSystem.customUI === "string" && (
            <input
              className="input-box text-xs ml-2"
              value={editedSystem.customUI}
              onChange={(e) => {
                editSystemDispatch({
                  type: "UPDATE_SYSTEM",
                  payload: { customUI: e.target.value },
                });
              }}
            />
          )}
        </div>
      </>
    );
  };

  if (!editedSystem)
    return (
      <div className="w-screen h-screen relative">
        <LoadingOverlay />
      </div>
    );

  return (
    <div className={"pt-8 pb-20 px-8 relative "}>
      <div className={`font-medium text-xl mb-4`}>
        <EditableName
          name={editedSystem.displayName}
          onChange={(displayName) => {
            if (!loading && editedSystem) {
              editSystemDispatch({
                type: "UPDATE_SYSTEM",
                payload: {
                  displayName,
                },
              });
            }
          }}
          loading={loading}
        />
        <hr className="mt-2" />
      </div>
      <div className="text-xs font-medium">System description</div>
      <textarea
        className="input-box text-xs w-1/2"
        value={editedSystem.description}
        onChange={(e) => {
          editSystemDispatch({
            type: "UPDATE_SYSTEM",
            payload: {
              description: e.target.value,
            },
          });
        }}
      />

      {experimentalFeaturesEnabled && renderExperimentalFeatures()}

      {renderSystemJobs()}
      <hr className="my-4" />
      {editedSystem && (
        <SystemParameters
          editedSystem={editedSystem}
          updateParameters={(updated) => {
            editSystemDispatch({
              type: "UPDATE_SYSTEM",
              payload: { parameters: updated },
            });
          }}
        />
      )}
      {editedSystem.modelType === "experimental_builder" && (
        <>
          <hr className="my-4" />
          <div className={`font-medium text-lg mb-2`}>Preamble</div>
          <CodeEditor
            defaultCode={editedSystem.codePreample || initialPreamble}
            onUpdate={(updated) => {
              editSystemDispatch({
                type: "UPDATE_SYSTEM",
                payload: {
                  codePreample: updated,
                },
              });
            }}
          />
        </>
      )}

      <hr className="my-4" />
      {system && editedSystem.modelType !== "experimental_builder" && (
        <SystemComponents
          editedComponentTypes={editedComponentTypes}
          editedContainers={editedContainers}
          system={system}
          onMoveComponentType={onMoveComponentType}
          updateComponent={updateComponent}
          updateContainer={updateContainer}
          onRemoveContainer={onRemoveContainer}
          onRemoveComponent={onRemoveComponent}
          onMoveContainer={onMoveContainer}
          addComponentType={addComponentType}
          addContainer={addContainer}
        />
      )}
      {system && editedSystem.modelType === "experimental_builder" && (
        <AdvancedSystemComponents
          editedComponentTypes={editedComponentTypes}
          editedContainers={editedContainers}
          system={system}
          onMoveComponentType={onMoveComponentType}
          updateContainer={updateContainer}
          onRemoveContainer={onRemoveContainer}
          onRemoveComponent={onRemoveComponent}
          onMoveContainer={onMoveContainer}
          addComponentType={addComponentType}
          addContainer={addContainer}
        />
      )}
      <hr className="mt-4 mb-2" />

      {simModelID && <LocalFiles modelID={simModelID} modelName={editedSystem.displayName} />}
      <hr className="mt-6 mb-4" />
      <div>
        <div className="text-lg font-medium">Share system</div>
        {isSystemOwner && (
          <>
            <span className="text-xs font-medium">Status</span>
            <DropdownAtRoot
              className={"bg-white w-64 text-xs"}
              flipped={editedSystem.status !== "published" || undefined}
              options={[
                { id: "draft", display: "Draft" },
                { id: "published", display: "Published" },
              ]}
              selectedID={editedSystem.status || "draft"}
              onSelect={(option) => {
                const newStatus = option.id as SimulationModel["status"];
                editSystemDispatch({
                  type: "UPDATE_SYSTEM",
                  payload: {
                    status: newStatus,
                  },
                });
              }}
            />
            <span className="text-xs italic">
              {editedSystem.status === "published"
                ? "System will be availalble to other developers and selected colaborators"
                : "Draft systems are only available to the author"}
            </span>
          </>
        )}
        {editedSystem.status === "published" && (
          <div>
            <EditCollaborators
              allowEditing
              collaborators={editedSystem.collaborators || []}
              teamIds={editedSystem.teams || []}
              updateCollaborators={(updatedCollaborators) =>
                updateSystem({ collaborators: updatedCollaborators })
              }
              updateTeams={(updatedTeams) => updateSystem({ teams: updatedTeams })}
            />
          </div>
        )}

        {isChanged && renderSystemSaver()}

        {isDeprecated && ( //TODO: remore this....
          <div className="absolute top-0 left-0 w-full h-full bg-gray-300 z-30 opacity-75 flex flex-col items-center justify-center">
            <div>System is deprecated</div>
            <button
              className="button-small"
              onClick={() => {
                if (!editedSystem) return;
                //copy components and containers from old format:
                const system: SimulationModel = {
                  ...editedSystem,
                  parameters: editedSystem.parameters || [],
                  jobs: editedSystem.jobs || {},
                  componentTypes: componentTypes,
                  containers: containers,
                  SPM_version: 2,
                };
                editSystemDispatch({ type: "SET_INITIAL_SYSTEM", payload: system });
                //notify state that system is updated?
                editSystemDispatch({
                  type: "UPDATE_SYSTEM",
                  payload: {},
                });
              }}
            >
              Upgrade to new version
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const EditSystemPageWithState = (props: Props) => (
  <EditSystemStateProvider>
    <EditSystemPage {...props} />
  </EditSystemStateProvider>
);

export default EditSystemPageWithState;

const initialPreamble = `import numerous
`;

type SystemTypesAvailable = "default" | "experimental_builder";
const systemTypes: { id: SystemTypesAvailable; display: string }[] = [
  { id: "default", display: "Default" },
  { id: "experimental_builder", display: "Experimental system builder" },
];
