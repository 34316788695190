import { useUserRole } from "api/useAuth";
import { useAnalyticsLogger, useFirestore, useProjectTypes } from "api/useFirebase";
import { DropdownAtRoot } from "components/basic/Dropdown";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import dayjs from "dayjs";
import { Group, Project, ProjectType } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { useGlobalState } from "store";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

const NewProject: React.FC<{ onFinish: (newProject?: Project) => void }> = ({ onFinish }) => {
  const [loading, setloading] = useState(false);
  const [projectName, setprojectName] = useState("Example Project");
  const [description, setDescription] = useState("");
  const [selectedProjectType, setSelectedProjectType] = useState<ProjectType>();
  const { hasSimulatorAccess } = useUserRole();

  const { user } = useGlobalState();
  const fs = useFirestore();
  const analyticsLogger = useAnalyticsLogger();
  const projectTypes = useProjectTypes();
  const projectTypeOptions = useMemo(() => {
    const typeOptions: {
      id: string;
      display: string;
      val?: ProjectType;
    }[] = projectTypes.map((pt) => ({ id: pt.id, display: pt.type, val: pt }));
    typeOptions.push({ id: "undefined", display: "No project type" });
    return typeOptions;
  }, [projectTypes]);

  const addProject = () => {
    if (hasSimulatorAccess && user?.fbUser.uid) {
      setloading(true);
      const batch = fs.batch();

      //create new project
      const projectDoc = fs.collection("Projects").doc();

      const newProject: Project = {
        id: projectDoc.id,
        projectName,
        description,
        created: dayjs(),
        deleted: null,

        organisation: user.organisation,
        ownerId: user?.fbUser.uid,

        ...(selectedProjectType && { projectType: selectedProjectType.id }),
        teams: [],
        collaborators: [],
        amountOfScenarios: 0,
        scenarios: [],
      };

      batch.set(projectDoc, convertToFirestoreFormat(newProject));

      //if projectType has fixed groups create the groups:
      if (selectedProjectType && selectedProjectType.fixedGroups) {
        selectedProjectType.fixedGroups.forEach((fixedGroup) => {
          const groupDoc = projectDoc.collection("Groups").doc(fixedGroup.id);
          const newGroup: Group = {
            id: fixedGroup.id,
            created: dayjs(),
            groupName: fixedGroup.groupName,
            ownerId: user?.fbUser.uid,
            scenarios: [],
          };
          batch.set(groupDoc, convertToFirestoreFormat(newGroup));
        });
      }

      batch
        .commit()
        .then(() => {
          setloading(false);
          onFinish(newProject);
          analyticsLogger("project_created", {
            project_name: projectName,
            project_id: projectDoc.id,
            user_name: user?.fullName || "",
            user_id: user?.fbUser.uid || "",
          });
        })
        .catch((error) => {
          console.log(error);

          Toast("Error creating project", { icon: "error" });
        });
    }
  };
  return (
    <Modal onClose={onFinish}>
      <div className={`modal-content z-50 flex flex-col w-1/2`}>
        <div className="font-medium mb-2">New project</div>
        <label className="font-bold text-xs">Project name</label>
        <input
          data-test="projectName"
          type="text"
          className={`input-box mb-2 text-xs`}
          value={projectName}
          onChange={(e) => setprojectName(e.target.value)}
        />
        <label className="font-bold text-xs">Project description</label>
        <textarea
          data-test="projectDescription"
          className={`input-box h-20 mb-2 text-xs`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {projectTypeOptions.length > 1 && (
          <>
            <label className="font-bold text-xs">Project type</label>
            <div className="mb-2 w-full">
              <DropdownAtRoot
                className="text-xs "
                placeholder="No project type"
                selectedID={selectedProjectType?.id}
                options={projectTypeOptions}
                onSelect={(option) => {
                  setSelectedProjectType(option.val);
                }}
              />
            </div>
          </>
        )}
        <button
          className={`button-small my-2 ${loading ? "opacity-50" : ""}`}
          onClick={() => {
            !loading && addProject();
          }}
        >
          Add project
        </button>
      </div>
    </Modal>
  );
};
export default NewProject;
