import React, { useState } from "react";
import {
  RunSettings,
  ScheduledSimulationTask,
  SimulationJob,
  SimulationScenario,
} from "model/datatypes";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";
import ToggleButton from "components/basic/ToggleButton";
import dayjs from "dayjs";
import { useAnalyticsLogger, useFirebase, useFirestore } from "api/useFirebase";
import { useGlobalState } from "store";
import LoadingOverlay from "components/basic/LoadingOverlay";
import ConfigureSchedule from "components/simulations/newSimulation/simSetup/ConfigureSchedule";
import Modal from "components/basic/Modal";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import Toast from "components/basic/Toast";
import * as Sentry from "@sentry/browser";
import Parameter from "../simComponent/Parameter";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { startJob } from "grpc/grpcClient";
import { ClipboardIcon } from "@heroicons/react/solid";
import { TimeSetup } from "components/simulations/newSimulation/simSetup/runJob/time/TimeSetup";
import HistorySetup from "./HistorySetup";

interface Props {
  onFinish: () => void;
  scenario: SimulationScenario;
  job: SimulationJob;
  projectID: string;
}

const stdRunSettings: RunSettings = {
  startDate: dayjs("00:00 01/01/21", "HH:mm DD/MM/YY"),
  runMode: "duration",
  endTimeVal: {
    unit: "months",
    value:
      (dayjs("01/01/2022 00:00", "DD/MM/YYYY HH:mm").valueOf() -
        dayjs("00:00 01/01/21", "HH:mm DD/MM/YY").valueOf()) /
      1000,
  },
  endDate: dayjs("01/01/2022 00:00", "DD/MM/YYYY HH:mm"),
};

export const RunJob: React.FC<Props> = ({ onFinish, scenario, projectID, job }) => {
  const {
    open: showAdvanced,
    setOpen: setShowAdvanced,
    style,
  } = useMaxHeightTransition("0", "2000px", false);

  const { user, projectName, gproject, grpcURL } = useGlobalState();
  const fb = useFirebase();

  const [settingsUpdated, setSettingsUpdated] = useState(false);

  const [editedImage, setEditedImage] = useState(job.image);
  const [editedInputScenarios, setEditedInputScenarios] = useState(scenario.inputScenarios);

  const [timeInputsValid, setTimeInputsValid] = useState(true);

  const [editedRunSettings, seteditedRunSettings] = useState(
    job.runSettings || stdRunSettings
  );

  const [loading, setLoading] = useState(false);

  const updateScenario = (updated: Partial<SimulationScenario>) => {
    return fb
      .firestore()
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenario.id)
      .update(
        convertToFirestoreFormat({
          ...updated,
        })
      );
  };

  const copyRefToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = JSON.stringify({
      projectID,
      scenarioID: scenario.id || null,
      groupID: scenario.groupID,
      scenarioName: scenario.scenarioName,
      projectName,
      job: { ...job, image: editedImage },
      fs_path: `/Projects/${projectID}/Scenarios/${scenario.id}`,
    });
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Reference copied to clipboard", { icon: "success" });
  };

  const requestSimulation = async () => {
    if (!user) return;

    if (settingsUpdated || !job.runSettings) {
      await updateScenario({
        inputScenarios: editedInputScenarios,
        jobs: {
          ...scenario.jobs,
          [job.id]: {
            ...job,
            image: editedImage,
            runSettings: editedRunSettings,
          },
        },
      });
      setSettingsUpdated(false);
    }

    const idToken = await user.fbUser.getIdToken();
    // await startSimulation(projectID, scenario.id, fb, scenario.groupID); //call start simulation api
    const deployedJob = await startJob(grpcURL, idToken, {
      projectId: projectID,
      scenarioId: scenario.id,
      jobId: job.id,
      organisationId: gproject || "numerous-development",
      userId: user.id,
    });
    console.log({ deployedJob });
  };

  const fs = useFirestore();

  const analyticsLogger = useAnalyticsLogger();

  const requestSchedule = async (
    updatedFields: Partial<SimulationScenario>,
    schedule: ScheduledSimulationTask
  ) => {
    const newScheduleDoc = fs.collection("ScheduledSimulationTasks").doc();
    updatedFields.scheduled = scenario.scheduled
      ? [...scenario.scheduled, newScheduleDoc.id]
      : [newScheduleDoc.id];
    await updateScenario(updatedFields);
    schedule.id = newScheduleDoc.id;
    await newScheduleDoc.set(convertToFirestoreFormat(schedule));
  };

  const onClickStart = async () => {
    if (!timeInputsValid) {
      Toast("All inputs should start before or same time as the simulation.", {
        icon: "warning",
      });
      return;
    }

    if (!loading) {
      try {
        setLoading(true);

        if (schedule) await requestSchedule({}, schedule);
        else await requestSimulation();

        analyticsLogger(schedule ? "scheduled_simulation_set" : "simulation_started", {
          project_name: projectName,
          project_id: projectID,
          scenarioName: scenario.scenarioName,
          user_name: user?.fullName || "",
          user_id: user?.fbUser.uid || "",
        });

        setLoading(false);
        onFinish();
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        Toast(error?.msg || "Something went wrong");
        setLoading(false);
      }
    }
  };

  const [schedule, setSchedule] = useState<ScheduledSimulationTask | null>(null);
  const renderScheduleSetup = () => {
    return (
      <div className="opacity-25">
        <label className={`font-medium text-xs mt-4`}>Scheduled run</label>
        <div className="p-px mb-4">
          <ToggleButton
            active={!!schedule}
            onChange={() => {
              // if (!schedule) {
              //   let now = dayjs();
              //   const roundedMin = Math.round(now.get("minute") / 5) * 5;
              //   setSchedule({
              //     id: "",
              //     launchTime: now.set("minute", roundedMin),
              //     projectID,
              //     scenarioID: scenario.id,
              //     userID: user?.fbUser.uid,
              //     job,
              //   });
              // } else setSchedule(null);
            }}
          />
        </div>
        {schedule && <ConfigureSchedule schedule={schedule} setSchedule={setSchedule} />}
      </div>
    );
  };

  const renderAdvancedSettings = () => {
    return (
      <div className="px-4 py-2 bg-gray-50 border border-gray-100 rounded my-4 ">
        <div
          className="flex py-2 justify-between cursor-pointer"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <span className="text-xs font-bold ">Advanced settings</span>
          <OpenCloseArrow isOpen={showAdvanced} />
        </div>
        <div className="flex flex-col text-sm overflow-hidden" style={style}>
          {renderScheduleSetup()}
          <div className="text-xs">
            <div className="font-medium mt-2">Custom path</div>
            <input
              className="input-box w-full"
              type="text"
              value={editedImage.path}
              onChange={(e) => {
                setEditedImage({ ...editedImage, path: e.target.value });
                setSettingsUpdated(true);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderJobParameters = () => {
    if (editedImage.parameters.length === 0) return null;
    return (
      <div className="flex items-end flex-wrap w-full text-xs border-t border-gray-200 mt-4">
        {editedImage.parameters.map((param) => {
          return (
            <Parameter
              key={param.uuid}
              parameter={param}
              onUpdate={(param) => {
                setEditedImage({
                  ...editedImage,
                  parameters: updateArrayValUUID(editedImage.parameters, param),
                });
                setSettingsUpdated(true);
              }}
            />
          );
        })}
      </div>
    );
  };

  const [saveChangeModal, setSaveChangeModal] = useState(false);
  const renderSaveChangeOption = () => {
    return (
      <Modal zIndex={40} onClose={() => setSaveChangeModal(false)}>
        <div className="modal-content z-50 w-1/2 lg:w-1/3">
          <div className="font-medium">Save updated settings?</div>
          <div className=" mt-2 w-full">
            <button
              className="button-small  w-full mb-2"
              onClick={() => {
                const updatedJob: SimulationJob = {
                  ...job,
                  image: editedImage,
                  runSettings: editedRunSettings,
                };

                //save
                const updatedFields: Partial<SimulationScenario> = {
                  inputScenarios: editedInputScenarios,
                  jobs: { ...scenario.jobs, [job.id]: updatedJob },
                };

                setLoading(true);
                updateScenario(updatedFields)
                  .then(() => {
                    setLoading(false);
                    onFinish();
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    Toast("Error saving changes", { icon: "error" });
                  });
              }}
            >
              Save
            </button>
            <button
              className="button-small  w-full"
              onClick={() => {
                onFinish();
              }}
            >
              Close without saving
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Modal
        canOverflow
        zIndex={30}
        onClose={() => {
          if (loading) return;
          else if (settingsUpdated) setSaveChangeModal(true);
          else onFinish();
        }}
      >
        <div className={`px-8 py-4 bg-white z-40 shadow-lg rounded w-full relative`}>
          {loading && <LoadingOverlay />}
          <div className="text-xl font-medium mb-2">Start {job.name}</div>

          <div className="grid grid-flow-row grid-cols-6 gap-x-1 gap-y-1 text-xs mb-6">
            <div className="col-span-1">Scenario:</div>
            <div className="font-medium col-span-5">{scenario.scenarioName}</div>

            <div className="col-span-1">Image path:</div>
            <div className="flex items-center font-medium col-span-5">
              <div>{editedImage.path}</div>
              <ClipboardIcon
                className="h-4 w-4 ml-1 cursor-pointer flex-none"
                onClick={() => {
                  copyRefToClipboard();
                }}
              />
            </div>
            {job.image.repository && <HistorySetup image={job.image} />}
          </div>

          {job.isMain && (
            <TimeSetup
              scenario={scenario}
              inputScenarios={editedInputScenarios}
              updateInputScenarios={(updated) => {
                setEditedInputScenarios(updated);
                setSettingsUpdated(true);
              }}
              runSettings={editedRunSettings}
              updateRunSettings={(updated) => {
                seteditedRunSettings(updated);
                setSettingsUpdated(true);
              }}
              setTimeInputsValid={setTimeInputsValid}
            />
          )}
          {renderJobParameters()}
          {renderAdvancedSettings()}

          <button
            onClick={() => {
              onClickStart();
            }}
            className={`button-small w-full mt-4 ${timeInputsValid ? "" : "opacity-50"}`}
          >
            {schedule ? "Setup scheduler" : "Start"}
          </button>
        </div>
      </Modal>
      {saveChangeModal && renderSaveChangeOption()}
    </>
  );
};

export default RunJob;
