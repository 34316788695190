import { FMUComponentType } from "components/extensions/FMU/FMU";
import { ComponentType, Container, SimulationModel } from "model/datatypes";
import React from "react";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

type AppState = {
  editedSystem: SimulationModel | null;
  isDeprecated: boolean;
  systemLoaded: boolean;
  isChanged: boolean;
  editedContainers: Container[];
  editedComponentTypes: ComponentType[];
};

const initialState: AppState = {
  editedSystem: null,
  systemLoaded: false,
  isDeprecated: false,
  isChanged: false,
  editedComponentTypes: [] as ComponentType[],
  editedContainers: [] as Container[],
};

type Action =
  | { type: "SET_INITIAL_SYSTEM"; payload: SimulationModel }
  | { type: "UPDATE_SYSTEM"; payload: Partial<SimulationModel> }
  | { type: "UPDATE_CONTAINER"; payload: Container }
  | { type: "SET_CONTAINERS"; payload: Container[] }
  | { type: "UPDATE_COMPONENT_TYPE"; payload: ComponentType }
  | { type: "SET_COMPONENT_TYPES"; payload: ComponentType[] }
  | { type: "SAVED_UPDATES" }
  | { type: "SET_FMU_MODE" };

const reducer = (state: AppState, action: Action) => {
  console.log(action);
  switch (action.type) {
    case "SET_INITIAL_SYSTEM":
      const editedSystem = action.payload;
      const isDeprecated = !!(
        editedSystem &&
        (!editedSystem.componentTypes || !editedSystem.containers)
      );
      return {
        ...state,
        editedSystem,
        isChanged: false,
        systemLoaded: true,
        editedContainers: editedSystem.containers || [],
        editedComponentTypes: editedSystem.componentTypes || [],
        isDeprecated,
      };
    case "UPDATE_SYSTEM":
      const updatedSystem: SimulationModel = { ...state.editedSystem!, ...action.payload };
      return {
        ...state,
        editedSystem: updatedSystem,
        isChanged: true,
      };
    case "UPDATE_CONTAINER":
      return {
        ...state,
        editedContainers: updateArrayVal(state.editedContainers, action.payload),
        isChanged: true,
      };
    case "SET_CONTAINERS":
      return {
        ...state,
        editedContainers: action.payload,
        isChanged: true,
      };
    case "UPDATE_COMPONENT_TYPE":
      return {
        ...state,
        editedComponentTypes: updateArrayVal(state.editedComponentTypes, action.payload),
        isChanged: true,
      };
    case "SET_COMPONENT_TYPES":
      return {
        ...state,
        editedComponentTypes: action.payload,
        isChanged: true,
      };
    case "SAVED_UPDATES":
      return {
        ...state,
        isChanged: false,
      };
    case "SET_FMU_MODE":
      const fmuComp =
        state.editedComponentTypes.find((ct) => ct.id === "fmu") || FMUComponentType;
      const updatedSys: SimulationModel = {
        ...state.editedSystem!,
        modelType: "FMU",
      };
      return {
        ...state,
        editedComponentTypes: updateArrayVal(state.editedComponentTypes, fmuComp),
        editedSystem: updatedSys,
        isChanged: true,
      };
    default:
      return state;
  }
};

type AppStateContext = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};
//@ts-ignore - ignore to avoid passing a default argument.
export const store = React.createContext<AppStateContext>();
const { Provider } = store;

export const EditSystemStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
//Here's three helper hooks to make access simple:
export const useEditSystemState = () => {
  const { state } = React.useContext(store);
  return state;
};
export const useEditSystemDispatch = () => {
  const { dispatch } = React.useContext(store);
  return dispatch;
};
