import { useFirestore, useProjectTypes } from "api/useFirebase";
import { DualButton } from "components/basic/ToggleButtonGroup";
import CloseIcon from "components/basic/icons/CloseIcon";
import Modal from "components/basic/Modal";
import { FixedGroup, ProjectType, SimulationModel } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import Toast from "components/basic/Toast";
import { ParametersEditTable } from "./editSystem/componentEditor/parameters/ParametersEditTable";
import { useGlobalState } from "store";
import { Popup, PopupButtonWarning } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { removeProjectType } from "api/firestore/firestoreAPI";
import LoadingOverlay from "components/basic/LoadingOverlay";

interface Props {
  systems: SimulationModel[];
}

const ProjectTypes: React.FC<Props> = ({ systems }) => {
  const projectTypes = useProjectTypes();
  const [addingType, setAddingType] = useState(false);
  const [editingType, setEditingType] = useState<ProjectType | null>(null);

  const { user } = useGlobalState();

  const fs = useFirestore();
  const [deletingProjectTypeID, setDeletingProjectTypeID] = useState<null | string>(null);

  //render the project type as a row:
  const renderProjectType = (projectType: ProjectType) => {
    const deleting = deletingProjectTypeID === projectType.id;
    let constainsInfo = "";
    if (projectType.constrains === "groups")
      constainsInfo = `${projectType.fixedGroups?.length || 0} Groups`;
    else constainsInfo = `${projectType.systems?.length || 0} Systems`;
    return (
      <div
        data-test="projectTypeRow"
        key={projectType.id}
        className="px-4 py-2 mb-2 border border-gray-200 bg-white rounded flex cursor-pointer relative"
        onClick={() => setEditingType(projectType)}
      >
        <span className="text-xs w-1/3 px-2">{projectType.type}</span>
        <span className="text-xs w-1/3 px-2">{constainsInfo}</span>
        <div className="w-1/3 flex justify-end">
          <Popup
            align={"right"}
            useHover
            content={(closeMe) => {
              return (
                <>
                  <PopupButtonWarning
                    btnText={"Remove project type"}
                    warningHeadline="Remove project type?"
                    warningDescription="You will no longer be able to create projects of this type."
                    onConfirm={() => {
                      if (!deleting) {
                        setDeletingProjectTypeID(projectType.id);
                        removeProjectType(fs, projectType.id)
                          .then(() => {
                            setDeletingProjectTypeID(null);
                          })
                          .catch((error) => {
                            console.log(error);
                            setDeletingProjectTypeID(null);
                          });
                      }
                    }}
                  />
                </>
              );
            }}
          >
            <DotDotDotIcon />
          </Popup>
        </div>
        {deleting && <LoadingOverlay />}
      </div>
    );
  };

  return (
    <div className="px-4 py-8">
      <div className="font-medium text-lg mb-4">Project Types</div>
      <div className="mb-4">{projectTypes.map(renderProjectType)}</div>
      <button
        data-test="addProjectType"
        className="button-small"
        onClick={() => setAddingType(true)}
      >
        Add project type
      </button>
      {addingType && user && (
        <EditProjectType
          organisation={user.organisation}
          onFinish={() => setAddingType(false)}
          systems={systems}
        />
      )}
      {editingType && user && (
        <EditProjectType
          organisation={user.organisation}
          onFinish={() => {
            setEditingType(null);
          }}
          systems={systems}
          projectType={editingType}
        />
      )}
    </div>
  );
};

export default ProjectTypes;

const EditProjectType: React.FC<{
  projectType?: ProjectType;
  onFinish: () => void;
  systems: SimulationModel[];
  organisation: string;
}> = ({ projectType, onFinish, systems, organisation }) => {
  const [editedProjectType, setEditedProjectType] = useState<ProjectType>(
    projectType || {
      id: getUUID(),
      type: "",
      organisation,
      collaborators: [],
      teams: [],
      constrains: "system",
      parameters: [],
      deleted: null,
    }
  );

  const [selectedFixedGroupI, setselectedFixedGroupI] = useState(0);
  const renderFixedGroups = () => {
    const selectedGroup =
      editedProjectType.fixedGroups && editedProjectType.fixedGroups[selectedFixedGroupI];
    return (
      <>
        <div className="font-medium text-xs mt-4">Prefixed Groups</div>
        <div className="flex items-center flex-wrap -mb-px">
          {editedProjectType.fixedGroups &&
            editedProjectType.fixedGroups.map((fixedGroup, i) => {
              const beingEdited = selectedFixedGroupI === i;
              return (
                <button
                  className={`focus:outline-none border-t border-l border-r rounded-t px-2 text-xs py-1 relative ${
                    beingEdited
                      ? "border-green-numerous text-green-numerous bg-white z-20"
                      : " text-gray-500 hover:text-gray-600"
                  }`}
                  key={fixedGroup.id}
                  onClick={() => setselectedFixedGroupI(i)}
                >
                  {fixedGroup.groupName}
                </button>
              );
            })}
          <button
            className="focus:outline-none border-t border-r border-l border-gray-200 text-xs px-2 py-1 rounded-t"
            onClick={() => {
              const newGroup = {
                id: getUUID(),
                groupName: "Group",
                systems: [],
              };
              const fixedGroups = editedProjectType.fixedGroups
                ? [...editedProjectType.fixedGroups, newGroup]
                : [newGroup];
              setEditedProjectType({ ...editedProjectType, fixedGroups });
            }}
          >
            +
          </button>
        </div>
        {selectedGroup && renderEditFixedGroup(selectedGroup)}
      </>
    );
  };

  const renderEditFixedGroup = (fixedGroup: FixedGroup) => {
    return (
      <div className=" mb-6 px-3 py-2 rounded-b rounded-tr border border-green-numerous relative z-10">
        <div className="font-medium text-xs">Group Name</div>
        <input
          type="text"
          className="input-box w-full text-xs"
          value={fixedGroup.groupName}
          onChange={(e) => {
            const ug = { ...fixedGroup, groupName: e.target.value };
            const fixedGroups = updateArrayVal(editedProjectType.fixedGroups!, ug);
            setEditedProjectType({ ...editedProjectType, fixedGroups });
          }}
        />
        <SystemSelecter
          allSystems={systems}
          updateSystems={(updatedSystems) => {
            const updatedFixedGroup = { ...fixedGroup, systems: updatedSystems };

            setEditedProjectType({
              ...editedProjectType,
              fixedGroups: updateArrayVal(editedProjectType.fixedGroups!, updatedFixedGroup),
            });
          }}
          systems={fixedGroup.systems}
        />
        {fixedGroup.systems.length > 0 && (
          <div className="mt-2">
            <div className="text-xs font-medium">New scenario button text</div>
            <input
              type="text"
              className="input-box text-xs"
              value={fixedGroup.simulationName}
              onChange={(e) => {
                const ug = { ...fixedGroup, simulationName: e.target.value };
                const fixedGroups = updateArrayVal(editedProjectType.fixedGroups!, ug);
                setEditedProjectType({ ...editedProjectType, fixedGroups });
              }}
            />
          </div>
        )}
        <div className="mt-4">
          <ParametersEditTable
            componentParameters={fixedGroup.parameters || []}
            systemRef={undefined}
            onUpdateParam={(updated) => {
              const updatedParameters = fixedGroup.parameters
                ? updateArrayValUUID(fixedGroup.parameters, updated)
                : [updated];
              const updatedFixedGroup: FixedGroup = {
                ...fixedGroup,
                parameters: updatedParameters,
              };
              setEditedProjectType({
                ...editedProjectType,
                fixedGroups: updateArrayVal(editedProjectType.fixedGroups!, updatedFixedGroup),
              });
            }}
            onUpdateAllParms={(updated) => {
              const updatedFixedGroup: FixedGroup = {
                ...fixedGroup,
                parameters: updated,
              };
              setEditedProjectType({
                ...editedProjectType,
                fixedGroups: updateArrayVal(editedProjectType.fixedGroups!, updatedFixedGroup),
              });
            }}
            onDelete={(param) => {
              const updatedFixedGroup: FixedGroup = {
                ...fixedGroup,
                parameters: fixedGroup.parameters!.filter((cp) => cp.uuid !== param.uuid),
              };
              setEditedProjectType({
                ...editedProjectType,
                fixedGroups: updateArrayVal(editedProjectType.fixedGroups!, updatedFixedGroup),
              });
            }}
          />
          <button
            onClick={() => {
              setEditedProjectType({
                ...editedProjectType,
                fixedGroups: editedProjectType.fixedGroups!.filter(
                  (g) => g.id !== fixedGroup.id
                ),
              });
              setselectedFixedGroupI((p) => {
                return p === 0 ? p : p - 1;
              });
            }}
            className="focus:outline-none text-red-500 hover:text-red-600 text-xs absolute bottom-0 right-0 mb-2 mr-3"
          >
            Remove group
          </button>
        </div>
      </div>
    );
  };

  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const saveProjectType = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await fs
        .collection("ProjectTypes")
        .doc(editedProjectType.id)
        .set(convertToFirestoreFormat(editedProjectType));
      setLoading(false);
      onFinish();
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      Toast("Error saving project type", { icon: "error" });
    }
  };

  return (
    <Modal canOverflow onClose={() => onFinish()}>
      <div className="modal-content z-30 w-full">
        <div className="font-medium mb-4">Project type</div>
        <div className="text-xs font-medium">Type</div>
        <input
          data-test="projectType"
          type="text"
          className="input-box w-full text-xs"
          value={editedProjectType.type}
          onChange={(e) =>
            setEditedProjectType({ ...editedProjectType, type: e.target.value })
          }
        />
        <div className="text-xs font-medium mt-4">Description</div>
        <input
          data-test="projectTypeDescription"
          type="text"
          className="input-box w-full text-xs mb-4"
          value={editedProjectType.description}
          onChange={(e) =>
            setEditedProjectType({ ...editedProjectType, description: e.target.value })
          }
        />

        <ParametersEditTable
          componentParameters={editedProjectType.parameters || []}
          systemRef={undefined}
          onUpdateParam={(updated) => {
            const updatedParameters = editedProjectType.parameters
              ? updateArrayValUUID(editedProjectType.parameters, updated)
              : [updated];
            setEditedProjectType({
              ...editedProjectType,
              parameters: updatedParameters,
            });
          }}
          onUpdateAllParms={(updated) => {
            setEditedProjectType({
              ...editedProjectType,
              parameters: updated,
            });
          }}
          onDelete={(param) => {
            const updatedParameters = editedProjectType.parameters!.filter(
              (cp) => cp.uuid !== param.uuid
            );
            setEditedProjectType({
              ...editedProjectType,
              parameters: updatedParameters,
            });
          }}
        />

        <div className="font-medium text-xs">Contrains</div>
        <DualButton
          optionOne="Predined systems"
          optionTwo="Predefined groups"
          active={editedProjectType.constrains === "system" ? "one" : "two"}
          onClickOne={() => {
            setEditedProjectType({ ...editedProjectType, constrains: "system" });
          }}
          onClickTwo={() =>
            setEditedProjectType({ ...editedProjectType, constrains: "groups" })
          }
        />
        {editedProjectType.constrains === "system" && (
          <>
            <SystemSelecter
              allSystems={systems}
              updateSystems={(updatedSystems) => {
                setEditedProjectType({ ...editedProjectType, systems: updatedSystems });
              }}
              systems={editedProjectType.systems}
            />
            {editedProjectType.systems && editedProjectType.systems.length > 0 && (
              <div>
                <div className="text-xs font-medium">New scenario button text</div>
                <input
                  type="text"
                  className="input-box w-full text-xs"
                  value={editedProjectType.simulationName}
                  onChange={(e) => {
                    setEditedProjectType({
                      ...editedProjectType,
                      simulationName: e.target.value,
                    });
                  }}
                />
              </div>
            )}
          </>
        )}
        {editedProjectType.constrains === "groups" && renderFixedGroups()}
        <div className="flex mt-8">
          <button
            data-test="saveProjectType"
            className="button-small flex-1 mr-2"
            onClick={() => {
              saveProjectType();
            }}
          >
            Save
          </button>
          <button className="button-small flex-1 ml-2" onClick={() => onFinish()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const SystemSelecter: React.FC<{
  allSystems: SimulationModel[];
  updateSystems: (updatedSystems: ProjectType["systems"]) => void;
  systems: ProjectType["systems"];
}> = ({ systems, updateSystems, allSystems }) => {
  const addableSystems = useMemo(
    () => allSystems.filter((s) => !systems?.some((sid) => sid === s.id)),
    [systems, allSystems]
  );
  return (
    <>
      <div className="mt-4 text-xs font-medium">Systems allowed</div>
      <div className="w-full flex flex-wrap text-xs">
        {systems && systems.length > 0 ? (
          systems.map((systemID) => {
            const fullSystem = allSystems.find((s) => s.id === systemID);
            return (
              <div
                key={systemID}
                className="px-2 py-1 bg-gray-200 rounded mr-2 mb-2 flex items-center"
              >
                <span className="mr-2">{fullSystem?.displayName || "error system"}</span>
                <CloseIcon
                  className="w-4"
                  onClick={() => {
                    updateSystems(systems.filter((s) => s !== systemID));
                  }}
                />
              </div>
            );
          })
        ) : (
          <div className="italic">All systems</div>
        )}
      </div>
      <div className="flex w-full overflow-y-auto py-2 scrollbar-light">
        {addableSystems.map((sys) => {
          return (
            <button
              data-test="allowedSystems"
              key={sys.id}
              className="button-small flex-none mr-2"
              onClick={() => {
                const systemRef = sys.id;
                const updatedSystems = systems ? [...systems, systemRef] : [systemRef];
                updateSystems(updatedSystems);
              }}
            >
              + {sys.displayName}
            </button>
          );
        })}
      </div>
    </>
  );
};
