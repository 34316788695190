import React, { useContext, useEffect, useMemo, useRef } from "react";
import "./index.css";
import useAuth from "./api/useAuth";
import Login from "./components/login/Login";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import FirebaseProvider from "api/FirebaseProvider";
import HomePage from "components/HomePage";
import OrganisationPage from "components/OrganisationPage";
import SimulationPage from "components/SimulationsPage";
import { StateProvider, store, useGlobalDispatch, useGlobalState } from "store";
import AdminPages from "components/AdminPage";
import SignUpPage from "components/SignUpPage";
import Sidebar from "components/sidebar/Sidebar";
import { useFirebase } from "api/useFirebase";
import { ReportViewPage } from "components/ReportViewPage";
import APIPage from "components/APIPage";
import Comments from "components/comments/Collaboration";
import TutorialCard from "components/onboarding/TutorialCard";

const App: React.FC = () => {
  return (
    <StateProvider>
      <FirebaseProvider>
        <BrowserRouter>
          <LoginWall>
            <TheApp />
          </LoginWall>
        </BrowserRouter>
      </FirebaseProvider>
    </StateProvider>
  );
};

//Log into main and try to log into ORG:
const LoginWall: React.FC = ({ children }) => {
  useLocalStorageCache();
  useCheckIfDevMode();
  useAuth();
  const { user } = useGlobalState();
  const fb = useFirebase(); //check firebase is actually ready!
  const isReady = useMemo(() => !!user && !!fb, [user, fb]);

  return (
    <>
      {!isReady ? (
        <div className="relative">
          <Switch>
            <Route path="/signup/:gprojectID/:inviteID" component={SignUpPage} />
            <Route component={Login} />
          </Switch>
        </div>
      ) : (
        children
      )}
    </>
  );
};

const TheApp: React.FC = () => {
  //make sure global state resets when navigating away from simulation page:
  const history = useHistory();
  const dispatch = useGlobalDispatch();
  const { sidebarState, user } = useGlobalState();

  //on page change cleanup:
  useEffect(() => {
    const unListen = history.listen((location) => {
      const page = location.pathname.split("/")[1];
      // console.log({ location, page });
      if (page !== "simulations") {
        dispatch({ type: "SET_ACTIVE_SCENARIO", payload: undefined });
      }
    });
    return unListen;
  }, [history, dispatch]);

  useEffect(() => {
    //@ts-ignore
    if (window.Cypress) {
      //@ts-ignore
      window.appHistory = history;
    }
  }, [history]);

  return (
    <div
      className={`w-screen min-h-screen relative bg-gray-bg text-gray-800 ${
        sidebarState === "wide" ? "pl-52" : "pl-20"
      }`}
    >
      <Sidebar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/systems" component={AdminPages} />
        <Route path="/files" component={AdminPages} />
        <Route path="/api" component={APIPage} />
        <Route path="/administration" component={OrganisationPage} />
        <Route
          path="/simulations/:projectName?/:projectID?/:groupID?/:scenarioID?"
          component={SimulationPage}
        />
        <Route
          exact
          path="/reports/:projectID/:scenarioID/:fileName"
          component={ReportViewPage}
        />
      </Switch>
      <Comments />
      {user && !user?.hasCompletedTutorial && <TutorialCard />}
    </div>
  );
};

export default App;

const useLocalStorageCache = () => {
  const cachedProjectSet = useRef(false);

  const { dispatch } = useContext(store);
  useEffect(() => {
    const cachedProjectID = localStorage.getItem("selectedProjectID");
    const cachedProjectName = localStorage.getItem("selectedProjectName");

    if (cachedProjectID && cachedProjectName && !cachedProjectSet.current) {
      dispatch({
        type: "SET_CURRENT_PROJECT",
        payload: {
          projectID: cachedProjectID,
          projectName: cachedProjectName,
        },
      });
      cachedProjectSet.current = true;
    }
  }, [dispatch]);
};

const useCheckIfDevMode = () => {
  const { dispatch } = useContext(store);
  useEffect(() => {
    const isLocalHost = window.location.host.match("localhost") !== null;
    if (isLocalHost)
      dispatch({
        type: "SET_IN_DEV_MODE",
      });
  }, [dispatch]);
};
