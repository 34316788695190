import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import {
  Component,
  ComponentType,
  ScenarioStateEdited,
  SimulationModel,
} from "model/datatypes";
import { useEffect, useState } from "react";
// import { useGlobalState } from "store";
import {
  CMConnectionInstanceEditor,
  CMConnectionTypeEditor,
  DefaultCMConnectionType,
} from "./ControlMachines/CMConnectionComponent";
import {
  BHSExtensionComponent,
  DefaultBHSType,
  BHSExtensionCompTypeEditor,
} from "./EMWebconfigurator/EnergySystem/bhs/BHSCompExtension";
import { FMUCompTypeEditor, FMUComponentType, FMUInstanceEditor } from "./FMU/FMU";

export type ExtensionCompTypeProps = {
  componentType: ComponentType;
  removeComponent: (componentID: string) => void;
  onMove?: ((dir: "up" | "down") => void) | undefined;
  maxOrder?: number;
};

export type ExtensionCompProps = {
  component: Component;
  updateComponent: (updated: Component) => void;
  scenarioState: ScenarioStateEdited;
  scenarioDispatch: (value: NewScenarioAction) => void;
  model: SimulationModel;
};

export type ExtensionComp = {
  type: ComponentType;
  typeEditor: React.FC<ExtensionCompTypeProps>;
  instanceEditor: React.FC<ExtensionCompProps>;
};

const useExtensionComponents = () => {
  // const { organisationID } = useGlobalState();

  const [extensionComponents, setExtensionComponents] = useState<ExtensionComp[]>([]);

  useEffect(() => {
    // if(organisationID !== "EM") {
    //   setExtensionComponents([])
    //   return
    // }

    //TODO implement logic for who can see the extensions

    const extensions: ExtensionComp[] = [
      {
        type: DefaultCMConnectionType,
        typeEditor: CMConnectionTypeEditor,
        instanceEditor: CMConnectionInstanceEditor,
      },
      {
        type: DefaultBHSType,
        typeEditor: BHSExtensionCompTypeEditor,
        instanceEditor: BHSExtensionComponent,
      },
      {
        type: FMUComponentType,
        typeEditor: FMUCompTypeEditor,
        instanceEditor: FMUInstanceEditor,
      },
    ];
    setExtensionComponents(extensions);
  }, []);
  return extensionComponents;
};

export default useExtensionComponents;
