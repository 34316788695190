import { Team } from "model/datatypes";
import React, { useState } from "react";
import { Popup } from "../basic/Popup";
import LoadingOverlay from "../basic/LoadingOverlay";
import EditTeam from "./EditTeam";
import { useFirebase, useTeamCollaborators } from "api/useFirebase";
import HoverTooltip from "components/basic/HoverTooltip";
import { useUserRole } from "api/useAuth";
import { fsFieldvalue } from "utils/firebase/helpers";

const TeamCard: React.FC<{
  team: Team;
  i: number;
}> = ({ team, i }) => {
  const [loading, setLoading] = useState(false);
  const [editingTeam, setEditingTeam] = useState(false);
  const fb = useFirebase();
  const { hasDeveloperAccess } = useUserRole();

  const collaborators = useTeamCollaborators(team);

  const deleteTeam = async (teamID: string) => {
    if (teamID && !loading) {
      try {
        setLoading(true);
        const batch = fb.firestore().batch();
        const projectCol = fb.firestore().collection("Projects");
        const projectsWithTeam = await projectCol
          .where("teams", "array-contains", teamID)
          .get();
        projectsWithTeam.docs.forEach((doc) => {
          batch.update(projectCol.doc(doc.id), {
            teams: fsFieldvalue.arrayRemove(teamID),
          });
        });
        batch.delete(fb.firestore().collection("teams").doc(teamID));
        await batch.commit();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(true);
      }
    }
  };

  const renderTeamOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={i % 3 === 2 ? "right" : "left"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={() => {
                setEditingTeam(true);
                closeMe();
              }}
            >
              Edit team
            </button>
            {renderDeleteOption(closeMe)}
          </div>
        )}
      >
        {hasDeveloperAccess && (
          <button className="relative focus:outline-none flex justify-center items-center">
            <DotDotDotIcon />
          </button>
        )}
      </Popup>
    );
  };

  const renderDeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete team?</div>
              <div className="italic mb-2">
                Are you sure you would like to delete this team?
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteTeam(team.id);
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`button-popup text-red-500 border-t border-gray-400`}>
          Delete team
        </button>
      </Popup>
    );
  };

  return (
    <>
      <div className="w-1/3 px-4 pb-8 relative">
        <div
          className={`${tw.card} px-4 py-3 h-32 cursor-pointer overflow-hidden`}
          onClick={() => setEditingTeam(true)}
        >
          <div data-test="existingTeamName" className="font-medium">
            {team.name}
          </div>
          <hr className="border-t border-gray-400 my-3" />
          <div className="text-xs flex flex-wrap">
            {collaborators.map((collaborator) => {
              return (
                <HoverTooltip key={collaborator.id} text={collaborator.fullName} mt={-25}>
                  <div className="mr-1 mb-1 h-8 w-8 flex items-center justify-center uppercase bg-green-numerous font-bold text-white rounded-full">
                    {collaborator.firstName && collaborator.lastName
                      ? collaborator.firstName.slice(0, 1) + collaborator.lastName.slice(0, 1)
                      : "N/A"}
                  </div>
                </HoverTooltip>
              );
            })}
          </div>
        </div>
        <div className="absolute top-0 right-0 mt-2 mr-6">{renderTeamOptions()}</div>
      </div>
      {loading && <LoadingOverlay />}
      {editingTeam && <EditTeam team={team} onFinish={() => setEditingTeam(false)} />}
    </>
  );
};

export default TeamCard;

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};

const DotDotDotIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className ? className : ""}
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 24 24"
      width="18"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      />
    </svg>
  );
};
