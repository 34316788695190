import { Fragment, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";

export type PopupMenuOption = {
  id: string;
  title: string;
  description: string;
  val?: any;
  value?: any;
};

interface Props {
  className?: string;
  options: PopupMenuOption[];
  buttonText: string;
  selectedOptions?: string[];
  emptyMsg?: string;
  onSelect: (selected: PopupMenuOption) => void;
  flipped?: true;
  closeOnClick?: true;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const PopupMenu: React.FC<Props> = ({
  className,
  options,
  selectedOptions,
  buttonText,
  onSelect,
  emptyMsg,
  flipped,
  closeOnClick,
}) => {
  const hackyClosePopup = () => {
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    btnRef.current?.dispatchEvent(clickEvent);
  };

  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div className="relative">
            <Popover.Button
              ref={btnRef}
              className="bg-white relative border border-gray-200 rounded shadow-sm px-3 py-1 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 hover:font-medium text-xs"
            >
              {buttonText}
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className={`absolute z-10 ${
                  flipped ? "bottom-0 mb-8" : "mt-2"
                } left-0  w-screen max-w-xs bg-white divide-y rounded border border-gray-200 shadow-xl overflow-hidden`}
              >
                {options.map((option) => {
                  const selected = selectedOptions?.some((id) => id === option.id);
                  return (
                    <div
                      key={option.id}
                      className={classNames(
                        selected ? "cursor-default" : "cursor-pointer",
                        "hover:text-white hover:bg-indigo-500 text-gray-900",
                        "select-none relative p-4 text-sm"
                      )}
                      onClick={() => {
                        onSelect(option);
                        closeOnClick && hackyClosePopup();
                      }}
                    >
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={"font-medium"}>{option.title}</p>
                          {selected ? (
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          ) : null}
                        </div>
                        <p className={"mt-2"}>{option.description}</p>
                      </div>
                    </div>
                  );
                })}
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default PopupMenu;

export const PopupOnClick: React.FC<{
  options: PopupMenuOption[];
  selectedOptions?: string[];
  onSelect: (selected: PopupMenuOption) => void;
}> = ({ options, selectedOptions, onSelect, children }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div className="relative">
            <Popover.Button className="focus:outline-none">{children}</Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="absolute z-10 left-0 mt-2  w-screen max-w-xs bg-white divide-y rounded border border-gray-200 shadow-xl overflow-hidden"
              >
                {options.map((option) => {
                  const selected = selectedOptions?.some((id) => id === option.id);
                  return (
                    <div
                      key={option.id}
                      className={classNames(
                        selected ? "cursor-default" : "cursor-pointer",
                        "hover:text-white hover:bg-indigo-500 text-gray-900",
                        "select-none relative p-4 text-sm"
                      )}
                      onClick={() => onSelect(option)}
                    >
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={"font-medium"}>{option.title}</p>
                          {selected ? (
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          ) : null}
                        </div>
                        <p className={"mt-2"}>{option.description}</p>
                      </div>
                    </div>
                  );
                })}
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};
