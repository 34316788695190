export const parseInput = (point: string) => {
  if (point.includes(",")) point = point.replace(",", ".");

  const num = parseFloat(point);
  if (!Number.isNaN(num)) return num;
  //add checks for infinite/other stuff.....

  //if NaN return original string:
  return point;
};
