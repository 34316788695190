import IFrameExpansion from "components/extensions/iFrameExpansion/IFrameExpansion";
import { SimulationScenario } from "model/datatypes";
import React, { useState } from "react";

interface Props {
  scenario: SimulationScenario;
  projectID: string;
}

const Extension = ({ scenario, projectID }: Props) => {
  const [expansionSelected, setExpansionSelected] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("http://localhost:3001");
  const [customURL, setCustomURL] = useState("http://localhost:3001");

  const renderNumerousReportOption = () => {
    return (
      <div className="border border-gray-200 shadow rounded-md flex mb-4 xl:w-1/2">
        <div className="px-4 py-2 flex-grow">
          <div className="text-xs font-medium">Numerous report generator</div>
        </div>
        <button
          className={`focus:outline-none border-l border-gray-200 text-sm w-32`}
          onClick={() => {
            setIframeUrl("https://numerous-reports.vercel.app/");
            setExpansionSelected(true);
          }}
        >
          Open
        </button>
      </div>
    );
  };

  const renderCustomURLOption = () => {
    return (
      <div className="border border-gray-200 shadow rounded-md flex xl:w-1/2">
        <div className="px-4 py-2 flex-grow">
          <div className="text-xs font-medium">Custom Url</div>
          <input
            className={`input-box text-xs w-full mb-2`}
            value={customURL}
            onChange={(e) => {
              setCustomURL(e.target.value);
            }}
          />
        </div>
        <button
          className={`focus:outline-none border-l border-gray-200 text-sm w-32`}
          onClick={() => {
            setIframeUrl(customURL);
            setExpansionSelected(true);
          }}
        >
          Open
        </button>
      </div>
    );
  };

  if (!expansionSelected)
    return (
      <div className="px-8 py-4">
        <div className="font-medium mb-2">Select Expansion</div>
        {renderNumerousReportOption()}
        {renderCustomURLOption()}
      </div>
    );
  else
    return <IFrameExpansion scenario={scenario} projectID={projectID} iframeURL={iframeUrl} />;
};

export default Extension;
