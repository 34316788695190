import React, { useState } from "react";
import { Popup } from "components/basic/Popup";
import EditProject from "components/projects/EditProject";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { Project } from "model/datatypes";

interface Props {
  project: Project | null;
}

const ProjectInfo = ({ project }: Props) => {
  const [editingProject, setEditingProject] = useState(false);

  return (
    <>
      <div className="flex items-center">
        <div data-test="projectName" className={`font-bold text-xl mr-2 text-gray-700`}>
          {project?.projectName}
        </div>
        <Popup
          useHover
          mt={15}
          content={(closeMe) => (
            <div className="text-xs">
              <button
                className={`button-popup`}
                onClick={() => {
                  setEditingProject(true);
                  closeMe();
                }}
              >
                Edit project
              </button>
            </div>
          )}
        >
          <button className="relative focus:outline-none flex justify-center items-center">
            <DotDotDotIcon />
          </button>
        </Popup>
      </div>

      <div className="text-sm mb-4" data-test="project_description">{project?.description}</div>
      {editingProject && project && (
        <EditProject project={project} onFinish={() => setEditingProject(false)} />
      )}
    </>
  );
};

export default ProjectInfo;
