import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { createPortal } from "react-dom";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import { usePagePosition } from "utils/hooks/usePagePosition";

interface Props {
  unit: string;
  value: number | null;
  placeholder?: string;
  onChange: (v: number) => void;
  showButtons?: boolean;
  onlyPositive?: boolean;
  incrementBy?: number;
  className?: string;
  onEnter?: () => void;
}

export const InputNum: React.FC<Props> = ({
  unit,
  value,
  onChange,
  showButtons,
  onlyPositive,
  placeholder,
  className,
  incrementBy = 1,
  onEnter,
}) => {
  const [displayVal, setdisplayVal] = useState<string>(
    typeof value === "number" ? value.toString() : ""
  );

  useEffect(() => {
    if (value) setdisplayVal(value.toString());
  }, [value]);

  return (
    <div
      className={`flex items-center justify-between px-1
    ${className ? className : ""}`}
    >
      <div className="flex flex-col h-6 w-6 text-xs">
        {showButtons && (
          <>
            <svg
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                let newValue = value ? value + incrementBy : 1;
                newValue = parseFloat(newValue.toFixed(2));
                onChange(newValue);
                setdisplayVal(newValue.toString());
              }}
              width="24px"
              fill="currentColor"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.57804987,13.6200909 L10.6278453,8.57029543 C11.3882393,7.80990152 12.6165679,7.80990152 13.3769618,8.57029543 L18.4267573,13.6200909 C19.6550859,14.8484195 18.7777083,16.9541258 17.0424504,16.9541258 L6.94285946,16.9541258 C5.20760155,16.9541258 4.34972124,14.8484195 5.57804987,13.6200909 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
            </svg>

            <svg
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                let newValue = value ? value - incrementBy : 0;
                newValue = parseFloat(newValue.toFixed(2));
                onChange(newValue);
                setdisplayVal(newValue < 0 && onlyPositive ? "0" : newValue.toString());
              }}
              width="24px"
              fill="currentColor"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.57804987,13.6200909 L10.6278453,8.57029543 C11.3882393,7.80990152 12.6165679,7.80990152 13.3769618,8.57029543 L18.4267573,13.6200909 C19.6550859,14.8484195 18.7777083,16.9541258 17.0424504,16.9541258 L6.94285946,16.9541258 C5.20760155,16.9541258 4.34972124,14.8484195 5.57804987,13.6200909 Z"
                id="Shape"
                fillRule="nonzero"
                transform="translate(12.000000, 12.477063) scale(1, -1) translate(-12.000000, -12.477063) "
              ></path>
            </svg>
          </>
        )}
      </div>
      <div className="flex-grow flex items-center justify-end py-1">
        <input
          className="w-full bg-transparent text-right focus:outline-none"
          type="text"
          value={displayVal}
          placeholder={placeholder}
          onChange={(e) => {
            let val = parseFloat(e.target.value);
            val = parseFloat(val.toFixed(2));
            onlyPositive && (val = Math.abs(val));
            onChange(val);
            const displayVal = e.target.value.replace(/[^\d|.|-]/, "");
            setdisplayVal(displayVal);
          }}
          onKeyDown={(e) => e.key === "Enter" && onEnter && onEnter()}
        />
        <div>{unit}</div>
      </div>
    </div>
  );
};

interface InputTextProps {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (v: string) => void;
  onEnter?: () => void;
  readOnly?: true;
}
export const InputText: React.FC<InputTextProps> = ({
  value,
  onChange,
  placeholder,
  className,
  onEnter,
  readOnly,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);
  const closeExpanded = useCallback(() => {
    setExpanded(false);
  }, []);

  const [expanded, setExpanded] = useState(false);
  const hasOverflow = useMemo(() => {
    const textWidth = hiddenTextRef.current?.clientWidth;
    const width = ref.current?.offsetWidth;
    if (width && textWidth && value.length > 0) return width - 20 < textWidth;
    else return false;
  }, [value]);

  const popupRef = useRef<HTMLDivElement>(null);

  useClickOutsideEffect([ref, popupRef], closeExpanded);

  const { pagePosition, updatePosition } = usePagePosition(ref);

  useEffect(() => {
    document.addEventListener("scroll", updatePosition);
    return () => {
      document.removeEventListener("scroll", updatePosition);
    };
  }, [updatePosition]);

  return (
    <div className="relative w-full" ref={ref}>
      {hasOverflow && (
        <FullScreenIcon
          onClick={() => {
            setExpanded(true);
          }}
          className="absolute top-0 right-0 mt-1 opacity-25 hover:opacity-100 hover:cursor-pointer z-10"
        />
      )}

      {expanded && (
        <RootPortal>
          <div ref={popupRef}>
            <textarea
              style={{
                marginTop: pagePosition.distanceTop,
                marginLeft: pagePosition.distanceLeft,
                zIndex: 1000,
              }}
              className={`input-box text-xs absolute top-0 left-0 w-64 h-20 z-20`}
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setExpanded(false);
                }
              }}
            />
          </div>
        </RootPortal>
      )}
      <input
        className={`relative w-full focus:outline-none ${className || ""}`}
        type="text"
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && onEnter && onEnter()}
      ></input>

      {/* Hidden input box for getting width */}
      <div
        className="text-xs absolute whitespace-no-wrap"
        style={{ visibility: "hidden" }}
        ref={hiddenTextRef}
      >
        {value}
      </div>
    </div>
  );
};

const FullScreenIcon: React.FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => (
  <svg
    className={className || ""}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
  </svg>
);

const RootPortal: React.FC = ({ children }) => {
  const appRoot = document.getElementById("root");
  return appRoot && createPortal(children, appRoot);
};

export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  ...htmlProps
}) => {
  return (
    <input
      {...htmlProps}
      className={`px-2 py-1 outline-none border rounded focus:ring focus:ring-blue-400 ${
        className || ""
      }`}
    />
  );
};
