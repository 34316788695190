import React from "react";

interface Props {
  optionOne: string;
  onClickOne: () => void;
  optionTwo: string;
  onClickTwo: () => void;
  active?: "one" | "two";
}

export const DualButton: React.FC<Props> = ({
  active,
  onClickOne,
  onClickTwo,
  optionOne,
  optionTwo,
}) => {
  return (
    <div style={{ padding: "2px" }} className="flex rounded-full bg-gray-200 w-64">
      <button
        className={` text-xs focus:outline-none w-1/2 flex-none py-px px-2 overflow-hidden ${
          active === "one" ? "bg-white shadow-md rounded-full" : ""
        }
        ${active === undefined ? "border-l" : ""}`}
        onClick={() => {
          onClickOne();
        }}
      >
        {optionOne}
      </button>
      <button
        className={`text-xs focus:outline-none w-1/2 flex-none py-px px-2  ${
          active === "two" ? "bg-white shadow-md rounded-full" : ""
        }`}
        onClick={() => {
          onClickTwo();
        }}
      >
        {optionTwo}
      </button>
    </div>
  );
};

type Option = {
  id: string;
  display: string;
};

export const MultiToggleButton: React.FC<{
  active?: string;
  options: Option[];
  onClick: (option: Option) => void;
}> = ({ active, options, onClick }) => {
  return (
    <div
      style={{ padding: "2px", width: `${8 * options.length}rem` }}
      className="flex rounded-full bg-gray-200"
    >
      {options.map((option) => {
        return (
          <button
            key={option.id}
            className={` text-xs focus:outline-none flex-1 py-px px-2 overflow-hidden ${
              active === option.id ? "bg-white shadow-md rounded-full" : ""
            }`}
            onClick={() => {
              onClick(option);
            }}
          >
            {option.display}
          </button>
        );
      })}
    </div>
  );
};
