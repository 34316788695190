import React from "react";

interface Props {
  className?: string;
}

const HeatpumpIcon = (props: Props) => {
  return (
    <svg
      className={props.className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="450"
      height="450"
      fill="none"
      viewBox="0 0 450 450"
    >
      <path fill="#fff" d="M0 0H450V450H0z"></path>
      <g stroke="#000" strokeWidth="14" filter="url(#filter0_d)">
        <path
          fill="#5B5B5B"
          d="M167.143 236.67v131.544c0 1.713 1.855 2.781 3.337 1.922l186.531-108.188a6 6 0 002.989-5.19V131.773c0-4.624-5.01-7.51-9.01-5.19L170.133 231.48a6 6 0 00-2.99 5.19z"
        ></path>
        <path d="M167.698 370.143L92.899 324.99A6 6 0 0190 319.853V193.759a6 6 0 012.93-5.155L277.244 78.855a6 6 0 016.207.041l73.686 45.205a6 6 0 012.863 5.114v24.002"></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="324"
          height="346.439"
          x="63"
          y="52.011"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default HeatpumpIcon;
