const gtw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow w-full",
  label: "text-xs font-medium",
  smallBtn:
    "bg-white py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  activeBtn: "bg-gray-700 border-gray-700 text-white font-medium",
  input: "px-2 py-1 focus:outline-none border rounded",
  popupBtn: "py-1 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
};
export default gtw;
