import { useFirestore } from "api/useFirebase";
import CrossIcon from "components/basic/icons/CrossIcon";
import StudentHat from "components/basic/icons/StudentHat";
import LoadingOverlay from "components/basic/LoadingOverlay";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "store";

type TutorialInformation = {
  step: number;
  header: string;
  instructions: string[];
};

const tutorialInformation: TutorialInformation[] = [
  {
    step: 1,
    header: "Create a system",
    instructions: [
      "Go to systems page",
      "Click ‘Add new system’",
      "Add model display name",
      "Click ‘Add model’",
    ],
  },
  {
    step: 2,
    header: "Upload your FMU",
    instructions: [
      "Go to System Components section",
      "Click ‘Add component type’",
      "Choose ‘FMU’ component",
      "Add the FMU file (drag and drop  or upload it from your computer)",
      "Click ‘Add to the system’",
      "Change component parameters if needed",
      "Save system changes",
    ],
  },
  {
    step: 3,
    header: "Create a project",
    instructions: [
      "Go to project page",
      "'Click 'Add new Project'",
      "Add project name",
      "Click ‘Add project’",
    ],
  },
  {
    step: 4,
    header: "Create a group",
    instructions: ["Click ‘New group’", "Add group name", "Click ‘Save’"],
  },
  {
    step: 5,
    header: "Create a scenario",
    instructions: [
      "Click ‘New scenario’",
      "Add scenario name",
      "Choose Simulation System",
      "Click ‘Ok’",
    ],
  },
  {
    step: 6,
    header: "Simulate",
    instructions: [
      "Find the scenario you created",
      "Click the play button above the word Simulate",
      "Set up the duration and resolution",
      "Click 'Start'",
    ],
  },
  {
    step: 7,
    header: "Generate a report",
    instructions: [
      "Find the scenario you simulated",
      "Click the play button above the word ‘Report’",
      "Set up the report",
      "Click ‘Start’",
    ],
  },
  {
    step: 8,
    header: "Congrats",
    instructions: [
      "You have accomplished all the steps and your simulation report will be ready soon.",
    ],
  },
];

const TutorialCard = () => {
  const fs = useFirestore();
  const { user } = useGlobalState();

  const [loading, setLoading] = useState(false);
  const [tutorialOpen, setTutorialOpen] = useState(true);

  const [currentTutorialStep, setCurrentTutorialStep] = useState(1);
  useEffect(() => {
    const storedStep = localStorage.getItem("step");
    if (storedStep) setCurrentTutorialStep(parseInt(storedStep));
  }, []);

  useEffect(() => {
    return localStorage.setItem("step", `${currentTutorialStep}`);
  }, [currentTutorialStep]);

  const handleNextStep = () => {
    if (currentTutorialStep === tutorialInformation.length) {
      setLoading(true);
      fs.collection("users")
        .doc(user?.fbUser.uid)
        .update({ hasCompletedTutorial: true })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setCurrentTutorialStep((prevStep) => prevStep + 1);
    }
  };

  const skipTutorial = () => {
    setLoading(true);
    fs.collection("users")
      .doc(user?.fbUser.uid)
      .update({ hasCompletedTutorial: true })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const toggleTutorialOpen = () => {
    setTutorialOpen((tutorialOpen) => !tutorialOpen);
  };

  const renderInstruction = (instruction: string, i: number) => {
    return (
      <div key={i} className="flex text-xs items-center mt-3">
        {currentTutorialStep !== tutorialInformation.length ? (
          <div
            style={{ height: "4px", width: "4px" }}
            className="bg-blue-600 rounded-full mr-3"
          ></div>
        ) : null}
        <div className="">{instruction}</div>
      </div>
    );
  };

  const renderTutorialInfo = (info: TutorialInformation) => {
    return info.step === currentTutorialStep ? (
      <div key={info.step} className="px-6 py-5">
        <div className="flex justify-between items-center mb–1 xl:mb-2">
          <div className=" text-blue-600 font-bold text-sm uppercase">{info.header}</div>
          <div className="text-xs text-blue-600 font-medium">
            Step {currentTutorialStep} of {tutorialInformation.length}
          </div>
        </div>
        <div className="flex flex-col">{info.instructions.map(renderInstruction)}</div>
      </div>
    ) : null;
  };

  const renderTutorialHeader = () => {
    return currentTutorialStep === 1 ? (
      <div className="h-24 mx-auto w-full p-6 rounded-t-xl bg-blue-600 text-white">
        <>
          <div className="text-lg font-medium">Welcome to numerous</div>
          <div className="mt-1 text-xs">
            Follow the steps below to set up and run your first simulation
          </div>
        </>
      </div>
    ) : (
      <div className="h-8 mx-auto w-full p-5 flex justify-between items-center rounded-t-xl bg-blue-600 text-white">
        <StudentHat />
        <button onClick={() => skipTutorial()} className="text-xxs font-medium">
          SKIP INTRO
        </button>
      </div>
    );
  };

  return (
    <div className="bottom-4 right-14 fixed z-30">
      {tutorialOpen ? (
        <div className="bg-white transform translate-x-12 rounded-xl shadow-2xl xl:w-96 w-80 place-content-center pb-6">
          {renderTutorialHeader()}
          {tutorialInformation.map(renderTutorialInfo)}
          <div className=" flex justify-end mr-6">
            {currentTutorialStep !== 1 ? (
              <button
                onClick={() => {
                  setCurrentTutorialStep((prevStep) => prevStep - 1);
                }}
                className="text-blue-600 font-medium text-sm xl:text-md xl:px-8 px-6"
              >
                Back
              </button>
            ) : null}
            <button
              onClick={() => handleNextStep()}
              className="bg-blue-600 text-white rounded-xl text-sm xl:text-md shadow-md py-2 xl:px-8 px-6 font-medium "
            >
              {currentTutorialStep === tutorialInformation.length ? "Finish" : "Next"}
            </button>
          </div>
          {loading && <LoadingOverlay />}
        </div>
      ) : null}
      <div className="flex justify-end w-full">
        <button
          style={{ height: "40px", width: "40px" }}
          onClick={() => toggleTutorialOpen()}
          className="bg-blue-600 flex p-2 rounded-full mt-1 transform shadow-xl items-center justify-center"
        >
          {tutorialOpen ? (
            <CrossIcon strokeColor="#fff" strokeWidth="3" width="16" height="16" />
          ) : (
            <StudentHat />
          )}
        </button>
      </div>
    </div>
  );
};

export default TutorialCard;
