import React from "react";

interface Props {
  className?: string;
}

const PVPanelsIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="450"
      height="450"
      fill="none"
      viewBox="0 0 450 450"
    >
      <path fill="#fff" d="M0 0H450V450H0z"></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="16"
        d="M63 186.043h15.38M93.76 93.76l15.381 15.381M186.043 63v15.368M262.945 93.76l-15.38 15.381"
      ></path>
      <mask id="path-5-inside-1" fill="#fff">
        <path d="M232.185 130.171a72.162 72.162 0 00-51.024-21.03 72.158 72.158 0 00-50.966 21.169 71.993 71.993 0 00-21.054 50.966 71.998 71.998 0 0021.193 50.909"></path>
      </mask>
      <path
        fill="#3C3C3C"
        d="M219.481 142.923c7.043 7.016 18.44 6.994 25.456-.049 7.016-7.043 6.994-18.44-.049-25.456l-25.407 25.505zM117.63 244.937c7.043 7.016 18.44 6.994 25.456-.049 7.016-7.043 6.994-18.44-.049-25.456l-25.407 25.505zm127.258-127.519a90.164 90.164 0 00-63.752-26.277l.049 36a54.162 54.162 0 0138.296 15.782l25.407-25.505zm-63.752-26.277a90.16 90.16 0 00-63.68 26.451l25.477 25.435a54.163 54.163 0 0138.252-15.886l-.049-36zm-63.68 26.451a89.998 89.998 0 00-26.315 63.709l36-.05a54 54 0 0115.792-38.224l-25.477-25.435zm-26.315 63.709a89.997 89.997 0 0026.489 63.636l25.407-25.505a53.995 53.995 0 01-15.896-38.181l-36 .05z"
        mask="url(#path-5-inside-1)"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="16"
        d="M93.76 262.945l15.381-15.38"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
        d="M386 347.665l-47.938-135.328H204.111l-47.939 135.328H386z"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M242.184 347.665v40.139M300.428 387.804v-40.139"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10"
        d="M271.071 212.337v135.328M183.916 269.681h174.32"
      ></path>
    </svg>
  );
};

export default PVPanelsIcon;
