import { useUserRole } from "api/useAuth";
import { useAllUsers, useTeamCollaborators, useTeams } from "api/useFirebase";
import HoverTooltip from "components/basic/HoverTooltip";
import CrossIcon from "components/basic/icons/CrossIcon";
import gtw from "gtw";
import { Team } from "model/datatypes";
import React, { useMemo, useState } from "react";
import AddCollaborator from "./AddCollaborator";
import AddTeam from "./AddTeam";

const EditCollaborators: React.FC<{
  collaborators: string[];
  teamIds: string[];
  allowEditing: boolean;
  updateTeams: (updatedTeams: string[]) => Promise<void>;
  updateCollaborators: (updatedCollaborators: string[]) => Promise<void>;
}> = ({ allowEditing, collaborators, teamIds, updateCollaborators, updateTeams }) => {
  const allUsers = useAllUsers();
  const [addingCollaborators, setAddingCollaborators] = useState(false);
  const [addingTeam, setAddingTeam] = useState(false);
  const teams = useTeams();

  const addedTeams = useMemo(() => {
    return teams.filter((team) => teamIds.some((teamID) => teamID === team.id));
  }, [teamIds, teams]);

  return (
    <div className={`${gtw.card}`}>
      <div className="mb-2 flex">
        <div className={`font-bold text-gray-700 text-lg flex-1`}>Users</div>
        {allowEditing && (
          <button onClick={() => setAddingCollaborators(true)} className={"button-small"}>
            Add collaborator
          </button>
        )}
      </div>
      {collaborators.length === 0 && <div className="italic text-sm">No users added</div>}
      {collaborators.map((collaborator) => {
        const fullUserInfo = allUsers.find((u) => u.id === collaborator);
        if (fullUserInfo) {
          return (
            <div
              key={fullUserInfo.id}
              className="flex items-center w-full py-2 border-b border-gray-200 pr-4 pl-2"
            >
              <span className="flex-grow text-sm">{fullUserInfo.fullName}</span>
              {allowEditing && (
                <button
                  onClick={() => {
                    updateCollaborators(collaborators.filter((c) => c !== collaborator));
                  }}
                >
                  <CrossIcon />
                </button>
              )}
            </div>
          );
        } else return null;
      })}

      <div className="py-2 flex mt-4">
        <div className={`font-bold text-gray-700 text-lg flex-1`}>Teams</div>
        {allowEditing && (
          <button
            data-test="addTeamToProjectButton"
            onClick={() => {
              setAddingTeam(true);
            }}
            className={"button-small"}
          >
            Add team
          </button>
        )}
      </div>
      {addedTeams.length > 0 ? (
        addedTeams.map((team) => {
          return (
            <ProjectTeamRow
              key={team.id}
              team={team}
              removeTeam={() => updateTeams(teamIds.filter((tid) => tid !== team.id))}
            />
          );
        })
      ) : (
        <span className="text-sm italic">No teams added</span>
      )}

      {addingTeam && (
        <AddTeam
          allTeams={teams}
          addedTeams={teamIds}
          onSave={updateTeams}
          onFinish={() => {
            setAddingTeam(false);
          }}
        />
      )}
      {addingCollaborators && (
        <AddCollaborator
          allUsers={allUsers}
          addedCollaborators={collaborators}
          onSave={updateCollaborators}
          onFinish={() => {
            setAddingCollaborators(false);
          }}
        />
      )}
    </div>
  );
};

export default EditCollaborators;

const ProjectTeamRow: React.FC<{ team: Team; removeTeam: (teamID: string) => void }> = ({
  team,
  removeTeam,
}) => {
  const collaborators = useTeamCollaborators(team);
  const { hasProjectEditAccess } = useUserRole();

  return (
    <div className="py-2 px-2 border-t border-b text-sm border-gray-200 relative flex items-center">
      <span data-test="projectTeamName" className="font-medium w-1/4 truncate">
        {team.name}
      </span>
      <div className="text-xs flex-grow px-2 flex flex-wrap">
        {collaborators.map((collaborator) => {
          return (
            <HoverTooltip key={collaborator.id} text={collaborator.fullName} mt={-25}>
              <div className="mr-2 mb-1 h-6 w-6 flex items-center justify-center uppercase bg-green-numerous font-bold text-white rounded-full">
                {collaborator.firstName?.slice(0, 1) + collaborator.lastName?.slice(0, 1)}
              </div>
            </HoverTooltip>
          );
        })}
      </div>
      {hasProjectEditAccess && (
        <button className={`button-small`} onClick={() => removeTeam(team.id)}>
          Remove
        </button>
      )}
    </div>
  );
};
