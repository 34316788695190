import { CloudUploadIcon } from "@heroicons/react/outline";
import { useFirebase, useSimFiles } from "api/useFirebase";
import axios from "axios";
import Dropdown from "components/basic/Dropdown";
import FileIcon from "components/basic/icons/FileIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal, { ModalContent } from "components/basic/Modal";
import Toast from "components/basic/Toast";
import Dropzone from "components/basic/upload/Dropzone";
import {
  ComponentParamType,
  ComponentType,
  SimFile,
  SimulationJobType,
  FileRef,
  FullUser,
  RunSettings,
} from "model/datatypes";
import { InputVar } from "model/datatypes";
import { useMemo, useState } from "react";
import { mergeInputVariables, mergeParameterTypes } from "utils/ComponentTypeHelpers";
import { saveFileInFs } from "api/firestore/firestoreAPI";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import { FMUConfiguration, stdFMIJob, StdFMUFileParam } from "./FMU";
import dayjs from "dayjs";

type FMULoaderProps = {
  componentType: ComponentType;
  updateComponent: (updated: ComponentType) => void;
  fmiJob?: SimulationJobType;
  updateSystemJob: (updated: SimulationJobType) => void;
  systemID: string;
  user: FullUser;
};

const FMUSelecter: React.FC<FMULoaderProps> = ({
  componentType,
  updateComponent,
  fmiJob,
  updateSystemJob,
  systemID,
  user,
}) => {
  const [newFMUConfig, setNewFMUConfig] = useState<null | FMUConfiguration>(null);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [newFileRef, setNewFileRef] = useState<FileRef | null>(null);

  const [editingFMU, setEditingFMU] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedFMUFile = useMemo(() => {
    return componentType.parameters.find((param) => param.id === "fmu_file")?.value as
      | undefined
      | FileRef;
  }, [componentType.parameters]);

  const FMUConfiguration = useMemo(
    () => componentType.customMetadata?.fmu as FMUConfiguration | undefined,
    [componentType.customMetadata]
  );

  const fileQuery = useMemo(() => {
    return { tags: ["fmu"] };
  }, []);
  const files = useSimFiles(fileQuery);
  const fmuFilesOptions = useMemo(() => {
    return files.simFiles
      .filter((file) => {
        return (
          !!file.customMetadata &&
          !!file.customMetadata.FMUConfiguration &&
          file.id !== selectedFMUFile?.id
        );
      })
      .map((file) => {
        return { id: file.id, display: file.name, value: file };
      });
  }, [files, selectedFMUFile?.id]);

  const parseFMUFile = async (file: File) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      //Read the file with parser:
      const res = await axios.post(
        "https://fmi-numerous-import-b3x4x3y7jq-ew.a.run.app/fmi/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        const fmuConfiguration = res.data as FMUConfiguration;
        console.log({ fmuConfiguration });
        setNewFMUConfig(fmuConfiguration);
        setNewFile(file);
      } else {
        console.log(res);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const renderSelectFMUUI = () => {
    return (
      <>
        {fmuFilesOptions.length > 0 && (
          <>
            <div className="text-xs">Select existing FMU</div>
            <Dropdown
              className="text-xs w-full mb-4"
              options={fmuFilesOptions}
              placeholder="Select FMU"
              onSelect={(option) => {
                const file = option.value as SimFile;
                const fileRef: FileRef = {
                  id: file.id,
                  name: file.name,
                  path: file.path,
                };
                const FMUConfiguration = file.customMetadata!
                  .FMUConfiguration as FMUConfiguration;
                console.log({ file, FMUConfiguration });
                setNewFileRef(fileRef);
                setNewFMUConfig(FMUConfiguration);
              }}
            />
          </>
        )}
        <div className="text-xs mr-2">Upload new FMU</div>
        <Dropzone
          allowedTypes="fmu"
          className=""
          onFilesAdded={(files, rawFileList) => {
            parseFMUFile(files[0]);
          }}
        >
          {(dropHovered, manualOpen) => {
            return (
              <div
                className={`cursor-pointer flex flex-col items-center justify-center border-4 border-gray-400 border-dashed rounded-lg h-32 ${
                  dropHovered ? "bg-green-200 shadow-lg" : ""
                }`}
                onClick={() => manualOpen()}
              >
                <div className="w-12 h-12 text-gray-700">
                  <CloudUploadIcon />
                </div>
                <div className="font-medium text-sm text-gray-700">
                  Drop file here or click here to upload FMU
                </div>
                {loading && <LoadingOverlay />}
              </div>
            );
          }}
        </Dropzone>
        {files.loadingFiles && <LoadingOverlay />}
      </>
    );
  };

  const renderEditFMUUI = () => {
    return (
      <Modal onClose={() => setEditingFMU(false)}>
        <ModalContent className="z-30 w-1/2 relative">
          <div className="font-medium text-center mb-4">Edit FMU</div>
          {renderSelectFMUUI()}
        </ModalContent>
      </Modal>
    );
  };

  return (
    <>
      {FMUConfiguration && (
        <div className="inline-flex my-2 items-center border border-gray-200 rounded">
          <FileIcon className="w-5 h-5 mx-2" />
          <div className="pr-2 py-1">
            <div className="text-xs font-medium">{FMUConfiguration.modelName}.fmu</div>
            <div className="text-xs italic">
              {FMUConfiguration.generationTool || FMUConfiguration.platforms.toString()}
            </div>
          </div>
          <button
            onClick={() => setEditingFMU(true)}
            className="px-4 py-3 border-l text-xs font-bold"
          >
            Edit
          </button>
        </div>
      )}

      {!FMUConfiguration && !newFMUConfig && renderSelectFMUUI()}
      {editingFMU && renderEditFMUUI()}
      {newFMUConfig && (
        <FMUConfirmSelecter
          componentType={componentType}
          fmuConfiguration={newFMUConfig}
          newFile={newFile}
          selectedFile={newFileRef || undefined}
          systemID={systemID}
          updateComponent={updateComponent}
          user={user}
          onFinish={(success) => {
            setNewFMUConfig(null);
            setNewFile(null);
            if (success) setEditingFMU(false);
          }}
          fmiJob={fmiJob}
          updateSystemJob={updateSystemJob}
        />
      )}
    </>
  );
};

const FMUConfirmSelecter: React.FC<
  FMULoaderProps & {
    onFinish: (success?: boolean) => void;
    fmuConfiguration: FMUConfiguration;
    newFile: File | null;
    selectedFile?: FileRef;
  }
> = ({
  onFinish,
  systemID,
  user,
  updateComponent,
  fmiJob,
  updateSystemJob,
  fmuConfiguration,
  componentType,
  newFile,
  selectedFile,
}) => {
  const fb = useFirebase();
  const [fileUploading, setFileUploading] = useState<null | number>(null);

  const updatedComponent = useMemo(() => {
    const updatedComp = parseFMUConfig(fmuConfiguration, componentType);
    return updatedComp;
  }, [fmuConfiguration, componentType]);

  const saveNewFile = (file: File, customMetadata: { [key: string]: any }) => {
    return new Promise<FileRef>((resolve, reject) => {
      const type = file.type.toLowerCase();
      //UPLOAD To buckett with metadata:
      let path = `${user.organisation}/fmu/systems/${systemID}`;
      const uploadRef = fb.storage().ref(path);
      setFileUploading(0);

      const uploadTask = uploadRef.put(file);

      uploadTask.on(
        "state_changed",
        (snap) => {
          let prog = (snap.bytesTransferred / snap.totalBytes) * 100;
          console.log({ prog });
          setFileUploading(prog);
        },
        (error) => {
          console.log(error);
          setFileUploading(null);
          reject();
        },
        async () => {
          //on finished callback:
          const fs = fb.firestore();
          const uploadedFile: SimFile = {
            id: getUUID(),
            path,
            name: file.name,
            tags: ["fmu"],
            type,
            organisation: user.organisation,
            modelID: systemID,
            customMetadata,
          };
          setFileUploading(null);
          await saveFileInFs(fs, uploadedFile, ["fmu"]);

          const fileRef: FileRef = {
            id: uploadedFile.id,
            name: uploadedFile.name,
            path: uploadedFile.path,
          };

          //return file reference in FMU file parameter:
          resolve(fileRef);
        }
      );
    });
  };

  const confirmAddFMU = async () => {
    if (fileUploading !== null) return;

    try {
      let fileRef = selectedFile;
      if (newFile) {
        fileRef = await saveNewFile(newFile, {
          FMUConfiguration: fmuConfiguration,
        });
      }
      if (!fileRef) return;

      const fileParam: ComponentParamType = { ...StdFMUFileParam, value: fileRef };
      updateComponent({
        ...updatedComponent,
        parameters: updateArrayVal(updatedComponent.parameters, fileParam),
      });

      //update the job with default experiment:
      const defaultExperiment = fmuConfiguration.defaultExperiment;
      const updatedJob = fmiJob || stdFMIJob;
      updatedJob.isMain = true;
      updatedJob.image.parameters = getFMIJobParameters(defaultExperiment);
      updatedJob.runSettings = getFMIRunSettings(defaultExperiment);

      console.log({ updatedJob });
      updateSystemJob(updatedJob);

      onFinish(true);
    } catch (error) {
      console.log(error);
      Toast("Error saving FMU", { icon: "error" });
    }
  };

  return (
    <Modal
      onClose={() => {
        onFinish();
      }}
    >
      <div className="modal-content z-30 relative w-1/2">
        <div className="text-center mb-4">FMU file</div>
        <div className="mb-2 text-xs">{newFile?.name}</div>
        <div className="mb-2 text-xs">{fmuConfiguration.platforms.toString()}</div>
        <div className="mb-2 text-xs">{updatedComponent.parameters.length} parameters</div>
        <div className="mb-2 text-xs">
          {updatedComponent.inputVariables.length} input variables
        </div>

        <div className="flex">
          <button
            className="button-small mr-2 flex-1"
            onClick={() => {
              confirmAddFMU();
            }}
          >
            save
          </button>
          <button
            className="button-small ml-2 flex-1"
            onClick={() => {
              if (!fileUploading) onFinish();
            }}
          >
            cancel
          </button>
        </div>
        {(fileUploading || typeof fileUploading === "number") && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default FMUSelecter;

const getFMIJobParameters = (defaultExperiment: FMUConfiguration["defaultExperiment"]) => {
  const standardParameters: ComponentParamType[] = [
    {
      displayName: "Result Resolution",
      type: "time_value",
      value: {
        unit: "seconds",
        value: defaultExperiment.stepSize || 0.01,
      },
      uuid: "f7d31f98_fd4e_425a_ad9f_051714eab37f",
      id: "result_resolution",
    },
    {
      type: "time_value",
      id: "solver_resolution",
      uuid: "bfd7ad75_6a14_44e1_a9cc_7283c131c1df",
      displayName: "Solver Resolution",
      value: {
        value: defaultExperiment.stepSize || 0.01,
        unit: "seconds",
      },
    },
  ];
  if (defaultExperiment.tolerance) {
    standardParameters.push({
      id: "tolerance",
      type: "number",
      displayName: "Tolerance",
      value: defaultExperiment.tolerance,
      uuid: "2b2e91be-40a6-4827-be6a-2ef945af5d6a",
    });
  }
  return standardParameters;
};

const getFMIRunSettings = (defaultExperiment: FMUConfiguration["defaultExperiment"]) => {
  let startDate = dayjs().startOf("year");
  if (defaultExperiment?.startTime && defaultExperiment?.startTime > 0) {
    startDate = dayjs.unix(defaultExperiment.startTime);
  }

  let runTime = 60; //1 hour std
  if (defaultExperiment.stopTime && defaultExperiment.stopTime) {
    runTime = defaultExperiment.stopTime - (defaultExperiment.startTime || 0);
  }

  const unit =
    runTime < 120
      ? "seconds"
      : runTime < 7200
      ? "minutes"
      : runTime < 172800
      ? "hours"
      : "days";

  const runSettings: RunSettings = {
    startDate,
    endDate: startDate.add(runTime, "s"),
    endTimeVal: { value: runTime, unit: unit },
    runMode: "duration",
  };

  return runSettings;
};

const parseFMUConfig = (fmuConfiguration: FMUConfiguration, prevComponent: ComponentType) => {
  const updatedComp = { ...prevComponent };

  updatedComp.customMetadata = { fmu: fmuConfiguration };

  let newParameters = parseFMUParameters(fmuConfiguration.parameters);
  updatedComp.parameters = mergeParameterTypes(newParameters, prevComponent.parameters);

  const newInputs = parseFMUInputs(fmuConfiguration.inputs);
  updatedComp.inputVariables = mergeInputVariables(newInputs, updatedComp.inputVariables);

  //Save config raw in component
  updatedComp.customMetadata = { fmu: fmuConfiguration };

  //update component name
  if (fmuConfiguration.modelName) updatedComp.name = fmuConfiguration.modelName;
  if (fmuConfiguration.description) updatedComp.displayName = fmuConfiguration.description;
  return updatedComp;
};

const parseFMUParameters = (fmuParameters: FMUConfiguration["parameters"]) => {
  return fmuParameters.map((fmuParam) => {
    let type: ComponentParamType["type"] = "number";
    let value: ComponentParamType["value"] = 0;

    if (fmuParam.type === "String") {
      type = "string";
      value = fmuParam.start;
    }
    if (fmuParam.type === "Boolean") {
      type = "boolean";
      value = fmuParam.start === "true";
    }
    if (fmuParam.type === "Real") {
      type = "number";
      const parsed = parseFloat(fmuParam.start);
      value = !isNaN(parsed) ? parsed : 0;
    }
    if (["Integer", "Enumeration"].includes(fmuParam.type)) {
      type = "number";
      const parsed = parseInt(fmuParam.start);
      value = !isNaN(parsed) ? parsed : 0;
    }

    const compParam: ComponentParamType = {
      id: fmuParam.name,
      uuid: getUUID(),
      displayName: fmuParam.name,
      tooltip: fmuParam.description,
      type,
      value,
      fixedID: true,
    };
    return compParam;
  });
};

const parseFMUInputs = (fmuInputs: FMUConfiguration["inputs"]) => {
  return fmuInputs.map((fmuInput) => {
    const input: InputVar = {
      id: fmuInput.name,
      display: fmuInput.name,
      tooltip: fmuInput.description,
      dataSourceType: "static",
      offset: 0,
      scaling: 1,
      uuid: getUUID(),
      value: fmuInput.initial || 0,
      unit: fmuInput.unit || undefined,
      fixedID: true,
    };
    return input;
  });
};
