import { useFirestore, useGroups } from "api/useFirebase";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import RadioButton from "components/basic/RadioButton";
import { Group, SimulationScenario } from "model/datatypes";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "store";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

interface Props {
  currentScenarioID: string;
  alreadyAdded: {
    projectID: string;
    scenarioID: string;
  }[];
  selectedInputID: string | null;
  setSelectedInputID: (newID: string | null) => void;
}

type GroupWithScenarios = {
  id: string;
  group: Group;
  scenarios: SimulationScenario[];
  open: boolean;
  loaded: boolean;
};

const SelectInputScenario: React.FC<Props> = ({
  alreadyAdded,
  currentScenarioID,
  selectedInputID,
  setSelectedInputID,
}) => {
  const { projectID } = useGlobalState();
  const [infusedGroups, setInfusedGroups] = useState<GroupWithScenarios[]>([]);

  const groups = useGroups(projectID || undefined);
  const [initialGroupsLoaded, setInitialGroupsLoaded] = useState(false);
  useEffect(() => {
    if (groups && groups.length > 0 && !initialGroupsLoaded) {
      setInfusedGroups(
        groups.map((g) => ({ id: g.id, group: g, scenarios: [], open: false, loaded: false }))
      );
      setInitialGroupsLoaded(true);
    }
  }, [groups, initialGroupsLoaded]);

  const fs = useFirestore();
  useEffect(() => {
    if (!projectID) return;
    //get all groups for a scenario
    const getGroupScenarios = async (groupID: string) => {
      const scenarioSnap = await fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("groupID", "==", groupID)
        .get();
      const groupScenarios: SimulationScenario[] = [];
      scenarioSnap.docs.forEach((doc) => {
        if (doc.id !== currentScenarioID)
          groupScenarios.push(
            convertFromFirestoreFormatNew({
              ...doc.data(),
              id: doc.id,
            }) as SimulationScenario
          );
      });
      return groupScenarios;
    };

    //get a new updated groups if any has been opened and has not loaded yet:
    const updateGroups = async () => {
      const updated: GroupWithScenarios[] = [];
      let newLoaded = false;
      for (let i = 0; i < infusedGroups.length; i++) {
        const infusedGroup = infusedGroups[i];
        if (infusedGroup.open && !infusedGroup.loaded) {
          const groupScenarios = await getGroupScenarios(infusedGroup.group.id);
          updated.push({
            ...infusedGroup,
            scenarios: groupScenarios,
            loaded: true,
          });
          newLoaded = true;
        } else {
          updated.push(infusedGroup);
        }
      }
      return newLoaded ? updated : null;
    };

    updateGroups().then((updated) => {
      if (updated) {
        setInfusedGroups(updated);
      }
    });
  }, [infusedGroups, groups, fs, projectID, currentScenarioID]);

  const renderGroup = (ig: GroupWithScenarios) => {
    return (
      <div
        className="mb-2  w-full px-4 py-1 border border-gray-300 border-dashed rounded"
        key={ig.id}
      >
        <button
          className={`text-sm focus:outline-none flex items-center w-full justify-between`}
          onClick={() => {
            const updatedIG = { ...ig, open: !ig.open };
            setInfusedGroups(updateArrayVal(infusedGroups, updatedIG));
          }}
        >
          <span>{ig.group.groupName}</span> <OpenCloseArrow isOpen={ig.open} />
        </button>
        {ig.open &&
          ig.scenarios.map((scenario) => {
            const isSelected = selectedInputID === scenario.id;
            const added = alreadyAdded.some((input) => input.scenarioID === scenario.id);
            return (
              <div
                className={`px-2 text-xs border py-1 rounded my-1 flex items-center border-gray-200 relative ${
                  added ? "" : "cursor-pointer"
                }`}
                onClick={() => !added && setSelectedInputID(isSelected ? null : scenario.id)}
                key={scenario.id}
              >
                {added ? (
                  <CheckmarkIcon className="w-4 h-4" />
                ) : (
                  <RadioButton active={isSelected} />
                )}
                <span className="ml-2">{scenario.scenarioName}</span>
                {added && (
                  <div className="absolute w-full h-full top-0 left-0 bg-green-200 border-green-200 text-white opacity-75"></div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className="font-medium text-sm">Scenarios</div>
      {infusedGroups.map(renderGroup)}
    </>
  );
};

export default SelectInputScenario;
