import {
  ComponentType,
  Container,
  RawDataReport,
  SimulationModel,
  SimulationScenario,
} from "model/datatypes";
import {
  instantiateAllComponentsFromPrev,
  mergeParameters,
  ParamTypeToInstance,
} from "utils/ComponentTypeHelpers";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import app from "firebase/app";
import { instantiateSimJob } from "utils/dataTransform/simulationJob";
import { experimentalInstantiateAllComponentsFromPrev } from "utils/ExperimentalCompIntantiater";

export const mergeScenarioSystem = async (
  fb: app.app.App,
  projectID: string,
  scenario: SimulationScenario,
  newModel: SimulationModel
) => {
  const fs = fb.firestore();
  //get simulation model and merge new components to the updated version.....
  const batch = fs.batch();

  const scenarioDoc = fs
    .collection("Projects")
    .doc(projectID)
    .collection("Scenarios")
    .doc(scenario.id);

  const modelDoc = fs.collection("SimulationModels").doc(newModel.id);

  const updatedCompTypes: ComponentType[] = newModel.componentTypes;
  const updatedContainers: Container[] = newModel.containers;

  //instantiate from types and merge with previoous simcomponents:
  let simComponents =
    newModel.modelType === "experimental_builder"
      ? experimentalInstantiateAllComponentsFromPrev(
          updatedCompTypes,
          updatedContainers,
          scenario.simComponents
        )
      : instantiateAllComponentsFromPrev(
          updatedCompTypes,
          updatedContainers,
          scenario.simComponents
        );

  let updatedParameters =
    newModel.parameters?.map((paramType) => ParamTypeToInstance(paramType)) || [];
  if (scenario.parameters)
    updatedParameters = mergeParameters(updatedParameters, scenario.parameters);

  const updatedJobs: SimulationScenario["jobs"] = instantiateSimJob(
    newModel.jobs,
    scenario.jobs
  );

  const scenarioFieldsToUpdate: Partial<SimulationScenario> = {
    simComponents,
    parameters: updatedParameters,
    jobs: updatedJobs,
    codePreample:
      (newModel.modelType === "experimental_builder" && newModel.codePreample) || undefined,
  };

  //update the scenario
  batch.update(scenarioDoc, convertToFirestoreFormat(scenarioFieldsToUpdate));

  //overwrite local system model with updated model
  const newLocalModelDoc = scenarioDoc.collection("SimulationModel").doc(newModel.id);
  const globalModelCopy = { ...newModel, localVersion: newModel.version };

  batch.set(newLocalModelDoc, convertToFirestoreFormat(globalModelCopy));
  console.log({ globalModelCopy });

  //clean up after updated/new model is merged.
  if (scenario.systemID !== newModel.id) {
    const prevModelDoc = scenarioDoc.collection("SimulationModel").doc(scenario.systemID);
    //delete prev model that is now removed
    batch.delete(prevModelDoc);

    //delete prev data reports
    const reportsSnap = await scenarioDoc.collection("RawDataReports").get();
    reportsSnap.forEach((doc) => {
      batch.delete(doc.ref);
    });
  }

  //overwrite all rawdata reports:
  const reportsSnap = await modelDoc.collection("RawDataReports").get();
  reportsSnap.forEach((doc) => {
    const report = { id: doc.id, ...doc.data() } as RawDataReport;
    const localReportDoc = scenarioDoc.collection("RawDataReports").doc(report.id);
    batch.set(localReportDoc, convertToFirestoreFormat(report));
  });

  await batch.commit();
  return scenarioFieldsToUpdate;
};
