import { useFirebase, useFirestore, useIdToken } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import InputNumber from "components/basic/headless/InputNumber";
import UploadIcon from "components/basic/icons/UploadIcon";
import Modal from "components/basic/Modal";
import SearchSelecter from "components/basic/SearchSelecter";
import Dropzone from "components/basic/upload/Dropzone";
import { motion, Point2D, useAnimation } from "framer-motion";
import { DrawObject, PIDiagram, PIDTag, SimFile } from "model/datatypes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import LoadingOverlay from "components/basic/LoadingOverlay";
import MinusIcon from "components/basic/icons/MinusIcon";
import PlusIcon from "components/basic/icons/PlusIcon";
import clamp from "utils/clamp";
import { IconsOptions } from "./icons/PIDIcons";
import { DrawRectangleIcon } from "components/basic/icons/DrawingIcons";
import ToggleButton from "components/basic/ToggleButton";
import { useGlobalState } from "store";
import { saveFileInFs } from "api/firestore/firestoreAPI";
import { getFileURL } from "grpc/api/grpcUtilAPI";

interface Props {
  availableFields?: string[];
  startDiagram?: PIDiagram;
  onFinish: () => void;
  modelID: string;
  projectID?: string;
  scenarioID?: string;
  savePID: (pid: PIDiagram) => Promise<void>;
}

const PIDSetup: React.FC<Props> = ({
  availableFields,
  startDiagram,
  onFinish,
  projectID,
  modelID,
  savePID,
}) => {
  const fs = useFirestore();
  const fb = useFirebase();

  const { user, grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const [name, setName] = useState(startDiagram?.name || "New PIDiagram");

  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState<PIDTag[]>(startDiagram?.tags || []);
  const [drawObjects, setDrawObjects] = useState(startDiagram?.drawObjects || []);

  const tagOptions = useMemo(
    () => availableFields?.map((field) => ({ id: field, display: field })),
    [availableFields]
  );

  const [updatedPositions, setUpdatedPositions] = useState<
    { id: string; position: [number, number] }[]
  >([]);

  const [editingTag, setEditingTag] = useState<PIDTag | null>(null);
  const refTagEditor = useRef<HTMLDivElement>(null);

  const saveAndCloseTagEditor = useCallback(() => {
    setEditingTag((prev) => {
      if (prev) setTags(updateArrayVal(tags, prev));
      return null;
    });
  }, [tags]);
  useClickOutsideEffect(refTagEditor, saveAndCloseTagEditor, "search_selecter");

  const [imgURL, setImgURL] = useState<string>();
  const [pendingFile, setPendingFile] = useState<File | null>(null);
  const [loadingIMG, setLoadingIMG] = useState(false);

  useEffect(() => {
    if (startDiagram && idToken) {
      setLoadingIMG(true);
      getFileURL(grpcURL, idToken, startDiagram.bg.path)
        .then((url) => {
          setImgURL(url);
          setLoadingIMG(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingIMG(false);
        });
    }
  }, [startDiagram, idToken, grpcURL]);

  const pidCanvasRef = useRef<HTMLDivElement>(null);
  const pidBoxRef = useRef<HTMLDivElement>(null);

  const bgRef = useRef<HTMLImageElement>(null);
  const [pidSize, setPidSize] = useState<[number, number]>(
    startDiagram?.canvasSize || [100, 100]
  );

  const controls = useAnimation();

  const saveNewDiagram = async () => {
    try {
      let bg = pendingFile ? await uploadPIDBGFile(pendingFile) : startDiagram?.bg;
      if (!bg) {
        console.log("error, bg missing..");
        return;
      }
      setLoading(true);
      const updatedDrawObjects = drawObjects.map((obj) => {
        const position = updatedPositions.find((pos) => pos.id === obj.id)?.position;
        return { ...obj, ...(position && { position }) };
      });
      const pid: PIDiagram = {
        id: startDiagram ? startDiagram.id : getUUID(),
        name,
        bg,
        canvasSize: pidSize,
        tags: tags.map((tag) => {
          const position = updatedPositions.find((pos) => pos.id === tag.id)?.position;
          return { ...tag, position: position || tag.position };
        }),
        drawObjects: updatedDrawObjects,
        localVersion: (startDiagram?.localVersion || 0) + 1,
      };
      await savePID(pid);
      onFinish();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const uploadPIDBGFile = (file: File) => {
    return new Promise<SimFile>(async (resolve, reject) => {
      try {
        if (!user) return;
        //prepare file to upload
        const fileTag = ["PID_img"];

        const uploadedFile: SimFile = {
          id: getUUID(),
          path: `${user.organisation}/simulation_files/${
            projectID ? `project/${projectID}` : "global"
          }/system/${modelID}`,
          name: file.name,
          tags: fileTag,
          type: file.type.toLowerCase(),
          modelID,
          organisation: user.organisation,
        };
        if (projectID) uploadedFile.projectID = projectID;
        uploadedFile.path = `${uploadedFile.path}/${uploadedFile.id}`;

        const uploadRef = fb.storage().ref(uploadedFile.path);
        const customMetadata: { [key: string]: any } = {
          name: file.name,
          tags: fileTag.toString(),
          modelID,
        };
        if (projectID) customMetadata.projectID = projectID;
        await uploadRef.put(file, { customMetadata });

        //store the file reference and update tags/types:

        await saveFileInFs(fs, uploadedFile, fileTag);
        resolve(uploadedFile);
      } catch (error) {
        reject(error);
      }
    });
  };

  const [keyPressedMode, setKeyPressedMode] = useState<null | "duplicate">(null);
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      // console.log(e.key);
      if (e.key === "Alt") {
        setKeyPressedMode("duplicate");
      }
    };
    const handleKeyup = () => {
      setKeyPressedMode(null);
      setDuplicatingTag(null);
    };
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, []);

  const addTag = (x: number, y: number) => {
    const newTag: PIDTag = {
      id: getUUID(),
      name: "new tag",
      scaling: 1,
      offset: 0,
      unit: "",
      position: [x, y],
      icon: "time",
    };
    setTags([...tags, newTag]);
  };

  const renderTagEditor = (tag: PIDTag) => {
    const position =
      updatedPositions.find((obj) => obj.id === tag.id)?.position || tag.position;

    const positionOnTop = position[1] > 210;
    const shiftToRight = position[0] < 76;

    return (
      <div
        className={`absolute top-0 left-0 z-20`}
        style={{
          marginLeft: position[0],
          marginTop: positionOnTop ? position[1] : position[1] + 20,
        }}
      >
        <div
          ref={refTagEditor}
          className={`absolute w-64 
          ${positionOnTop ? "bottom-0 mb-2" : "top-0 mt-2"}
          ${shiftToRight ? "" : "-ml-20"} 
          bg-white shadow-md border border-gray-200 text-xs px-4 pt-3 pb-4`}
        >
          {tagOptions && (
            <>
              <div className={"text-xs font-medium"}>Tag</div>
              <SearchSelecter
                className={`w-full input-box`}
                headlessStyle
                placeholder="Search tags"
                options={tagOptions}
                selectedID={editingTag?.sourceID}
                onSelect={(option) => {
                  setEditingTag({
                    ...tag,
                    name: tag.name === "new tag" ? option.id : tag.name,
                    sourceID: option.id,
                  });
                }}
                drawerID={"search_selecter"}
              />
            </>
          )}
          <div className={"text-xs font-medium mt-1"}>Display name *</div>
          <input
            type="text"
            className={`input-box w-full`}
            value={tag.name}
            onChange={(e) => {
              setEditingTag({ ...tag, name: e.target.value });
            }}
          />

          <div className="flex mt-1">
            <div className="flex-1 mr-1">
              <div className={"text-xs font-medium"}>Icon</div>
              <Dropdown
                className="w-full shadow-none"
                options={IconsOptions}
                selectedID={editingTag?.icon}
                onSelect={(option) => {
                  setEditingTag({ ...tag, icon: option.id as PIDTag["icon"] });
                }}
              />
            </div>
            <div className="flex-1 ml-1">
              <div className={"text-xs font-medium"}>Unit</div>
              <input
                type="text"
                className={`input-box w-full`}
                value={tag.unit}
                onChange={(e) => {
                  setEditingTag({ ...tag, unit: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex mt-1">
            <div className="flex-1 mr-1">
              <div className={"text-xs font-medium"}>Scaling</div>
              <InputNumber
                className={`input-box w-full`}
                value={tag.scaling}
                onChange={(val) => {
                  setEditingTag({ ...tag, scaling: val });
                }}
              />
            </div>
            <div className="flex-1 ml-1">
              <div className={"text-xs font-medium"}>Offset</div>
              <InputNumber
                className={`input-box w-full`}
                value={tag.offset}
                onChange={(val) => {
                  setEditingTag({ ...tag, offset: val });
                }}
              />
            </div>
          </div>
          <div className="mt-1">
            <ToggleButton
              active={!!tag.conditional_styles}
              onChange={() => {
                if (tag.conditional_styles) setEditingTag({ ...tag });
              }}
            />
          </div>
          <button
            onClick={() => {
              setEditingTag(null);
              setTags(tags.filter((t) => t.id !== tag.id));
            }}
            className="text-red-500 italic focus:outline-none mt-2 underline"
          >
            Remove Tag
          </button>

          <div
            style={{
              left: shiftToRight ? "0%" : "50%",
              transform: `
                ${positionOnTop ? "rotate(45deg)" : "rotate(225deg)"} 
                ${shiftToRight ? "" : "translateX(-50%)"}
              `,
              marginLeft: shiftToRight ? "0.5rem" : "",
              marginBottom: positionOnTop ? "-0.275rem" : "",
              marginTop: positionOnTop ? "" : "-0.275rem",
            }}
            className={`absolute block w-2 h-2 bg-white border-r border-b border-gray-200
                ${positionOnTop ? "bottom-0" : "top-0"}
              `}
          ></div>
        </div>
      </div>
    );
  };

  const [duplicatingTag, setDuplicatingTag] = useState<null | PIDTag>(null);

  const renderTag = (tag: PIDTag) => {
    const extraTagWidth = tag.unit.length * 4;
    const icon = IconsOptions.find((icon) => icon.id === tag.icon)?.icon;
    const isBeingDuplicated = duplicatingTag?.id === tag.id;

    const renderTagGraphics = () => {
      return (
        <>
          <rect
            x={0}
            y={0}
            rx={10}
            width={100 + extraTagWidth}
            height={20}
            fill={"#fff"}
            stroke={"#a3a3a3"}
            strokeWidth={1}
          />
          {icon}

          <text x={26} y={13.5} fontSize={10}>
            {`(${tag.name.slice(0, 9)}${tag.name.length > 9 ? ".." : ""})`}
          </text>
          <text x={87} y={13.5} fontSize={10}>
            {`${tag.unit}`}
          </text>
        </>
      );
    };

    const renderTagDuringDuplicate = () => {
      const prePos =
        updatedPositions.find((obj) => obj.id === tag.id)?.position || tag.position;
      return (
        <g style={{ opacity: 0.5 }} transform={`translate(${prePos[0]}, ${prePos[1]})`}>
          {renderTagGraphics()}
        </g>
      );
    };

    return (
      <>
        <g
          key={tag.id}
          id={tag.id}
          transform={`translate(${tag.position[0]}, ${tag.position[1]})`}
        >
          <motion.g
            drag
            dragConstraints={pidCanvasRef}
            dragMomentum={false}
            onDragStart={() => {
              if (keyPressedMode === "duplicate") {
                setDuplicatingTag(tag);
              }
            }}
            onDragEnd={(e, drag) => {
              const prevPos =
                updatedPositions.find((obj) => obj.id === tag.id)?.position || tag.position;
              const newX = prevPos[0] + drag.offset.x;
              const newY = prevPos[1] + drag.offset.y;
              let newPosition: [number, number] = [
                clamp(newX, 0, pidSize[0] - 100), //make sure the tag fits within the diagram x
                clamp(newY, 0, pidSize[1] - 20), //make sure the tag fits within the diagram y
              ];
              const updatedPos = {
                id: tag.id,
                position: newPosition,
              };
              setUpdatedPositions(updateArrayVal(updatedPositions, updatedPos));
              if (isBeingDuplicated) {
                //Add tag copy at previous position....
                const tagCopy: PIDTag = { ...tag, id: getUUID(), position: prevPos };
                setTags([...tags, tagCopy]);
                setDuplicatingTag(null);
              }
            }}
          >
            <motion.g
              onTap={(e) => {
                setEditingTag(tag);
              }}
              whileHover={{ scale: 1.05 }}
            >
              {renderTagGraphics()}
            </motion.g>
          </motion.g>
        </g>
        {isBeingDuplicated && renderTagDuringDuplicate()}
      </>
    );
  };

  const renderAddTagControl = () => {
    return (
      <div className="relative">
        <motion.div
          style={{
            borderColor: "#a3a3a3",
            width: "100px",
            fontSize: 10,
            paddingTop: "2px",
            paddingBottom: "1px",
          }}
          className="absolute top-0 left-0 px-2 mx-2 border bg-white rounded-full z-10 hover:cursor-pointer flex items-center"
          drag
          animate={controls}
          dragMomentum={false}
          dragConstraints={pidBoxRef}
          onDragEnd={(e, drag) => {
            if (drag.offset.y < 15) {
              setTimeout(() => {
                controls.start({ x: 0, y: 0 }); // not dragged in - dont add.
              }, 200);
            } else {
              //new tag add:
              setTimeout(() => {
                controls.set({ x: 0, y: 0 });
                addTag(
                  drag.offset.x + 12 + canvasScrollPos.current.x,
                  drag.offset.y - 25 + canvasScrollPos.current.y
                );
              }, 200);
            }
          }}
          whileHover={{ scale: 1.05 }}
        >
          <StdIcon />
          <span className="ml-1">(new tag)</span>
        </motion.div>
        <div
          style={{
            borderColor: "#a3a3a3",
            width: "100px",
            fontSize: 10,
            paddingTop: "2px",
            paddingBottom: "1px",
          }}
          className="opacity-25 px-2 mx-2 border bg-white rounded-full  hover:cursor-pointer flex items-center"
        >
          <StdIcon />
          <span className="ml-1">New tag</span>
        </div>
      </div>
    );
  };

  const [cursorMode, setCursorMode] = useState<null | "draw_box">(null);
  const [startDrawPosition, setStartDrawPosition] = useState<null | Point2D>(null);

  const renderDiagramControls = () => {
    return (
      <div className="absolute top-0 left-0 w-full flex items-center px-1 bg-white shadow rounded z-10">
        {renderAddTagControl()}
        <button
          onClick={() => setCursorMode("draw_box")}
          className={`ml-2 px-3 py-1 focus:outline-none ${
            cursorMode === "draw_box" ? "bg-blue-400" : "hover:bg-gray-100"
          }`}
        >
          <DrawRectangleIcon />
        </button>
        <div className="flex-grow"></div>
        {keyPressedMode && <div className="italic text-xs mx-2">{keyPressedMode}</div>}
        <button
          className="mx-1 bg-white rounded-full p-1 shadow border border-gray-200 focus:outline-none w-5 h-5 flex items-center justify-center"
          onClick={() => {
            setPidSize((prev) => [prev[0] * 1.05, prev[1] * 1.05]);
          }}
        >
          <PlusIcon />
        </button>
        <button
          className="bg-white rounded-full p-1 shadow border border-gray-200 focus:outline-none w-5 h-5 flex items-center justify-center"
          onClick={() => {
            setPidSize((prev) => [prev[0] * 0.95, prev[1] * 0.95]);
          }}
        >
          <MinusIcon />
        </button>
      </div>
    );
  };

  const [drawGuide, setDrawGuide] = useState<null | DrawObject>(null);

  const updateDrawGuide = (currentOffset: Point2D) => {
    if (!startDrawPosition) {
      setDrawGuide(null);
      return;
    }
    if (cursorMode === "draw_box") {
      const target = {
        x: startDrawPosition.x + currentOffset.x,
        y: startDrawPosition.y + currentOffset.y,
      };
      if (target.x < 0 || target.y < 0) {
        setDrawGuide(null);
        return;
      }
      if (cursorMode === "draw_box") {
        const line1 = `0 ${currentOffset.y}`;
        const line2 = `${currentOffset.x} ${currentOffset.y}`;
        const line3 = `${currentOffset.x} 0`;
        const d = `M0 0 L${line1} L${line2} L${line3} Z`;
        setDrawGuide({
          id: "temp",
          type: "rect",
          position: [startDrawPosition.x, startDrawPosition.y],
          d,
          style: { opacity: 0.5, fill: "#a8a8a8" },
        });
      }
    }
  };
  const renderDrawGuide = () => {
    if (drawGuide?.type === "rect")
      return (
        <g transform={`translate(${drawGuide.position[0]}, ${drawGuide.position[1]})`}>
          <path {...drawGuide.style} d={drawGuide.d} />
        </g>
      );
    return null;
  };

  const [selectedDrawObject, setSelectedDrawObject] = useState<DrawObject | null>(null);
  const refDrawObjectEditor = useRef<HTMLDivElement>(null);
  const saveAndCloseDrawObjectEditor = useCallback(() => {
    setSelectedDrawObject((prev) => {
      if (prev) setDrawObjects(updateArrayVal(drawObjects, prev));
      return null;
    });
  }, [drawObjects]);
  useClickOutsideEffect(refDrawObjectEditor, saveAndCloseDrawObjectEditor);

  const renderDrawObject = (drawObject: DrawObject) => {
    const isSelected = drawObject.id === selectedDrawObject?.id;
    const style = { ...drawObject.style, ...(isSelected && { stroke: "#116BF1" }) };
    const position = drawObject.position || [0, 0];
    if (drawObject.type === "rect")
      return (
        <g
          key={drawObject.id}
          id={drawObject.id}
          transform={`translate(${position[0]}, ${position[1]})`}
        >
          <motion.g
            drag
            dragConstraints={pidCanvasRef}
            dragMomentum={false}
            onDragEnd={(e, drag) => {
              const prevPos =
                updatedPositions.find((obj) => obj.id === drawObject.id)?.position ||
                drawObject.position;
              const newX = prevPos[0] + drag.offset.x;
              const newY = prevPos[1] + drag.offset.y;
              let newPosition = [
                clamp(newX, 0, pidSize[0] - 50), //make sure the grahics fits within the diagram x
                clamp(newY, 0, pidSize[1] - 50), //make sure the tag fits within the diagram y
              ];
              const updatedPos = {
                id: drawObject.id,
                position: newPosition,
              };
              setUpdatedPositions(updateArrayVal(updatedPositions, updatedPos));
            }}
          >
            <motion.path
              onTap={(event) => {
                setSelectedDrawObject(drawObject);
              }}
              {...style}
              d={drawObject.d}
            />
          </motion.g>
        </g>
      );
    return null;
  };

  const renderDrawObjectEditor = (drawObject: DrawObject) => {
    const position =
      updatedPositions.find((obj) => obj.id === drawObject.id)?.position ||
      drawObject.position;

    const positionOnTop = position[1] > 210;
    const shiftToRight = position[0] < 76;

    return (
      <div
        className={`absolute top-0 left-0 z-20`}
        style={{
          marginLeft: position[0],
          marginTop: positionOnTop ? position[1] : position[1] + 20,
        }}
      >
        <div
          ref={refDrawObjectEditor}
          className={`absolute w-64 
          ${positionOnTop ? "bottom-0 mb-2" : "top-0 mt-2"}
          ${shiftToRight ? "" : "-ml-20"} 
          bg-white shadow-md border border-gray-200 text-xs px-4 pt-3 pb-4`}
        >
          <div className="flex mt-1">
            <div className="flex-1 mr-1">
              <div className={"text-xs font-medium"}>Opacity</div>
              <InputNumber
                className={`input-box w-full`}
                value={drawObject.style.opacity}
                onChange={(val) => {
                  setSelectedDrawObject({
                    ...drawObject,
                    style: { ...drawObject.style, opacity: val },
                  });
                }}
              />
            </div>
            <div className="flex-1 ml-1 mb-2">
              <div className={"text-xs font-medium"}>Fill Color</div>
              <input
                type="text"
                className={`input-box w-full`}
                value={drawObject.style.fill}
                onChange={(e) => {
                  setSelectedDrawObject({
                    ...drawObject,
                    style: { ...drawObject.style, fill: e.target.value },
                  });
                }}
              />
            </div>
          </div>
          <div>
            <button onClick={() => {}} className={"button-small"}>
              Add condition
            </button>
          </div>
          <button
            onClick={() => {
              setSelectedDrawObject(null);
              setDrawObjects(drawObjects.filter((o) => o.id !== drawObject.id));
            }}
            className="text-red-500 italic focus:outline-none mt-2 underline"
          >
            Remove graphics
          </button>

          <div
            style={{
              left: shiftToRight ? "0%" : "50%",
              transform: `
                ${positionOnTop ? "rotate(45deg)" : "rotate(225deg)"} 
                ${shiftToRight ? "" : "translateX(-50%)"}
              `,
              marginLeft: shiftToRight ? "0.5rem" : "",
              marginBottom: positionOnTop ? "-0.275rem" : "",
              marginTop: positionOnTop ? "" : "-0.275rem",
            }}
            className={`absolute block w-2 h-2 bg-white border-r border-b border-gray-200
                ${positionOnTop ? "bottom-0" : "top-0"}
              `}
          ></div>
        </div>
      </div>
    );
  };

  const canvasScrollPos = useRef({ x: 0, y: 0 });

  const renderTheGraphic = () => {
    return (
      <>
        {renderDiagramControls()}
        <div
          className="w-full h-full overflow-auto"
          onScroll={(e) => {
            const element = e.target as HTMLDivElement;
            canvasScrollPos.current = { x: element.scrollLeft, y: element.scrollTop };
          }}
        >
          <motion.div
            ref={pidCanvasRef}
            className="relative"
            style={{
              width: pidSize[0],
              height: pidSize[1],
              ...(cursorMode === "draw_box" && { cursor: "crosshair" }),
            }}
          >
            <img
              ref={bgRef}
              src={imgURL}
              alt=""
              onLoad={(e) => {
                if (!startDiagram) {
                  const img = e.target as HTMLImageElement;
                  const height = img.naturalHeight;
                  const width = img.naturalWidth;
                  setPidSize([width, height]);
                }
              }}
              className="max-w-none absolute top-0 left-0 w-full h-full"
            />
            <motion.svg
              width={pidSize[0]}
              height={pidSize[1]}
              style={{
                ...(cursorMode === "draw_box" && { cursor: "crosshair" }),
              }}
              className="absolute top-0 left-0 z-10"
              onPanStart={(event, info) => {
                if (cursorMode === "draw_box") {
                  const bounding = pidCanvasRef.current?.getBoundingClientRect();
                  const windowScrollY = window.scrollY; //info.point is affected by windowscroll position
                  if (!bounding) return;
                  const x = info.point.x - bounding.x - canvasScrollPos.current.x;
                  const y = info.point.y - bounding.y - windowScrollY;
                  console.log({ y, canvasY: canvasScrollPos.current.y });
                  setStartDrawPosition({ x, y });
                }
              }}
              onPan={(event, info) => {
                if (cursorMode === "draw_box") {
                  updateDrawGuide(info.offset);
                }
              }}
              onPanEnd={(event, info) => {
                //if distance large enough draw a box..
                if (info.offset.x > 5 && info.offset.y > 5 && drawGuide) {
                  const newDrawnObject = { ...drawGuide, id: getUUID() };
                  setDrawObjects([...drawObjects, newDrawnObject]);
                }
                //then stop drawing:
                setCursorMode(null);
                setStartDrawPosition(null);
                setDrawGuide(null);
              }}
            >
              {tags.map((tag) => {
                return renderTag(tag);
              })}
              <g>{drawObjects.map(renderDrawObject)}</g>
              <g>{renderDrawGuide()}</g>
            </motion.svg>
            {editingTag && renderTagEditor(editingTag)}
            {selectedDrawObject && renderDrawObjectEditor(selectedDrawObject)}
          </motion.div>
        </div>
      </>
    );
  };

  return (
    <Modal onClose={onFinish}>
      <div className="pb-12 relative bg-white shadow z-30 w-full h-full">
        <div className="w-full h-full px-4 py-4 flex flex-col">
          <div>{startDiagram ? "Edit" : "New"} Diagram</div>
          <div className={`text-xs font-medium`}>Name</div>
          <input
            type="text"
            className={`input-box text-xs w-full mb-2`}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Dropzone
            allowedTypes="svg"
            className="flex-grow overflow-hidden"
            onFilesAdded={(files) => {
              const imgFile = files[0];
              setPendingFile(imgFile);
              const imgURL = URL.createObjectURL(imgFile);
              setImgURL(imgURL);
              console.log(imgFile);
            }}
          >
            {(dropHovered, manualOpen) => {
              return (
                <div
                  ref={pidBoxRef}
                  className={`h-full border w-full overflow-hidden pt-8 relative border-gray-200 rounded ${
                    !imgURL ? "flex items-center justify-center" : ""
                  } ${dropHovered ? "bg-green-200 shadow-lg" : ""}`}
                >
                  {!imgURL && (
                    <button
                      className={`flex items-center button-small`}
                      onClick={() => manualOpen()}
                    >
                      <div className="w-4 h-4 text-gray-700 mr-2">
                        <UploadIcon />
                      </div>
                      <span>Upload Background</span>
                    </button>
                  )}
                  {imgURL && renderTheGraphic()}
                </div>
              );
            }}
          </Dropzone>
        </div>
        <div className="w-full flex absolute bottom-0 left-0 p-4">
          <button onClick={() => saveNewDiagram()} className={`button-small flex-1 mr-2`}>
            Save
          </button>
          <button
            onClick={() => {
              onFinish();
            }}
            className={`button-small flex-1 ml-2`}
          >
            Cancel
          </button>
        </div>
        {(loading || loadingIMG) && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default PIDSetup;

const StdIcon = () => (
  <svg className="h-4 w-4">
    <g id="g824" transform="">
      <circle
        id="circle713"
        stroke="#707070"
        cx="5.8701"
        fill="#FFFFFF"
        cy="6.21002"
        r="4.8200002"
      ></circle>
      <rect
        id="rect716"
        fill="#FFFFFF"
        fillRule="nonzero"
        x="0.6099"
        y="13.21997"
        width="10.52"
        height="1.75"
      ></rect>
      <rect
        id="rect718"
        stroke="#707070"
        x="1.0498"
        y="13.65997"
        width="9.6499996"
        height="1"
      ></rect>
      <path d="M5.87,6.21 L8.38,3.69" id="Path_175-2" stroke="#707070"></path>
      <path d="M5.87,13.33 L5.87,10.79" id="Path_181-2" stroke="#707070"></path>
    </g>
  </svg>
);
