import { useFirebase } from "api/useFirebase";
import { completeUserSignup } from "grpc/api/grpcUtilAPI";
import { Invitation } from "model/datatypes";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useGlobalDispatch, useGlobalState } from "store";
import { getInvitation } from "api/firestore/firestoreAPI";
import LoadingOverlay from "./basic/LoadingOverlay";
import Toast from "./basic/Toast";
import { useCloudProjects } from "./login/useCloudProjects";

const SignUpPage: React.FC<{}> = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invitation, setInvitation] = useState<null | Invitation>(null);

  const fb = useFirebase();
  const dispatch = useGlobalDispatch();
  const { grpcURL } = useGlobalState();
  const history = useHistory();

  const [pageLoading, setPageLoading] = useState(true);

  const { inviteID, gprojectID } = useParams<{
    inviteID: string;
    gprojectID: string;
  }>();

  const { cloudProjects } = useCloudProjects();
  useEffect(() => {
    const cloudProject = cloudProjects.find((org) => org.id === gprojectID);
    if (cloudProject)
      dispatch({
        type: "SET_CLOUD_PROJECT",
        payload: cloudProject,
      });
  }, [dispatch, gprojectID, cloudProjects]);

  useEffect(() => {
    setPageLoading(true);
    if (fb && fb.name === gprojectID) {
      getInvitation(inviteID, fb.firestore())
        .then((invitation) => {
          setInvitation(invitation);
          setPageLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setInvitation(null);
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, [fb, gprojectID, inviteID]);

  const finishSignUp = () => {
    if (!invitation || loading) return;
    setLoading(true);
    completeUserSignup(grpcURL, {
      invitationID: inviteID,
      firstName: firstName,
      lastName: lastName,
      organisation: invitation.organisation,
      email: invitation.mail,
      password: password,
    })
      .then(() => {
        setLoading(false);
        Toast("Signup success", { icon: "success" });
        setSuccess(true);
        console.log("Succesful sign up");
      })
      .catch((error) => {
        setLoading(false);
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          Toast("The password is too weak.");
        } else {
          Toast(errorMessage);
        }
        console.log(error);
      });
  };

  const renderSignUpForm = () => {
    if (!invitation) return null;
    return (
      <div className="">
        <div className="text-lg font-bold text-center mb-4">Finish your sign-up</div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">First name</label>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="first-name"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Last name</label>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="last-name"
            className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-password">
            Password
          </label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">User role</label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-300 leading-tight">
            {invitation.userRole}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Mail</label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-300 leading-tight">
            {invitation.mail}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-green-400 hover:bg-green-600 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={finishSignUp}
          >
            Sign Up
          </button>
        </div>
        <div
          onClick={() => history.push("/")}
          className="cursor-pointer text-xs text-center mt-2"
        >
          Already a user? Login here
        </div>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="text-center">Successfully signed up!</div>
        <Link className="text-blue-600 underline" to="/">
          Go to login
        </Link>
      </div>
    );
  };

  return (
    <div className="w-full h-screen relative flex justify-center items-center">
      <div className="bg-white border border-gray-200 shadow-md rounded w-1/2 px-8 pt-6 pb-8">
        {(pageLoading || loading) && <LoadingOverlay />}
        {success && renderSuccess()}
        {invitation && !pageLoading && !success && renderSignUpForm()}
        {!pageLoading && !invitation && !success && (
          <div className="">
            <div className="text-lg font-bold text-center mb-4">
              This invitation is invalid
            </div>
            <div className="text-sm text-center mt-2">Please contact your administrator</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
