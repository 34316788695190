import React, { useContext, useState } from "react";
import { useFirestore } from "api/useFirebase";
import { SimulationModel } from "model/datatypes";
import { store } from "store";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { useHistory } from "react-router-dom";
import { Input } from "components/basic/Input";
import { Button } from "components/basic/Buttons";

interface Props {
  onSuccess: () => void;
  startModel?: SimulationModel;
}

const AddNewModel: React.FC<Props> = ({ onSuccess, startModel }) => {
  const { state } = useContext(store);
  const { user } = state;
  const ownerId = user?.fbUser.uid;
  const [loading, setloading] = useState(false);
  const [displayName, setDisplayName] = useState(
    startModel ? `${startModel.displayName} copy` : "Example model"
  );

  const fs = useFirestore();

  const history = useHistory();

  const addModel = () => {
    if (ownerId && user) {
      setloading(true);
      const batch = fs.batch();
      const modelDoc = fs.collection("SimulationModels").doc();

      const newModel: SimulationModel = {
        ...defaultModel,
        id: modelDoc.id,
        displayName,
        ownerId,
        organisation: user.organisation,
        jobs: {},
        SPM_version: 2, //TODO: remove after intergration SPM
        modelType: "default",
      };

      if (startModel) {
        newModel.parameters = startModel.parameters;
        newModel.jobs = startModel.jobs;
        newModel.containers = startModel.containers;
        newModel.componentTypes = startModel.componentTypes;
      }

      batch.set(modelDoc, convertToFirestoreFormat(newModel));

      batch
        .commit()
        .then(() => {
          setloading(false);
          onSuccess();
          history.push(`/systems/${modelDoc.id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={`card w-1/2 flex flex-col z-50`}>
      <div className="font-medium mb-4 text-center">New system model</div>
      {/* {componentTypes.length > 0 && (
        <div className="italic text-xs">{componentTypes.length} copied components</div>
      )} */}
      <label className="font-bold text-xs">Model display name</label>
      <Input
        autoFocus
        data-test="newSystemName"
        type="text"
        className={`text-sm`}
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      />

      <Button
        data-test="addModel"
        className={`mt-4 ${loading ? "opacity-50" : ""}`}
        onClick={() => {
          !loading && addModel();
        }}
      >
        Add model
      </Button>
    </div>
  );
};

export default AddNewModel;

const defaultModel = {
  description: "",
  status: "draft" as SimulationModel["status"],
  version: 1,
  collaborators: [] as SimulationModel["collaborators"],
  teams: [] as SimulationModel["teams"],
  jobs: {} as SimulationModel["jobs"], //TODO remove..
  parameters: [] as SimulationModel["parameters"],
  containers: [] as SimulationModel["containers"],
  componentTypes: [] as SimulationModel["componentTypes"],
};
