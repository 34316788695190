import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import ScheduleVariableBuilder from "components/simulations/newSimulation/simSetup/inputs/ScheduleVariableBuilder";
import { ComponentParameters } from "components/simulations/newSimulation/simSetup/simComponent/SimulationComponent";
import { DatasetScenario, InputVar, ScenarioStateEdited, Schedule } from "model/datatypes";
import React, { useMemo } from "react";
import { useGlobalState } from "store";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const DHWComponent: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const { projectID } = useGlobalState();

  const dhwComp = useMemo(() => {
    return scenarioState.simComponents.find((c) => c.name === "DHW");
  }, [scenarioState.simComponents]);
  const scheduleInputVar = useMemo(() => {
    let scheduleVar = dhwComp?.inputVariables.find((inputVar) => inputVar.id === "dhw");
    const defaultVar: InputVar = {
      uuid: getUUID(),
      id: "dhw",
      display: "Domestic hot water",
      value: 0,
      offset: 0,
      scaling: 1,
      dataSourceType: "static",
    };
    return scheduleVar || defaultVar;
  }, [dhwComp]);

  const selectSchedule = (selected: { dataset: DatasetScenario; schedule: Schedule }) => {
    const { dataset, schedule } = selected;

    //add dataset to sceneario inputs:
    let prevInputs = scenarioState.inputScenarios;
    if (!prevInputs.some((pi) => pi.scenarioID === selected.dataset.id)) {
      if (scheduleInputVar?.tagSource) {
        prevInputs = prevInputs.filter(
          (inputRef) => inputRef.scenarioID !== scheduleInputVar.tagSource!.scenarioID
        );
      }
      scenarioDispatch({
        type: "UPDATE_SCENARIO_INPUTS",
        payload: [
          ...prevInputs,
          { scenarioID: dataset.id, projectID: projectID!, type: "dataset" },
        ],
      });
    }

    //update tag source:
    updateScheduleVariable({
      dataSourceType: "scenario",
      tagSource: { scenarioID: dataset.id, projectID: projectID!, tag: schedule.tag },
      dataSourceID: dataset.id,
    });
  };

  const updateScheduleVariable = (updated: Partial<InputVar>) => {
    if (dhwComp && scheduleInputVar) {
      const updatedScheduleVar = {
        ...scheduleInputVar,
        ...updated,
      };
      scenarioDispatch({
        type: "UPDATE_COMPONENT",
        payload: {
          ...dhwComp,
          inputVariables: updateArrayValUUID(dhwComp.inputVariables, updatedScheduleVar),
        },
      });
    }
  };

  return (
    <div className="w-full px-4 py-4 bg-white ">
      <div className="w-full bg-white mb-8 text-xs">
        {dhwComp && (
          <ComponentParameters
            component={dhwComp}
            onUpdate={(updated) => {
              scenarioDispatch({
                type: "UPDATE_COMPONENT",
                payload: updated,
              });
            }}
          />
        )}
      </div>
      <ScheduleVariableBuilder
        scheduleInputVar={scheduleInputVar}
        selectSchedule={selectSchedule}
        updateScheduleVariable={updateScheduleVariable}
      />
    </div>
  );
};

export default DHWComponent;
