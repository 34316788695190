import { useDatasetScenarios } from "api/useFirebase";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import RadioButton from "components/basic/RadioButton";
import { DatasetScenario, InputScenarioRef } from "model/datatypes";
import React from "react";

interface Props {
  onSelect: (ds: DatasetScenario | null) => void;
  selected: DatasetScenario | null;
  alreadyAdded?: InputScenarioRef[];
}

const SelectDataset: React.FC<Props> = ({ onSelect, selected, alreadyAdded }) => {
  const datasets = useDatasetScenarios();
  return (
    <>
      {datasets.length > 0 && <div className="font-medium text-sm mt-4">Input datasets</div>}
      {datasets.map((ds) => {
        const isSelected = selected?.id === ds.id;
        const added = alreadyAdded?.some((a) => a.scenarioID === ds.id);
        return (
          <div
            key={ds.id}
            onClick={() => !added && onSelect(isSelected ? null : ds)}
            className={`relative w-full border border-gray-200 rounded my-1 cursor-pointer text-xs px-2 py-1 flex items-center ${
              isSelected ? "" : ""
            }`}
          >
            {added ? (
              <CheckmarkIcon className="w-4 h-4" />
            ) : (
              <RadioButton active={isSelected} />
            )}
            <span className="ml-2">{ds.scenarioName}</span>
            {added && (
              <div className="absolute w-full h-full top-0 left-0 bg-green-200 border-green-200 text-white opacity-75"></div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SelectDataset;
