// source: node.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Compressor', null, global);
goog.exportSymbol('proto.Condition', null, global);
goog.exportSymbol('proto.ConfigurationCondition', null, global);
goog.exportSymbol('proto.ConfigurationConditionIterationResult', null, global);
goog.exportSymbol('proto.ConfigurationConditionList', null, global);
goog.exportSymbol('proto.HeatExchangerConfiguration', null, global);
goog.exportSymbol('proto.HeatpumpConfiguration', null, global);
goog.exportSymbol('proto.IterationStatus', null, global);
goog.exportSymbol('proto.TestInfo', null, global);
goog.exportSymbol('proto.TestResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Compressor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Compressor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Compressor.displayName = 'proto.Compressor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeatExchangerConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HeatExchangerConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.HeatExchangerConfiguration.displayName = 'proto.HeatExchangerConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeatpumpConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HeatpumpConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.HeatpumpConfiguration.displayName = 'proto.HeatpumpConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Condition.displayName = 'proto.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ConfigurationCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ConfigurationCondition.repeatedFields_, null);
};
goog.inherits(proto.ConfigurationCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConfigurationCondition.displayName = 'proto.ConfigurationCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ConfigurationConditionList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ConfigurationConditionList.repeatedFields_, null);
};
goog.inherits(proto.ConfigurationConditionList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConfigurationConditionList.displayName = 'proto.ConfigurationConditionList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IterationStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IterationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IterationStatus.displayName = 'proto.IterationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ConfigurationConditionIterationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ConfigurationConditionIterationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConfigurationConditionIterationResult.displayName = 'proto.ConfigurationConditionIterationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TestInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TestInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TestInfo.displayName = 'proto.TestInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TestResponse.displayName = 'proto.TestResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Compressor.prototype.toObject = function(opt_includeInstance) {
  return proto.Compressor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Compressor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Compressor.toObject = function(includeInstance, msg) {
  var f, obj = {
    serie: jspb.Message.getFieldWithDefault(msg, 1, 0),
    model: jspb.Message.getFieldWithDefault(msg, 2, ""),
    compressor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    net: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ov: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cr: jspb.Message.getFieldWithDefault(msg, 7, 0),
    usefulSuperheat: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Compressor}
 */
proto.Compressor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Compressor;
  return proto.Compressor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Compressor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Compressor}
 */
proto.Compressor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerie(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompressor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNet(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOv(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCr(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsefulSuperheat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Compressor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Compressor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Compressor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Compressor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerie();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompressor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNet();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDs();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOv();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCr();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUsefulSuperheat();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int32 serie = 1;
 * @return {number}
 */
proto.Compressor.prototype.getSerie = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setSerie = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string model = 2;
 * @return {string}
 */
proto.Compressor.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string compressor = 3;
 * @return {string}
 */
proto.Compressor.prototype.getCompressor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setCompressor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 net = 4;
 * @return {number}
 */
proto.Compressor.prototype.getNet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setNet = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ds = 5;
 * @return {number}
 */
proto.Compressor.prototype.getDs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setDs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 ov = 6;
 * @return {number}
 */
proto.Compressor.prototype.getOv = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setOv = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 cr = 7;
 * @return {number}
 */
proto.Compressor.prototype.getCr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setCr = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool useful_superheat = 8;
 * @return {boolean}
 */
proto.Compressor.prototype.getUsefulSuperheat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Compressor} returns this
 */
proto.Compressor.prototype.setUsefulSuperheat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HeatExchangerConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.HeatExchangerConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HeatExchangerConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeatExchangerConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    plates: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fluid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeatExchangerConfiguration}
 */
proto.HeatExchangerConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HeatExchangerConfiguration;
  return proto.HeatExchangerConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeatExchangerConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeatExchangerConfiguration}
 */
proto.HeatExchangerConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlates(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConcentration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeatExchangerConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HeatExchangerConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeatExchangerConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeatExchangerConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlates();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFluid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.HeatExchangerConfiguration.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatExchangerConfiguration} returns this
 */
proto.HeatExchangerConfiguration.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 plates = 2;
 * @return {number}
 */
proto.HeatExchangerConfiguration.prototype.getPlates = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.HeatExchangerConfiguration} returns this
 */
proto.HeatExchangerConfiguration.prototype.setPlates = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string fluid = 3;
 * @return {string}
 */
proto.HeatExchangerConfiguration.prototype.getFluid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatExchangerConfiguration} returns this
 */
proto.HeatExchangerConfiguration.prototype.setFluid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float concentration = 4;
 * @return {number}
 */
proto.HeatExchangerConfiguration.prototype.getConcentration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.HeatExchangerConfiguration} returns this
 */
proto.HeatExchangerConfiguration.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HeatpumpConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.HeatpumpConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HeatpumpConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeatpumpConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    refrigerant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    circuitType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberOfCompressors: jspb.Message.getFieldWithDefault(msg, 3, 0),
    compressor: (f = msg.getCompressor()) && proto.Compressor.toObject(includeInstance, f),
    evaporator: (f = msg.getEvaporator()) && proto.HeatExchangerConfiguration.toObject(includeInstance, f),
    condenser: (f = msg.getCondenser()) && proto.HeatExchangerConfiguration.toObject(includeInstance, f),
    subcooler: (f = msg.getSubcooler()) && proto.HeatExchangerConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeatpumpConfiguration}
 */
proto.HeatpumpConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HeatpumpConfiguration;
  return proto.HeatpumpConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeatpumpConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeatpumpConfiguration}
 */
proto.HeatpumpConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefrigerant(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCircuitType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfCompressors(value);
      break;
    case 4:
      var value = new proto.Compressor;
      reader.readMessage(value,proto.Compressor.deserializeBinaryFromReader);
      msg.setCompressor(value);
      break;
    case 5:
      var value = new proto.HeatExchangerConfiguration;
      reader.readMessage(value,proto.HeatExchangerConfiguration.deserializeBinaryFromReader);
      msg.setEvaporator(value);
      break;
    case 6:
      var value = new proto.HeatExchangerConfiguration;
      reader.readMessage(value,proto.HeatExchangerConfiguration.deserializeBinaryFromReader);
      msg.setCondenser(value);
      break;
    case 7:
      var value = new proto.HeatExchangerConfiguration;
      reader.readMessage(value,proto.HeatExchangerConfiguration.deserializeBinaryFromReader);
      msg.setSubcooler(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeatpumpConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HeatpumpConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeatpumpConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeatpumpConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRefrigerant();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCircuitType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberOfCompressors();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompressor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Compressor.serializeBinaryToWriter
    );
  }
  f = message.getEvaporator();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.HeatExchangerConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getCondenser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.HeatExchangerConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getSubcooler();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.HeatExchangerConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.HeatpumpConfiguration.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.HeatpumpConfiguration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string refrigerant = 1;
 * @return {string}
 */
proto.HeatpumpConfiguration.prototype.getRefrigerant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.setRefrigerant = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string circuit_type = 2;
 * @return {string}
 */
proto.HeatpumpConfiguration.prototype.getCircuitType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.setCircuitType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 number_of_compressors = 3;
 * @return {number}
 */
proto.HeatpumpConfiguration.prototype.getNumberOfCompressors = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.setNumberOfCompressors = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Compressor compressor = 4;
 * @return {?proto.Compressor}
 */
proto.HeatpumpConfiguration.prototype.getCompressor = function() {
  return /** @type{?proto.Compressor} */ (
    jspb.Message.getWrapperField(this, proto.Compressor, 4));
};


/**
 * @param {?proto.Compressor|undefined} value
 * @return {!proto.HeatpumpConfiguration} returns this
*/
proto.HeatpumpConfiguration.prototype.setCompressor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.clearCompressor = function() {
  return this.setCompressor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeatpumpConfiguration.prototype.hasCompressor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HeatExchangerConfiguration evaporator = 5;
 * @return {?proto.HeatExchangerConfiguration}
 */
proto.HeatpumpConfiguration.prototype.getEvaporator = function() {
  return /** @type{?proto.HeatExchangerConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.HeatExchangerConfiguration, 5));
};


/**
 * @param {?proto.HeatExchangerConfiguration|undefined} value
 * @return {!proto.HeatpumpConfiguration} returns this
*/
proto.HeatpumpConfiguration.prototype.setEvaporator = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.clearEvaporator = function() {
  return this.setEvaporator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeatpumpConfiguration.prototype.hasEvaporator = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HeatExchangerConfiguration condenser = 6;
 * @return {?proto.HeatExchangerConfiguration}
 */
proto.HeatpumpConfiguration.prototype.getCondenser = function() {
  return /** @type{?proto.HeatExchangerConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.HeatExchangerConfiguration, 6));
};


/**
 * @param {?proto.HeatExchangerConfiguration|undefined} value
 * @return {!proto.HeatpumpConfiguration} returns this
*/
proto.HeatpumpConfiguration.prototype.setCondenser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.clearCondenser = function() {
  return this.setCondenser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeatpumpConfiguration.prototype.hasCondenser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HeatExchangerConfiguration subcooler = 7;
 * @return {?proto.HeatExchangerConfiguration}
 */
proto.HeatpumpConfiguration.prototype.getSubcooler = function() {
  return /** @type{?proto.HeatExchangerConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.HeatExchangerConfiguration, 7));
};


/**
 * @param {?proto.HeatExchangerConfiguration|undefined} value
 * @return {!proto.HeatpumpConfiguration} returns this
*/
proto.HeatpumpConfiguration.prototype.setSubcooler = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.HeatpumpConfiguration} returns this
 */
proto.HeatpumpConfiguration.prototype.clearSubcooler = function() {
  return this.setSubcooler(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeatpumpConfiguration.prototype.hasSubcooler = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tEvapGuess: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    tCondGuess: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    tLiquidSubcooling: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    tSubcoolCondenser: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    tSuperheat: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    tFluid2EvapIn: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    tFluid2EvapOut: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    tFluid2CondIn: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    tFluid2CondOut: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    tFluid2SubIn: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    tFluid2SubOut: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    subcoolingAbsoluteTemp: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    id: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Condition}
 */
proto.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Condition;
  return proto.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Condition}
 */
proto.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTEvapGuess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTCondGuess(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTLiquidSubcooling(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTSubcoolCondenser(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTSuperheat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2EvapIn(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2EvapOut(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2CondIn(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2CondOut(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2SubIn(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTFluid2SubOut(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubcoolingAbsoluteTemp(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTEvapGuess();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTCondGuess();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTLiquidSubcooling();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTSubcoolCondenser();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTSuperheat();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTFluid2EvapIn();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTFluid2EvapOut();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTFluid2CondIn();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTFluid2CondOut();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTFluid2SubIn();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTFluid2SubOut();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getSubcoolingAbsoluteTemp();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.Condition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float T_evap_guess = 1;
 * @return {number}
 */
proto.Condition.prototype.getTEvapGuess = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTEvapGuess = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float T_cond_guess = 2;
 * @return {number}
 */
proto.Condition.prototype.getTCondGuess = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTCondGuess = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float T_liquid_subcooling = 3;
 * @return {number}
 */
proto.Condition.prototype.getTLiquidSubcooling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTLiquidSubcooling = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float T_subcool_condenser = 4;
 * @return {number}
 */
proto.Condition.prototype.getTSubcoolCondenser = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTSubcoolCondenser = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float T_superheat = 5;
 * @return {number}
 */
proto.Condition.prototype.getTSuperheat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTSuperheat = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float T_fluid2_evap_in = 6;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2EvapIn = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2EvapIn = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float T_fluid2_evap_out = 7;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2EvapOut = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2EvapOut = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float T_fluid2_cond_in = 8;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2CondIn = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2CondIn = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float T_fluid2_cond_out = 9;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2CondOut = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2CondOut = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float T_fluid2_sub_in = 10;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2SubIn = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2SubIn = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float T_fluid2_sub_out = 11;
 * @return {number}
 */
proto.Condition.prototype.getTFluid2SubOut = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setTFluid2SubOut = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool subcooling_absolute_temp = 12;
 * @return {boolean}
 */
proto.Condition.prototype.getSubcoolingAbsoluteTemp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setSubcoolingAbsoluteTemp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string id = 14;
 * @return {string}
 */
proto.Condition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Condition} returns this
 */
proto.Condition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ConfigurationCondition.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ConfigurationCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.ConfigurationCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ConfigurationCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    configuration: (f = msg.getConfiguration()) && proto.HeatpumpConfiguration.toObject(includeInstance, f),
    conditionList: jspb.Message.toObjectList(msg.getConditionList(),
    proto.Condition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConfigurationCondition}
 */
proto.ConfigurationCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConfigurationCondition;
  return proto.ConfigurationCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConfigurationCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConfigurationCondition}
 */
proto.ConfigurationCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.HeatpumpConfiguration;
      reader.readMessage(value,proto.HeatpumpConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 2:
      var value = new proto.Condition;
      reader.readMessage(value,proto.Condition.deserializeBinaryFromReader);
      msg.addCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ConfigurationCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ConfigurationCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConfigurationCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.HeatpumpConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getConditionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Condition.serializeBinaryToWriter
    );
  }
};


/**
 * optional HeatpumpConfiguration configuration = 1;
 * @return {?proto.HeatpumpConfiguration}
 */
proto.ConfigurationCondition.prototype.getConfiguration = function() {
  return /** @type{?proto.HeatpumpConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.HeatpumpConfiguration, 1));
};


/**
 * @param {?proto.HeatpumpConfiguration|undefined} value
 * @return {!proto.ConfigurationCondition} returns this
*/
proto.ConfigurationCondition.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ConfigurationCondition} returns this
 */
proto.ConfigurationCondition.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ConfigurationCondition.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Condition condition = 2;
 * @return {!Array<!proto.Condition>}
 */
proto.ConfigurationCondition.prototype.getConditionList = function() {
  return /** @type{!Array<!proto.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Condition, 2));
};


/**
 * @param {!Array<!proto.Condition>} value
 * @return {!proto.ConfigurationCondition} returns this
*/
proto.ConfigurationCondition.prototype.setConditionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Condition}
 */
proto.ConfigurationCondition.prototype.addCondition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ConfigurationCondition} returns this
 */
proto.ConfigurationCondition.prototype.clearConditionList = function() {
  return this.setConditionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ConfigurationConditionList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ConfigurationConditionList.prototype.toObject = function(opt_includeInstance) {
  return proto.ConfigurationConditionList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ConfigurationConditionList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationConditionList.toObject = function(includeInstance, msg) {
  var f, obj = {
    configurationConditionListList: jspb.Message.toObjectList(msg.getConfigurationConditionListList(),
    proto.ConfigurationCondition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConfigurationConditionList}
 */
proto.ConfigurationConditionList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConfigurationConditionList;
  return proto.ConfigurationConditionList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConfigurationConditionList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConfigurationConditionList}
 */
proto.ConfigurationConditionList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ConfigurationCondition;
      reader.readMessage(value,proto.ConfigurationCondition.deserializeBinaryFromReader);
      msg.addConfigurationConditionList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ConfigurationConditionList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ConfigurationConditionList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConfigurationConditionList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationConditionList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigurationConditionListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ConfigurationCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConfigurationCondition configuration_condition_list = 1;
 * @return {!Array<!proto.ConfigurationCondition>}
 */
proto.ConfigurationConditionList.prototype.getConfigurationConditionListList = function() {
  return /** @type{!Array<!proto.ConfigurationCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ConfigurationCondition, 1));
};


/**
 * @param {!Array<!proto.ConfigurationCondition>} value
 * @return {!proto.ConfigurationConditionList} returns this
*/
proto.ConfigurationConditionList.prototype.setConfigurationConditionListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ConfigurationCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ConfigurationCondition}
 */
proto.ConfigurationConditionList.prototype.addConfigurationConditionList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ConfigurationCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ConfigurationConditionList} returns this
 */
proto.ConfigurationConditionList.prototype.clearConfigurationConditionListList = function() {
  return this.setConfigurationConditionListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IterationStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.IterationStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IterationStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IterationStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    traceback: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exception: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 10, 0),
    iteration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    change: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    conditionPhase: jspb.Message.getFieldWithDefault(msg, 8, ""),
    iterationPhase: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IterationStatus}
 */
proto.IterationStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IterationStatus;
  return proto.IterationStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IterationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IterationStatus}
 */
proto.IterationStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceback(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setException(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIteration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChange(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionPhase(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIterationPhase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IterationStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IterationStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IterationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IterationStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTraceback();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getException();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIteration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getChange();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConditionPhase();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIterationPhase();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.IterationStatus.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.IterationStatus.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string traceback = 3;
 * @return {string}
 */
proto.IterationStatus.prototype.getTraceback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setTraceback = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string exception = 4;
 * @return {string}
 */
proto.IterationStatus.prototype.getException = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setException = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 10;
 * @return {number}
 */
proto.IterationStatus.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 iteration = 5;
 * @return {number}
 */
proto.IterationStatus.prototype.getIteration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setIteration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float change = 6;
 * @return {number}
 */
proto.IterationStatus.prototype.getChange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setChange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.IterationStatus.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string condition_phase = 8;
 * @return {string}
 */
proto.IterationStatus.prototype.getConditionPhase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setConditionPhase = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string iteration_phase = 11;
 * @return {string}
 */
proto.IterationStatus.prototype.getIterationPhase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.IterationStatus} returns this
 */
proto.IterationStatus.prototype.setIterationPhase = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ConfigurationConditionIterationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ConfigurationConditionIterationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ConfigurationConditionIterationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationConditionIterationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && proto.IterationStatus.toObject(includeInstance, f),
    condition: (f = msg.getCondition()) && proto.Condition.toObject(includeInstance, f),
    heatpump: (f = msg.getHeatpump()) && proto.HeatpumpConfiguration.toObject(includeInstance, f),
    result: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConfigurationConditionIterationResult}
 */
proto.ConfigurationConditionIterationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConfigurationConditionIterationResult;
  return proto.ConfigurationConditionIterationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConfigurationConditionIterationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConfigurationConditionIterationResult}
 */
proto.ConfigurationConditionIterationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.IterationStatus;
      reader.readMessage(value,proto.IterationStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 1:
      var value = new proto.Condition;
      reader.readMessage(value,proto.Condition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    case 4:
      var value = new proto.HeatpumpConfiguration;
      reader.readMessage(value,proto.HeatpumpConfiguration.deserializeBinaryFromReader);
      msg.setHeatpump(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ConfigurationConditionIterationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ConfigurationConditionIterationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConfigurationConditionIterationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConfigurationConditionIterationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.IterationStatus.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Condition.serializeBinaryToWriter
    );
  }
  f = message.getHeatpump();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.HeatpumpConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional IterationStatus status = 2;
 * @return {?proto.IterationStatus}
 */
proto.ConfigurationConditionIterationResult.prototype.getStatus = function() {
  return /** @type{?proto.IterationStatus} */ (
    jspb.Message.getWrapperField(this, proto.IterationStatus, 2));
};


/**
 * @param {?proto.IterationStatus|undefined} value
 * @return {!proto.ConfigurationConditionIterationResult} returns this
*/
proto.ConfigurationConditionIterationResult.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ConfigurationConditionIterationResult} returns this
 */
proto.ConfigurationConditionIterationResult.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ConfigurationConditionIterationResult.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Condition condition = 1;
 * @return {?proto.Condition}
 */
proto.ConfigurationConditionIterationResult.prototype.getCondition = function() {
  return /** @type{?proto.Condition} */ (
    jspb.Message.getWrapperField(this, proto.Condition, 1));
};


/**
 * @param {?proto.Condition|undefined} value
 * @return {!proto.ConfigurationConditionIterationResult} returns this
*/
proto.ConfigurationConditionIterationResult.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ConfigurationConditionIterationResult} returns this
 */
proto.ConfigurationConditionIterationResult.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ConfigurationConditionIterationResult.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HeatpumpConfiguration heatpump = 4;
 * @return {?proto.HeatpumpConfiguration}
 */
proto.ConfigurationConditionIterationResult.prototype.getHeatpump = function() {
  return /** @type{?proto.HeatpumpConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.HeatpumpConfiguration, 4));
};


/**
 * @param {?proto.HeatpumpConfiguration|undefined} value
 * @return {!proto.ConfigurationConditionIterationResult} returns this
*/
proto.ConfigurationConditionIterationResult.prototype.setHeatpump = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ConfigurationConditionIterationResult} returns this
 */
proto.ConfigurationConditionIterationResult.prototype.clearHeatpump = function() {
  return this.setHeatpump(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ConfigurationConditionIterationResult.prototype.hasHeatpump = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string result = 3;
 * @return {string}
 */
proto.ConfigurationConditionIterationResult.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ConfigurationConditionIterationResult} returns this
 */
proto.ConfigurationConditionIterationResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TestInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.TestInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TestInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TestInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TestInfo}
 */
proto.TestInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TestInfo;
  return proto.TestInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TestInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TestInfo}
 */
proto.TestInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TestInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TestInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TestInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TestInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.TestInfo.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TestInfo} returns this
 */
proto.TestInfo.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TestResponse}
 */
proto.TestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TestResponse;
  return proto.TestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TestResponse}
 */
proto.TestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.TestResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TestResponse} returns this
 */
proto.TestResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto);
