import React, { useCallback, useMemo, useState } from "react";
import { TimeSlotEditable, AddTimeslot } from "./components/TimeSlotEditable";
import { v4 as uuid } from "uuid";
import ScheduleDayGraph from "./components/ScheduleDayGraph";
import InputNumber from "components/basic/headless/InputNumber";
import { updateArrayVal, updateArrayValRawString } from "utils/jsUtils/imutableArray";
import { DualButton } from "components/basic/ToggleButtonGroup";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Modal from "components/basic/Modal";
import { useFirestore, useIdToken } from "api/useFirebase";
import Toast from "components/basic/Toast";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { DatasetScenario, Schedule, PeriodSetting, TimeSlot } from "model/datatypes";
import { useGlobalState } from "store";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { getDayStart } from "./scheduleUtils";
import { uploadTagData } from "grpc/grpcClient";
import { fsFieldvalue } from "utils/firebase/helpers";
import getUUID from "utils/jsUtils/getUUID";
import { ScenarioMetaData, Tag } from "grpc/client/spm_pb";

dayjs.extend(isBetween);

interface Props {
  dataset?: DatasetScenario;
  scenarioMetadata?: ScenarioMetaData;
  onFinished: (scheduleDataset?: { schedule: Schedule; dataset: DatasetScenario }) => void;
  startSchedule?: Schedule;
  unit?: string;
}

const ScheduleInput: React.FC<Props> = ({
  onFinished,
  unit,
  startSchedule,
  dataset,
  scenarioMetadata,
}) => {
  const { projectID, grpcURL } = useGlobalState();
  const [editedSchedule, setEditedSchedule] = useState<Schedule>(
    startSchedule || getDefaultNewSchedule("")
  );

  const [scheduleUpdated, setScheduleUpdated] = useState(false);
  const [activePeriodID, setActivePeriodID] = useState<string>("weekday");
  const [savingScheduleOpen, setSavingScheduleOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  ////Whenever new time period is input / updated
  const updatePeriodTimeSetting = useCallback(
    (newTimeslot: TimeSlot) => {
      console.log({ newTimeslot });
      //make sure the settings fit full day, the update the settings:
      const curPeriodSetting = editedSchedule.periodSettings.find(
        (ps) => ps.id === activePeriodID
      );
      const curPeriodSettingIndex = editedSchedule.periodSettings.findIndex(
        (ps) => ps.id === activePeriodID
      );
      if (curPeriodSetting) {
        let timeslots = curPeriodSetting.timeslots;
        timeslots = getCleanTimeslots(timeslots, newTimeslot);
        const newPeriodSetting = {
          ...curPeriodSetting,
          timeslots,
        };
        if (curPeriodSetting) {
          setEditedSchedule({
            ...editedSchedule,
            periodSettings: [
              ...editedSchedule.periodSettings.slice(0, curPeriodSettingIndex),
              newPeriodSetting,
              ...editedSchedule.periodSettings.slice(curPeriodSettingIndex + 1),
            ],
          });
          setScheduleUpdated(true);
        }
      }
    },
    [editedSchedule, activePeriodID]
  );

  const addNewPeriod = useCallback(
    (newTimeslot: TimeSlot) => {
      updatePeriodTimeSetting(newTimeslot);
      setNewTimeslot(newTimeslot.id);
    },
    [updatePeriodTimeSetting]
  );

  const activePeriod = useMemo(
    () => editedSchedule.periodSettings.find((p) => p.id === activePeriodID),
    [editedSchedule, activePeriodID]
  );

  const [newTimeslot, setNewTimeslot] = useState<null | string>(null);

  const sortedTimeslots = useMemo(
    () =>
      activePeriod?.timeslots.sort((a, b) => {
        return a.endTime.valueOf() - b.endTime.valueOf();
      }),
    [activePeriod]
  );

  const fs = useFirestore();
  const { user } = useGlobalState();
  const idToken = useIdToken();

  const saveSchedule = async () => {
    if (saving || !projectID || !user || !idToken) {
      throw new Error("Error saving schedule");
    }
    const tagAlreadyExists = dataset?.dataTags?.some((t) => t === editedSchedule.tag);
    if (!startSchedule && tagAlreadyExists) {
      throw new Error("Tag already exists in dataset");
    }

    const executionID = dataset?.lastest_main_execution || getUUID();

    setSaving(true);

    const batch = fs.batch();
    const projectDoc = fs.collection("Projects").doc(dataset?.projectID || projectID!);
    const datasetDoc = projectDoc.collection("Scenarios").doc(dataset?.id);

    const schedule = { ...editedSchedule };

    //updload the data:
    const scheduleCol = {
      tag: schedule.tag,
      values: getScheduleDataHourlyForYear(schedule),
    };
    const indexCol = {
      tag: "_index",
      values: scheduleCol.values.map((v, i) => {
        return i * 3600;
      }),
    };

    //todo check start index in original data?
    const metadata =
      scenarioMetadata ||
      new ScenarioMetaData()
        .setScenario(datasetDoc.id)
        .setEpochType("s")
        .setTimezone("utc")
        .setOffset(0)
        .setTagsList([
          new Tag().setName("_index").setType("double"),
          new Tag().setName(schedule.tag).setType("double"),
        ])
        .setExecution(executionID);

    if (scenarioMetadata && !tagAlreadyExists) {
      const updatedTags = [
        ...scenarioMetadata.getTagsList(),
        new Tag().setName(schedule.tag).setType("double"),
      ];
      metadata.setTagsList(updatedTags);
    }

    await uploadTagData(grpcURL, idToken, {
      scenarioID: datasetDoc.id,
      projectID,
      data: [indexCol, scheduleCol],
      alreadyExists: !!dataset?.lastest_main_execution,
      execution: executionID,
      metadata,
    });

    console.log("UPLOADED DATA SUCCESS");

    let updatedDataset = dataset || {
      id: datasetDoc.id,
      scenarioName: "Schedules",
      projectID: projectID,
      created: dayjs(),
      ownerId: user.id,
      type: "dataset",
      dataTags: [schedule.tag],
      schedules: { [schedule.tag]: schedule },
    };
    updatedDataset.lastest_main_execution = executionID;

    if (dataset) {
      const prevSchedules = dataset.schedules || {};
      updatedDataset.schedules = { ...prevSchedules, [schedule.tag]: schedule };
      if (!tagAlreadyExists)
        updatedDataset.dataTags = updateArrayValRawString(dataset.dataTags, schedule.tag);

      batch.update(datasetDoc, convertToFirestoreFormat(updatedDataset));
    } else {
      batch.set(datasetDoc, convertToFirestoreFormat(updatedDataset));
      batch.update(projectDoc, { scenarios: fsFieldvalue.arrayUnion(datasetDoc.id) });
    }

    await batch.commit();
    setSaving(false);
    setSavingScheduleOpen(false);
    console.log({ toReturn: { dataset: updatedDataset, schedule: schedule } });
    return { dataset: updatedDataset, schedule: schedule };
  };

  const renderSavingSchedule = () => {
    return (
      <Modal onClose={() => setSavingScheduleOpen(false)}>
        <div className="modal-content z-40 w-2/3 relative">
          <div className="text-lg text-center font-medium mb-4">Save new schedule</div>
          <>
            <div className="text-xs font-medium">Schedule tag name</div>
            <input
              className="input-box w-full mb-4"
              value={editedSchedule.tag}
              onChange={(e) => {
                setEditedSchedule({ ...editedSchedule, tag: e.target.value });
              }}
            />
          </>

          <div className="flex">
            <button
              className="button-small mr-1 flex-1"
              onClick={() => {
                saveSchedule()
                  .then((scheduleDataset) => {
                    onFinished(scheduleDataset);
                    Toast("Succesfully saved schedule", { icon: "success" });
                  })
                  .catch((e) => {
                    console.log(e);
                    setSaving(false);
                    Toast("Eror saving schedule");
                  });
              }}
            >
              Save
            </button>
            <button
              className="button-small mr-1 flex-1"
              onClick={() => {
                setSavingScheduleOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          {saving && <LoadingOverlay />}
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Modal
        onClose={() => {
          onFinished();
        }}
      >
        <div className="modal-content z-30 w-full relative">
          <div className="font-bold ">{startSchedule ? "Edit" : "New"} Schedule</div>
          {startSchedule && (
            <div className="text-xs">
              For tag: <span className="italic">{startSchedule.tag}</span>
            </div>
          )}
          <div className="mt-2 w-full flex relative">
            <div className="w-2/3 pr-4 min-h-64 flex flex-col">
              <DualButton
                optionOne="Weekday"
                optionTwo="Weekends"
                active={activePeriodID === "weekday" ? "one" : "two"}
                onClickOne={() => {
                  setActivePeriodID("weekday");
                }}
                onClickTwo={() => {
                  setActivePeriodID("weekends");
                }}
              />
              <div className="">
                {activePeriod && <ScheduleDayGraph period={activePeriod} />}
              </div>
            </div>

            <div className="w-1/3 min-h-64 pl-4 flex flex-col pt-6">
              <div className="flex justify-end w-full mb-4">
                <AddTimeslot onAdd={addNewPeriod} />
              </div>

              <div className="">
                {sortedTimeslots &&
                  sortedTimeslots.map((slot) => {
                    const isNew = newTimeslot === slot.id;
                    return (
                      <TimeSlotEditable
                        key={slot.id}
                        slot={slot}
                        unit={unit}
                        onUpdate={updatePeriodTimeSetting}
                        isNew={isNew}
                      />
                    );
                  })}
                {activePeriod && (
                  <div
                    className={`flex items-center my-2  border rounded overflow-hidden ${
                      newTimeslot === "default" ? "border-green-numerous" : "border-gray-200"
                    }`}
                    onClick={() => {
                      setNewTimeslot(newTimeslot === "default" ? null : "default");
                    }}
                  >
                    <div className={`w-1/2 px-2 py-1 text-xs`}>Default</div>
                    <InputNumber
                      className={`w-1/2 py-1 px-2 text-right text-xs focus:outline-none`}
                      value={activePeriod.defaultValue}
                      onChange={(val) => {
                        setEditedSchedule({
                          ...editedSchedule,
                          periodSettings: updateArrayVal(editedSchedule.periodSettings, {
                            ...activePeriod,
                            defaultValue: val,
                          }),
                        });
                        setScheduleUpdated(true);
                      }}
                    />
                    {unit && <div className="text-xs px-2">{unit}</div>}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex">
            {startSchedule && (
              <button
                className={`button-small flex-1 mr-2 ${scheduleUpdated ? "" : "opacity-50"}`}
                onClick={() => {
                  if (scheduleUpdated) {
                    saveSchedule()
                      .then((scheduleDataset) => {
                        onFinished(scheduleDataset);
                        Toast("Succesfully saved schedule", { icon: "success" });
                        setScheduleUpdated(false);
                      })
                      .catch((e) => {
                        console.log(e);
                        setSaving(false);
                        Toast("Eror saving schedule");
                      });
                  }
                }}
              >
                Update schedule
              </button>
            )}
            <button
              className="button-small flex-1 mx-2"
              onClick={() => {
                setSavingScheduleOpen(true);
              }}
            >
              {startSchedule ? "Save as new" : "Save"}
            </button>
            <button className="button-small flex-1 ml-2" onClick={() => onFinished()}>
              Cancel changes
            </button>
          </div>
          {saving && <LoadingOverlay />}
        </div>
      </Modal>
      {savingScheduleOpen && renderSavingSchedule()}
    </>
  );
};

export default ScheduleInput;

//Inserts new timeslot into previous and makes sure no previous values overlap.
const getCleanTimeslots = (timeslots: TimeSlot[], newTimeslot: TimeSlot) => {
  const cleanTSStart: TimeSlot[] = [];
  const cleanTS = timeslots.reduce((prev, cur) => {
    //if cur is the one being updated:
    if (cur.id === newTimeslot.id) return prev;
    //if cur is between newTS completely, remove it
    if (
      cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]") &&
      cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]")
    )
      return prev;
    //if cur overlaps newTS completely, split it into two
    if (
      cur.startTime.isBefore(newTimeslot.startTime) &&
      cur.endTime.isAfter(newTimeslot.endTime)
    )
      return [
        ...prev,
        { ...cur, id: uuid().replace(/-/gi, "_"), endTime: newTimeslot.startTime },
        { ...cur, startTime: newTimeslot.endTime },
      ];

    //If overlap only one time with new TS:
    if (cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, startTime: newTimeslot.endTime.clone() }];
    if (cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, endTime: newTimeslot.startTime.clone() }];
    //no overlap..
    return [...prev, cur];
  }, cleanTSStart);

  return [...cleanTS, newTimeslot];
};

//get the hourly values for the year
export const getScheduleDataHourlyForYear = (schedule: Schedule) => {
  let timeIterater = dayjs().startOf("year");
  const endTime = timeIterater.add(1, "year");
  let data: number[] = [];
  const includeWeekends = schedule.periodSettings.some((p) => p.type === "Weekends");
  const includeHolidays = schedule.periodSettings.some((p) => p.type === "Holidays");
  const includeWeekdays = schedule.periodSettings.some((p) => p.type === "Weekdays");

  //Run once for each day during the year:
  while (timeIterater.isBefore(endTime)) {
    //Check what type of day it is and get data for the type of day.
    let nextDataPoints: number[] = [];
    if (includeHolidays && isHoliday(timeIterater, swedishHoliday)) {
      const period = schedule.periodSettings.find((p) => p.type === "Holidays");
      if (period) nextDataPoints = getDayData(period.timeslots, period.defaultValue);
    } else if (includeWeekends && isWeekend(timeIterater)) {
      const period = schedule.periodSettings.find((p) => p.type === "Weekends");
      if (period) nextDataPoints = getDayData(period.timeslots, period.defaultValue);
    } else if (includeWeekdays && isWeekday(timeIterater)) {
      const period = schedule.periodSettings.find((p) => p.type === "Weekdays");
      if (period) nextDataPoints = getDayData(period.timeslots, period.defaultValue);
    } else if (nextDataPoints.length === 0) {
      const period = schedule.periodSettings.find((p) => p.type === "Other");
      if (period) nextDataPoints = getDayData(period.timeslots, period.defaultValue);
    }

    data = [...data, ...nextDataPoints];
    timeIterater = timeIterater.add(1, "day");
  }
  return data;
};

const getDayData = (timeslots: TimeSlot[], defaultSetting: number) => {
  //Get data for all hours during a single day
  const data: number[] = [];
  let timeI = getDayStart();
  const endTime = timeI.clone().add(1, "day");

  const getSlotForTime = (time: Dayjs) =>
    timeslots.find((ts) => {
      return time.isBetween(ts.startTime, ts.endTime, undefined, "[)");
    });

  while (timeI.isBefore(endTime)) {
    const slot = getSlotForTime(timeI);

    const value = slot ? slot.setting : defaultSetting;

    data.push(value);
    timeI = timeI.add(1, "hour");
  }
  return data;
};

const isWeekday = (date: Dayjs) => {
  const day = date.day();
  return day > 0 && day < 6;
};
const isWeekend = (date: Dayjs) => {
  const day = date.day();
  return day === 0 || day === 6;
};

const isHoliday = (date: Dayjs, holidays: Dayjs[]) => {
  let holiday = false;
  swedishHoliday.forEach((day) => {
    if (day.isSame(date, "day")) holiday = true;
  });
  return holiday;
};

const swedishHoliday = [
  dayjs("6/1", "DD/MM"), //Epiphany
  dayjs("1/1", "DD/MM"), //new years day
  dayjs("31/12", "DD/MM"), //New years eve
  dayjs("24/12", "DD/MM"), //Christmas eve
  dayjs("25/12", "DD/MM"), //Christmas day
  dayjs("26/12", "DD/MM"), //Seccond christmas day
  dayjs("6/6", "DD/MM"), //National day
  // easter stuff......
];

const DefaultWeekendSettings: PeriodSetting = {
  id: "weekends",
  type: "Weekends",
  timeslots: [],
  defaultValue: 0,
};

const DefaultWeekdaySettings: PeriodSetting = {
  id: "weekday",
  type: "Weekdays",
  timeslots: [],
  defaultValue: 0,
};

const getDefaultNewSchedule = (tag?: string) => {
  const newSchedule: Schedule = {
    tag: tag || "",
    periodSettings: [DefaultWeekdaySettings, DefaultWeekendSettings],
  };
  return newSchedule;
};
