import React, { useState } from "react";
import { useComponentLibrary } from "api/useFirebase";
import { ComponentType } from "model/datatypes";
import Modal from "components/basic/Modal";
import ComponentEditer from "components/systems/editSystem/componentEditor/ComponentEditor";
import Toast from "components/basic/Toast";

const ComponentLibrary = () => {
  const { componentLib, removeComponentFromLib, updateLibraryComponent } =
    useComponentLibrary();
  const [selectedComp, setSelectedComp] = useState<ComponentType | null>(null);
  const [editingComponent, setEditingComponent] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="bg-gray-bg w-1/2 px-4 py-4 border border-gray-300 shadow-lg rounded relative z-40">
        <div className="font-medium mb-4">Component library</div>
        <div className="flex flex-wrap">
          {componentLib.map((libComp) => {
            const isSelected = libComp.id === selectedComp?.id;
            return (
              <div
                className={`mr-4 mb-4 shadow-md rounded bg-white cursor-pointer overflow-hidden hover:opacity-100
              ${!isSelected && !!selectedComp ? "opacity-75" : ""}
            `}
                onClick={() => setSelectedComp(isSelected ? null : libComp)}
              >
                <div
                  className={`border-4 overflow-hidden px-2 py-2 ${
                    isSelected ? "border-green-300" : "border-white"
                  }`}
                >
                  <div className="font-medium">{libComp.displayName}</div>
                  <div className="text-xs mt-3">
                    {libComp.parameters ? libComp.parameters.length : 0} parameters
                  </div>
                  <div className="text-xs">
                    {libComp.inputVariables.length} input variables
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <button
            className={`button-small 
            ${selectedComp ? "" : "opacity-50"} mr-2`}
            onClick={() => selectedComp && removeComponentFromLib(selectedComp.id)}
          >
            Remove
          </button>
          <button
            className={`button-small 
            ${selectedComp ? "" : "opacity-50"} mr-2`}
            onClick={() => setEditingComponent(true)}
          >
            Edit
          </button>
        </div>
      </div>
      {editingComponent && selectedComp && (
        <Modal zIndex={50} onClose={() => setEditingComponent(false)}>
          <div className="z-50 bg-white max-h-screen overflow-y-auto">
            <ComponentEditer
              systemRef={undefined}
              comp={selectedComp}
              updateComponent={(componentID: string, updates: Partial<ComponentType>) => {
                setSelectedComp({ ...selectedComp, ...updates });
              }}
              subCompOptions={[]}
              allowedParamRefs={[]}
            />
            <div className="flex w-full px-2 mt-4">
              <button
                className="button-small flex-1 mr-2"
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                    updateLibraryComponent(selectedComp.id, selectedComp)
                      .then(() => {
                        setEditingComponent(false);
                        setLoading(false);
                      })
                      .catch((e) => {
                        Toast("Error saving");
                        setLoading(false);
                      });
                  }
                }}
              >
                Save
              </button>
              <button className="button-small flex-1 ml-2">Cancel</button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ComponentLibrary;
