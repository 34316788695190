import React from "react";
import { invlerp } from "utils/jsUtils/lerp";

interface SliderProps {
  value: number;
  min: number;
  max: number;
  unit?: string;
  step?: number;
  updateValue: (newValue: number) => void;
}

const RangeSlider: React.FC<SliderProps> = ({ value, min, max, unit, updateValue, step }) => {
  const procent = invlerp(min, max, value) * 100;
  return (
    <div className="relative w-full flow-slider">
      <input
        type="range"
        className="w-full z-10"
        style={{ marginTop: "0.125rem" }}
        value={value}
        min={min}
        max={max}
        step={step || 1}
        onChange={(e) => {
          const newVal = parseFloat(e.target.value);
          updateValue(newVal);
        }}
      />
      <div className="absolute top-0 left-0 -mt-4" style={{ marginLeft: `${procent}%` }}>
        <div className="font-medium -ml-4 w-8 flex justify-center">
          {value} {unit}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
