import { useFirestore } from "api/useFirebase";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import Toast from "components/basic/Toast";
import { ComponentParameter, ComponentParamType } from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import { ParamTypeToInstance } from "utils/ComponentTypeHelpers";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import Parameter from "./newSimulation/simSetup/simComponent/Parameter";

interface Props {
  parameters: ComponentParameter[];
  parameterTypes: ComponentParamType[];
  updateParameters: (updatedParams: ComponentParameter[]) => void;
}

const ParameterSetup: React.FC<Props> = ({ parameters, parameterTypes, updateParameters }) => {
  //hide reference and hidden comp from scenario
  const shownParameters = useMemo(
    () =>
      parameters?.filter(
        (param) =>
          param.type !== "reference" &&
          param.displayMode !== "hidden" &&
          param.displayMode !== "advanced"
      ),
    [parameters]
  );
  const advancedParameters = useMemo(
    () => parameters?.filter((param) => param.displayMode === "advanced"),
    [parameters]
  );
  const [showAdvanced, setShowAdvanced] = useState(false);
  if (shownParameters.length === 0 && advancedParameters.length === 0) return null;
  return (
    <>
      {shownParameters.length > 0 && (
        <div className="flex flex-wrap text-xs">
          {shownParameters.map((param, i) => {
            const protoParam = parameterTypes.find((p) => param.id === p.id);
            const hideBorder = isLastRow(shownParameters.length, 2, i);
            return (
              <Parameter
                key={param.uuid}
                parameter={param}
                paramType={protoParam}
                hideBorder={hideBorder}
                onUpdate={(updatedParam) => {
                  updateParameters(updateArrayVal(parameters, updatedParam));
                }}
              />
            );
          })}
        </div>
      )}
      {advancedParameters.length > 0 && (
        <div className="bg-gray-100 rounded px-2 mb-2">
          <div
            className="text-xs font-medium flex items-center cursor-pointer px-2 py-2"
            onClick={() => setShowAdvanced((prev) => !prev)}
          >
            <div className="mr-2">{showAdvanced ? "Hide" : "Show"} advanced</div>
            <OpenCloseArrow isOpen={showAdvanced} />
          </div>
          {showAdvanced && (
            <div className="flex flex-wrap">
              {advancedParameters.map((param, i) => {
                const hideBorder = isLastRow(advancedParameters.length, 2, i);
                const protoParam = parameterTypes.find((p) => param.id === p.id);
                return (
                  <Parameter
                    key={param.uuid}
                    parameter={param}
                    paramType={protoParam}
                    hideBorder={hideBorder}
                    onUpdate={(updatedParam) => {
                      updateParameters(updateArrayVal(parameters, updatedParam));
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      <hr className=" border-gray-200" />
    </>
  );
};

const isLastRow = (totalItems: number, itemsPerRow: number, i: number) => {
  let lastRowStart = totalItems - itemsPerRow + (totalItems % itemsPerRow);
  return i >= lastRowStart;
};

export default ParameterSetup;

//For group and project Parmaters:
export const ProjectTypeParameters: React.FC<{
  paramHeadline?: string;
  parameters?: ComponentParameter[];
  paramTypes: ComponentParamType[];
  updateParameters: (updated: ComponentParameter[]) => Promise<void>;
}> = ({ parameters, paramTypes, updateParameters, paramHeadline }) => {
  const [editedParameters, setEditedParameters] = useState(parameters || []);
  const [isChanged, setisChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const fs = useFirestore();

  //check group contains all fixedgroup parameters.
  useEffect(() => {
    const updatedParms = checkParametersUpdated(paramTypes, parameters);
    if (updatedParms) {
      updateParameters(updatedParms);
      setEditedParameters(updatedParms);
      setisChanged(false);
    }
  }, [paramTypes, parameters, updateParameters, fs]);

  if (!parameters) return null;
  else {
    return (
      <div className="my-4">
        <div
          onClick={() => setOpen((p) => !p)}
          className="w-full border-b border-gray-200 flex items-center cursor-pointer pb-2"
        >
          <div className="text-xs font-medium mr-2">{paramHeadline || "Parameters"}</div>
          <OpenCloseArrow isOpen={open} />
        </div>
        {open && (
          <ParameterSetup
            parameters={editedParameters}
            parameterTypes={paramTypes}
            updateParameters={(updated) => {
              setEditedParameters(updated);
              setisChanged(true);
            }}
          />
        )}
        {isChanged && (
          <div className="flex items-center mt-2">
            <button
              className="button-small"
              onClick={() => {
                if (loading) return;
                setLoading(true);
                updateParameters(editedParameters)
                  .then(() => {
                    setisChanged(false);
                    setLoading(false);
                  })
                  .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    Toast("Error saving parameters....");
                  });
              }}
            >
              Save
            </button>
            <button
              className="ml-2 button-small"
              onClick={() => {
                setEditedParameters(parameters);
                setisChanged(false);
              }}
            >
              cancel
            </button>
          </div>
        )}
      </div>
    );
  }
};

const checkParametersUpdated = (
  parameterTypes: ComponentParamType[],
  parameters?: ComponentParameter[]
) => {
  let updatedParams = parameterTypes.map((pt) => ParamTypeToInstance(pt));

  //if no previous parameters
  if (!parameters) return updatedParams;

  //check for updates:
  let updated = false;
  updatedParams = updatedParams.map((param) => {
    const existing = parameters.find((p) => p.uuid === param.uuid);
    if (!existing || existing.type !== param.type) {
      updated = true;
      return param;
    } else {
      return existing;
    }
  });

  //check for deleted (type no longer exists)
  const deletedParams = parameters.filter(
    (p) => !parameterTypes.some((type) => type.uuid === p.uuid)
  );
  if (deletedParams.length > 0) updated = true;

  if (updated) return updatedParams;
};
