import React, { createContext, useReducer, useContext, useMemo, useEffect } from "react";
import { FullUser, Comment, Group, SimulationModel } from "model/datatypes";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import { CloudProject, getDefaultCloudProject } from "components/login/useCloudProjects";

export type AppState = {
  projectID: null | string;
  projectName: null | string;
  projectDescription?: null | string;
  groups: Group[];
  selectedGroups: string[];
  user: null | FullUser;
  teamIds: string[];
  gproject: string;
  organisationName: string | null;
  grpcURL: string;
  google_project: string;
  linkedScenario: string | null;
  inLocalDevMode?: boolean;
  experimentalFeaturesEnabled: boolean;
  isAddingComment: boolean;
  commentTarget?: Partial<Comment>;
  comments: Comment[];
  openScenarios: {
    id: string;
    scenarioID: string;
    scenarioName: string;
    groupID: string;
    edited: boolean;
    saving: boolean;
  }[];
  activeScenario?: string; //the one being viewed right now...
  highlightedComment: string;
  clipboard?: { type: "system"; object: SimulationModel };
  sidebarState: "wide" | "narrow";
};

export type ActionType =
  | {
      type: "SET_CURRENT_PROJECT";
      payload: {
        projectID: null | string;
        projectName: string | null;
      };
    }
  | { type: "SET_GROUPS"; payload: Group[] }
  | { type: "SELECT_GROUP"; payload: string }
  | { type: "DESELECT_GROUP"; payload: string }
  | { type: "SET_LINKED_SCENARIO"; payload: string | null }
  | { type: "SET_USER"; payload: FullUser | null }
  | { type: "SET_TEAMS"; payload: string[] }
  | {
      type: "SET_CLOUD_PROJECT";
      payload: CloudProject;
    }
  | { type: "SET_IN_DEV_MODE" }
  | { type: "ENABLE_EXPERIMENTAL_FEATURES" }
  | { type: "START_ADDING_COMMENT" }
  | { type: "CANCEL_ADDING_COMMENT" }
  | { type: "SELECT_COMMENT_TARGET"; payload: AppState["commentTarget"] }
  | { type: "FINISHED_ADDING_COMMENT" }
  | { type: "UPDATE_COMMENTS"; payload: AppState["comments"] }
  | {
      type: "OPEN_SCENARIO";
      payload: {
        id: string;
        scenarioID: string;
        scenarioName: string;
        groupID: string;
        edited: boolean;
        saving: boolean;
      };
    }
  | { type: "CLOSE_SCENARIO"; payload: string }
  | { type: "SET_ACTIVE_SCENARIO"; payload: string | undefined }
  | { type: "SET_HIGHLIGHTED_COMMENT"; payload: AppState["highlightedComment"] }
  | { type: "UPDATE_CLIPBOARD"; payload: AppState["clipboard"] }
  | { type: "SET_SIDEBAR_STATE"; payload: AppState["sidebarState"] }
  | {
      type: "UPDATE_OPEN_SCENARIO_STATE";
      payload: { scenarioID: string; edited?: boolean; saving?: boolean };
    };

type ContextState = { state: AppState; dispatch: React.Dispatch<ActionType> };

//@ts-ignore
const store = createContext<ContextState>();
const { Provider } = store;

const stdCloudProject = getDefaultCloudProject();

const initialState: AppState = {
  projectID: null,
  projectName: null,
  selectedGroups: [],
  user: null,
  teamIds: [],
  gproject: stdCloudProject.id,
  organisationName: stdCloudProject.name,
  grpcURL: stdCloudProject.api,
  google_project: stdCloudProject.google_project,
  linkedScenario: null,
  isAddingComment: false,
  highlightedComment: "",
  comments: [],
  groups: [],
  openScenarios: [],
  sidebarState: "wide",
  experimentalFeaturesEnabled: false,
};

type GlobalStateReducer = (state: AppState, action: ActionType) => AppState;

const reducer: GlobalStateReducer = (state: AppState, action: ActionType) => {
  console.log(action);
  switch (action.type) {
    case "SET_CURRENT_PROJECT":
      return {
        ...state,
        projectID: action.payload.projectID,
        projectName: action.payload.projectName,
        selectedGroups: [],
        openScenarios: [],
        scenariosEdited: [],
      };
    case "SET_GROUPS":
      return { ...state, groups: action.payload };
    case "SELECT_GROUP":
      return { ...state, selectedGroups: [...state.selectedGroups, action.payload] };
    case "DESELECT_GROUP":
      return {
        ...state,
        selectedGroups: state.selectedGroups.filter((group) => group !== action.payload),
      };
    case "SET_LINKED_SCENARIO":
      return { ...state, linkedScenario: action.payload };
    case "SET_IN_DEV_MODE":
      return { ...state, inLocalDevMode: true };
    case "ENABLE_EXPERIMENTAL_FEATURES":
      return { ...state, experimentalFeaturesEnabled: true };
    case "SET_USER":
      const projectID = action.payload?.selectedProject
        ? action.payload?.selectedProject
        : state.projectID;
      return { ...state, user: action.payload, projectID };
    case "SET_TEAMS":
      return { ...state, teamIds: action.payload };
    case "SET_CLOUD_PROJECT":
      return {
        ...state,
        gproject: action.payload.id,
        organisationName: action.payload.name,
        google_project: action.payload.google_project,
        grpcURL: action.payload.api,
      };
    case "START_ADDING_COMMENT":
      return {
        ...state,
        isAddingComment: true,
      };
    case "CANCEL_ADDING_COMMENT":
      return {
        ...state,
        isAddingComment: false,
      };
    case "SELECT_COMMENT_TARGET":
      return {
        ...state,
        isAddingComment: false,
        commentTarget: action.payload,
      };
    case "SET_HIGHLIGHTED_COMMENT":
      return {
        ...state,
        highlightedComment: action.payload,
      };
    case "FINISHED_ADDING_COMMENT":
      return { ...state, isAddingComment: false, commentTarget: undefined };
    case "UPDATE_COMMENTS":
      return {
        ...state,
        comments: action.payload,
      };
    case "OPEN_SCENARIO":
      document.body.style.overflow = "hidden";
      return {
        ...state,
        openScenarios: [...state.openScenarios, action.payload],
        activeScenario: action.payload.scenarioID,
      };
    case "CLOSE_SCENARIO":
      document.body.style.overflow = "";
      return {
        ...state,
        openScenarios: state.openScenarios.filter(
          (openScenario) => openScenario.scenarioID !== action.payload
        ),
        activeScenario: undefined,
      };
    case "SET_ACTIVE_SCENARIO":
      document.body.style.overflow = action.payload ? "hidden" : "";
      return {
        ...state,
        activeScenario: action.payload,
      };
    case "UPDATE_CLIPBOARD":
      return {
        ...state,
        clipboard: action.payload,
      };
    case "SET_SIDEBAR_STATE":
      return {
        ...state,
        sidebarState: action.payload,
      };
    case "UPDATE_OPEN_SCENARIO_STATE":
      const { scenarioID, ...changedState } = action.payload;
      const editedOpenScenario = state.openScenarios.find(
        (os) => os.scenarioID === scenarioID
      );
      if (editedOpenScenario)
        return {
          ...state,
          openScenarios: updateArrayVal(state.openScenarios, {
            ...editedOpenScenario,
            ...changedState,
          }),
        };
      else return state;

    default:
      return state;
  }
};

const StateProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    //@ts-ignore
    if (window.Cypress) {
      //@ts-ignore
      window.appDispatch = dispatch;
      //@ts-ignore
      window.appState = state;
    }
  }, [state]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useSelector: (
  selector: (state: AppState) => Partial<AppState>
) => Partial<AppState> | undefined = (selector) => {
  const { state } = useContext(store);
  const returnVal = useMemo(() => {
    return selector(state);
  }, [state, selector]);
  return returnVal;
};

const useGlobalState = () => {
  const { state } = useContext(store);
  return state;
};
const useGlobalDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};
const useGlobalStore = () => {
  const { state, dispatch } = useContext(store);
  return { state, dispatch };
};

export {
  store,
  StateProvider,
  useSelector,
  useGlobalState,
  useGlobalDispatch,
  useGlobalStore,
};
