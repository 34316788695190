import { useEffect, useState } from "react";
import { useGlobalState } from "store";

export type CloudProject = { id: string; name: string; google_project: string; api: string };

export const useCloudProjects = () => {
  const [cloudProjects, setCloudProjects] = useState<CloudProject[]>(stdCloudProjects);
  const { inLocalDevMode } = useGlobalState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const cp: CloudProject[] = [...stdCloudProjects];
    if (inLocalDevMode) {
      cp.push(localEmulatorProjects);
      setCloudProjects(cp);
      setLoading(false);
    }
  }, [inLocalDevMode]);

  return { cloudProjects, loading };
};

export const getDefaultCloudProject = () => {
  const cachedOrganisationID = localStorage.getItem("cloudProjectID");
  const cloudProject = [...stdCloudProjects, localEmulatorProjects].find(
    (o) => o.id === cachedOrganisationID
  );
  return cloudProject || stdCloudProject;
};

export const stdCloudProject: CloudProject = {
  id: "EM",
  name: "Energy Machines",
  google_project: "simulationwidgets",
  api: "https://api-energymachines.numerously.com:50060",
};

export const stdCloudProjects = [
  stdCloudProject,
  {
    id: "numerous-development",
    name: "Numerous Devevelopment",
    google_project: "numerous-development",
    api: "https://api-development.numerously.com:50060",
  },
];

const localEmulatorProjects = {
  id: "local",
  name: "Local emulators",
  google_project: "",
  api: "https://api-development.numerously.com:50060",
};
