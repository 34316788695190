import React, { useMemo, useState } from "react";

import { SimulationJob, SimulationScenario } from "model/datatypes";
import { useExcecutionLog } from "grpc/grpcReact";
import Dropdown from "components/basic/Dropdown";
import LoadingOverlay from "components/basic/LoadingOverlay";
import dayjs from "dayjs";

interface Props {
  jobs?: SimulationScenario["jobs"];
  job?: SimulationJob;
  scenarioName: string;
}

const ConsoleLogger: React.FC<Props> = ({ jobs, scenarioName, job }) => {
  const jobEntries = useMemo(() => jobs && Object.entries(jobs), [jobs]);

  const [selectedJob, setSelectedJob] = useState<SimulationJob | undefined>(job);
  const [selectedExcecutionId, setSelectedExcecutionId] = useState(
    job?.most_recent_execution?.execution || undefined
  );

  const executionOptions = useMemo(() => {
    return (
      selectedJob?.execution_history
        ?.sort((a, b) => {
          return b.started.valueOf() - a.started.valueOf();
        })
        .map((excecution) => {
          return {
            id: excecution.execution,
            display: excecution.started?.format("HH:mm DD/MM YYYY"),
          };
        }) || []
    );
  }, [selectedJob]);

  const { loadingLogs, logs } = useExcecutionLog(selectedExcecutionId);

  const renderExecutionSelecter = () => {
    return (
      <div className="py-2 px-8">
        <Dropdown
          className="w-64 text-xs"
          selectedID={selectedExcecutionId}
          options={executionOptions}
          onSelect={(option) => {
            setSelectedExcecutionId(option.id);
          }}
        />
      </div>
    );
  };

  return (
    <div className={` bg-white z-50 shadow-lg relative rounded w-full`}>
      <div className="font-bold px-8 pt-4">Job log</div>
      <div className="text-xs font-medium my-2 px-8">{scenarioName}</div>
      <div className="flex flex-wrap px-8">
        {jobEntries?.map(([id, job]) => {
          const active = selectedJob?.image.id === job.image?.id;
          return (
            <button
              key={id}
              className={`button-small mr-2 ${active ? "" : "opacity-50"}`}
              onClick={() => {
                setSelectedJob(job);
                setSelectedExcecutionId(job.most_recent_execution?.execution);
              }}
            >
              {job.name}
            </button>
          );
        })}
        {!jobEntries && <div className="text-xs">{job?.name}</div>}
      </div>
      {renderExecutionSelecter()}
      <div
        className="text-xxs overflow-y-auto font-mono w-full bg-gray-100 px-8 scrollbar-light"
        style={{ maxHeight: "60vh" }}
      >
        {logs.map((log) => {
          return (
            <div key={log.timestamp} className="flex my-2">
              <span className="pr-4 w-1/8 flex-none">
                {dayjs.unix(log.timestamp).format("DD/MM HH:mm:ss")}
              </span>
              <span className="flex-grow whitespace-pre-line">{log.logEntry}</span>
            </div>
          );
        })}
      </div>
      {loadingLogs && <LoadingOverlay />}
    </div>
  );
};

export default ConsoleLogger;
