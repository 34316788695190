import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import clamp from "utils/clamp";
import { updateArrayItemPosition } from "utils/jsUtils/imutableArray";

export type RepositionListItem = {
  id: string;
  element: JSX.Element;
  val?: any;
};
type OrderedListItem = { order: number } & RepositionListItem;

interface Props {
  list: RepositionListItem[];
  onReposition: (updatedList: RepositionListItem[]) => void;
  onDragEnd?: () => void;
  className?: string;
  enableDragOverlay?: boolean;
  dragableID?: null | string;
}

const RepositionList: React.FC<Props> = ({
  list,
  onReposition,
  onDragEnd,
  className,
  enableDragOverlay,
  dragableID,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const reposition = (item: OrderedListItem, yPosition: number) => {
    const newI = findChildItemPosition(yPosition);
    if (newI !== item.order) {
      //change the order to fit the new order:
      const reorderedList = updateArrayItemPosition(
        displayList,
        item,
        newI
      ) as OrderedListItem[];
      const withUpdatedOrder = reorderedList.map((li, i) => ({ ...li, order: i })); // set the order
      setDisplayList(withUpdatedOrder);
    }
  };

  const findChildItemPosition = (yPosition: number) => {
    const children = ref.current?.children;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        const element = children.item(i);
        if (element) {
          const box = element.getBoundingClientRect();
          const elementYCenter = box.y + box.height / 2;
          if (elementYCenter > yPosition) return clamp(i - 1, 0);
        }
      }
      return children.length - 1;
    }
    return 0;
  };

  const [displayList, setDisplayList] = useState<OrderedListItem[]>(
    list.map((li, i) => ({ ...li, order: i }))
  );

  useEffect(() => {
    setDisplayList(list.map((li, i) => ({ ...li, order: i })));
  }, [list]);

  return (
    <div ref={ref} className={className || ""}>
      {displayList.map((li) => {
        const disableDrag = dragableID !== undefined && dragableID !== li.id;
        const showDragGuide = enableDragOverlay && !disableDrag;
        return (
          <ListItem
            onDrag={(yPos) => {
              reposition(li, yPos);
            }}
            onDragEnd={() => {
              const origOrder = list.findIndex((l) => l.id === li.id);
              if (origOrder !== li.order) onReposition(displayList);
              onDragEnd && onDragEnd();
            }}
            key={li.id}
            item={li}
            showGuideOverlay={showDragGuide}
            disableDrag={disableDrag}
          />
        );
      })}
    </div>
  );
};

export default RepositionList;

const ListItem: React.FC<{
  item: RepositionListItem;
  onDrag: (yPos: number) => void;
  onDragEnd: () => void;
  disableDrag?: boolean;
  showGuideOverlay?: boolean;
}> = ({ item, onDrag, onDragEnd, showGuideOverlay, disableDrag }) => {
  const listItemRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  // const variants = {
  //   visible: { opacity: 1 },
  //   hidden: { opacity: 0 },
  // }
  const renderGuideOverlay = () => {
    return (
      <AnimatePresence>
        {showGuideOverlay && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-green-numerous z-20 text-white cursor-pointer"
          >
            <VerticalDragIcon />
            <div className="font-medium text-lg">Drag</div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <motion.div
      className={"relative"}
      ref={listItemRef}
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={1}
      dragMomentum={false}
      // animate={dragging ? { zIndex: 100 } : { zIndex: 0 }}
      style={dragging ? { zIndex: 10 } : { zIndex: 0 }}
      layout="position"
      onDrag={(e, info) => {
        const height = listItemRef.current?.scrollHeight || 0;
        const yPosition = info.point.y - window.pageYOffset + height / 2;
        onDrag(yPosition);
      }}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => {
        setDragging(false);
        onDragEnd();
      }}
      drag={!disableDrag && "y"}
      layoutId={item.id}
    >
      {renderGuideOverlay()}
      {item.element}
    </motion.div>
  );
};
// const onTop = { zIndex: 20 };
// const flat = {
//   zIndex: 0,
//   transition: { delay: 0.3 },
// };

const VerticalDragIcon = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"></path>
    </svg>
  );
};
