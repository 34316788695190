import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useGlobalDispatch, useGlobalState } from "store";
import { useProjects, useFirebase } from "api/useFirebase";
import { useUserRole } from "api/useAuth";
import { Popup } from "components/basic/Popup";
import SimIcon from "components/basic/icons/SimIcon";
import CodeIcon from "components/basic/icons/CodeIcon";
import ArrowIcon from "components/basic/icons/ArrowIcon";
import Modal from "components/basic/Modal";
import EMLogoText from "resources/EMlogoText.svg";
import EMLogoNoText from "resources/EMLogoNoText.svg";
import NumerousLogo from "resources/NumerousLogo";

const isBeta = process.env.REACT_APP_BETA === "true";

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const history = useHistory();

  const dispatch = useGlobalDispatch();
  const {
    projectID,
    projectName,
    openScenarios,
    selectedGroups,
    groups,
    activeScenario,
    sidebarState,
    gproject,
    organisationName,
  } = useGlobalState();
  const { hasDeveloperAccess } = useUserRole();

  const { myProjects } = useProjects();
  const setProject = (projectID: string | null, projectName: string | null) => {
    //save selected project locally...
    if (projectID && projectName) {
      localStorage.setItem("selectedProjectID", projectID);
      localStorage.setItem("selectedProjectName", projectName);
    } else {
      localStorage.removeItem("selectedProjectID");
      localStorage.removeItem("selectedProjectName");
    }

    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: { projectID, projectName },
    });
    dispatch({ type: "SET_ACTIVE_SCENARIO", payload: undefined });
  };

  //when a project not in my projects, is active:
  const otherActiveProject = useMemo(() => {
    return !myProjects.some((p) => p.id === projectID);
  }, [projectID, myProjects]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);

  const orderedOpenScenarios = useMemo(() => {
    return openScenarios
      .filter((os) => selectedGroups.some((gid) => gid === os.groupID))
      .sort((a, b) => {
        //sort based on group order:
        const groupOrderA = groups.findIndex((g) => g.id === a.groupID);
        const groupOrderB = groups.findIndex((g) => g.id === b.groupID);
        if (groupOrderA !== groupOrderB) return groupOrderA - groupOrderB;
        //if in same group:
        const group = groups.find((g) => g.id === a.groupID);
        if (!group) return 0;
        const scenarioOrderA = group?.scenarios.findIndex((sid) => sid === a.scenarioID);
        const scenarioOrderB = group?.scenarios.findIndex((sid) => sid === b.scenarioID);
        return scenarioOrderA - scenarioOrderB;
      });
  }, [openScenarios, groups, selectedGroups]);

  const renderProject = (
    projectID: string,
    projectName: string,
    isActive: boolean,
    description?: string
  ) => {
    return (
      <div
        key={projectID}
        className={`text-gray-700 w-full ${
          isActive ? " border-l-4 border-blue-600 bg-blue-100 py-1" : "opacity-75"
        }`}
      >
        <button
          className={`px-4 flex flex-col ${
            isActive ? "pt-1 pb-2" : "hover:bg-gray-200 py-2"
          } w-full text-left focus:outline-none`}
          onClick={() => {
            if (!isActive) {
              setProject(projectID, projectName);
            }
            history.push("/simulations");
          }}
        >
          <span className="font-bold text-sm">{projectName}</span>
          <span className="text-xs truncate w-full">
            {description ? description : "no project description"}
          </span>
        </button>
        {isActive &&
          currentPage === "simulations" &&
          orderedOpenScenarios.map((openScenario) => {
            const isActiveScenario = openScenario.scenarioID === activeScenario;
            return (
              <div key={openScenario.scenarioID} className="px-2 text-xs w-full">
                <div
                  className={`"w-full cursor-pointer font-medium px-2 py-1 mb-1 flex items-center hover:bg-white rounded-lg  ${
                    isActiveScenario ? "text-blue-600 " : "text-gray-700 opacity-75 "
                  }`}
                  onClick={() => {
                    //scroll to scenario?
                    dispatch({
                      type: "SET_ACTIVE_SCENARIO",
                      payload: openScenario.scenarioID,
                    });
                  }}
                >
                  <SimIcon />
                  <span className="pl-2">{openScenario.scenarioName}</span>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderSidebarBtn = (
    target: string,
    display: string,
    icon: JSX.Element,
    onClick?: () => any
  ) => {
    const isActive = currentPage === target;
    return (
      <Link
        to={`/${target}`}
        data-test="sidebarLink"
        onClick={() => onClick && onClick()}
        className={`flex items-center text-xs hover:bg-gray-200 px-3 py-2
        ${isActive ? "text-blue-600 bg-blue-100 " : `text-gray-800 opacity-75`} 
        ${sidebarState === "narrow" ? "justify-center" : ""}
        ${isActive && sidebarState === "wide" ? "border-blue-600 border-l-2" : ""}`}
      >
        <div className="h-6 w-6">{icon}</div>
        {sidebarState === "wide" && <div className={`ml-3 font-medium`}>{display}</div>}
      </Link>
    );
  };

  return (
    <div
      data-test="sidebar"
      className={` position fixed top-0 left-0 bg-white h-full border-r border-gray-300 flex flex-col text-sm overflow-hidden z-20 ${
        sidebarState === "wide" ? "w-52" : "w-20"
      }`}
    >
      <NumerousLogo className={sidebarState === "wide" ? "w-28 ml-4 mt-6" : "w-full my-4"} />

      {gproject === "EM" && (
        <img
          src={sidebarState === "wide" ? EMLogoText : EMLogoNoText}
          className={`my-1 ${sidebarState === "wide" ? "px-4 w-24" : "px-6"}`}
          alt="organisation logo"
        />
      )}
      {gproject !== "EM" && (
        <div className={`text-xs my-1 ${sidebarState === "wide" ? "px-4" : "text-center"}`}>
          {organisationName}
        </div>
      )}
      {isBeta && (
        <div
          className={`text-xxs text-gray-600 mb-2 ${
            sidebarState === "wide" ? "px-4" : "text-center"
          }`}
        >
          Beta v2.0
        </div>
      )}
      <div className="border-b border-t border-gray-200 py-2">
        <div>
          {renderSidebarBtn("", "Projects", homeIcon, () => setProject(null, null))}
          {hasDeveloperAccess && renderSidebarBtn("systems", "Systems", sysIcon)}
          {hasDeveloperAccess &&
            renderSidebarBtn("administration", "Organization", UserAdministration)}
          {hasDeveloperAccess && renderSidebarBtn("files", "Files", fileIcon)}
          {hasDeveloperAccess && renderSidebarBtn("api", "API", <CodeIcon />)}
        </div>
      </div>

      {otherActiveProject && projectID && projectName && sidebarState === "wide" && (
        <div className="border-b border-gray-200">
          {renderProject(projectID, projectName, true, "")}
        </div>
      )}
      {sidebarState === "narrow" && projectID && (
        <div className="py-4 w-full">
          <Link
            to={"/simulations"}
            className={`w-full flex items-center justify-center py-2 ${
              currentPage === "simulations"
                ? "text-blue-600 bg-blue-100"
                : "text-gray-800 opacity-75 hover:bg-gray-200"
            }`}
          >
            {projectIcon}
          </Link>
        </div>
      )}
      <div className="flex-grow flex flex-col overflow-hidden py-2">
        {sidebarState === "wide" && (
          <>
            <div className="text-gray-700 text-xs px-4 mb-2 font-bold">My projects</div>
            <div className="flex-grow overflow-y-auto overflow-x-hidden scrollbar-light">
              {myProjects.map((project) => {
                const isActive = projectID === project.id && currentPage === "simulations";
                return renderProject(
                  project.id,
                  project.projectName,
                  isActive,
                  project.description
                );
              })}
            </div>
          </>
        )}
      </div>

      <SidebarButtom />
    </div>
  );
};

export default Sidebar;

const SidebarButtom: React.FC = () => {
  const [customGRPCApi, setCustomGRPCApi] = useState<null | string>(null);

  const [editedGRPCApi, setEditedGRPCApi] = useState("");
  const [settingCustom, setSettingCustom] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedCustomEndpoint = localStorage.getItem("customGRPCAPI");
    if (savedCustomEndpoint) setCustomGRPCApi(savedCustomEndpoint);
  }, []);
  const { user, sidebarState } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const fb = useFirebase();

  const resetTutorial = () => {
    if (!loading) {
      setLoading(true);
      fb.firestore()
        .collection("users")
        .doc(user?.fbUser.uid)
        .update({ hasCompletedTutorial: false })
        .catch((err) => console.log(err));
      localStorage.setItem("step", "1");
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`w-full flex items-center justify-between py-4 px-3 border-t border-gray-200 ${
          sidebarState === "wide" ? "" : "flex-col"
        }`}
      >
        <Popup
          mt={-50}
          content={(closeMe) => {
            return (
              <>
                <button
                  className="button-popup text-xs border-b border-gray-200"
                  onClick={() => {
                    setEditedGRPCApi("http://localhost:8090");
                    setSettingCustom(true);
                    closeMe();
                  }}
                >
                  Set custom numerous API
                </button>
                {user?.hasCompletedTutorial ? (
                  <button
                    onClick={() => {
                      resetTutorial();
                      closeMe();
                    }}
                    className="button-popup text-xs border-b border-gray-200"
                  >
                    Reset tutorial
                  </button>
                ) : null}

                <button
                  className={`button-popup text-xs`}
                  name="logoutBtn"
                  onClick={() => {
                    fb.auth().signOut();
                    closeMe();
                  }}
                >
                  log out
                </button>
              </>
            );
          }}
        >
          <div className="cursor-pointer flex items-center">
            <div className="h-8 w-8 flex items-center justify-center uppercase bg-green-numerous font-bold text-white rounded-full">
              {user?.firstName && user.lastName
                ? user?.firstName.slice(0, 1) + user?.lastName.slice(0, 1)
                : "N"}
            </div>
            {sidebarState === "wide" && (
              <div className="ml-4">{user?.fullName || "Logged in"}</div>
            )}
          </div>
        </Popup>
        <ArrowIcon
          className={`cursor-pointer ${sidebarState === "wide" ? "" : "mt-4"}`}
          onClick={() => {
            dispatch({
              type: "SET_SIDEBAR_STATE",
              payload: sidebarState === "wide" ? "narrow" : "wide",
            });
          }}
          direction={sidebarState === "wide" ? "left" : "right"}
        />
      </div>
      {customGRPCApi && !settingCustom && (
        <div
          className="text-xs italic w-full text-center pb-2"
          onClick={() => {
            setSettingCustom(true);
          }}
        >
          <div className="font-medium mr-2">Numerous API:</div>
          <div>{customGRPCApi}</div>
        </div>
      )}
      {settingCustom && (
        <Modal
          onClose={() => {
            setSettingCustom(false);
          }}
        >
          <div className="modal-content z-30 w-1/2">
            <div className="font-medium mb-4">Set numerous API endpoint</div>
            <div className="font-medium text-xs">URL</div>
            <input
              className="input-box mb-4 w-full text-xs"
              type="text"
              value={editedGRPCApi}
              onChange={(e) => {
                setEditedGRPCApi(e.target.value);
              }}
            />
            <div className="flex w-full">
              <button
                className="button-small flex-1 mr-1"
                onClick={() => {
                  setCustomGRPCApi(editedGRPCApi);
                  localStorage.setItem("customGRPCAPI", editedGRPCApi);
                  setSettingCustom(false);
                }}
              >
                Set custom
              </button>
              <button
                className="button-small flex-1 ml-1"
                onClick={() => {
                  setCustomGRPCApi(null);
                  localStorage.removeItem("customGRPCAPI");
                  setSettingCustom(false);
                }}
              >
                Use standard
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const homeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-full w-full"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
  </svg>
);

const sysIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="currentColor"
      d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H2c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1H5V2zm4 14c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-1c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1h-1zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-1c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1h-1V2zm4 14c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z"
    />
  </svg>
);

const UserAdministration = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path
      fill={"currentColor"}
      d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7a2.5 2.5 0 000 5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"
    ></path>
  </svg>
);

const fileIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path
      fill={"currentColor"}
      d="M19.35 10.04A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 000 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"
    ></path>
  </svg>
);

const projectIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
  </svg>
);
