import { Button } from "components/basic/Buttons";
import { ComponentParamType, SimulationModel } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import ParametersEditTable from "./componentEditor/parameters/ParametersEditTable";

interface Props {
  editedSystem: SimulationModel;

  updateParameters: (updated: ComponentParamType[]) => void;
}

const SystemParameters: React.FC<Props> = ({ editedSystem, updateParameters }) => {
  const systemRef = useMemo(() => {
    return {
      id: editedSystem.id,
      displayName: editedSystem.displayName,
    };
  }, [editedSystem.id, editedSystem.displayName]);

  const [showSystemParameters, setShowSystemParameters] = useState(
    editedSystem.parameters.length > 0
  );

  return (
    <div className=" bg-white px-4 py-4 border border-gray-200 rounded">
      <div className={`font-medium text-lg mb-2`}>System parameters</div>
      {!showSystemParameters && (
        <Button onClick={() => setShowSystemParameters(true)} className="">
          Add system parameters
        </Button>
      )}
      {showSystemParameters && (
        <ParametersEditTable
          componentParameters={editedSystem.parameters || []}
          systemRef={systemRef}
          onUpdateParam={(updated) => {
            updateParameters(updateArrayValUUID(editedSystem.parameters, updated));
          }}
          onUpdateAllParms={(updated) => {
            updateParameters(updated);
          }}
          onDelete={(param) => {
            updateParameters(editedSystem.parameters.filter((cp) => cp.uuid !== param.uuid));
          }}
          allowedComponentRefs={undefined}
        />
      )}
    </div>
  );
};

export default SystemParameters;
