const firebaseConfig = {
  apiKey: "AIzaSyATc023XWp0yloa6CmbPBgIGJwMJECzKxM",
  authDomain: "energy-machines-beta.firebaseapp.com",
  databaseURL: "https://energy-machines-beta.firebaseio.com",
  projectId: "energy-machines-beta",
  storageBucket: "energy-machines-beta.appspot.com",
  messagingSenderId: "92707460808",
  appId: "1:92707460808:web:cc998c96fcaa1cc8d8b7fd",
};

export default firebaseConfig;
