import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputScenarioRef, RunSettings, SimulationScenario } from "model/datatypes";
import { Dayjs } from "dayjs";
import { immutableSplice, updateArrayVal } from "utils/jsUtils/imutableArray";
import { TimeSelecterCurrentJob } from "./TimeSelecter";
import InputTimeInfo from "./InputTimeInfo";

export const TimeSetup: React.FC<{
  scenario: SimulationScenario;
  inputScenarios: InputScenarioRef[];
  updateInputScenarios: (updated: InputScenarioRef[]) => void;
  runSettings: RunSettings;
  updateRunSettings: (updated: RunSettings) => void;
  setTimeInputsValid: (valid: boolean) => void;
}> = ({
  inputScenarios,
  updateInputScenarios,
  runSettings,
  updateRunSettings,
  setTimeInputsValid,
}) => {
  // const [useScheduler, setUseScheduler] = useState(false);
  // const [sleepAfter, setSleepAfter] = useState<TimeValue>({ value: 300, unit: "minutes" });
  // const [sleepFor, setSleepFor] = useState<TimeValue>({ value: 3600, unit: "hours" });

  const renderCurrentScenario = () => {
    return (
      <div className="px-1/10">
        <div className="font-medium px-2">This scenaro</div>
        <div className="">
          <TimeSelecterCurrentJob
            startDate={runSettings.startDate}
            endTimeVal={runSettings.endTimeVal}
            updateEnd={(updated) => {
              updateRunSettings({ ...runSettings, endTimeVal: updated });
            }}
            updateStart={(updated) => {
              updateRunSettings({ ...runSettings, startDate: updated });
            }}
            timeMode={runSettings.runMode}
            setTimeMode={(updated) => {
              updateRunSettings({ ...runSettings, runMode: updated });
            }}
          />
        </div>
      </div>
    );
  };

  const [timeDomain, setTimeDomain] = useState<TimeInfoItem[]>([]);
  const onLoadedTime = useCallback((info: TimeInfoItem) => {
    setTimeDomain((p) => updateArrayVal(p, info));
  }, []);

  useEffect(() => {
    let valid = true;
    timeDomain.forEach((inputTimeItem) => {
      if (inputTimeItem.startTime.isAfter(runSettings.startDate)) valid = false;
    });
    setTimeInputsValid(valid);
  }, [timeDomain, runSettings, setTimeInputsValid]);

  //simulation end time:
  const endDate = useMemo(() => {
    if (runSettings.runMode === "duration")
      return runSettings.startDate.add(runSettings.endTimeVal.value, "seconds");
    else {
      let latest = runSettings.startDate;

      timeDomain.forEach((tdi) => {
        if (tdi.endTime.isAfter(latest)) latest = tdi.endTime;
      });

      if (latest.isSame(runSettings.startDate)) latest = latest.add(1, "week");

      return latest;
    }
  }, [runSettings.endTimeVal, runSettings.startDate, timeDomain, runSettings.runMode]);

  return (
    <div className="w-full relative">
      <div className="relative z-10 py-10">{renderCurrentScenario()}</div>
      <div className="">
        {inputScenarios.map((inputScenario, i) => {
          return (
            <InputTimeInfo
              key={inputScenario.scenarioID}
              onUpdateOffset={(updated) => {
                const updatedInput = { ...inputScenario, offset: updated };
                updateInputScenarios(immutableSplice(inputScenarios, i, 1, updatedInput));
              }}
              inputScenario={inputScenario}
              onLoadedTime={onLoadedTime}
              simulationStart={runSettings.startDate}
              simulationEnd={endDate}
              timeDomain={timeDomain}
            />
          );
        })}
      </div>
      <div
        className="absolute top-0 left-0 w-1/10 h-full  bg-gray-50"
        style={{ zIndex: -1 }}
      ></div>
      <div
        className="absolute top-0 right-0 w-1/10 h-full bg-gray-50"
        style={{ zIndex: -1 }}
      ></div>
    </div>
  );
};

export type TimeInfoItem = {
  startTime: Dayjs;
  endTime: Dayjs;
  id: string;
};
