import { useAllUsers, useFirebase } from "api/useFirebase";
import CrossCircleIcon from "components/basic/icons/CrossCircleIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import { Comment, Notification, User } from "model/datatypes";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { store } from "store";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

interface Props {
  commentTarget: Partial<Comment>;
  onFinish: () => void;
  onClose: () => void;
}

const AddComment: React.FC<Props> = ({ commentTarget, onFinish, onClose }) => {
  const [message, setmessage] = useState("");
  const textCursorPosition = useRef(0);
  const fb = useFirebase();
  const { state } = useContext(store);
  const { user } = state;
  const [loading, setLoading] = useState(false);
  const [tagged, setTagged] = useState<User[]>([]);
  const [showTagList, setShowTagList] = useState(false);
  const users = useAllUsers();
  const [searchword, setsearchword] = useState<string>();
  const [tagableUsers, setTagableUsers] = useState<User[]>([]);

  useEffect(() => {
    const regex = /@[A-z]+\b/gi;
    if (message) {
      const messageBeforeCursor = message.substr(0);

      const matches = messageBeforeCursor.match(regex); // .source på regex
      const matchedWord = matches?.pop()?.substr(1).toLowerCase();
      if (matchedWord) {
        setShowTagList(true);
        setsearchword(matchedWord);
      } else {
        setShowTagList(false);
      }
    }
  }, [message]);

  // Update the searchable users
  useEffect(() => {
    if (searchword) {
      const filteredUsers = users.filter(
        (u) => u.fullName.toLowerCase().search(searchword) !== -1
      );
      setTagableUsers(filteredUsers.slice(0, 5));
    }
  }, [searchword, users]);

  const addToTagged = (user: User) => {
    setTagged([...tagged, user]);

    let lastIndex = message.lastIndexOf(" ");
    setmessage(message.substring(0, lastIndex) + " " + user.fullName);

    setShowTagList(false);
  };

  const sendNotifications = (commentID: string) => {
    tagged.forEach((tag) => {
      const docRef = fb
        .firestore()
        .collection("users")
        .doc(tag.id)
        .collection("notifications")
        .doc();
      const notification: Notification = {
        id: docRef.id,
        read: false,
        CommentID: commentID,
        timestamp: moment(),
      };
      docRef
        .set(convertToFirestoreFormat(notification))
        .then(() => {
          console.log("Succesfully sent notifications");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onSaveComment = () => {
    const scope = commentTarget.componentID
      ? "component"
      : commentTarget.scenarioID
      ? "scenario"
      : commentTarget.groupID
      ? "group"
      : commentTarget.projectID
      ? "project"
      : commentTarget.systemID
      ? "system"
      : undefined;
    if (message.length <= 0) {
      console.log("There is no message");
      Toast("The comment text is empty", { icon: "warning", time: 4000 });
      return;
    } else if (!scope) {
      Toast("Comment missing scope", { icon: "error", time: 4000 });
      return;
    } else {
      setLoading(true);
      const docRef = fb.firestore().collection("Comments").doc();
      const commentToSave: Comment = {
        id: docRef.id,
        ...commentTarget,
        senderID: user?.fbUser.uid!,
        senderUserName: user?.fullName || "Unknown user",
        comment: message,
        tagged: tagged,
        commentScope: scope,
        replies: [],
        timestamp: moment(),
      };
      docRef
        .set(convertToFirestoreFormat(commentToSave))
        .then(() => {
          sendNotifications(commentToSave.id);
          setLoading(false);
          onFinish();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const [activeTag, setActiveTag] = useState<number>(-1);

  return (
    <>
      <Modal onClose={onClose}>
        <div className="w-1/2 bg-white rounded relative shadow z-50 p-4">
          {loading && <LoadingOverlay />}
          <div className=" font-normal mb-2">Add a comment</div>

          <textarea
            data-test="commentTextInput"
            id="commentText"
            className={`input-box w-full h-16`}
            value={message}
            rows={3}
            required
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" && activeTag > 0) {
                setActiveTag(activeTag - 1);
              }
              if (e.key === "ArrowDown" && activeTag < tagableUsers.length - 1) {
                setActiveTag(activeTag + 1);
              }
              if (e.key === "Enter") {
                if (activeTag !== -1) {
                  addToTagged(tagableUsers[activeTag]);
                  setActiveTag(-1);
                  setShowTagList(false);
                }
              }
            }}
            placeholder="Comment..."
            onChange={(e) => {
              textCursorPosition.current = e.target.selectionEnd;
              setmessage(e.target.value);
            }}
          />
          {tagged.length > 0 && (
            <>
              <div className="text-xs text-gray-500 mb-1">This comment will notify:</div>
              <div className="mb-3 flex flex-wrap">
                {tagged.map((tag) => {
                  return (
                    <span
                      key={tag.id}
                      className=" flex justify-center items-center mr-1 px-2 text-xs text-white font-bold bg-blue-400 py-1 rounded-full mt-1"
                    >
                      <span data-test="tagPill">{tag.fullName}</span>
                      <span
                        className="ml-1 cursor-pointer"
                        onClick={() => setTagged(tagged.filter((t) => t.id !== tag.id))}
                      >
                        <CrossCircleIcon />
                      </span>
                    </span>
                  );
                })}
              </div>
            </>
          )}

          <button
            data-test="submitComment"
            className="bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium"
            onClick={() => onSaveComment()}
          >
            OK
          </button>
          {showTagList && tagableUsers.length > 0 && (
            <div style={{ left: 0, top: "172px" }} className="z-40 absolute">
              <div className=" w-full overflow-y-auto bg-white rounded shadow p-2 ">
                {tagableUsers.map((user, i) => {
                  const isActive = activeTag === i;
                  return (
                    <div
                      data-test="tagableUser"
                      key={user.id}
                      className={`m-1 p-1 rounded hover:bg-blue-100 ${
                        isActive ? "bg-blue-100" : ""
                      }`}
                      onClick={(e) => addToTagged(user)}
                    >
                      {user.fullName}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddComment;

// const TextAreaWithTags: React.FC<{
//   message: string;
//   setMessage: (updated: string) => void;
// }> = ({ message, setMessage }) => {
//   const [msg, setMsg] = useState<string>("");

//   return (
//     <div
//       contentEditable
//       suppressContentEditableWarning
//       onInput={(e) => {
//         console.log({ message, target: e.target });
//         //@ts-ignore
//         const newMsg = e.target.innerHTML as string;

//         setMsg(newMsg);
//       }}
//       dangerouslySetInnerHTML={{ __html: msg }}
//       className={`input-box w-full relative h-16`}
//     ></div>
//   );
// };
// /* <div className="w-full h-full">{message}</div>
//       <textarea
//         id="commentText"
//         className={`absolute top-0 left-0 w-full h-full opacity-0`}
//         value={message}
//         rows={3}
//         required
//         placeholder="Comment..."
//         onChange={(e) => {
//           // textCursorPosition.current = e.target.selectionEnd;
//           setMessage(e.target.value);
//         }}
//       /> */
