import React, { useState, useRef } from "react";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import EditIcon from "./icons/EditIcon";

interface Props {
  text: string;
  onChange: (newDisplayName: string) => void;
  className?: string;
  loading?: boolean;
}

const EditableDisplayText: React.FC<Props> = ({ text, onChange, className, loading }) => {
  const [edited, setEdited] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className={`flex items-center relative ${className || ""}`}>
        <div className={`cursor-pointer`}>{text}</div>
        {loading ? (
          <LoadingIcon className="ml-2" />
        ) : (
          <EditIcon
            className="ml-2 h-4 w-4 cursor-pointer"
            onClick={() => {
              setEdited(text);
            }}
          />
        )}
        {edited && (
          <div className="absolute z-10 top-0 left-0 w-full bg-white p-2 border border-gray-200">
            <input
              ref={ref}
              className={`input-box w-full mb-2 ${className ? className : ""}`}
              value={edited}
              onChange={(e) => setEdited(e.target.value)}
            />
            <div className="flex">
              <button
                className={`flex-1 mr-1 button-small ${loading ? "opacity-50" : ""}`}
                onClick={() => {
                  onChange(edited);
                  setEdited(null);
                }}
              >
                edit
              </button>
              <button
                className={`flex-1 ml-1 button-small ${loading ? "opacity-50" : ""}`}
                onClick={() => {
                  setEdited(null);
                }}
              >
                cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditableDisplayText;
