import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { useFirestore } from "api/useFirebase";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import { Dayjs } from "dayjs";
import { SimulationImage } from "model/datatypes";
import React, { useEffect, useState } from "react";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";

interface Props {
  image: SimulationImage;
}

const HistorySetup = ({ image }: Props) => {
  const { build, history } = useImageInfo(image);

  const renderStatus = () => {
    if (build?.status === "completed") return <CheckCircleIcon className="w-4 h-4 mr-1" />;
    else if (build?.status === "running") return <LoadingIcon className="w-4 h-4 mr-1" />;
    else return <XCircleIcon className="w-4 h-4 mr-1" />;
  };

  return (
    <>
      <div className="col-span-1">Image build: </div>
      <div className="col-span-5 flex items-center">
        <div className="inline-flex items-center border border-gray-200 rounded px-2 py-1">
          {renderStatus()}
          <div className="italic">{build?.timestamp.format("HH:mm DD/MM YY")}</div>
        </div>
        {history?.branch && (
          <div className="ml-2">{history.branch === "mater" ? "master" : "local branch"}</div>
        )}
      </div>
    </>
  );
};

export default HistorySetup;

// const His: React.FC<{ currentBuild: string; branch: string }> = () => {
//   return <div></div>;
// };

const useImageInfo = (image: SimulationImage) => {
  const [build, setBuild] = useState<null | Build>(null);
  const [history, setHistory] = useState<null | History>(null);

  const fs = useFirestore();

  useEffect(() => {
    //...
    if (image.repository) {
      const repoDoc = fs
        .collection("file_registries")
        .doc("default")
        .collection("repository")
        .doc(image.repository);
      if (image.build)
        return repoDoc
          .collection("builds")
          .doc(image.build)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const build = convertFromFirestoreFormatNew({
                id: doc.id,
                ...doc.data(),
              }) as Build;
              setBuild(build);
            }
          });
    }
  }, [image, fs]);

  useEffect(() => {
    if (image.repository) {
      const repoDoc = fs
        .collection("file_registries")
        .doc("default")
        .collection("repository")
        .doc(image.repository);
      if (image.history_id)
        return repoDoc
          .collection("history")
          .doc(image.history_id)
          .onSnapshot((doc) => {
            console.log({ doc });
            if (doc.exists) {
              const history = convertFromFirestoreFormatNew({
                id: doc.id,
                ...doc.data(),
              }) as History;
              setHistory(history);
            }
          });
    }
  }, [image, fs]);
  return { history, build };
};

type Build = {
  comit_id: string;
  message: string;
  progress: number;
  repository: string;
  snapshot_id: string;
  state: "ready" | string;
  status: "completed" | "running" | string;
  timestamp: Dayjs;
};

type History = {
  branch: string;
  branch_version: number;
  build_id: string;
  comment: string;
  parent_comit_id: string;
  snapshot: string;
  user_id: string;
  timestamp: Dayjs;
};
