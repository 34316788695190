import React from "react";

interface Props {
  className?: string;
}

const HeatRecoveryIcon = ({ className }: Props) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#fff" d="M0 0H24V24H0z"></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.95"
        d="M5.732 9.477a1.453 1.453 0 00-.73 1.323c0 1 .695 1.74.695 2.827a1.827 1.827 0 01-.696 1.488M8.072 9.477A1.453 1.453 0 007.36 10.8c0 1 .696 1.74.696 2.827a1.836 1.836 0 01-.687 1.488M10.552 9.477a1.453 1.453 0 00-.713 1.323c0 1 .696 1.74.696 2.827a1.834 1.834 0 01-.688 1.488"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.64"
        d="M16.138 9.234v6.125M17.042 9.599l-.904.713M15.233 9.599l.905.713M15.233 15.08l.905-.713M17.042 15.08l-.904-.713M13.484 10.765l5.307 3.062M14.25 10.165l.165 1.14M13.345 11.73l1.07-.426M18.095 14.471l-.165-1.14M19 12.905l-1.07.426M13.484 13.87l5.307-3.062M13.345 12.905l1.07.426M14.25 14.471l.165-1.14M19 11.73l-1.07-.426M18.095 10.165l-.165 1.14"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.95"
        d="M16.573 18.169a4.046 4.046 0 11-8.092 0"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.95"
        d="M10.265 18.96l-1.853-1.496-1.549 1.914"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.95"
        d="M7.672 5.867a4.054 4.054 0 018.1 0"
      ></path>
      <path
        stroke="#3C3C3C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.95"
        d="M13.98 5.075l1.853 1.505 1.549-1.923"
      ></path>
    </svg>
  );
};

export default HeatRecoveryIcon;
