import React, { useState, useContext } from "react";
import Modal from "components/basic/Modal";
import { useAnalyticsLogger, useFirebase } from "api/useFirebase";
import { store } from "store";
import { Group } from "model/datatypes";
import * as Sentry from "@sentry/browser";
import { fsTimestamp } from "utils/firebase/helpers";
import dayjs from "dayjs";
import Toast from "components/basic/Toast";

interface Props {
  onFinish: (group?: Group) => void;
}

const NewGroup: React.FC<Props> = ({ onFinish }) => {
  const [groupName, setgroupName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { state } = useContext(store);
  const { projectID, projectName, user } = state;
  const fb = useFirebase();
  const analyticsLogger = useAnalyticsLogger();

  const onSave = () => {
    if (!isLoading) {
      if (groupName.length < 1) {
        Toast("Group name cannot be empty", { icon: "error" });
        return;
      }
      if (!projectID) {
        Toast("No project ID found", { icon: "error" });
        return;
      }
      setisLoading(true);
      const created = dayjs();
      //set is loadin ..
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .add({
          ownerId: state.user?.fbUser.uid!,
          groupName,
          description,
          scenarios: [],
          created: fsTimestamp.fromDate(created.toDate()),
        })
        .then((doc) => {
          onFinish({
            ownerId: state.user?.fbUser.uid!,
            groupName,
            id: doc.id,
            scenarios: [],
            created,
          });
          analyticsLogger("group_created", {
            user_name: user?.fullName || "",
            user_id: user?.fbUser.uid || "",
            project_id: projectID,
            project_name: projectName,
          });
          setisLoading(false);
        })
        .catch((error) => {
          //set error here..
          console.log(error);
          Sentry.captureException(error);
          Toast("Errror creating group", { icon: "error" });
          setisLoading(false);
        });
    }
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className="bg-white rounded shadow px-4 py-4 z-50 flex flex-col w-1/2">
        <div className="text-lg font-medium mb-2">Add new group</div>
        <label className={tw.label}>Group Name</label>
        <input
          type="text"
          value={groupName}
          onChange={(e) => setgroupName(e.target.value)}
          className={tw.input}
        />
        <label className={tw.label}>Group Description</label>

        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={`input-box h-20`}
        />
        <button
          className={`${tw.saveBtn} mt-2 ${isLoading ? "opacity-50" : ""}`}
          onClick={() => onSave()}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default NewGroup;

const tw = {
  label: "text-xs font-bold",
  saveBtn: "py-2 px-4 shadow rounded border focus:outline-none text-xs font-medium w-full",
  input: "px-2 py-1 focus:outline-none border rounded",
};
