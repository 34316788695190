import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ScenarioStateEdited } from "model/datatypes";
import React, { useState } from "react";
import { GraphHelpers } from "./EnergySystemScenario";
import SetupStep from "./SetupStep";
import AHUComponent from "./SimpleBox/AHUComponent";
import DHWComponent from "./SimpleBox/DHWComponent";
import { ZoneComponent } from "./SimpleBox/Zone";
import SystemTemperatures from "./SystemTemperatures";
import WeatherInput from "./weather/WeatherInput";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const SimpleEnergyModelScenario: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [graphHelpersOpen, setgraphHelpersOpen] = useState(false);

  return (
    <div
      className={`flex-grow overflow-hidden relative flex flex-col ${
        graphHelpersOpen ? "pb-56 " : ""
      }`}
    >
      <div className="flex-grow overflow-y-scroll scrollbar-light px-4 py-4 bg-gray-100">
        <SetupStep
          stepName={"Select weather file"}
          stepNr={1}
          isActive={currentStep === 1}
          ready={true}
          setCurrentStep={setCurrentStep}
        >
          <WeatherInput scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
        </SetupStep>
        <SetupStep
          stepName={"Configure building"}
          stepNr={2}
          isActive={currentStep === 2}
          ready={true}
          setCurrentStep={setCurrentStep}
        >
          <ZoneComponent scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
          <SystemTemperatures
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        </SetupStep>
        <SetupStep
          stepName={"DHW Schedule"}
          stepNr={3}
          isActive={currentStep === 3}
          ready={true}
          setCurrentStep={setCurrentStep}
        >
          <DHWComponent scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
        </SetupStep>
        {/* <SetupStep
          stepName={"Internal gains"}
          stepNr={4}
          isActive={currentStep === 4}
          ready={true}
          setCurrentStep={setCurrentStep}
        >
          <InternalGains scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
        </SetupStep> */}
        <SetupStep
          stepName={"Air handling unit"}
          stepNr={4}
          isActive={currentStep === 4}
          ready={true}
          setCurrentStep={setCurrentStep}
        >
          <AHUComponent scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
        </SetupStep>
        <button className={`button-small w-full font-bold`} onClick={() => {}}>
          Simulate
        </button>
      </div>

      {currentStep === 1 && (
        <GraphHelpers
          graphHelpersOpen={graphHelpersOpen}
          setGraphHelpersOpen={setgraphHelpersOpen}
        />
      )}
    </div>
  );
};

export default SimpleEnergyModelScenario;
