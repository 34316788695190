import React, { useMemo } from "react";
import {
  Container,
  ComponentType,
  ComponentTypeReference,
  SubCompRules,
} from "model/datatypes";
import gtw from "gtw";
import EditableName from "components/basic/EditableName";
import { Popup } from "components/basic/Popup";
import { BooleanDropdownSelecter } from "components/basic/Dropdown";
import ConfirmDeleteButton from "components/basic/ConfirmDeleteButton";
import CloseIcon from "components/basic/icons/CloseIcon";
import RadioButton from "components/basic/RadioButton";

interface Props {
  container: Container;
  updateContainer: (containerID: string, updatedParams: Partial<Container>) => any;
  allComponentTypes: ComponentType[];
  removeContainer: () => void;
  allowedParamRefs: ComponentTypeReference[];
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  onMove: (dir: "up" | "down") => void;
  maxOrder: number;
  loading?: boolean;
}

const ContainerEditor: React.FC<Props> = ({
  container,
  allComponentTypes,
  updateContainer,
  removeContainer,
  onMove,
  maxOrder,
  loading,
}) => {
  const { addableComponentTypes, addedComponentTypes } = useMemo(() => {
    const addableComponentTypes: ComponentType[] = [];
    const addedComponentTypes: ComponentType[] = [];
    allComponentTypes.forEach((ct) => {
      if (container.componentTypeIDs.some((ctID) => ctID === ct.id))
        addedComponentTypes.push(ct);
      else addableComponentTypes.push(ct);
    });
    return { addableComponentTypes, addedComponentTypes };
  }, [container, allComponentTypes]);

  const renderOptions = () => {
    return (
      <div className="flex items-center">
        <div className="mr-1 text-xs font-medium">{container.order}</div>
        <Popup
          align={"right"}
          useHover
          className="text-xs"
          content={(closeMe) => {
            return (
              <>
                <ConfirmDeleteButton
                  onDelete={removeContainer}
                  buttonText={"Delete container"}
                  warningHeadline={"Delete container?"}
                />
                {container.order > 0 && (
                  <button
                    className={`button-popup ${
                      container.order < maxOrder ? "border-b border-gray-400" : ""
                    }`}
                    onClick={() => {
                      onMove("up");
                      closeMe();
                    }}
                  >
                    Move up
                  </button>
                )}
                {container.order < maxOrder && (
                  <button
                    className={`button-popup`}
                    onClick={() => {
                      onMove("down");
                      closeMe();
                    }}
                  >
                    Move down
                  </button>
                )}
              </>
            );
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </Popup>
      </div>
    );
  };

  const renderCommonInputs = () => {
    return (
      <div className="flex pt-2 text-xs">
        <div className="w-40 flex flex-col">
          <label className={`text-xs font-medium`}>Allow disabling</label>
          <BooleanDropdownSelecter
            className="w-40 text-xs mb-2"
            value={container.instantiationRules?.allowDisabling}
            onChange={(newVal) => {
              updateContainer(container.id, {
                instantiationRules: {
                  ...container.instantiationRules,
                  allowDisabling: newVal,
                },
              });
            }}
          />
          <div className={gtw.label}>Instantiate on start</div>
          <BooleanDropdownSelecter
            className="w-40 text-xs mb-2"
            value={container.instantiationRules?.isMain}
            onChange={(newVal) => {
              updateContainer(container.id, {
                instantiationRules: {
                  ...container.instantiationRules,
                  isMain: newVal,
                },
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderComponent = (c: ComponentType) => {
    const isDefault = container.defaultComponentType === c.id;

    return (
      <div key={c.id} className={`card w-full my-2 relative flex items-center`}>
        <div
          className="cursor-pointer flex-grow"
          onClick={() => {
            console.log("SCROLL TO THIS COMPONENT!");
          }}
        >
          {c.displayName}
        </div>
        <DefaultSelecterIcon
          isDefault={isDefault}
          onClick={() => {
            updateContainer(container.id, {
              defaultComponentType: isDefault ? undefined : c.id,
            });
          }}
        />
        <div
          className="ml-4 cursor-pointer h-5 w-5 text-gray-600"
          onClick={() => {
            updateContainer(container.id, {
              componentTypeIDs: container.componentTypeIDs.filter((id) => id !== c.id),
              ...(isDefault && { defaultComponentType: undefined }),
            });
          }}
        >
          <CloseIcon />
        </div>
      </div>
    );
  };

  return (
    <div className={`border-2 border-gray-400 border-dashed rounded p-4 w-full flex flex-col`}>
      <div className="pb-4">
        <div className="flex justify-between">
          <EditableName
            className="w-26"
            loading={!!loading}
            name={container.displayName}
            onChange={(newName) => {
              updateContainer(container.id, {
                displayName: newName,
              });
            }}
          />
          {renderOptions()}
        </div>
        <div className={gtw.label}>Name: {container.name}</div>
        {renderCommonInputs()}
      </div>

      {addedComponentTypes.map(renderComponent)}

      <Popup
        mt={35}
        className="text-xs"
        content={(closeMe) => {
          return (
            <div>
              {addableComponentTypes.map((compType) => (
                <button
                  key={compType.id}
                  className={gtw.popupBtn}
                  onClick={() => {
                    updateContainer(container.id, {
                      componentTypeIDs: [...container.componentTypeIDs, compType.id],
                    });

                    closeMe();
                  }}
                >
                  {compType.displayName}
                </button>
              ))}
            </div>
          );
        }}
      >
        <button className={`button-small`}>Add Component to container</button>
      </Popup>
    </div>
  );
};

export default ContainerEditor;

export const DefaultSelecterIcon: React.FC<{ onClick: () => void; isDefault: boolean }> = ({
  onClick,
  isDefault,
}) => {
  return (
    <div className="flex cursor-pointer items-center" onClick={() => onClick()}>
      <div className="text-xs font-medium mr-2">Default</div>
      <RadioButton active={isDefault} />
    </div>
  );
};
