import { Component, ComponentParameter, ScenarioStateEdited } from "model/datatypes";
import { useMemo } from "react";

type ComponentParamRef = { componentName: string; parameterID: string };

export const useScenarioCompsParams = (
  scenarioState: ScenarioStateEdited,
  compParams: ComponentParamRef[]
) => {
  const componentsParameters = useMemo(() => {
    const getParam = (componentName: string, paramID: string) => {
      const component = scenarioState.simComponents.find((p) => p.name === componentName);
      const parameter = component?.parameters.find((param) => param.id === paramID);
      // console.log({ scenarioState, component, parameter });
      if (component && parameter) return { component, parameter };
      else return undefined;
    };
    const res: {
      component: Component;
      parameter: ComponentParameter;
    }[] = [];

    compParams.forEach((compParamRef) => {
      const compParam = getParam(compParamRef.componentName, compParamRef.parameterID);
      if (compParam) res.push(compParam);
    });

    return res;
  }, [scenarioState, compParams]);
  return componentsParameters;
};
