import React from "react";
import { PIDTag } from "model/datatypes";

const TimeIcon = (
  <g id="g824" transform="translate(7.000000, 2.000000)">
    <circle
      id="circle713"
      stroke="#707070"
      cx="5.8701"
      fill="#FFFFFF"
      cy="6.21002"
      r="4.8200002"
    ></circle>
    <rect
      id="rect716"
      fill="#FFFFFF"
      fillRule="nonzero"
      x="0.6099"
      y="13.21997"
      width="10.52"
      height="1.75"
    ></rect>
    <rect
      id="rect718"
      stroke="#707070"
      x="1.0498"
      y="13.65997"
      width="9.6499996"
      height="1"
    ></rect>
    <path d="M5.87,6.21 L8.38,3.69" id="Path_175-2" stroke="#707070"></path>
    <path d="M5.87,13.33 L5.87,10.79" id="Path_181-2" stroke="#707070"></path>
  </g>
);

const arrowRight = (
  <g id="g838" transform="translate(7.000000, 5.000000)">
    <path d="M0.0698,4.35999 L11.4702,4.35999" id="line834" stroke="#707070"></path>
    <polygon
      id="polygon836"
      fill="#707070"
      fillRule="nonzero"
      points="8.16 8.45 7.48 7.71 11.09 4.36 7.48 1 8.16 0.27 12.56 4.36"
    ></polygon>
  </g>
);

const arrowLeft = (
  <g id="g852" transform="translate(8.000000, 5.000000)">
    <path d="M12.5601,4.48999 L1.1602,4.48999" id="line848" stroke="#707070"></path>
    <polygon
      id="polygon850"
      fill="#707070"
      fillRule="nonzero"
      points="4.47 0.4 5.15 1.14 1.54 4.49 5.15 7.85 4.47 8.58 0.07 4.49"
    ></polygon>
  </g>
);

const temperature = (
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    transform="translate(8.000000, 2.000000)"
  >
    <g id="Group-3">
      <path
        d="M2.23945171,8.04717603 C1.16959887,8.87389054 0.634672453,9.90945071 0.634672453,11.1538565 C0.634672453,13.0204653 2.09818248,14.7774778 4.36673093,14.7774778 C6.63527938,14.7774778 7.99898531,13.0729097 7.99898531,11.1538565 C7.99898531,9.87448777 7.47033752,8.8389276 6.41304195,8.04717603 L6.41304195,2.44120732 C6.13292156,1.34529037 5.43418387,0.797331891 4.31682888,0.797331891 C2.64079639,0.797331891 2.23945171,2.44120732 2.23945171,2.44120732 C2.23945171,2.44120732 2.23945171,4.30986356 2.23945171,8.04717603 Z"
        id="Path-2"
        stroke="#707070"
      ></path>
      <path
        d="M4.32,13.6 C5.70071187,13.6 6.82,12.4807119 6.82,11.1 C6.82,10.03764 6.15735767,9.13004846 5.22284601,8.76799831 L5.22284601,5.20138726 C5.11722514,4.73364427 4.84272012,4.4023232 4.32,4.4023232 C3.79727988,4.4023232 3.52282393,4.76415345 3.43484118,5.20138726 L3.43484118,8.7612234 C2.49101444,9.11862369 1.82,10.0309499 1.82,11.1 C1.82,12.4807119 2.93928813,13.6 4.32,13.6 Z"
        id="Oval"
        fill="#707070"
      ></path>
    </g>
  </g>
);

const power = (
  <g id="Group" transform="translate(9.000000, 5.000000)">
    <polygon
      id="Path_525-2-2"
      points="3 0.89 7.1 0.89 5 5.09 7.6 5.09 1.6 12.99 2.6 7.59 0 7.59"
    ></polygon>
  </g>
);

const moisture = (
  <g
    fill="none"
    fillRule="evenodd"
    stroke="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <g stroke="#0832FF" transform="translate(0 0)">
      <g transform="translate(6 4)">
        <path
          strokeWidth="1.125"
          d="M9 7.429C9 9.953 6.985 12 4.5 12S0 9.953 0 7.429C0 4.903 4.5 0 4.5 0S9 4.903 9 7.429z"
        ></path>
        <path
          strokeWidth="0.563"
          d="M3 4.5a30.84 30.84 0 011.5-2.25M1.5 6.75c.095-.535.351-1.047.75-1.5"
        ></path>
        <path strokeWidth="1.125" d="M9 7.5c0 2.368-2.014 3.75-4.5 3.75S0 9.868 0 7.5"></path>
      </g>
    </g>
  </g>
);

const humidity = (
  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g transform="translate(6 4)">
      <path
        fill="#0BAEFF"
        fillRule="nonzero"
        d="M10 8.163C10 10.834 7.761 13 5 13s-5-2.166-5-4.837C0 3.93 4.054 0 5 0c.946 0 5 3.93 5 8.163z"
      ></path>
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        d="M4.334 7.157c0 .81-.562 1.232-1.178 1.232C2.541 8.39 2 7.948 2 7.22 2 6.493 2.463 6 3.188 6c.725 0 1.146.495 1.146 1.157zm-1.606.041c0 .405.15.698.439.698.289 0 .417-.26.417-.698 0-.393-.117-.698-.425-.698s-.42.313-.42.698h-.011zM8 8.768C8 9.578 7.438 10 6.822 10c-.615 0-1.15-.442-1.156-1.169-.005-.727.463-1.22 1.189-1.22C7.58 7.61 8 8.116 8 8.768zm-1.587.041c0 .405.145.698.43.698.287 0 .418-.261.418-.698 0-.393-.112-.698-.417-.698s-.42.31-.42.698h-.011z"
      ></path>
      <path
        stroke="#E0E0E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.648"
        d="M7 6l-3 4"
      ></path>
    </g>
  </g>
);

const onOff = (
  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g fill="currentColor" fillRule="nonzero" transform="translate(6 3)">
      <path d="M9.4 2.046a.46.46 0 00-.608.2.434.434 0 00.206.589C14.262 5.419 12.406 13.06 6.51 13.12c-2.6.002-4.86-1.732-5.453-4.186-.594-2.454.635-4.98 2.965-6.1a.434.434 0 00.206-.589.46.46 0 00-.608-.2C.91 3.346-.517 6.283.172 9.136.862 11.985 3.488 14.001 6.51 14c6.853-.073 9.008-8.956 2.89-11.954z"></path>
      <path d="M6.5 5c.276 0 .5-.188.5-.42V.42C7 .189 6.776 0 6.5 0S6 .188 6 .42v4.16c0 .232.224.42.5.42z"></path>
    </g>
  </g>
);

export const IconsOptions: { id: PIDTag["icon"]; display: string; icon: JSX.Element }[] = [
  { id: "time", display: "Time", icon: TimeIcon },
  { id: "arrowRight", display: "Arrow Right", icon: arrowRight },
  { id: "arrowLeft", display: "Arrow Left", icon: arrowLeft },
  { id: "temperature", display: "Temperature", icon: temperature },
  { id: "power", display: "Power", icon: power },
  { id: "moisture", display: "Moisture", icon: moisture },
  { id: "humidity", display: "Humidity", icon: humidity },
  { id: "on_off", display: "On / Off", icon: onOff },
];
