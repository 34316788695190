import React, { useReducer, useCallback, useState, useEffect, useMemo } from "react";
import { SimulationScenario } from "model/datatypes";
import EngineSetup from "./simSetup/EngineSetup";
import ComponentSetup from "./simSetup/ComponentSetup";
import { newScenarioReducer } from "./NewScenarioReducer";
import { useLocalSimulationModel } from "api/useFirebase";
import { useSimulationSaver } from "./simSetup/useSimulationSaver";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import LoadingOverlay from "components/basic/LoadingOverlay";

import Toast from "components/basic/Toast";
import InputSetup from "./simSetup/inputs/InputSetup";
import ParameterSetup from "../ParameterSetup";
import EnergySystemScenario from "components/extensions/EMWebconfigurator/EnergySystemScenario";
import SimpleEnergyModelScenario from "components/extensions/EMWebconfigurator/SimpleEnergyModelScenario";
import HeatpumpdesignScenario from "components/extensions/HeatpumpDesign/HeatpumpdesignScenario";
import { useGlobalDispatch, useGlobalState } from "store";
import { JobStatusStates } from "model/datatypes";
import ToggleButton from "components/basic/ToggleButton";

interface Props {
  scenario: SimulationScenario;
  projectID?: string;
}

const ScenarioBuilder: React.FC<Props> = ({ scenario, projectID }) => {
  const [scenarioState, scenarioDispatch] = useReducer(newScenarioReducer, scenario);
  const { systemID, changed } = scenarioState;
  const system = useLocalSimulationModel(systemID, projectID, scenario.id);

  const { openScenarios, activeScenario } = useGlobalState();
  const dispatch = useGlobalDispatch();

  const openScenario = useMemo(() => {
    return openScenarios.find((os) => os.scenarioID === scenario.id);
  }, [openScenarios, scenario.id]);

  //tell global state when scenario is changed
  useEffect(() => {
    if (changed !== openScenario?.edited) {
      dispatch({
        type: "UPDATE_OPEN_SCENARIO_STATE",
        payload: { scenarioID: scenario.id, edited: changed },
      });
    }
  }, [changed, openScenario, dispatch, scenario.id]);

  useEffect(() => {
    scenarioDispatch({ type: "SET_SYSTEM", payload: system || undefined });
  }, [system]);

  const { saveSimulation, loading } = useSimulationSaver(scenarioState, scenarioDispatch);

  const [saveConfirmModalOpen, setSaveConfirmModalOpen] = useState(false);
  const [stoppingSimulation, setStoppingSimulation] = useState(false);
  const [stopSimError, setstopSimError] = useState<null | string>(null);

  const hasActiveJob = useMemo(() => {
    const activeStates: JobStatusStates[] = [
      "Waiting",
      "initializing",
      "running",
      "requested",
    ];
    const hasActive = Object.entries(scenario.jobs).some(([id, job]) =>
      activeStates.includes(job.status.status)
    );
    if (hasActive)
      Toast("Editing a scenario that is currently processing", {
        icon: "warning",
        time: 5000,
      });

    return hasActive;
  }, [scenario.jobs]);

  const saveSim = useCallback(() => {
    if (hasActiveJob) {
      setSaveConfirmModalOpen(true);
    } else {
      saveSimulation()
        .then(() => {
          scenarioDispatch({ type: "SCENARIO_SAVED" });
          Toast("Saved scenario", { icon: "success" });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
        });
    }
  }, [saveSimulation, scenarioDispatch, hasActiveJob]);

  const [scenarioUI, setScenarioUI] = useState("standard");

  useEffect(() => {
    if (
      system?.customUI &&
      ["simpleBox", "energySystem", "heatpumpDesign"].includes(system.customUI)
    )
      setScenarioUI(system.customUI);
  }, [system?.customUI]); // "standard" || "simpleBox" || "energySystem" || "heatpumpDesign"

  const renderSaveConfirmModal = () => {
    return (
      <Modal zIndex={30}>
        <div className="z-50 w-64 bg-white px-4 py-2 relative">
          <div className="font-bold">Overwrite current scenario?</div>
          <div className="text-sm mb-2">
            Scenario is currently processing with previous settings. Saving scenario changes
            will termniate current running simulation.
          </div>
          {stopSimError && <div>{stopSimError}</div>}
          <div className={`flex w-full`}>
            <button
              className={`button-small  flex-1 mr-1`}
              onClick={async () => {
                if (!stoppingSimulation && projectID) {
                  try {
                    setStoppingSimulation(true);
                    // await stopJob();
                    Toast("missing stop job implementation");
                    setStoppingSimulation(false);
                    setSaveConfirmModalOpen(false);
                    saveSimulation()
                      .then(() => {
                        scenarioDispatch({ type: "SCENARIO_SAVED" });
                      })
                      .catch((error) => {
                        Sentry.captureException(error);
                        console.log(error);
                      });
                  } catch (error) {
                    Sentry.captureException(error);
                    setstopSimError("Error stopping simulation");
                    setStoppingSimulation(false);
                  }
                }
              }}
            >
              Termniate & save
            </button>
            <button
              className={`button-small flex-1 ml-1`}
              onClick={() => setSaveConfirmModalOpen(false)}
            >
              Cancel
            </button>
          </div>
          {stoppingSimulation && <LoadingOverlay />}
        </div>
      </Modal>
    );
  };

  const renderSaveModal = () => {
    return (
      <Modal
        zIndex={30}
        onClose={() => {
          dispatch({
            type: "UPDATE_OPEN_SCENARIO_STATE",
            payload: { scenarioID: scenario.id, saving: false },
          });
        }}
      >
        <div className="modal-content z-30 w-1/2">
          <div className="text-md font-medium">Scenario changed</div>
          <div className="text-xs italic">Would you like to save scenario changes?</div>
          <div className="flex mt-2">
            <button
              className="button-small flex-1 mr-1"
              onClick={() => {
                saveSimulation()
                  .then(() => {
                    Toast("Saved scenario", { icon: "success" });
                    dispatch({ type: "CLOSE_SCENARIO", payload: scenario.id });
                  })
                  .catch((e) => {
                    console.log(e);
                    Sentry.captureException(e);
                    Toast("Error saving scenario", { icon: "error" });
                  });
              }}
            >
              Save and close
            </button>
            <button
              className="button-small flex-1 mr-1"
              onClick={() => {
                dispatch({ type: "CLOSE_SCENARIO", payload: scenario.id });
              }}
            >
              Close without saving
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderUIToggle = () => {
    const active = scenarioUI === system?.customUI;
    return (
      <div className="absolute top-0 left-0 mt-12 border-t border-b border-r flex items-center z-10 bg-white p-1 rounded-br-lg">
        <div className="text-xs mr-2">Custom UI</div>
        <ToggleButton
          active={active}
          onChange={() => {
            if (active) setScenarioUI("standard");
            else setScenarioUI(system?.customUI!);
          }}
        />
      </div>
    );
  };

  if (scenarioUI === "standard")
    return (
      <>
        <div className="z-10 flex-grow overflow-hidden flex flex-col">
          <div
            className="flex-grow overflow-y-scroll scrollbar-light px-8 py-2 bg-gray-bg"
            id={activeScenario === scenario.id ? "open-active-scenario" : undefined}
          >
            <div className="w-full pt-4 pb-8 text-xs relative">
              <EngineSetup
                scenarioDispatch={scenarioDispatch}
                scenarioState={scenarioState}
                systemName={system?.displayName || "unknown model"}
              />
              <InputSetup scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
              {scenarioState.parameters && !!system?.parameters && (
                <>
                  <hr className="mt-8 mb-4" />
                  {scenarioState.parameters.length > 0 && (
                    <div className="text-xs font-medium w-full border-b border-gray-200">
                      Parameters
                    </div>
                  )}
                  <ParameterSetup
                    parameters={scenarioState.parameters}
                    parameterTypes={system.parameters}
                    updateParameters={(updatedParams) => {
                      scenarioDispatch({
                        type: "UPDATE_PARAMETERS",
                        payload: updatedParams,
                      });
                    }}
                  />
                </>
              )}
              <div className={`font-medium text-xl my-8`}>Components</div>
              {system && (
                <ComponentSetup
                  scenarioDispatch={scenarioDispatch}
                  scenarioState={scenarioState}
                  system={system}
                />
              )}
            </div>
          </div>
          <div className="w-full flex flex-none border-t px-4 py-2 bg-gray-bg">
            <button
              className={`focus:outline-none text-xs py-1 flex-1 border-r font-medium shadow rounded-l bg-white ${
                changed ? "hover:shadow-md" : "opacity-50"
              }`}
              onClick={() => changed && saveSim()}
            >
              Save {loading && <LoadingOverlay />}
            </button>
            <button
              className={`focus:outline-none text-xs py-1 flex-1 text-center font-medium shadow rounded-r bg-white ${
                changed ? "hover:shadow-md" : "opacity-50"
              }`}
              onClick={() => {
                if (changed) scenarioDispatch({ type: "RESET_SCENARIO", payload: scenario });
              }}
            >
              Cancel changes
            </button>
          </div>
        </div>
        {saveConfirmModalOpen && renderSaveConfirmModal()}
        {openScenario?.saving && renderSaveModal()}
        {system?.customUI && renderUIToggle()}
      </>
    );
  else
    return (
      <>
        {scenarioUI === "simpleBox" && (
          <SimpleEnergyModelScenario
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        )}
        {scenarioUI === "energySystem" && (
          <EnergySystemScenario
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        )}
        {scenarioUI === "heatpumpDesign" && (
          <HeatpumpdesignScenario
            scenarioDispatch={scenarioDispatch}
            scenarioState={scenarioState}
          />
        )}
        {saveConfirmModalOpen && renderSaveConfirmModal()}
        {openScenario?.saving && renderSaveModal()}
        {renderUIToggle()}
      </>
    );
};

export default ScenarioBuilder;
