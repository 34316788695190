import OutputDataIcon from "components/basic/icons/OutputDataIcon";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ScenarioStateEdited } from "model/datatypes";
import React, { useMemo, useState } from "react";
import ElectricitySources from "./EnergySystem/ElectricitySources";
import EnergyDemandSelecter from "./EnergySystem/EnergyDemandSelecter";
import EnergySystemSelecter from "./EnergySystem/EnergySystemSelecter";
import PrimarySource from "./EnergySystem/PrimarySource";
import SecondarySources from "./EnergySystem/SecondarySources";
import SetupStep from "./SetupStep";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const EnergySystemScenario: React.FC<Props> = ({ scenarioState, scenarioDispatch }) => {
  const [currentStep, setcurrentStep] = useState(1);
  // const [latestStep, setLatestStep] = useState(1);
  // useEffect(() => {
  //   setLatestStep((p) => (p > currentStep ? p : currentStep));
  // }, [currentStep]);

  const latestsStepAvailable = useMemo(() => {
    const hasSelectedInput = scenarioState.inputScenarios.length > 0;
    if (hasSelectedInput) return 2;

    //const hasPrimarySourceSelected
    //const hasSecondarySourceSelected
    //const hasHPSelected

    return 1;
  }, [scenarioState]);

  const [graphHelpersOpen, setgraphHelpersOpen] = useState(false);
  return (
    <div className="flex-grow overflow-y-scroll scrollbar-light px-4 py-4 bg-gray-100">
      <SetupStep
        stepName={"Select energy demand"}
        stepNr={1}
        isActive={currentStep === 1}
        ready={true}
        setCurrentStep={setcurrentStep}
      >
        <EnergyDemandSelecter
          scenarioDispatch={scenarioDispatch}
          scenarioState={scenarioState}
        />
      </SetupStep>

      <SetupStep
        stepName={"Primary source"}
        stepNr={2}
        isActive={currentStep === 2}
        ready={latestsStepAvailable > 1}
        setCurrentStep={setcurrentStep}
      >
        <PrimarySource scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
      </SetupStep>
      <SetupStep
        stepName={"Secondary sources"}
        stepNr={3}
        isActive={currentStep === 3}
        ready={latestsStepAvailable > 1}
        setCurrentStep={setcurrentStep}
      >
        <SecondarySources scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
      </SetupStep>
      <SetupStep
        stepName={"Heat pump system"}
        stepNr={4}
        isActive={currentStep === 4}
        ready={latestsStepAvailable > 1}
        setCurrentStep={setcurrentStep}
      >
        <EnergySystemSelecter
          scenarioDispatch={scenarioDispatch}
          scenarioState={scenarioState}
        />
      </SetupStep>
      <SetupStep
        stepName={"Additional electricity sources"}
        stepNr={5}
        isActive={currentStep === 5}
        ready={latestsStepAvailable > 1}
        setCurrentStep={setcurrentStep}
      >
        <ElectricitySources
          scenarioDispatch={scenarioDispatch}
          scenarioState={scenarioState}
        />
      </SetupStep>

      <button className={`button-small w-full`}>Start Simulation</button>

      <GraphHelpers
        graphHelpersOpen={graphHelpersOpen}
        setGraphHelpersOpen={setgraphHelpersOpen}
      />
    </div>
  );
};

export default EnergySystemScenario;

export const GraphHelpers: React.FC<{
  graphHelpersOpen: boolean;
  setGraphHelpersOpen: (state: boolean) => void;
}> = ({ graphHelpersOpen, setGraphHelpersOpen }) => {
  if (!graphHelpersOpen)
    return (
      <div
        className="z-50 absolute cursor-pointer bottom-0 left-0 -ml-px -mb-px rounded-tr-lg bg-white shadow-lg border border-gray-300 h-8 px-2 flex items-center justify-center"
        onClick={() => setGraphHelpersOpen(true)}
      >
        <OutputDataIcon />
        <div className="ml-2 text-xxs">View graphs</div>
      </div>
    );
  else
    return (
      <div className="absolute bottom-0 left-0 w-full h-56 bg-white shadow-lg border-t border-gray-200 z-50">
        <button className="text-xs px-2 italic" onClick={() => setGraphHelpersOpen(false)}>
          hide
        </button>
      </div>
    );
};
