import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ParamEditValue } from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import { ScenarioStateEdited } from "model/datatypes";
import React, { useMemo } from "react";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

const SystemTemperatures = ({ scenarioDispatch, scenarioState }: Props) => {
  const { energyModelComp, temperaturesParam, paramType } = useMemo(() => {
    const energyModelComp = scenarioState.simComponents.find(
      (comp) => comp.name === "EnergyModel"
    );
    const temperaturesParam = energyModelComp?.parameters.find(
      (param) => param.id === "temperatures"
    );

    const paramType = scenarioState.system?.componentTypes
      .find((comp) => comp.name === "EnergyModel")
      ?.parameters.find((param) => param.id === "temperatures");

    return { energyModelComp, temperaturesParam, paramType };
  }, [scenarioState.simComponents, scenarioState.system]);

  return (
    <div className="w-full py-4 px-4">
      <div className="text-lg font-medium mb-4">System temperatures</div>
      <div className="w-1/2">
        {temperaturesParam && energyModelComp && (
          <ParamEditValue
            parameter={temperaturesParam}
            paramType={paramType}
            onUpdate={(updatedParam) => {
              const updatedComp = {
                ...energyModelComp,
                parameters: updateArrayVal(energyModelComp.parameters, updatedParam),
              };
              scenarioDispatch({ type: "UPDATE_COMPONENT", payload: updatedComp });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SystemTemperatures;
