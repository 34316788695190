import React, { useState, useRef, useCallback } from "react";
import { JSONValue } from "model/datatypes";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";

interface Props {
  json: JSONValue;
  updateMetadata: (updated: JSONValue) => void;
}

const JSONParameterValue = ({ json, updateMetadata }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [editorForcused, setEditorForcused] = useState(false);
  const [jsonText, setJsonText] = useState(json ? JSON.stringify(json, undefined, 4) : "");
  const parseJson = (text: string) => {
    if (text.length > 0) {
      try {
        const json = JSON.parse(text);
        setError(null);
        updateMetadata(json);
      } catch (error) {
        console.log("error parsing:");
        console.log({ text });
        setError("Error parsing json");
      }
    }
  };
  const editorRef = useRef<HTMLDivElement>(null);
  const clickOutsideEditor = useCallback(() => {
    if (open && !editorForcused) {
      setOpen(false);
    }
  }, [open, editorForcused]);
  useClickOutsideEffect(editorRef, clickOutsideEditor);

  return (
    <div className="text-xs relative w-full">
      <button
        onClick={() => setOpen(!open)}
        className={`button-small w-full flex items-center cursor-pointer truncate
         ${!open && error ? "text-red-500" : ""}`}
      >
        {jsonText}
      </button>
      {open && (
        <div
          ref={editorRef}
          style={{ zIndex: 100 }}
          className="bg-white shadow-md overflow-hidden absolute top-0 right-0 p-2 w-128"
        >
          <div className="flex flex-col">
            <textarea
              ref={textAreaRef}
              className="h-52 bg-gray-100 focus:outline-none focus:bg-white p-2"
              onFocus={() => {
                setEditorForcused(true);
              }}
              onBlur={() => {
                setEditorForcused(false);
                parseJson(jsonText);
              }}
              onKeyDown={(e) => {
                if (!textAreaRef.current) return;
                if (e.keyCode === 9) {
                  //tab...
                  e.preventDefault();
                  // const start = textAreaRef.current.selectionStart;
                  // const end = textAreaRef.current.selectionEnd;
                  // setJsonText(`${jsonText.substring(0, start)}   ${jsonText.substring(end)}`);
                }
                if (e.keyCode === 27) {
                  //escape
                  e.preventDefault();
                  textAreaRef.current.blur();
                }
              }}
              value={jsonText}
              onChange={(e) => setJsonText(e.target.value)}
            />
          </div>
          {error && (
            <div className="italic text-red-500 mt-2">
              {error}
              <button
                className={`text-blue-700 hover:font-medium italic focus:outline-none`}
                onClick={() => {
                  setJsonText(json ? JSON.stringify(json, undefined, 4) : "");
                  setError(null);
                }}
              >
                reset
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JSONParameterValue;
