import React, { useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ScenarioOutputFile, SimulationJob, SimulationScenario } from "model/datatypes";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import RetryIcon from "components/basic/icons/RetryIcon";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import StopIcon from "components/basic/icons/StopIcon";
import PlayIcon from "components/basic/icons/PlayIcon";
import { useUserRole } from "api/useAuth";
import NewTabIcon from "components/basic/icons/NewTabIcon";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { Popup } from "components/basic/Popup";
import HoverTooltip from "components/basic/HoverTooltip";
import { DocumentReportIcon, EyeIcon, DocumentIcon } from "@heroicons/react/solid";
import ReportEditor from "../reportEditor/ReportEditor";
import { useGlobalState } from "store";
import { useIdToken } from "api/useFirebase";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { getFileURL } from "grpc/api/grpcUtilAPI";

type SimulationPipelineIconProps = {
  job: SimulationJob;
  pipelineReady: boolean;
  onStart: () => void;
  onStop: () => void;
  onHardReset: () => void;
  onShowLog: () => void;
};

export const SmulationPipelineIcon = ({
  onStart,
  onStop,
  pipelineReady,
  job,
  onHardReset,
  onShowLog,
}: SimulationPipelineIconProps) => {
  const { hasDeveloperAccess } = useUserRole();
  let statusColor = "#CBD5E0"; //grey
  let done = false;
  let running = false;
  switch (job.status?.status) {
    case "finished":
      statusColor = "#48BB78"; //dark green
      done = true;
      break;
    case "failed":
      statusColor = "#E53E3E"; //red
      break;
    case "Waiting":
    case "requested":
    case "running":
    case "initializing":
    case "Model Initializing":
    case "Environment Initializing":
    case "Equations Assembly":
      statusColor = "#9AE6B4"; //Light green
      running = true;
      break;
  }

  const [hovered, setHovered] = useState(false);

  const simCanReRun = job.status?.status === "finished" && hasDeveloperAccess;
  const simCanRun = pipelineReady && !done && !running;

  const message = job.status
    ? job.status.message
      ? job.status.message
      : job.status.status
    : "Simulation";

  const renderIcon = () => {
    if (done) return hovered && simCanReRun ? <RetryIcon /> : <CheckmarkIcon className="" />;
    if (running)
      return hovered ? (
        <StopIcon />
      ) : job.status?.progress ? (
        <span className="text-xs font-medium">{job.status?.progress.toFixed(0)}%</span>
      ) : (
        <LoadingIcon />
      );
    if (simCanRun) return <PlayIcon />;
  };

  const renderOption = () => {
    return (
      <Popup
        content={(closeMe) => {
          return (
            <>
              <button
                className="button-popup text-xs border-b border-gray-200"
                onClick={() => {
                  onHardReset();
                  closeMe();
                }}
              >
                Hard reset job
              </button>
              <button
                className="button-popup text-xs"
                onClick={() => {
                  onShowLog();
                  closeMe();
                }}
              >
                Show job log
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon className="w-4 h-4 ml-1 cursor-pointer" />
      </Popup>
    );
  };

  return (
    <>
      <div className={`mr-2 ${pipelineReady ? "" : "opacity-50"}`}>
        <div className="flex items-center relative">
          <div
            className={`h-8 w-8 ${
              simCanRun || simCanReRun || pipelineReady ? "cursor-pointer" : ""
            }`}
            style={{ color: statusColor }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
              (simCanRun || simCanReRun) && onStart();
              running && onStop();
            }}
          >
            <CircularProgressbarWithChildren
              value={job.status?.progress ? job.status.progress : 100}
              strokeWidth={8}
              styles={{ path: { stroke: statusColor } }}
            >
              {renderIcon()}
            </CircularProgressbarWithChildren>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-xs font-bold">{job.name || "Simulation"}</div>
          {renderOption()}
        </div>
        <div className="text-xs italic pr-2 w-24">{message}</div>
      </div>
    </>
  );
};

export const OutputFile: React.FC<{
  file: ScenarioOutputFile;
  fileName: string;
  isDeprecated: boolean;
  reportLink?: string;
  isPublished: boolean;
  onPublish?: () => void;
  scenario: SimulationScenario;
}> = ({ fileName, isDeprecated, reportLink, onPublish, isPublished, file, scenario }) => {
  const baseURL = window.location.protocol + "//" + window.location.host;
  const [viewingReport, setviewingReport] = useState<ScenarioOutputFile | null>(null);
  const { projectName, grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const viewFile = () => {
    if (file.fileType === "csv") downloadFile();
    else setviewingReport(file);
  };

  const [loadingFile, setLoadingFile] = useState(false);

  const downloadFile = async () => {
    try {
      if (loadingFile || !idToken) return;

      setLoadingFile(true);
      const url = await getFileURL(grpcURL, idToken, file.report_path);

      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", url);
      linkElement.setAttribute("download", file.fileName);
      linkElement.click();

      setLoadingFile(false);
    } catch (error) {
      console.log(error);
      setLoadingFile(false);
    }
  };

  const renderReportOptions = () => {
    if (file.fileType !== "html") return null;
    return (
      <div className="flex">
        {reportLink && (
          <a
            href={`${baseURL}${reportLink}`}
            target="_blank"
            rel="noopener noreferrer"
            className="h-4 w-4 mr-2"
          >
            <NewTabIcon />
          </a>
        )}
        {isPublished && (
          <HoverTooltip
            className="mr-2"
            rightAlign
            mt={-30}
            mx={-150}
            text="Report is published and can be viewed by guests."
          >
            <EyeIcon className="h-4 w-4" />
          </HoverTooltip>
        )}
        <Popup
          useHover
          mt={15}
          align={"right"}
          content={(closeMe) => {
            return (
              <>
                {onPublish && (
                  <button
                    onClick={() => {
                      onPublish();
                      closeMe();
                    }}
                    className="button-popup  text-xs"
                  >
                    {isPublished ? "Un-publish" : "Publish"} report
                  </button>
                )}
              </>
            );
          }}
        >
          <DotDotDotIcon />
        </Popup>
      </div>
    );
  };

  const renderIcon = () => {
    if (file.fileType === "html") return <DocumentReportIcon className={`h-7 w-7`} />;
    else return <DocumentIcon className={`h-7 w-7`} />;
  };
  return (
    <>
      <div className="text-xs relative flex items-center mb-4">
        <div
          onClick={() => {
            viewFile();
          }}
          className={`cursor-pointer ${!isDeprecated ? " text-green-500" : "text-gray-300"} `}
        >
          {renderIcon()}
        </div>
        <div className="ml-3">
          <div className="flex items-center">
            <span className="font-medium mr-1">{fileName}</span>
            {renderReportOptions()}
          </div>
          <div className="italic">Generated: {file.timestamp.format("HH:mm DD/MM")}</div>
        </div>
      </div>
      {viewingReport && (
        <ReportEditor
          sourcetype="scenario"
          sourceID={scenario.id}
          onClose={() => setviewingReport(null)}
          report_download={viewingReport}
          useModal={true}
          reportName={`${projectName}_${scenario.scenarioName}`}
          useDeprecatedBucket={isDeprecated || undefined}
        />
      )}
      {loadingFile && <LoadingOverlay />}
    </>
  );
};
