import { useFirebase } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import InputNumber from "components/basic/headless/InputNumber";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import RangeSlider from "components/basic/rangeSlider/RangeSlider";
import Toast from "components/basic/Toast";
import { Component, ComponentParameter, JSONValue } from "model/datatypes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NewModal from "components/basic/NewModal";
import BHSResults, { SingleResultTable } from "./BHSResults";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { FullBHSConfig, parseBHSPlotToLayout } from "./useBHSPlotData";
import BHSPlot from "./BHSPlot";
import { PlotData } from "plotly.js";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";

interface Props {
  BHSComponent: Component;
  editBHS: (updates: Partial<Component>) => void;
  demand?: BHSDemandInput;
}

const BHSLibrarySelecter: React.FC<Props> = ({ BHSComponent, editBHS, demand }) => {
  const [configuringBHS, setConfiguringBHS] = useState(false);

  const fb = useFirebase();

  const searchConfig = useMemo(() => {
    const search_configuration = BHSComponent.parameters.find(
      (p) => p.id === "search_configuration"
    )?.value as JSONValue;

    console.log({ search_configuration });

    if (search_configuration) return search_configuration as BHSSearchConfig;
    else return stdSearchConfig;
  }, [BHSComponent]);

  const setSearchConfig = useCallback(
    (updatedConfig: BHSSearchConfig) => {
      const searchConfigParam = BHSComponent.parameters.find(
        (p) => p.id === "search_configuration"
      );

      if (!searchConfigParam) {
        console.log("ERROR search config missing");
        return;
      }

      const updatedParam: ComponentParameter = { ...searchConfigParam, value: updatedConfig };
      editBHS({ parameters: updateArrayValUUID(BHSComponent.parameters, updatedParam) });
    },
    [editBHS, BHSComponent.parameters]
  );

  //automatically update demand if provided by default (from webconfigurator)
  const [demandSet, setDemandSet] = useState(false);
  useEffect(() => {
    if (demand && !demandSet) {
      setDemandSet(true);
      console.log("set demand from std.");
      setSearchConfig({ ...searchConfig, ...demand });
    }
  }, [demand, demandSet, setSearchConfig, searchConfig]);

  const { config, layoutPlot } = useMemo(() => {
    const val = BHSComponent.parameters.find((p) => p.id === "selected_configuration")
      ?.value as JSONValue;
    return { config: val?.config || null, layoutPlot: val?.layoutPlot || null } as {
      config: BHSConfig | null;
      layoutPlot: Partial<PlotData>[] | null;
    };
  }, [BHSComponent]);

  const [plotDataLoading, setPlotDataLoading] = useState(false);

  const selectBHS = (bhs: BHSConfig | null) => {
    //get parameters to update:
    const selectedConfigParam = BHSComponent.parameters.find(
      (p) => p.id === "selected_configuration"
    ); //full configuration
    const selectedConfigIdParam = BHSComponent.parameters.find(
      (p) => p.id === "configuration_id"
    ); //set the ID to be read by the simulation model

    const depthOfFieldParam = BHSComponent.parameters.find((p) => p.id === "H"); //Depth of selected field.

    if (!selectedConfigParam || !selectedConfigIdParam) return;

    //set full config to parameters
    let updatedParameters = updateArrayValUUID(BHSComponent.parameters, {
      ...selectedConfigParam,
      value: { config: bhs, layoutPlot: null },
    });
    //set ID to parameters
    updatedParameters = updateArrayValUUID(updatedParameters, {
      ...selectedConfigIdParam,
      value: bhs?.Configuration_ID,
    });

    if (depthOfFieldParam) {
      updatedParameters = updateArrayValUUID(updatedParameters, {
        ...depthOfFieldParam,
        value: bhs?.Depth,
      });
    }

    console.log({ updatedParameters });

    editBHS({ parameters: updatedParameters });

    if (bhs) loadLayoutPlot(bhs);
  };

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchResults, setSearchResults] = useState<null | BHSConfig[]>(null);
  const [lengthResult, setLengthResult] = useState<null | number>(null);

  const [loading, setLoading] = useState(false);

  const getConfigurations = async () => {
    if (!loading) {
      try {
        setLoading(true);
        //SEARCH BHS HERE...
        const getBHS = fb.functions().httpsCallable("bhsLibrary");
        const config = {
          ...stdConfig,
          ...searchConfig,
        };
        console.log("Getting borehole configuration for:");
        console.log({ parameters: config });
        const results = await getBHS({ config });
        const resultsBHSConfigs = results.data.configurations as BHSConfig[];
        setLoading(false);
        setLengthResult(results.data.totalLength as number);
        setSearchResults(resultsBHSConfigs);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setSearchResults(null);
        Toast("Error finding configurations", { icon: "error" });
      }
    }
  };

  const loadLayoutPlot = async (bhs: BHSConfig) => {
    setPlotDataLoading(true);
    try {
      //SEARCH BHS HERE...
      const getBHSConfig = fb.functions().httpsCallable("getBHSConfig");
      const result = await getBHSConfig({ configID: bhs.Configuration_ID });
      const resultsBHSConfig = result.data as FullBHSConfig;
      const plotData = parseBHSPlotToLayout(resultsBHSConfig);
      const selectedConfigParam = BHSComponent.parameters.find(
        (p) => p.id === "selected_configuration"
      );
      if (!selectedConfigParam) return;
      const updatedParam: ComponentParameter = {
        ...selectedConfigParam,
        value: { config: bhs, layoutPlot: plotData },
      };
      editBHS({ parameters: updateArrayValUUID(BHSComponent.parameters, updatedParam) });
      setPlotDataLoading(false);
    } catch (error) {
      console.log(error);
      setPlotDataLoading(false);
    }
  };

  const renderNumberParameter = (key: string, name: string, value: number) => {
    return (
      <div key={key} className="flex w-1/2 items-center my-2 pr-4">
        <div className="w-1/2 pr-2">{name}</div>
        <InputNumber
          className="input-box w-1/2"
          value={value}
          onChange={(val) =>
            setSearchConfig({
              ...searchConfig,
              [key]: val,
            })
          }
        />
      </div>
    );
  };
  const renderParameter = (name: string, parmObj: JSX.Element) => {
    return (
      <div className="flex w-1/2 items-center my-2 pr-4">
        <div className="w-1/2 pr-2 flex-none">{name}</div>
        {parmObj}
      </div>
    );
  };

  const renderAdvanced = (
    headline: string,
    parameters: { [key: string]: { val: number; name: string } }
  ) => {
    return (
      <div className="py-4 border-b border-gray-200">
        <div className="font-bold text-xs">{headline}</div>
        <div className="w-full flex flex-wrap text-xs ">
          {Object.entries(parameters).map(([key, value]) =>
            renderNumberParameter(key, value.name, value.val)
          )}
        </div>
      </div>
    );
  };

  const [selectedHeatcarrierFluid, setSelectedHeatcarrierFluid] = useState("methanol");
  const renderHeatCarierFluid = () => {
    return (
      <div className="py-4 border-b border-gray-200">
        <div className="font-bold text-xs">Heat carrier fluid properties</div>
        <div className="w-full flex flex-wrap text-xs">
          {renderParameter(
            "Heat carrier fluid",
            <Dropdown
              className="w-1/2 bg-white"
              options={[{ id: "methanol", display: "Methanol", val: 4000 }]}
              selectedID={selectedHeatcarrierFluid}
              onSelect={(option) => {
                setSelectedHeatcarrierFluid(option.id);
                const Cp = option.val as number;
                setSearchConfig({ ...searchConfig, Cp });
              }}
            />
          )}
          {renderParameter(
            "Maximum brine temperature",
            <div className="w-1/2 py-4">
              <RangeSlider
                value={searchConfig.max_bt}
                min={25}
                max={30}
                unit={"°C"}
                updateValue={(newVal) => {
                  setSearchConfig({ ...searchConfig, max_bt: newVal });
                }}
              />
            </div>
          )}
          {renderParameter(
            "Minimum brine temperature",
            <RangeSlider
              value={searchConfig.min_bt}
              min={-5}
              max={0}
              unit={"°C"}
              updateValue={(newVal) => {
                console.log({ newVal });
                setSearchConfig({ ...searchConfig, min_bt: newVal });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderCaracteristics = () => {
    return (
      <div className="pt-4 ">
        <div className="font-bold text-xs">Borehole characteristics</div>
        <div className="w-full flex flex-wrap text-xs">
          {renderParameter(
            "Borehole radius",
            <Dropdown
              className="w-1/2 bg-white"
              options={[
                { id: "44.5", display: "89mm", val: 44.5 },
                { id: "57", display: "114mm", val: 57 },
              ]}
              selectedID={searchConfig.r_bore.toString()}
              onSelect={(option) => {
                const r_bore = option.val as number;
                setSearchConfig({ ...searchConfig, r_bore });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderDemandInput = () => {
    return (
      <div className="w-full flex flex-wrap text-xs mb-4">
        {demand && <div>{JSON.stringify(demand)}</div>}
        {!demand && (
          <>
            {renderNumberParameter("h_tot", "Heating total (?)", searchConfig.h_tot)}
            {renderNumberParameter("q_h_h", "Heating peak hourly (?)", searchConfig.q_h_h)}
            {renderNumberParameter("q_m_h", "Heating peak monthly (?)", searchConfig.q_m_h)}
            {renderNumberParameter("c_tot", "Cooling total (?)", searchConfig.c_tot)}
            {renderNumberParameter("q_h_c", "Cooling peak hourly (?)", searchConfig.q_h_c)}
            {renderNumberParameter("q_m_c", "Cooling peak monthly (?)", searchConfig.q_m_c)}
            {renderNumberParameter("COP_h", "COP Heating", searchConfig.COP_h)}
            {renderNumberParameter("COP_c", "COP cooling", searchConfig.COP_c)}
          </>
        )}
      </div>
    );
  };

  const renderBHSSelecter = () => {
    return (
      <div style={{ width: "90vw" }}>
        <div className="pb-4 border-b border-gray-200">Borefield configuration library</div>

        <div className="font-medium text-xs mt-4">Constrains</div>
        <div className="w-full flex flex-wrap text-xs mb-4">
          {renderNumberParameter("Short_side", "Short side (m)", searchConfig.Short_side)}
          {renderNumberParameter("Long_side", "Long side (m)", searchConfig.Long_side)}
          {renderNumberParameter("H_max", "Max drilling depth (m)", searchConfig.H_max)}
        </div>

        <div className="font-medium text-xs mt-4">Demand to cover</div>
        {renderDemandInput()}

        <div className="bg-gray-100 rounded px-4 py-4 mb-4">
          <div
            className="text-xs font-medium flex items-center cursor-pointer"
            onClick={() => setShowAdvanced((prev) => !prev)}
          >
            <div className="mr-2">Advanced borehole parameters</div>
            <OpenCloseArrow isOpen={showAdvanced} />
          </div>
          {showAdvanced && (
            <>
              {renderAdvanced("Ground properties", {
                k: { val: 2.25, name: "Ground thermal conductivity [W/(m K)]" },
                V: { val: 2.856, name: "Ground volumetric heat capacity [MJ/(m3 K)]" }, //Group volumetric heat capacity
                T_g: { val: 12.41, name: "Undisturbed ground temperature [°C]" }, // undisturbed ground temperature
              })}
              {renderHeatCarierFluid()}
              {renderCaracteristics()}
            </>
          )}
        </div>
        <button
          className={`py-2 shadow-md  font-medium focus:outline-none text-xs rounded w-full bg-green-numerous relative text-white flex items-center justify-center ${
            loading ? "opacity-75" : ""
          }`}
          onClick={() => {
            getConfigurations();
          }}
        >
          Search borefield configurations
          {loading && <LoadingIcon className="text-white ml-2" />}
        </button>
        <BHSResults
          searchResults={searchResults}
          lengthResult={lengthResult}
          selected={config}
          onSelect={(selected) => {
            selectBHS(selected);
          }}
          loadingLayout={plotDataLoading}
          layout={layoutPlot}
        />
      </div>
    );
  };

  return (
    <>
      <div className=" my-4 shadow border border-gray-200 rounded">
        {config && (
          <>
            <SingleResultTable res={config} />
            <div className="flex justify-center h-64 overflow-hidden">
              {layoutPlot && <BHSPlot layout={layoutPlot} />}
            </div>
          </>
        )}
        <button
          className={`w-full focus:outline-none py-2 font-medium ${
            config ? "border-t border-gray-200" : ""
          }`}
          onClick={() => setConfiguringBHS(true)}
        >
          {config ? "Edit configured" : "Configure"} BHS
        </button>
      </div>
      <NewModal open={configuringBHS} onClose={() => setConfiguringBHS(false)}>
        {renderBHSSelecter()}
      </NewModal>
    </>
  );
};

export default BHSLibrarySelecter;

export type BHSConfig = {
  AR: number;
  Configuration_ID: string;
  Cost: number;
  Depth: number;
  Long_side: number;
  N_tot: number;
  Nx: number;
  Ny: number;
  Radius: number;
  Short_side: number;
  Spacing: number;
  T_design: number;
  Temp_pen: number;
  Total_length: number;
  G_25y_L: number;
};

type BHSSearchConfig = {
  H_max: number;
  Short_side: number;
  Long_side: number;
  k: number;
  V: number;
  T_g: number;
  Geo_grad: number;
  Geo_cover: number;
  Cp: number;
  mfl: number;
  max_bt: number;
  min_bt: number;
  r_bore: number;
  l_conf: number;

  h_tot: number;
  q_h_h: number;
  q_m_h: number;
  c_tot: number;
  q_h_c: number;
  q_m_c: number;
  COP_h: number;
  COP_c: number;
};

type BHSDemandInput = {
  h_tot: number;
  q_h_h: number;
  q_m_h: number;
  c_tot: number;
  q_h_c: number;
  q_m_c: number;
  COP_h: number;
  COP_c: number;
};

//Editable fields:
const stdSearchConfig = {
  H_max: 1000,
  Short_side: 1000,
  Long_side: 1000,

  //let ground_properties: {
  k: 2.25, //thermal_conductivity
  V: 2.856, //Group volumetric heat capacity
  T_g: 12.41, // undisturbed ground temperature
  Geo_grad: 3, //Geothermal gradient
  Geo_cover: 1, //grass/soil/concrete (always 1....)

  //HEAT CARRIER FLUID PROPERTIES
  Cp: 4000, //heat carier fluid (default: Methanole)
  mfl: 29.0265, // Mass flow rate
  max_bt: 30, //max brine temperature
  min_bt: 0, //min brine temperature

  //BOREHOLE CHARACTERISTICS
  r_bore: 57, //114mm borehole radius
  l_conf: 1, //Loop configuration (default: single U)

  //DEMAND:
  h_tot: 517, //heating_total
  q_h_h: 126, //heating_peak_hourly
  q_m_h: 70, //heating_peak_monthly
  c_tot: 0, //cooling_total
  q_h_c: 0, //  cooling_peak_hourly
  q_m_c: 0, //  cooling_peak_monthly:
  COP_h: 3.5, //COP_heating
  COP_c: 12, // COP_cooling:
};

//Full search config:
const stdConfig = {
  h_tot: 517, //heating_total
  q_h_h: 126, //heating_peak_hourly
  q_m_h: 70, //heating_peak_monthly
  c_tot: 0, //cooling_total
  q_h_c: 0, //  cooling_peak_hourly
  q_m_c: 0, //  cooling_peak_monthly:
  COP_h: 3.5, //COP_heating
  COP_c: 12, // COP_cooling:

  //let ground_properties: {
  k: 2.25, //thermal_conductivity
  V: 2.856, //Group volumetric heat capacity
  T_g: 12.41, // undisturbed ground temperature
  Geo_grad: 3, //Geothermal gradient
  Geo_cover: 1, //grass/soil/concrete (always 1....)

  //HEAT CARRIER FLUID PROPERTIES
  Cp: 4000, //heat carier fluid (default: Methanole)
  mfl: 29.0265, // Mass flow rate
  max_bt: 30, //max brine temperature
  min_bt: 0, //min brine temperature

  //BOREHOLE CHARACTERISTICS
  r_bore: 57, //114mm borehole radius
  r_pin: 13.7, //Pipe inner radius
  r_pext: 16.7, //Pipe outer radius
  k_grout: 1.73, //grout thermal conductivity
  k_pipe: 0.42, //pipe thermal conductivity (default: polyethylene PE)
  L_u: 47.1, //Center to center distance
  h_conv: 1000, //Internal convection coefficient  [W/(m2 K)] (default: turbulent)
  l_conf: 1, //Loop configuration (default: single U)

  //ground volume available
  H_max: 1000, //max drilling depth
  Short_side: 1000, //short side of available area
  Long_side: 1000, //longer side of the available area

  //Settings
  L_marg: 20, //Tolerance of total length
  Sp_ma: 10, //Tolerance of available space
  Ord: "Temp", //order criteria (temperature or cost)

  //Cost
  Ditch_cost: 220, //Site preparation cost [DKK/m2]
  Set_bore_cost: 6000, //Set up a new borehole  [DKK/borehole]
  Drilling_cost: 300, //Cost per meter [DKK/m]
  Pipe_cost: 100, // Pipe cost per meter [DKK/m]
  Grouting_cost: 150, // Electricity cost [DKK/m3]
  HC_fluid_cost: 20, //HC fluid cost  [DKK/liter]
};
