import { useUserRole } from "api/useAuth";
import { useFirestore, useProject } from "api/useFirebase";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { Project } from "model/datatypes";
import React, { useState } from "react";
import { useGlobalState } from "store";
import EditCollaborators from "./EditCollaborators";

interface Props {}

const ProjectCollaborators = (props: Props) => {
  const { projectID } = useGlobalState();

  const { hasProjectEditAccess, hasSimulatorAccess } = useUserRole();
  const project = useProject(projectID);
  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const updateProject = async (updates: Partial<Project>) => {
    if (project && !loading) {
      try {
        setLoading(true);
        await fs.collection("Projects").doc(project.id).update(updates);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const [viewCollaboartors, setViewCollaboartors] = useState(false);

  if (!hasSimulatorAccess) return null;
  else
    return (
      <>
        <div className="mb-8">
          <div
            onClick={() => setViewCollaboartors((p) => !p)}
            className={`font-medium text-gray-700 text-xl mb-4 flex items-center cursor-pointer`}
          >
            <span data-test="openCloseProjectCollaborators" className="mr-2">
              Project collaborators
            </span>
            <OpenCloseArrow isOpen={viewCollaboartors} />
          </div>
          {project && viewCollaboartors && (
            <EditCollaborators
              allowEditing={hasProjectEditAccess}
              collaborators={project.collaborators}
              teamIds={project.teams}
              updateTeams={(updatedTeams) => {
                return updateProject({ teams: updatedTeams });
              }}
              updateCollaborators={(updatedCollaboratos) => {
                return updateProject({ collaborators: updatedCollaboratos });
              }}
            />
          )}
        </div>
      </>
    );
};

export default ProjectCollaborators;
