import React, { useMemo, useState } from "react";
import { ComponentType, FileQuery, ConnectorRef, SubcomponentType } from "model/datatypes";

import { immutableSplice, updateArrayValUUID } from "utils/jsUtils/imutableArray";

import { deepEqual } from "utils/jsUtils/equality";

import AddComponentType from "components/systems/editSystem/AddComponentType";

import { Transition } from "@headlessui/react";
import { useGlobalState } from "store";
import {
  useEditSystemDispatch,
  useEditSystemState,
} from "components/systems/editSystem/EditSystemStore";
import PopupMenu from "components/basic/PopupMenu";
import { ParamEditValue } from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import InputNumber from "components/basic/headless/InputNumber";
import NewModal from "components/basic/NewModal";
import Toast from "components/basic/Toast";
import getUUID from "utils/jsUtils/getUUID";

const SubComponents: React.FC<{
  comp: ComponentType;
  systemRef: FileQuery["modelRef"];
}> = ({ comp, systemRef }) => {
  const { sidebarState } = useGlobalState();
  const { editedComponentTypes } = useEditSystemState();
  const editSystemDispatch = useEditSystemDispatch();
  const subs = useMemo(
    () =>
      comp.subComponents?.map((sub) => {
        console.log({ sub, editedComponentTypes });
        const componentType = editedComponentTypes.find(
          (compType) => compType.id === sub.componentTypeId
        );

        return { sub, componentType };
      }),
    [comp.subComponents, editedComponentTypes]
  );

  const [addNewSubComponent, setAddNewSubComponent] = useState(false);
  const [addSubComponent, setAddSubComponent] = useState<null | SubcomponentType>(null);
  const amountOfSubcomponent = comp.subComponents
    ? Object.entries(comp.subComponents).length
    : 0;

  const [editInternalConnection, setEditInternalConnection] =
    useState<{
      side1: ConnectorRef | null;
      side2: ConnectorRef | null;
    } | null>(null);
  const [selectingSide, setSelectingSide] = useState<"one" | "two" | null>("one");
  const [editConnectionI, setEditConnectionI] = useState<number | null>(null);

  const connectSubComponent = (ref: ConnectorRef) => {
    if (!editInternalConnection) return;
    if (selectingSide === "one") {
      setEditInternalConnection({ ...editInternalConnection, side1: ref });
      setSelectingSide("two");
    } else if (selectingSide === "two") {
      setEditInternalConnection({ ...editInternalConnection, side2: ref });
      setSelectingSide(editInternalConnection?.side1 === null ? "one" : null);
    }
  };

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...comp, ...newComponentProps };
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const renderEditableConnection = () => {
    return (
      <Transition.Root
        show={!!editInternalConnection}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed bottom-0 left-0 mb-2 z-20 w-full pr-4"
          style={{ paddingLeft: sidebarState === "wide" ? "14rem" : "6rem" }}
        >
          <div className="w-full px-4 py-3 z-50 bg-white shadow-2xl border border-gray-200 rounded">
            <div className="font-medium mb-3 text-center">Internal connection</div>

            <div className="flex">
              <div
                className={`mr-2 w-64 border rounded cursor-pointer hover:bg-gray-100 ${
                  selectingSide === "one" ? "border-green-numerous" : ""
                }`}
                onClick={() => {
                  setSelectingSide("one");
                }}
              >
                <div className="font-medium p-1 border-b text-center">Side 1</div>
                <div className="p-1 text-center">
                  {editInternalConnection?.side1?.display || "Select pin"}
                </div>
              </div>
              <div
                className={`w-64 border rounded cursor-pointer hover:bg-gray-100 ${
                  selectingSide === "two" ? "border-green-numerous" : ""
                }`}
                onClick={() => {
                  setSelectingSide("two");
                }}
              >
                <div className="font-medium p-1 border-b text-center">Side 2</div>
                <div className="p-1 text-center">
                  {editInternalConnection?.side2?.display || "Select pin"}
                </div>
              </div>
            </div>

            <div className="flex mt-4">
              <button
                className="button-small mr-1 flex-1"
                onClick={() => {
                  const prev = comp.internalConnections || [];
                  const side1 = editInternalConnection?.side1;
                  const side2 = editInternalConnection?.side2;

                  if (side1 && side2) {
                    const newConnection = { side1, side2 };
                    const updatedConnections =
                      typeof editConnectionI === "number"
                        ? immutableSplice(prev, editConnectionI, 1, newConnection)
                        : [...prev, newConnection];

                    updateComponent({
                      internalConnections: updatedConnections,
                    });
                    setEditInternalConnection(null);
                    setEditConnectionI(null);
                    setSelectingSide(null);
                  }
                }}
              >
                OK
              </button>
              <button
                className="button-small ml-1 flex-1"
                onClick={() => {
                  setEditInternalConnection(null);
                  setSelectingSide(null);
                  setEditConnectionI(null);
                }}
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </Transition.Root>
    );
  };

  const subcomponentOptions = useMemo(() => {
    return [
      ...editedComponentTypes
        .filter((c) => c.id !== comp.id)
        .map((c) => ({
          id: c.id,
          title: c.displayName,
          description: ``,
          value: c,
        })),
      {
        id: "new",
        title: "New component",
        description: "Add a new component, or a component from the library",
      },
    ];
  }, [editedComponentTypes, comp.id]);

  const renderAttachingSubComponent = () => {
    return (
      <NewModal
        open={!!addSubComponent}
        onClose={() => {
          setAddSubComponent(null);
        }}
      >
        <div style={{ width: "40vw" }}>
          {addSubComponent && (
            <>
              <div className="font-medium text-center text-lg mb-4">Add subcomponent</div>
              <div className="text-xs">Subcomponent name</div>
              <input
                type="text"
                className="text-xs input-box w-full"
                value={addSubComponent.name}
                onChange={(e) => {
                  setAddSubComponent({
                    ...addSubComponent,
                    name: e.target.value,
                  });
                }}
              />
              <div className="flex mt-4">
                <button
                  className="flex-1 mr-1 button-small"
                  onClick={() => {
                    if (comp.subComponents?.some((sub) => sub.name === addSubComponent.name)) {
                      Toast("Name already used");
                      return;
                    }
                    const prev = comp.subComponents || [];
                    updateComponent({ subComponents: [...prev, addSubComponent] });
                    setAddSubComponent(null);
                  }}
                >
                  add
                </button>
                <button
                  className="flex-1 ml-1 button-small"
                  onClick={() => {
                    setAddSubComponent(null);
                  }}
                >
                  cancel
                </button>
              </div>
            </>
          )}
        </div>
      </NewModal>
    );
  };

  const renderSubComponent = ({
    sub,
    componentType,
  }: {
    sub: SubcomponentType;
    componentType?: ComponentType;
  }) => {
    if (!componentType)
      return <div className="my-1 italic text-xs">{sub.name}: missing component type</div>;
    return (
      <div key={sub.name} className="card p-0 my-4">
        <div className="flex justify-between py-3 px-4 border-b border-gray-200">
          <div>
            <div>{componentType.displayName}</div>
            <div className="text-xs">{sub.name}</div>
          </div>
          <div className="flex items-center">
            <button
              className="button-small ml-2"
              onClick={() => {
                const updated = comp.subComponents?.filter((s) => s.name !== sub.name);
                updateComponent({
                  subComponents: updated,
                });
              }}
            >
              Remove
            </button>
          </div>
        </div>

        <div className="flex flex-wrap text-xs  border-b border-gray-200">
          {componentType.parameters.map((paramType) => {
            const stdParam = sub.stdParameters.find((p) => p.uuid === paramType.uuid);
            let param = { ...paramType, ...(stdParam && { value: stdParam.value }) };
            return (
              <div key={paramType.uuid} className="w-1/2 px-4 py-2 flex items-center">
                <div className="w-1/2">{paramType.displayName}</div>
                <ParamEditValue
                  parameter={param}
                  onUpdate={(updated) => {
                    const updatedParam = { uuid: param.uuid, value: updated.value };
                    const updatedParameters = updateArrayValUUID(
                      sub.stdParameters,
                      updatedParam
                    );
                    const updatedSub: SubcomponentType = {
                      ...sub,
                      stdParameters: updatedParameters,
                    };
                    console.log({ updatedSub });
                    updateComponent({
                      subComponents: updateArrayValName(comp.subComponents!, updatedSub),
                    });
                  }}
                />
              </div>
            );
          })}
          {componentType.inputVariables.map((inputVar) => {
            const stdVal = sub.stdVariables.find((v) => v.uuid === inputVar.uuid)?.value;
            return (
              <div key={inputVar.uuid} className="w-1/2 px-4 py-2 flex items-center">
                <div className="w-1/2">{inputVar.display}</div>
                <InputNumber
                  className="input-box w-1/2"
                  value={stdVal || inputVar.value}
                  onChange={(updated) => {
                    const updatedVars = updateArrayValUUID(sub.stdVariables, {
                      uuid: inputVar.uuid,
                      value: updated,
                    });
                    const updatedSub: SubcomponentType = {
                      ...sub,
                      stdVariables: updatedVars,
                    };
                    updateComponent({
                      subComponents: updateArrayValName(comp.subComponents!, updatedSub),
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        {componentType.connectors && componentType.connectors.length > 0 && (
          <div className=" border-gray-200 flex  py-2 px-4">
            {componentType.connectors.map((connector) => {
              return (
                <div className="border border-gray-200 border-dashed py-3 px-4">
                  <div className="font-medium text-xs">{connector.name}</div>
                  <div className="flex flex-wrap text-xs mt-2">
                    {connector.pins.map((pin) => {
                      const ref = {
                        component_id: sub.uuid,
                        connector_id: connector.id,
                        pin: pin.name,
                        display: `${sub.name}.${connector.name}.${pin.name}`,
                      };
                      const isSide1 =
                        editInternalConnection?.side1 &&
                        deepEqual(ref, editInternalConnection.side1);
                      const isSide2 =
                        editInternalConnection?.side2 &&
                        deepEqual(ref, editInternalConnection.side2);
                      const selectable = editInternalConnection && !isSide1 && !isSide2;
                      const side = isSide1
                        ? "1"
                        : isSide2
                        ? "2"
                        : selectingSide === "one"
                        ? "1"
                        : "2";
                      return (
                        <div
                          onClick={() => {
                            selectable && connectSubComponent(ref);
                          }}
                          className={`mr-2 py-1 relative rounded ${
                            selectable
                              ? "shadow-xl border-2 border-green-numerous cursor-pointer"
                              : "border"
                          }`}
                        >
                          <div className="border-b border-gray-200 px-2 ">{pin.name}</div>
                          <div className="px-2 ">{pin.access}</div>

                          <div
                            className={`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-green-numerous text-white text-lg 
                                        ${
                                          isSide1 || isSide2
                                            ? "opacity-75"
                                            : selectingSide !== null &&
                                              !!editInternalConnection
                                            ? "opacity-0 hover:opacity-50"
                                            : "opacity-0"
                                        }
                                      `}
                          >
                            {side}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="px-4 py-4 bg-gray-100">
        <div className="font-bold text-xs">Sub components</div>
        <div>{subs && subs.map(renderSubComponent)}</div>
        <PopupMenu
          buttonText="Add subcomponent"
          options={subcomponentOptions}
          onSelect={(option) => {
            if (option.id === "new") setAddNewSubComponent(true);
            else {
              const compType = option.value as ComponentType;
              const nrExisting = comp.subComponents?.filter(
                (c) => c.componentTypeId === compType.id
              ).length;
              const newSub = {
                uuid: getUUID(),
                componentTypeId: compType.id,
                name: `${compType.name}${nrExisting ? `_${nrExisting + 1}` : ""}`,
                stdParameters: [],
                stdVariables: [],
              } as SubcomponentType;
              setAddSubComponent(newSub);
            }
          }}
        />

        <button
          className="button-small mt-2"
          onClick={() => {
            setEditInternalConnection({
              side1: null,
              side2: null,
            });
            setSelectingSide("one");
          }}
        >
          Connect subcomponents
        </button>
        {amountOfSubcomponent > 1 && (
          <InternalConnections
            comp={comp}
            onSelectConnection={(connection, i) => {
              setEditInternalConnection(connection);
              setEditConnectionI(i);
            }}
          />
        )}
      </div>

      {renderEditableConnection()}

      {addNewSubComponent && (
        <AddComponentType
          order={amountOfSubcomponent}
          onFinish={() => setAddNewSubComponent(false)}
          onAddComponent={(newComponent) => {
            const newComp: ComponentType = {
              ...newComponent,
              instantiationRules: { ...newComponent.instantiationRules, isMain: false },
            };
            editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: newComp });
            Toast("Added new component to system", { icon: "success" });

            const newSubcomp: SubcomponentType = {
              uuid: getUUID(),
              componentTypeId: newComponent.id,
              name: `${newComponent.name}`,
              stdParameters: [],
              stdVariables: [],
            };
            setAddSubComponent(newSubcomp);
          }}
        />
      )}
      {renderAttachingSubComponent()}
    </>
  );
};

export default SubComponents;

const InternalConnections: React.FC<{
  comp: ComponentType;
  onSelectConnection: (
    connection: { side1: ConnectorRef; side2: ConnectorRef },
    i: number
  ) => void;
}> = ({ comp, onSelectConnection }) => {
  if (!comp.internalConnections || comp.internalConnections.length === 0) return null;
  return (
    <>
      <div className="text-xs font-medium mt-4">Internal connections</div>
      <div className="">
        {comp.internalConnections?.map((connectionRef, i) => {
          return (
            <div
              className="inline-flex text-xs border rounded border-gray-400 hover:shadow-lg cursor-pointer mr-2 bg-white"
              onClick={() => {
                onSelectConnection(connectionRef, i);
              }}
            >
              <div className="px-2 border-r border-gray-400">
                {connectionRef.side1.display}
              </div>
              <div className="px-2">{connectionRef.side2.display}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

// const SubComponent: React.FC<{
//   componentType: ComponentType;
//   sub: SubcomponentType;
//   editInternalConnection: {
//     side1: ConnectorRef | null;
//     side2: ConnectorRef | null;
//   } | null;
//   onUpdateSub: (updated: SubcomponentType) => void;
//   onRemove: () => void;
// }> = ({ componentType, sub, editInternalConnection, onUpdateSub, onRemove }) => {
//   return (
//     <div className="card p-0 my-4">
//       <div className="flex justify-between py-3 px-4">
//         <div>
//           <div>{componentType.displayName}</div>
//           <div className="text-xs">{sub.name}</div>
//         </div>
//         <div className="flex items-center">
//           <button
//             className="button-small ml-2"
//             onClick={() => {
//               const updated = comp.subComponents?.filter((s) => s.name !== sub.name);
//               updateComponent(comp.id, {
//                 subComponents: updated,
//               });
//             }}
//           >
//             Remove
//           </button>
//         </div>
//       </div>
//       {componentType.connectors && componentType.connectors.length > 0 && (
//         <div className="border-t border-gray-200 flex  py-2 px-4">
//           {componentType.connectors.map((connector) => {
//             return (
//               <div className="border border-gray-200 border-dashed py-3 px-4">
//                 <div className="font-medium text-xs">{connector.name}</div>
//                 <div className="flex flex-wrap text-xs mt-2">
//                   {connector.pins.map((pin) => {
//                     const ref = {
//                       component_id: sub.name,
//                       connector_id: connector.id,
//                       pin: pin.name,
//                       display: `${sub.name}.${connector.name}.${pin.name}`,
//                     };
//                     const isSide1 =
//                       editInternalConnection?.side1 &&
//                       deepEqual(ref, editInternalConnection.side1);
//                     const isSide2 =
//                       editInternalConnection?.side2 &&
//                       deepEqual(ref, editInternalConnection.side2);
//                     const selectable = editInternalConnection && !isSide1 && !isSide2;

//                     return (
//                       <div
//                         onClick={() => {
//                           selectable && connectSubComponent(ref);
//                         }}
//                         className={`mr-2 py-1 relative rounded ${
//                           selectable
//                             ? "shadow-xl border-2 border-green-numerous cursor-pointer"
//                             : "border"
//                         }`}
//                       >
//                         <div className="border-b border-gray-200 px-2 ">{pin.name}</div>
//                         <div className="px-2 ">{pin.access}</div>

//                         <div
//                           className={`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-green-numerous text-white text-lg
//                                         ${
//                                           isSide1 || isSide2
//                                             ? "opacity-75"
//                                             : selectingSide !== null &&
//                                               !!editInternalConnection
//                                             ? "opacity-0 hover:opacity-50"
//                                             : "opacity-0"
//                                         }
//                                       `}
//                         >
//                           {isSide1 ? "1" : isSide2 ? "2" : selectingSide === "one" ? "1" : "2"}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

export const updateArrayValName = (
  array: { name: string; [key: string]: any }[],
  value: { name: string; [key: string]: any }
) => {
  const i = array.findIndex((item) => item.name === value.name);
  return i > -1 ? immutableSplice(array, i, 1, value) : [...array, value];
};
