import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSimulationModels, useUserInfo, useIdToken } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { Link } from "react-router-dom";
import { SimulationModel } from "model/datatypes";
import ComponentLibrary from "components/systems/ComponentLibrary";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import AddNewModel from "./AddNewModel";
import { useGlobalDispatch, useGlobalState } from "store";
import AddIcon from "components/basic/icons/AddIcon";
import ComponentLibIcon from "components/basic/icons/ComponentLibIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import SearchBar from "components/basic/SearchBar";
import CommentableComponent from "components/comments/CommentableComponent";
import ProjectTypes from "./ProjectTypes";
import Toast from "components/basic/Toast";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/solid";
import { deleteSystemModel } from "grpc/api/grpcUtilAPI";

interface Props {}

const SystemsOverview = (props: Props) => {
  const [addingModel, setAddingModel] = useState<boolean | SimulationModel>(false);

  const { clipboard } = useGlobalState();

  const { allModels } = useSimulationModels();

  const [searchWord, setSearchWord] = useState("");
  const [sortBy, setSortBy] = useState<"edited" | "status">("edited");
  const [ascending, setAscending] = useState(false);

  const shownModels = useMemo(() => {
    let models = allModels;
    if (searchWord.length > 0)
      models = models.filter(
        (model) => !!model.displayName.match(new RegExp(searchWord, "i"))
      );

    models = models.sort((a, b) => {
      if (sortBy === "edited" && ascending)
        return (a.latestEdited?.time.valueOf() || 0) - (b.latestEdited?.time.valueOf() || 0);
      if (sortBy === "edited" && !ascending)
        return (b.latestEdited?.time.valueOf() || 0) - (a.latestEdited?.time.valueOf() || 0);
      if (sortBy === "status" && ascending) return a.status === "draft" ? -1 : 1;
      if (sortBy === "status" && !ascending) return a.status === "draft" ? 1 : -1;

      return 1;
    });
    return models;
  }, [allModels, searchWord, sortBy, ascending]);

  const [contextMenu, setContextMenu] = useState<null | { x: number; y: number }>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const closeContextMenu = useCallback(() => setContextMenu(null), []);
  useClickOutsideEffect(contextMenuRef, closeContextMenu);

  const renderContextMenu = (pos: { x: number; y: number }) => {
    return (
      <div
        ref={contextMenuRef}
        className="fixed top-0 left-0 bg-white rounded border border-gray-200 w-56 text-xs z-20"
        style={{ marginLeft: pos.x, marginTop: pos.y }}
      >
        {clipboard?.type === "system" && (
          <button
            className="button-popup"
            onClick={() => {
              setAddingModel(clipboard.object);
              setContextMenu(null);
            }}
          >
            Paste system
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={"py-8 px-8"}>
      <div className={`${tw.headline} pl-4 mb-4`}>System Models</div>

      <div className="flex items-center w-full px-4 mb-6">
        <div className="flex-grow"></div>
        <SearchBar value={searchWord} onUpdate={(updated) => setSearchWord(updated)} />
        <button
          data-test="addSystem"
          onClick={() => setAddingModel(true)}
          className={`flex items-center justify-center px-4 py-1 border-2 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
        >
          <AddIcon className="w-4 h-4 mr-4" />
          <span className="font-medium text-xs">Add new system</span>
        </button>
      </div>
      <div className="flex items-center w-full px-6 py-1">
        <div className="pl-2 w-1/3">
          <SystemHeadCol ascending={ascending} sortingBy={false} className="w-full">
            System name
          </SystemHeadCol>
        </div>
        <SystemHeadCol ascending={ascending} sortingBy={false} className="w-1/6">
          Created by
        </SystemHeadCol>
        <SystemHeadCol
          ascending={ascending}
          sortingBy={sortBy === "edited"}
          className="w-1/6"
          onClick={() => {
            setSortBy("edited");
            setAscending(sortBy !== "edited" ? false : !ascending);
          }}
        >
          Last edited
        </SystemHeadCol>
        <SystemHeadCol
          ascending={ascending}
          sortingBy={sortBy === "status"}
          className="w-1/6"
          onClick={() => {
            setSortBy("status");
            setAscending(sortBy !== "status" ? false : !ascending);
          }}
        >
          Status
        </SystemHeadCol>
      </div>
      {shownModels.length === 0 && (
        <div className="px-4 text-sm italic py-2 border-t border-gray-200">
          Added system models will appear here.
        </div>
      )}
      <div
        className="px-4 pb-12 relative"
        onContextMenu={(e) => {
          e.preventDefault();
          console.log(e);
          setContextMenu({ x: e.clientX, y: e.clientY });
        }}
      >
        {shownModels.map((simModel, i) => {
          return (
            <div key={simModel.id} className="py-1">
              <CommentableComponent
                commentTaget={{
                  systemID: simModel.id,
                  systemName: simModel.displayName,
                }}
                className={`relative`}
              >
                <Link to={`/systems/${simModel.id}`}>
                  <SimModelRow
                    simModel={simModel}
                    i={i}
                    onDuplicate={() => {
                      setAddingModel(simModel);
                    }}
                  />
                </Link>
              </CommentableComponent>
            </div>
          );
        })}
        {contextMenu && clipboard && renderContextMenu(contextMenu)}
      </div>

      {addingModel && (
        <Modal onClose={() => setAddingModel(false)}>
          <AddNewModel
            onSuccess={() => setAddingModel(false)}
            startModel={typeof addingModel === "object" ? addingModel : undefined}
          />
        </Modal>
      )}

      <div className={`${tw.headline} pl-4 mt-8`}>Other</div>
      <div className="w-1/3 px-4 my-2">
        <CompLibraryCard />
      </div>

      <ProjectTypes systems={allModels} />
    </div>
  );
};

const SystemHeadCol: React.FC<{
  className: string;
  sortingBy: boolean;
  onClick?: () => void;
  ascending: boolean;
}> = ({ className, onClick, sortingBy, children, ascending }) => {
  return (
    <div
      onClick={onClick}
      className={`${className} font-medium text-sm px-2 flex items-center ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      <div className="mr-1">{children}</div>
      {sortingBy &&
        (ascending ? (
          <SortAscendingIcon className="h-4 w-4" />
        ) : (
          <SortDescendingIcon className="h-4 w-4" />
        ))}
    </div>
  );
};

export default SystemsOverview;

const SimModelRow: React.FC<{
  simModel: SimulationModel;
  i: number;
  onDuplicate: () => void;
}> = ({ simModel, i, onDuplicate }) => {
  const dispatch = useGlobalDispatch();
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const creator = useUserInfo(simModel.ownerId);
  const latestEditor = useUserInfo(simModel.latestEdited?.userId);

  const [loading, setLoading] = useState(false);

  const DeleteSimModel = () => {
    if (!loading && idToken) {
      setLoading(true);
      deleteSystemModel(grpcURL, idToken, simModel.id)
        .then(() => {
          //nothing to do here, model is deleted...
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const copySystemToClipboard = () => {
    dispatch({ type: "UPDATE_CLIPBOARD", payload: { type: "system", object: simModel } });
    Toast("Copied system to clipboard", { icon: "success" });
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        align={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`button-popup`}
              onClick={(e) => {
                e.preventDefault();
                onDuplicate();
                closeMe();
              }}
            >
              Duplicate System
            </button>
            <button
              className={`button-popup`}
              onClick={(e) => {
                e.preventDefault();
                copySystemToClipboard();
                closeMe();
              }}
            >
              Copy system
            </button>
            {DeleteOption(closeMe)}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete system model?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`button-small border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        DeleteSimModel();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`button-small w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`button-popup text-red-500 border-t border-gray-400`}>
          Delete System
        </button>
      </Popup>
    );
  };

  return (
    <div
      className={`flex items-center w-full px-6 py-4 shadow-sm bg-white cursor-pointer relative rounded border border-gray-200 hover:border-gray-300`}
    >
      <div data-test="systemModelName" className="font-medium w-1/3 text-sm">
        {simModel.displayName}
      </div>
      <div className="text-xs italic w-1/6 px-2">
        {creator?.fullName && <span>{creator.fullName}</span>}
      </div>
      <div className="text-xs italic w-1/6 px-2">
        <div className="font-medium">{latestEditor?.fullName}</div>
        <div>{simModel.latestEdited?.time.format("HH:mm DD MMM YY")}</div>
      </div>
      <div className="text-xs italic w-1/6 px-2">
        <div
          className={`${
            simModel.status === "published"
              ? "bg-green-400 text-white"
              : "bg-gray-300 text-gray-700"
          } rounded-full px-4 py-1 inline`}
        >
          {simModel.status === "published" ? "Shared model" : "Private draft"}
        </div>
      </div>
      <div className="flex items-center justify-end w-1/6 px-2">{renderOptions()}</div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

const CompLibraryCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <ComponentLibrary />
        </Modal>
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${tw.cardBtn} flex items-center bg-white`}
      >
        <div className="h-8 w-8 mr-4 text-gray-700">
          <ComponentLibIcon />
        </div>
        <span className="">Component Library</span>
      </button>
    </>
  );
};

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "rounded px-4 py-2 bg-white shadow h-24",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  cardBtn:
    "py-2 px-4 w-full shadow rounded focus:outline-none text-sm font-medium hover:font-bold",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-100 focus:outline-none text-xs",
};
