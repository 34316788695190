import React, { useState } from "react";
import FileUploader from "components/files/FileUploader";
import { useSimFiles } from "api/useFirebase";
import { FileQuery } from "model/datatypes";
import FileFilter from "components/files/FileFilter";
import FileViewer from "components/files/FileViewer";

interface Props {}

const FileOverview = (props: Props) => {
  const [fileQuery, setFileQuery] = useState<FileQuery>({ tags: [] });
  const { simFiles: files, loadingFiles } = useSimFiles(fileQuery);

  return (
    <div className={`py-8 px-8 flex flex-col h-screen`}>
      <div className="bg-white shadow-md border border-gray-300 rounded-lg flex-none">
        <FileFilter
          query={fileQuery || { tags: [] }}
          updateQuery={(newQuery) => {
            setFileQuery(newQuery);
          }}
        />
      </div>
      <div className="flex-grow my-4 overflow-hidden">
        <FileViewer files={files} loadingFiles={loadingFiles} />
      </div>
      <FileUploader />
    </div>
  );
};

export default FileOverview;
