import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFirestore, useGroups, useProject, useProjectType } from "api/useFirebase";
import NewGroup from "components/simulations/group/NewGroup";
import GroupCard from "./simulations/GroupCard";
import AddIcon from "./basic/icons/AddIcon";
import ProjectInfo from "./projects/ProjectInfo";
import { useParams } from "react-router-dom";
import { store, useGlobalDispatch, useGlobalState } from "store";
import RepositionList, { RepositionListItem } from "./basic/RepositionList";
import { ComponentParameter, Group } from "model/datatypes";
import ProjectCollaborators from "./collaboratos/ProjectCollaborators";
import ProjectInputSection from "./input/ProjectInputSection";
import { ProjectTypeParameters } from "./simulations/ParameterSetup";
import { useUserRole } from "api/useAuth";
import PublishedReports from "./simulations/PublishedReports";

interface Props {}

const SimulationPage: React.FC<Props> = () => {
  const { userRole } = useUserRole();
  if (userRole === "guest") return <SimulationPageGuest />;
  else return <Simulations />;
};

const SimulationPageGuest: React.FC<Props> = () => {
  const { projectID } = useGlobalState();

  const project = useProject(projectID);

  return (
    <div className="py-8 px-8">
      <div className="text-xl font-bold">{project?.projectName}</div>
      {project?.description && <div className="text-sm">{project.description}</div>}
      {projectID && <PublishedReports projectID={projectID} />}
    </div>
  );
};

const Simulations: React.FC<Props> = () => {
  const { projectID, groups, activeScenario } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [repositionID, setRepositionID] = useState<string | null>(null);
  useLoadGroups();

  const project = useProject(projectID);
  const projectType = useProjectType(project?.projectType);

  const groupsList = useMemo(() => {
    const groupsList: RepositionListItem[] = [];
    //id of group being repositioned currently:
    groups?.forEach((group) => {
      groupsList.push({
        id: group.id,
        element: (
          <GroupCard
            key={group.id}
            projectType={projectType}
            onStartReposition={() => {
              setRepositionID(group.id);
            }}
            group={group}
          />
        ),
        val: group,
      });
    });
    return groupsList;
  }, [groups, projectType]);

  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const onRepositionGroups = (groups: Group[]) => {
    if (!projectID || loading) return;
    setLoading(true);
    //check if same position as previous
    const batch = fs.batch();
    const groupsCol = fs.collection("Projects").doc(projectID).collection("Groups");
    groups.forEach((group, i) => {
      const prevGroup = groups.find((g) => g.id === group.id);
      if (!group.order || prevGroup?.order !== group.order) {
        batch.update(groupsCol.doc(group.id), { order: i });
      }
    });
    batch
      .commit()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error saving group order");
        console.log(e);
        setLoading(false);
      });
  };

  const updateProjectParameters = useCallback(
    async (parameters: ComponentParameter[]) => {
      if (!projectID) return;
      await fs.collection("Projects").doc(projectID).update({ parameters });
    },
    [fs, projectID]
  );

  const [addingGroup, setaddingGroup] = useState(false);

  //if user is accessing page through direct link to group and / or simulation
  useSimulationLinkLoader();


  return (
    <div
      className={`py-8 px-8 relative ${
        activeScenario ? "bg-gray-300 transition-colors duration-1000" : "bg-gray-bg"
      }`}
      onClick={() => {
        // console.log("click inside simpage");
        if (activeScenario) {
          dispatch({ type: "SET_ACTIVE_SCENARIO", payload: undefined });
        }
      }}
    >
      <ProjectInfo project={project} />
      {projectType && projectType.parameters && (
        <ProjectTypeParameters
          paramHeadline={"Project parameters"}
          paramTypes={projectType.parameters}
          parameters={project?.parameters}
          updateParameters={updateProjectParameters}
        />
      )}
      <RepositionList
        list={groupsList}
        onReposition={(updatedList) => {
          onRepositionGroups(updatedList.map((li) => li.val));
        }}
        onDragEnd={() => setRepositionID(null)}
        enableDragOverlay
        dragableID={repositionID}
      />
      {!projectType?.fixedGroups && (
        <button
          onClick={() => setaddingGroup(true)}
          className="mb-2 w-48 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
        >
          <AddIcon className="h-6 w-6 mr-3" />
          <span className="text-xs font-bold">New group</span>
        </button>
      )}
      <div className="my-8">
        <ProjectInputSection />
      </div>

      <div className="my-8">
        <ProjectCollaborators />
      </div>

      {addingGroup && (
        <NewGroup
          onFinish={() => {
            setaddingGroup(false);
          }}
        />
      )}
    </div>
  );
};

export default SimulationPage;

const useSimulationLinkLoader = () => {
  const { dispatch } = useContext(store);

  const { projectID, projectName, groupID, scenarioID } =
    useParams<{
      projectName: string | undefined;
      projectID: string | undefined;
      groupID: string | undefined;
      scenarioID: string | undefined;
    }>();

  useEffect(() => {
    if (projectName && projectID) {
      dispatch({ type: "SET_CURRENT_PROJECT", payload: { projectID, projectName } });
    }
    if (groupID) dispatch({ type: "SELECT_GROUP", payload: groupID });
    dispatch({ type: "SET_LINKED_SCENARIO", payload: scenarioID || null });
  }, [projectID, projectName, groupID, scenarioID, dispatch]);
};

const useLoadGroups = () => {
  const { projectID } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const groups = useGroups(projectID || undefined);
  useEffect(() => {
    const sortedGroups = groups?.sort((a, b) => {
      if (!a.order && !b.order) return a.created.diff(b.created);
      else if (!a.order) return -1;
      else if (!b.order) return 1;
      return a.order - b.order;
    });
    dispatch({ type: "SET_GROUPS", payload: sortedGroups || [] });
  }, [groups, dispatch]);
};
