import React, { useState, useEffect, useMemo } from "react";
import {
  SimulationModel,
  LocalSimulationModel,
  RawDataReport,
  SimulationScenario,
  Group,
} from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import {
  useSimulationModels,
  useAnalyticsLogger,
  useProjects,
  useFirestore,
  useGroups,
} from "api/useFirebase";
import gtw from "gtw";
import { useGlobalDispatch, useGlobalState } from "store";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import { checkScenarioStatus } from "utils/checkScenarioStatus";
import { instantiateAllComponents, ParamTypeToInstance } from "utils/ComponentTypeHelpers";
import app from "firebase/app";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import dayjs from "dayjs";
import { instantiateSimJob } from "utils/dataTransform/simulationJob";
import { experimentalInstantiateAllComponents } from "utils/ExperimentalCompIntantiater";
import Toast from "components/basic/Toast";

interface Props {
  onFinish: () => void;
  projectID: string;
  group?: Group;
  fromScenario?: SimulationScenario;
  availableModels?: SimulationModel[];
}

const NewScenario: React.FC<Props> = ({
  onFinish,
  group,
  projectID,
  fromScenario,
  availableModels,
}) => {
  const analyticsLogger = useAnalyticsLogger();
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [selectedProjectID, setSelectedProjectID] = useState(projectID);
  const groups = useGroups(selectedProjectID);
  const groupOptions = useMemo(
    () =>
      groups?.map((group) => ({
        id: group.id,
        display: group.groupName,
        val: group.id,
      })) || [],
    [groups]
  );

  const { allProjects } = useProjects();
  const fs = useFirestore();

  const [selectedSystem, setselectedSystem] = useState<null | SimulationModel>(null);

  const [scenarioName, setscenarioName] = useState("");
  const [selectedGroupID, setSelectedGroupID] = useState(
    group ? group.id : fromScenario ? fromScenario.groupID : undefined
  );

  useEffect(() => {
    if (fromScenario) {
      fs.collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(fromScenario.id)
        .collection("SimulationModel")
        .doc(fromScenario.systemID)
        .get()
        .then((doc) => {
          if (doc.exists) setselectedSystem({ id: doc.id, ...doc.data() } as SimulationModel);
        })
        .catch((e) => {
          console.log(e);
        });
      setscenarioName(`${fromScenario.scenarioName} copy`);
    }
  }, [fromScenario, fs, projectID]);

  const readyToSave = useMemo(
    () => scenarioName.length > 0 && !!selectedGroupID && !!selectedSystem,
    [scenarioName, selectedGroupID, selectedSystem]
  );

  const saveNewScenario = async () => {
    if (selectedSystem && scenarioName.length > 0 && selectedProjectID && selectedGroupID) {
      try {
        const batch = fs.batch();
        const projectDoc = fs.collection("Projects").doc(selectedProjectID);

        const scenarioDoc = projectDoc.collection("Scenarios").doc();

        //get initial state:
        const initState = initialScenarioStateHelper(fromScenario);

        let simComponents = fromScenario
          ? fromScenario.simComponents
          : selectedSystem.modelType === "experimental_builder"
          ? experimentalInstantiateAllComponents(
              selectedSystem.componentTypes,
              selectedSystem.containers
            )
          : instantiateAllComponents(selectedSystem.componentTypes, selectedSystem.containers);

        console.log({ simComponents });

        const parameters = fromScenario
          ? fromScenario.parameters
          : selectedSystem.parameters.map((paramType) => ParamTypeToInstance(paramType));

        const jobs: SimulationScenario["jobs"] = instantiateSimJob(selectedSystem.jobs || {});

        //set the scennario:
        const newScenario: SimulationScenario = {
          ...initState,
          id: scenarioDoc.id,
          type: "scenario",
          projectID: selectedProjectID,
          created: dayjs(),
          parameters,
          simComponents,
          ownerId: user?.fbUser.uid!,
          systemID: selectedSystem.id,
          scenarioName,
          groupID: selectedGroupID,
          status: fromScenario ? checkScenarioStatus(fromScenario) : "incomplete",
          SPM_version: 2,
          // datasets: fromScenario ? fromScenario.datasets : [],
          inputScenarios: fromScenario ? fromScenario.inputScenarios : [],
          jobs,
          dataTags: [],
          logs: [],
          codePreample:
            (selectedSystem.modelType === "experimental_builder" &&
              selectedSystem.codePreample) ||
            undefined,
        };

        batch.set(scenarioDoc, convertToFirestoreFormat(newScenario));

        //save referebce to project and group
        batch.update(projectDoc, {
          amountOfScenarios: app.firestore.FieldValue.increment(1),
          scenarios: app.firestore.FieldValue.arrayUnion(scenarioDoc.id),
        });
        const groupDoc = projectDoc.collection("Groups").doc(newScenario.groupID);
        batch.update(groupDoc, {
          scenarios: app.firestore.FieldValue.arrayUnion(scenarioDoc.id),
        });

        //copy model to scenario for local model.
        const modelCopyDoc = scenarioDoc.collection("SimulationModel").doc(selectedSystem.id);
        const modelCopy: LocalSimulationModel = {
          ...selectedSystem,
          localVersion: selectedSystem.version,
        };
        batch.set(modelCopyDoc, convertToFirestoreFormat(modelCopy));

        //overwrite all PID diagrams:
        const reportsSnap = await fs
          .collection("SimulationModels")
          .doc(selectedSystem.id)
          .collection("RawDataReports")
          .get();
        reportsSnap.forEach((doc) => {
          const report = { id: doc.id, ...doc.data() } as RawDataReport;
          const localReportDoc = scenarioDoc.collection("RawDataReports").doc(report.id);
          batch.set(localReportDoc, convertToFirestoreFormat(report));
        });

        if (fromScenario) {
          const localReportsSnap = await fs
            .collection("Projects")
            .doc(fromScenario.projectID)
            .collection("Scenarios")
            .doc(fromScenario.id)
            .collection("RawDataReports")
            .get();
          localReportsSnap.forEach((doc) => {
            const report = { id: doc.id, ...doc.data() } as RawDataReport;
            const localReportDoc = scenarioDoc.collection("RawDataReports").doc(report.id);
            batch.set(localReportDoc, convertToFirestoreFormat(report));
          });
        }

        //commit changes:
        batch.commit().then(() => {
          dispatch({ type: "SELECT_GROUP", payload: selectedGroupID });
          const projectName =
            allProjects.find((p) => p.id === selectedProjectID)?.projectName || "";

          if (selectedProjectID !== projectID) {
            dispatch({
              type: "SET_CURRENT_PROJECT",
              payload: { projectName, projectID: selectedProjectID },
            });
          }
          analyticsLogger("scenario_created", {
            project_name: projectName,
            project_id: selectedProjectID,
            scenarioName: newScenario.scenarioName,
            user_name: user?.fullName || "",
            user_id: user?.fbUser.uid || "",
          });
        });
        onFinish();
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        onFinish();
      }
    }
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className="relative z-40 w-full lg:w-3/4 bg-white px-8 py-4 rounded shadow-xl border border-gray-200 text-xs">
        <div className="font-medium text-lg">New simulation scenario</div>
        {fromScenario && <div className="italic">Copy of {fromScenario.scenarioName}</div>}

        <div className={`${gtw.label} mt-2`}>Scenario Name</div>
        <input
          data-test="addScenarioName"
          type="text"
          autoFocus
          className={`input-box w-full text-sm mb-2 focus:ring-1 ring-blue-400`}
          value={scenarioName}
          onChange={(e) => {
            setscenarioName(e.target.value);
          }}
        />

        {(!group || selectedProjectID !== projectID) && (
          <>
            <div className={`${gtw.label}`}>Group</div>
            <Dropdown
              className={`w-full mb-2`}
              selectedID={selectedGroupID}
              options={groupOptions}
              placeholder="Select Group"
              onSelect={(option) => {
                setSelectedGroupID(option.val);
              }}
            />
          </>
        )}

        {fromScenario && (
          <>
            <div className={`${gtw.label}`}>Project</div>
            <Dropdown
              className={`w-full mb-4`}
              selectedID={selectedProjectID}
              options={allProjects.map((project) => ({
                id: project.id,
                display: project.projectName,
              }))}
              placeholder="Select Group"
              onSelect={(option) => {
                if (option.id !== selectedProjectID) {
                  setSelectedGroupID(undefined);
                  setSelectedProjectID(option.id);
                }
              }}
            />
          </>
        )}
        <div className={`font-medium text-xs`}>Simulation system</div>
        {fromScenario ? (
          <div className="mb-2">{selectedSystem?.displayName}</div>
        ) : (
          <SystemSelecter
            selectedSystem={selectedSystem}
            setSelectedSystem={setselectedSystem}
            availableModels={availableModels}
          />
        )}
        <button
          className={`w-full py-1 shadow-md rounded border border-gray-200 ${
            !readyToSave ? "opacity-50" : ""
          }`}
          data-test="addNewScenario"
          onClick={() => {
            if (readyToSave) saveNewScenario();
            else {
              Toast(`Please fill in scenario name and select a system.`, { icon: "warning" });
            }
          }}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default NewScenario;

const SystemSelecter: React.FC<{
  selectedSystem: SimulationModel | null;
  setSelectedSystem: React.Dispatch<React.SetStateAction<SimulationModel | null>>;
  availableModels?: SimulationModel[];
}> = ({ selectedSystem, setSelectedSystem, availableModels }) => {
  const { allModels } = useSimulationModels();

  const systems = useMemo(() => {
    if (availableModels && availableModels.length > 0) {
      return availableModels;
    } else {
      return allModels;
    }
  }, [availableModels, allModels]);

  useEffect(() => {
    setSelectedSystem((p) => (!p && systems.length === 1 ? systems[0] : p));
  }, [systems, setSelectedSystem]);

  return (
    <div
      className={`border rounded border-gray-200 bg-gray-50 w-full overflow-auto max-h-64 py-2 px-4 mb-4 scrollbar-light`}
    >
      {systems.map((system) => {
        const isSelected = selectedSystem?.id === system.id;
        return (
          <div className="py-1" key={system.id}>
            <div
              className={`border bg-white rounded px-3 py-2 w-full flex-none cursor-pointer ${
                isSelected ? "ring-2 ring-blue-400 shadow-lg" : "border-gray-200"
              }`}
              onClick={() => {
                setSelectedSystem(isSelected ? null : system);
              }}
            >
              <div className="font-medium">{system.displayName}</div>
              <div className="text-xs italic">{system.description || "No description"}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const initialScenarioStateHelper: (
  fromScenario?: SimulationScenario
) => Partial<SimulationScenario> = (fromScenario) => {
  if (fromScenario)
    return {
      created: dayjs(),
      scenarioName: fromScenario.scenarioName + " copy",
      description: fromScenario.description,
      parameters: fromScenario.parameters,
      inputScenarios: fromScenario.inputScenarios,
      // datasets: fromScenario.datasets,
    };
  return {
    created: dayjs(),
  };
};
