import dayjs from "dayjs";
import React, { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

interface Props {
  date: Date;
  onUpdate: (updated: Date) => void;
  className?: string;
}

const DateTimeInput = ({ date, onUpdate, className }: Props) => {
  const [displayDate, setDisplayDate] = useState<string>(
    dayjs(date).format("DD/MM/YYYY HH:mm")
  );

  const [dateValid, setDateValid] = useState(true);

  return (
    <Datetime
      dateFormat={"DD/MM/YYYY"}
      timeFormat={"HH:mm"}
      initialValue={displayDate}
      onChange={(updated) => {
        if (typeof updated !== "string") {
          onUpdate(updated.toDate());
          setDisplayDate(updated.format("DD/MM/YYYY HH:mm"));
          setDateValid(true);
        } else {
          setDateValid(false);
        }
      }}
      className={className || "w-40"}
      inputProps={{
        className: `input-box text-xs w-full focus:outline-none focus:ring-1 focus:ring-indigo-500 ${
          dateValid ? "" : "border-red-500"
        }`,
      }}
    />
  );
};

export default DateTimeInput;
