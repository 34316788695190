import { PlotData } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";

interface Props {
  layout: Partial<PlotData>[] | null;
}

const BHSPlot: React.FC<Props> = ({ layout }) => {
  if (!layout) return null;
  return (
    <div className="w-full ">
      <Plot
        data={layout}
        useResizeHandler
        config={{ displayModeBar: false, frameMargins: 0 }}
        style={{
          width: "100%",
          height: "105%",
        }}
        layout={{
          autosize: true,
          // paper_bgcolor: "#f3f3f3",
          showlegend: false,
          font: { size: 9 },
          scene: {
            xaxis: { title: "Position (m)" },
            yaxis: { title: "Position (m)" },
            zaxis: { title: "Depth (m)" },
            camera: {
              up: { x: 0, y: 0, z: 1 },
              eye: { x: 1, y: 1, z: 2 },
            },
            aspectmode: "manual",
            aspectratio: { x: 1, y: 1, z: 1 },
          },
          margin: {
            t: 0,
            b: 0,
            l: 0,
            r: 0,
            pad: 0,
          },
        }}
      />
    </div>
  );
};

export default BHSPlot;
