import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { NewScenarioAction } from "components/simulations/newSimulation/NewScenarioReducer";
import { ParamEditValue } from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import { ScenarioStateEdited } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { useScenarioCompsParams } from "../useScenarioCompsParams";
import { Geometry3dVisualise, GeometryInputs } from "./Geometry";

interface Props {
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  scenarioState: ScenarioStateEdited;
}

export type ZoneGeometry = {
  azimuth: number;
  height: number;
  length: number;
  width: number;
};

export const ZoneComponent: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const zoneComp = useMemo(() => {
    const comp = scenarioState.simComponents.find((comp) => comp.name === "Zone");
    if (!comp) console.log("ERROR: unable to find 'Zone' Component");
    return comp;
  }, [scenarioState.simComponents]);

  const geometry = useMemo(() => {
    const azimuth = zoneComp?.parameters.find((p) => p.id === "rotation")?.value as
      | number
      | undefined;
    const height = zoneComp?.parameters.find((p) => p.id === "height")?.value as
      | number
      | undefined;
    const length = zoneComp?.parameters.find((p) => p.id === "length_x")?.value as
      | number
      | undefined;
    const width = zoneComp?.parameters.find((p) => p.id === "length_y")?.value as
      | number
      | undefined;

    if (
      azimuth === undefined ||
      height === undefined ||
      length === undefined ||
      width === undefined
    ) {
      console.log("ERROR: Missing geometry parameter");
      console.log({
        azimuth,
        height,
        length,
        width,
      });
      return null;
    }

    return {
      azimuth,
      height,
      length,
      width,
    } as ZoneGeometry;
  }, [zoneComp]);

  const setGeometry = (updated: ZoneGeometry) => {
    if (!zoneComp) return;
    let updatedParams = [...zoneComp.parameters];
    const azimuthParam = zoneComp.parameters.find((p) => p.id === "rotation")!;
    updatedParams = updateArrayValUUID(updatedParams, {
      ...azimuthParam,
      value: updated.azimuth,
    });
    const heightParam = zoneComp.parameters.find((p) => p.id === "height")!;
    updatedParams = updateArrayValUUID(updatedParams, {
      ...heightParam,
      value: updated.height,
    });
    const lengthParam = zoneComp.parameters.find((p) => p.id === "length_x")!;
    updatedParams = updateArrayValUUID(updatedParams, {
      ...lengthParam,
      value: updated.length,
    });
    const widthParam = zoneComp.parameters.find((p) => p.id === "length_y")!;
    updatedParams = updateArrayValUUID(updatedParams, {
      ...widthParam,
      value: updated.width,
    });
    scenarioDispatch({
      type: "UPDATE_COMPONENT",
      payload: { ...zoneComp, parameters: updatedParams },
    });
  };

  return (
    <div className="w-full py-4 px-4">
      <div className="flex w-full">
        <div className="w-1/2">
          {geometry && (
            <GeometryInputs
              scenarioState={scenarioState}
              scenarioDispatch={scenarioDispatch}
              geometry={geometry}
              setGeometry={setGeometry}
            />
          )}
          <WindowWallRatio scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
          <UValues scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
          <ZoneParamList scenarioState={scenarioState} scenarioDispatch={scenarioDispatch} />
        </div>
        <div className="rounded h-64 w-1/2 pl-4">
          {geometry && <Geometry3dVisualise geometry={geometry} selected={null} />}
        </div>
      </div>
    </div>
  );
};

const windowWallParameters = [
  { componentName: "Zone", parameterID: "fraction_windows_north" },
  { componentName: "Zone", parameterID: "fraction_windows_east" },
  { componentName: "Zone", parameterID: "fraction_windows_south" },
  { componentName: "Zone", parameterID: "fraction_windows_west" },
  { componentName: "Zone", parameterID: "fraction_windows_roof" },
];

export const WindowWallRatio: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const windowRatioObjects = useScenarioCompsParams(scenarioState, windowWallParameters);

  return (
    <>
      <div
        className="w-full cursor-pointer text-xs flex items-center mt-6 mb-2"
        onClick={() => {
          setIsOpen((p) => !p);
        }}
      >
        <div className="font-bold w-1/3 flex-none pr-2">Window/wall ratio</div>
        {isOpen ? (
          <div className="flex-grow" />
        ) : (
          <div className=" flex-grow flex flex-wrap">
            {windowRatioObjects.map(({ component, parameter }) => (
              <div key={component.id + parameter.uuid} className="mr-2 text-xxs">
                {parameter.tooltip} {parameter.value}%
              </div>
            ))}
          </div>
        )}
        <OpenCloseArrow className="flex-none" isOpen={isOpen} />
      </div>
      {isOpen && (
        <div className="text-xs">
          {windowRatioObjects.map(({ component, parameter }) => {
            return (
              <div key={component.id + parameter.uuid} className="flex items-center my-1">
                <div className={`w-1/2`}>{parameter.displayName}</div>
                <div className="w-1/2 pl-2">
                  <ParamEditValue
                    parameter={parameter}
                    onUpdate={(updated) => {
                      const updatedParams = updateArrayValUUID(component.parameters, updated);
                      const updatedComponent = { ...component, parameters: updatedParams };
                      scenarioDispatch({
                        type: "UPDATE_COMPONENT",
                        payload: updatedComponent,
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const uValuesParameters = [
  { componentName: "Zone", parameterID: "U_wall" },
  { componentName: "Zone", parameterID: "U_slab" },
  { componentName: "Zone", parameterID: "U_roof" },
  { componentName: "Zone", parameterID: "U_windows" },
];

export const UValues: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const uValueObjects = useScenarioCompsParams(scenarioState, uValuesParameters);

  return (
    <>
      <div
        className="w-full cursor-pointer text-xs flex items-center mt-6 mb-2"
        onClick={() => {
          setIsOpen((p) => !p);
        }}
      >
        <div className="font-bold w-1/3 flex-none pr-2">U values</div>
        {isOpen ? (
          <div className="flex-grow" />
        ) : (
          <div className=" flex-grow flex flex-wrap">
            {uValueObjects.map(({ component, parameter }) => (
              <div key={parameter.uuid} className="mr-2 text-xxs">
                {parameter.tooltip} {parameter.value}
              </div>
            ))}
          </div>
        )}
        <OpenCloseArrow className="flex-none" isOpen={isOpen} />
      </div>
      {isOpen && (
        <div className="text-xs">
          {uValueObjects.map(({ component, parameter }, i) => {
            return (
              <div key={parameter.uuid} className="flex items-center my-1">
                <div className={`w-1/2`}>{parameter.displayName}</div>
                <div className="w-1/2 pl-2">
                  <ParamEditValue
                    parameter={parameter}
                    onUpdate={(updated) => {
                      const updatedParams = updateArrayValUUID(component.parameters, updated);
                      const updatedComponent = { ...component, parameters: updatedParams };
                      scenarioDispatch({
                        type: "UPDATE_COMPONENT",
                        payload: updatedComponent,
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const ZoneParams = [
  { componentName: "Zone", parameterID: "G_windows" },
  { componentName: "Zone", parameterID: "X_hr" },
  { componentName: "Zone", parameterID: "T_shading" },
  { componentName: "Zone", parameterID: "thickness_slab" },
  { componentName: "Zone", parameterID: "g_shading" },
];

export const ZoneParamList: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const zoneObjects = useScenarioCompsParams(scenarioState, ZoneParams);

  return (
    <div className="pt-4">
      {zoneObjects.map(({ component, parameter }, i) => {
        return (
          <div key={parameter.uuid} className="w-full flex items-center my-2 text-xs">
            <div className={`w-1/2`}>{parameter.displayName}</div>
            <div className="w-1/2 pl-2">
              <ParamEditValue
                parameter={parameter}
                onUpdate={(updated) => {
                  const updatedParams = updateArrayValUUID(component.parameters, updated);
                  const updatedComponent = { ...component, parameters: updatedParams };
                  scenarioDispatch({
                    type: "UPDATE_COMPONENT",
                    payload: updatedComponent,
                  });
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
