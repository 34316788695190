import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

type Option = { id: string; display: string; val?: any; value?: any };

interface Props {
  className?: string;
  options: Option[];
  labelText?: string;
  selectedId?: string;
  placeholder?: string;
  emptyMsg?: string;
  onSelect: (selected: Option) => void;
  onAddNew?: () => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const SelectMenu: React.FC<Props> = ({
  className,
  options,
  selectedId,
  placeholder,
  onSelect,
  labelText,
  onAddNew,
  emptyMsg,
}) => {
  const selected = options.find((option) => option.id === selectedId);
  return (
    <Listbox value={selected} onChange={onSelect}>
      {({ open }) => (
        <>
          {labelText && (
            <Listbox.Label className="block font-medium text-gray-700">
              {labelText}
            </Listbox.Label>
          )}
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <span className="block truncate">
                {selected?.display || placeholder || "Select option"}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className=" absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-1 pl-6 pr-4",
                        className || ""
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.display}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-3.5"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {options.length === 0 && (
                  <div className="cursor-default select-none relative py-1 pl-6 pr-4">
                    {emptyMsg || "No options available"}
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectMenu;
