import { useFirestore, useIdToken, useScenario } from "api/useFirebase";
import SimIcon from "components/basic/icons/SimIcon";
import Modal from "components/basic/Modal";
import {
  DatasetScenario,
  InputScenarioRef,
  Project,
  ScenarioStateEdited,
} from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalState } from "store";
import { convertFromFirestoreFormatNew } from "utils/firebase/firestoreFormatter";
import { NewScenarioAction } from "../../NewScenarioReducer";
import InputDataIcon from "components/basic/icons/InputDataIcon";
import SelectInputScenario from "./SelectInputScenario";
import NewInput from "components/input/newInput/NewInput";
import AddIcon from "components/basic/icons/AddIcon";
import SelectDataset from "./SelectDataset";
import { PopupButtonWarning, Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { useDataInfo } from "grpc/grpcReact";
import { PencilIcon } from "@heroicons/react/solid";
import DateTimeInput from "components/basic/DateTimeInput";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import dayjs from "dayjs";
import { readTimeInfo } from "grpc/grpcClient";
import { getScenario } from "api/firestore/firestoreAPI";

interface Props {
  scenarioState: ScenarioStateEdited;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}

const InputSetup: React.FC<Props> = ({ scenarioDispatch, scenarioState }) => {
  const [addingInput, setAddingInput] = useState(false);

  useInputSourcesLoader(scenarioState, scenarioDispatch);

  return (
    <div>
      <div className="font-medium text-xl mb-2">Inputs</div>
      <div className="flex flex-wrap">
        {scenarioState.inputScenarios.map((scenarioRef, i) => {
          return (
            <DataInfo
              key={scenarioRef.scenarioID}
              scenarioRef={scenarioRef}
              projectId={scenarioState.projectID}
              onRemove={() => {
                scenarioDispatch({
                  type: "UPDATE_SCENARIO_INPUTS",
                  payload: scenarioState.inputScenarios.filter(
                    (s) => s.scenarioID !== scenarioRef.scenarioID
                  ),
                });
              }}
              updateOffset={(updated) => {
                const updatedRef = {
                  ...scenarioRef,
                  offset: updated,
                };
                scenarioDispatch({
                  type: "UPDATE_SCENARIO_INPUTS",
                  payload: immutableSplice(scenarioState.inputScenarios, i, 1, updatedRef),
                });
              }}
            />
          );
        })}
      </div>
      <div className="flex items-center mt-4">
        <button
          className="button-small"
          onClick={() => {
            setAddingInput(true);
          }}
        >
          Add input
        </button>
      </div>
      {addingInput && (
        <SelectInput
          scenarioState={scenarioState}
          onFinish={() => {
            setAddingInput(false);
          }}
          scenarioDispatch={scenarioDispatch}
        />
      )}
    </div>
  );
};

export default InputSetup;

const SelectInput: React.FC<{
  scenarioState: ScenarioStateEdited;
  onFinish: () => void;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}> = ({ scenarioState, onFinish, scenarioDispatch }) => {
  const { projectID } = useGlobalState();
  const [addNewInput, setAddNewInput] = useState(false);

  const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
  const [selectedDataset, setSelectedDataset] = useState<DatasetScenario | null>(null);

  const ready = useMemo(() => {
    if (selectedScenario || selectedDataset) return true;
    return false;
  }, [selectedScenario, selectedDataset]);

  const selectNewInput = () => {
    if (selectedScenario && projectID)
      onSelectScenario({ projectID, scenarioID: selectedScenario, type: "scenario" });
    else if (selectedDataset)
      onSelectScenario({
        scenarioID: selectedDataset.id,
        projectID: selectedDataset.projectID,
        type: selectedDataset.type,
      });
  };

  const onSelectScenario = (newInput: InputScenarioRef) => {
    const prev = scenarioState.inputScenarios || [];
    scenarioDispatch({ type: "UPDATE_SCENARIO_INPUTS", payload: [...prev, newInput] });
    onFinish();
  };

  return (
    <>
      <Modal canOverflow onClose={onFinish}>
        <div className="modal-content z-30 w-1/2 relative">
          <div className="font-medium mb-4 text-lg">Select input</div>

          <SelectInputScenario
            alreadyAdded={scenarioState.inputScenarios}
            currentScenarioID={scenarioState.id}
            selectedInputID={selectedScenario}
            setSelectedInputID={(sid) => {
              setSelectedScenario(sid);
              setSelectedDataset(null);
            }}
          />
          <SelectDataset
            selected={selectedDataset}
            onSelect={(ds) => {
              setSelectedDataset(ds);
              setSelectedScenario(null);
            }}
            alreadyAdded={scenarioState.inputScenarios}
          />

          <button
            onClick={() => {
              setAddNewInput(true);
            }}
            className="button-small mt-8 flex items-center text-gray-700"
          >
            <AddIcon className="w-5" />
            <span className="ml-2">New input</span>
          </button>
          <div className="w-full flex items-center mt-4">
            <button
              onClick={() => {
                if (ready) selectNewInput();
              }}
              className={`button-small flex-1 mr-2 ${ready ? "" : "opacity-50"}`}
            >
              Ok
            </button>
            <button onClick={() => onFinish()} className={`button-small flex-1 ml-2`}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {addNewInput && (
        <NewInput
          onFinish={() => setAddNewInput(false)}
          onCSVAdded={(newDataset) => {
            setAddNewInput(false);
          }}
        />
      )}
    </>
  );
};

const DataInfo: React.FC<{
  scenarioRef: InputScenarioRef;
  onRemove: () => void;
  updateOffset: (updated: number) => void;
  projectId: string;
}> = ({ scenarioRef, projectId, onRemove, updateOffset }) => {
  const fs = useFirestore();
  const [otherProject, setOtherProject] = useState<Project | null>(null);
  useEffect(() => {
    if (projectId !== scenarioRef.projectID) {
      fs.collection("Projects")
        .doc(scenarioRef.projectID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setOtherProject(
              convertFromFirestoreFormatNew({ ...doc.data(), id: doc.id }) as Project
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [scenarioRef, projectId, fs]);

  const scenario = useScenario(scenarioRef.projectID, scenarioRef.scenarioID);

  const { timeInfo } = useDataInfo(
    scenarioRef.projectID,
    scenarioRef.scenarioID,
    scenario?.lastest_main_execution,
    scenarioRef.offset
  );

  const [offsetDate, setOffsetDate] = useState<Date | null>(null);
  useEffect(() => {
    setOffsetDate(timeInfo?.startTime.toDate() || null);
  }, [timeInfo?.startTime]);

  const renderInputOption = (onRemove: () => void) => {
    return (
      <div className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer">
        <Popup
          className="text-xs"
          useHover
          content={(closeMe) => {
            return (
              <div>
                <PopupButtonWarning
                  warningHeadline={"Remove input source?"}
                  warningDescription={"Input variables using this source will stop working"}
                  btnText="Remove input"
                  onConfirm={() => {
                    onRemove();
                    closeMe();
                  }}
                />
              </div>
            );
          }}
        >
          <DotDotDotIcon />
        </Popup>
      </div>
    );
  };
  const [editing, setEditing] = useState(false);

  const renderEdit = () => {
    return (
      <Modal
        onClose={() => {
          setEditing(false);
        }}
      >
        <div className="modal-content z-30 w-1/2 lg:w-1/3">
          <div className="mb-6 text-center">Edit Input setting</div>
          <div className="font-medium text-xs">Offset data start time</div>
          {offsetDate && (
            <DateTimeInput
              className="w-full"
              date={offsetDate}
              onUpdate={(update) => {
                setOffsetDate(update);
              }}
            />
          )}
          <div className="flex mt-4">
            <button
              className="button-small flex-1"
              onClick={() => {
                if (offsetDate) {
                  updateOffset(offsetDate.valueOf() / 1000);
                  setEditing(false);
                }
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div
        key={scenarioRef.scenarioID}
        className="px-2 py-2 bg-gray-200 rounded mr-2 flex items-center w-64 relative"
      >
        {scenarioRef.type === "dataset" ? <InputDataIcon className="w-6 mx-2" /> : <SimIcon />}
        <div className="px-2">
          <div className="font-medium">{scenario?.scenarioName || "Unknown input.."}</div>
          {otherProject && (
            <div className="flex">
              <span className="italic mr-1">Project:</span>
              <span>{otherProject.projectName}</span>
            </div>
          )}
          {timeInfo && (
            <div
              className="cursor-pointer flex items-center border border-gray-300 rounded "
              onClick={() => {
                setEditing(true);
              }}
            >
              <div className="px-2 py-1 border-r border-gray-300">
                <div className="">{timeInfo.startTime.format("HH:mm DD/MM/YYYY")}</div>
                <div>{timeInfo.endTime.format("HH:mm DD/MM/YYYY")}</div>
              </div>
              <div>
                <PencilIcon className="w-4 h-4 mx-2" />
              </div>
            </div>
          )}
        </div>
        {renderInputOption(onRemove)}
      </div>
      {editing && renderEdit()}
    </>
  );
};

const useInputSourcesLoader = (
  scenarioState: ScenarioStateEdited,
  scenarioDispatch: React.Dispatch<NewScenarioAction>
) => {
  const fs = useFirestore();
  //load scenarios:
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();

  // const allScenarios = useMemo(() => {}, [
  //   scenarioState.inputScenarios,
  //   scenarioState.datasets,
  // ]);

  useEffect(() => {
    if (!idToken) return;

    let active = true;
    const LoadDataInfo = async () => {
      const loadedInfo: ScenarioStateEdited["inputDataInfo"] = [];

      for (let i = 0; i < scenarioState.inputScenarios.length; i++) {
        const ref = scenarioState.inputScenarios[i];
        const scenario = await getScenario(ref.projectID, ref.scenarioID, fs);
        if (scenario) {
          try {
            let { timeInfo } = await readTimeInfo(
              grpcURL,
              idToken,
              ref.projectID,
              ref.scenarioID,
              scenario.lastest_main_execution
            );

            if (ref.offset) {
              const startTimeVal = timeInfo.scenarioStats.min + ref.offset;
              const endTimeVal = timeInfo.scenarioStats.max + ref.offset;
              timeInfo = {
                ...timeInfo,
                offset: ref.offset,
                startTime: dayjs.unix(startTimeVal),
                endTime: dayjs.unix(endTimeVal),
              };
            }
            loadedInfo.push({ scenario, timeInfo });
          } catch (error) {
            console.log("Error loading timeinfo for scenario " + ref.scenarioID);
            console.log(error);
            loadedInfo.push({ scenario });
          }
        }
      }
      return loadedInfo;
    };

    LoadDataInfo()
      .then((dataInfo) => {
        if (!active) return;

        scenarioDispatch({
          type: "LOAD_DATA_INFO",
          payload: dataInfo,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      active = false;
    };
  }, [scenarioState.inputScenarios, scenarioDispatch, idToken, grpcURL, fs]);
};
